@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Source+Sans+Pro:300,400,400i,600,700,900);
.m-none {
  margin: 0; }

.p-none {
  padding: 0; }

.m-t-none {
  margin-top: 0; }

.p-t-none {
  padding-top: 0; }

.m-r-none {
  margin-right: 0; }

.p-r-none {
  padding-right: 0; }

.m-b-none {
  margin-bottom: 0; }

.p-b-none {
  padding-bottom: 0; }

.m-l-none {
  margin-left: 0; }

.p-l-none {
  padding-left: 0; }

.m-xxs {
  margin: 0.125rem; }

.p-xxs {
  padding: 0.125rem; }

.m-t-xxs {
  margin-top: 0.125rem; }

.p-t-xxs {
  padding-top: 0.125rem; }

.m-r-xxs {
  margin-right: 0.125rem; }

.p-r-xxs {
  padding-right: 0.125rem; }

.m-b-xxs {
  margin-bottom: 0.125rem; }

.p-b-xxs {
  padding-bottom: 0.125rem; }

.m-l-xxs {
  margin-left: 0.125rem; }

.p-l-xxs {
  padding-left: 0.125rem; }

.m-xs {
  margin: 0.25rem; }

.p-xs {
  padding: 0.25rem; }

.m-t-xs {
  margin-top: 0.25rem; }

.p-t-xs {
  padding-top: 0.25rem; }

.m-r-xs {
  margin-right: 0.25rem; }

.p-r-xs {
  padding-right: 0.25rem; }

.m-b-xs {
  margin-bottom: 0.25rem; }

.p-b-xs {
  padding-bottom: 0.25rem; }

.m-l-xs {
  margin-left: 0.25rem; }

.p-l-xs {
  padding-left: 0.25rem; }

.m-sm {
  margin: 0.6rem; }

.p-sm {
  padding: 0.6rem; }

.m-t-sm {
  margin-top: 0.6rem; }

.p-t-sm {
  padding-top: 0.6rem; }

.m-r-sm {
  margin-right: 0.6rem; }

.p-r-sm {
  padding-right: 0.6rem; }

.m-b-sm {
  margin-bottom: 0.6rem; }

.p-b-sm {
  padding-bottom: 0.6rem; }

.m-l-sm {
  margin-left: 0.6rem; }

.p-l-sm {
  padding-left: 0.6rem; }

.m-md {
  margin: 1rem; }

.p-md {
  padding: 1rem; }

.m-t-md {
  margin-top: 1rem; }

.p-t-md {
  padding-top: 1rem; }

.m-r-md {
  margin-right: 1rem; }

.p-r-md {
  padding-right: 1rem; }

.m-b-md {
  margin-bottom: 1rem; }

.p-b-md {
  padding-bottom: 1rem; }

.m-l-md {
  margin-left: 1rem; }

.p-l-md {
  padding-left: 1rem; }

.m-lg {
  margin: 2rem; }

.p-lg {
  padding: 2rem; }

.m-t-lg {
  margin-top: 2rem; }

.p-t-lg {
  padding-top: 2rem; }

.m-r-lg {
  margin-right: 2rem; }

.p-r-lg {
  padding-right: 2rem; }

.m-b-lg {
  margin-bottom: 2rem; }

.p-b-lg {
  padding-bottom: 2rem; }

.m-l-lg {
  margin-left: 2rem; }

.p-l-lg {
  padding-left: 2rem; }

.m-xl {
  margin: 4rem; }

.p-xl {
  padding: 4rem; }

.m-t-xl {
  margin-top: 4rem; }

.p-t-xl {
  padding-top: 4rem; }

.m-r-xl {
  margin-right: 4rem; }

.p-r-xl {
  padding-right: 4rem; }

.m-b-xl {
  margin-bottom: 4rem; }

.p-b-xl {
  padding-bottom: 4rem; }

.m-l-xl {
  margin-left: 4rem; }

.p-l-xl {
  padding-left: 4rem; }

.m-xxl {
  margin: 8rem; }

.p-xxl {
  padding: 8rem; }

.m-t-xxl {
  margin-top: 8rem; }

.p-t-xxl {
  padding-top: 8rem; }

.m-r-xxl {
  margin-right: 8rem; }

.p-r-xxl {
  padding-right: 8rem; }

.m-b-xxl {
  margin-bottom: 8rem; }

.p-b-xxl {
  padding-bottom: 8rem; }

.m-l-xxl {
  margin-left: 8rem; }

.p-l-xxl {
  padding-left: 8rem; }

.has-text-primary {
  color: #2d2f7b; }

.has-background-primary {
  background-color: #2d2f7b; }

.border-primary {
  border: 1px solid #2d2f7b; }

.border-m-primary:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #2d2f7b;
  transform: translateY(-50%); }

.border-primary-dotted {
  border: 1px dotted #2d2f7b; }

.border-primary-dashed {
  border: 1px dashed #2d2f7b; }

.border-primary-solid {
  border: 1px solid #2d2f7b; }

.border-primary-double {
  border: 1px double #2d2f7b; }

.border-primary-groove {
  border: 1px groove #2d2f7b; }

.border-primary-ridge {
  border: 1px ridge #2d2f7b; }

.border-primary-inset {
  border: 1px inset #2d2f7b; }

.border-primary-outset {
  border: 1px outset #2d2f7b; }

.border-primary-none {
  border: 1px none #2d2f7b; }

.border-primary-hidden {
  border: 1px hidden #2d2f7b; }

.border-t-primary {
  border-top: 1px solid #2d2f7b; }

.border-t-primary-dotted {
  border-top: 1px dotted #2d2f7b; }

.border-t-primary-dashed {
  border-top: 1px dashed #2d2f7b; }

.border-t-primary-solid {
  border-top: 1px solid #2d2f7b; }

.border-t-primary-double {
  border-top: 1px double #2d2f7b; }

.border-t-primary-groove {
  border-top: 1px groove #2d2f7b; }

.border-t-primary-ridge {
  border-top: 1px ridge #2d2f7b; }

.border-t-primary-inset {
  border-top: 1px inset #2d2f7b; }

.border-t-primary-outset {
  border-top: 1px outset #2d2f7b; }

.border-t-primary-none {
  border-top: 1px none #2d2f7b; }

.border-t-primary-hidden {
  border-top: 1px hidden #2d2f7b; }

.border-r-primary {
  border-right: 1px solid #2d2f7b; }

.border-r-primary-dotted {
  border-right: 1px dotted #2d2f7b; }

.border-r-primary-dashed {
  border-right: 1px dashed #2d2f7b; }

.border-r-primary-solid {
  border-right: 1px solid #2d2f7b; }

.border-r-primary-double {
  border-right: 1px double #2d2f7b; }

.border-r-primary-groove {
  border-right: 1px groove #2d2f7b; }

.border-r-primary-ridge {
  border-right: 1px ridge #2d2f7b; }

.border-r-primary-inset {
  border-right: 1px inset #2d2f7b; }

.border-r-primary-outset {
  border-right: 1px outset #2d2f7b; }

.border-r-primary-none {
  border-right: 1px none #2d2f7b; }

.border-r-primary-hidden {
  border-right: 1px hidden #2d2f7b; }

.border-b-primary {
  border-bottom: 1px solid #2d2f7b; }

.border-b-primary-dotted {
  border-bottom: 1px dotted #2d2f7b; }

.border-b-primary-dashed {
  border-bottom: 1px dashed #2d2f7b; }

.border-b-primary-solid {
  border-bottom: 1px solid #2d2f7b; }

.border-b-primary-double {
  border-bottom: 1px double #2d2f7b; }

.border-b-primary-groove {
  border-bottom: 1px groove #2d2f7b; }

.border-b-primary-ridge {
  border-bottom: 1px ridge #2d2f7b; }

.border-b-primary-inset {
  border-bottom: 1px inset #2d2f7b; }

.border-b-primary-outset {
  border-bottom: 1px outset #2d2f7b; }

.border-b-primary-none {
  border-bottom: 1px none #2d2f7b; }

.border-b-primary-hidden {
  border-bottom: 1px hidden #2d2f7b; }

.border-l-primary {
  border-left: 1px solid #2d2f7b; }

.border-l-primary-dotted {
  border-left: 1px dotted #2d2f7b; }

.border-l-primary-dashed {
  border-left: 1px dashed #2d2f7b; }

.border-l-primary-solid {
  border-left: 1px solid #2d2f7b; }

.border-l-primary-double {
  border-left: 1px double #2d2f7b; }

.border-l-primary-groove {
  border-left: 1px groove #2d2f7b; }

.border-l-primary-ridge {
  border-left: 1px ridge #2d2f7b; }

.border-l-primary-inset {
  border-left: 1px inset #2d2f7b; }

.border-l-primary-outset {
  border-left: 1px outset #2d2f7b; }

.border-l-primary-none {
  border-left: 1px none #2d2f7b; }

.border-l-primary-hidden {
  border-left: 1px hidden #2d2f7b; }

.has-text-primary-bis {
  color: #4d4db3; }

.has-background-primary-bis {
  background-color: #4d4db3; }

.border-primary-bis {
  border: 1px solid #4d4db3; }

.border-m-primary-bis:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #4d4db3;
  transform: translateY(-50%); }

.border-primary-bis-dotted {
  border: 1px dotted #4d4db3; }

.border-primary-bis-dashed {
  border: 1px dashed #4d4db3; }

.border-primary-bis-solid {
  border: 1px solid #4d4db3; }

.border-primary-bis-double {
  border: 1px double #4d4db3; }

.border-primary-bis-groove {
  border: 1px groove #4d4db3; }

.border-primary-bis-ridge {
  border: 1px ridge #4d4db3; }

.border-primary-bis-inset {
  border: 1px inset #4d4db3; }

.border-primary-bis-outset {
  border: 1px outset #4d4db3; }

.border-primary-bis-none {
  border: 1px none #4d4db3; }

.border-primary-bis-hidden {
  border: 1px hidden #4d4db3; }

.border-t-primary-bis {
  border-top: 1px solid #4d4db3; }

.border-t-primary-bis-dotted {
  border-top: 1px dotted #4d4db3; }

.border-t-primary-bis-dashed {
  border-top: 1px dashed #4d4db3; }

.border-t-primary-bis-solid {
  border-top: 1px solid #4d4db3; }

.border-t-primary-bis-double {
  border-top: 1px double #4d4db3; }

.border-t-primary-bis-groove {
  border-top: 1px groove #4d4db3; }

.border-t-primary-bis-ridge {
  border-top: 1px ridge #4d4db3; }

.border-t-primary-bis-inset {
  border-top: 1px inset #4d4db3; }

.border-t-primary-bis-outset {
  border-top: 1px outset #4d4db3; }

.border-t-primary-bis-none {
  border-top: 1px none #4d4db3; }

.border-t-primary-bis-hidden {
  border-top: 1px hidden #4d4db3; }

.border-r-primary-bis {
  border-right: 1px solid #4d4db3; }

.border-r-primary-bis-dotted {
  border-right: 1px dotted #4d4db3; }

.border-r-primary-bis-dashed {
  border-right: 1px dashed #4d4db3; }

.border-r-primary-bis-solid {
  border-right: 1px solid #4d4db3; }

.border-r-primary-bis-double {
  border-right: 1px double #4d4db3; }

.border-r-primary-bis-groove {
  border-right: 1px groove #4d4db3; }

.border-r-primary-bis-ridge {
  border-right: 1px ridge #4d4db3; }

.border-r-primary-bis-inset {
  border-right: 1px inset #4d4db3; }

.border-r-primary-bis-outset {
  border-right: 1px outset #4d4db3; }

.border-r-primary-bis-none {
  border-right: 1px none #4d4db3; }

.border-r-primary-bis-hidden {
  border-right: 1px hidden #4d4db3; }

.border-b-primary-bis {
  border-bottom: 1px solid #4d4db3; }

.border-b-primary-bis-dotted {
  border-bottom: 1px dotted #4d4db3; }

.border-b-primary-bis-dashed {
  border-bottom: 1px dashed #4d4db3; }

.border-b-primary-bis-solid {
  border-bottom: 1px solid #4d4db3; }

.border-b-primary-bis-double {
  border-bottom: 1px double #4d4db3; }

.border-b-primary-bis-groove {
  border-bottom: 1px groove #4d4db3; }

.border-b-primary-bis-ridge {
  border-bottom: 1px ridge #4d4db3; }

.border-b-primary-bis-inset {
  border-bottom: 1px inset #4d4db3; }

.border-b-primary-bis-outset {
  border-bottom: 1px outset #4d4db3; }

.border-b-primary-bis-none {
  border-bottom: 1px none #4d4db3; }

.border-b-primary-bis-hidden {
  border-bottom: 1px hidden #4d4db3; }

.border-l-primary-bis {
  border-left: 1px solid #4d4db3; }

.border-l-primary-bis-dotted {
  border-left: 1px dotted #4d4db3; }

.border-l-primary-bis-dashed {
  border-left: 1px dashed #4d4db3; }

.border-l-primary-bis-solid {
  border-left: 1px solid #4d4db3; }

.border-l-primary-bis-double {
  border-left: 1px double #4d4db3; }

.border-l-primary-bis-groove {
  border-left: 1px groove #4d4db3; }

.border-l-primary-bis-ridge {
  border-left: 1px ridge #4d4db3; }

.border-l-primary-bis-inset {
  border-left: 1px inset #4d4db3; }

.border-l-primary-bis-outset {
  border-left: 1px outset #4d4db3; }

.border-l-primary-bis-none {
  border-left: 1px none #4d4db3; }

.border-l-primary-bis-hidden {
  border-left: 1px hidden #4d4db3; }

.has-text-primary-ter {
  color: #7a7ab8; }

.has-background-primary-ter {
  background-color: #7a7ab8; }

.border-primary-ter {
  border: 1px solid #7a7ab8; }

.border-m-primary-ter:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #7a7ab8;
  transform: translateY(-50%); }

.border-primary-ter-dotted {
  border: 1px dotted #7a7ab8; }

.border-primary-ter-dashed {
  border: 1px dashed #7a7ab8; }

.border-primary-ter-solid {
  border: 1px solid #7a7ab8; }

.border-primary-ter-double {
  border: 1px double #7a7ab8; }

.border-primary-ter-groove {
  border: 1px groove #7a7ab8; }

.border-primary-ter-ridge {
  border: 1px ridge #7a7ab8; }

.border-primary-ter-inset {
  border: 1px inset #7a7ab8; }

.border-primary-ter-outset {
  border: 1px outset #7a7ab8; }

.border-primary-ter-none {
  border: 1px none #7a7ab8; }

.border-primary-ter-hidden {
  border: 1px hidden #7a7ab8; }

.border-t-primary-ter {
  border-top: 1px solid #7a7ab8; }

.border-t-primary-ter-dotted {
  border-top: 1px dotted #7a7ab8; }

.border-t-primary-ter-dashed {
  border-top: 1px dashed #7a7ab8; }

.border-t-primary-ter-solid {
  border-top: 1px solid #7a7ab8; }

.border-t-primary-ter-double {
  border-top: 1px double #7a7ab8; }

.border-t-primary-ter-groove {
  border-top: 1px groove #7a7ab8; }

.border-t-primary-ter-ridge {
  border-top: 1px ridge #7a7ab8; }

.border-t-primary-ter-inset {
  border-top: 1px inset #7a7ab8; }

.border-t-primary-ter-outset {
  border-top: 1px outset #7a7ab8; }

.border-t-primary-ter-none {
  border-top: 1px none #7a7ab8; }

.border-t-primary-ter-hidden {
  border-top: 1px hidden #7a7ab8; }

.border-r-primary-ter {
  border-right: 1px solid #7a7ab8; }

.border-r-primary-ter-dotted {
  border-right: 1px dotted #7a7ab8; }

.border-r-primary-ter-dashed {
  border-right: 1px dashed #7a7ab8; }

.border-r-primary-ter-solid {
  border-right: 1px solid #7a7ab8; }

.border-r-primary-ter-double {
  border-right: 1px double #7a7ab8; }

.border-r-primary-ter-groove {
  border-right: 1px groove #7a7ab8; }

.border-r-primary-ter-ridge {
  border-right: 1px ridge #7a7ab8; }

.border-r-primary-ter-inset {
  border-right: 1px inset #7a7ab8; }

.border-r-primary-ter-outset {
  border-right: 1px outset #7a7ab8; }

.border-r-primary-ter-none {
  border-right: 1px none #7a7ab8; }

.border-r-primary-ter-hidden {
  border-right: 1px hidden #7a7ab8; }

.border-b-primary-ter {
  border-bottom: 1px solid #7a7ab8; }

.border-b-primary-ter-dotted {
  border-bottom: 1px dotted #7a7ab8; }

.border-b-primary-ter-dashed {
  border-bottom: 1px dashed #7a7ab8; }

.border-b-primary-ter-solid {
  border-bottom: 1px solid #7a7ab8; }

.border-b-primary-ter-double {
  border-bottom: 1px double #7a7ab8; }

.border-b-primary-ter-groove {
  border-bottom: 1px groove #7a7ab8; }

.border-b-primary-ter-ridge {
  border-bottom: 1px ridge #7a7ab8; }

.border-b-primary-ter-inset {
  border-bottom: 1px inset #7a7ab8; }

.border-b-primary-ter-outset {
  border-bottom: 1px outset #7a7ab8; }

.border-b-primary-ter-none {
  border-bottom: 1px none #7a7ab8; }

.border-b-primary-ter-hidden {
  border-bottom: 1px hidden #7a7ab8; }

.border-l-primary-ter {
  border-left: 1px solid #7a7ab8; }

.border-l-primary-ter-dotted {
  border-left: 1px dotted #7a7ab8; }

.border-l-primary-ter-dashed {
  border-left: 1px dashed #7a7ab8; }

.border-l-primary-ter-solid {
  border-left: 1px solid #7a7ab8; }

.border-l-primary-ter-double {
  border-left: 1px double #7a7ab8; }

.border-l-primary-ter-groove {
  border-left: 1px groove #7a7ab8; }

.border-l-primary-ter-ridge {
  border-left: 1px ridge #7a7ab8; }

.border-l-primary-ter-inset {
  border-left: 1px inset #7a7ab8; }

.border-l-primary-ter-outset {
  border-left: 1px outset #7a7ab8; }

.border-l-primary-ter-none {
  border-left: 1px none #7a7ab8; }

.border-l-primary-ter-hidden {
  border-left: 1px hidden #7a7ab8; }

.has-text-primary-qua {
  color: #b9b9e9; }

.has-background-primary-qua {
  background-color: #b9b9e9; }

.border-primary-qua {
  border: 1px solid #b9b9e9; }

.border-m-primary-qua:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b9b9e9;
  transform: translateY(-50%); }

.border-primary-qua-dotted {
  border: 1px dotted #b9b9e9; }

.border-primary-qua-dashed {
  border: 1px dashed #b9b9e9; }

.border-primary-qua-solid {
  border: 1px solid #b9b9e9; }

.border-primary-qua-double {
  border: 1px double #b9b9e9; }

.border-primary-qua-groove {
  border: 1px groove #b9b9e9; }

.border-primary-qua-ridge {
  border: 1px ridge #b9b9e9; }

.border-primary-qua-inset {
  border: 1px inset #b9b9e9; }

.border-primary-qua-outset {
  border: 1px outset #b9b9e9; }

.border-primary-qua-none {
  border: 1px none #b9b9e9; }

.border-primary-qua-hidden {
  border: 1px hidden #b9b9e9; }

.border-t-primary-qua {
  border-top: 1px solid #b9b9e9; }

.border-t-primary-qua-dotted {
  border-top: 1px dotted #b9b9e9; }

.border-t-primary-qua-dashed {
  border-top: 1px dashed #b9b9e9; }

.border-t-primary-qua-solid {
  border-top: 1px solid #b9b9e9; }

.border-t-primary-qua-double {
  border-top: 1px double #b9b9e9; }

.border-t-primary-qua-groove {
  border-top: 1px groove #b9b9e9; }

.border-t-primary-qua-ridge {
  border-top: 1px ridge #b9b9e9; }

.border-t-primary-qua-inset {
  border-top: 1px inset #b9b9e9; }

.border-t-primary-qua-outset {
  border-top: 1px outset #b9b9e9; }

.border-t-primary-qua-none {
  border-top: 1px none #b9b9e9; }

.border-t-primary-qua-hidden {
  border-top: 1px hidden #b9b9e9; }

.border-r-primary-qua {
  border-right: 1px solid #b9b9e9; }

.border-r-primary-qua-dotted {
  border-right: 1px dotted #b9b9e9; }

.border-r-primary-qua-dashed {
  border-right: 1px dashed #b9b9e9; }

.border-r-primary-qua-solid {
  border-right: 1px solid #b9b9e9; }

.border-r-primary-qua-double {
  border-right: 1px double #b9b9e9; }

.border-r-primary-qua-groove {
  border-right: 1px groove #b9b9e9; }

.border-r-primary-qua-ridge {
  border-right: 1px ridge #b9b9e9; }

.border-r-primary-qua-inset {
  border-right: 1px inset #b9b9e9; }

.border-r-primary-qua-outset {
  border-right: 1px outset #b9b9e9; }

.border-r-primary-qua-none {
  border-right: 1px none #b9b9e9; }

.border-r-primary-qua-hidden {
  border-right: 1px hidden #b9b9e9; }

.border-b-primary-qua {
  border-bottom: 1px solid #b9b9e9; }

.border-b-primary-qua-dotted {
  border-bottom: 1px dotted #b9b9e9; }

.border-b-primary-qua-dashed {
  border-bottom: 1px dashed #b9b9e9; }

.border-b-primary-qua-solid {
  border-bottom: 1px solid #b9b9e9; }

.border-b-primary-qua-double {
  border-bottom: 1px double #b9b9e9; }

.border-b-primary-qua-groove {
  border-bottom: 1px groove #b9b9e9; }

.border-b-primary-qua-ridge {
  border-bottom: 1px ridge #b9b9e9; }

.border-b-primary-qua-inset {
  border-bottom: 1px inset #b9b9e9; }

.border-b-primary-qua-outset {
  border-bottom: 1px outset #b9b9e9; }

.border-b-primary-qua-none {
  border-bottom: 1px none #b9b9e9; }

.border-b-primary-qua-hidden {
  border-bottom: 1px hidden #b9b9e9; }

.border-l-primary-qua {
  border-left: 1px solid #b9b9e9; }

.border-l-primary-qua-dotted {
  border-left: 1px dotted #b9b9e9; }

.border-l-primary-qua-dashed {
  border-left: 1px dashed #b9b9e9; }

.border-l-primary-qua-solid {
  border-left: 1px solid #b9b9e9; }

.border-l-primary-qua-double {
  border-left: 1px double #b9b9e9; }

.border-l-primary-qua-groove {
  border-left: 1px groove #b9b9e9; }

.border-l-primary-qua-ridge {
  border-left: 1px ridge #b9b9e9; }

.border-l-primary-qua-inset {
  border-left: 1px inset #b9b9e9; }

.border-l-primary-qua-outset {
  border-left: 1px outset #b9b9e9; }

.border-l-primary-qua-none {
  border-left: 1px none #b9b9e9; }

.border-l-primary-qua-hidden {
  border-left: 1px hidden #b9b9e9; }

.has-text-primary-qui {
  color: #b8b8d6; }

.has-background-primary-qui {
  background-color: #b8b8d6; }

.border-primary-qui {
  border: 1px solid #b8b8d6; }

.border-m-primary-qui:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b8b8d6;
  transform: translateY(-50%); }

.border-primary-qui-dotted {
  border: 1px dotted #b8b8d6; }

.border-primary-qui-dashed {
  border: 1px dashed #b8b8d6; }

.border-primary-qui-solid {
  border: 1px solid #b8b8d6; }

.border-primary-qui-double {
  border: 1px double #b8b8d6; }

.border-primary-qui-groove {
  border: 1px groove #b8b8d6; }

.border-primary-qui-ridge {
  border: 1px ridge #b8b8d6; }

.border-primary-qui-inset {
  border: 1px inset #b8b8d6; }

.border-primary-qui-outset {
  border: 1px outset #b8b8d6; }

.border-primary-qui-none {
  border: 1px none #b8b8d6; }

.border-primary-qui-hidden {
  border: 1px hidden #b8b8d6; }

.border-t-primary-qui {
  border-top: 1px solid #b8b8d6; }

.border-t-primary-qui-dotted {
  border-top: 1px dotted #b8b8d6; }

.border-t-primary-qui-dashed {
  border-top: 1px dashed #b8b8d6; }

.border-t-primary-qui-solid {
  border-top: 1px solid #b8b8d6; }

.border-t-primary-qui-double {
  border-top: 1px double #b8b8d6; }

.border-t-primary-qui-groove {
  border-top: 1px groove #b8b8d6; }

.border-t-primary-qui-ridge {
  border-top: 1px ridge #b8b8d6; }

.border-t-primary-qui-inset {
  border-top: 1px inset #b8b8d6; }

.border-t-primary-qui-outset {
  border-top: 1px outset #b8b8d6; }

.border-t-primary-qui-none {
  border-top: 1px none #b8b8d6; }

.border-t-primary-qui-hidden {
  border-top: 1px hidden #b8b8d6; }

.border-r-primary-qui {
  border-right: 1px solid #b8b8d6; }

.border-r-primary-qui-dotted {
  border-right: 1px dotted #b8b8d6; }

.border-r-primary-qui-dashed {
  border-right: 1px dashed #b8b8d6; }

.border-r-primary-qui-solid {
  border-right: 1px solid #b8b8d6; }

.border-r-primary-qui-double {
  border-right: 1px double #b8b8d6; }

.border-r-primary-qui-groove {
  border-right: 1px groove #b8b8d6; }

.border-r-primary-qui-ridge {
  border-right: 1px ridge #b8b8d6; }

.border-r-primary-qui-inset {
  border-right: 1px inset #b8b8d6; }

.border-r-primary-qui-outset {
  border-right: 1px outset #b8b8d6; }

.border-r-primary-qui-none {
  border-right: 1px none #b8b8d6; }

.border-r-primary-qui-hidden {
  border-right: 1px hidden #b8b8d6; }

.border-b-primary-qui {
  border-bottom: 1px solid #b8b8d6; }

.border-b-primary-qui-dotted {
  border-bottom: 1px dotted #b8b8d6; }

.border-b-primary-qui-dashed {
  border-bottom: 1px dashed #b8b8d6; }

.border-b-primary-qui-solid {
  border-bottom: 1px solid #b8b8d6; }

.border-b-primary-qui-double {
  border-bottom: 1px double #b8b8d6; }

.border-b-primary-qui-groove {
  border-bottom: 1px groove #b8b8d6; }

.border-b-primary-qui-ridge {
  border-bottom: 1px ridge #b8b8d6; }

.border-b-primary-qui-inset {
  border-bottom: 1px inset #b8b8d6; }

.border-b-primary-qui-outset {
  border-bottom: 1px outset #b8b8d6; }

.border-b-primary-qui-none {
  border-bottom: 1px none #b8b8d6; }

.border-b-primary-qui-hidden {
  border-bottom: 1px hidden #b8b8d6; }

.border-l-primary-qui {
  border-left: 1px solid #b8b8d6; }

.border-l-primary-qui-dotted {
  border-left: 1px dotted #b8b8d6; }

.border-l-primary-qui-dashed {
  border-left: 1px dashed #b8b8d6; }

.border-l-primary-qui-solid {
  border-left: 1px solid #b8b8d6; }

.border-l-primary-qui-double {
  border-left: 1px double #b8b8d6; }

.border-l-primary-qui-groove {
  border-left: 1px groove #b8b8d6; }

.border-l-primary-qui-ridge {
  border-left: 1px ridge #b8b8d6; }

.border-l-primary-qui-inset {
  border-left: 1px inset #b8b8d6; }

.border-l-primary-qui-outset {
  border-left: 1px outset #b8b8d6; }

.border-l-primary-qui-none {
  border-left: 1px none #b8b8d6; }

.border-l-primary-qui-hidden {
  border-left: 1px hidden #b8b8d6; }

.has-text-primary-light {
  color: #dbdbf0; }

.has-background-primary-light {
  background-color: #dbdbf0; }

.border-primary-light {
  border: 1px solid #dbdbf0; }

.border-m-primary-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dbdbf0;
  transform: translateY(-50%); }

.border-primary-light-dotted {
  border: 1px dotted #dbdbf0; }

.border-primary-light-dashed {
  border: 1px dashed #dbdbf0; }

.border-primary-light-solid {
  border: 1px solid #dbdbf0; }

.border-primary-light-double {
  border: 1px double #dbdbf0; }

.border-primary-light-groove {
  border: 1px groove #dbdbf0; }

.border-primary-light-ridge {
  border: 1px ridge #dbdbf0; }

.border-primary-light-inset {
  border: 1px inset #dbdbf0; }

.border-primary-light-outset {
  border: 1px outset #dbdbf0; }

.border-primary-light-none {
  border: 1px none #dbdbf0; }

.border-primary-light-hidden {
  border: 1px hidden #dbdbf0; }

.border-t-primary-light {
  border-top: 1px solid #dbdbf0; }

.border-t-primary-light-dotted {
  border-top: 1px dotted #dbdbf0; }

.border-t-primary-light-dashed {
  border-top: 1px dashed #dbdbf0; }

.border-t-primary-light-solid {
  border-top: 1px solid #dbdbf0; }

.border-t-primary-light-double {
  border-top: 1px double #dbdbf0; }

.border-t-primary-light-groove {
  border-top: 1px groove #dbdbf0; }

.border-t-primary-light-ridge {
  border-top: 1px ridge #dbdbf0; }

.border-t-primary-light-inset {
  border-top: 1px inset #dbdbf0; }

.border-t-primary-light-outset {
  border-top: 1px outset #dbdbf0; }

.border-t-primary-light-none {
  border-top: 1px none #dbdbf0; }

.border-t-primary-light-hidden {
  border-top: 1px hidden #dbdbf0; }

.border-r-primary-light {
  border-right: 1px solid #dbdbf0; }

.border-r-primary-light-dotted {
  border-right: 1px dotted #dbdbf0; }

.border-r-primary-light-dashed {
  border-right: 1px dashed #dbdbf0; }

.border-r-primary-light-solid {
  border-right: 1px solid #dbdbf0; }

.border-r-primary-light-double {
  border-right: 1px double #dbdbf0; }

.border-r-primary-light-groove {
  border-right: 1px groove #dbdbf0; }

.border-r-primary-light-ridge {
  border-right: 1px ridge #dbdbf0; }

.border-r-primary-light-inset {
  border-right: 1px inset #dbdbf0; }

.border-r-primary-light-outset {
  border-right: 1px outset #dbdbf0; }

.border-r-primary-light-none {
  border-right: 1px none #dbdbf0; }

.border-r-primary-light-hidden {
  border-right: 1px hidden #dbdbf0; }

.border-b-primary-light {
  border-bottom: 1px solid #dbdbf0; }

.border-b-primary-light-dotted {
  border-bottom: 1px dotted #dbdbf0; }

.border-b-primary-light-dashed {
  border-bottom: 1px dashed #dbdbf0; }

.border-b-primary-light-solid {
  border-bottom: 1px solid #dbdbf0; }

.border-b-primary-light-double {
  border-bottom: 1px double #dbdbf0; }

.border-b-primary-light-groove {
  border-bottom: 1px groove #dbdbf0; }

.border-b-primary-light-ridge {
  border-bottom: 1px ridge #dbdbf0; }

.border-b-primary-light-inset {
  border-bottom: 1px inset #dbdbf0; }

.border-b-primary-light-outset {
  border-bottom: 1px outset #dbdbf0; }

.border-b-primary-light-none {
  border-bottom: 1px none #dbdbf0; }

.border-b-primary-light-hidden {
  border-bottom: 1px hidden #dbdbf0; }

.border-l-primary-light {
  border-left: 1px solid #dbdbf0; }

.border-l-primary-light-dotted {
  border-left: 1px dotted #dbdbf0; }

.border-l-primary-light-dashed {
  border-left: 1px dashed #dbdbf0; }

.border-l-primary-light-solid {
  border-left: 1px solid #dbdbf0; }

.border-l-primary-light-double {
  border-left: 1px double #dbdbf0; }

.border-l-primary-light-groove {
  border-left: 1px groove #dbdbf0; }

.border-l-primary-light-ridge {
  border-left: 1px ridge #dbdbf0; }

.border-l-primary-light-inset {
  border-left: 1px inset #dbdbf0; }

.border-l-primary-light-outset {
  border-left: 1px outset #dbdbf0; }

.border-l-primary-light-none {
  border-left: 1px none #dbdbf0; }

.border-l-primary-light-hidden {
  border-left: 1px hidden #dbdbf0; }

.has-text-primary-lighter {
  color: #ededf7; }

.has-background-primary-lighter {
  background-color: #ededf7; }

.border-primary-lighter {
  border: 1px solid #ededf7; }

.border-m-primary-lighter:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ededf7;
  transform: translateY(-50%); }

.border-primary-lighter-dotted {
  border: 1px dotted #ededf7; }

.border-primary-lighter-dashed {
  border: 1px dashed #ededf7; }

.border-primary-lighter-solid {
  border: 1px solid #ededf7; }

.border-primary-lighter-double {
  border: 1px double #ededf7; }

.border-primary-lighter-groove {
  border: 1px groove #ededf7; }

.border-primary-lighter-ridge {
  border: 1px ridge #ededf7; }

.border-primary-lighter-inset {
  border: 1px inset #ededf7; }

.border-primary-lighter-outset {
  border: 1px outset #ededf7; }

.border-primary-lighter-none {
  border: 1px none #ededf7; }

.border-primary-lighter-hidden {
  border: 1px hidden #ededf7; }

.border-t-primary-lighter {
  border-top: 1px solid #ededf7; }

.border-t-primary-lighter-dotted {
  border-top: 1px dotted #ededf7; }

.border-t-primary-lighter-dashed {
  border-top: 1px dashed #ededf7; }

.border-t-primary-lighter-solid {
  border-top: 1px solid #ededf7; }

.border-t-primary-lighter-double {
  border-top: 1px double #ededf7; }

.border-t-primary-lighter-groove {
  border-top: 1px groove #ededf7; }

.border-t-primary-lighter-ridge {
  border-top: 1px ridge #ededf7; }

.border-t-primary-lighter-inset {
  border-top: 1px inset #ededf7; }

.border-t-primary-lighter-outset {
  border-top: 1px outset #ededf7; }

.border-t-primary-lighter-none {
  border-top: 1px none #ededf7; }

.border-t-primary-lighter-hidden {
  border-top: 1px hidden #ededf7; }

.border-r-primary-lighter {
  border-right: 1px solid #ededf7; }

.border-r-primary-lighter-dotted {
  border-right: 1px dotted #ededf7; }

.border-r-primary-lighter-dashed {
  border-right: 1px dashed #ededf7; }

.border-r-primary-lighter-solid {
  border-right: 1px solid #ededf7; }

.border-r-primary-lighter-double {
  border-right: 1px double #ededf7; }

.border-r-primary-lighter-groove {
  border-right: 1px groove #ededf7; }

.border-r-primary-lighter-ridge {
  border-right: 1px ridge #ededf7; }

.border-r-primary-lighter-inset {
  border-right: 1px inset #ededf7; }

.border-r-primary-lighter-outset {
  border-right: 1px outset #ededf7; }

.border-r-primary-lighter-none {
  border-right: 1px none #ededf7; }

.border-r-primary-lighter-hidden {
  border-right: 1px hidden #ededf7; }

.border-b-primary-lighter {
  border-bottom: 1px solid #ededf7; }

.border-b-primary-lighter-dotted {
  border-bottom: 1px dotted #ededf7; }

.border-b-primary-lighter-dashed {
  border-bottom: 1px dashed #ededf7; }

.border-b-primary-lighter-solid {
  border-bottom: 1px solid #ededf7; }

.border-b-primary-lighter-double {
  border-bottom: 1px double #ededf7; }

.border-b-primary-lighter-groove {
  border-bottom: 1px groove #ededf7; }

.border-b-primary-lighter-ridge {
  border-bottom: 1px ridge #ededf7; }

.border-b-primary-lighter-inset {
  border-bottom: 1px inset #ededf7; }

.border-b-primary-lighter-outset {
  border-bottom: 1px outset #ededf7; }

.border-b-primary-lighter-none {
  border-bottom: 1px none #ededf7; }

.border-b-primary-lighter-hidden {
  border-bottom: 1px hidden #ededf7; }

.border-l-primary-lighter {
  border-left: 1px solid #ededf7; }

.border-l-primary-lighter-dotted {
  border-left: 1px dotted #ededf7; }

.border-l-primary-lighter-dashed {
  border-left: 1px dashed #ededf7; }

.border-l-primary-lighter-solid {
  border-left: 1px solid #ededf7; }

.border-l-primary-lighter-double {
  border-left: 1px double #ededf7; }

.border-l-primary-lighter-groove {
  border-left: 1px groove #ededf7; }

.border-l-primary-lighter-ridge {
  border-left: 1px ridge #ededf7; }

.border-l-primary-lighter-inset {
  border-left: 1px inset #ededf7; }

.border-l-primary-lighter-outset {
  border-left: 1px outset #ededf7; }

.border-l-primary-lighter-none {
  border-left: 1px none #ededf7; }

.border-l-primary-lighter-hidden {
  border-left: 1px hidden #ededf7; }

.has-text-primary-lighbis {
  color: #f4f4fa; }

.has-background-primary-lighbis {
  background-color: #f4f4fa; }

.border-primary-lighbis {
  border: 1px solid #f4f4fa; }

.border-m-primary-lighbis:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f4f4fa;
  transform: translateY(-50%); }

.border-primary-lighbis-dotted {
  border: 1px dotted #f4f4fa; }

.border-primary-lighbis-dashed {
  border: 1px dashed #f4f4fa; }

.border-primary-lighbis-solid {
  border: 1px solid #f4f4fa; }

.border-primary-lighbis-double {
  border: 1px double #f4f4fa; }

.border-primary-lighbis-groove {
  border: 1px groove #f4f4fa; }

.border-primary-lighbis-ridge {
  border: 1px ridge #f4f4fa; }

.border-primary-lighbis-inset {
  border: 1px inset #f4f4fa; }

.border-primary-lighbis-outset {
  border: 1px outset #f4f4fa; }

.border-primary-lighbis-none {
  border: 1px none #f4f4fa; }

.border-primary-lighbis-hidden {
  border: 1px hidden #f4f4fa; }

.border-t-primary-lighbis {
  border-top: 1px solid #f4f4fa; }

.border-t-primary-lighbis-dotted {
  border-top: 1px dotted #f4f4fa; }

.border-t-primary-lighbis-dashed {
  border-top: 1px dashed #f4f4fa; }

.border-t-primary-lighbis-solid {
  border-top: 1px solid #f4f4fa; }

.border-t-primary-lighbis-double {
  border-top: 1px double #f4f4fa; }

.border-t-primary-lighbis-groove {
  border-top: 1px groove #f4f4fa; }

.border-t-primary-lighbis-ridge {
  border-top: 1px ridge #f4f4fa; }

.border-t-primary-lighbis-inset {
  border-top: 1px inset #f4f4fa; }

.border-t-primary-lighbis-outset {
  border-top: 1px outset #f4f4fa; }

.border-t-primary-lighbis-none {
  border-top: 1px none #f4f4fa; }

.border-t-primary-lighbis-hidden {
  border-top: 1px hidden #f4f4fa; }

.border-r-primary-lighbis {
  border-right: 1px solid #f4f4fa; }

.border-r-primary-lighbis-dotted {
  border-right: 1px dotted #f4f4fa; }

.border-r-primary-lighbis-dashed {
  border-right: 1px dashed #f4f4fa; }

.border-r-primary-lighbis-solid {
  border-right: 1px solid #f4f4fa; }

.border-r-primary-lighbis-double {
  border-right: 1px double #f4f4fa; }

.border-r-primary-lighbis-groove {
  border-right: 1px groove #f4f4fa; }

.border-r-primary-lighbis-ridge {
  border-right: 1px ridge #f4f4fa; }

.border-r-primary-lighbis-inset {
  border-right: 1px inset #f4f4fa; }

.border-r-primary-lighbis-outset {
  border-right: 1px outset #f4f4fa; }

.border-r-primary-lighbis-none {
  border-right: 1px none #f4f4fa; }

.border-r-primary-lighbis-hidden {
  border-right: 1px hidden #f4f4fa; }

.border-b-primary-lighbis {
  border-bottom: 1px solid #f4f4fa; }

.border-b-primary-lighbis-dotted {
  border-bottom: 1px dotted #f4f4fa; }

.border-b-primary-lighbis-dashed {
  border-bottom: 1px dashed #f4f4fa; }

.border-b-primary-lighbis-solid {
  border-bottom: 1px solid #f4f4fa; }

.border-b-primary-lighbis-double {
  border-bottom: 1px double #f4f4fa; }

.border-b-primary-lighbis-groove {
  border-bottom: 1px groove #f4f4fa; }

.border-b-primary-lighbis-ridge {
  border-bottom: 1px ridge #f4f4fa; }

.border-b-primary-lighbis-inset {
  border-bottom: 1px inset #f4f4fa; }

.border-b-primary-lighbis-outset {
  border-bottom: 1px outset #f4f4fa; }

.border-b-primary-lighbis-none {
  border-bottom: 1px none #f4f4fa; }

.border-b-primary-lighbis-hidden {
  border-bottom: 1px hidden #f4f4fa; }

.border-l-primary-lighbis {
  border-left: 1px solid #f4f4fa; }

.border-l-primary-lighbis-dotted {
  border-left: 1px dotted #f4f4fa; }

.border-l-primary-lighbis-dashed {
  border-left: 1px dashed #f4f4fa; }

.border-l-primary-lighbis-solid {
  border-left: 1px solid #f4f4fa; }

.border-l-primary-lighbis-double {
  border-left: 1px double #f4f4fa; }

.border-l-primary-lighbis-groove {
  border-left: 1px groove #f4f4fa; }

.border-l-primary-lighbis-ridge {
  border-left: 1px ridge #f4f4fa; }

.border-l-primary-lighbis-inset {
  border-left: 1px inset #f4f4fa; }

.border-l-primary-lighbis-outset {
  border-left: 1px outset #f4f4fa; }

.border-l-primary-lighbis-none {
  border-left: 1px none #f4f4fa; }

.border-l-primary-lighbis-hidden {
  border-left: 1px hidden #f4f4fa; }

.has-text-secondary {
  color: #eb6666; }

.has-background-secondary {
  background-color: #eb6666; }

.border-secondary {
  border: 1px solid #eb6666; }

.border-m-secondary:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #eb6666;
  transform: translateY(-50%); }

.border-secondary-dotted {
  border: 1px dotted #eb6666; }

.border-secondary-dashed {
  border: 1px dashed #eb6666; }

.border-secondary-solid {
  border: 1px solid #eb6666; }

.border-secondary-double {
  border: 1px double #eb6666; }

.border-secondary-groove {
  border: 1px groove #eb6666; }

.border-secondary-ridge {
  border: 1px ridge #eb6666; }

.border-secondary-inset {
  border: 1px inset #eb6666; }

.border-secondary-outset {
  border: 1px outset #eb6666; }

.border-secondary-none {
  border: 1px none #eb6666; }

.border-secondary-hidden {
  border: 1px hidden #eb6666; }

.border-t-secondary {
  border-top: 1px solid #eb6666; }

.border-t-secondary-dotted {
  border-top: 1px dotted #eb6666; }

.border-t-secondary-dashed {
  border-top: 1px dashed #eb6666; }

.border-t-secondary-solid {
  border-top: 1px solid #eb6666; }

.border-t-secondary-double {
  border-top: 1px double #eb6666; }

.border-t-secondary-groove {
  border-top: 1px groove #eb6666; }

.border-t-secondary-ridge {
  border-top: 1px ridge #eb6666; }

.border-t-secondary-inset {
  border-top: 1px inset #eb6666; }

.border-t-secondary-outset {
  border-top: 1px outset #eb6666; }

.border-t-secondary-none {
  border-top: 1px none #eb6666; }

.border-t-secondary-hidden {
  border-top: 1px hidden #eb6666; }

.border-r-secondary {
  border-right: 1px solid #eb6666; }

.border-r-secondary-dotted {
  border-right: 1px dotted #eb6666; }

.border-r-secondary-dashed {
  border-right: 1px dashed #eb6666; }

.border-r-secondary-solid {
  border-right: 1px solid #eb6666; }

.border-r-secondary-double {
  border-right: 1px double #eb6666; }

.border-r-secondary-groove {
  border-right: 1px groove #eb6666; }

.border-r-secondary-ridge {
  border-right: 1px ridge #eb6666; }

.border-r-secondary-inset {
  border-right: 1px inset #eb6666; }

.border-r-secondary-outset {
  border-right: 1px outset #eb6666; }

.border-r-secondary-none {
  border-right: 1px none #eb6666; }

.border-r-secondary-hidden {
  border-right: 1px hidden #eb6666; }

.border-b-secondary {
  border-bottom: 1px solid #eb6666; }

.border-b-secondary-dotted {
  border-bottom: 1px dotted #eb6666; }

.border-b-secondary-dashed {
  border-bottom: 1px dashed #eb6666; }

.border-b-secondary-solid {
  border-bottom: 1px solid #eb6666; }

.border-b-secondary-double {
  border-bottom: 1px double #eb6666; }

.border-b-secondary-groove {
  border-bottom: 1px groove #eb6666; }

.border-b-secondary-ridge {
  border-bottom: 1px ridge #eb6666; }

.border-b-secondary-inset {
  border-bottom: 1px inset #eb6666; }

.border-b-secondary-outset {
  border-bottom: 1px outset #eb6666; }

.border-b-secondary-none {
  border-bottom: 1px none #eb6666; }

.border-b-secondary-hidden {
  border-bottom: 1px hidden #eb6666; }

.border-l-secondary {
  border-left: 1px solid #eb6666; }

.border-l-secondary-dotted {
  border-left: 1px dotted #eb6666; }

.border-l-secondary-dashed {
  border-left: 1px dashed #eb6666; }

.border-l-secondary-solid {
  border-left: 1px solid #eb6666; }

.border-l-secondary-double {
  border-left: 1px double #eb6666; }

.border-l-secondary-groove {
  border-left: 1px groove #eb6666; }

.border-l-secondary-ridge {
  border-left: 1px ridge #eb6666; }

.border-l-secondary-inset {
  border-left: 1px inset #eb6666; }

.border-l-secondary-outset {
  border-left: 1px outset #eb6666; }

.border-l-secondary-none {
  border-left: 1px none #eb6666; }

.border-l-secondary-hidden {
  border-left: 1px hidden #eb6666; }

.has-text-danger {
  color: #cc3333; }

.has-background-danger {
  background-color: #cc3333; }

.border-danger {
  border: 1px solid #cc3333; }

.border-m-danger:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #cc3333;
  transform: translateY(-50%); }

.border-danger-dotted {
  border: 1px dotted #cc3333; }

.border-danger-dashed {
  border: 1px dashed #cc3333; }

.border-danger-solid {
  border: 1px solid #cc3333; }

.border-danger-double {
  border: 1px double #cc3333; }

.border-danger-groove {
  border: 1px groove #cc3333; }

.border-danger-ridge {
  border: 1px ridge #cc3333; }

.border-danger-inset {
  border: 1px inset #cc3333; }

.border-danger-outset {
  border: 1px outset #cc3333; }

.border-danger-none {
  border: 1px none #cc3333; }

.border-danger-hidden {
  border: 1px hidden #cc3333; }

.border-t-danger {
  border-top: 1px solid #cc3333; }

.border-t-danger-dotted {
  border-top: 1px dotted #cc3333; }

.border-t-danger-dashed {
  border-top: 1px dashed #cc3333; }

.border-t-danger-solid {
  border-top: 1px solid #cc3333; }

.border-t-danger-double {
  border-top: 1px double #cc3333; }

.border-t-danger-groove {
  border-top: 1px groove #cc3333; }

.border-t-danger-ridge {
  border-top: 1px ridge #cc3333; }

.border-t-danger-inset {
  border-top: 1px inset #cc3333; }

.border-t-danger-outset {
  border-top: 1px outset #cc3333; }

.border-t-danger-none {
  border-top: 1px none #cc3333; }

.border-t-danger-hidden {
  border-top: 1px hidden #cc3333; }

.border-r-danger {
  border-right: 1px solid #cc3333; }

.border-r-danger-dotted {
  border-right: 1px dotted #cc3333; }

.border-r-danger-dashed {
  border-right: 1px dashed #cc3333; }

.border-r-danger-solid {
  border-right: 1px solid #cc3333; }

.border-r-danger-double {
  border-right: 1px double #cc3333; }

.border-r-danger-groove {
  border-right: 1px groove #cc3333; }

.border-r-danger-ridge {
  border-right: 1px ridge #cc3333; }

.border-r-danger-inset {
  border-right: 1px inset #cc3333; }

.border-r-danger-outset {
  border-right: 1px outset #cc3333; }

.border-r-danger-none {
  border-right: 1px none #cc3333; }

.border-r-danger-hidden {
  border-right: 1px hidden #cc3333; }

.border-b-danger {
  border-bottom: 1px solid #cc3333; }

.border-b-danger-dotted {
  border-bottom: 1px dotted #cc3333; }

.border-b-danger-dashed {
  border-bottom: 1px dashed #cc3333; }

.border-b-danger-solid {
  border-bottom: 1px solid #cc3333; }

.border-b-danger-double {
  border-bottom: 1px double #cc3333; }

.border-b-danger-groove {
  border-bottom: 1px groove #cc3333; }

.border-b-danger-ridge {
  border-bottom: 1px ridge #cc3333; }

.border-b-danger-inset {
  border-bottom: 1px inset #cc3333; }

.border-b-danger-outset {
  border-bottom: 1px outset #cc3333; }

.border-b-danger-none {
  border-bottom: 1px none #cc3333; }

.border-b-danger-hidden {
  border-bottom: 1px hidden #cc3333; }

.border-l-danger {
  border-left: 1px solid #cc3333; }

.border-l-danger-dotted {
  border-left: 1px dotted #cc3333; }

.border-l-danger-dashed {
  border-left: 1px dashed #cc3333; }

.border-l-danger-solid {
  border-left: 1px solid #cc3333; }

.border-l-danger-double {
  border-left: 1px double #cc3333; }

.border-l-danger-groove {
  border-left: 1px groove #cc3333; }

.border-l-danger-ridge {
  border-left: 1px ridge #cc3333; }

.border-l-danger-inset {
  border-left: 1px inset #cc3333; }

.border-l-danger-outset {
  border-left: 1px outset #cc3333; }

.border-l-danger-none {
  border-left: 1px none #cc3333; }

.border-l-danger-hidden {
  border-left: 1px hidden #cc3333; }

.has-text-danger-light {
  color: #f6dada; }

.has-background-danger-light {
  background-color: #f6dada; }

.border-danger-light {
  border: 1px solid #f6dada; }

.border-m-danger-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f6dada;
  transform: translateY(-50%); }

.border-danger-light-dotted {
  border: 1px dotted #f6dada; }

.border-danger-light-dashed {
  border: 1px dashed #f6dada; }

.border-danger-light-solid {
  border: 1px solid #f6dada; }

.border-danger-light-double {
  border: 1px double #f6dada; }

.border-danger-light-groove {
  border: 1px groove #f6dada; }

.border-danger-light-ridge {
  border: 1px ridge #f6dada; }

.border-danger-light-inset {
  border: 1px inset #f6dada; }

.border-danger-light-outset {
  border: 1px outset #f6dada; }

.border-danger-light-none {
  border: 1px none #f6dada; }

.border-danger-light-hidden {
  border: 1px hidden #f6dada; }

.border-t-danger-light {
  border-top: 1px solid #f6dada; }

.border-t-danger-light-dotted {
  border-top: 1px dotted #f6dada; }

.border-t-danger-light-dashed {
  border-top: 1px dashed #f6dada; }

.border-t-danger-light-solid {
  border-top: 1px solid #f6dada; }

.border-t-danger-light-double {
  border-top: 1px double #f6dada; }

.border-t-danger-light-groove {
  border-top: 1px groove #f6dada; }

.border-t-danger-light-ridge {
  border-top: 1px ridge #f6dada; }

.border-t-danger-light-inset {
  border-top: 1px inset #f6dada; }

.border-t-danger-light-outset {
  border-top: 1px outset #f6dada; }

.border-t-danger-light-none {
  border-top: 1px none #f6dada; }

.border-t-danger-light-hidden {
  border-top: 1px hidden #f6dada; }

.border-r-danger-light {
  border-right: 1px solid #f6dada; }

.border-r-danger-light-dotted {
  border-right: 1px dotted #f6dada; }

.border-r-danger-light-dashed {
  border-right: 1px dashed #f6dada; }

.border-r-danger-light-solid {
  border-right: 1px solid #f6dada; }

.border-r-danger-light-double {
  border-right: 1px double #f6dada; }

.border-r-danger-light-groove {
  border-right: 1px groove #f6dada; }

.border-r-danger-light-ridge {
  border-right: 1px ridge #f6dada; }

.border-r-danger-light-inset {
  border-right: 1px inset #f6dada; }

.border-r-danger-light-outset {
  border-right: 1px outset #f6dada; }

.border-r-danger-light-none {
  border-right: 1px none #f6dada; }

.border-r-danger-light-hidden {
  border-right: 1px hidden #f6dada; }

.border-b-danger-light {
  border-bottom: 1px solid #f6dada; }

.border-b-danger-light-dotted {
  border-bottom: 1px dotted #f6dada; }

.border-b-danger-light-dashed {
  border-bottom: 1px dashed #f6dada; }

.border-b-danger-light-solid {
  border-bottom: 1px solid #f6dada; }

.border-b-danger-light-double {
  border-bottom: 1px double #f6dada; }

.border-b-danger-light-groove {
  border-bottom: 1px groove #f6dada; }

.border-b-danger-light-ridge {
  border-bottom: 1px ridge #f6dada; }

.border-b-danger-light-inset {
  border-bottom: 1px inset #f6dada; }

.border-b-danger-light-outset {
  border-bottom: 1px outset #f6dada; }

.border-b-danger-light-none {
  border-bottom: 1px none #f6dada; }

.border-b-danger-light-hidden {
  border-bottom: 1px hidden #f6dada; }

.border-l-danger-light {
  border-left: 1px solid #f6dada; }

.border-l-danger-light-dotted {
  border-left: 1px dotted #f6dada; }

.border-l-danger-light-dashed {
  border-left: 1px dashed #f6dada; }

.border-l-danger-light-solid {
  border-left: 1px solid #f6dada; }

.border-l-danger-light-double {
  border-left: 1px double #f6dada; }

.border-l-danger-light-groove {
  border-left: 1px groove #f6dada; }

.border-l-danger-light-ridge {
  border-left: 1px ridge #f6dada; }

.border-l-danger-light-inset {
  border-left: 1px inset #f6dada; }

.border-l-danger-light-outset {
  border-left: 1px outset #f6dada; }

.border-l-danger-light-none {
  border-left: 1px none #f6dada; }

.border-l-danger-light-hidden {
  border-left: 1px hidden #f6dada; }

.has-text-warning {
  color: #ffd500; }

.has-background-warning {
  background-color: #ffd500; }

.border-warning {
  border: 1px solid #ffd500; }

.border-m-warning:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ffd500;
  transform: translateY(-50%); }

.border-warning-dotted {
  border: 1px dotted #ffd500; }

.border-warning-dashed {
  border: 1px dashed #ffd500; }

.border-warning-solid {
  border: 1px solid #ffd500; }

.border-warning-double {
  border: 1px double #ffd500; }

.border-warning-groove {
  border: 1px groove #ffd500; }

.border-warning-ridge {
  border: 1px ridge #ffd500; }

.border-warning-inset {
  border: 1px inset #ffd500; }

.border-warning-outset {
  border: 1px outset #ffd500; }

.border-warning-none {
  border: 1px none #ffd500; }

.border-warning-hidden {
  border: 1px hidden #ffd500; }

.border-t-warning {
  border-top: 1px solid #ffd500; }

.border-t-warning-dotted {
  border-top: 1px dotted #ffd500; }

.border-t-warning-dashed {
  border-top: 1px dashed #ffd500; }

.border-t-warning-solid {
  border-top: 1px solid #ffd500; }

.border-t-warning-double {
  border-top: 1px double #ffd500; }

.border-t-warning-groove {
  border-top: 1px groove #ffd500; }

.border-t-warning-ridge {
  border-top: 1px ridge #ffd500; }

.border-t-warning-inset {
  border-top: 1px inset #ffd500; }

.border-t-warning-outset {
  border-top: 1px outset #ffd500; }

.border-t-warning-none {
  border-top: 1px none #ffd500; }

.border-t-warning-hidden {
  border-top: 1px hidden #ffd500; }

.border-r-warning {
  border-right: 1px solid #ffd500; }

.border-r-warning-dotted {
  border-right: 1px dotted #ffd500; }

.border-r-warning-dashed {
  border-right: 1px dashed #ffd500; }

.border-r-warning-solid {
  border-right: 1px solid #ffd500; }

.border-r-warning-double {
  border-right: 1px double #ffd500; }

.border-r-warning-groove {
  border-right: 1px groove #ffd500; }

.border-r-warning-ridge {
  border-right: 1px ridge #ffd500; }

.border-r-warning-inset {
  border-right: 1px inset #ffd500; }

.border-r-warning-outset {
  border-right: 1px outset #ffd500; }

.border-r-warning-none {
  border-right: 1px none #ffd500; }

.border-r-warning-hidden {
  border-right: 1px hidden #ffd500; }

.border-b-warning {
  border-bottom: 1px solid #ffd500; }

.border-b-warning-dotted {
  border-bottom: 1px dotted #ffd500; }

.border-b-warning-dashed {
  border-bottom: 1px dashed #ffd500; }

.border-b-warning-solid {
  border-bottom: 1px solid #ffd500; }

.border-b-warning-double {
  border-bottom: 1px double #ffd500; }

.border-b-warning-groove {
  border-bottom: 1px groove #ffd500; }

.border-b-warning-ridge {
  border-bottom: 1px ridge #ffd500; }

.border-b-warning-inset {
  border-bottom: 1px inset #ffd500; }

.border-b-warning-outset {
  border-bottom: 1px outset #ffd500; }

.border-b-warning-none {
  border-bottom: 1px none #ffd500; }

.border-b-warning-hidden {
  border-bottom: 1px hidden #ffd500; }

.border-l-warning {
  border-left: 1px solid #ffd500; }

.border-l-warning-dotted {
  border-left: 1px dotted #ffd500; }

.border-l-warning-dashed {
  border-left: 1px dashed #ffd500; }

.border-l-warning-solid {
  border-left: 1px solid #ffd500; }

.border-l-warning-double {
  border-left: 1px double #ffd500; }

.border-l-warning-groove {
  border-left: 1px groove #ffd500; }

.border-l-warning-ridge {
  border-left: 1px ridge #ffd500; }

.border-l-warning-inset {
  border-left: 1px inset #ffd500; }

.border-l-warning-outset {
  border-left: 1px outset #ffd500; }

.border-l-warning-none {
  border-left: 1px none #ffd500; }

.border-l-warning-hidden {
  border-left: 1px hidden #ffd500; }

.has-text-success {
  color: #66cc33; }

.has-background-success {
  background-color: #66cc33; }

.border-success {
  border: 1px solid #66cc33; }

.border-m-success:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #66cc33;
  transform: translateY(-50%); }

.border-success-dotted {
  border: 1px dotted #66cc33; }

.border-success-dashed {
  border: 1px dashed #66cc33; }

.border-success-solid {
  border: 1px solid #66cc33; }

.border-success-double {
  border: 1px double #66cc33; }

.border-success-groove {
  border: 1px groove #66cc33; }

.border-success-ridge {
  border: 1px ridge #66cc33; }

.border-success-inset {
  border: 1px inset #66cc33; }

.border-success-outset {
  border: 1px outset #66cc33; }

.border-success-none {
  border: 1px none #66cc33; }

.border-success-hidden {
  border: 1px hidden #66cc33; }

.border-t-success {
  border-top: 1px solid #66cc33; }

.border-t-success-dotted {
  border-top: 1px dotted #66cc33; }

.border-t-success-dashed {
  border-top: 1px dashed #66cc33; }

.border-t-success-solid {
  border-top: 1px solid #66cc33; }

.border-t-success-double {
  border-top: 1px double #66cc33; }

.border-t-success-groove {
  border-top: 1px groove #66cc33; }

.border-t-success-ridge {
  border-top: 1px ridge #66cc33; }

.border-t-success-inset {
  border-top: 1px inset #66cc33; }

.border-t-success-outset {
  border-top: 1px outset #66cc33; }

.border-t-success-none {
  border-top: 1px none #66cc33; }

.border-t-success-hidden {
  border-top: 1px hidden #66cc33; }

.border-r-success {
  border-right: 1px solid #66cc33; }

.border-r-success-dotted {
  border-right: 1px dotted #66cc33; }

.border-r-success-dashed {
  border-right: 1px dashed #66cc33; }

.border-r-success-solid {
  border-right: 1px solid #66cc33; }

.border-r-success-double {
  border-right: 1px double #66cc33; }

.border-r-success-groove {
  border-right: 1px groove #66cc33; }

.border-r-success-ridge {
  border-right: 1px ridge #66cc33; }

.border-r-success-inset {
  border-right: 1px inset #66cc33; }

.border-r-success-outset {
  border-right: 1px outset #66cc33; }

.border-r-success-none {
  border-right: 1px none #66cc33; }

.border-r-success-hidden {
  border-right: 1px hidden #66cc33; }

.border-b-success {
  border-bottom: 1px solid #66cc33; }

.border-b-success-dotted {
  border-bottom: 1px dotted #66cc33; }

.border-b-success-dashed {
  border-bottom: 1px dashed #66cc33; }

.border-b-success-solid {
  border-bottom: 1px solid #66cc33; }

.border-b-success-double {
  border-bottom: 1px double #66cc33; }

.border-b-success-groove {
  border-bottom: 1px groove #66cc33; }

.border-b-success-ridge {
  border-bottom: 1px ridge #66cc33; }

.border-b-success-inset {
  border-bottom: 1px inset #66cc33; }

.border-b-success-outset {
  border-bottom: 1px outset #66cc33; }

.border-b-success-none {
  border-bottom: 1px none #66cc33; }

.border-b-success-hidden {
  border-bottom: 1px hidden #66cc33; }

.border-l-success {
  border-left: 1px solid #66cc33; }

.border-l-success-dotted {
  border-left: 1px dotted #66cc33; }

.border-l-success-dashed {
  border-left: 1px dashed #66cc33; }

.border-l-success-solid {
  border-left: 1px solid #66cc33; }

.border-l-success-double {
  border-left: 1px double #66cc33; }

.border-l-success-groove {
  border-left: 1px groove #66cc33; }

.border-l-success-ridge {
  border-left: 1px ridge #66cc33; }

.border-l-success-inset {
  border-left: 1px inset #66cc33; }

.border-l-success-outset {
  border-left: 1px outset #66cc33; }

.border-l-success-none {
  border-left: 1px none #66cc33; }

.border-l-success-hidden {
  border-left: 1px hidden #66cc33; }

.has-text-success-light {
  color: #dbffe0; }

.has-background-success-light {
  background-color: #dbffe0; }

.border-success-light {
  border: 1px solid #dbffe0; }

.border-m-success-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dbffe0;
  transform: translateY(-50%); }

.border-success-light-dotted {
  border: 1px dotted #dbffe0; }

.border-success-light-dashed {
  border: 1px dashed #dbffe0; }

.border-success-light-solid {
  border: 1px solid #dbffe0; }

.border-success-light-double {
  border: 1px double #dbffe0; }

.border-success-light-groove {
  border: 1px groove #dbffe0; }

.border-success-light-ridge {
  border: 1px ridge #dbffe0; }

.border-success-light-inset {
  border: 1px inset #dbffe0; }

.border-success-light-outset {
  border: 1px outset #dbffe0; }

.border-success-light-none {
  border: 1px none #dbffe0; }

.border-success-light-hidden {
  border: 1px hidden #dbffe0; }

.border-t-success-light {
  border-top: 1px solid #dbffe0; }

.border-t-success-light-dotted {
  border-top: 1px dotted #dbffe0; }

.border-t-success-light-dashed {
  border-top: 1px dashed #dbffe0; }

.border-t-success-light-solid {
  border-top: 1px solid #dbffe0; }

.border-t-success-light-double {
  border-top: 1px double #dbffe0; }

.border-t-success-light-groove {
  border-top: 1px groove #dbffe0; }

.border-t-success-light-ridge {
  border-top: 1px ridge #dbffe0; }

.border-t-success-light-inset {
  border-top: 1px inset #dbffe0; }

.border-t-success-light-outset {
  border-top: 1px outset #dbffe0; }

.border-t-success-light-none {
  border-top: 1px none #dbffe0; }

.border-t-success-light-hidden {
  border-top: 1px hidden #dbffe0; }

.border-r-success-light {
  border-right: 1px solid #dbffe0; }

.border-r-success-light-dotted {
  border-right: 1px dotted #dbffe0; }

.border-r-success-light-dashed {
  border-right: 1px dashed #dbffe0; }

.border-r-success-light-solid {
  border-right: 1px solid #dbffe0; }

.border-r-success-light-double {
  border-right: 1px double #dbffe0; }

.border-r-success-light-groove {
  border-right: 1px groove #dbffe0; }

.border-r-success-light-ridge {
  border-right: 1px ridge #dbffe0; }

.border-r-success-light-inset {
  border-right: 1px inset #dbffe0; }

.border-r-success-light-outset {
  border-right: 1px outset #dbffe0; }

.border-r-success-light-none {
  border-right: 1px none #dbffe0; }

.border-r-success-light-hidden {
  border-right: 1px hidden #dbffe0; }

.border-b-success-light {
  border-bottom: 1px solid #dbffe0; }

.border-b-success-light-dotted {
  border-bottom: 1px dotted #dbffe0; }

.border-b-success-light-dashed {
  border-bottom: 1px dashed #dbffe0; }

.border-b-success-light-solid {
  border-bottom: 1px solid #dbffe0; }

.border-b-success-light-double {
  border-bottom: 1px double #dbffe0; }

.border-b-success-light-groove {
  border-bottom: 1px groove #dbffe0; }

.border-b-success-light-ridge {
  border-bottom: 1px ridge #dbffe0; }

.border-b-success-light-inset {
  border-bottom: 1px inset #dbffe0; }

.border-b-success-light-outset {
  border-bottom: 1px outset #dbffe0; }

.border-b-success-light-none {
  border-bottom: 1px none #dbffe0; }

.border-b-success-light-hidden {
  border-bottom: 1px hidden #dbffe0; }

.border-l-success-light {
  border-left: 1px solid #dbffe0; }

.border-l-success-light-dotted {
  border-left: 1px dotted #dbffe0; }

.border-l-success-light-dashed {
  border-left: 1px dashed #dbffe0; }

.border-l-success-light-solid {
  border-left: 1px solid #dbffe0; }

.border-l-success-light-double {
  border-left: 1px double #dbffe0; }

.border-l-success-light-groove {
  border-left: 1px groove #dbffe0; }

.border-l-success-light-ridge {
  border-left: 1px ridge #dbffe0; }

.border-l-success-light-inset {
  border-left: 1px inset #dbffe0; }

.border-l-success-light-outset {
  border-left: 1px outset #dbffe0; }

.border-l-success-light-none {
  border-left: 1px none #dbffe0; }

.border-l-success-light-hidden {
  border-left: 1px hidden #dbffe0; }

.has-text-info {
  color: #209fee; }

.has-background-info {
  background-color: #209fee; }

.border-info {
  border: 1px solid #209fee; }

.border-m-info:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #209fee;
  transform: translateY(-50%); }

.border-info-dotted {
  border: 1px dotted #209fee; }

.border-info-dashed {
  border: 1px dashed #209fee; }

.border-info-solid {
  border: 1px solid #209fee; }

.border-info-double {
  border: 1px double #209fee; }

.border-info-groove {
  border: 1px groove #209fee; }

.border-info-ridge {
  border: 1px ridge #209fee; }

.border-info-inset {
  border: 1px inset #209fee; }

.border-info-outset {
  border: 1px outset #209fee; }

.border-info-none {
  border: 1px none #209fee; }

.border-info-hidden {
  border: 1px hidden #209fee; }

.border-t-info {
  border-top: 1px solid #209fee; }

.border-t-info-dotted {
  border-top: 1px dotted #209fee; }

.border-t-info-dashed {
  border-top: 1px dashed #209fee; }

.border-t-info-solid {
  border-top: 1px solid #209fee; }

.border-t-info-double {
  border-top: 1px double #209fee; }

.border-t-info-groove {
  border-top: 1px groove #209fee; }

.border-t-info-ridge {
  border-top: 1px ridge #209fee; }

.border-t-info-inset {
  border-top: 1px inset #209fee; }

.border-t-info-outset {
  border-top: 1px outset #209fee; }

.border-t-info-none {
  border-top: 1px none #209fee; }

.border-t-info-hidden {
  border-top: 1px hidden #209fee; }

.border-r-info {
  border-right: 1px solid #209fee; }

.border-r-info-dotted {
  border-right: 1px dotted #209fee; }

.border-r-info-dashed {
  border-right: 1px dashed #209fee; }

.border-r-info-solid {
  border-right: 1px solid #209fee; }

.border-r-info-double {
  border-right: 1px double #209fee; }

.border-r-info-groove {
  border-right: 1px groove #209fee; }

.border-r-info-ridge {
  border-right: 1px ridge #209fee; }

.border-r-info-inset {
  border-right: 1px inset #209fee; }

.border-r-info-outset {
  border-right: 1px outset #209fee; }

.border-r-info-none {
  border-right: 1px none #209fee; }

.border-r-info-hidden {
  border-right: 1px hidden #209fee; }

.border-b-info {
  border-bottom: 1px solid #209fee; }

.border-b-info-dotted {
  border-bottom: 1px dotted #209fee; }

.border-b-info-dashed {
  border-bottom: 1px dashed #209fee; }

.border-b-info-solid {
  border-bottom: 1px solid #209fee; }

.border-b-info-double {
  border-bottom: 1px double #209fee; }

.border-b-info-groove {
  border-bottom: 1px groove #209fee; }

.border-b-info-ridge {
  border-bottom: 1px ridge #209fee; }

.border-b-info-inset {
  border-bottom: 1px inset #209fee; }

.border-b-info-outset {
  border-bottom: 1px outset #209fee; }

.border-b-info-none {
  border-bottom: 1px none #209fee; }

.border-b-info-hidden {
  border-bottom: 1px hidden #209fee; }

.border-l-info {
  border-left: 1px solid #209fee; }

.border-l-info-dotted {
  border-left: 1px dotted #209fee; }

.border-l-info-dashed {
  border-left: 1px dashed #209fee; }

.border-l-info-solid {
  border-left: 1px solid #209fee; }

.border-l-info-double {
  border-left: 1px double #209fee; }

.border-l-info-groove {
  border-left: 1px groove #209fee; }

.border-l-info-ridge {
  border-left: 1px ridge #209fee; }

.border-l-info-inset {
  border-left: 1px inset #209fee; }

.border-l-info-outset {
  border-left: 1px outset #209fee; }

.border-l-info-none {
  border-left: 1px none #209fee; }

.border-l-info-hidden {
  border-left: 1px hidden #209fee; }

.has-text-black {
  color: #0a0a0a; }

.has-background-black {
  background-color: #0a0a0a; }

.border-black {
  border: 1px solid #0a0a0a; }

.border-black-dotted {
  border: 1px dotted #0a0a0a; }

.border-black-dashed {
  border: 1px dashed #0a0a0a; }

.border-black-solid {
  border: 1px solid #0a0a0a; }

.border-black-double {
  border: 1px double #0a0a0a; }

.border-black-groove {
  border: 1px groove #0a0a0a; }

.border-black-ridge {
  border: 1px ridge #0a0a0a; }

.border-black-inset {
  border: 1px inset #0a0a0a; }

.border-black-outset {
  border: 1px outset #0a0a0a; }

.border-black-none {
  border: 1px none #0a0a0a; }

.border-black-hidden {
  border: 1px hidden #0a0a0a; }

.border-t-black {
  border-top: 1px solid #0a0a0a; }

.border-t-black-dotted {
  border-top: 1px dotted #0a0a0a; }

.border-t-black-dashed {
  border-top: 1px dashed #0a0a0a; }

.border-t-black-solid {
  border-top: 1px solid #0a0a0a; }

.border-t-black-double {
  border-top: 1px double #0a0a0a; }

.border-t-black-groove {
  border-top: 1px groove #0a0a0a; }

.border-t-black-ridge {
  border-top: 1px ridge #0a0a0a; }

.border-t-black-inset {
  border-top: 1px inset #0a0a0a; }

.border-t-black-outset {
  border-top: 1px outset #0a0a0a; }

.border-t-black-none {
  border-top: 1px none #0a0a0a; }

.border-t-black-hidden {
  border-top: 1px hidden #0a0a0a; }

.border-r-black {
  border-right: 1px solid #0a0a0a; }

.border-r-black-dotted {
  border-right: 1px dotted #0a0a0a; }

.border-r-black-dashed {
  border-right: 1px dashed #0a0a0a; }

.border-r-black-solid {
  border-right: 1px solid #0a0a0a; }

.border-r-black-double {
  border-right: 1px double #0a0a0a; }

.border-r-black-groove {
  border-right: 1px groove #0a0a0a; }

.border-r-black-ridge {
  border-right: 1px ridge #0a0a0a; }

.border-r-black-inset {
  border-right: 1px inset #0a0a0a; }

.border-r-black-outset {
  border-right: 1px outset #0a0a0a; }

.border-r-black-none {
  border-right: 1px none #0a0a0a; }

.border-r-black-hidden {
  border-right: 1px hidden #0a0a0a; }

.border-b-black {
  border-bottom: 1px solid #0a0a0a; }

.border-b-black-dotted {
  border-bottom: 1px dotted #0a0a0a; }

.border-b-black-dashed {
  border-bottom: 1px dashed #0a0a0a; }

.border-b-black-solid {
  border-bottom: 1px solid #0a0a0a; }

.border-b-black-double {
  border-bottom: 1px double #0a0a0a; }

.border-b-black-groove {
  border-bottom: 1px groove #0a0a0a; }

.border-b-black-ridge {
  border-bottom: 1px ridge #0a0a0a; }

.border-b-black-inset {
  border-bottom: 1px inset #0a0a0a; }

.border-b-black-outset {
  border-bottom: 1px outset #0a0a0a; }

.border-b-black-none {
  border-bottom: 1px none #0a0a0a; }

.border-b-black-hidden {
  border-bottom: 1px hidden #0a0a0a; }

.border-l-black {
  border-left: 1px solid #0a0a0a; }

.border-l-black-dotted {
  border-left: 1px dotted #0a0a0a; }

.border-l-black-dashed {
  border-left: 1px dashed #0a0a0a; }

.border-l-black-solid {
  border-left: 1px solid #0a0a0a; }

.border-l-black-double {
  border-left: 1px double #0a0a0a; }

.border-l-black-groove {
  border-left: 1px groove #0a0a0a; }

.border-l-black-ridge {
  border-left: 1px ridge #0a0a0a; }

.border-l-black-inset {
  border-left: 1px inset #0a0a0a; }

.border-l-black-outset {
  border-left: 1px outset #0a0a0a; }

.border-l-black-none {
  border-left: 1px none #0a0a0a; }

.border-l-black-hidden {
  border-left: 1px hidden #0a0a0a; }

.has-text-black-bis {
  color: #121212; }

.has-background-black-bis {
  background-color: #121212; }

.border-black-bis {
  border: 1px solid #121212; }

.border-black-bis-dotted {
  border: 1px dotted #121212; }

.border-black-bis-dashed {
  border: 1px dashed #121212; }

.border-black-bis-solid {
  border: 1px solid #121212; }

.border-black-bis-double {
  border: 1px double #121212; }

.border-black-bis-groove {
  border: 1px groove #121212; }

.border-black-bis-ridge {
  border: 1px ridge #121212; }

.border-black-bis-inset {
  border: 1px inset #121212; }

.border-black-bis-outset {
  border: 1px outset #121212; }

.border-black-bis-none {
  border: 1px none #121212; }

.border-black-bis-hidden {
  border: 1px hidden #121212; }

.border-t-black-bis {
  border-top: 1px solid #121212; }

.border-t-black-bis-dotted {
  border-top: 1px dotted #121212; }

.border-t-black-bis-dashed {
  border-top: 1px dashed #121212; }

.border-t-black-bis-solid {
  border-top: 1px solid #121212; }

.border-t-black-bis-double {
  border-top: 1px double #121212; }

.border-t-black-bis-groove {
  border-top: 1px groove #121212; }

.border-t-black-bis-ridge {
  border-top: 1px ridge #121212; }

.border-t-black-bis-inset {
  border-top: 1px inset #121212; }

.border-t-black-bis-outset {
  border-top: 1px outset #121212; }

.border-t-black-bis-none {
  border-top: 1px none #121212; }

.border-t-black-bis-hidden {
  border-top: 1px hidden #121212; }

.border-r-black-bis {
  border-right: 1px solid #121212; }

.border-r-black-bis-dotted {
  border-right: 1px dotted #121212; }

.border-r-black-bis-dashed {
  border-right: 1px dashed #121212; }

.border-r-black-bis-solid {
  border-right: 1px solid #121212; }

.border-r-black-bis-double {
  border-right: 1px double #121212; }

.border-r-black-bis-groove {
  border-right: 1px groove #121212; }

.border-r-black-bis-ridge {
  border-right: 1px ridge #121212; }

.border-r-black-bis-inset {
  border-right: 1px inset #121212; }

.border-r-black-bis-outset {
  border-right: 1px outset #121212; }

.border-r-black-bis-none {
  border-right: 1px none #121212; }

.border-r-black-bis-hidden {
  border-right: 1px hidden #121212; }

.border-b-black-bis {
  border-bottom: 1px solid #121212; }

.border-b-black-bis-dotted {
  border-bottom: 1px dotted #121212; }

.border-b-black-bis-dashed {
  border-bottom: 1px dashed #121212; }

.border-b-black-bis-solid {
  border-bottom: 1px solid #121212; }

.border-b-black-bis-double {
  border-bottom: 1px double #121212; }

.border-b-black-bis-groove {
  border-bottom: 1px groove #121212; }

.border-b-black-bis-ridge {
  border-bottom: 1px ridge #121212; }

.border-b-black-bis-inset {
  border-bottom: 1px inset #121212; }

.border-b-black-bis-outset {
  border-bottom: 1px outset #121212; }

.border-b-black-bis-none {
  border-bottom: 1px none #121212; }

.border-b-black-bis-hidden {
  border-bottom: 1px hidden #121212; }

.border-l-black-bis {
  border-left: 1px solid #121212; }

.border-l-black-bis-dotted {
  border-left: 1px dotted #121212; }

.border-l-black-bis-dashed {
  border-left: 1px dashed #121212; }

.border-l-black-bis-solid {
  border-left: 1px solid #121212; }

.border-l-black-bis-double {
  border-left: 1px double #121212; }

.border-l-black-bis-groove {
  border-left: 1px groove #121212; }

.border-l-black-bis-ridge {
  border-left: 1px ridge #121212; }

.border-l-black-bis-inset {
  border-left: 1px inset #121212; }

.border-l-black-bis-outset {
  border-left: 1px outset #121212; }

.border-l-black-bis-none {
  border-left: 1px none #121212; }

.border-l-black-bis-hidden {
  border-left: 1px hidden #121212; }

.has-text-black-ter {
  color: #242424; }

.has-background-black-ter {
  background-color: #242424; }

.border-black-ter {
  border: 1px solid #242424; }

.border-black-ter-dotted {
  border: 1px dotted #242424; }

.border-black-ter-dashed {
  border: 1px dashed #242424; }

.border-black-ter-solid {
  border: 1px solid #242424; }

.border-black-ter-double {
  border: 1px double #242424; }

.border-black-ter-groove {
  border: 1px groove #242424; }

.border-black-ter-ridge {
  border: 1px ridge #242424; }

.border-black-ter-inset {
  border: 1px inset #242424; }

.border-black-ter-outset {
  border: 1px outset #242424; }

.border-black-ter-none {
  border: 1px none #242424; }

.border-black-ter-hidden {
  border: 1px hidden #242424; }

.border-t-black-ter {
  border-top: 1px solid #242424; }

.border-t-black-ter-dotted {
  border-top: 1px dotted #242424; }

.border-t-black-ter-dashed {
  border-top: 1px dashed #242424; }

.border-t-black-ter-solid {
  border-top: 1px solid #242424; }

.border-t-black-ter-double {
  border-top: 1px double #242424; }

.border-t-black-ter-groove {
  border-top: 1px groove #242424; }

.border-t-black-ter-ridge {
  border-top: 1px ridge #242424; }

.border-t-black-ter-inset {
  border-top: 1px inset #242424; }

.border-t-black-ter-outset {
  border-top: 1px outset #242424; }

.border-t-black-ter-none {
  border-top: 1px none #242424; }

.border-t-black-ter-hidden {
  border-top: 1px hidden #242424; }

.border-r-black-ter {
  border-right: 1px solid #242424; }

.border-r-black-ter-dotted {
  border-right: 1px dotted #242424; }

.border-r-black-ter-dashed {
  border-right: 1px dashed #242424; }

.border-r-black-ter-solid {
  border-right: 1px solid #242424; }

.border-r-black-ter-double {
  border-right: 1px double #242424; }

.border-r-black-ter-groove {
  border-right: 1px groove #242424; }

.border-r-black-ter-ridge {
  border-right: 1px ridge #242424; }

.border-r-black-ter-inset {
  border-right: 1px inset #242424; }

.border-r-black-ter-outset {
  border-right: 1px outset #242424; }

.border-r-black-ter-none {
  border-right: 1px none #242424; }

.border-r-black-ter-hidden {
  border-right: 1px hidden #242424; }

.border-b-black-ter {
  border-bottom: 1px solid #242424; }

.border-b-black-ter-dotted {
  border-bottom: 1px dotted #242424; }

.border-b-black-ter-dashed {
  border-bottom: 1px dashed #242424; }

.border-b-black-ter-solid {
  border-bottom: 1px solid #242424; }

.border-b-black-ter-double {
  border-bottom: 1px double #242424; }

.border-b-black-ter-groove {
  border-bottom: 1px groove #242424; }

.border-b-black-ter-ridge {
  border-bottom: 1px ridge #242424; }

.border-b-black-ter-inset {
  border-bottom: 1px inset #242424; }

.border-b-black-ter-outset {
  border-bottom: 1px outset #242424; }

.border-b-black-ter-none {
  border-bottom: 1px none #242424; }

.border-b-black-ter-hidden {
  border-bottom: 1px hidden #242424; }

.border-l-black-ter {
  border-left: 1px solid #242424; }

.border-l-black-ter-dotted {
  border-left: 1px dotted #242424; }

.border-l-black-ter-dashed {
  border-left: 1px dashed #242424; }

.border-l-black-ter-solid {
  border-left: 1px solid #242424; }

.border-l-black-ter-double {
  border-left: 1px double #242424; }

.border-l-black-ter-groove {
  border-left: 1px groove #242424; }

.border-l-black-ter-ridge {
  border-left: 1px ridge #242424; }

.border-l-black-ter-inset {
  border-left: 1px inset #242424; }

.border-l-black-ter-outset {
  border-left: 1px outset #242424; }

.border-l-black-ter-none {
  border-left: 1px none #242424; }

.border-l-black-ter-hidden {
  border-left: 1px hidden #242424; }

.has-text-grey-darker {
  color: #363636; }

.has-background-grey-darker {
  background-color: #363636; }

.border-grey-darker {
  border: 1px solid #363636; }

.border-grey-darker-dotted {
  border: 1px dotted #363636; }

.border-grey-darker-dashed {
  border: 1px dashed #363636; }

.border-grey-darker-solid {
  border: 1px solid #363636; }

.border-grey-darker-double {
  border: 1px double #363636; }

.border-grey-darker-groove {
  border: 1px groove #363636; }

.border-grey-darker-ridge {
  border: 1px ridge #363636; }

.border-grey-darker-inset {
  border: 1px inset #363636; }

.border-grey-darker-outset {
  border: 1px outset #363636; }

.border-grey-darker-none {
  border: 1px none #363636; }

.border-grey-darker-hidden {
  border: 1px hidden #363636; }

.border-t-grey-darker {
  border-top: 1px solid #363636; }

.border-t-grey-darker-dotted {
  border-top: 1px dotted #363636; }

.border-t-grey-darker-dashed {
  border-top: 1px dashed #363636; }

.border-t-grey-darker-solid {
  border-top: 1px solid #363636; }

.border-t-grey-darker-double {
  border-top: 1px double #363636; }

.border-t-grey-darker-groove {
  border-top: 1px groove #363636; }

.border-t-grey-darker-ridge {
  border-top: 1px ridge #363636; }

.border-t-grey-darker-inset {
  border-top: 1px inset #363636; }

.border-t-grey-darker-outset {
  border-top: 1px outset #363636; }

.border-t-grey-darker-none {
  border-top: 1px none #363636; }

.border-t-grey-darker-hidden {
  border-top: 1px hidden #363636; }

.border-r-grey-darker {
  border-right: 1px solid #363636; }

.border-r-grey-darker-dotted {
  border-right: 1px dotted #363636; }

.border-r-grey-darker-dashed {
  border-right: 1px dashed #363636; }

.border-r-grey-darker-solid {
  border-right: 1px solid #363636; }

.border-r-grey-darker-double {
  border-right: 1px double #363636; }

.border-r-grey-darker-groove {
  border-right: 1px groove #363636; }

.border-r-grey-darker-ridge {
  border-right: 1px ridge #363636; }

.border-r-grey-darker-inset {
  border-right: 1px inset #363636; }

.border-r-grey-darker-outset {
  border-right: 1px outset #363636; }

.border-r-grey-darker-none {
  border-right: 1px none #363636; }

.border-r-grey-darker-hidden {
  border-right: 1px hidden #363636; }

.border-b-grey-darker {
  border-bottom: 1px solid #363636; }

.border-b-grey-darker-dotted {
  border-bottom: 1px dotted #363636; }

.border-b-grey-darker-dashed {
  border-bottom: 1px dashed #363636; }

.border-b-grey-darker-solid {
  border-bottom: 1px solid #363636; }

.border-b-grey-darker-double {
  border-bottom: 1px double #363636; }

.border-b-grey-darker-groove {
  border-bottom: 1px groove #363636; }

.border-b-grey-darker-ridge {
  border-bottom: 1px ridge #363636; }

.border-b-grey-darker-inset {
  border-bottom: 1px inset #363636; }

.border-b-grey-darker-outset {
  border-bottom: 1px outset #363636; }

.border-b-grey-darker-none {
  border-bottom: 1px none #363636; }

.border-b-grey-darker-hidden {
  border-bottom: 1px hidden #363636; }

.border-l-grey-darker {
  border-left: 1px solid #363636; }

.border-l-grey-darker-dotted {
  border-left: 1px dotted #363636; }

.border-l-grey-darker-dashed {
  border-left: 1px dashed #363636; }

.border-l-grey-darker-solid {
  border-left: 1px solid #363636; }

.border-l-grey-darker-double {
  border-left: 1px double #363636; }

.border-l-grey-darker-groove {
  border-left: 1px groove #363636; }

.border-l-grey-darker-ridge {
  border-left: 1px ridge #363636; }

.border-l-grey-darker-inset {
  border-left: 1px inset #363636; }

.border-l-grey-darker-outset {
  border-left: 1px outset #363636; }

.border-l-grey-darker-none {
  border-left: 1px none #363636; }

.border-l-grey-darker-hidden {
  border-left: 1px hidden #363636; }

.has-text-grey-dark {
  color: #4a4a4a; }

.has-background-grey-dark {
  background-color: #4a4a4a; }

.border-grey-dark {
  border: 1px solid #4a4a4a; }

.border-grey-dark-dotted {
  border: 1px dotted #4a4a4a; }

.border-grey-dark-dashed {
  border: 1px dashed #4a4a4a; }

.border-grey-dark-solid {
  border: 1px solid #4a4a4a; }

.border-grey-dark-double {
  border: 1px double #4a4a4a; }

.border-grey-dark-groove {
  border: 1px groove #4a4a4a; }

.border-grey-dark-ridge {
  border: 1px ridge #4a4a4a; }

.border-grey-dark-inset {
  border: 1px inset #4a4a4a; }

.border-grey-dark-outset {
  border: 1px outset #4a4a4a; }

.border-grey-dark-none {
  border: 1px none #4a4a4a; }

.border-grey-dark-hidden {
  border: 1px hidden #4a4a4a; }

.border-t-grey-dark {
  border-top: 1px solid #4a4a4a; }

.border-t-grey-dark-dotted {
  border-top: 1px dotted #4a4a4a; }

.border-t-grey-dark-dashed {
  border-top: 1px dashed #4a4a4a; }

.border-t-grey-dark-solid {
  border-top: 1px solid #4a4a4a; }

.border-t-grey-dark-double {
  border-top: 1px double #4a4a4a; }

.border-t-grey-dark-groove {
  border-top: 1px groove #4a4a4a; }

.border-t-grey-dark-ridge {
  border-top: 1px ridge #4a4a4a; }

.border-t-grey-dark-inset {
  border-top: 1px inset #4a4a4a; }

.border-t-grey-dark-outset {
  border-top: 1px outset #4a4a4a; }

.border-t-grey-dark-none {
  border-top: 1px none #4a4a4a; }

.border-t-grey-dark-hidden {
  border-top: 1px hidden #4a4a4a; }

.border-r-grey-dark {
  border-right: 1px solid #4a4a4a; }

.border-r-grey-dark-dotted {
  border-right: 1px dotted #4a4a4a; }

.border-r-grey-dark-dashed {
  border-right: 1px dashed #4a4a4a; }

.border-r-grey-dark-solid {
  border-right: 1px solid #4a4a4a; }

.border-r-grey-dark-double {
  border-right: 1px double #4a4a4a; }

.border-r-grey-dark-groove {
  border-right: 1px groove #4a4a4a; }

.border-r-grey-dark-ridge {
  border-right: 1px ridge #4a4a4a; }

.border-r-grey-dark-inset {
  border-right: 1px inset #4a4a4a; }

.border-r-grey-dark-outset {
  border-right: 1px outset #4a4a4a; }

.border-r-grey-dark-none {
  border-right: 1px none #4a4a4a; }

.border-r-grey-dark-hidden {
  border-right: 1px hidden #4a4a4a; }

.border-b-grey-dark {
  border-bottom: 1px solid #4a4a4a; }

.border-b-grey-dark-dotted {
  border-bottom: 1px dotted #4a4a4a; }

.border-b-grey-dark-dashed {
  border-bottom: 1px dashed #4a4a4a; }

.border-b-grey-dark-solid {
  border-bottom: 1px solid #4a4a4a; }

.border-b-grey-dark-double {
  border-bottom: 1px double #4a4a4a; }

.border-b-grey-dark-groove {
  border-bottom: 1px groove #4a4a4a; }

.border-b-grey-dark-ridge {
  border-bottom: 1px ridge #4a4a4a; }

.border-b-grey-dark-inset {
  border-bottom: 1px inset #4a4a4a; }

.border-b-grey-dark-outset {
  border-bottom: 1px outset #4a4a4a; }

.border-b-grey-dark-none {
  border-bottom: 1px none #4a4a4a; }

.border-b-grey-dark-hidden {
  border-bottom: 1px hidden #4a4a4a; }

.border-l-grey-dark {
  border-left: 1px solid #4a4a4a; }

.border-l-grey-dark-dotted {
  border-left: 1px dotted #4a4a4a; }

.border-l-grey-dark-dashed {
  border-left: 1px dashed #4a4a4a; }

.border-l-grey-dark-solid {
  border-left: 1px solid #4a4a4a; }

.border-l-grey-dark-double {
  border-left: 1px double #4a4a4a; }

.border-l-grey-dark-groove {
  border-left: 1px groove #4a4a4a; }

.border-l-grey-dark-ridge {
  border-left: 1px ridge #4a4a4a; }

.border-l-grey-dark-inset {
  border-left: 1px inset #4a4a4a; }

.border-l-grey-dark-outset {
  border-left: 1px outset #4a4a4a; }

.border-l-grey-dark-none {
  border-left: 1px none #4a4a4a; }

.border-l-grey-dark-hidden {
  border-left: 1px hidden #4a4a4a; }

.has-text-grey {
  color: #7a7a7a; }

.has-background-grey {
  background-color: #7a7a7a; }

.border-grey {
  border: 1px solid #7a7a7a; }

.border-grey-dotted {
  border: 1px dotted #7a7a7a; }

.border-grey-dashed {
  border: 1px dashed #7a7a7a; }

.border-grey-solid {
  border: 1px solid #7a7a7a; }

.border-grey-double {
  border: 1px double #7a7a7a; }

.border-grey-groove {
  border: 1px groove #7a7a7a; }

.border-grey-ridge {
  border: 1px ridge #7a7a7a; }

.border-grey-inset {
  border: 1px inset #7a7a7a; }

.border-grey-outset {
  border: 1px outset #7a7a7a; }

.border-grey-none {
  border: 1px none #7a7a7a; }

.border-grey-hidden {
  border: 1px hidden #7a7a7a; }

.border-t-grey {
  border-top: 1px solid #7a7a7a; }

.border-t-grey-dotted {
  border-top: 1px dotted #7a7a7a; }

.border-t-grey-dashed {
  border-top: 1px dashed #7a7a7a; }

.border-t-grey-solid {
  border-top: 1px solid #7a7a7a; }

.border-t-grey-double {
  border-top: 1px double #7a7a7a; }

.border-t-grey-groove {
  border-top: 1px groove #7a7a7a; }

.border-t-grey-ridge {
  border-top: 1px ridge #7a7a7a; }

.border-t-grey-inset {
  border-top: 1px inset #7a7a7a; }

.border-t-grey-outset {
  border-top: 1px outset #7a7a7a; }

.border-t-grey-none {
  border-top: 1px none #7a7a7a; }

.border-t-grey-hidden {
  border-top: 1px hidden #7a7a7a; }

.border-r-grey {
  border-right: 1px solid #7a7a7a; }

.border-r-grey-dotted {
  border-right: 1px dotted #7a7a7a; }

.border-r-grey-dashed {
  border-right: 1px dashed #7a7a7a; }

.border-r-grey-solid {
  border-right: 1px solid #7a7a7a; }

.border-r-grey-double {
  border-right: 1px double #7a7a7a; }

.border-r-grey-groove {
  border-right: 1px groove #7a7a7a; }

.border-r-grey-ridge {
  border-right: 1px ridge #7a7a7a; }

.border-r-grey-inset {
  border-right: 1px inset #7a7a7a; }

.border-r-grey-outset {
  border-right: 1px outset #7a7a7a; }

.border-r-grey-none {
  border-right: 1px none #7a7a7a; }

.border-r-grey-hidden {
  border-right: 1px hidden #7a7a7a; }

.border-b-grey {
  border-bottom: 1px solid #7a7a7a; }

.border-b-grey-dotted {
  border-bottom: 1px dotted #7a7a7a; }

.border-b-grey-dashed {
  border-bottom: 1px dashed #7a7a7a; }

.border-b-grey-solid {
  border-bottom: 1px solid #7a7a7a; }

.border-b-grey-double {
  border-bottom: 1px double #7a7a7a; }

.border-b-grey-groove {
  border-bottom: 1px groove #7a7a7a; }

.border-b-grey-ridge {
  border-bottom: 1px ridge #7a7a7a; }

.border-b-grey-inset {
  border-bottom: 1px inset #7a7a7a; }

.border-b-grey-outset {
  border-bottom: 1px outset #7a7a7a; }

.border-b-grey-none {
  border-bottom: 1px none #7a7a7a; }

.border-b-grey-hidden {
  border-bottom: 1px hidden #7a7a7a; }

.border-l-grey {
  border-left: 1px solid #7a7a7a; }

.border-l-grey-dotted {
  border-left: 1px dotted #7a7a7a; }

.border-l-grey-dashed {
  border-left: 1px dashed #7a7a7a; }

.border-l-grey-solid {
  border-left: 1px solid #7a7a7a; }

.border-l-grey-double {
  border-left: 1px double #7a7a7a; }

.border-l-grey-groove {
  border-left: 1px groove #7a7a7a; }

.border-l-grey-ridge {
  border-left: 1px ridge #7a7a7a; }

.border-l-grey-inset {
  border-left: 1px inset #7a7a7a; }

.border-l-grey-outset {
  border-left: 1px outset #7a7a7a; }

.border-l-grey-none {
  border-left: 1px none #7a7a7a; }

.border-l-grey-hidden {
  border-left: 1px hidden #7a7a7a; }

.has-text-grey-light {
  color: #b5b5b5; }

.has-background-grey-light {
  background-color: #b5b5b5; }

.border-grey-light {
  border: 1px solid #b5b5b5; }

.border-grey-light-dotted {
  border: 1px dotted #b5b5b5; }

.border-grey-light-dashed {
  border: 1px dashed #b5b5b5; }

.border-grey-light-solid {
  border: 1px solid #b5b5b5; }

.border-grey-light-double {
  border: 1px double #b5b5b5; }

.border-grey-light-groove {
  border: 1px groove #b5b5b5; }

.border-grey-light-ridge {
  border: 1px ridge #b5b5b5; }

.border-grey-light-inset {
  border: 1px inset #b5b5b5; }

.border-grey-light-outset {
  border: 1px outset #b5b5b5; }

.border-grey-light-none {
  border: 1px none #b5b5b5; }

.border-grey-light-hidden {
  border: 1px hidden #b5b5b5; }

.border-t-grey-light {
  border-top: 1px solid #b5b5b5; }

.border-t-grey-light-dotted {
  border-top: 1px dotted #b5b5b5; }

.border-t-grey-light-dashed {
  border-top: 1px dashed #b5b5b5; }

.border-t-grey-light-solid {
  border-top: 1px solid #b5b5b5; }

.border-t-grey-light-double {
  border-top: 1px double #b5b5b5; }

.border-t-grey-light-groove {
  border-top: 1px groove #b5b5b5; }

.border-t-grey-light-ridge {
  border-top: 1px ridge #b5b5b5; }

.border-t-grey-light-inset {
  border-top: 1px inset #b5b5b5; }

.border-t-grey-light-outset {
  border-top: 1px outset #b5b5b5; }

.border-t-grey-light-none {
  border-top: 1px none #b5b5b5; }

.border-t-grey-light-hidden {
  border-top: 1px hidden #b5b5b5; }

.border-r-grey-light {
  border-right: 1px solid #b5b5b5; }

.border-r-grey-light-dotted {
  border-right: 1px dotted #b5b5b5; }

.border-r-grey-light-dashed {
  border-right: 1px dashed #b5b5b5; }

.border-r-grey-light-solid {
  border-right: 1px solid #b5b5b5; }

.border-r-grey-light-double {
  border-right: 1px double #b5b5b5; }

.border-r-grey-light-groove {
  border-right: 1px groove #b5b5b5; }

.border-r-grey-light-ridge {
  border-right: 1px ridge #b5b5b5; }

.border-r-grey-light-inset {
  border-right: 1px inset #b5b5b5; }

.border-r-grey-light-outset {
  border-right: 1px outset #b5b5b5; }

.border-r-grey-light-none {
  border-right: 1px none #b5b5b5; }

.border-r-grey-light-hidden {
  border-right: 1px hidden #b5b5b5; }

.border-b-grey-light {
  border-bottom: 1px solid #b5b5b5; }

.border-b-grey-light-dotted {
  border-bottom: 1px dotted #b5b5b5; }

.border-b-grey-light-dashed {
  border-bottom: 1px dashed #b5b5b5; }

.border-b-grey-light-solid {
  border-bottom: 1px solid #b5b5b5; }

.border-b-grey-light-double {
  border-bottom: 1px double #b5b5b5; }

.border-b-grey-light-groove {
  border-bottom: 1px groove #b5b5b5; }

.border-b-grey-light-ridge {
  border-bottom: 1px ridge #b5b5b5; }

.border-b-grey-light-inset {
  border-bottom: 1px inset #b5b5b5; }

.border-b-grey-light-outset {
  border-bottom: 1px outset #b5b5b5; }

.border-b-grey-light-none {
  border-bottom: 1px none #b5b5b5; }

.border-b-grey-light-hidden {
  border-bottom: 1px hidden #b5b5b5; }

.border-l-grey-light {
  border-left: 1px solid #b5b5b5; }

.border-l-grey-light-dotted {
  border-left: 1px dotted #b5b5b5; }

.border-l-grey-light-dashed {
  border-left: 1px dashed #b5b5b5; }

.border-l-grey-light-solid {
  border-left: 1px solid #b5b5b5; }

.border-l-grey-light-double {
  border-left: 1px double #b5b5b5; }

.border-l-grey-light-groove {
  border-left: 1px groove #b5b5b5; }

.border-l-grey-light-ridge {
  border-left: 1px ridge #b5b5b5; }

.border-l-grey-light-inset {
  border-left: 1px inset #b5b5b5; }

.border-l-grey-light-outset {
  border-left: 1px outset #b5b5b5; }

.border-l-grey-light-none {
  border-left: 1px none #b5b5b5; }

.border-l-grey-light-hidden {
  border-left: 1px hidden #b5b5b5; }

.has-text-grey-lighter {
  color: #e6e6e6; }

.has-background-grey-lighter {
  background-color: #e6e6e6; }

.border-grey-lighter {
  border: 1px solid #e6e6e6; }

.border-grey-lighter-dotted {
  border: 1px dotted #e6e6e6; }

.border-grey-lighter-dashed {
  border: 1px dashed #e6e6e6; }

.border-grey-lighter-solid {
  border: 1px solid #e6e6e6; }

.border-grey-lighter-double {
  border: 1px double #e6e6e6; }

.border-grey-lighter-groove {
  border: 1px groove #e6e6e6; }

.border-grey-lighter-ridge {
  border: 1px ridge #e6e6e6; }

.border-grey-lighter-inset {
  border: 1px inset #e6e6e6; }

.border-grey-lighter-outset {
  border: 1px outset #e6e6e6; }

.border-grey-lighter-none {
  border: 1px none #e6e6e6; }

.border-grey-lighter-hidden {
  border: 1px hidden #e6e6e6; }

.border-t-grey-lighter {
  border-top: 1px solid #e6e6e6; }

.border-t-grey-lighter-dotted {
  border-top: 1px dotted #e6e6e6; }

.border-t-grey-lighter-dashed {
  border-top: 1px dashed #e6e6e6; }

.border-t-grey-lighter-solid {
  border-top: 1px solid #e6e6e6; }

.border-t-grey-lighter-double {
  border-top: 1px double #e6e6e6; }

.border-t-grey-lighter-groove {
  border-top: 1px groove #e6e6e6; }

.border-t-grey-lighter-ridge {
  border-top: 1px ridge #e6e6e6; }

.border-t-grey-lighter-inset {
  border-top: 1px inset #e6e6e6; }

.border-t-grey-lighter-outset {
  border-top: 1px outset #e6e6e6; }

.border-t-grey-lighter-none {
  border-top: 1px none #e6e6e6; }

.border-t-grey-lighter-hidden {
  border-top: 1px hidden #e6e6e6; }

.border-r-grey-lighter {
  border-right: 1px solid #e6e6e6; }

.border-r-grey-lighter-dotted {
  border-right: 1px dotted #e6e6e6; }

.border-r-grey-lighter-dashed {
  border-right: 1px dashed #e6e6e6; }

.border-r-grey-lighter-solid {
  border-right: 1px solid #e6e6e6; }

.border-r-grey-lighter-double {
  border-right: 1px double #e6e6e6; }

.border-r-grey-lighter-groove {
  border-right: 1px groove #e6e6e6; }

.border-r-grey-lighter-ridge {
  border-right: 1px ridge #e6e6e6; }

.border-r-grey-lighter-inset {
  border-right: 1px inset #e6e6e6; }

.border-r-grey-lighter-outset {
  border-right: 1px outset #e6e6e6; }

.border-r-grey-lighter-none {
  border-right: 1px none #e6e6e6; }

.border-r-grey-lighter-hidden {
  border-right: 1px hidden #e6e6e6; }

.border-b-grey-lighter {
  border-bottom: 1px solid #e6e6e6; }

.border-b-grey-lighter-dotted {
  border-bottom: 1px dotted #e6e6e6; }

.border-b-grey-lighter-dashed {
  border-bottom: 1px dashed #e6e6e6; }

.border-b-grey-lighter-solid {
  border-bottom: 1px solid #e6e6e6; }

.border-b-grey-lighter-double {
  border-bottom: 1px double #e6e6e6; }

.border-b-grey-lighter-groove {
  border-bottom: 1px groove #e6e6e6; }

.border-b-grey-lighter-ridge {
  border-bottom: 1px ridge #e6e6e6; }

.border-b-grey-lighter-inset {
  border-bottom: 1px inset #e6e6e6; }

.border-b-grey-lighter-outset {
  border-bottom: 1px outset #e6e6e6; }

.border-b-grey-lighter-none {
  border-bottom: 1px none #e6e6e6; }

.border-b-grey-lighter-hidden {
  border-bottom: 1px hidden #e6e6e6; }

.border-l-grey-lighter {
  border-left: 1px solid #e6e6e6; }

.border-l-grey-lighter-dotted {
  border-left: 1px dotted #e6e6e6; }

.border-l-grey-lighter-dashed {
  border-left: 1px dashed #e6e6e6; }

.border-l-grey-lighter-solid {
  border-left: 1px solid #e6e6e6; }

.border-l-grey-lighter-double {
  border-left: 1px double #e6e6e6; }

.border-l-grey-lighter-groove {
  border-left: 1px groove #e6e6e6; }

.border-l-grey-lighter-ridge {
  border-left: 1px ridge #e6e6e6; }

.border-l-grey-lighter-inset {
  border-left: 1px inset #e6e6e6; }

.border-l-grey-lighter-outset {
  border-left: 1px outset #e6e6e6; }

.border-l-grey-lighter-none {
  border-left: 1px none #e6e6e6; }

.border-l-grey-lighter-hidden {
  border-left: 1px hidden #e6e6e6; }

.has-text-white-ter {
  color: whitesmoke; }

.has-background-white-ter {
  background-color: whitesmoke; }

.border-white-ter {
  border: 1px solid whitesmoke; }

.border-white-ter-dotted {
  border: 1px dotted whitesmoke; }

.border-white-ter-dashed {
  border: 1px dashed whitesmoke; }

.border-white-ter-solid {
  border: 1px solid whitesmoke; }

.border-white-ter-double {
  border: 1px double whitesmoke; }

.border-white-ter-groove {
  border: 1px groove whitesmoke; }

.border-white-ter-ridge {
  border: 1px ridge whitesmoke; }

.border-white-ter-inset {
  border: 1px inset whitesmoke; }

.border-white-ter-outset {
  border: 1px outset whitesmoke; }

.border-white-ter-none {
  border: 1px none whitesmoke; }

.border-white-ter-hidden {
  border: 1px hidden whitesmoke; }

.border-t-white-ter {
  border-top: 1px solid whitesmoke; }

.border-t-white-ter-dotted {
  border-top: 1px dotted whitesmoke; }

.border-t-white-ter-dashed {
  border-top: 1px dashed whitesmoke; }

.border-t-white-ter-solid {
  border-top: 1px solid whitesmoke; }

.border-t-white-ter-double {
  border-top: 1px double whitesmoke; }

.border-t-white-ter-groove {
  border-top: 1px groove whitesmoke; }

.border-t-white-ter-ridge {
  border-top: 1px ridge whitesmoke; }

.border-t-white-ter-inset {
  border-top: 1px inset whitesmoke; }

.border-t-white-ter-outset {
  border-top: 1px outset whitesmoke; }

.border-t-white-ter-none {
  border-top: 1px none whitesmoke; }

.border-t-white-ter-hidden {
  border-top: 1px hidden whitesmoke; }

.border-r-white-ter {
  border-right: 1px solid whitesmoke; }

.border-r-white-ter-dotted {
  border-right: 1px dotted whitesmoke; }

.border-r-white-ter-dashed {
  border-right: 1px dashed whitesmoke; }

.border-r-white-ter-solid {
  border-right: 1px solid whitesmoke; }

.border-r-white-ter-double {
  border-right: 1px double whitesmoke; }

.border-r-white-ter-groove {
  border-right: 1px groove whitesmoke; }

.border-r-white-ter-ridge {
  border-right: 1px ridge whitesmoke; }

.border-r-white-ter-inset {
  border-right: 1px inset whitesmoke; }

.border-r-white-ter-outset {
  border-right: 1px outset whitesmoke; }

.border-r-white-ter-none {
  border-right: 1px none whitesmoke; }

.border-r-white-ter-hidden {
  border-right: 1px hidden whitesmoke; }

.border-b-white-ter {
  border-bottom: 1px solid whitesmoke; }

.border-b-white-ter-dotted {
  border-bottom: 1px dotted whitesmoke; }

.border-b-white-ter-dashed {
  border-bottom: 1px dashed whitesmoke; }

.border-b-white-ter-solid {
  border-bottom: 1px solid whitesmoke; }

.border-b-white-ter-double {
  border-bottom: 1px double whitesmoke; }

.border-b-white-ter-groove {
  border-bottom: 1px groove whitesmoke; }

.border-b-white-ter-ridge {
  border-bottom: 1px ridge whitesmoke; }

.border-b-white-ter-inset {
  border-bottom: 1px inset whitesmoke; }

.border-b-white-ter-outset {
  border-bottom: 1px outset whitesmoke; }

.border-b-white-ter-none {
  border-bottom: 1px none whitesmoke; }

.border-b-white-ter-hidden {
  border-bottom: 1px hidden whitesmoke; }

.border-l-white-ter {
  border-left: 1px solid whitesmoke; }

.border-l-white-ter-dotted {
  border-left: 1px dotted whitesmoke; }

.border-l-white-ter-dashed {
  border-left: 1px dashed whitesmoke; }

.border-l-white-ter-solid {
  border-left: 1px solid whitesmoke; }

.border-l-white-ter-double {
  border-left: 1px double whitesmoke; }

.border-l-white-ter-groove {
  border-left: 1px groove whitesmoke; }

.border-l-white-ter-ridge {
  border-left: 1px ridge whitesmoke; }

.border-l-white-ter-inset {
  border-left: 1px inset whitesmoke; }

.border-l-white-ter-outset {
  border-left: 1px outset whitesmoke; }

.border-l-white-ter-none {
  border-left: 1px none whitesmoke; }

.border-l-white-ter-hidden {
  border-left: 1px hidden whitesmoke; }

.has-text-white-bis {
  color: #fafafa; }

.has-background-white-bis {
  background-color: #fafafa; }

.border-white-bis {
  border: 1px solid #fafafa; }

.border-white-bis-dotted {
  border: 1px dotted #fafafa; }

.border-white-bis-dashed {
  border: 1px dashed #fafafa; }

.border-white-bis-solid {
  border: 1px solid #fafafa; }

.border-white-bis-double {
  border: 1px double #fafafa; }

.border-white-bis-groove {
  border: 1px groove #fafafa; }

.border-white-bis-ridge {
  border: 1px ridge #fafafa; }

.border-white-bis-inset {
  border: 1px inset #fafafa; }

.border-white-bis-outset {
  border: 1px outset #fafafa; }

.border-white-bis-none {
  border: 1px none #fafafa; }

.border-white-bis-hidden {
  border: 1px hidden #fafafa; }

.border-t-white-bis {
  border-top: 1px solid #fafafa; }

.border-t-white-bis-dotted {
  border-top: 1px dotted #fafafa; }

.border-t-white-bis-dashed {
  border-top: 1px dashed #fafafa; }

.border-t-white-bis-solid {
  border-top: 1px solid #fafafa; }

.border-t-white-bis-double {
  border-top: 1px double #fafafa; }

.border-t-white-bis-groove {
  border-top: 1px groove #fafafa; }

.border-t-white-bis-ridge {
  border-top: 1px ridge #fafafa; }

.border-t-white-bis-inset {
  border-top: 1px inset #fafafa; }

.border-t-white-bis-outset {
  border-top: 1px outset #fafafa; }

.border-t-white-bis-none {
  border-top: 1px none #fafafa; }

.border-t-white-bis-hidden {
  border-top: 1px hidden #fafafa; }

.border-r-white-bis {
  border-right: 1px solid #fafafa; }

.border-r-white-bis-dotted {
  border-right: 1px dotted #fafafa; }

.border-r-white-bis-dashed {
  border-right: 1px dashed #fafafa; }

.border-r-white-bis-solid {
  border-right: 1px solid #fafafa; }

.border-r-white-bis-double {
  border-right: 1px double #fafafa; }

.border-r-white-bis-groove {
  border-right: 1px groove #fafafa; }

.border-r-white-bis-ridge {
  border-right: 1px ridge #fafafa; }

.border-r-white-bis-inset {
  border-right: 1px inset #fafafa; }

.border-r-white-bis-outset {
  border-right: 1px outset #fafafa; }

.border-r-white-bis-none {
  border-right: 1px none #fafafa; }

.border-r-white-bis-hidden {
  border-right: 1px hidden #fafafa; }

.border-b-white-bis {
  border-bottom: 1px solid #fafafa; }

.border-b-white-bis-dotted {
  border-bottom: 1px dotted #fafafa; }

.border-b-white-bis-dashed {
  border-bottom: 1px dashed #fafafa; }

.border-b-white-bis-solid {
  border-bottom: 1px solid #fafafa; }

.border-b-white-bis-double {
  border-bottom: 1px double #fafafa; }

.border-b-white-bis-groove {
  border-bottom: 1px groove #fafafa; }

.border-b-white-bis-ridge {
  border-bottom: 1px ridge #fafafa; }

.border-b-white-bis-inset {
  border-bottom: 1px inset #fafafa; }

.border-b-white-bis-outset {
  border-bottom: 1px outset #fafafa; }

.border-b-white-bis-none {
  border-bottom: 1px none #fafafa; }

.border-b-white-bis-hidden {
  border-bottom: 1px hidden #fafafa; }

.border-l-white-bis {
  border-left: 1px solid #fafafa; }

.border-l-white-bis-dotted {
  border-left: 1px dotted #fafafa; }

.border-l-white-bis-dashed {
  border-left: 1px dashed #fafafa; }

.border-l-white-bis-solid {
  border-left: 1px solid #fafafa; }

.border-l-white-bis-double {
  border-left: 1px double #fafafa; }

.border-l-white-bis-groove {
  border-left: 1px groove #fafafa; }

.border-l-white-bis-ridge {
  border-left: 1px ridge #fafafa; }

.border-l-white-bis-inset {
  border-left: 1px inset #fafafa; }

.border-l-white-bis-outset {
  border-left: 1px outset #fafafa; }

.border-l-white-bis-none {
  border-left: 1px none #fafafa; }

.border-l-white-bis-hidden {
  border-left: 1px hidden #fafafa; }

.has-text-white {
  color: white; }

.has-background-white {
  background-color: white; }

.border-white {
  border: 1px solid white; }

.border-white-dotted {
  border: 1px dotted white; }

.border-white-dashed {
  border: 1px dashed white; }

.border-white-solid {
  border: 1px solid white; }

.border-white-double {
  border: 1px double white; }

.border-white-groove {
  border: 1px groove white; }

.border-white-ridge {
  border: 1px ridge white; }

.border-white-inset {
  border: 1px inset white; }

.border-white-outset {
  border: 1px outset white; }

.border-white-none {
  border: 1px none white; }

.border-white-hidden {
  border: 1px hidden white; }

.border-t-white {
  border-top: 1px solid white; }

.border-t-white-dotted {
  border-top: 1px dotted white; }

.border-t-white-dashed {
  border-top: 1px dashed white; }

.border-t-white-solid {
  border-top: 1px solid white; }

.border-t-white-double {
  border-top: 1px double white; }

.border-t-white-groove {
  border-top: 1px groove white; }

.border-t-white-ridge {
  border-top: 1px ridge white; }

.border-t-white-inset {
  border-top: 1px inset white; }

.border-t-white-outset {
  border-top: 1px outset white; }

.border-t-white-none {
  border-top: 1px none white; }

.border-t-white-hidden {
  border-top: 1px hidden white; }

.border-r-white {
  border-right: 1px solid white; }

.border-r-white-dotted {
  border-right: 1px dotted white; }

.border-r-white-dashed {
  border-right: 1px dashed white; }

.border-r-white-solid {
  border-right: 1px solid white; }

.border-r-white-double {
  border-right: 1px double white; }

.border-r-white-groove {
  border-right: 1px groove white; }

.border-r-white-ridge {
  border-right: 1px ridge white; }

.border-r-white-inset {
  border-right: 1px inset white; }

.border-r-white-outset {
  border-right: 1px outset white; }

.border-r-white-none {
  border-right: 1px none white; }

.border-r-white-hidden {
  border-right: 1px hidden white; }

.border-b-white {
  border-bottom: 1px solid white; }

.border-b-white-dotted {
  border-bottom: 1px dotted white; }

.border-b-white-dashed {
  border-bottom: 1px dashed white; }

.border-b-white-solid {
  border-bottom: 1px solid white; }

.border-b-white-double {
  border-bottom: 1px double white; }

.border-b-white-groove {
  border-bottom: 1px groove white; }

.border-b-white-ridge {
  border-bottom: 1px ridge white; }

.border-b-white-inset {
  border-bottom: 1px inset white; }

.border-b-white-outset {
  border-bottom: 1px outset white; }

.border-b-white-none {
  border-bottom: 1px none white; }

.border-b-white-hidden {
  border-bottom: 1px hidden white; }

.border-l-white {
  border-left: 1px solid white; }

.border-l-white-dotted {
  border-left: 1px dotted white; }

.border-l-white-dashed {
  border-left: 1px dashed white; }

.border-l-white-solid {
  border-left: 1px solid white; }

.border-l-white-double {
  border-left: 1px double white; }

.border-l-white-groove {
  border-left: 1px groove white; }

.border-l-white-ridge {
  border-left: 1px ridge white; }

.border-l-white-inset {
  border-left: 1px inset white; }

.border-l-white-outset {
  border-left: 1px outset white; }

.border-l-white-none {
  border-left: 1px none white; }

.border-l-white-hidden {
  border-left: 1px hidden white; }

.absolute-down {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

figure {
  width: 100%;
  overflow: hidden;
  margin: 0; }

figure img {
  display: block;
  width: 100%; }

.circle {
  position: absolute;
  top: 0;
  right: -10px;
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  background-color: #cc3333;
  border-radius: 50%; }

.inherit-height {
  height: inherit; }

.flex-1 {
  flex: 1 1; }

.zIndex-1 {
  z-index: 1; }

.zIndexn-1 {
  z-index: -1; }

.flex-2 {
  flex: 2 1; }

.zIndex-2 {
  z-index: 2; }

.zIndexn-2 {
  z-index: -2; }

.flex-3 {
  flex: 3 1; }

.zIndex-3 {
  z-index: 3; }

.zIndexn-3 {
  z-index: -3; }

.flex-4 {
  flex: 4 1; }

.zIndex-4 {
  z-index: 4; }

.zIndexn-4 {
  z-index: -4; }

.flex-5 {
  flex: 5 1; }

.zIndex-5 {
  z-index: 5; }

.zIndexn-5 {
  z-index: -5; }

.flex-6 {
  flex: 6 1; }

.zIndex-6 {
  z-index: 6; }

.zIndexn-6 {
  z-index: -6; }

.flex-7 {
  flex: 7 1; }

.zIndex-7 {
  z-index: 7; }

.zIndexn-7 {
  z-index: -7; }

.flex-8 {
  flex: 8 1; }

.zIndex-8 {
  z-index: 8; }

.zIndexn-8 {
  z-index: -8; }

.flex-9 {
  flex: 9 1; }

.zIndex-9 {
  z-index: 9; }

.zIndexn-9 {
  z-index: -9; }

.flex-10 {
  flex: 10 1; }

.zIndex-10 {
  z-index: 10; }

.zIndexn-10 {
  z-index: -10; }

.u-m-0 {
  margin: 0px; }

.u-m-l-0 {
  margin-left: 0px; }

.u-m-r-0 {
  margin-right: 0px; }

.u-m-b-0 {
  margin-bottom: 0px; }

.u-m-t-0 {
  margin-top: 0px; }

.u-p-0 {
  padding: 0px; }

.u-p-l-0 {
  padding-left: 0px; }

.u-p-r-0 {
  padding-right: 0px; }

.u-p-b-0 {
  padding-bottom: 0px; }

.u-p-t-0 {
  padding-top: 0px; }

.u-b-r-0 {
  border-radius: 0px; }

.u-w-0 {
  width: 0%; }

.u-h-0 {
  height: 0%; }

.u-m-2 {
  margin: 2px; }

.u-m-l-2 {
  margin-left: 2px; }

.u-m-r-2 {
  margin-right: 2px; }

.u-m-b-2 {
  margin-bottom: 2px; }

.u-m-t-2 {
  margin-top: 2px; }

.u-p-2 {
  padding: 2px; }

.u-p-l-2 {
  padding-left: 2px; }

.u-p-r-2 {
  padding-right: 2px; }

.u-p-b-2 {
  padding-bottom: 2px; }

.u-p-t-2 {
  padding-top: 2px; }

.u-b-r-2 {
  border-radius: 2px; }

.u-w-2 {
  width: 2%; }

.u-h-2 {
  height: 2%; }

.u-m-5 {
  margin: 5px; }

.u-m-l-5 {
  margin-left: 5px; }

.u-m-r-5 {
  margin-right: 5px; }

.u-m-b-5 {
  margin-bottom: 5px; }

.u-m-t-5 {
  margin-top: 5px; }

.u-p-5 {
  padding: 5px; }

.u-p-l-5 {
  padding-left: 5px; }

.u-p-r-5 {
  padding-right: 5px; }

.u-p-b-5 {
  padding-bottom: 5px; }

.u-p-t-5 {
  padding-top: 5px; }

.u-b-r-5 {
  border-radius: 5px; }

.u-w-5 {
  width: 5%; }

.u-h-5 {
  height: 5%; }

.u-m-7 {
  margin: 7px; }

.u-m-l-7 {
  margin-left: 7px; }

.u-m-r-7 {
  margin-right: 7px; }

.u-m-b-7 {
  margin-bottom: 7px; }

.u-m-t-7 {
  margin-top: 7px; }

.u-p-7 {
  padding: 7px; }

.u-p-l-7 {
  padding-left: 7px; }

.u-p-r-7 {
  padding-right: 7px; }

.u-p-b-7 {
  padding-bottom: 7px; }

.u-p-t-7 {
  padding-top: 7px; }

.u-b-r-7 {
  border-radius: 7px; }

.u-w-7 {
  width: 7%; }

.u-h-7 {
  height: 7%; }

.u-m-8 {
  margin: 8px; }

.u-m-l-8 {
  margin-left: 8px; }

.u-m-r-8 {
  margin-right: 8px; }

.u-m-b-8 {
  margin-bottom: 8px; }

.u-m-t-8 {
  margin-top: 8px; }

.u-p-8 {
  padding: 8px; }

.u-p-l-8 {
  padding-left: 8px; }

.u-p-r-8 {
  padding-right: 8px; }

.u-p-b-8 {
  padding-bottom: 8px; }

.u-p-t-8 {
  padding-top: 8px; }

.u-b-r-8 {
  border-radius: 8px; }

.u-w-8 {
  width: 8%; }

.u-h-8 {
  height: 8%; }

.u-m-9 {
  margin: 9px; }

.u-m-l-9 {
  margin-left: 9px; }

.u-m-r-9 {
  margin-right: 9px; }

.u-m-b-9 {
  margin-bottom: 9px; }

.u-m-t-9 {
  margin-top: 9px; }

.u-p-9 {
  padding: 9px; }

.u-p-l-9 {
  padding-left: 9px; }

.u-p-r-9 {
  padding-right: 9px; }

.u-p-b-9 {
  padding-bottom: 9px; }

.u-p-t-9 {
  padding-top: 9px; }

.u-b-r-9 {
  border-radius: 9px; }

.u-w-9 {
  width: 9%; }

.u-h-9 {
  height: 9%; }

.u-m-10 {
  margin: 10px; }

.u-m-l-10 {
  margin-left: 10px; }

.u-m-r-10 {
  margin-right: 10px; }

.u-m-b-10 {
  margin-bottom: 10px; }

.u-m-t-10 {
  margin-top: 10px; }

.u-p-10 {
  padding: 10px; }

.u-p-l-10 {
  padding-left: 10px; }

.u-p-r-10 {
  padding-right: 10px; }

.u-p-b-10 {
  padding-bottom: 10px; }

.u-p-t-10 {
  padding-top: 10px; }

.u-b-r-10 {
  border-radius: 10px; }

.u-w-10 {
  width: 10%; }

.u-h-10 {
  height: 10%; }

.u-m-15 {
  margin: 15px; }

.u-m-l-15 {
  margin-left: 15px; }

.u-m-r-15 {
  margin-right: 15px; }

.u-m-b-15 {
  margin-bottom: 15px; }

.u-m-t-15 {
  margin-top: 15px; }

.u-p-15 {
  padding: 15px; }

.u-p-l-15 {
  padding-left: 15px; }

.u-p-r-15 {
  padding-right: 15px; }

.u-p-b-15 {
  padding-bottom: 15px; }

.u-p-t-15 {
  padding-top: 15px; }

.u-b-r-15 {
  border-radius: 15px; }

.u-w-15 {
  width: 15%; }

.u-h-15 {
  height: 15%; }

.u-m-20 {
  margin: 20px; }

.u-m-l-20 {
  margin-left: 20px; }

.u-m-r-20 {
  margin-right: 20px; }

.u-m-b-20 {
  margin-bottom: 20px; }

.u-m-t-20 {
  margin-top: 20px; }

.u-p-20 {
  padding: 20px; }

.u-p-l-20 {
  padding-left: 20px; }

.u-p-r-20 {
  padding-right: 20px; }

.u-p-b-20 {
  padding-bottom: 20px; }

.u-p-t-20 {
  padding-top: 20px; }

.u-b-r-20 {
  border-radius: 20px; }

.u-w-20 {
  width: 20%; }

.u-h-20 {
  height: 20%; }

.u-m-25 {
  margin: 25px; }

.u-m-l-25 {
  margin-left: 25px; }

.u-m-r-25 {
  margin-right: 25px; }

.u-m-b-25 {
  margin-bottom: 25px; }

.u-m-t-25 {
  margin-top: 25px; }

.u-p-25 {
  padding: 25px; }

.u-p-l-25 {
  padding-left: 25px; }

.u-p-r-25 {
  padding-right: 25px; }

.u-p-b-25 {
  padding-bottom: 25px; }

.u-p-t-25 {
  padding-top: 25px; }

.u-b-r-25 {
  border-radius: 25px; }

.u-w-25 {
  width: 25%; }

.u-h-25 {
  height: 25%; }

.u-m-30 {
  margin: 30px; }

.u-m-l-30 {
  margin-left: 30px; }

.u-m-r-30 {
  margin-right: 30px; }

.u-m-b-30 {
  margin-bottom: 30px; }

.u-m-t-30 {
  margin-top: 30px; }

.u-p-30 {
  padding: 30px; }

.u-p-l-30 {
  padding-left: 30px; }

.u-p-r-30 {
  padding-right: 30px; }

.u-p-b-30 {
  padding-bottom: 30px; }

.u-p-t-30 {
  padding-top: 30px; }

.u-b-r-30 {
  border-radius: 30px; }

.u-w-30 {
  width: 30%; }

.u-h-30 {
  height: 30%; }

.u-m-45 {
  margin: 45px; }

.u-m-l-45 {
  margin-left: 45px; }

.u-m-r-45 {
  margin-right: 45px; }

.u-m-b-45 {
  margin-bottom: 45px; }

.u-m-t-45 {
  margin-top: 45px; }

.u-p-45 {
  padding: 45px; }

.u-p-l-45 {
  padding-left: 45px; }

.u-p-r-45 {
  padding-right: 45px; }

.u-p-b-45 {
  padding-bottom: 45px; }

.u-p-t-45 {
  padding-top: 45px; }

.u-b-r-45 {
  border-radius: 45px; }

.u-w-45 {
  width: 45%; }

.u-h-45 {
  height: 45%; }

.u-m-50 {
  margin: 50px; }

.u-m-l-50 {
  margin-left: 50px; }

.u-m-r-50 {
  margin-right: 50px; }

.u-m-b-50 {
  margin-bottom: 50px; }

.u-m-t-50 {
  margin-top: 50px; }

.u-p-50 {
  padding: 50px; }

.u-p-l-50 {
  padding-left: 50px; }

.u-p-r-50 {
  padding-right: 50px; }

.u-p-b-50 {
  padding-bottom: 50px; }

.u-p-t-50 {
  padding-top: 50px; }

.u-b-r-50 {
  border-radius: 50px; }

.u-w-50 {
  width: 50%; }

.u-h-50 {
  height: 50%; }

.u-m-60 {
  margin: 60px; }

.u-m-l-60 {
  margin-left: 60px; }

.u-m-r-60 {
  margin-right: 60px; }

.u-m-b-60 {
  margin-bottom: 60px; }

.u-m-t-60 {
  margin-top: 60px; }

.u-p-60 {
  padding: 60px; }

.u-p-l-60 {
  padding-left: 60px; }

.u-p-r-60 {
  padding-right: 60px; }

.u-p-b-60 {
  padding-bottom: 60px; }

.u-p-t-60 {
  padding-top: 60px; }

.u-b-r-60 {
  border-radius: 60px; }

.u-w-60 {
  width: 60%; }

.u-h-60 {
  height: 60%; }

.u-m-70 {
  margin: 70px; }

.u-m-l-70 {
  margin-left: 70px; }

.u-m-r-70 {
  margin-right: 70px; }

.u-m-b-70 {
  margin-bottom: 70px; }

.u-m-t-70 {
  margin-top: 70px; }

.u-p-70 {
  padding: 70px; }

.u-p-l-70 {
  padding-left: 70px; }

.u-p-r-70 {
  padding-right: 70px; }

.u-p-b-70 {
  padding-bottom: 70px; }

.u-p-t-70 {
  padding-top: 70px; }

.u-b-r-70 {
  border-radius: 70px; }

.u-w-70 {
  width: 70%; }

.u-h-70 {
  height: 70%; }

.u-m-80 {
  margin: 80px; }

.u-m-l-80 {
  margin-left: 80px; }

.u-m-r-80 {
  margin-right: 80px; }

.u-m-b-80 {
  margin-bottom: 80px; }

.u-m-t-80 {
  margin-top: 80px; }

.u-p-80 {
  padding: 80px; }

.u-p-l-80 {
  padding-left: 80px; }

.u-p-r-80 {
  padding-right: 80px; }

.u-p-b-80 {
  padding-bottom: 80px; }

.u-p-t-80 {
  padding-top: 80px; }

.u-b-r-80 {
  border-radius: 80px; }

.u-w-80 {
  width: 80%; }

.u-h-80 {
  height: 80%; }

.u-m-90 {
  margin: 90px; }

.u-m-l-90 {
  margin-left: 90px; }

.u-m-r-90 {
  margin-right: 90px; }

.u-m-b-90 {
  margin-bottom: 90px; }

.u-m-t-90 {
  margin-top: 90px; }

.u-p-90 {
  padding: 90px; }

.u-p-l-90 {
  padding-left: 90px; }

.u-p-r-90 {
  padding-right: 90px; }

.u-p-b-90 {
  padding-bottom: 90px; }

.u-p-t-90 {
  padding-top: 90px; }

.u-b-r-90 {
  border-radius: 90px; }

.u-w-90 {
  width: 90%; }

.u-h-90 {
  height: 90%; }

.u-m-100 {
  margin: 100px; }

.u-m-l-100 {
  margin-left: 100px; }

.u-m-r-100 {
  margin-right: 100px; }

.u-m-b-100 {
  margin-bottom: 100px; }

.u-m-t-100 {
  margin-top: 100px; }

.u-p-100 {
  padding: 100px; }

.u-p-l-100 {
  padding-left: 100px; }

.u-p-r-100 {
  padding-right: 100px; }

.u-p-b-100 {
  padding-bottom: 100px; }

.u-p-t-100 {
  padding-top: 100px; }

.u-b-r-100 {
  border-radius: 100px; }

.u-w-100 {
  width: 100%; }

.u-h-100 {
  height: 100%; }

.has-position-absolute {
  position: absolute; }

.has-position-relative {
  position: relative; }

.has-position-static {
  position: static; }

.has-position-fixed {
  position: fixed; }

.has-position-sticky {
  position: -webkit-sticky;
  position: sticky; }

.align-items-stretch {
  display: flex;
  align-items: stretch; }

.align-items-center {
  display: flex;
  align-items: center; }

.align-items-flex-start {
  display: flex;
  align-items: flex-start; }

.align-items-flex-end {
  display: flex;
  align-items: flex-end; }

.justify-content-flex-start {
  display: flex;
  justify-content: flex-start; }

.justify-content-center {
  display: flex;
  justify-content: center; }

.justify-content-space-between {
  display: flex;
  justify-content: space-between; }

.justify-content-space-around {
  display: flex;
  justify-content: space-around; }

.justify-content-space-evenly {
  display: flex;
  justify-content: space-evenly; }

.has-flex-direction-row {
  display: flex;
  flex-direction: row; }

.has-flex-direction-row-reverse {
  display: flex;
  flex-direction: row-reverse; }

.has-flex-direction-column {
  display: flex;
  flex-direction: column; }

.has-flex-direction-column-reverse {
  display: flex;
  flex-direction: column-reverse; }

.has-flex-direction-row {
  display: flex;
  flex-direction: row; }

.has-flex-direction-row-reverse {
  display: flex;
  flex-direction: row-reverse; }

.has-flex-direction-column {
  display: flex;
  flex-direction: column; }

.has-flex-direction-column-reverse {
  display: flex;
  flex-direction: column-reverse; }

.has-white-space-normal {
  white-space: normal; }

.has-white-space-nowrap {
  white-space: nowrap; }

.has-white-space-pre {
  white-space: pre; }

.has-white-space-pre-wrap {
  white-space: pre-wrap; }

.has-white-space-pre-line {
  white-space: pre-line; }

.is-display-flex {
  display: flex; }

.is-display-block {
  display: block; }

.is-display-grid {
  display: grid; }

.is-display-unset {
  display: unset; }

.has-scrollbar-visible::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px; }

.has-scrollbar-visible::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }

a.is-danger {
  color: #cc3333; }

.app {
  text-align: left;
  margin: auto;
  max-width: 100vw;
  height: 100vh; }

.core {
  grid-area: core;
  display: grid;
  grid-template-areas: ". header" "sidebar view";
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  margin-top: 20px; }
  .core ul {
    margin: 0;
    padding: 0; }

.is-view {
  border-radius: 0px 0px 3px 3px;
  grid-area: view;
  box-shadow: none;
  z-index: 1;
  border: none;
  height: calc(100vh * 0.75);
  background: white;
  box-shadow: 2px 2px 6px #363636, -3px 2px 4px #2b2b2b91; }
  .is-view.is-splitted {
    display: grid;
    grid-template-columns: 1fr auto; }

.main {
  position: relative;
  height: inherit; }
  .main.scrollable {
    overflow: auto; }

.content.is-nlp-items {
  padding: 10px 0px 10px 0px;
  margin: 5px 0px 5px 0px;
  border-radius: 5px; }

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .content {
    width: 489px; } }

@media screen and (min-width: 1440px) and (max-width: 2559px) {
  .content {
    width: 755px; } }

@media screen and (min-width: 2560px) {
  .content {
    width: 2025px; } }

.content.perso-scenario {
  border: 1px solid #ededf7;
  display: grid;
  width: calc(100% - 550px);
  height: 100%;
  position: relative; }

.messagerie {
  border-bottom: 1px solid #EEE9FE; }
  .messagerie.contents {
    padding: 10px 20px; }
  .messagerie.header {
    justify-content: space-between;
    top: 0px;
    position: -webkit-sticky;
    position: sticky;
    background: white;
    height: 10%; }

.css-15k3avv {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  position: absolute;
  width: 100%;
  bottom: 40px !important;
  top: unset !important;
  z-index: 1;
  box-sizing: border-box; }

@media screen and (max-width: 768px) {
  .sizeContent {
    width: calc(100vw - 55px); } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .sizeContent {
    width: calc(100vw - 200px); } }

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .sizeContent {
    width: calc(1024px - 200px); } }

@media screen and (min-width: 1440px) and (max-width: 2559px) {
  .sizeContent {
    width: calc(1440px - 200px); } }

@media screen and (min-width: 2560px) {
  .sizeContent {
    width: calc(2560px - 200px); } }

.menu {
  width: 200px;
  position: relative;
  overflow: scroll;
  height: calc(100vh * 0.70);
  background: white;
  grid-area: sidebar;
  background: white;
  transition: 0.4s ease; }
  .menu.small {
    width: 55px; }
    .menu.small:hover {
      width: 200px; }

.link {
  background: #2d2f7b;
  list-style: none;
  float: left;
  font-weight: 600 !important;
  margin-top: 15px;
  z-index: 1; }
  .link:hover:not(.no-hover) {
    background: #dbdbf0 !important;
    border-radius: 2px;
    color: #7a7ab8; }

.mini-menu {
  position: relative;
  overflow: scroll;
  background: white;
  width: 280px; }

.fwh-scroll {
  height: 100vh;
  width: 100vw;
  overflow: scroll; }

.menu-label {
  text-align: center;
  background: #7a7ab8;
  padding: 7px;
  color: white;
  margin-bottom: 0px !important;
  border: none; }

.menu-list > li {
  list-style: none;
  margin: 0;
  padding: 0; }
  .menu-list > li a {
    padding: 0;
    margin: 0; }
    .menu-list > li a .menu-item {
      padding: 7px; }
      .menu-list > li a .menu-item.is-active {
        padding: 7px 7px 7px 16px;
        color: #dbdbf0;
        background: #5052be; }
  .menu-list > li .menu-item {
    display: flex;
    align-items: center; }
  .menu-list > li .checkbox {
    margin: 5px;
    justify-content: flex-start; }
    .menu-list > li .checkbox span {
      margin-left: 5px; }

.justify-content {
  display: flex; }
  .justify-content.start {
    justify-content: flex-start; }
  .justify-content.end {
    justify-content: flex-end; }

.menu-footer {
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 0px;
  height: 25px;
  background: #7a7ab8; }

.is-view-header {
  z-index: 2;
  grid-area: header;
  border-radius: 3px 3px 0px 0px; }

.flex-center {
  justify-content: center !important; }

.align-baseline {
  align-items: baseline !important; }

.shadow-bottom {
  box-shadow: -1px 1px 1px lightgrey; }

.shadow-right {
  box-shadow: 1px 1px 1px lightgrey; }

.pricingBox {
  position: fixed;
  overflow: auto;
  height: calc(100vh * 0.65); }
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    .pricingBox {
      width: 824px; } }
  @media screen and (min-width: 1440px) and (max-width: 2559px) {
    .pricingBox {
      width: 1240px; } }
  @media screen and (min-width: 2560px) {
    .pricingBox {
      width: 2360px; } }

.intentBox {
  overflow: auto;
  height: calc(100% - 150px); }

.panel {
  display: grid;
  grid-template-rows: 52px 1fr;
  grid-template-areas: "head" "core";
  height: 100%;
  margin: auto; }
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    .panel {
      width: 1024px; } }
  @media screen and (min-width: 1440px) and (max-width: 2559px) {
    .panel {
      width: 1440px; } }
  @media screen and (min-width: 2560px) {
    .panel {
      width: 2560px; } }

.head {
  grid-area: head;
  margin: auto; }
  @media screen and (max-width: 767px) {
    .head {
      width: 100vw;
      margin: unset; } }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .head {
      width: 100vw; } }
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    .head {
      width: 1024px; } }
  @media screen and (min-width: 1440px) and (max-width: 2559px) {
    .head {
      width: 1440px; } }
  @media screen and (min-width: 2560px) {
    .head {
      width: 2560px; } }

.word {
  word-break: break-word;
  white-space: pre-line; }

.navbar {
  z-index: 3;
  position: -webkit-sticky;
  position: sticky;
  background: #2d2f7b; }

.navbar-center {
  flex: 1 1;
  justify-content: center; }

.navbar-item-is-active {
  border-bottom: 1px solid white; }

.gradient-to-bottom-right {
  background: linear-gradient(to bottom right, #AD6BFF, #74BCF7); }

.gradient-to-right {
  background: linear-gradient(to right, #AD6BFF, #74BCF7); }

.is-bubble {
  position: relative;
  max-width: 400px;
  text-align: left;
  list-style: none;
  margin: 2px;
  margin-left: auto;
  padding: 10px;
  border-radius: 15px 15px 15px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  clear: both; }
  .is-bubble:hover {
    cursor: move; }
  .is-bubble.right {
    background: #ededf7;
    color: #7a7ab8;
    float: right;
    word-break: break-word;
    margin-left: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 400px; }
  .is-bubble.left {
    background: #dbdbf0;
    color: #7a7ab8;
    float: left;
    word-break: break-word;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 400px;
    margin-right: auto;
    margin-left: 0;
    margin-left: initial; }
  .is-bubble.carrousel {
    padding: unset;
    border-radius: unset;
    overflow: hidden;
    max-width: 100%; }
  .is-bubble.info {
    background: #c5c5c5;
    color: #636363; }

.bubble {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
  clear: both;
  float: right;
  z-index: 1;
  width: 100%;
  margin: 2px; }
  .bubble.mini {
    margin: 7px;
    white-space: pre-line;
    word-break: break-word;
    max-width: 85%; }

.has-flex-direction-row {
  display: flex;
  flex-direction: row; }

.has-flex-direction-row-reverse {
  display: flex;
  flex-direction: row-reverse; }

.has-flex-direction-column {
  display: flex;
  flex-direction: column; }

.has-flex-direction-column-reverse {
  display: flex;
  flex-direction: column-reverse; }

@media screen and (max-width: 768px) {
  .has-transform-0 {
    transform: translateX(0px); } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-transform-0 {
    transform: translateX(0px); } }

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .has-transform-0 {
    transform: translateX(0px); } }

@media screen and (min-width: 1440px) {
  .has-transform-0 {
    transform: translateX(0px); } }

@media screen and (max-width: 768px) {
  .has-transform-1 {
    transform: translateX(-172px); } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-transform-1 {
    transform: translateX(-202px); } }

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .has-transform-1 {
    transform: translateX(-242px); } }

@media screen and (min-width: 1440px) {
  .has-transform-1 {
    transform: translateX(-302px); } }

@media screen and (max-width: 768px) {
  .has-transform-2 {
    transform: translateX(-344px); } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-transform-2 {
    transform: translateX(-404px); } }

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .has-transform-2 {
    transform: translateX(-484px); } }

@media screen and (min-width: 1440px) {
  .has-transform-2 {
    transform: translateX(-604px); } }

@media screen and (max-width: 768px) {
  .has-transform-3 {
    transform: translateX(-516px); } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-transform-3 {
    transform: translateX(-606px); } }

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .has-transform-3 {
    transform: translateX(-726px); } }

@media screen and (min-width: 1440px) {
  .has-transform-3 {
    transform: translateX(-906px); } }

@media screen and (max-width: 768px) {
  .has-transform-4 {
    transform: translateX(-688px); } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-transform-4 {
    transform: translateX(-808px); } }

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .has-transform-4 {
    transform: translateX(-968px); } }

@media screen and (min-width: 1440px) {
  .has-transform-4 {
    transform: translateX(-1208px); } }

@media screen and (max-width: 768px) {
  .has-transform-5 {
    transform: translateX(-860px); } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-transform-5 {
    transform: translateX(-1010px); } }

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .has-transform-5 {
    transform: translateX(-1210px); } }

@media screen and (min-width: 1440px) {
  .has-transform-5 {
    transform: translateX(-1510px); } }

@media screen and (max-width: 768px) {
  .has-transform-6 {
    transform: translateX(-1032px); } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-transform-6 {
    transform: translateX(-1212px); } }

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .has-transform-6 {
    transform: translateX(-1452px); } }

@media screen and (min-width: 1440px) {
  .has-transform-6 {
    transform: translateX(-1812px); } }

@media screen and (max-width: 768px) {
  .has-transform-7 {
    transform: translateX(-1204px); } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-transform-7 {
    transform: translateX(-1414px); } }

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .has-transform-7 {
    transform: translateX(-1694px); } }

@media screen and (min-width: 1440px) {
  .has-transform-7 {
    transform: translateX(-2114px); } }

@media screen and (max-width: 768px) {
  .has-transform-8 {
    transform: translateX(-1376px); } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-transform-8 {
    transform: translateX(-1616px); } }

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .has-transform-8 {
    transform: translateX(-1936px); } }

@media screen and (min-width: 1440px) {
  .has-transform-8 {
    transform: translateX(-2416px); } }

@media screen and (max-width: 768px) {
  .has-transform-9 {
    transform: translateX(-1548px); } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-transform-9 {
    transform: translateX(-1818px); } }

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .has-transform-9 {
    transform: translateX(-2178px); } }

@media screen and (min-width: 1440px) {
  .has-transform-9 {
    transform: translateX(-2718px); } }

@media screen and (max-width: 768px) {
  .has-transform-10 {
    transform: translateX(-1720px); } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-transform-10 {
    transform: translateX(-2020px); } }

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .has-transform-10 {
    transform: translateX(-2420px); } }

@media screen and (min-width: 1440px) {
  .has-transform-10 {
    transform: translateX(-3020px); } }

@media screen and (max-width: 768px) {
  .has-transform-phone-0 {
    transform: translateX(0px); } }

@media screen and (min-width: 769px) {
  .has-transform-phone-0 {
    transform: translateX(0px); } }

@media screen and (max-width: 768px) {
  .has-transform-phone-1 {
    transform: translateX(-142px); } }

@media screen and (min-width: 769px) {
  .has-transform-phone-1 {
    transform: translateX(-172px); } }

@media screen and (max-width: 768px) {
  .has-transform-phone-2 {
    transform: translateX(-284px); } }

@media screen and (min-width: 769px) {
  .has-transform-phone-2 {
    transform: translateX(-344px); } }

@media screen and (max-width: 768px) {
  .has-transform-phone-3 {
    transform: translateX(-426px); } }

@media screen and (min-width: 769px) {
  .has-transform-phone-3 {
    transform: translateX(-516px); } }

@media screen and (max-width: 768px) {
  .has-transform-phone-4 {
    transform: translateX(-568px); } }

@media screen and (min-width: 769px) {
  .has-transform-phone-4 {
    transform: translateX(-688px); } }

@media screen and (max-width: 768px) {
  .has-transform-phone-5 {
    transform: translateX(-710px); } }

@media screen and (min-width: 769px) {
  .has-transform-phone-5 {
    transform: translateX(-860px); } }

@media screen and (max-width: 768px) {
  .has-transform-phone-6 {
    transform: translateX(-852px); } }

@media screen and (min-width: 769px) {
  .has-transform-phone-6 {
    transform: translateX(-1032px); } }

@media screen and (max-width: 768px) {
  .has-transform-phone-7 {
    transform: translateX(-994px); } }

@media screen and (min-width: 769px) {
  .has-transform-phone-7 {
    transform: translateX(-1204px); } }

@media screen and (max-width: 768px) {
  .has-transform-phone-8 {
    transform: translateX(-1136px); } }

@media screen and (min-width: 769px) {
  .has-transform-phone-8 {
    transform: translateX(-1376px); } }

@media screen and (max-width: 768px) {
  .has-transform-phone-9 {
    transform: translateX(-1278px); } }

@media screen and (min-width: 769px) {
  .has-transform-phone-9 {
    transform: translateX(-1548px); } }

@media screen and (max-width: 768px) {
  .has-transform-phone-10 {
    transform: translateX(-1420px); } }

@media screen and (min-width: 769px) {
  .has-transform-phone-10 {
    transform: translateX(-1720px); } }

@media screen and (max-width: 768px) {
  .cards {
    width: 170px; } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .cards {
    width: 200px; } }

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .cards {
    width: 240px; } }

@media screen and (min-width: 1440px) {
  .cards {
    width: 300px; } }

@media screen and (max-width: 768px) {
  .cards.phone {
    width: 140px; } }

@media screen and (min-width: 769px) {
  .cards.phone {
    width: 170px; } }

@media screen and (max-width: 768px) {
  .card-height {
    height: calc(170px / 1.91); } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .card-height {
    height: calc(200px / 1.91); } }

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .card-height {
    height: calc(240px / 1.91); } }

@media screen and (min-width: 1440px) {
  .card-height {
    height: calc(300px / 1.91); } }

@media screen and (max-width: 768px) {
  .card-height.phone {
    height: calc(140px / 1.91); } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .card-height.phone {
    height: calc(170px / 1.91); } }

.message-content {
  padding: 30px; }

.askmona-ui-qr {
  -ms-overflow-style: none; }
  .askmona-ui-qr ::-webkit-scrollbar {
    display: none;
    width: 0px;
    background: transparent; }

.chatbox {
  color: #7a7ab8;
  overflow: scroll;
  position: relative;
  border: 1px solid #dbdbf0;
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  height: inherit;
  width: 100%; }

.chatElem.header {
  color: #7a7ab8;
  padding: 10px;
  background: #ededf7; }

.chatElem.conversation {
  padding: 20px;
  clear: both;
  background: white;
  overflow: auto;
  height: inherit; }

.chatElem.footer {
  clear: both;
  bottom: 0; }

.botOptBox {
  max-width: 600px; }
  .botOptBox.is-large {
    max-width: 800px; }
  .botOptBox .is-description {
    width: 300px; }
  .botOptBox .checkbox:not(first-child) {
    border-bottom: 1px solid #dbdbf0; }
  .botOptBox .checkbox {
    padding: 15px; }

.libSelector {
  height: 150px;
  overflow: scroll;
  border: 1px solid #b5b5b5; }
  .libSelector.has-error {
    border: 1px solid #cc3333; }
  .libSelector .is-active {
    background-color: #c0c0e8; }
  .libSelector div {
    padding: 5px;
    border-bottom: 1px solid #b5b5b5; }

.is-top-right {
  position: absolute;
  top: 10px;
  right: 10px; }

.is-checkbox {
  width: 27px;
  height: 27px;
  background: transparent !important; }

.checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.teamPermBox .checkbox:not(first-child) {
  border-bottom: 1px solid #dbdbf0; }

.teamPermBox .checkbox {
  padding: 5px; }

.teamPermBox span {
  padding-right: 5px; }

.has-white-background {
  background: white; }

.has-border {
  border: 1px solid black; }

.has-border-grey-lighter {
  border: 1px solid #e6e6e6; }

.has-border-light {
  border: 1px solid #dbdbf0; }

.has-space-between {
  display: flex;
  justify-content: space-between; }

.has-margin-auto {
  margin: auto; }

.align-items-center {
  align-items: center; }

.has-all-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; }
  .has-all-centered.justify-content-unset {
    justify-content: unset; }
  .has-all-centered.vertical {
    flex-direction: column; }

.has-background-white {
  background: white; }

.has-background-askmona {
  background: #2d2f7b; }

.has-background-askmona-medium {
  background: #dbdbf0; }

.has-background-askmona-dark {
  background: #7a7ab8; }

.has-background-askmona-lighter {
  background: #ededf7; }

.has-background-askmona-lighter-lighter {
  background: #adaee1; }

.has-background-light {
  background: #ECE6FE; }

.has-background-askmona-light {
  background: #4d4db3; }

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.text-area {
  background: #ededf7; }
  .text-area.bubble {
    border-radius: 5px;
    border: 1px solid #e6e6e6;
    padding: inherit;
    font-size: 1em;
    width: 100%; }
  .text-area.card {
    border-radius: 3px;
    padding: 5px;
    border: none;
    height: 45px; }
    .text-area.card.danger {
      border: 1px solid #cc3333;
      outline: 0; }
  .text-area.perso-text {
    border-radius: 5px;
    padding: 5px;
    border: 1px solid #2d2f7b; }

.textarea {
  background: #ededf7; }
  .textarea.is-info {
    background: #209fee; }
  .textarea.chatbox {
    margin-left: 25px;
    margin-bottom: 25px;
    border-radius: 3px;
    height: 80px; }
  .textarea.logic {
    margin-left: 25px;
    margin-bottom: 25px;
    border-radius: 3px;
    height: 0.5vh; }
  .textarea.unset-size {
    max-width: unset;
    min-width: unset; }

.grey-grey {
  background: whitesmoke;
  color: dimgrey; }

.dark-white {
  background: #7a7ab8;
  color: white; }
  .dark-white.hover:hover {
    color: #4d4db3; }

.lighter-dark {
  background: #ededf7;
  color: #7a7ab8; }
  .lighter-dark.hover:hover {
    color: #2d2f7b; }

.light-dark {
  background: #4d4db3;
  color: #7a7ab8; }
  .light-dark.hover:hover {
    color: #2d2f7b; }

.medium-white {
  background: #dbdbf0;
  color: white; }
  .medium-white.hover:hover {
    color: #4d4db3; }

.white-dark {
  background: white;
  color: #7a7ab8; }
  .white-dark.hover:hover {
    color: #2d2f7b; }

.white-light-dark {
  background: white;
  color: #2d2f7b; }
  .white-light-dark.hover:hover {
    color: #ededf7; }

.background.is-askmona-medium {
  background: #dbdbf0; }

.background.is-askmona-light {
  background: #4d4db3; }

.background.is-askmona-lighter {
  background: #ededf7; }

.background.is-askmona-lighterbis {
  background: #f1f1f9; }

.background.is-black-transparent {
  background: rgba(50, 50, 50, 0.7); }

.background.is-primary {
  background: #2d2f7b; }

.background.is-white {
  background: white; }

.background.is-white-ter {
  background: whitesmoke; }

.background.is-light {
  background: #9a9cda; }
  .background.is-light.is-hoverable:hover {
    background: #9a9cda; }

.background.is-transparent {
  background: transparent !important; }
  .background.is-transparent.has-placeholder::-webkit-input-placeholder {
    color: white; }
  .background.is-transparent.has-placeholder::-ms-input-placeholder {
    color: white; }
  .background.is-transparent.has-placeholder::placeholder {
    color: white; }

.background.is-yellow {
  background: #ffe666; }

.border-lighter {
  border: 1px solid #ededf7; }

.border-light {
  border: 1px solid #4d4db3; }

.border-light-dark {
  border: 1px solid #2d2f7b; }

.border-medium {
  border: 1px solid #dbdbf0; }

.border-dark {
  border: 1px solid #7a7ab8; }

.has-margin-5 {
  margin: 5px; }

.has-margin-3 {
  margin: 3px; }

.has-transparent-background {
  color: white;
  background: transparent !important; }
  .has-transparent-background::-webkit-input-placeholder {
    color: white; }
  .has-transparent-background::-ms-input-placeholder {
    color: white; }
  .has-transparent-background::placeholder {
    color: white; }

.format-mediation-columns {
  background: white;
  display: grid;
  grid-template-columns: 42px repeat(5, 1fr); }

.format-team-columns {
  display: grid;
  white-space: pre-line;
  grid-template-columns: 150px 1fr  200px; }

.format-inbox-columns {
  display: grid;
  white-space: pre-line;
  grid-template-columns: 42px 100px 1fr 150px 150px; }

.format-pricing-columns {
  display: grid;
  white-space: pre-line;
  grid-template-columns: 42px 180px 1fr 1fr 50px 150px; }

.format-list-columns {
  background: white;
  display: grid;
  grid-template-columns: 42px repeat(7, 1fr); }

.format-list-header {
  background: white;
  display: grid;
  grid-template-columns: 42px repeat(7, 1fr);
  position: fixed;
  z-index: 1; }

.format-answer-columns {
  display: grid;
  grid-template-columns: 42px 1fr 1fr 42px;
  grid-template-areas: "content content content content" ". footer footer .";
  border: 1px solid white; }
  .format-answer-columns div:last-child {
    border-left: 1px solid white; }

.format-askInbox-columns {
  display: grid;
  grid-template-columns: 42px minmax(auto, 180px) 1fr 84px;
  grid-template-areas: "content content content content" ". footer footer .";
  border: 1px solid white; }
  .format-askInbox-columns div {
    border-left: 1px solid white; }
  .format-askInbox-columns .has-text-left {
    padding: 0px 15px 0px 15px;
    border-left: 1px solid #b5b5b5; }

.format-view-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "upper-left upper-right" "lower-left lower-right" "child child";
  grid-row-gap: 35px; }

.format-direction-columns {
  display: grid;
  grid-template-columns: 200px 1fr 100px 100px; }

.is-vertical-center {
  display: flex;
  align-items: center !important; }

.is-240x160 {
  height: 160px;
  width: 240px; }

.field .control {
  margin: 3px 0px 0px 0px; }
  .field .control.is-rounded {
    border-radius: 25%; }

.no-outline {
  outline: none !important; }

.has-text-right .button {
  margin: 10px 0px 10px 10px; }

.is-absolute {
  position: absolute; }
  .is-absolute.bottom {
    bottom: 0px; }
  .is-absolute.is-bottom-right {
    top: 10px;
    right: 10px; }
  .is-absolute.top-right {
    top: 10px;
    right: 10px; }

.hover-lighter:hover {
  cursor: pointer;
  background: #ededf7; }

.has-hoverable-cursor:hover {
  cursor: pointer; }

.has-cursor-initial:hover {
  cursor: auto;
  cursor: initial; }

.is-size-8 {
  font-size: 0.7em; }

.is-size-6-5 {
  font-size: 0.90rem; }

.control.autosize textarea {
  width: 100%;
  height: 36px;
  min-height: 36px;
  resize: none;
  border: 1px solid #e6e6e6;
  border-radius: 3px 0px 0px 3px;
  padding: 7px;
  font-size: 1rem; }
  .control.autosize textarea.border-danger {
    border: 1px solid #cc3333; }

#case {
  height: 480px;
  width: 240px;
  border-radius: 40px 40px;
  border: 1px solid #ccc;
  margin: auto 50px;
  box-shadow: -22px 3px 300px -90px rgba(0, 0, 0, 0.75); }
  @media screen and (max-width: 768px) {
    #case {
      width: 200px;
      height: 420px; } }

#earsection {
  margin-top: 20px;
  margin-left: 92px;
  height: 16px; }

#camera {
  float: left;
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  border-radius: 9px 9px; }

#speaker {
  float: left;
  width: 54px;
  height: 5px;
  margin-left: 15px;
  border: 1px solid #ccc;
  border-radius: 7px 7px; }

#screenOLD {
  overflow: scroll;
  clear: both;
  width: 230px;
  height: 374px;
  margin: auto;
  margin-top: 10px;
  border: 1px solid #ccc;
  background: white; }
  @media screen and (max-width: 768px) {
    #screenOLD {
      width: 190px;
      height: 324px; } }

#buttonOLD {
  height: 30px;
  width: 30px;
  border: 1px solid #ccc;
  border-radius: 28px 28px;
  margin: auto;
  margin-top: 12px; }

#buttonsquare {
  height: 12px;
  width: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin: auto;
  margin-top: 8px; }

input[type=checkbox].checkbox {
  position: absolute;
  left: -9999px; }
  input[type=checkbox].checkbox + label {
    position: relative;
    display: block;
    padding: 6px 10px 6px 22px;
    cursor: pointer; }
    input[type=checkbox].checkbox + label:before {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      margin: -6px 0 0;
      width: 12px;
      height: 12px;
      border: 1px solid #e6e6e6;
      background: white; }
  input[type=checkbox].checkbox:checked + label:before {
    background-position: -12px 0;
    background: #2d2f7b; }

.label {
  color: #7a7ab8; }

.border-bottom {
  border-bottom: 1px solid #f5f5f5; }

.border-none {
  border: none; }

.is-loading {
  border: none !important; }

.react-select__control {
  background: #ededf7 !important; }

.css-15k3avv {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  position: absolute;
  width: 100%;
  bottom: 40px !important;
  top: unset !important;
  z-index: 1;
  box-sizing: border-box; }

.css-bl6clz {
  background: #7a7ab8 !important; }

.select.white::after {
  border-color: white !important; }

.navbar-link::after {
  border-color: white !important; }

#userMenu::hover {
  background: #dbdbf0 !important;
  border-radius: 2px; }

.has-text-underline {
  text-decoration: underline !important; }

.triangle-up {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 17.5px 15px 17.5px;
  border-color: transparent transparent #e6e6e6 transparent; }

.loginBtn {
  box-sizing: border-box;
  position: relative;
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #FFF; }
  .loginBtn:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 100%; }
  .loginBtn:focus {
    outline: none; }
  .loginBtn:active {
    box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1); }

.loginBtn--facebook {
  background-color: #4C69BA;
  background-image: linear-gradient(#4C69BA, #3B55A0);
  text-shadow: 0 -1px 0 #354C8C; }
  .loginBtn--facebook:before {
    border-right: #364e92 1px solid;
    background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png") 6px 6px no-repeat; }
  .loginBtn--facebook:hover, .loginBtn--facebook:focus {
    cursor: pointer;
    background-color: #5B7BD5;
    background-image: linear-gradient(#5B7BD5, #4864B1); }

.is-wrap {
  display: flex;
  flex-wrap: wrap; }

.askmona-ui-input {
  width: 100%; }

.m-none {
  margin: 0; }

.p-none {
  padding: 0; }

.m-t-none {
  margin-top: 0; }

.p-t-none {
  padding-top: 0; }

.m-r-none {
  margin-right: 0; }

.p-r-none {
  padding-right: 0; }

.m-b-none {
  margin-bottom: 0; }

.p-b-none {
  padding-bottom: 0; }

.m-l-none {
  margin-left: 0; }

.p-l-none {
  padding-left: 0; }

.m-xxs {
  margin: 0.125rem; }

.p-xxs {
  padding: 0.125rem; }

.m-t-xxs {
  margin-top: 0.125rem; }

.p-t-xxs {
  padding-top: 0.125rem; }

.m-r-xxs {
  margin-right: 0.125rem; }

.p-r-xxs {
  padding-right: 0.125rem; }

.m-b-xxs {
  margin-bottom: 0.125rem; }

.p-b-xxs {
  padding-bottom: 0.125rem; }

.m-l-xxs {
  margin-left: 0.125rem; }

.p-l-xxs {
  padding-left: 0.125rem; }

.m-xs {
  margin: 0.25rem; }

.p-xs {
  padding: 0.25rem; }

.m-t-xs {
  margin-top: 0.25rem; }

.p-t-xs {
  padding-top: 0.25rem; }

.m-r-xs {
  margin-right: 0.25rem; }

.p-r-xs {
  padding-right: 0.25rem; }

.m-b-xs {
  margin-bottom: 0.25rem; }

.p-b-xs {
  padding-bottom: 0.25rem; }

.m-l-xs {
  margin-left: 0.25rem; }

.p-l-xs {
  padding-left: 0.25rem; }

.m-sm {
  margin: 0.6rem; }

.p-sm {
  padding: 0.6rem; }

.m-t-sm {
  margin-top: 0.6rem; }

.p-t-sm {
  padding-top: 0.6rem; }

.m-r-sm {
  margin-right: 0.6rem; }

.p-r-sm {
  padding-right: 0.6rem; }

.m-b-sm {
  margin-bottom: 0.6rem; }

.p-b-sm {
  padding-bottom: 0.6rem; }

.m-l-sm {
  margin-left: 0.6rem; }

.p-l-sm {
  padding-left: 0.6rem; }

.m-md {
  margin: 1rem; }

.p-md {
  padding: 1rem; }

.m-t-md {
  margin-top: 1rem; }

.p-t-md {
  padding-top: 1rem; }

.m-r-md {
  margin-right: 1rem; }

.p-r-md {
  padding-right: 1rem; }

.m-b-md {
  margin-bottom: 1rem; }

.p-b-md {
  padding-bottom: 1rem; }

.m-l-md {
  margin-left: 1rem; }

.p-l-md {
  padding-left: 1rem; }

.m-lg {
  margin: 2rem; }

.p-lg {
  padding: 2rem; }

.m-t-lg {
  margin-top: 2rem; }

.p-t-lg {
  padding-top: 2rem; }

.m-r-lg {
  margin-right: 2rem; }

.p-r-lg {
  padding-right: 2rem; }

.m-b-lg {
  margin-bottom: 2rem; }

.p-b-lg {
  padding-bottom: 2rem; }

.m-l-lg {
  margin-left: 2rem; }

.p-l-lg {
  padding-left: 2rem; }

.m-xl {
  margin: 4rem; }

.p-xl {
  padding: 4rem; }

.m-t-xl {
  margin-top: 4rem; }

.p-t-xl {
  padding-top: 4rem; }

.m-r-xl {
  margin-right: 4rem; }

.p-r-xl {
  padding-right: 4rem; }

.m-b-xl {
  margin-bottom: 4rem; }

.p-b-xl {
  padding-bottom: 4rem; }

.m-l-xl {
  margin-left: 4rem; }

.p-l-xl {
  padding-left: 4rem; }

.m-xxl {
  margin: 8rem; }

.p-xxl {
  padding: 8rem; }

.m-t-xxl {
  margin-top: 8rem; }

.p-t-xxl {
  padding-top: 8rem; }

.m-r-xxl {
  margin-right: 8rem; }

.p-r-xxl {
  padding-right: 8rem; }

.m-b-xxl {
  margin-bottom: 8rem; }

.p-b-xxl {
  padding-bottom: 8rem; }

.m-l-xxl {
  margin-left: 8rem; }

.p-l-xxl {
  padding-left: 8rem; }

.m-none {
  margin: 0; }

.p-none {
  padding: 0; }

.m-t-none {
  margin-top: 0; }

.p-t-none {
  padding-top: 0; }

.m-r-none {
  margin-right: 0; }

.p-r-none {
  padding-right: 0; }

.m-b-none {
  margin-bottom: 0; }

.p-b-none {
  padding-bottom: 0; }

.m-l-none {
  margin-left: 0; }

.p-l-none {
  padding-left: 0; }

.m-xxs {
  margin: 0.125rem; }

.p-xxs {
  padding: 0.125rem; }

.m-t-xxs {
  margin-top: 0.125rem; }

.p-t-xxs {
  padding-top: 0.125rem; }

.m-r-xxs {
  margin-right: 0.125rem; }

.p-r-xxs {
  padding-right: 0.125rem; }

.m-b-xxs {
  margin-bottom: 0.125rem; }

.p-b-xxs {
  padding-bottom: 0.125rem; }

.m-l-xxs {
  margin-left: 0.125rem; }

.p-l-xxs {
  padding-left: 0.125rem; }

.m-xs {
  margin: 0.25rem; }

.p-xs {
  padding: 0.25rem; }

.m-t-xs {
  margin-top: 0.25rem; }

.p-t-xs {
  padding-top: 0.25rem; }

.m-r-xs {
  margin-right: 0.25rem; }

.p-r-xs {
  padding-right: 0.25rem; }

.m-b-xs {
  margin-bottom: 0.25rem; }

.p-b-xs {
  padding-bottom: 0.25rem; }

.m-l-xs {
  margin-left: 0.25rem; }

.p-l-xs {
  padding-left: 0.25rem; }

.m-sm {
  margin: 0.6rem; }

.p-sm {
  padding: 0.6rem; }

.m-t-sm {
  margin-top: 0.6rem; }

.p-t-sm {
  padding-top: 0.6rem; }

.m-r-sm {
  margin-right: 0.6rem; }

.p-r-sm {
  padding-right: 0.6rem; }

.m-b-sm {
  margin-bottom: 0.6rem; }

.p-b-sm {
  padding-bottom: 0.6rem; }

.m-l-sm {
  margin-left: 0.6rem; }

.p-l-sm {
  padding-left: 0.6rem; }

.m-md {
  margin: 1rem; }

.p-md {
  padding: 1rem; }

.m-t-md {
  margin-top: 1rem; }

.p-t-md {
  padding-top: 1rem; }

.m-r-md {
  margin-right: 1rem; }

.p-r-md {
  padding-right: 1rem; }

.m-b-md {
  margin-bottom: 1rem; }

.p-b-md {
  padding-bottom: 1rem; }

.m-l-md {
  margin-left: 1rem; }

.p-l-md {
  padding-left: 1rem; }

.m-lg {
  margin: 2rem; }

.p-lg {
  padding: 2rem; }

.m-t-lg {
  margin-top: 2rem; }

.p-t-lg {
  padding-top: 2rem; }

.m-r-lg {
  margin-right: 2rem; }

.p-r-lg {
  padding-right: 2rem; }

.m-b-lg {
  margin-bottom: 2rem; }

.p-b-lg {
  padding-bottom: 2rem; }

.m-l-lg {
  margin-left: 2rem; }

.p-l-lg {
  padding-left: 2rem; }

.m-xl {
  margin: 4rem; }

.p-xl {
  padding: 4rem; }

.m-t-xl {
  margin-top: 4rem; }

.p-t-xl {
  padding-top: 4rem; }

.m-r-xl {
  margin-right: 4rem; }

.p-r-xl {
  padding-right: 4rem; }

.m-b-xl {
  margin-bottom: 4rem; }

.p-b-xl {
  padding-bottom: 4rem; }

.m-l-xl {
  margin-left: 4rem; }

.p-l-xl {
  padding-left: 4rem; }

.m-xxl {
  margin: 8rem; }

.p-xxl {
  padding: 8rem; }

.m-t-xxl {
  margin-top: 8rem; }

.p-t-xxl {
  padding-top: 8rem; }

.m-r-xxl {
  margin-right: 8rem; }

.p-r-xxl {
  padding-right: 8rem; }

.m-b-xxl {
  margin-bottom: 8rem; }

.p-b-xxl {
  padding-bottom: 8rem; }

.m-l-xxl {
  margin-left: 8rem; }

.p-l-xxl {
  padding-left: 8rem; }

.has-text-primary {
  color: #2d2f7b; }

.has-background-primary {
  background-color: #2d2f7b; }

.border-primary {
  border: 1px solid #2d2f7b; }

.border-m-primary:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #2d2f7b;
  transform: translateY(-50%); }

.border-primary-dotted {
  border: 1px dotted #2d2f7b; }

.border-primary-dashed {
  border: 1px dashed #2d2f7b; }

.border-primary-solid {
  border: 1px solid #2d2f7b; }

.border-primary-double {
  border: 1px double #2d2f7b; }

.border-primary-groove {
  border: 1px groove #2d2f7b; }

.border-primary-ridge {
  border: 1px ridge #2d2f7b; }

.border-primary-inset {
  border: 1px inset #2d2f7b; }

.border-primary-outset {
  border: 1px outset #2d2f7b; }

.border-primary-none {
  border: 1px none #2d2f7b; }

.border-primary-hidden {
  border: 1px hidden #2d2f7b; }

.border-t-primary {
  border-top: 1px solid #2d2f7b; }

.border-t-primary-dotted {
  border-top: 1px dotted #2d2f7b; }

.border-t-primary-dashed {
  border-top: 1px dashed #2d2f7b; }

.border-t-primary-solid {
  border-top: 1px solid #2d2f7b; }

.border-t-primary-double {
  border-top: 1px double #2d2f7b; }

.border-t-primary-groove {
  border-top: 1px groove #2d2f7b; }

.border-t-primary-ridge {
  border-top: 1px ridge #2d2f7b; }

.border-t-primary-inset {
  border-top: 1px inset #2d2f7b; }

.border-t-primary-outset {
  border-top: 1px outset #2d2f7b; }

.border-t-primary-none {
  border-top: 1px none #2d2f7b; }

.border-t-primary-hidden {
  border-top: 1px hidden #2d2f7b; }

.border-r-primary {
  border-right: 1px solid #2d2f7b; }

.border-r-primary-dotted {
  border-right: 1px dotted #2d2f7b; }

.border-r-primary-dashed {
  border-right: 1px dashed #2d2f7b; }

.border-r-primary-solid {
  border-right: 1px solid #2d2f7b; }

.border-r-primary-double {
  border-right: 1px double #2d2f7b; }

.border-r-primary-groove {
  border-right: 1px groove #2d2f7b; }

.border-r-primary-ridge {
  border-right: 1px ridge #2d2f7b; }

.border-r-primary-inset {
  border-right: 1px inset #2d2f7b; }

.border-r-primary-outset {
  border-right: 1px outset #2d2f7b; }

.border-r-primary-none {
  border-right: 1px none #2d2f7b; }

.border-r-primary-hidden {
  border-right: 1px hidden #2d2f7b; }

.border-b-primary {
  border-bottom: 1px solid #2d2f7b; }

.border-b-primary-dotted {
  border-bottom: 1px dotted #2d2f7b; }

.border-b-primary-dashed {
  border-bottom: 1px dashed #2d2f7b; }

.border-b-primary-solid {
  border-bottom: 1px solid #2d2f7b; }

.border-b-primary-double {
  border-bottom: 1px double #2d2f7b; }

.border-b-primary-groove {
  border-bottom: 1px groove #2d2f7b; }

.border-b-primary-ridge {
  border-bottom: 1px ridge #2d2f7b; }

.border-b-primary-inset {
  border-bottom: 1px inset #2d2f7b; }

.border-b-primary-outset {
  border-bottom: 1px outset #2d2f7b; }

.border-b-primary-none {
  border-bottom: 1px none #2d2f7b; }

.border-b-primary-hidden {
  border-bottom: 1px hidden #2d2f7b; }

.border-l-primary {
  border-left: 1px solid #2d2f7b; }

.border-l-primary-dotted {
  border-left: 1px dotted #2d2f7b; }

.border-l-primary-dashed {
  border-left: 1px dashed #2d2f7b; }

.border-l-primary-solid {
  border-left: 1px solid #2d2f7b; }

.border-l-primary-double {
  border-left: 1px double #2d2f7b; }

.border-l-primary-groove {
  border-left: 1px groove #2d2f7b; }

.border-l-primary-ridge {
  border-left: 1px ridge #2d2f7b; }

.border-l-primary-inset {
  border-left: 1px inset #2d2f7b; }

.border-l-primary-outset {
  border-left: 1px outset #2d2f7b; }

.border-l-primary-none {
  border-left: 1px none #2d2f7b; }

.border-l-primary-hidden {
  border-left: 1px hidden #2d2f7b; }

.has-text-primary-bis {
  color: #4d4db3; }

.has-background-primary-bis {
  background-color: #4d4db3; }

.border-primary-bis {
  border: 1px solid #4d4db3; }

.border-m-primary-bis:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #4d4db3;
  transform: translateY(-50%); }

.border-primary-bis-dotted {
  border: 1px dotted #4d4db3; }

.border-primary-bis-dashed {
  border: 1px dashed #4d4db3; }

.border-primary-bis-solid {
  border: 1px solid #4d4db3; }

.border-primary-bis-double {
  border: 1px double #4d4db3; }

.border-primary-bis-groove {
  border: 1px groove #4d4db3; }

.border-primary-bis-ridge {
  border: 1px ridge #4d4db3; }

.border-primary-bis-inset {
  border: 1px inset #4d4db3; }

.border-primary-bis-outset {
  border: 1px outset #4d4db3; }

.border-primary-bis-none {
  border: 1px none #4d4db3; }

.border-primary-bis-hidden {
  border: 1px hidden #4d4db3; }

.border-t-primary-bis {
  border-top: 1px solid #4d4db3; }

.border-t-primary-bis-dotted {
  border-top: 1px dotted #4d4db3; }

.border-t-primary-bis-dashed {
  border-top: 1px dashed #4d4db3; }

.border-t-primary-bis-solid {
  border-top: 1px solid #4d4db3; }

.border-t-primary-bis-double {
  border-top: 1px double #4d4db3; }

.border-t-primary-bis-groove {
  border-top: 1px groove #4d4db3; }

.border-t-primary-bis-ridge {
  border-top: 1px ridge #4d4db3; }

.border-t-primary-bis-inset {
  border-top: 1px inset #4d4db3; }

.border-t-primary-bis-outset {
  border-top: 1px outset #4d4db3; }

.border-t-primary-bis-none {
  border-top: 1px none #4d4db3; }

.border-t-primary-bis-hidden {
  border-top: 1px hidden #4d4db3; }

.border-r-primary-bis {
  border-right: 1px solid #4d4db3; }

.border-r-primary-bis-dotted {
  border-right: 1px dotted #4d4db3; }

.border-r-primary-bis-dashed {
  border-right: 1px dashed #4d4db3; }

.border-r-primary-bis-solid {
  border-right: 1px solid #4d4db3; }

.border-r-primary-bis-double {
  border-right: 1px double #4d4db3; }

.border-r-primary-bis-groove {
  border-right: 1px groove #4d4db3; }

.border-r-primary-bis-ridge {
  border-right: 1px ridge #4d4db3; }

.border-r-primary-bis-inset {
  border-right: 1px inset #4d4db3; }

.border-r-primary-bis-outset {
  border-right: 1px outset #4d4db3; }

.border-r-primary-bis-none {
  border-right: 1px none #4d4db3; }

.border-r-primary-bis-hidden {
  border-right: 1px hidden #4d4db3; }

.border-b-primary-bis {
  border-bottom: 1px solid #4d4db3; }

.border-b-primary-bis-dotted {
  border-bottom: 1px dotted #4d4db3; }

.border-b-primary-bis-dashed {
  border-bottom: 1px dashed #4d4db3; }

.border-b-primary-bis-solid {
  border-bottom: 1px solid #4d4db3; }

.border-b-primary-bis-double {
  border-bottom: 1px double #4d4db3; }

.border-b-primary-bis-groove {
  border-bottom: 1px groove #4d4db3; }

.border-b-primary-bis-ridge {
  border-bottom: 1px ridge #4d4db3; }

.border-b-primary-bis-inset {
  border-bottom: 1px inset #4d4db3; }

.border-b-primary-bis-outset {
  border-bottom: 1px outset #4d4db3; }

.border-b-primary-bis-none {
  border-bottom: 1px none #4d4db3; }

.border-b-primary-bis-hidden {
  border-bottom: 1px hidden #4d4db3; }

.border-l-primary-bis {
  border-left: 1px solid #4d4db3; }

.border-l-primary-bis-dotted {
  border-left: 1px dotted #4d4db3; }

.border-l-primary-bis-dashed {
  border-left: 1px dashed #4d4db3; }

.border-l-primary-bis-solid {
  border-left: 1px solid #4d4db3; }

.border-l-primary-bis-double {
  border-left: 1px double #4d4db3; }

.border-l-primary-bis-groove {
  border-left: 1px groove #4d4db3; }

.border-l-primary-bis-ridge {
  border-left: 1px ridge #4d4db3; }

.border-l-primary-bis-inset {
  border-left: 1px inset #4d4db3; }

.border-l-primary-bis-outset {
  border-left: 1px outset #4d4db3; }

.border-l-primary-bis-none {
  border-left: 1px none #4d4db3; }

.border-l-primary-bis-hidden {
  border-left: 1px hidden #4d4db3; }

.has-text-primary-ter {
  color: #7a7ab8; }

.has-background-primary-ter {
  background-color: #7a7ab8; }

.border-primary-ter {
  border: 1px solid #7a7ab8; }

.border-m-primary-ter:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #7a7ab8;
  transform: translateY(-50%); }

.border-primary-ter-dotted {
  border: 1px dotted #7a7ab8; }

.border-primary-ter-dashed {
  border: 1px dashed #7a7ab8; }

.border-primary-ter-solid {
  border: 1px solid #7a7ab8; }

.border-primary-ter-double {
  border: 1px double #7a7ab8; }

.border-primary-ter-groove {
  border: 1px groove #7a7ab8; }

.border-primary-ter-ridge {
  border: 1px ridge #7a7ab8; }

.border-primary-ter-inset {
  border: 1px inset #7a7ab8; }

.border-primary-ter-outset {
  border: 1px outset #7a7ab8; }

.border-primary-ter-none {
  border: 1px none #7a7ab8; }

.border-primary-ter-hidden {
  border: 1px hidden #7a7ab8; }

.border-t-primary-ter {
  border-top: 1px solid #7a7ab8; }

.border-t-primary-ter-dotted {
  border-top: 1px dotted #7a7ab8; }

.border-t-primary-ter-dashed {
  border-top: 1px dashed #7a7ab8; }

.border-t-primary-ter-solid {
  border-top: 1px solid #7a7ab8; }

.border-t-primary-ter-double {
  border-top: 1px double #7a7ab8; }

.border-t-primary-ter-groove {
  border-top: 1px groove #7a7ab8; }

.border-t-primary-ter-ridge {
  border-top: 1px ridge #7a7ab8; }

.border-t-primary-ter-inset {
  border-top: 1px inset #7a7ab8; }

.border-t-primary-ter-outset {
  border-top: 1px outset #7a7ab8; }

.border-t-primary-ter-none {
  border-top: 1px none #7a7ab8; }

.border-t-primary-ter-hidden {
  border-top: 1px hidden #7a7ab8; }

.border-r-primary-ter {
  border-right: 1px solid #7a7ab8; }

.border-r-primary-ter-dotted {
  border-right: 1px dotted #7a7ab8; }

.border-r-primary-ter-dashed {
  border-right: 1px dashed #7a7ab8; }

.border-r-primary-ter-solid {
  border-right: 1px solid #7a7ab8; }

.border-r-primary-ter-double {
  border-right: 1px double #7a7ab8; }

.border-r-primary-ter-groove {
  border-right: 1px groove #7a7ab8; }

.border-r-primary-ter-ridge {
  border-right: 1px ridge #7a7ab8; }

.border-r-primary-ter-inset {
  border-right: 1px inset #7a7ab8; }

.border-r-primary-ter-outset {
  border-right: 1px outset #7a7ab8; }

.border-r-primary-ter-none {
  border-right: 1px none #7a7ab8; }

.border-r-primary-ter-hidden {
  border-right: 1px hidden #7a7ab8; }

.border-b-primary-ter {
  border-bottom: 1px solid #7a7ab8; }

.border-b-primary-ter-dotted {
  border-bottom: 1px dotted #7a7ab8; }

.border-b-primary-ter-dashed {
  border-bottom: 1px dashed #7a7ab8; }

.border-b-primary-ter-solid {
  border-bottom: 1px solid #7a7ab8; }

.border-b-primary-ter-double {
  border-bottom: 1px double #7a7ab8; }

.border-b-primary-ter-groove {
  border-bottom: 1px groove #7a7ab8; }

.border-b-primary-ter-ridge {
  border-bottom: 1px ridge #7a7ab8; }

.border-b-primary-ter-inset {
  border-bottom: 1px inset #7a7ab8; }

.border-b-primary-ter-outset {
  border-bottom: 1px outset #7a7ab8; }

.border-b-primary-ter-none {
  border-bottom: 1px none #7a7ab8; }

.border-b-primary-ter-hidden {
  border-bottom: 1px hidden #7a7ab8; }

.border-l-primary-ter {
  border-left: 1px solid #7a7ab8; }

.border-l-primary-ter-dotted {
  border-left: 1px dotted #7a7ab8; }

.border-l-primary-ter-dashed {
  border-left: 1px dashed #7a7ab8; }

.border-l-primary-ter-solid {
  border-left: 1px solid #7a7ab8; }

.border-l-primary-ter-double {
  border-left: 1px double #7a7ab8; }

.border-l-primary-ter-groove {
  border-left: 1px groove #7a7ab8; }

.border-l-primary-ter-ridge {
  border-left: 1px ridge #7a7ab8; }

.border-l-primary-ter-inset {
  border-left: 1px inset #7a7ab8; }

.border-l-primary-ter-outset {
  border-left: 1px outset #7a7ab8; }

.border-l-primary-ter-none {
  border-left: 1px none #7a7ab8; }

.border-l-primary-ter-hidden {
  border-left: 1px hidden #7a7ab8; }

.has-text-primary-qua {
  color: #b9b9e9; }

.has-background-primary-qua {
  background-color: #b9b9e9; }

.border-primary-qua {
  border: 1px solid #b9b9e9; }

.border-m-primary-qua:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b9b9e9;
  transform: translateY(-50%); }

.border-primary-qua-dotted {
  border: 1px dotted #b9b9e9; }

.border-primary-qua-dashed {
  border: 1px dashed #b9b9e9; }

.border-primary-qua-solid {
  border: 1px solid #b9b9e9; }

.border-primary-qua-double {
  border: 1px double #b9b9e9; }

.border-primary-qua-groove {
  border: 1px groove #b9b9e9; }

.border-primary-qua-ridge {
  border: 1px ridge #b9b9e9; }

.border-primary-qua-inset {
  border: 1px inset #b9b9e9; }

.border-primary-qua-outset {
  border: 1px outset #b9b9e9; }

.border-primary-qua-none {
  border: 1px none #b9b9e9; }

.border-primary-qua-hidden {
  border: 1px hidden #b9b9e9; }

.border-t-primary-qua {
  border-top: 1px solid #b9b9e9; }

.border-t-primary-qua-dotted {
  border-top: 1px dotted #b9b9e9; }

.border-t-primary-qua-dashed {
  border-top: 1px dashed #b9b9e9; }

.border-t-primary-qua-solid {
  border-top: 1px solid #b9b9e9; }

.border-t-primary-qua-double {
  border-top: 1px double #b9b9e9; }

.border-t-primary-qua-groove {
  border-top: 1px groove #b9b9e9; }

.border-t-primary-qua-ridge {
  border-top: 1px ridge #b9b9e9; }

.border-t-primary-qua-inset {
  border-top: 1px inset #b9b9e9; }

.border-t-primary-qua-outset {
  border-top: 1px outset #b9b9e9; }

.border-t-primary-qua-none {
  border-top: 1px none #b9b9e9; }

.border-t-primary-qua-hidden {
  border-top: 1px hidden #b9b9e9; }

.border-r-primary-qua {
  border-right: 1px solid #b9b9e9; }

.border-r-primary-qua-dotted {
  border-right: 1px dotted #b9b9e9; }

.border-r-primary-qua-dashed {
  border-right: 1px dashed #b9b9e9; }

.border-r-primary-qua-solid {
  border-right: 1px solid #b9b9e9; }

.border-r-primary-qua-double {
  border-right: 1px double #b9b9e9; }

.border-r-primary-qua-groove {
  border-right: 1px groove #b9b9e9; }

.border-r-primary-qua-ridge {
  border-right: 1px ridge #b9b9e9; }

.border-r-primary-qua-inset {
  border-right: 1px inset #b9b9e9; }

.border-r-primary-qua-outset {
  border-right: 1px outset #b9b9e9; }

.border-r-primary-qua-none {
  border-right: 1px none #b9b9e9; }

.border-r-primary-qua-hidden {
  border-right: 1px hidden #b9b9e9; }

.border-b-primary-qua {
  border-bottom: 1px solid #b9b9e9; }

.border-b-primary-qua-dotted {
  border-bottom: 1px dotted #b9b9e9; }

.border-b-primary-qua-dashed {
  border-bottom: 1px dashed #b9b9e9; }

.border-b-primary-qua-solid {
  border-bottom: 1px solid #b9b9e9; }

.border-b-primary-qua-double {
  border-bottom: 1px double #b9b9e9; }

.border-b-primary-qua-groove {
  border-bottom: 1px groove #b9b9e9; }

.border-b-primary-qua-ridge {
  border-bottom: 1px ridge #b9b9e9; }

.border-b-primary-qua-inset {
  border-bottom: 1px inset #b9b9e9; }

.border-b-primary-qua-outset {
  border-bottom: 1px outset #b9b9e9; }

.border-b-primary-qua-none {
  border-bottom: 1px none #b9b9e9; }

.border-b-primary-qua-hidden {
  border-bottom: 1px hidden #b9b9e9; }

.border-l-primary-qua {
  border-left: 1px solid #b9b9e9; }

.border-l-primary-qua-dotted {
  border-left: 1px dotted #b9b9e9; }

.border-l-primary-qua-dashed {
  border-left: 1px dashed #b9b9e9; }

.border-l-primary-qua-solid {
  border-left: 1px solid #b9b9e9; }

.border-l-primary-qua-double {
  border-left: 1px double #b9b9e9; }

.border-l-primary-qua-groove {
  border-left: 1px groove #b9b9e9; }

.border-l-primary-qua-ridge {
  border-left: 1px ridge #b9b9e9; }

.border-l-primary-qua-inset {
  border-left: 1px inset #b9b9e9; }

.border-l-primary-qua-outset {
  border-left: 1px outset #b9b9e9; }

.border-l-primary-qua-none {
  border-left: 1px none #b9b9e9; }

.border-l-primary-qua-hidden {
  border-left: 1px hidden #b9b9e9; }

.has-text-primary-qui {
  color: #b8b8d6; }

.has-background-primary-qui {
  background-color: #b8b8d6; }

.border-primary-qui {
  border: 1px solid #b8b8d6; }

.border-m-primary-qui:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b8b8d6;
  transform: translateY(-50%); }

.border-primary-qui-dotted {
  border: 1px dotted #b8b8d6; }

.border-primary-qui-dashed {
  border: 1px dashed #b8b8d6; }

.border-primary-qui-solid {
  border: 1px solid #b8b8d6; }

.border-primary-qui-double {
  border: 1px double #b8b8d6; }

.border-primary-qui-groove {
  border: 1px groove #b8b8d6; }

.border-primary-qui-ridge {
  border: 1px ridge #b8b8d6; }

.border-primary-qui-inset {
  border: 1px inset #b8b8d6; }

.border-primary-qui-outset {
  border: 1px outset #b8b8d6; }

.border-primary-qui-none {
  border: 1px none #b8b8d6; }

.border-primary-qui-hidden {
  border: 1px hidden #b8b8d6; }

.border-t-primary-qui {
  border-top: 1px solid #b8b8d6; }

.border-t-primary-qui-dotted {
  border-top: 1px dotted #b8b8d6; }

.border-t-primary-qui-dashed {
  border-top: 1px dashed #b8b8d6; }

.border-t-primary-qui-solid {
  border-top: 1px solid #b8b8d6; }

.border-t-primary-qui-double {
  border-top: 1px double #b8b8d6; }

.border-t-primary-qui-groove {
  border-top: 1px groove #b8b8d6; }

.border-t-primary-qui-ridge {
  border-top: 1px ridge #b8b8d6; }

.border-t-primary-qui-inset {
  border-top: 1px inset #b8b8d6; }

.border-t-primary-qui-outset {
  border-top: 1px outset #b8b8d6; }

.border-t-primary-qui-none {
  border-top: 1px none #b8b8d6; }

.border-t-primary-qui-hidden {
  border-top: 1px hidden #b8b8d6; }

.border-r-primary-qui {
  border-right: 1px solid #b8b8d6; }

.border-r-primary-qui-dotted {
  border-right: 1px dotted #b8b8d6; }

.border-r-primary-qui-dashed {
  border-right: 1px dashed #b8b8d6; }

.border-r-primary-qui-solid {
  border-right: 1px solid #b8b8d6; }

.border-r-primary-qui-double {
  border-right: 1px double #b8b8d6; }

.border-r-primary-qui-groove {
  border-right: 1px groove #b8b8d6; }

.border-r-primary-qui-ridge {
  border-right: 1px ridge #b8b8d6; }

.border-r-primary-qui-inset {
  border-right: 1px inset #b8b8d6; }

.border-r-primary-qui-outset {
  border-right: 1px outset #b8b8d6; }

.border-r-primary-qui-none {
  border-right: 1px none #b8b8d6; }

.border-r-primary-qui-hidden {
  border-right: 1px hidden #b8b8d6; }

.border-b-primary-qui {
  border-bottom: 1px solid #b8b8d6; }

.border-b-primary-qui-dotted {
  border-bottom: 1px dotted #b8b8d6; }

.border-b-primary-qui-dashed {
  border-bottom: 1px dashed #b8b8d6; }

.border-b-primary-qui-solid {
  border-bottom: 1px solid #b8b8d6; }

.border-b-primary-qui-double {
  border-bottom: 1px double #b8b8d6; }

.border-b-primary-qui-groove {
  border-bottom: 1px groove #b8b8d6; }

.border-b-primary-qui-ridge {
  border-bottom: 1px ridge #b8b8d6; }

.border-b-primary-qui-inset {
  border-bottom: 1px inset #b8b8d6; }

.border-b-primary-qui-outset {
  border-bottom: 1px outset #b8b8d6; }

.border-b-primary-qui-none {
  border-bottom: 1px none #b8b8d6; }

.border-b-primary-qui-hidden {
  border-bottom: 1px hidden #b8b8d6; }

.border-l-primary-qui {
  border-left: 1px solid #b8b8d6; }

.border-l-primary-qui-dotted {
  border-left: 1px dotted #b8b8d6; }

.border-l-primary-qui-dashed {
  border-left: 1px dashed #b8b8d6; }

.border-l-primary-qui-solid {
  border-left: 1px solid #b8b8d6; }

.border-l-primary-qui-double {
  border-left: 1px double #b8b8d6; }

.border-l-primary-qui-groove {
  border-left: 1px groove #b8b8d6; }

.border-l-primary-qui-ridge {
  border-left: 1px ridge #b8b8d6; }

.border-l-primary-qui-inset {
  border-left: 1px inset #b8b8d6; }

.border-l-primary-qui-outset {
  border-left: 1px outset #b8b8d6; }

.border-l-primary-qui-none {
  border-left: 1px none #b8b8d6; }

.border-l-primary-qui-hidden {
  border-left: 1px hidden #b8b8d6; }

.has-text-primary-light {
  color: #dbdbf0; }

.has-background-primary-light {
  background-color: #dbdbf0; }

.border-primary-light {
  border: 1px solid #dbdbf0; }

.border-m-primary-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dbdbf0;
  transform: translateY(-50%); }

.border-primary-light-dotted {
  border: 1px dotted #dbdbf0; }

.border-primary-light-dashed {
  border: 1px dashed #dbdbf0; }

.border-primary-light-solid {
  border: 1px solid #dbdbf0; }

.border-primary-light-double {
  border: 1px double #dbdbf0; }

.border-primary-light-groove {
  border: 1px groove #dbdbf0; }

.border-primary-light-ridge {
  border: 1px ridge #dbdbf0; }

.border-primary-light-inset {
  border: 1px inset #dbdbf0; }

.border-primary-light-outset {
  border: 1px outset #dbdbf0; }

.border-primary-light-none {
  border: 1px none #dbdbf0; }

.border-primary-light-hidden {
  border: 1px hidden #dbdbf0; }

.border-t-primary-light {
  border-top: 1px solid #dbdbf0; }

.border-t-primary-light-dotted {
  border-top: 1px dotted #dbdbf0; }

.border-t-primary-light-dashed {
  border-top: 1px dashed #dbdbf0; }

.border-t-primary-light-solid {
  border-top: 1px solid #dbdbf0; }

.border-t-primary-light-double {
  border-top: 1px double #dbdbf0; }

.border-t-primary-light-groove {
  border-top: 1px groove #dbdbf0; }

.border-t-primary-light-ridge {
  border-top: 1px ridge #dbdbf0; }

.border-t-primary-light-inset {
  border-top: 1px inset #dbdbf0; }

.border-t-primary-light-outset {
  border-top: 1px outset #dbdbf0; }

.border-t-primary-light-none {
  border-top: 1px none #dbdbf0; }

.border-t-primary-light-hidden {
  border-top: 1px hidden #dbdbf0; }

.border-r-primary-light {
  border-right: 1px solid #dbdbf0; }

.border-r-primary-light-dotted {
  border-right: 1px dotted #dbdbf0; }

.border-r-primary-light-dashed {
  border-right: 1px dashed #dbdbf0; }

.border-r-primary-light-solid {
  border-right: 1px solid #dbdbf0; }

.border-r-primary-light-double {
  border-right: 1px double #dbdbf0; }

.border-r-primary-light-groove {
  border-right: 1px groove #dbdbf0; }

.border-r-primary-light-ridge {
  border-right: 1px ridge #dbdbf0; }

.border-r-primary-light-inset {
  border-right: 1px inset #dbdbf0; }

.border-r-primary-light-outset {
  border-right: 1px outset #dbdbf0; }

.border-r-primary-light-none {
  border-right: 1px none #dbdbf0; }

.border-r-primary-light-hidden {
  border-right: 1px hidden #dbdbf0; }

.border-b-primary-light {
  border-bottom: 1px solid #dbdbf0; }

.border-b-primary-light-dotted {
  border-bottom: 1px dotted #dbdbf0; }

.border-b-primary-light-dashed {
  border-bottom: 1px dashed #dbdbf0; }

.border-b-primary-light-solid {
  border-bottom: 1px solid #dbdbf0; }

.border-b-primary-light-double {
  border-bottom: 1px double #dbdbf0; }

.border-b-primary-light-groove {
  border-bottom: 1px groove #dbdbf0; }

.border-b-primary-light-ridge {
  border-bottom: 1px ridge #dbdbf0; }

.border-b-primary-light-inset {
  border-bottom: 1px inset #dbdbf0; }

.border-b-primary-light-outset {
  border-bottom: 1px outset #dbdbf0; }

.border-b-primary-light-none {
  border-bottom: 1px none #dbdbf0; }

.border-b-primary-light-hidden {
  border-bottom: 1px hidden #dbdbf0; }

.border-l-primary-light {
  border-left: 1px solid #dbdbf0; }

.border-l-primary-light-dotted {
  border-left: 1px dotted #dbdbf0; }

.border-l-primary-light-dashed {
  border-left: 1px dashed #dbdbf0; }

.border-l-primary-light-solid {
  border-left: 1px solid #dbdbf0; }

.border-l-primary-light-double {
  border-left: 1px double #dbdbf0; }

.border-l-primary-light-groove {
  border-left: 1px groove #dbdbf0; }

.border-l-primary-light-ridge {
  border-left: 1px ridge #dbdbf0; }

.border-l-primary-light-inset {
  border-left: 1px inset #dbdbf0; }

.border-l-primary-light-outset {
  border-left: 1px outset #dbdbf0; }

.border-l-primary-light-none {
  border-left: 1px none #dbdbf0; }

.border-l-primary-light-hidden {
  border-left: 1px hidden #dbdbf0; }

.has-text-primary-lighter {
  color: #ededf7; }

.has-background-primary-lighter {
  background-color: #ededf7; }

.border-primary-lighter {
  border: 1px solid #ededf7; }

.border-m-primary-lighter:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ededf7;
  transform: translateY(-50%); }

.border-primary-lighter-dotted {
  border: 1px dotted #ededf7; }

.border-primary-lighter-dashed {
  border: 1px dashed #ededf7; }

.border-primary-lighter-solid {
  border: 1px solid #ededf7; }

.border-primary-lighter-double {
  border: 1px double #ededf7; }

.border-primary-lighter-groove {
  border: 1px groove #ededf7; }

.border-primary-lighter-ridge {
  border: 1px ridge #ededf7; }

.border-primary-lighter-inset {
  border: 1px inset #ededf7; }

.border-primary-lighter-outset {
  border: 1px outset #ededf7; }

.border-primary-lighter-none {
  border: 1px none #ededf7; }

.border-primary-lighter-hidden {
  border: 1px hidden #ededf7; }

.border-t-primary-lighter {
  border-top: 1px solid #ededf7; }

.border-t-primary-lighter-dotted {
  border-top: 1px dotted #ededf7; }

.border-t-primary-lighter-dashed {
  border-top: 1px dashed #ededf7; }

.border-t-primary-lighter-solid {
  border-top: 1px solid #ededf7; }

.border-t-primary-lighter-double {
  border-top: 1px double #ededf7; }

.border-t-primary-lighter-groove {
  border-top: 1px groove #ededf7; }

.border-t-primary-lighter-ridge {
  border-top: 1px ridge #ededf7; }

.border-t-primary-lighter-inset {
  border-top: 1px inset #ededf7; }

.border-t-primary-lighter-outset {
  border-top: 1px outset #ededf7; }

.border-t-primary-lighter-none {
  border-top: 1px none #ededf7; }

.border-t-primary-lighter-hidden {
  border-top: 1px hidden #ededf7; }

.border-r-primary-lighter {
  border-right: 1px solid #ededf7; }

.border-r-primary-lighter-dotted {
  border-right: 1px dotted #ededf7; }

.border-r-primary-lighter-dashed {
  border-right: 1px dashed #ededf7; }

.border-r-primary-lighter-solid {
  border-right: 1px solid #ededf7; }

.border-r-primary-lighter-double {
  border-right: 1px double #ededf7; }

.border-r-primary-lighter-groove {
  border-right: 1px groove #ededf7; }

.border-r-primary-lighter-ridge {
  border-right: 1px ridge #ededf7; }

.border-r-primary-lighter-inset {
  border-right: 1px inset #ededf7; }

.border-r-primary-lighter-outset {
  border-right: 1px outset #ededf7; }

.border-r-primary-lighter-none {
  border-right: 1px none #ededf7; }

.border-r-primary-lighter-hidden {
  border-right: 1px hidden #ededf7; }

.border-b-primary-lighter {
  border-bottom: 1px solid #ededf7; }

.border-b-primary-lighter-dotted {
  border-bottom: 1px dotted #ededf7; }

.border-b-primary-lighter-dashed {
  border-bottom: 1px dashed #ededf7; }

.border-b-primary-lighter-solid {
  border-bottom: 1px solid #ededf7; }

.border-b-primary-lighter-double {
  border-bottom: 1px double #ededf7; }

.border-b-primary-lighter-groove {
  border-bottom: 1px groove #ededf7; }

.border-b-primary-lighter-ridge {
  border-bottom: 1px ridge #ededf7; }

.border-b-primary-lighter-inset {
  border-bottom: 1px inset #ededf7; }

.border-b-primary-lighter-outset {
  border-bottom: 1px outset #ededf7; }

.border-b-primary-lighter-none {
  border-bottom: 1px none #ededf7; }

.border-b-primary-lighter-hidden {
  border-bottom: 1px hidden #ededf7; }

.border-l-primary-lighter {
  border-left: 1px solid #ededf7; }

.border-l-primary-lighter-dotted {
  border-left: 1px dotted #ededf7; }

.border-l-primary-lighter-dashed {
  border-left: 1px dashed #ededf7; }

.border-l-primary-lighter-solid {
  border-left: 1px solid #ededf7; }

.border-l-primary-lighter-double {
  border-left: 1px double #ededf7; }

.border-l-primary-lighter-groove {
  border-left: 1px groove #ededf7; }

.border-l-primary-lighter-ridge {
  border-left: 1px ridge #ededf7; }

.border-l-primary-lighter-inset {
  border-left: 1px inset #ededf7; }

.border-l-primary-lighter-outset {
  border-left: 1px outset #ededf7; }

.border-l-primary-lighter-none {
  border-left: 1px none #ededf7; }

.border-l-primary-lighter-hidden {
  border-left: 1px hidden #ededf7; }

.has-text-primary-lighbis {
  color: #f4f4fa; }

.has-background-primary-lighbis {
  background-color: #f4f4fa; }

.border-primary-lighbis {
  border: 1px solid #f4f4fa; }

.border-m-primary-lighbis:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f4f4fa;
  transform: translateY(-50%); }

.border-primary-lighbis-dotted {
  border: 1px dotted #f4f4fa; }

.border-primary-lighbis-dashed {
  border: 1px dashed #f4f4fa; }

.border-primary-lighbis-solid {
  border: 1px solid #f4f4fa; }

.border-primary-lighbis-double {
  border: 1px double #f4f4fa; }

.border-primary-lighbis-groove {
  border: 1px groove #f4f4fa; }

.border-primary-lighbis-ridge {
  border: 1px ridge #f4f4fa; }

.border-primary-lighbis-inset {
  border: 1px inset #f4f4fa; }

.border-primary-lighbis-outset {
  border: 1px outset #f4f4fa; }

.border-primary-lighbis-none {
  border: 1px none #f4f4fa; }

.border-primary-lighbis-hidden {
  border: 1px hidden #f4f4fa; }

.border-t-primary-lighbis {
  border-top: 1px solid #f4f4fa; }

.border-t-primary-lighbis-dotted {
  border-top: 1px dotted #f4f4fa; }

.border-t-primary-lighbis-dashed {
  border-top: 1px dashed #f4f4fa; }

.border-t-primary-lighbis-solid {
  border-top: 1px solid #f4f4fa; }

.border-t-primary-lighbis-double {
  border-top: 1px double #f4f4fa; }

.border-t-primary-lighbis-groove {
  border-top: 1px groove #f4f4fa; }

.border-t-primary-lighbis-ridge {
  border-top: 1px ridge #f4f4fa; }

.border-t-primary-lighbis-inset {
  border-top: 1px inset #f4f4fa; }

.border-t-primary-lighbis-outset {
  border-top: 1px outset #f4f4fa; }

.border-t-primary-lighbis-none {
  border-top: 1px none #f4f4fa; }

.border-t-primary-lighbis-hidden {
  border-top: 1px hidden #f4f4fa; }

.border-r-primary-lighbis {
  border-right: 1px solid #f4f4fa; }

.border-r-primary-lighbis-dotted {
  border-right: 1px dotted #f4f4fa; }

.border-r-primary-lighbis-dashed {
  border-right: 1px dashed #f4f4fa; }

.border-r-primary-lighbis-solid {
  border-right: 1px solid #f4f4fa; }

.border-r-primary-lighbis-double {
  border-right: 1px double #f4f4fa; }

.border-r-primary-lighbis-groove {
  border-right: 1px groove #f4f4fa; }

.border-r-primary-lighbis-ridge {
  border-right: 1px ridge #f4f4fa; }

.border-r-primary-lighbis-inset {
  border-right: 1px inset #f4f4fa; }

.border-r-primary-lighbis-outset {
  border-right: 1px outset #f4f4fa; }

.border-r-primary-lighbis-none {
  border-right: 1px none #f4f4fa; }

.border-r-primary-lighbis-hidden {
  border-right: 1px hidden #f4f4fa; }

.border-b-primary-lighbis {
  border-bottom: 1px solid #f4f4fa; }

.border-b-primary-lighbis-dotted {
  border-bottom: 1px dotted #f4f4fa; }

.border-b-primary-lighbis-dashed {
  border-bottom: 1px dashed #f4f4fa; }

.border-b-primary-lighbis-solid {
  border-bottom: 1px solid #f4f4fa; }

.border-b-primary-lighbis-double {
  border-bottom: 1px double #f4f4fa; }

.border-b-primary-lighbis-groove {
  border-bottom: 1px groove #f4f4fa; }

.border-b-primary-lighbis-ridge {
  border-bottom: 1px ridge #f4f4fa; }

.border-b-primary-lighbis-inset {
  border-bottom: 1px inset #f4f4fa; }

.border-b-primary-lighbis-outset {
  border-bottom: 1px outset #f4f4fa; }

.border-b-primary-lighbis-none {
  border-bottom: 1px none #f4f4fa; }

.border-b-primary-lighbis-hidden {
  border-bottom: 1px hidden #f4f4fa; }

.border-l-primary-lighbis {
  border-left: 1px solid #f4f4fa; }

.border-l-primary-lighbis-dotted {
  border-left: 1px dotted #f4f4fa; }

.border-l-primary-lighbis-dashed {
  border-left: 1px dashed #f4f4fa; }

.border-l-primary-lighbis-solid {
  border-left: 1px solid #f4f4fa; }

.border-l-primary-lighbis-double {
  border-left: 1px double #f4f4fa; }

.border-l-primary-lighbis-groove {
  border-left: 1px groove #f4f4fa; }

.border-l-primary-lighbis-ridge {
  border-left: 1px ridge #f4f4fa; }

.border-l-primary-lighbis-inset {
  border-left: 1px inset #f4f4fa; }

.border-l-primary-lighbis-outset {
  border-left: 1px outset #f4f4fa; }

.border-l-primary-lighbis-none {
  border-left: 1px none #f4f4fa; }

.border-l-primary-lighbis-hidden {
  border-left: 1px hidden #f4f4fa; }

.has-text-secondary {
  color: #eb6666; }

.has-background-secondary {
  background-color: #eb6666; }

.border-secondary {
  border: 1px solid #eb6666; }

.border-m-secondary:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #eb6666;
  transform: translateY(-50%); }

.border-secondary-dotted {
  border: 1px dotted #eb6666; }

.border-secondary-dashed {
  border: 1px dashed #eb6666; }

.border-secondary-solid {
  border: 1px solid #eb6666; }

.border-secondary-double {
  border: 1px double #eb6666; }

.border-secondary-groove {
  border: 1px groove #eb6666; }

.border-secondary-ridge {
  border: 1px ridge #eb6666; }

.border-secondary-inset {
  border: 1px inset #eb6666; }

.border-secondary-outset {
  border: 1px outset #eb6666; }

.border-secondary-none {
  border: 1px none #eb6666; }

.border-secondary-hidden {
  border: 1px hidden #eb6666; }

.border-t-secondary {
  border-top: 1px solid #eb6666; }

.border-t-secondary-dotted {
  border-top: 1px dotted #eb6666; }

.border-t-secondary-dashed {
  border-top: 1px dashed #eb6666; }

.border-t-secondary-solid {
  border-top: 1px solid #eb6666; }

.border-t-secondary-double {
  border-top: 1px double #eb6666; }

.border-t-secondary-groove {
  border-top: 1px groove #eb6666; }

.border-t-secondary-ridge {
  border-top: 1px ridge #eb6666; }

.border-t-secondary-inset {
  border-top: 1px inset #eb6666; }

.border-t-secondary-outset {
  border-top: 1px outset #eb6666; }

.border-t-secondary-none {
  border-top: 1px none #eb6666; }

.border-t-secondary-hidden {
  border-top: 1px hidden #eb6666; }

.border-r-secondary {
  border-right: 1px solid #eb6666; }

.border-r-secondary-dotted {
  border-right: 1px dotted #eb6666; }

.border-r-secondary-dashed {
  border-right: 1px dashed #eb6666; }

.border-r-secondary-solid {
  border-right: 1px solid #eb6666; }

.border-r-secondary-double {
  border-right: 1px double #eb6666; }

.border-r-secondary-groove {
  border-right: 1px groove #eb6666; }

.border-r-secondary-ridge {
  border-right: 1px ridge #eb6666; }

.border-r-secondary-inset {
  border-right: 1px inset #eb6666; }

.border-r-secondary-outset {
  border-right: 1px outset #eb6666; }

.border-r-secondary-none {
  border-right: 1px none #eb6666; }

.border-r-secondary-hidden {
  border-right: 1px hidden #eb6666; }

.border-b-secondary {
  border-bottom: 1px solid #eb6666; }

.border-b-secondary-dotted {
  border-bottom: 1px dotted #eb6666; }

.border-b-secondary-dashed {
  border-bottom: 1px dashed #eb6666; }

.border-b-secondary-solid {
  border-bottom: 1px solid #eb6666; }

.border-b-secondary-double {
  border-bottom: 1px double #eb6666; }

.border-b-secondary-groove {
  border-bottom: 1px groove #eb6666; }

.border-b-secondary-ridge {
  border-bottom: 1px ridge #eb6666; }

.border-b-secondary-inset {
  border-bottom: 1px inset #eb6666; }

.border-b-secondary-outset {
  border-bottom: 1px outset #eb6666; }

.border-b-secondary-none {
  border-bottom: 1px none #eb6666; }

.border-b-secondary-hidden {
  border-bottom: 1px hidden #eb6666; }

.border-l-secondary {
  border-left: 1px solid #eb6666; }

.border-l-secondary-dotted {
  border-left: 1px dotted #eb6666; }

.border-l-secondary-dashed {
  border-left: 1px dashed #eb6666; }

.border-l-secondary-solid {
  border-left: 1px solid #eb6666; }

.border-l-secondary-double {
  border-left: 1px double #eb6666; }

.border-l-secondary-groove {
  border-left: 1px groove #eb6666; }

.border-l-secondary-ridge {
  border-left: 1px ridge #eb6666; }

.border-l-secondary-inset {
  border-left: 1px inset #eb6666; }

.border-l-secondary-outset {
  border-left: 1px outset #eb6666; }

.border-l-secondary-none {
  border-left: 1px none #eb6666; }

.border-l-secondary-hidden {
  border-left: 1px hidden #eb6666; }

.has-text-danger {
  color: #cc3333; }

.has-background-danger {
  background-color: #cc3333; }

.border-danger {
  border: 1px solid #cc3333; }

.border-m-danger:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #cc3333;
  transform: translateY(-50%); }

.border-danger-dotted {
  border: 1px dotted #cc3333; }

.border-danger-dashed {
  border: 1px dashed #cc3333; }

.border-danger-solid {
  border: 1px solid #cc3333; }

.border-danger-double {
  border: 1px double #cc3333; }

.border-danger-groove {
  border: 1px groove #cc3333; }

.border-danger-ridge {
  border: 1px ridge #cc3333; }

.border-danger-inset {
  border: 1px inset #cc3333; }

.border-danger-outset {
  border: 1px outset #cc3333; }

.border-danger-none {
  border: 1px none #cc3333; }

.border-danger-hidden {
  border: 1px hidden #cc3333; }

.border-t-danger {
  border-top: 1px solid #cc3333; }

.border-t-danger-dotted {
  border-top: 1px dotted #cc3333; }

.border-t-danger-dashed {
  border-top: 1px dashed #cc3333; }

.border-t-danger-solid {
  border-top: 1px solid #cc3333; }

.border-t-danger-double {
  border-top: 1px double #cc3333; }

.border-t-danger-groove {
  border-top: 1px groove #cc3333; }

.border-t-danger-ridge {
  border-top: 1px ridge #cc3333; }

.border-t-danger-inset {
  border-top: 1px inset #cc3333; }

.border-t-danger-outset {
  border-top: 1px outset #cc3333; }

.border-t-danger-none {
  border-top: 1px none #cc3333; }

.border-t-danger-hidden {
  border-top: 1px hidden #cc3333; }

.border-r-danger {
  border-right: 1px solid #cc3333; }

.border-r-danger-dotted {
  border-right: 1px dotted #cc3333; }

.border-r-danger-dashed {
  border-right: 1px dashed #cc3333; }

.border-r-danger-solid {
  border-right: 1px solid #cc3333; }

.border-r-danger-double {
  border-right: 1px double #cc3333; }

.border-r-danger-groove {
  border-right: 1px groove #cc3333; }

.border-r-danger-ridge {
  border-right: 1px ridge #cc3333; }

.border-r-danger-inset {
  border-right: 1px inset #cc3333; }

.border-r-danger-outset {
  border-right: 1px outset #cc3333; }

.border-r-danger-none {
  border-right: 1px none #cc3333; }

.border-r-danger-hidden {
  border-right: 1px hidden #cc3333; }

.border-b-danger {
  border-bottom: 1px solid #cc3333; }

.border-b-danger-dotted {
  border-bottom: 1px dotted #cc3333; }

.border-b-danger-dashed {
  border-bottom: 1px dashed #cc3333; }

.border-b-danger-solid {
  border-bottom: 1px solid #cc3333; }

.border-b-danger-double {
  border-bottom: 1px double #cc3333; }

.border-b-danger-groove {
  border-bottom: 1px groove #cc3333; }

.border-b-danger-ridge {
  border-bottom: 1px ridge #cc3333; }

.border-b-danger-inset {
  border-bottom: 1px inset #cc3333; }

.border-b-danger-outset {
  border-bottom: 1px outset #cc3333; }

.border-b-danger-none {
  border-bottom: 1px none #cc3333; }

.border-b-danger-hidden {
  border-bottom: 1px hidden #cc3333; }

.border-l-danger {
  border-left: 1px solid #cc3333; }

.border-l-danger-dotted {
  border-left: 1px dotted #cc3333; }

.border-l-danger-dashed {
  border-left: 1px dashed #cc3333; }

.border-l-danger-solid {
  border-left: 1px solid #cc3333; }

.border-l-danger-double {
  border-left: 1px double #cc3333; }

.border-l-danger-groove {
  border-left: 1px groove #cc3333; }

.border-l-danger-ridge {
  border-left: 1px ridge #cc3333; }

.border-l-danger-inset {
  border-left: 1px inset #cc3333; }

.border-l-danger-outset {
  border-left: 1px outset #cc3333; }

.border-l-danger-none {
  border-left: 1px none #cc3333; }

.border-l-danger-hidden {
  border-left: 1px hidden #cc3333; }

.has-text-danger-light {
  color: #f6dada; }

.has-background-danger-light {
  background-color: #f6dada; }

.border-danger-light {
  border: 1px solid #f6dada; }

.border-m-danger-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f6dada;
  transform: translateY(-50%); }

.border-danger-light-dotted {
  border: 1px dotted #f6dada; }

.border-danger-light-dashed {
  border: 1px dashed #f6dada; }

.border-danger-light-solid {
  border: 1px solid #f6dada; }

.border-danger-light-double {
  border: 1px double #f6dada; }

.border-danger-light-groove {
  border: 1px groove #f6dada; }

.border-danger-light-ridge {
  border: 1px ridge #f6dada; }

.border-danger-light-inset {
  border: 1px inset #f6dada; }

.border-danger-light-outset {
  border: 1px outset #f6dada; }

.border-danger-light-none {
  border: 1px none #f6dada; }

.border-danger-light-hidden {
  border: 1px hidden #f6dada; }

.border-t-danger-light {
  border-top: 1px solid #f6dada; }

.border-t-danger-light-dotted {
  border-top: 1px dotted #f6dada; }

.border-t-danger-light-dashed {
  border-top: 1px dashed #f6dada; }

.border-t-danger-light-solid {
  border-top: 1px solid #f6dada; }

.border-t-danger-light-double {
  border-top: 1px double #f6dada; }

.border-t-danger-light-groove {
  border-top: 1px groove #f6dada; }

.border-t-danger-light-ridge {
  border-top: 1px ridge #f6dada; }

.border-t-danger-light-inset {
  border-top: 1px inset #f6dada; }

.border-t-danger-light-outset {
  border-top: 1px outset #f6dada; }

.border-t-danger-light-none {
  border-top: 1px none #f6dada; }

.border-t-danger-light-hidden {
  border-top: 1px hidden #f6dada; }

.border-r-danger-light {
  border-right: 1px solid #f6dada; }

.border-r-danger-light-dotted {
  border-right: 1px dotted #f6dada; }

.border-r-danger-light-dashed {
  border-right: 1px dashed #f6dada; }

.border-r-danger-light-solid {
  border-right: 1px solid #f6dada; }

.border-r-danger-light-double {
  border-right: 1px double #f6dada; }

.border-r-danger-light-groove {
  border-right: 1px groove #f6dada; }

.border-r-danger-light-ridge {
  border-right: 1px ridge #f6dada; }

.border-r-danger-light-inset {
  border-right: 1px inset #f6dada; }

.border-r-danger-light-outset {
  border-right: 1px outset #f6dada; }

.border-r-danger-light-none {
  border-right: 1px none #f6dada; }

.border-r-danger-light-hidden {
  border-right: 1px hidden #f6dada; }

.border-b-danger-light {
  border-bottom: 1px solid #f6dada; }

.border-b-danger-light-dotted {
  border-bottom: 1px dotted #f6dada; }

.border-b-danger-light-dashed {
  border-bottom: 1px dashed #f6dada; }

.border-b-danger-light-solid {
  border-bottom: 1px solid #f6dada; }

.border-b-danger-light-double {
  border-bottom: 1px double #f6dada; }

.border-b-danger-light-groove {
  border-bottom: 1px groove #f6dada; }

.border-b-danger-light-ridge {
  border-bottom: 1px ridge #f6dada; }

.border-b-danger-light-inset {
  border-bottom: 1px inset #f6dada; }

.border-b-danger-light-outset {
  border-bottom: 1px outset #f6dada; }

.border-b-danger-light-none {
  border-bottom: 1px none #f6dada; }

.border-b-danger-light-hidden {
  border-bottom: 1px hidden #f6dada; }

.border-l-danger-light {
  border-left: 1px solid #f6dada; }

.border-l-danger-light-dotted {
  border-left: 1px dotted #f6dada; }

.border-l-danger-light-dashed {
  border-left: 1px dashed #f6dada; }

.border-l-danger-light-solid {
  border-left: 1px solid #f6dada; }

.border-l-danger-light-double {
  border-left: 1px double #f6dada; }

.border-l-danger-light-groove {
  border-left: 1px groove #f6dada; }

.border-l-danger-light-ridge {
  border-left: 1px ridge #f6dada; }

.border-l-danger-light-inset {
  border-left: 1px inset #f6dada; }

.border-l-danger-light-outset {
  border-left: 1px outset #f6dada; }

.border-l-danger-light-none {
  border-left: 1px none #f6dada; }

.border-l-danger-light-hidden {
  border-left: 1px hidden #f6dada; }

.has-text-warning {
  color: #ffd500; }

.has-background-warning {
  background-color: #ffd500; }

.border-warning {
  border: 1px solid #ffd500; }

.border-m-warning:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ffd500;
  transform: translateY(-50%); }

.border-warning-dotted {
  border: 1px dotted #ffd500; }

.border-warning-dashed {
  border: 1px dashed #ffd500; }

.border-warning-solid {
  border: 1px solid #ffd500; }

.border-warning-double {
  border: 1px double #ffd500; }

.border-warning-groove {
  border: 1px groove #ffd500; }

.border-warning-ridge {
  border: 1px ridge #ffd500; }

.border-warning-inset {
  border: 1px inset #ffd500; }

.border-warning-outset {
  border: 1px outset #ffd500; }

.border-warning-none {
  border: 1px none #ffd500; }

.border-warning-hidden {
  border: 1px hidden #ffd500; }

.border-t-warning {
  border-top: 1px solid #ffd500; }

.border-t-warning-dotted {
  border-top: 1px dotted #ffd500; }

.border-t-warning-dashed {
  border-top: 1px dashed #ffd500; }

.border-t-warning-solid {
  border-top: 1px solid #ffd500; }

.border-t-warning-double {
  border-top: 1px double #ffd500; }

.border-t-warning-groove {
  border-top: 1px groove #ffd500; }

.border-t-warning-ridge {
  border-top: 1px ridge #ffd500; }

.border-t-warning-inset {
  border-top: 1px inset #ffd500; }

.border-t-warning-outset {
  border-top: 1px outset #ffd500; }

.border-t-warning-none {
  border-top: 1px none #ffd500; }

.border-t-warning-hidden {
  border-top: 1px hidden #ffd500; }

.border-r-warning {
  border-right: 1px solid #ffd500; }

.border-r-warning-dotted {
  border-right: 1px dotted #ffd500; }

.border-r-warning-dashed {
  border-right: 1px dashed #ffd500; }

.border-r-warning-solid {
  border-right: 1px solid #ffd500; }

.border-r-warning-double {
  border-right: 1px double #ffd500; }

.border-r-warning-groove {
  border-right: 1px groove #ffd500; }

.border-r-warning-ridge {
  border-right: 1px ridge #ffd500; }

.border-r-warning-inset {
  border-right: 1px inset #ffd500; }

.border-r-warning-outset {
  border-right: 1px outset #ffd500; }

.border-r-warning-none {
  border-right: 1px none #ffd500; }

.border-r-warning-hidden {
  border-right: 1px hidden #ffd500; }

.border-b-warning {
  border-bottom: 1px solid #ffd500; }

.border-b-warning-dotted {
  border-bottom: 1px dotted #ffd500; }

.border-b-warning-dashed {
  border-bottom: 1px dashed #ffd500; }

.border-b-warning-solid {
  border-bottom: 1px solid #ffd500; }

.border-b-warning-double {
  border-bottom: 1px double #ffd500; }

.border-b-warning-groove {
  border-bottom: 1px groove #ffd500; }

.border-b-warning-ridge {
  border-bottom: 1px ridge #ffd500; }

.border-b-warning-inset {
  border-bottom: 1px inset #ffd500; }

.border-b-warning-outset {
  border-bottom: 1px outset #ffd500; }

.border-b-warning-none {
  border-bottom: 1px none #ffd500; }

.border-b-warning-hidden {
  border-bottom: 1px hidden #ffd500; }

.border-l-warning {
  border-left: 1px solid #ffd500; }

.border-l-warning-dotted {
  border-left: 1px dotted #ffd500; }

.border-l-warning-dashed {
  border-left: 1px dashed #ffd500; }

.border-l-warning-solid {
  border-left: 1px solid #ffd500; }

.border-l-warning-double {
  border-left: 1px double #ffd500; }

.border-l-warning-groove {
  border-left: 1px groove #ffd500; }

.border-l-warning-ridge {
  border-left: 1px ridge #ffd500; }

.border-l-warning-inset {
  border-left: 1px inset #ffd500; }

.border-l-warning-outset {
  border-left: 1px outset #ffd500; }

.border-l-warning-none {
  border-left: 1px none #ffd500; }

.border-l-warning-hidden {
  border-left: 1px hidden #ffd500; }

.has-text-success {
  color: #66cc33; }

.has-background-success {
  background-color: #66cc33; }

.border-success {
  border: 1px solid #66cc33; }

.border-m-success:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #66cc33;
  transform: translateY(-50%); }

.border-success-dotted {
  border: 1px dotted #66cc33; }

.border-success-dashed {
  border: 1px dashed #66cc33; }

.border-success-solid {
  border: 1px solid #66cc33; }

.border-success-double {
  border: 1px double #66cc33; }

.border-success-groove {
  border: 1px groove #66cc33; }

.border-success-ridge {
  border: 1px ridge #66cc33; }

.border-success-inset {
  border: 1px inset #66cc33; }

.border-success-outset {
  border: 1px outset #66cc33; }

.border-success-none {
  border: 1px none #66cc33; }

.border-success-hidden {
  border: 1px hidden #66cc33; }

.border-t-success {
  border-top: 1px solid #66cc33; }

.border-t-success-dotted {
  border-top: 1px dotted #66cc33; }

.border-t-success-dashed {
  border-top: 1px dashed #66cc33; }

.border-t-success-solid {
  border-top: 1px solid #66cc33; }

.border-t-success-double {
  border-top: 1px double #66cc33; }

.border-t-success-groove {
  border-top: 1px groove #66cc33; }

.border-t-success-ridge {
  border-top: 1px ridge #66cc33; }

.border-t-success-inset {
  border-top: 1px inset #66cc33; }

.border-t-success-outset {
  border-top: 1px outset #66cc33; }

.border-t-success-none {
  border-top: 1px none #66cc33; }

.border-t-success-hidden {
  border-top: 1px hidden #66cc33; }

.border-r-success {
  border-right: 1px solid #66cc33; }

.border-r-success-dotted {
  border-right: 1px dotted #66cc33; }

.border-r-success-dashed {
  border-right: 1px dashed #66cc33; }

.border-r-success-solid {
  border-right: 1px solid #66cc33; }

.border-r-success-double {
  border-right: 1px double #66cc33; }

.border-r-success-groove {
  border-right: 1px groove #66cc33; }

.border-r-success-ridge {
  border-right: 1px ridge #66cc33; }

.border-r-success-inset {
  border-right: 1px inset #66cc33; }

.border-r-success-outset {
  border-right: 1px outset #66cc33; }

.border-r-success-none {
  border-right: 1px none #66cc33; }

.border-r-success-hidden {
  border-right: 1px hidden #66cc33; }

.border-b-success {
  border-bottom: 1px solid #66cc33; }

.border-b-success-dotted {
  border-bottom: 1px dotted #66cc33; }

.border-b-success-dashed {
  border-bottom: 1px dashed #66cc33; }

.border-b-success-solid {
  border-bottom: 1px solid #66cc33; }

.border-b-success-double {
  border-bottom: 1px double #66cc33; }

.border-b-success-groove {
  border-bottom: 1px groove #66cc33; }

.border-b-success-ridge {
  border-bottom: 1px ridge #66cc33; }

.border-b-success-inset {
  border-bottom: 1px inset #66cc33; }

.border-b-success-outset {
  border-bottom: 1px outset #66cc33; }

.border-b-success-none {
  border-bottom: 1px none #66cc33; }

.border-b-success-hidden {
  border-bottom: 1px hidden #66cc33; }

.border-l-success {
  border-left: 1px solid #66cc33; }

.border-l-success-dotted {
  border-left: 1px dotted #66cc33; }

.border-l-success-dashed {
  border-left: 1px dashed #66cc33; }

.border-l-success-solid {
  border-left: 1px solid #66cc33; }

.border-l-success-double {
  border-left: 1px double #66cc33; }

.border-l-success-groove {
  border-left: 1px groove #66cc33; }

.border-l-success-ridge {
  border-left: 1px ridge #66cc33; }

.border-l-success-inset {
  border-left: 1px inset #66cc33; }

.border-l-success-outset {
  border-left: 1px outset #66cc33; }

.border-l-success-none {
  border-left: 1px none #66cc33; }

.border-l-success-hidden {
  border-left: 1px hidden #66cc33; }

.has-text-success-light {
  color: #dbffe0; }

.has-background-success-light {
  background-color: #dbffe0; }

.border-success-light {
  border: 1px solid #dbffe0; }

.border-m-success-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dbffe0;
  transform: translateY(-50%); }

.border-success-light-dotted {
  border: 1px dotted #dbffe0; }

.border-success-light-dashed {
  border: 1px dashed #dbffe0; }

.border-success-light-solid {
  border: 1px solid #dbffe0; }

.border-success-light-double {
  border: 1px double #dbffe0; }

.border-success-light-groove {
  border: 1px groove #dbffe0; }

.border-success-light-ridge {
  border: 1px ridge #dbffe0; }

.border-success-light-inset {
  border: 1px inset #dbffe0; }

.border-success-light-outset {
  border: 1px outset #dbffe0; }

.border-success-light-none {
  border: 1px none #dbffe0; }

.border-success-light-hidden {
  border: 1px hidden #dbffe0; }

.border-t-success-light {
  border-top: 1px solid #dbffe0; }

.border-t-success-light-dotted {
  border-top: 1px dotted #dbffe0; }

.border-t-success-light-dashed {
  border-top: 1px dashed #dbffe0; }

.border-t-success-light-solid {
  border-top: 1px solid #dbffe0; }

.border-t-success-light-double {
  border-top: 1px double #dbffe0; }

.border-t-success-light-groove {
  border-top: 1px groove #dbffe0; }

.border-t-success-light-ridge {
  border-top: 1px ridge #dbffe0; }

.border-t-success-light-inset {
  border-top: 1px inset #dbffe0; }

.border-t-success-light-outset {
  border-top: 1px outset #dbffe0; }

.border-t-success-light-none {
  border-top: 1px none #dbffe0; }

.border-t-success-light-hidden {
  border-top: 1px hidden #dbffe0; }

.border-r-success-light {
  border-right: 1px solid #dbffe0; }

.border-r-success-light-dotted {
  border-right: 1px dotted #dbffe0; }

.border-r-success-light-dashed {
  border-right: 1px dashed #dbffe0; }

.border-r-success-light-solid {
  border-right: 1px solid #dbffe0; }

.border-r-success-light-double {
  border-right: 1px double #dbffe0; }

.border-r-success-light-groove {
  border-right: 1px groove #dbffe0; }

.border-r-success-light-ridge {
  border-right: 1px ridge #dbffe0; }

.border-r-success-light-inset {
  border-right: 1px inset #dbffe0; }

.border-r-success-light-outset {
  border-right: 1px outset #dbffe0; }

.border-r-success-light-none {
  border-right: 1px none #dbffe0; }

.border-r-success-light-hidden {
  border-right: 1px hidden #dbffe0; }

.border-b-success-light {
  border-bottom: 1px solid #dbffe0; }

.border-b-success-light-dotted {
  border-bottom: 1px dotted #dbffe0; }

.border-b-success-light-dashed {
  border-bottom: 1px dashed #dbffe0; }

.border-b-success-light-solid {
  border-bottom: 1px solid #dbffe0; }

.border-b-success-light-double {
  border-bottom: 1px double #dbffe0; }

.border-b-success-light-groove {
  border-bottom: 1px groove #dbffe0; }

.border-b-success-light-ridge {
  border-bottom: 1px ridge #dbffe0; }

.border-b-success-light-inset {
  border-bottom: 1px inset #dbffe0; }

.border-b-success-light-outset {
  border-bottom: 1px outset #dbffe0; }

.border-b-success-light-none {
  border-bottom: 1px none #dbffe0; }

.border-b-success-light-hidden {
  border-bottom: 1px hidden #dbffe0; }

.border-l-success-light {
  border-left: 1px solid #dbffe0; }

.border-l-success-light-dotted {
  border-left: 1px dotted #dbffe0; }

.border-l-success-light-dashed {
  border-left: 1px dashed #dbffe0; }

.border-l-success-light-solid {
  border-left: 1px solid #dbffe0; }

.border-l-success-light-double {
  border-left: 1px double #dbffe0; }

.border-l-success-light-groove {
  border-left: 1px groove #dbffe0; }

.border-l-success-light-ridge {
  border-left: 1px ridge #dbffe0; }

.border-l-success-light-inset {
  border-left: 1px inset #dbffe0; }

.border-l-success-light-outset {
  border-left: 1px outset #dbffe0; }

.border-l-success-light-none {
  border-left: 1px none #dbffe0; }

.border-l-success-light-hidden {
  border-left: 1px hidden #dbffe0; }

.has-text-info {
  color: #209fee; }

.has-background-info {
  background-color: #209fee; }

.border-info {
  border: 1px solid #209fee; }

.border-m-info:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #209fee;
  transform: translateY(-50%); }

.border-info-dotted {
  border: 1px dotted #209fee; }

.border-info-dashed {
  border: 1px dashed #209fee; }

.border-info-solid {
  border: 1px solid #209fee; }

.border-info-double {
  border: 1px double #209fee; }

.border-info-groove {
  border: 1px groove #209fee; }

.border-info-ridge {
  border: 1px ridge #209fee; }

.border-info-inset {
  border: 1px inset #209fee; }

.border-info-outset {
  border: 1px outset #209fee; }

.border-info-none {
  border: 1px none #209fee; }

.border-info-hidden {
  border: 1px hidden #209fee; }

.border-t-info {
  border-top: 1px solid #209fee; }

.border-t-info-dotted {
  border-top: 1px dotted #209fee; }

.border-t-info-dashed {
  border-top: 1px dashed #209fee; }

.border-t-info-solid {
  border-top: 1px solid #209fee; }

.border-t-info-double {
  border-top: 1px double #209fee; }

.border-t-info-groove {
  border-top: 1px groove #209fee; }

.border-t-info-ridge {
  border-top: 1px ridge #209fee; }

.border-t-info-inset {
  border-top: 1px inset #209fee; }

.border-t-info-outset {
  border-top: 1px outset #209fee; }

.border-t-info-none {
  border-top: 1px none #209fee; }

.border-t-info-hidden {
  border-top: 1px hidden #209fee; }

.border-r-info {
  border-right: 1px solid #209fee; }

.border-r-info-dotted {
  border-right: 1px dotted #209fee; }

.border-r-info-dashed {
  border-right: 1px dashed #209fee; }

.border-r-info-solid {
  border-right: 1px solid #209fee; }

.border-r-info-double {
  border-right: 1px double #209fee; }

.border-r-info-groove {
  border-right: 1px groove #209fee; }

.border-r-info-ridge {
  border-right: 1px ridge #209fee; }

.border-r-info-inset {
  border-right: 1px inset #209fee; }

.border-r-info-outset {
  border-right: 1px outset #209fee; }

.border-r-info-none {
  border-right: 1px none #209fee; }

.border-r-info-hidden {
  border-right: 1px hidden #209fee; }

.border-b-info {
  border-bottom: 1px solid #209fee; }

.border-b-info-dotted {
  border-bottom: 1px dotted #209fee; }

.border-b-info-dashed {
  border-bottom: 1px dashed #209fee; }

.border-b-info-solid {
  border-bottom: 1px solid #209fee; }

.border-b-info-double {
  border-bottom: 1px double #209fee; }

.border-b-info-groove {
  border-bottom: 1px groove #209fee; }

.border-b-info-ridge {
  border-bottom: 1px ridge #209fee; }

.border-b-info-inset {
  border-bottom: 1px inset #209fee; }

.border-b-info-outset {
  border-bottom: 1px outset #209fee; }

.border-b-info-none {
  border-bottom: 1px none #209fee; }

.border-b-info-hidden {
  border-bottom: 1px hidden #209fee; }

.border-l-info {
  border-left: 1px solid #209fee; }

.border-l-info-dotted {
  border-left: 1px dotted #209fee; }

.border-l-info-dashed {
  border-left: 1px dashed #209fee; }

.border-l-info-solid {
  border-left: 1px solid #209fee; }

.border-l-info-double {
  border-left: 1px double #209fee; }

.border-l-info-groove {
  border-left: 1px groove #209fee; }

.border-l-info-ridge {
  border-left: 1px ridge #209fee; }

.border-l-info-inset {
  border-left: 1px inset #209fee; }

.border-l-info-outset {
  border-left: 1px outset #209fee; }

.border-l-info-none {
  border-left: 1px none #209fee; }

.border-l-info-hidden {
  border-left: 1px hidden #209fee; }

.has-text-black {
  color: #0a0a0a; }

.has-background-black {
  background-color: #0a0a0a; }

.border-black {
  border: 1px solid #0a0a0a; }

.border-black-dotted {
  border: 1px dotted #0a0a0a; }

.border-black-dashed {
  border: 1px dashed #0a0a0a; }

.border-black-solid {
  border: 1px solid #0a0a0a; }

.border-black-double {
  border: 1px double #0a0a0a; }

.border-black-groove {
  border: 1px groove #0a0a0a; }

.border-black-ridge {
  border: 1px ridge #0a0a0a; }

.border-black-inset {
  border: 1px inset #0a0a0a; }

.border-black-outset {
  border: 1px outset #0a0a0a; }

.border-black-none {
  border: 1px none #0a0a0a; }

.border-black-hidden {
  border: 1px hidden #0a0a0a; }

.border-t-black {
  border-top: 1px solid #0a0a0a; }

.border-t-black-dotted {
  border-top: 1px dotted #0a0a0a; }

.border-t-black-dashed {
  border-top: 1px dashed #0a0a0a; }

.border-t-black-solid {
  border-top: 1px solid #0a0a0a; }

.border-t-black-double {
  border-top: 1px double #0a0a0a; }

.border-t-black-groove {
  border-top: 1px groove #0a0a0a; }

.border-t-black-ridge {
  border-top: 1px ridge #0a0a0a; }

.border-t-black-inset {
  border-top: 1px inset #0a0a0a; }

.border-t-black-outset {
  border-top: 1px outset #0a0a0a; }

.border-t-black-none {
  border-top: 1px none #0a0a0a; }

.border-t-black-hidden {
  border-top: 1px hidden #0a0a0a; }

.border-r-black {
  border-right: 1px solid #0a0a0a; }

.border-r-black-dotted {
  border-right: 1px dotted #0a0a0a; }

.border-r-black-dashed {
  border-right: 1px dashed #0a0a0a; }

.border-r-black-solid {
  border-right: 1px solid #0a0a0a; }

.border-r-black-double {
  border-right: 1px double #0a0a0a; }

.border-r-black-groove {
  border-right: 1px groove #0a0a0a; }

.border-r-black-ridge {
  border-right: 1px ridge #0a0a0a; }

.border-r-black-inset {
  border-right: 1px inset #0a0a0a; }

.border-r-black-outset {
  border-right: 1px outset #0a0a0a; }

.border-r-black-none {
  border-right: 1px none #0a0a0a; }

.border-r-black-hidden {
  border-right: 1px hidden #0a0a0a; }

.border-b-black {
  border-bottom: 1px solid #0a0a0a; }

.border-b-black-dotted {
  border-bottom: 1px dotted #0a0a0a; }

.border-b-black-dashed {
  border-bottom: 1px dashed #0a0a0a; }

.border-b-black-solid {
  border-bottom: 1px solid #0a0a0a; }

.border-b-black-double {
  border-bottom: 1px double #0a0a0a; }

.border-b-black-groove {
  border-bottom: 1px groove #0a0a0a; }

.border-b-black-ridge {
  border-bottom: 1px ridge #0a0a0a; }

.border-b-black-inset {
  border-bottom: 1px inset #0a0a0a; }

.border-b-black-outset {
  border-bottom: 1px outset #0a0a0a; }

.border-b-black-none {
  border-bottom: 1px none #0a0a0a; }

.border-b-black-hidden {
  border-bottom: 1px hidden #0a0a0a; }

.border-l-black {
  border-left: 1px solid #0a0a0a; }

.border-l-black-dotted {
  border-left: 1px dotted #0a0a0a; }

.border-l-black-dashed {
  border-left: 1px dashed #0a0a0a; }

.border-l-black-solid {
  border-left: 1px solid #0a0a0a; }

.border-l-black-double {
  border-left: 1px double #0a0a0a; }

.border-l-black-groove {
  border-left: 1px groove #0a0a0a; }

.border-l-black-ridge {
  border-left: 1px ridge #0a0a0a; }

.border-l-black-inset {
  border-left: 1px inset #0a0a0a; }

.border-l-black-outset {
  border-left: 1px outset #0a0a0a; }

.border-l-black-none {
  border-left: 1px none #0a0a0a; }

.border-l-black-hidden {
  border-left: 1px hidden #0a0a0a; }

.has-text-black-bis {
  color: #121212; }

.has-background-black-bis {
  background-color: #121212; }

.border-black-bis {
  border: 1px solid #121212; }

.border-black-bis-dotted {
  border: 1px dotted #121212; }

.border-black-bis-dashed {
  border: 1px dashed #121212; }

.border-black-bis-solid {
  border: 1px solid #121212; }

.border-black-bis-double {
  border: 1px double #121212; }

.border-black-bis-groove {
  border: 1px groove #121212; }

.border-black-bis-ridge {
  border: 1px ridge #121212; }

.border-black-bis-inset {
  border: 1px inset #121212; }

.border-black-bis-outset {
  border: 1px outset #121212; }

.border-black-bis-none {
  border: 1px none #121212; }

.border-black-bis-hidden {
  border: 1px hidden #121212; }

.border-t-black-bis {
  border-top: 1px solid #121212; }

.border-t-black-bis-dotted {
  border-top: 1px dotted #121212; }

.border-t-black-bis-dashed {
  border-top: 1px dashed #121212; }

.border-t-black-bis-solid {
  border-top: 1px solid #121212; }

.border-t-black-bis-double {
  border-top: 1px double #121212; }

.border-t-black-bis-groove {
  border-top: 1px groove #121212; }

.border-t-black-bis-ridge {
  border-top: 1px ridge #121212; }

.border-t-black-bis-inset {
  border-top: 1px inset #121212; }

.border-t-black-bis-outset {
  border-top: 1px outset #121212; }

.border-t-black-bis-none {
  border-top: 1px none #121212; }

.border-t-black-bis-hidden {
  border-top: 1px hidden #121212; }

.border-r-black-bis {
  border-right: 1px solid #121212; }

.border-r-black-bis-dotted {
  border-right: 1px dotted #121212; }

.border-r-black-bis-dashed {
  border-right: 1px dashed #121212; }

.border-r-black-bis-solid {
  border-right: 1px solid #121212; }

.border-r-black-bis-double {
  border-right: 1px double #121212; }

.border-r-black-bis-groove {
  border-right: 1px groove #121212; }

.border-r-black-bis-ridge {
  border-right: 1px ridge #121212; }

.border-r-black-bis-inset {
  border-right: 1px inset #121212; }

.border-r-black-bis-outset {
  border-right: 1px outset #121212; }

.border-r-black-bis-none {
  border-right: 1px none #121212; }

.border-r-black-bis-hidden {
  border-right: 1px hidden #121212; }

.border-b-black-bis {
  border-bottom: 1px solid #121212; }

.border-b-black-bis-dotted {
  border-bottom: 1px dotted #121212; }

.border-b-black-bis-dashed {
  border-bottom: 1px dashed #121212; }

.border-b-black-bis-solid {
  border-bottom: 1px solid #121212; }

.border-b-black-bis-double {
  border-bottom: 1px double #121212; }

.border-b-black-bis-groove {
  border-bottom: 1px groove #121212; }

.border-b-black-bis-ridge {
  border-bottom: 1px ridge #121212; }

.border-b-black-bis-inset {
  border-bottom: 1px inset #121212; }

.border-b-black-bis-outset {
  border-bottom: 1px outset #121212; }

.border-b-black-bis-none {
  border-bottom: 1px none #121212; }

.border-b-black-bis-hidden {
  border-bottom: 1px hidden #121212; }

.border-l-black-bis {
  border-left: 1px solid #121212; }

.border-l-black-bis-dotted {
  border-left: 1px dotted #121212; }

.border-l-black-bis-dashed {
  border-left: 1px dashed #121212; }

.border-l-black-bis-solid {
  border-left: 1px solid #121212; }

.border-l-black-bis-double {
  border-left: 1px double #121212; }

.border-l-black-bis-groove {
  border-left: 1px groove #121212; }

.border-l-black-bis-ridge {
  border-left: 1px ridge #121212; }

.border-l-black-bis-inset {
  border-left: 1px inset #121212; }

.border-l-black-bis-outset {
  border-left: 1px outset #121212; }

.border-l-black-bis-none {
  border-left: 1px none #121212; }

.border-l-black-bis-hidden {
  border-left: 1px hidden #121212; }

.has-text-black-ter {
  color: #242424; }

.has-background-black-ter {
  background-color: #242424; }

.border-black-ter {
  border: 1px solid #242424; }

.border-black-ter-dotted {
  border: 1px dotted #242424; }

.border-black-ter-dashed {
  border: 1px dashed #242424; }

.border-black-ter-solid {
  border: 1px solid #242424; }

.border-black-ter-double {
  border: 1px double #242424; }

.border-black-ter-groove {
  border: 1px groove #242424; }

.border-black-ter-ridge {
  border: 1px ridge #242424; }

.border-black-ter-inset {
  border: 1px inset #242424; }

.border-black-ter-outset {
  border: 1px outset #242424; }

.border-black-ter-none {
  border: 1px none #242424; }

.border-black-ter-hidden {
  border: 1px hidden #242424; }

.border-t-black-ter {
  border-top: 1px solid #242424; }

.border-t-black-ter-dotted {
  border-top: 1px dotted #242424; }

.border-t-black-ter-dashed {
  border-top: 1px dashed #242424; }

.border-t-black-ter-solid {
  border-top: 1px solid #242424; }

.border-t-black-ter-double {
  border-top: 1px double #242424; }

.border-t-black-ter-groove {
  border-top: 1px groove #242424; }

.border-t-black-ter-ridge {
  border-top: 1px ridge #242424; }

.border-t-black-ter-inset {
  border-top: 1px inset #242424; }

.border-t-black-ter-outset {
  border-top: 1px outset #242424; }

.border-t-black-ter-none {
  border-top: 1px none #242424; }

.border-t-black-ter-hidden {
  border-top: 1px hidden #242424; }

.border-r-black-ter {
  border-right: 1px solid #242424; }

.border-r-black-ter-dotted {
  border-right: 1px dotted #242424; }

.border-r-black-ter-dashed {
  border-right: 1px dashed #242424; }

.border-r-black-ter-solid {
  border-right: 1px solid #242424; }

.border-r-black-ter-double {
  border-right: 1px double #242424; }

.border-r-black-ter-groove {
  border-right: 1px groove #242424; }

.border-r-black-ter-ridge {
  border-right: 1px ridge #242424; }

.border-r-black-ter-inset {
  border-right: 1px inset #242424; }

.border-r-black-ter-outset {
  border-right: 1px outset #242424; }

.border-r-black-ter-none {
  border-right: 1px none #242424; }

.border-r-black-ter-hidden {
  border-right: 1px hidden #242424; }

.border-b-black-ter {
  border-bottom: 1px solid #242424; }

.border-b-black-ter-dotted {
  border-bottom: 1px dotted #242424; }

.border-b-black-ter-dashed {
  border-bottom: 1px dashed #242424; }

.border-b-black-ter-solid {
  border-bottom: 1px solid #242424; }

.border-b-black-ter-double {
  border-bottom: 1px double #242424; }

.border-b-black-ter-groove {
  border-bottom: 1px groove #242424; }

.border-b-black-ter-ridge {
  border-bottom: 1px ridge #242424; }

.border-b-black-ter-inset {
  border-bottom: 1px inset #242424; }

.border-b-black-ter-outset {
  border-bottom: 1px outset #242424; }

.border-b-black-ter-none {
  border-bottom: 1px none #242424; }

.border-b-black-ter-hidden {
  border-bottom: 1px hidden #242424; }

.border-l-black-ter {
  border-left: 1px solid #242424; }

.border-l-black-ter-dotted {
  border-left: 1px dotted #242424; }

.border-l-black-ter-dashed {
  border-left: 1px dashed #242424; }

.border-l-black-ter-solid {
  border-left: 1px solid #242424; }

.border-l-black-ter-double {
  border-left: 1px double #242424; }

.border-l-black-ter-groove {
  border-left: 1px groove #242424; }

.border-l-black-ter-ridge {
  border-left: 1px ridge #242424; }

.border-l-black-ter-inset {
  border-left: 1px inset #242424; }

.border-l-black-ter-outset {
  border-left: 1px outset #242424; }

.border-l-black-ter-none {
  border-left: 1px none #242424; }

.border-l-black-ter-hidden {
  border-left: 1px hidden #242424; }

.has-text-grey-darker {
  color: #363636; }

.has-background-grey-darker {
  background-color: #363636; }

.border-grey-darker {
  border: 1px solid #363636; }

.border-grey-darker-dotted {
  border: 1px dotted #363636; }

.border-grey-darker-dashed {
  border: 1px dashed #363636; }

.border-grey-darker-solid {
  border: 1px solid #363636; }

.border-grey-darker-double {
  border: 1px double #363636; }

.border-grey-darker-groove {
  border: 1px groove #363636; }

.border-grey-darker-ridge {
  border: 1px ridge #363636; }

.border-grey-darker-inset {
  border: 1px inset #363636; }

.border-grey-darker-outset {
  border: 1px outset #363636; }

.border-grey-darker-none {
  border: 1px none #363636; }

.border-grey-darker-hidden {
  border: 1px hidden #363636; }

.border-t-grey-darker {
  border-top: 1px solid #363636; }

.border-t-grey-darker-dotted {
  border-top: 1px dotted #363636; }

.border-t-grey-darker-dashed {
  border-top: 1px dashed #363636; }

.border-t-grey-darker-solid {
  border-top: 1px solid #363636; }

.border-t-grey-darker-double {
  border-top: 1px double #363636; }

.border-t-grey-darker-groove {
  border-top: 1px groove #363636; }

.border-t-grey-darker-ridge {
  border-top: 1px ridge #363636; }

.border-t-grey-darker-inset {
  border-top: 1px inset #363636; }

.border-t-grey-darker-outset {
  border-top: 1px outset #363636; }

.border-t-grey-darker-none {
  border-top: 1px none #363636; }

.border-t-grey-darker-hidden {
  border-top: 1px hidden #363636; }

.border-r-grey-darker {
  border-right: 1px solid #363636; }

.border-r-grey-darker-dotted {
  border-right: 1px dotted #363636; }

.border-r-grey-darker-dashed {
  border-right: 1px dashed #363636; }

.border-r-grey-darker-solid {
  border-right: 1px solid #363636; }

.border-r-grey-darker-double {
  border-right: 1px double #363636; }

.border-r-grey-darker-groove {
  border-right: 1px groove #363636; }

.border-r-grey-darker-ridge {
  border-right: 1px ridge #363636; }

.border-r-grey-darker-inset {
  border-right: 1px inset #363636; }

.border-r-grey-darker-outset {
  border-right: 1px outset #363636; }

.border-r-grey-darker-none {
  border-right: 1px none #363636; }

.border-r-grey-darker-hidden {
  border-right: 1px hidden #363636; }

.border-b-grey-darker {
  border-bottom: 1px solid #363636; }

.border-b-grey-darker-dotted {
  border-bottom: 1px dotted #363636; }

.border-b-grey-darker-dashed {
  border-bottom: 1px dashed #363636; }

.border-b-grey-darker-solid {
  border-bottom: 1px solid #363636; }

.border-b-grey-darker-double {
  border-bottom: 1px double #363636; }

.border-b-grey-darker-groove {
  border-bottom: 1px groove #363636; }

.border-b-grey-darker-ridge {
  border-bottom: 1px ridge #363636; }

.border-b-grey-darker-inset {
  border-bottom: 1px inset #363636; }

.border-b-grey-darker-outset {
  border-bottom: 1px outset #363636; }

.border-b-grey-darker-none {
  border-bottom: 1px none #363636; }

.border-b-grey-darker-hidden {
  border-bottom: 1px hidden #363636; }

.border-l-grey-darker {
  border-left: 1px solid #363636; }

.border-l-grey-darker-dotted {
  border-left: 1px dotted #363636; }

.border-l-grey-darker-dashed {
  border-left: 1px dashed #363636; }

.border-l-grey-darker-solid {
  border-left: 1px solid #363636; }

.border-l-grey-darker-double {
  border-left: 1px double #363636; }

.border-l-grey-darker-groove {
  border-left: 1px groove #363636; }

.border-l-grey-darker-ridge {
  border-left: 1px ridge #363636; }

.border-l-grey-darker-inset {
  border-left: 1px inset #363636; }

.border-l-grey-darker-outset {
  border-left: 1px outset #363636; }

.border-l-grey-darker-none {
  border-left: 1px none #363636; }

.border-l-grey-darker-hidden {
  border-left: 1px hidden #363636; }

.has-text-grey-dark {
  color: #4a4a4a; }

.has-background-grey-dark {
  background-color: #4a4a4a; }

.border-grey-dark {
  border: 1px solid #4a4a4a; }

.border-grey-dark-dotted {
  border: 1px dotted #4a4a4a; }

.border-grey-dark-dashed {
  border: 1px dashed #4a4a4a; }

.border-grey-dark-solid {
  border: 1px solid #4a4a4a; }

.border-grey-dark-double {
  border: 1px double #4a4a4a; }

.border-grey-dark-groove {
  border: 1px groove #4a4a4a; }

.border-grey-dark-ridge {
  border: 1px ridge #4a4a4a; }

.border-grey-dark-inset {
  border: 1px inset #4a4a4a; }

.border-grey-dark-outset {
  border: 1px outset #4a4a4a; }

.border-grey-dark-none {
  border: 1px none #4a4a4a; }

.border-grey-dark-hidden {
  border: 1px hidden #4a4a4a; }

.border-t-grey-dark {
  border-top: 1px solid #4a4a4a; }

.border-t-grey-dark-dotted {
  border-top: 1px dotted #4a4a4a; }

.border-t-grey-dark-dashed {
  border-top: 1px dashed #4a4a4a; }

.border-t-grey-dark-solid {
  border-top: 1px solid #4a4a4a; }

.border-t-grey-dark-double {
  border-top: 1px double #4a4a4a; }

.border-t-grey-dark-groove {
  border-top: 1px groove #4a4a4a; }

.border-t-grey-dark-ridge {
  border-top: 1px ridge #4a4a4a; }

.border-t-grey-dark-inset {
  border-top: 1px inset #4a4a4a; }

.border-t-grey-dark-outset {
  border-top: 1px outset #4a4a4a; }

.border-t-grey-dark-none {
  border-top: 1px none #4a4a4a; }

.border-t-grey-dark-hidden {
  border-top: 1px hidden #4a4a4a; }

.border-r-grey-dark {
  border-right: 1px solid #4a4a4a; }

.border-r-grey-dark-dotted {
  border-right: 1px dotted #4a4a4a; }

.border-r-grey-dark-dashed {
  border-right: 1px dashed #4a4a4a; }

.border-r-grey-dark-solid {
  border-right: 1px solid #4a4a4a; }

.border-r-grey-dark-double {
  border-right: 1px double #4a4a4a; }

.border-r-grey-dark-groove {
  border-right: 1px groove #4a4a4a; }

.border-r-grey-dark-ridge {
  border-right: 1px ridge #4a4a4a; }

.border-r-grey-dark-inset {
  border-right: 1px inset #4a4a4a; }

.border-r-grey-dark-outset {
  border-right: 1px outset #4a4a4a; }

.border-r-grey-dark-none {
  border-right: 1px none #4a4a4a; }

.border-r-grey-dark-hidden {
  border-right: 1px hidden #4a4a4a; }

.border-b-grey-dark {
  border-bottom: 1px solid #4a4a4a; }

.border-b-grey-dark-dotted {
  border-bottom: 1px dotted #4a4a4a; }

.border-b-grey-dark-dashed {
  border-bottom: 1px dashed #4a4a4a; }

.border-b-grey-dark-solid {
  border-bottom: 1px solid #4a4a4a; }

.border-b-grey-dark-double {
  border-bottom: 1px double #4a4a4a; }

.border-b-grey-dark-groove {
  border-bottom: 1px groove #4a4a4a; }

.border-b-grey-dark-ridge {
  border-bottom: 1px ridge #4a4a4a; }

.border-b-grey-dark-inset {
  border-bottom: 1px inset #4a4a4a; }

.border-b-grey-dark-outset {
  border-bottom: 1px outset #4a4a4a; }

.border-b-grey-dark-none {
  border-bottom: 1px none #4a4a4a; }

.border-b-grey-dark-hidden {
  border-bottom: 1px hidden #4a4a4a; }

.border-l-grey-dark {
  border-left: 1px solid #4a4a4a; }

.border-l-grey-dark-dotted {
  border-left: 1px dotted #4a4a4a; }

.border-l-grey-dark-dashed {
  border-left: 1px dashed #4a4a4a; }

.border-l-grey-dark-solid {
  border-left: 1px solid #4a4a4a; }

.border-l-grey-dark-double {
  border-left: 1px double #4a4a4a; }

.border-l-grey-dark-groove {
  border-left: 1px groove #4a4a4a; }

.border-l-grey-dark-ridge {
  border-left: 1px ridge #4a4a4a; }

.border-l-grey-dark-inset {
  border-left: 1px inset #4a4a4a; }

.border-l-grey-dark-outset {
  border-left: 1px outset #4a4a4a; }

.border-l-grey-dark-none {
  border-left: 1px none #4a4a4a; }

.border-l-grey-dark-hidden {
  border-left: 1px hidden #4a4a4a; }

.has-text-grey {
  color: #7a7a7a; }

.has-background-grey {
  background-color: #7a7a7a; }

.border-grey {
  border: 1px solid #7a7a7a; }

.border-grey-dotted {
  border: 1px dotted #7a7a7a; }

.border-grey-dashed {
  border: 1px dashed #7a7a7a; }

.border-grey-solid {
  border: 1px solid #7a7a7a; }

.border-grey-double {
  border: 1px double #7a7a7a; }

.border-grey-groove {
  border: 1px groove #7a7a7a; }

.border-grey-ridge {
  border: 1px ridge #7a7a7a; }

.border-grey-inset {
  border: 1px inset #7a7a7a; }

.border-grey-outset {
  border: 1px outset #7a7a7a; }

.border-grey-none {
  border: 1px none #7a7a7a; }

.border-grey-hidden {
  border: 1px hidden #7a7a7a; }

.border-t-grey {
  border-top: 1px solid #7a7a7a; }

.border-t-grey-dotted {
  border-top: 1px dotted #7a7a7a; }

.border-t-grey-dashed {
  border-top: 1px dashed #7a7a7a; }

.border-t-grey-solid {
  border-top: 1px solid #7a7a7a; }

.border-t-grey-double {
  border-top: 1px double #7a7a7a; }

.border-t-grey-groove {
  border-top: 1px groove #7a7a7a; }

.border-t-grey-ridge {
  border-top: 1px ridge #7a7a7a; }

.border-t-grey-inset {
  border-top: 1px inset #7a7a7a; }

.border-t-grey-outset {
  border-top: 1px outset #7a7a7a; }

.border-t-grey-none {
  border-top: 1px none #7a7a7a; }

.border-t-grey-hidden {
  border-top: 1px hidden #7a7a7a; }

.border-r-grey {
  border-right: 1px solid #7a7a7a; }

.border-r-grey-dotted {
  border-right: 1px dotted #7a7a7a; }

.border-r-grey-dashed {
  border-right: 1px dashed #7a7a7a; }

.border-r-grey-solid {
  border-right: 1px solid #7a7a7a; }

.border-r-grey-double {
  border-right: 1px double #7a7a7a; }

.border-r-grey-groove {
  border-right: 1px groove #7a7a7a; }

.border-r-grey-ridge {
  border-right: 1px ridge #7a7a7a; }

.border-r-grey-inset {
  border-right: 1px inset #7a7a7a; }

.border-r-grey-outset {
  border-right: 1px outset #7a7a7a; }

.border-r-grey-none {
  border-right: 1px none #7a7a7a; }

.border-r-grey-hidden {
  border-right: 1px hidden #7a7a7a; }

.border-b-grey {
  border-bottom: 1px solid #7a7a7a; }

.border-b-grey-dotted {
  border-bottom: 1px dotted #7a7a7a; }

.border-b-grey-dashed {
  border-bottom: 1px dashed #7a7a7a; }

.border-b-grey-solid {
  border-bottom: 1px solid #7a7a7a; }

.border-b-grey-double {
  border-bottom: 1px double #7a7a7a; }

.border-b-grey-groove {
  border-bottom: 1px groove #7a7a7a; }

.border-b-grey-ridge {
  border-bottom: 1px ridge #7a7a7a; }

.border-b-grey-inset {
  border-bottom: 1px inset #7a7a7a; }

.border-b-grey-outset {
  border-bottom: 1px outset #7a7a7a; }

.border-b-grey-none {
  border-bottom: 1px none #7a7a7a; }

.border-b-grey-hidden {
  border-bottom: 1px hidden #7a7a7a; }

.border-l-grey {
  border-left: 1px solid #7a7a7a; }

.border-l-grey-dotted {
  border-left: 1px dotted #7a7a7a; }

.border-l-grey-dashed {
  border-left: 1px dashed #7a7a7a; }

.border-l-grey-solid {
  border-left: 1px solid #7a7a7a; }

.border-l-grey-double {
  border-left: 1px double #7a7a7a; }

.border-l-grey-groove {
  border-left: 1px groove #7a7a7a; }

.border-l-grey-ridge {
  border-left: 1px ridge #7a7a7a; }

.border-l-grey-inset {
  border-left: 1px inset #7a7a7a; }

.border-l-grey-outset {
  border-left: 1px outset #7a7a7a; }

.border-l-grey-none {
  border-left: 1px none #7a7a7a; }

.border-l-grey-hidden {
  border-left: 1px hidden #7a7a7a; }

.has-text-grey-light {
  color: #b5b5b5; }

.has-background-grey-light {
  background-color: #b5b5b5; }

.border-grey-light {
  border: 1px solid #b5b5b5; }

.border-grey-light-dotted {
  border: 1px dotted #b5b5b5; }

.border-grey-light-dashed {
  border: 1px dashed #b5b5b5; }

.border-grey-light-solid {
  border: 1px solid #b5b5b5; }

.border-grey-light-double {
  border: 1px double #b5b5b5; }

.border-grey-light-groove {
  border: 1px groove #b5b5b5; }

.border-grey-light-ridge {
  border: 1px ridge #b5b5b5; }

.border-grey-light-inset {
  border: 1px inset #b5b5b5; }

.border-grey-light-outset {
  border: 1px outset #b5b5b5; }

.border-grey-light-none {
  border: 1px none #b5b5b5; }

.border-grey-light-hidden {
  border: 1px hidden #b5b5b5; }

.border-t-grey-light {
  border-top: 1px solid #b5b5b5; }

.border-t-grey-light-dotted {
  border-top: 1px dotted #b5b5b5; }

.border-t-grey-light-dashed {
  border-top: 1px dashed #b5b5b5; }

.border-t-grey-light-solid {
  border-top: 1px solid #b5b5b5; }

.border-t-grey-light-double {
  border-top: 1px double #b5b5b5; }

.border-t-grey-light-groove {
  border-top: 1px groove #b5b5b5; }

.border-t-grey-light-ridge {
  border-top: 1px ridge #b5b5b5; }

.border-t-grey-light-inset {
  border-top: 1px inset #b5b5b5; }

.border-t-grey-light-outset {
  border-top: 1px outset #b5b5b5; }

.border-t-grey-light-none {
  border-top: 1px none #b5b5b5; }

.border-t-grey-light-hidden {
  border-top: 1px hidden #b5b5b5; }

.border-r-grey-light {
  border-right: 1px solid #b5b5b5; }

.border-r-grey-light-dotted {
  border-right: 1px dotted #b5b5b5; }

.border-r-grey-light-dashed {
  border-right: 1px dashed #b5b5b5; }

.border-r-grey-light-solid {
  border-right: 1px solid #b5b5b5; }

.border-r-grey-light-double {
  border-right: 1px double #b5b5b5; }

.border-r-grey-light-groove {
  border-right: 1px groove #b5b5b5; }

.border-r-grey-light-ridge {
  border-right: 1px ridge #b5b5b5; }

.border-r-grey-light-inset {
  border-right: 1px inset #b5b5b5; }

.border-r-grey-light-outset {
  border-right: 1px outset #b5b5b5; }

.border-r-grey-light-none {
  border-right: 1px none #b5b5b5; }

.border-r-grey-light-hidden {
  border-right: 1px hidden #b5b5b5; }

.border-b-grey-light {
  border-bottom: 1px solid #b5b5b5; }

.border-b-grey-light-dotted {
  border-bottom: 1px dotted #b5b5b5; }

.border-b-grey-light-dashed {
  border-bottom: 1px dashed #b5b5b5; }

.border-b-grey-light-solid {
  border-bottom: 1px solid #b5b5b5; }

.border-b-grey-light-double {
  border-bottom: 1px double #b5b5b5; }

.border-b-grey-light-groove {
  border-bottom: 1px groove #b5b5b5; }

.border-b-grey-light-ridge {
  border-bottom: 1px ridge #b5b5b5; }

.border-b-grey-light-inset {
  border-bottom: 1px inset #b5b5b5; }

.border-b-grey-light-outset {
  border-bottom: 1px outset #b5b5b5; }

.border-b-grey-light-none {
  border-bottom: 1px none #b5b5b5; }

.border-b-grey-light-hidden {
  border-bottom: 1px hidden #b5b5b5; }

.border-l-grey-light {
  border-left: 1px solid #b5b5b5; }

.border-l-grey-light-dotted {
  border-left: 1px dotted #b5b5b5; }

.border-l-grey-light-dashed {
  border-left: 1px dashed #b5b5b5; }

.border-l-grey-light-solid {
  border-left: 1px solid #b5b5b5; }

.border-l-grey-light-double {
  border-left: 1px double #b5b5b5; }

.border-l-grey-light-groove {
  border-left: 1px groove #b5b5b5; }

.border-l-grey-light-ridge {
  border-left: 1px ridge #b5b5b5; }

.border-l-grey-light-inset {
  border-left: 1px inset #b5b5b5; }

.border-l-grey-light-outset {
  border-left: 1px outset #b5b5b5; }

.border-l-grey-light-none {
  border-left: 1px none #b5b5b5; }

.border-l-grey-light-hidden {
  border-left: 1px hidden #b5b5b5; }

.has-text-grey-lighter {
  color: #e6e6e6; }

.has-background-grey-lighter {
  background-color: #e6e6e6; }

.border-grey-lighter {
  border: 1px solid #e6e6e6; }

.border-grey-lighter-dotted {
  border: 1px dotted #e6e6e6; }

.border-grey-lighter-dashed {
  border: 1px dashed #e6e6e6; }

.border-grey-lighter-solid {
  border: 1px solid #e6e6e6; }

.border-grey-lighter-double {
  border: 1px double #e6e6e6; }

.border-grey-lighter-groove {
  border: 1px groove #e6e6e6; }

.border-grey-lighter-ridge {
  border: 1px ridge #e6e6e6; }

.border-grey-lighter-inset {
  border: 1px inset #e6e6e6; }

.border-grey-lighter-outset {
  border: 1px outset #e6e6e6; }

.border-grey-lighter-none {
  border: 1px none #e6e6e6; }

.border-grey-lighter-hidden {
  border: 1px hidden #e6e6e6; }

.border-t-grey-lighter {
  border-top: 1px solid #e6e6e6; }

.border-t-grey-lighter-dotted {
  border-top: 1px dotted #e6e6e6; }

.border-t-grey-lighter-dashed {
  border-top: 1px dashed #e6e6e6; }

.border-t-grey-lighter-solid {
  border-top: 1px solid #e6e6e6; }

.border-t-grey-lighter-double {
  border-top: 1px double #e6e6e6; }

.border-t-grey-lighter-groove {
  border-top: 1px groove #e6e6e6; }

.border-t-grey-lighter-ridge {
  border-top: 1px ridge #e6e6e6; }

.border-t-grey-lighter-inset {
  border-top: 1px inset #e6e6e6; }

.border-t-grey-lighter-outset {
  border-top: 1px outset #e6e6e6; }

.border-t-grey-lighter-none {
  border-top: 1px none #e6e6e6; }

.border-t-grey-lighter-hidden {
  border-top: 1px hidden #e6e6e6; }

.border-r-grey-lighter {
  border-right: 1px solid #e6e6e6; }

.border-r-grey-lighter-dotted {
  border-right: 1px dotted #e6e6e6; }

.border-r-grey-lighter-dashed {
  border-right: 1px dashed #e6e6e6; }

.border-r-grey-lighter-solid {
  border-right: 1px solid #e6e6e6; }

.border-r-grey-lighter-double {
  border-right: 1px double #e6e6e6; }

.border-r-grey-lighter-groove {
  border-right: 1px groove #e6e6e6; }

.border-r-grey-lighter-ridge {
  border-right: 1px ridge #e6e6e6; }

.border-r-grey-lighter-inset {
  border-right: 1px inset #e6e6e6; }

.border-r-grey-lighter-outset {
  border-right: 1px outset #e6e6e6; }

.border-r-grey-lighter-none {
  border-right: 1px none #e6e6e6; }

.border-r-grey-lighter-hidden {
  border-right: 1px hidden #e6e6e6; }

.border-b-grey-lighter {
  border-bottom: 1px solid #e6e6e6; }

.border-b-grey-lighter-dotted {
  border-bottom: 1px dotted #e6e6e6; }

.border-b-grey-lighter-dashed {
  border-bottom: 1px dashed #e6e6e6; }

.border-b-grey-lighter-solid {
  border-bottom: 1px solid #e6e6e6; }

.border-b-grey-lighter-double {
  border-bottom: 1px double #e6e6e6; }

.border-b-grey-lighter-groove {
  border-bottom: 1px groove #e6e6e6; }

.border-b-grey-lighter-ridge {
  border-bottom: 1px ridge #e6e6e6; }

.border-b-grey-lighter-inset {
  border-bottom: 1px inset #e6e6e6; }

.border-b-grey-lighter-outset {
  border-bottom: 1px outset #e6e6e6; }

.border-b-grey-lighter-none {
  border-bottom: 1px none #e6e6e6; }

.border-b-grey-lighter-hidden {
  border-bottom: 1px hidden #e6e6e6; }

.border-l-grey-lighter {
  border-left: 1px solid #e6e6e6; }

.border-l-grey-lighter-dotted {
  border-left: 1px dotted #e6e6e6; }

.border-l-grey-lighter-dashed {
  border-left: 1px dashed #e6e6e6; }

.border-l-grey-lighter-solid {
  border-left: 1px solid #e6e6e6; }

.border-l-grey-lighter-double {
  border-left: 1px double #e6e6e6; }

.border-l-grey-lighter-groove {
  border-left: 1px groove #e6e6e6; }

.border-l-grey-lighter-ridge {
  border-left: 1px ridge #e6e6e6; }

.border-l-grey-lighter-inset {
  border-left: 1px inset #e6e6e6; }

.border-l-grey-lighter-outset {
  border-left: 1px outset #e6e6e6; }

.border-l-grey-lighter-none {
  border-left: 1px none #e6e6e6; }

.border-l-grey-lighter-hidden {
  border-left: 1px hidden #e6e6e6; }

.has-text-white-ter {
  color: whitesmoke; }

.has-background-white-ter {
  background-color: whitesmoke; }

.border-white-ter {
  border: 1px solid whitesmoke; }

.border-white-ter-dotted {
  border: 1px dotted whitesmoke; }

.border-white-ter-dashed {
  border: 1px dashed whitesmoke; }

.border-white-ter-solid {
  border: 1px solid whitesmoke; }

.border-white-ter-double {
  border: 1px double whitesmoke; }

.border-white-ter-groove {
  border: 1px groove whitesmoke; }

.border-white-ter-ridge {
  border: 1px ridge whitesmoke; }

.border-white-ter-inset {
  border: 1px inset whitesmoke; }

.border-white-ter-outset {
  border: 1px outset whitesmoke; }

.border-white-ter-none {
  border: 1px none whitesmoke; }

.border-white-ter-hidden {
  border: 1px hidden whitesmoke; }

.border-t-white-ter {
  border-top: 1px solid whitesmoke; }

.border-t-white-ter-dotted {
  border-top: 1px dotted whitesmoke; }

.border-t-white-ter-dashed {
  border-top: 1px dashed whitesmoke; }

.border-t-white-ter-solid {
  border-top: 1px solid whitesmoke; }

.border-t-white-ter-double {
  border-top: 1px double whitesmoke; }

.border-t-white-ter-groove {
  border-top: 1px groove whitesmoke; }

.border-t-white-ter-ridge {
  border-top: 1px ridge whitesmoke; }

.border-t-white-ter-inset {
  border-top: 1px inset whitesmoke; }

.border-t-white-ter-outset {
  border-top: 1px outset whitesmoke; }

.border-t-white-ter-none {
  border-top: 1px none whitesmoke; }

.border-t-white-ter-hidden {
  border-top: 1px hidden whitesmoke; }

.border-r-white-ter {
  border-right: 1px solid whitesmoke; }

.border-r-white-ter-dotted {
  border-right: 1px dotted whitesmoke; }

.border-r-white-ter-dashed {
  border-right: 1px dashed whitesmoke; }

.border-r-white-ter-solid {
  border-right: 1px solid whitesmoke; }

.border-r-white-ter-double {
  border-right: 1px double whitesmoke; }

.border-r-white-ter-groove {
  border-right: 1px groove whitesmoke; }

.border-r-white-ter-ridge {
  border-right: 1px ridge whitesmoke; }

.border-r-white-ter-inset {
  border-right: 1px inset whitesmoke; }

.border-r-white-ter-outset {
  border-right: 1px outset whitesmoke; }

.border-r-white-ter-none {
  border-right: 1px none whitesmoke; }

.border-r-white-ter-hidden {
  border-right: 1px hidden whitesmoke; }

.border-b-white-ter {
  border-bottom: 1px solid whitesmoke; }

.border-b-white-ter-dotted {
  border-bottom: 1px dotted whitesmoke; }

.border-b-white-ter-dashed {
  border-bottom: 1px dashed whitesmoke; }

.border-b-white-ter-solid {
  border-bottom: 1px solid whitesmoke; }

.border-b-white-ter-double {
  border-bottom: 1px double whitesmoke; }

.border-b-white-ter-groove {
  border-bottom: 1px groove whitesmoke; }

.border-b-white-ter-ridge {
  border-bottom: 1px ridge whitesmoke; }

.border-b-white-ter-inset {
  border-bottom: 1px inset whitesmoke; }

.border-b-white-ter-outset {
  border-bottom: 1px outset whitesmoke; }

.border-b-white-ter-none {
  border-bottom: 1px none whitesmoke; }

.border-b-white-ter-hidden {
  border-bottom: 1px hidden whitesmoke; }

.border-l-white-ter {
  border-left: 1px solid whitesmoke; }

.border-l-white-ter-dotted {
  border-left: 1px dotted whitesmoke; }

.border-l-white-ter-dashed {
  border-left: 1px dashed whitesmoke; }

.border-l-white-ter-solid {
  border-left: 1px solid whitesmoke; }

.border-l-white-ter-double {
  border-left: 1px double whitesmoke; }

.border-l-white-ter-groove {
  border-left: 1px groove whitesmoke; }

.border-l-white-ter-ridge {
  border-left: 1px ridge whitesmoke; }

.border-l-white-ter-inset {
  border-left: 1px inset whitesmoke; }

.border-l-white-ter-outset {
  border-left: 1px outset whitesmoke; }

.border-l-white-ter-none {
  border-left: 1px none whitesmoke; }

.border-l-white-ter-hidden {
  border-left: 1px hidden whitesmoke; }

.has-text-white-bis {
  color: #fafafa; }

.has-background-white-bis {
  background-color: #fafafa; }

.border-white-bis {
  border: 1px solid #fafafa; }

.border-white-bis-dotted {
  border: 1px dotted #fafafa; }

.border-white-bis-dashed {
  border: 1px dashed #fafafa; }

.border-white-bis-solid {
  border: 1px solid #fafafa; }

.border-white-bis-double {
  border: 1px double #fafafa; }

.border-white-bis-groove {
  border: 1px groove #fafafa; }

.border-white-bis-ridge {
  border: 1px ridge #fafafa; }

.border-white-bis-inset {
  border: 1px inset #fafafa; }

.border-white-bis-outset {
  border: 1px outset #fafafa; }

.border-white-bis-none {
  border: 1px none #fafafa; }

.border-white-bis-hidden {
  border: 1px hidden #fafafa; }

.border-t-white-bis {
  border-top: 1px solid #fafafa; }

.border-t-white-bis-dotted {
  border-top: 1px dotted #fafafa; }

.border-t-white-bis-dashed {
  border-top: 1px dashed #fafafa; }

.border-t-white-bis-solid {
  border-top: 1px solid #fafafa; }

.border-t-white-bis-double {
  border-top: 1px double #fafafa; }

.border-t-white-bis-groove {
  border-top: 1px groove #fafafa; }

.border-t-white-bis-ridge {
  border-top: 1px ridge #fafafa; }

.border-t-white-bis-inset {
  border-top: 1px inset #fafafa; }

.border-t-white-bis-outset {
  border-top: 1px outset #fafafa; }

.border-t-white-bis-none {
  border-top: 1px none #fafafa; }

.border-t-white-bis-hidden {
  border-top: 1px hidden #fafafa; }

.border-r-white-bis {
  border-right: 1px solid #fafafa; }

.border-r-white-bis-dotted {
  border-right: 1px dotted #fafafa; }

.border-r-white-bis-dashed {
  border-right: 1px dashed #fafafa; }

.border-r-white-bis-solid {
  border-right: 1px solid #fafafa; }

.border-r-white-bis-double {
  border-right: 1px double #fafafa; }

.border-r-white-bis-groove {
  border-right: 1px groove #fafafa; }

.border-r-white-bis-ridge {
  border-right: 1px ridge #fafafa; }

.border-r-white-bis-inset {
  border-right: 1px inset #fafafa; }

.border-r-white-bis-outset {
  border-right: 1px outset #fafafa; }

.border-r-white-bis-none {
  border-right: 1px none #fafafa; }

.border-r-white-bis-hidden {
  border-right: 1px hidden #fafafa; }

.border-b-white-bis {
  border-bottom: 1px solid #fafafa; }

.border-b-white-bis-dotted {
  border-bottom: 1px dotted #fafafa; }

.border-b-white-bis-dashed {
  border-bottom: 1px dashed #fafafa; }

.border-b-white-bis-solid {
  border-bottom: 1px solid #fafafa; }

.border-b-white-bis-double {
  border-bottom: 1px double #fafafa; }

.border-b-white-bis-groove {
  border-bottom: 1px groove #fafafa; }

.border-b-white-bis-ridge {
  border-bottom: 1px ridge #fafafa; }

.border-b-white-bis-inset {
  border-bottom: 1px inset #fafafa; }

.border-b-white-bis-outset {
  border-bottom: 1px outset #fafafa; }

.border-b-white-bis-none {
  border-bottom: 1px none #fafafa; }

.border-b-white-bis-hidden {
  border-bottom: 1px hidden #fafafa; }

.border-l-white-bis {
  border-left: 1px solid #fafafa; }

.border-l-white-bis-dotted {
  border-left: 1px dotted #fafafa; }

.border-l-white-bis-dashed {
  border-left: 1px dashed #fafafa; }

.border-l-white-bis-solid {
  border-left: 1px solid #fafafa; }

.border-l-white-bis-double {
  border-left: 1px double #fafafa; }

.border-l-white-bis-groove {
  border-left: 1px groove #fafafa; }

.border-l-white-bis-ridge {
  border-left: 1px ridge #fafafa; }

.border-l-white-bis-inset {
  border-left: 1px inset #fafafa; }

.border-l-white-bis-outset {
  border-left: 1px outset #fafafa; }

.border-l-white-bis-none {
  border-left: 1px none #fafafa; }

.border-l-white-bis-hidden {
  border-left: 1px hidden #fafafa; }

.has-text-white {
  color: white; }

.has-background-white {
  background-color: white; }

.border-white {
  border: 1px solid white; }

.border-white-dotted {
  border: 1px dotted white; }

.border-white-dashed {
  border: 1px dashed white; }

.border-white-solid {
  border: 1px solid white; }

.border-white-double {
  border: 1px double white; }

.border-white-groove {
  border: 1px groove white; }

.border-white-ridge {
  border: 1px ridge white; }

.border-white-inset {
  border: 1px inset white; }

.border-white-outset {
  border: 1px outset white; }

.border-white-none {
  border: 1px none white; }

.border-white-hidden {
  border: 1px hidden white; }

.border-t-white {
  border-top: 1px solid white; }

.border-t-white-dotted {
  border-top: 1px dotted white; }

.border-t-white-dashed {
  border-top: 1px dashed white; }

.border-t-white-solid {
  border-top: 1px solid white; }

.border-t-white-double {
  border-top: 1px double white; }

.border-t-white-groove {
  border-top: 1px groove white; }

.border-t-white-ridge {
  border-top: 1px ridge white; }

.border-t-white-inset {
  border-top: 1px inset white; }

.border-t-white-outset {
  border-top: 1px outset white; }

.border-t-white-none {
  border-top: 1px none white; }

.border-t-white-hidden {
  border-top: 1px hidden white; }

.border-r-white {
  border-right: 1px solid white; }

.border-r-white-dotted {
  border-right: 1px dotted white; }

.border-r-white-dashed {
  border-right: 1px dashed white; }

.border-r-white-solid {
  border-right: 1px solid white; }

.border-r-white-double {
  border-right: 1px double white; }

.border-r-white-groove {
  border-right: 1px groove white; }

.border-r-white-ridge {
  border-right: 1px ridge white; }

.border-r-white-inset {
  border-right: 1px inset white; }

.border-r-white-outset {
  border-right: 1px outset white; }

.border-r-white-none {
  border-right: 1px none white; }

.border-r-white-hidden {
  border-right: 1px hidden white; }

.border-b-white {
  border-bottom: 1px solid white; }

.border-b-white-dotted {
  border-bottom: 1px dotted white; }

.border-b-white-dashed {
  border-bottom: 1px dashed white; }

.border-b-white-solid {
  border-bottom: 1px solid white; }

.border-b-white-double {
  border-bottom: 1px double white; }

.border-b-white-groove {
  border-bottom: 1px groove white; }

.border-b-white-ridge {
  border-bottom: 1px ridge white; }

.border-b-white-inset {
  border-bottom: 1px inset white; }

.border-b-white-outset {
  border-bottom: 1px outset white; }

.border-b-white-none {
  border-bottom: 1px none white; }

.border-b-white-hidden {
  border-bottom: 1px hidden white; }

.border-l-white {
  border-left: 1px solid white; }

.border-l-white-dotted {
  border-left: 1px dotted white; }

.border-l-white-dashed {
  border-left: 1px dashed white; }

.border-l-white-solid {
  border-left: 1px solid white; }

.border-l-white-double {
  border-left: 1px double white; }

.border-l-white-groove {
  border-left: 1px groove white; }

.border-l-white-ridge {
  border-left: 1px ridge white; }

.border-l-white-inset {
  border-left: 1px inset white; }

.border-l-white-outset {
  border-left: 1px outset white; }

.border-l-white-none {
  border-left: 1px none white; }

.border-l-white-hidden {
  border-left: 1px hidden white; }

.is-capitalized {
  text-transform: capitalize; }

.is-lowercase {
  text-transform: lowercase; }

.is-uppercase {
  text-transform: uppercase; }

.is-italic {
  font-style: italic; }

.is-underlined {
  text-decoration: underline; }

.has-text-weight-light {
  font-weight: 300; }

.has-text-weight-normal {
  font-weight: 400; }

.has-text-weight-semibold {
  font-weight: 700; }

.has-text-weight-bold {
  font-weight: 900; }

.is-size-1 {
  font-size: 3rem; }

.is-size-2 {
  font-size: 2.5rem; }

.is-size-3 {
  font-size: 2rem; }

.is-size-4 {
  font-size: 1.5rem; }

.is-size-5 {
  font-size: 1.25rem; }

.is-size-6 {
  font-size: 1rem; }

.is-size-6bis {
  font-size: 0.85rem; }

.is-size-7 {
  font-size: 0.75rem; }

.is-size-8 {
  font-size: 0.7rem; }

.flex-1 {
  flex: 1 1; }

.flex-2 {
  flex: 2 1; }

.flex-3 {
  flex: 3 1; }

.flex-4 {
  flex: 4 1; }

.flex-5 {
  flex: 5 1; }

.flex-6 {
  flex: 6 1; }

.flex-7 {
  flex: 7 1; }

.flex-8 {
  flex: 8 1; }

.flex-9 {
  flex: 9 1; }

.flex-10 {
  flex: 10 1; }

.flex {
  display: flex; }
  .flex.row {
    flex-direction: row; }
  .flex.row-reverse {
    flex-direction: row-reverse; }
  .flex.column {
    flex-direction: column; }
  .flex.column-reverse {
    flex-direction: column-reverse; }
  .flex.nowrap {
    flex-wrap: nowrap; }
  .flex.wrap {
    flex-wrap: wrap; }
  .flex.wrap-reverse {
    flex-wrap: wrap-reverse; }
  .flex.flex-start {
    justify-content: flex-start; }
  .flex.flex-end {
    justify-content: flex-end; }
  .flex.center {
    justify-content: center; }
  .flex.space-between {
    justify-content: space-between; }
  .flex.space-around {
    justify-content: space-around; }
  .flex.space-evenly {
    justify-content: space-evenly; }
  .flex.self-auto {
    align-self: auto; }
  .flex.self-flex-start {
    align-self: flex-start; }
  .flex.self-flex-end {
    align-self: flex-end; }
  .flex.self-center {
    align-self: center; }
  .flex.self-baseline {
    align-self: baseline; }
  .flex.self-stretch {
    align-self: stretch; }
  .flex.items-flex-start {
    align-items: flex-start; }
  .flex.items-flex-end {
    align-items: flex-end; }
  .flex.items-center {
    align-items: center; }
  .flex.items-baseline {
    align-items: baseline; }
  .flex.items-stretch {
    align-items: stretch; }
  .flex.content-flex-start {
    align-content: flex-start; }
  .flex.content-flex-end {
    align-content: flex-end; }
  .flex.content-center {
    align-content: center; }
  .flex.content-space-between {
    align-content: space-between; }
  .flex.content-space-around {
    align-content: space-around; }
  .flex.content-stretch {
    align-content: stretch; }
  .flex.grow-0 {
    flex-grow: 0; }
  .flex.shrink-0 {
    flex-shrink: 0; }
  .flex.basis-0 {
    flex-basis: 0; }
  .flex.grow-1 {
    flex-grow: 1; }
  .flex.shrink-1 {
    flex-shrink: 1; }
  .flex.basis-1 {
    flex-basis: 1; }
  .flex.grow-2 {
    flex-grow: 2; }
  .flex.shrink-2 {
    flex-shrink: 2; }
  .flex.basis-2 {
    flex-basis: 2; }
  .flex.grow-3 {
    flex-grow: 3; }
  .flex.shrink-3 {
    flex-shrink: 3; }
  .flex.basis-3 {
    flex-basis: 3; }
  .flex.grow-4 {
    flex-grow: 4; }
  .flex.shrink-4 {
    flex-shrink: 4; }
  .flex.basis-4 {
    flex-basis: 4; }
  .flex.grow-5 {
    flex-grow: 5; }
  .flex.shrink-5 {
    flex-shrink: 5; }
  .flex.basis-5 {
    flex-basis: 5; }
  .flex.grow-6 {
    flex-grow: 6; }
  .flex.shrink-6 {
    flex-shrink: 6; }
  .flex.basis-6 {
    flex-basis: 6; }
  .flex.grow-7 {
    flex-grow: 7; }
  .flex.shrink-7 {
    flex-shrink: 7; }
  .flex.basis-7 {
    flex-basis: 7; }
  .flex.grow-8 {
    flex-grow: 8; }
  .flex.shrink-8 {
    flex-shrink: 8; }
  .flex.basis-8 {
    flex-basis: 8; }
  .flex.grow-9 {
    flex-grow: 9; }
  .flex.shrink-9 {
    flex-shrink: 9; }
  .flex.basis-9 {
    flex-basis: 9; }
  .flex.grow-10 {
    flex-grow: 10; }
  .flex.shrink-10 {
    flex-shrink: 10; }
  .flex.basis-10 {
    flex-basis: 10; }

.flex-auto {
  flex: 0 0 auto; }

@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px); }
  60% {
    opacity: 1;
    transform: translateX(-30px); }
  80% {
    transform: translateX(10px); }
  100% {
    transform: translateX(0); } }

@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px); }
  60% {
    opacity: 1;
    transform: translateX(-30px); }
  80% {
    transform: translateX(10px); }
  100% {
    transform: translateX(0); } }

@-webkit-keyframes slideIn {
  0% {
    width: 0%; }
  100% {
    width: 100%; } }

@keyframes slideIn {
  0% {
    width: 0%; }
  100% {
    width: 100%; } }

@-webkit-keyframes slideOut {
  0% {
    width: 100%; }
  100% {
    width: 0%; } }

@keyframes slideOut {
  0% {
    width: 100%; }
  100% {
    width: 0%; } }

@-webkit-keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes growth {
  0% {
    width: 48px;
    height: 48px; }
  100% {
    width: 320px;
    height: 568px; } }

@keyframes growth {
  0% {
    width: 48px;
    height: 48px; }
  100% {
    width: 320px;
    height: 568px; } }

@-webkit-keyframes slideDown {
  0% {
    max-height: 0px; }
  100% {
    max-height: 100vh; } }

@keyframes slideDown {
  0% {
    max-height: 0px; }
  100% {
    max-height: 100vh; } }

.animation-bounceInRight {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-delay: initial;
  animation-delay: 0s;
  animation-delay: initial; }

.animation-slideDown {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: slideDown;
  animation-name: slideDown;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-delay: initial;
  animation-delay: 0s;
  animation-delay: initial; }

.animation-slideDownFast {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: slideDown;
  animation-name: slideDown;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-delay: initial;
  animation-delay: 0s;
  animation-delay: initial; }

.animation-slideUp {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: slideDown;
  animation-name: slideDown;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-delay: initial;
  animation-delay: 0s;
  animation-delay: initial;
  overflow: hidden; }

.animation-slideIn {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-delay: initial;
  animation-delay: 0s;
  animation-delay: initial; }

.animation-sliteOut {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: slideOut;
  animation-name: slideOut;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-delay: initial;
  animation-delay: 0s;
  animation-delay: initial; }

.animation-spin {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: spin;
  animation-name: spin;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-delay: initial;
  animation-delay: 0s;
  animation-delay: initial; }

.animation-fadeIn {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
  -webkit-animation-delay: initial;
  animation-delay: 0s;
  animation-delay: initial; }

.animation-fadeInFast {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-delay: initial;
  animation-delay: 0s;
  animation-delay: initial; }

.animation-growth {
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: growth;
  animation-name: growth;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
  -webkit-animation-delay: initial;
  animation-delay: 0s;
  animation-delay: initial; }

.turn-90 {
  transition: .5s ease;
  border: none; }
  .turn-90::after {
    transform: scale(0.8);
    transition: all 0.3s ease; }
  .turn-90:hover {
    transform: rotate(90deg);
    transition: all 0.3s ease; }

.turn-360 {
  transition: .8s ease;
  border: none; }
  .turn-360::after {
    transform: scale(0.8);
    transition: all 0.5s ease; }
  .turn-360:hover {
    transform: rotate(360deg);
    transition: all 0.5s ease; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.static {
  position: static; }

.fixed {
  position: fixed; }

.sticky {
  position: -webkit-sticky;
  position: sticky; }

.top {
  top: 0px; }

.right {
  right: 0px; }

.bottom {
  bottom: 0px; }

.left {
  left: 0px; }

.overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7); }

.overlay1 {
  position: absolute;
  z-index: 1; }

.default {
  cursor: default; }

.help {
  cursor: help; }

.pointer {
  cursor: pointer; }

.move {
  cursor: move; }

.crosshair {
  cursor: crosshair; }

.copy {
  cursor: copy; }

.cursor-none {
  pointer-events: none; }

.align-top {
  vertical-align: top; }

.valign-center {
  vertical-align: top; }

.is-8x8 {
  width: 8px;
  height: 8px; }

.is-24x24 {
  width: 24px;
  height: 24px; }

.is-48x48 {
  width: 48px;
  height: 48px; }

.is-80x80 {
  width: 80px;
  height: 80px; }

.w-1 {
  width: 1.3em; }
  @media only screen and (max-width: 768px) {
    .w-1 {
      width: 100%; }
      .w-1.no-resize {
        width: 1.3em; } }

.h-1 {
  height: 1.3em; }

.mw-1 {
  max-width: 1.3em; }

.mh-1 {
  max-height: 1.3em; }

.w-2 {
  width: 8.33em; }
  @media only screen and (max-width: 768px) {
    .w-2 {
      width: 100%; }
      .w-2.no-resize {
        width: 8.33em; } }

.h-2 {
  height: 8.33em; }

.mw-2 {
  max-width: 8.33em; }

.mh-2 {
  max-height: 8.33em; }

.w-3 {
  width: 16.66em; }
  @media only screen and (max-width: 768px) {
    .w-3 {
      width: 100%; }
      .w-3.no-resize {
        width: 16.66em; } }

.h-3 {
  height: 16.66em; }

.mw-3 {
  max-width: 16.66em; }

.mh-3 {
  max-height: 16.66em; }

.w-4 {
  width: 25em; }
  @media only screen and (max-width: 768px) {
    .w-4 {
      width: 100%; }
      .w-4.no-resize {
        width: 25em; } }

.h-4 {
  height: 25em; }

.mw-4 {
  max-width: 25em; }

.mh-4 {
  max-height: 25em; }

.w-5 {
  width: 33.33em; }
  @media only screen and (max-width: 768px) {
    .w-5 {
      width: 100%; }
      .w-5.no-resize {
        width: 33.33em; } }

.h-5 {
  height: 33.33em; }

.mw-5 {
  max-width: 33.33em; }

.mh-5 {
  max-height: 33.33em; }

.w-6 {
  width: 41.66em; }
  @media only screen and (max-width: 768px) {
    .w-6 {
      width: 100%; }
      .w-6.no-resize {
        width: 41.66em; } }

.h-6 {
  height: 41.66em; }

.mw-6 {
  max-width: 41.66em; }

.mh-6 {
  max-height: 41.66em; }

.w-7 {
  width: 50em; }
  @media only screen and (max-width: 768px) {
    .w-7 {
      width: 100%; }
      .w-7.no-resize {
        width: 50em; } }

.h-7 {
  height: 50em; }

.mw-7 {
  max-width: 50em; }

.mh-7 {
  max-height: 50em; }

.w-8 {
  width: 58.33em; }
  @media only screen and (max-width: 768px) {
    .w-8 {
      width: 100%; }
      .w-8.no-resize {
        width: 58.33em; } }

.h-8 {
  height: 58.33em; }

.mw-8 {
  max-width: 58.33em; }

.mh-8 {
  max-height: 58.33em; }

.w-9 {
  width: 66.66em; }
  @media only screen and (max-width: 768px) {
    .w-9 {
      width: 100%; }
      .w-9.no-resize {
        width: 66.66em; } }

.h-9 {
  height: 66.66em; }

.mw-9 {
  max-width: 66.66em; }

.mh-9 {
  max-height: 66.66em; }

.w-10 {
  width: 75em; }
  @media only screen and (max-width: 768px) {
    .w-10 {
      width: 100%; }
      .w-10.no-resize {
        width: 75em; } }

.h-10 {
  height: 75em; }

.mw-10 {
  max-width: 75em; }

.mh-10 {
  max-height: 75em; }

.w-11 {
  width: 83.33em; }
  @media only screen and (max-width: 768px) {
    .w-11 {
      width: 100%; }
      .w-11.no-resize {
        width: 83.33em; } }

.h-11 {
  height: 83.33em; }

.mw-11 {
  max-width: 83.33em; }

.mh-11 {
  max-height: 83.33em; }

.w-12 {
  width: 91.66em; }
  @media only screen and (max-width: 768px) {
    .w-12 {
      width: 100%; }
      .w-12.no-resize {
        width: 91.66em; } }

.h-12 {
  height: 91.66em; }

.mw-12 {
  max-width: 91.66em; }

.mh-12 {
  max-height: 91.66em; }

.w-13 {
  width: 100em; }
  @media only screen and (max-width: 768px) {
    .w-13 {
      width: 100%; }
      .w-13.no-resize {
        width: 100em; } }

.h-13 {
  height: 100em; }

.mw-13 {
  max-width: 100em; }

.mh-13 {
  max-height: 100em; }

.w-5p {
  width: 5%; }

.h-5p {
  height: 5%; }

.mh-5p {
  max-height: 5%; }

.mw-5p {
  max-width: 5%; }

.w-10p {
  width: 10%; }

.h-10p {
  height: 10%; }

.mh-10p {
  max-height: 10%; }

.mw-10p {
  max-width: 10%; }

.w-15p {
  width: 15%; }

.h-15p {
  height: 15%; }

.mh-15p {
  max-height: 15%; }

.mw-15p {
  max-width: 15%; }

.w-20p {
  width: 20%; }

.h-20p {
  height: 20%; }

.mh-20p {
  max-height: 20%; }

.mw-20p {
  max-width: 20%; }

.w-24p {
  width: 24%; }

.h-24p {
  height: 24%; }

.mh-24p {
  max-height: 24%; }

.mw-24p {
  max-width: 24%; }

.w-25p {
  width: 25%; }

.h-25p {
  height: 25%; }

.mh-25p {
  max-height: 25%; }

.mw-25p {
  max-width: 25%; }

.w-30p {
  width: 30%; }

.h-30p {
  height: 30%; }

.mh-30p {
  max-height: 30%; }

.mw-30p {
  max-width: 30%; }

.w-40p {
  width: 40%; }

.h-40p {
  height: 40%; }

.mh-40p {
  max-height: 40%; }

.mw-40p {
  max-width: 40%; }

.w-50p {
  width: 50%; }

.h-50p {
  height: 50%; }

.mh-50p {
  max-height: 50%; }

.mw-50p {
  max-width: 50%; }

.w-55p {
  width: 55%; }

.h-55p {
  height: 55%; }

.mh-55p {
  max-height: 55%; }

.mw-55p {
  max-width: 55%; }

.w-60p {
  width: 60%; }

.h-60p {
  height: 60%; }

.mh-60p {
  max-height: 60%; }

.mw-60p {
  max-width: 60%; }

.w-70p {
  width: 70%; }

.h-70p {
  height: 70%; }

.mh-70p {
  max-height: 70%; }

.mw-70p {
  max-width: 70%; }

.w-75p {
  width: 75%; }

.h-75p {
  height: 75%; }

.mh-75p {
  max-height: 75%; }

.mw-75p {
  max-width: 75%; }

.w-80p {
  width: 80%; }

.h-80p {
  height: 80%; }

.mh-80p {
  max-height: 80%; }

.mw-80p {
  max-width: 80%; }

.w-85p {
  width: 85%; }

.h-85p {
  height: 85%; }

.mh-85p {
  max-height: 85%; }

.mw-85p {
  max-width: 85%; }

.w-90p {
  width: 90%; }

.h-90p {
  height: 90%; }

.mh-90p {
  max-height: 90%; }

.mw-90p {
  max-width: 90%; }

.w-100p {
  width: 100%;
  width: -moz-available;
  /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  width: stretch; }

.h-100p {
  height: 100%;
  height: -moz-available;
  /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  height: stretch; }

.mh-100p {
  max-height: 100%; }

.mw-100p {
  max-width: 100%; }

.h-100 {
  height: 100%; }

.h-fill {
  height: 100%;
  height: -moz-available;
  /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  height: stretch; }

.w-fill {
  width: 100%;
  width: -moz-available;
  /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  width: stretch; }

.h-inherit {
  height: inherit; }

.w-100p {
  width: 100%; }

.maxWidth-100 {
  max-width: 100%; }

.w-20 {
  width: 20%; }

.grid-fondations {
  display: grid;
  grid-template-areas: ". header" "sidebar main" ". main";
  width: 100%;
  height: 85%;
  grid-template-columns: fit-content(100%) 1fr;
  grid-template-rows: fit-content(100%) 1fr; }

.grid-sidebar {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: sidebar;
  height: 95%;
  border-radius: 5px 0px 0px 5px; }

.grid-header {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: header;
  border-radius: 5px 5px 0px 0px;
  box-shadow: 2px 2px 6px #363636, -3px 2px 4px #2b2b2b91; }

.grid-main {
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  -ms-grid-column: 2;
  grid-area: main;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 2px 2px 6px #363636, -3px 2px 4px #2b2b2b91;
  max-height: -webkit-fill-available;
  max-height: -moz-available;
  max-height: stretch;
  overflow: scroll; }

input:focus::-webkit-input-placeholder {
  color: transparent; }

input:focus:-moz-placeholder {
  color: transparent; }

/* FF 4-18 */
input:focus::-moz-placeholder {
  color: transparent; }

/* FF 19+ */
input:focus:-ms-input-placeholder {
  color: transparent; }

/* IE 10+ */
.rad-5 {
  border-radius: 5px; }

.shadow {
  box-shadow: -5px 5px 15px -5px rgba(0, 0, 0, 0.75); }

.tabactive > :first-child {
  border-bottom: 1px solid white; }

.overflow-hidden {
  overflow: hidden; }

.lstImage > .askmona-ui-image > .askmona-ui-hover > .askmona-ui-field {
  margin-right: 110px; }

.funcSentence > .askmona-ui-input-contain {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }
  .funcSentence > .askmona-ui-input-contain > .askmona-ui-input {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }

.has-content-centered {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.none {
  display: none; }

a {
  text-decoration: none; }

.modal {
  z-index: 3;
  height: inherit;
  width: 100%;
  background: rgba(15, 15, 15, 0.7); }

.askmona-ui-input {
  width: -webkit-fill-available; }

.break-word {
  word-break: break-all;
  word-break: break-word; }

details {
  position: relative;
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  overflow-x: auto;
  white-space: pre-wrap;
  border-radius: 0.25rem;
  background-color: rgba(206, 17, 38, 0.05);
  max-height: 250px; }
  details > summary {
    max-width: 500px;
    background-color: rgba(206, 17, 38, 0.1);
    padding: 0.5em;
    color: red; }

.overflow-auto {
  overflow: auto; }

.overflow-hidden {
  overflow: hidden; }

.overflow-scroll {
  overflow: scroll; }

.overflow-visible {
  overflow: visible; }

.text-ellipsis--alone {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s linear;
  white-space: nowrap; }

.text-ellipsis:focus,
.text-ellipsis:hover {
  color: transparent; }

.-text-ellipsis:focus:after,
.text-ellipsis:hover:after {
  content: attr(data-text);
  overflow: visible;
  text-overflow: inherit;
  background: #fff;
  position: absolute;
  left: auto;
  top: auto;
  width: auto;
  max-width: 20rem;
  border: 1px solid #eaebec;
  padding: 0 0.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  white-space: normal;
  word-wrap: break-word;
  display: block;
  color: black;
  margin-top: -1.25rem; }

.rad-5px {
  border-radius: 5px; }

.rad-t-l-5px {
  border-top-left-radius: 5px; }

.rad-t-r-5px {
  border-top-right-radius: 5px; }

.rad-b-l-5px {
  border-bottom-left-radius: 5px; }

.rad-b-r-5px {
  border-bottom-right-radius: 5px; }

.rad-10px {
  border-radius: 10px; }

.rad-t-l-10px {
  border-top-left-radius: 10px; }

.rad-t-r-10px {
  border-top-right-radius: 10px; }

.rad-b-l-10px {
  border-bottom-left-radius: 10px; }

.rad-b-r-10px {
  border-bottom-right-radius: 10px; }

.rad-15px {
  border-radius: 15px; }

.rad-t-l-15px {
  border-top-left-radius: 15px; }

.rad-t-r-15px {
  border-top-right-radius: 15px; }

.rad-b-l-15px {
  border-bottom-left-radius: 15px; }

.rad-b-r-15px {
  border-bottom-right-radius: 15px; }

.rad-25px {
  border-radius: 25px; }

.rad-t-l-25px {
  border-top-left-radius: 25px; }

.rad-t-r-25px {
  border-top-right-radius: 25px; }

.rad-b-l-25px {
  border-bottom-left-radius: 25px; }

.rad-b-r-25px {
  border-bottom-right-radius: 25px; }

.rad-x {
  border-radius: 50%; }

.m-none {
  margin: 0; }

.p-none {
  padding: 0; }

.m-t-none {
  margin-top: 0; }

.p-t-none {
  padding-top: 0; }

.m-r-none {
  margin-right: 0; }

.p-r-none {
  padding-right: 0; }

.m-b-none {
  margin-bottom: 0; }

.p-b-none {
  padding-bottom: 0; }

.m-l-none {
  margin-left: 0; }

.p-l-none {
  padding-left: 0; }

.m-xxs {
  margin: 0.125rem; }

.p-xxs {
  padding: 0.125rem; }

.m-t-xxs {
  margin-top: 0.125rem; }

.p-t-xxs {
  padding-top: 0.125rem; }

.m-r-xxs {
  margin-right: 0.125rem; }

.p-r-xxs {
  padding-right: 0.125rem; }

.m-b-xxs {
  margin-bottom: 0.125rem; }

.p-b-xxs {
  padding-bottom: 0.125rem; }

.m-l-xxs {
  margin-left: 0.125rem; }

.p-l-xxs {
  padding-left: 0.125rem; }

.m-xs {
  margin: 0.25rem; }

.p-xs {
  padding: 0.25rem; }

.m-t-xs {
  margin-top: 0.25rem; }

.p-t-xs {
  padding-top: 0.25rem; }

.m-r-xs {
  margin-right: 0.25rem; }

.p-r-xs {
  padding-right: 0.25rem; }

.m-b-xs {
  margin-bottom: 0.25rem; }

.p-b-xs {
  padding-bottom: 0.25rem; }

.m-l-xs {
  margin-left: 0.25rem; }

.p-l-xs {
  padding-left: 0.25rem; }

.m-sm {
  margin: 0.6rem; }

.p-sm {
  padding: 0.6rem; }

.m-t-sm {
  margin-top: 0.6rem; }

.p-t-sm {
  padding-top: 0.6rem; }

.m-r-sm {
  margin-right: 0.6rem; }

.p-r-sm {
  padding-right: 0.6rem; }

.m-b-sm {
  margin-bottom: 0.6rem; }

.p-b-sm {
  padding-bottom: 0.6rem; }

.m-l-sm {
  margin-left: 0.6rem; }

.p-l-sm {
  padding-left: 0.6rem; }

.m-md {
  margin: 1rem; }

.p-md {
  padding: 1rem; }

.m-t-md {
  margin-top: 1rem; }

.p-t-md {
  padding-top: 1rem; }

.m-r-md {
  margin-right: 1rem; }

.p-r-md {
  padding-right: 1rem; }

.m-b-md {
  margin-bottom: 1rem; }

.p-b-md {
  padding-bottom: 1rem; }

.m-l-md {
  margin-left: 1rem; }

.p-l-md {
  padding-left: 1rem; }

.m-lg {
  margin: 2rem; }

.p-lg {
  padding: 2rem; }

.m-t-lg {
  margin-top: 2rem; }

.p-t-lg {
  padding-top: 2rem; }

.m-r-lg {
  margin-right: 2rem; }

.p-r-lg {
  padding-right: 2rem; }

.m-b-lg {
  margin-bottom: 2rem; }

.p-b-lg {
  padding-bottom: 2rem; }

.m-l-lg {
  margin-left: 2rem; }

.p-l-lg {
  padding-left: 2rem; }

.m-xl {
  margin: 4rem; }

.p-xl {
  padding: 4rem; }

.m-t-xl {
  margin-top: 4rem; }

.p-t-xl {
  padding-top: 4rem; }

.m-r-xl {
  margin-right: 4rem; }

.p-r-xl {
  padding-right: 4rem; }

.m-b-xl {
  margin-bottom: 4rem; }

.p-b-xl {
  padding-bottom: 4rem; }

.m-l-xl {
  margin-left: 4rem; }

.p-l-xl {
  padding-left: 4rem; }

.m-xxl {
  margin: 8rem; }

.p-xxl {
  padding: 8rem; }

.m-t-xxl {
  margin-top: 8rem; }

.p-t-xxl {
  padding-top: 8rem; }

.m-r-xxl {
  margin-right: 8rem; }

.p-r-xxl {
  padding-right: 8rem; }

.m-b-xxl {
  margin-bottom: 8rem; }

.p-b-xxl {
  padding-bottom: 8rem; }

.m-l-xxl {
  margin-left: 8rem; }

.p-l-xxl {
  padding-left: 8rem; }

.has-text-primary {
  color: #2d2f7b; }

.has-background-primary {
  background-color: #2d2f7b; }

.border-primary {
  border: 1px solid #2d2f7b; }

.border-m-primary:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #2d2f7b;
  transform: translateY(-50%); }

.border-primary-dotted {
  border: 1px dotted #2d2f7b; }

.border-primary-dashed {
  border: 1px dashed #2d2f7b; }

.border-primary-solid {
  border: 1px solid #2d2f7b; }

.border-primary-double {
  border: 1px double #2d2f7b; }

.border-primary-groove {
  border: 1px groove #2d2f7b; }

.border-primary-ridge {
  border: 1px ridge #2d2f7b; }

.border-primary-inset {
  border: 1px inset #2d2f7b; }

.border-primary-outset {
  border: 1px outset #2d2f7b; }

.border-primary-none {
  border: 1px none #2d2f7b; }

.border-primary-hidden {
  border: 1px hidden #2d2f7b; }

.border-t-primary {
  border-top: 1px solid #2d2f7b; }

.border-t-primary-dotted {
  border-top: 1px dotted #2d2f7b; }

.border-t-primary-dashed {
  border-top: 1px dashed #2d2f7b; }

.border-t-primary-solid {
  border-top: 1px solid #2d2f7b; }

.border-t-primary-double {
  border-top: 1px double #2d2f7b; }

.border-t-primary-groove {
  border-top: 1px groove #2d2f7b; }

.border-t-primary-ridge {
  border-top: 1px ridge #2d2f7b; }

.border-t-primary-inset {
  border-top: 1px inset #2d2f7b; }

.border-t-primary-outset {
  border-top: 1px outset #2d2f7b; }

.border-t-primary-none {
  border-top: 1px none #2d2f7b; }

.border-t-primary-hidden {
  border-top: 1px hidden #2d2f7b; }

.border-r-primary {
  border-right: 1px solid #2d2f7b; }

.border-r-primary-dotted {
  border-right: 1px dotted #2d2f7b; }

.border-r-primary-dashed {
  border-right: 1px dashed #2d2f7b; }

.border-r-primary-solid {
  border-right: 1px solid #2d2f7b; }

.border-r-primary-double {
  border-right: 1px double #2d2f7b; }

.border-r-primary-groove {
  border-right: 1px groove #2d2f7b; }

.border-r-primary-ridge {
  border-right: 1px ridge #2d2f7b; }

.border-r-primary-inset {
  border-right: 1px inset #2d2f7b; }

.border-r-primary-outset {
  border-right: 1px outset #2d2f7b; }

.border-r-primary-none {
  border-right: 1px none #2d2f7b; }

.border-r-primary-hidden {
  border-right: 1px hidden #2d2f7b; }

.border-b-primary {
  border-bottom: 1px solid #2d2f7b; }

.border-b-primary-dotted {
  border-bottom: 1px dotted #2d2f7b; }

.border-b-primary-dashed {
  border-bottom: 1px dashed #2d2f7b; }

.border-b-primary-solid {
  border-bottom: 1px solid #2d2f7b; }

.border-b-primary-double {
  border-bottom: 1px double #2d2f7b; }

.border-b-primary-groove {
  border-bottom: 1px groove #2d2f7b; }

.border-b-primary-ridge {
  border-bottom: 1px ridge #2d2f7b; }

.border-b-primary-inset {
  border-bottom: 1px inset #2d2f7b; }

.border-b-primary-outset {
  border-bottom: 1px outset #2d2f7b; }

.border-b-primary-none {
  border-bottom: 1px none #2d2f7b; }

.border-b-primary-hidden {
  border-bottom: 1px hidden #2d2f7b; }

.border-l-primary {
  border-left: 1px solid #2d2f7b; }

.border-l-primary-dotted {
  border-left: 1px dotted #2d2f7b; }

.border-l-primary-dashed {
  border-left: 1px dashed #2d2f7b; }

.border-l-primary-solid {
  border-left: 1px solid #2d2f7b; }

.border-l-primary-double {
  border-left: 1px double #2d2f7b; }

.border-l-primary-groove {
  border-left: 1px groove #2d2f7b; }

.border-l-primary-ridge {
  border-left: 1px ridge #2d2f7b; }

.border-l-primary-inset {
  border-left: 1px inset #2d2f7b; }

.border-l-primary-outset {
  border-left: 1px outset #2d2f7b; }

.border-l-primary-none {
  border-left: 1px none #2d2f7b; }

.border-l-primary-hidden {
  border-left: 1px hidden #2d2f7b; }

.has-text-primary-bis {
  color: #4d4db3; }

.has-background-primary-bis {
  background-color: #4d4db3; }

.border-primary-bis {
  border: 1px solid #4d4db3; }

.border-m-primary-bis:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #4d4db3;
  transform: translateY(-50%); }

.border-primary-bis-dotted {
  border: 1px dotted #4d4db3; }

.border-primary-bis-dashed {
  border: 1px dashed #4d4db3; }

.border-primary-bis-solid {
  border: 1px solid #4d4db3; }

.border-primary-bis-double {
  border: 1px double #4d4db3; }

.border-primary-bis-groove {
  border: 1px groove #4d4db3; }

.border-primary-bis-ridge {
  border: 1px ridge #4d4db3; }

.border-primary-bis-inset {
  border: 1px inset #4d4db3; }

.border-primary-bis-outset {
  border: 1px outset #4d4db3; }

.border-primary-bis-none {
  border: 1px none #4d4db3; }

.border-primary-bis-hidden {
  border: 1px hidden #4d4db3; }

.border-t-primary-bis {
  border-top: 1px solid #4d4db3; }

.border-t-primary-bis-dotted {
  border-top: 1px dotted #4d4db3; }

.border-t-primary-bis-dashed {
  border-top: 1px dashed #4d4db3; }

.border-t-primary-bis-solid {
  border-top: 1px solid #4d4db3; }

.border-t-primary-bis-double {
  border-top: 1px double #4d4db3; }

.border-t-primary-bis-groove {
  border-top: 1px groove #4d4db3; }

.border-t-primary-bis-ridge {
  border-top: 1px ridge #4d4db3; }

.border-t-primary-bis-inset {
  border-top: 1px inset #4d4db3; }

.border-t-primary-bis-outset {
  border-top: 1px outset #4d4db3; }

.border-t-primary-bis-none {
  border-top: 1px none #4d4db3; }

.border-t-primary-bis-hidden {
  border-top: 1px hidden #4d4db3; }

.border-r-primary-bis {
  border-right: 1px solid #4d4db3; }

.border-r-primary-bis-dotted {
  border-right: 1px dotted #4d4db3; }

.border-r-primary-bis-dashed {
  border-right: 1px dashed #4d4db3; }

.border-r-primary-bis-solid {
  border-right: 1px solid #4d4db3; }

.border-r-primary-bis-double {
  border-right: 1px double #4d4db3; }

.border-r-primary-bis-groove {
  border-right: 1px groove #4d4db3; }

.border-r-primary-bis-ridge {
  border-right: 1px ridge #4d4db3; }

.border-r-primary-bis-inset {
  border-right: 1px inset #4d4db3; }

.border-r-primary-bis-outset {
  border-right: 1px outset #4d4db3; }

.border-r-primary-bis-none {
  border-right: 1px none #4d4db3; }

.border-r-primary-bis-hidden {
  border-right: 1px hidden #4d4db3; }

.border-b-primary-bis {
  border-bottom: 1px solid #4d4db3; }

.border-b-primary-bis-dotted {
  border-bottom: 1px dotted #4d4db3; }

.border-b-primary-bis-dashed {
  border-bottom: 1px dashed #4d4db3; }

.border-b-primary-bis-solid {
  border-bottom: 1px solid #4d4db3; }

.border-b-primary-bis-double {
  border-bottom: 1px double #4d4db3; }

.border-b-primary-bis-groove {
  border-bottom: 1px groove #4d4db3; }

.border-b-primary-bis-ridge {
  border-bottom: 1px ridge #4d4db3; }

.border-b-primary-bis-inset {
  border-bottom: 1px inset #4d4db3; }

.border-b-primary-bis-outset {
  border-bottom: 1px outset #4d4db3; }

.border-b-primary-bis-none {
  border-bottom: 1px none #4d4db3; }

.border-b-primary-bis-hidden {
  border-bottom: 1px hidden #4d4db3; }

.border-l-primary-bis {
  border-left: 1px solid #4d4db3; }

.border-l-primary-bis-dotted {
  border-left: 1px dotted #4d4db3; }

.border-l-primary-bis-dashed {
  border-left: 1px dashed #4d4db3; }

.border-l-primary-bis-solid {
  border-left: 1px solid #4d4db3; }

.border-l-primary-bis-double {
  border-left: 1px double #4d4db3; }

.border-l-primary-bis-groove {
  border-left: 1px groove #4d4db3; }

.border-l-primary-bis-ridge {
  border-left: 1px ridge #4d4db3; }

.border-l-primary-bis-inset {
  border-left: 1px inset #4d4db3; }

.border-l-primary-bis-outset {
  border-left: 1px outset #4d4db3; }

.border-l-primary-bis-none {
  border-left: 1px none #4d4db3; }

.border-l-primary-bis-hidden {
  border-left: 1px hidden #4d4db3; }

.has-text-primary-ter {
  color: #7a7ab8; }

.has-background-primary-ter {
  background-color: #7a7ab8; }

.border-primary-ter {
  border: 1px solid #7a7ab8; }

.border-m-primary-ter:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #7a7ab8;
  transform: translateY(-50%); }

.border-primary-ter-dotted {
  border: 1px dotted #7a7ab8; }

.border-primary-ter-dashed {
  border: 1px dashed #7a7ab8; }

.border-primary-ter-solid {
  border: 1px solid #7a7ab8; }

.border-primary-ter-double {
  border: 1px double #7a7ab8; }

.border-primary-ter-groove {
  border: 1px groove #7a7ab8; }

.border-primary-ter-ridge {
  border: 1px ridge #7a7ab8; }

.border-primary-ter-inset {
  border: 1px inset #7a7ab8; }

.border-primary-ter-outset {
  border: 1px outset #7a7ab8; }

.border-primary-ter-none {
  border: 1px none #7a7ab8; }

.border-primary-ter-hidden {
  border: 1px hidden #7a7ab8; }

.border-t-primary-ter {
  border-top: 1px solid #7a7ab8; }

.border-t-primary-ter-dotted {
  border-top: 1px dotted #7a7ab8; }

.border-t-primary-ter-dashed {
  border-top: 1px dashed #7a7ab8; }

.border-t-primary-ter-solid {
  border-top: 1px solid #7a7ab8; }

.border-t-primary-ter-double {
  border-top: 1px double #7a7ab8; }

.border-t-primary-ter-groove {
  border-top: 1px groove #7a7ab8; }

.border-t-primary-ter-ridge {
  border-top: 1px ridge #7a7ab8; }

.border-t-primary-ter-inset {
  border-top: 1px inset #7a7ab8; }

.border-t-primary-ter-outset {
  border-top: 1px outset #7a7ab8; }

.border-t-primary-ter-none {
  border-top: 1px none #7a7ab8; }

.border-t-primary-ter-hidden {
  border-top: 1px hidden #7a7ab8; }

.border-r-primary-ter {
  border-right: 1px solid #7a7ab8; }

.border-r-primary-ter-dotted {
  border-right: 1px dotted #7a7ab8; }

.border-r-primary-ter-dashed {
  border-right: 1px dashed #7a7ab8; }

.border-r-primary-ter-solid {
  border-right: 1px solid #7a7ab8; }

.border-r-primary-ter-double {
  border-right: 1px double #7a7ab8; }

.border-r-primary-ter-groove {
  border-right: 1px groove #7a7ab8; }

.border-r-primary-ter-ridge {
  border-right: 1px ridge #7a7ab8; }

.border-r-primary-ter-inset {
  border-right: 1px inset #7a7ab8; }

.border-r-primary-ter-outset {
  border-right: 1px outset #7a7ab8; }

.border-r-primary-ter-none {
  border-right: 1px none #7a7ab8; }

.border-r-primary-ter-hidden {
  border-right: 1px hidden #7a7ab8; }

.border-b-primary-ter {
  border-bottom: 1px solid #7a7ab8; }

.border-b-primary-ter-dotted {
  border-bottom: 1px dotted #7a7ab8; }

.border-b-primary-ter-dashed {
  border-bottom: 1px dashed #7a7ab8; }

.border-b-primary-ter-solid {
  border-bottom: 1px solid #7a7ab8; }

.border-b-primary-ter-double {
  border-bottom: 1px double #7a7ab8; }

.border-b-primary-ter-groove {
  border-bottom: 1px groove #7a7ab8; }

.border-b-primary-ter-ridge {
  border-bottom: 1px ridge #7a7ab8; }

.border-b-primary-ter-inset {
  border-bottom: 1px inset #7a7ab8; }

.border-b-primary-ter-outset {
  border-bottom: 1px outset #7a7ab8; }

.border-b-primary-ter-none {
  border-bottom: 1px none #7a7ab8; }

.border-b-primary-ter-hidden {
  border-bottom: 1px hidden #7a7ab8; }

.border-l-primary-ter {
  border-left: 1px solid #7a7ab8; }

.border-l-primary-ter-dotted {
  border-left: 1px dotted #7a7ab8; }

.border-l-primary-ter-dashed {
  border-left: 1px dashed #7a7ab8; }

.border-l-primary-ter-solid {
  border-left: 1px solid #7a7ab8; }

.border-l-primary-ter-double {
  border-left: 1px double #7a7ab8; }

.border-l-primary-ter-groove {
  border-left: 1px groove #7a7ab8; }

.border-l-primary-ter-ridge {
  border-left: 1px ridge #7a7ab8; }

.border-l-primary-ter-inset {
  border-left: 1px inset #7a7ab8; }

.border-l-primary-ter-outset {
  border-left: 1px outset #7a7ab8; }

.border-l-primary-ter-none {
  border-left: 1px none #7a7ab8; }

.border-l-primary-ter-hidden {
  border-left: 1px hidden #7a7ab8; }

.has-text-primary-qua {
  color: #b9b9e9; }

.has-background-primary-qua {
  background-color: #b9b9e9; }

.border-primary-qua {
  border: 1px solid #b9b9e9; }

.border-m-primary-qua:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b9b9e9;
  transform: translateY(-50%); }

.border-primary-qua-dotted {
  border: 1px dotted #b9b9e9; }

.border-primary-qua-dashed {
  border: 1px dashed #b9b9e9; }

.border-primary-qua-solid {
  border: 1px solid #b9b9e9; }

.border-primary-qua-double {
  border: 1px double #b9b9e9; }

.border-primary-qua-groove {
  border: 1px groove #b9b9e9; }

.border-primary-qua-ridge {
  border: 1px ridge #b9b9e9; }

.border-primary-qua-inset {
  border: 1px inset #b9b9e9; }

.border-primary-qua-outset {
  border: 1px outset #b9b9e9; }

.border-primary-qua-none {
  border: 1px none #b9b9e9; }

.border-primary-qua-hidden {
  border: 1px hidden #b9b9e9; }

.border-t-primary-qua {
  border-top: 1px solid #b9b9e9; }

.border-t-primary-qua-dotted {
  border-top: 1px dotted #b9b9e9; }

.border-t-primary-qua-dashed {
  border-top: 1px dashed #b9b9e9; }

.border-t-primary-qua-solid {
  border-top: 1px solid #b9b9e9; }

.border-t-primary-qua-double {
  border-top: 1px double #b9b9e9; }

.border-t-primary-qua-groove {
  border-top: 1px groove #b9b9e9; }

.border-t-primary-qua-ridge {
  border-top: 1px ridge #b9b9e9; }

.border-t-primary-qua-inset {
  border-top: 1px inset #b9b9e9; }

.border-t-primary-qua-outset {
  border-top: 1px outset #b9b9e9; }

.border-t-primary-qua-none {
  border-top: 1px none #b9b9e9; }

.border-t-primary-qua-hidden {
  border-top: 1px hidden #b9b9e9; }

.border-r-primary-qua {
  border-right: 1px solid #b9b9e9; }

.border-r-primary-qua-dotted {
  border-right: 1px dotted #b9b9e9; }

.border-r-primary-qua-dashed {
  border-right: 1px dashed #b9b9e9; }

.border-r-primary-qua-solid {
  border-right: 1px solid #b9b9e9; }

.border-r-primary-qua-double {
  border-right: 1px double #b9b9e9; }

.border-r-primary-qua-groove {
  border-right: 1px groove #b9b9e9; }

.border-r-primary-qua-ridge {
  border-right: 1px ridge #b9b9e9; }

.border-r-primary-qua-inset {
  border-right: 1px inset #b9b9e9; }

.border-r-primary-qua-outset {
  border-right: 1px outset #b9b9e9; }

.border-r-primary-qua-none {
  border-right: 1px none #b9b9e9; }

.border-r-primary-qua-hidden {
  border-right: 1px hidden #b9b9e9; }

.border-b-primary-qua {
  border-bottom: 1px solid #b9b9e9; }

.border-b-primary-qua-dotted {
  border-bottom: 1px dotted #b9b9e9; }

.border-b-primary-qua-dashed {
  border-bottom: 1px dashed #b9b9e9; }

.border-b-primary-qua-solid {
  border-bottom: 1px solid #b9b9e9; }

.border-b-primary-qua-double {
  border-bottom: 1px double #b9b9e9; }

.border-b-primary-qua-groove {
  border-bottom: 1px groove #b9b9e9; }

.border-b-primary-qua-ridge {
  border-bottom: 1px ridge #b9b9e9; }

.border-b-primary-qua-inset {
  border-bottom: 1px inset #b9b9e9; }

.border-b-primary-qua-outset {
  border-bottom: 1px outset #b9b9e9; }

.border-b-primary-qua-none {
  border-bottom: 1px none #b9b9e9; }

.border-b-primary-qua-hidden {
  border-bottom: 1px hidden #b9b9e9; }

.border-l-primary-qua {
  border-left: 1px solid #b9b9e9; }

.border-l-primary-qua-dotted {
  border-left: 1px dotted #b9b9e9; }

.border-l-primary-qua-dashed {
  border-left: 1px dashed #b9b9e9; }

.border-l-primary-qua-solid {
  border-left: 1px solid #b9b9e9; }

.border-l-primary-qua-double {
  border-left: 1px double #b9b9e9; }

.border-l-primary-qua-groove {
  border-left: 1px groove #b9b9e9; }

.border-l-primary-qua-ridge {
  border-left: 1px ridge #b9b9e9; }

.border-l-primary-qua-inset {
  border-left: 1px inset #b9b9e9; }

.border-l-primary-qua-outset {
  border-left: 1px outset #b9b9e9; }

.border-l-primary-qua-none {
  border-left: 1px none #b9b9e9; }

.border-l-primary-qua-hidden {
  border-left: 1px hidden #b9b9e9; }

.has-text-primary-qui {
  color: #b8b8d6; }

.has-background-primary-qui {
  background-color: #b8b8d6; }

.border-primary-qui {
  border: 1px solid #b8b8d6; }

.border-m-primary-qui:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b8b8d6;
  transform: translateY(-50%); }

.border-primary-qui-dotted {
  border: 1px dotted #b8b8d6; }

.border-primary-qui-dashed {
  border: 1px dashed #b8b8d6; }

.border-primary-qui-solid {
  border: 1px solid #b8b8d6; }

.border-primary-qui-double {
  border: 1px double #b8b8d6; }

.border-primary-qui-groove {
  border: 1px groove #b8b8d6; }

.border-primary-qui-ridge {
  border: 1px ridge #b8b8d6; }

.border-primary-qui-inset {
  border: 1px inset #b8b8d6; }

.border-primary-qui-outset {
  border: 1px outset #b8b8d6; }

.border-primary-qui-none {
  border: 1px none #b8b8d6; }

.border-primary-qui-hidden {
  border: 1px hidden #b8b8d6; }

.border-t-primary-qui {
  border-top: 1px solid #b8b8d6; }

.border-t-primary-qui-dotted {
  border-top: 1px dotted #b8b8d6; }

.border-t-primary-qui-dashed {
  border-top: 1px dashed #b8b8d6; }

.border-t-primary-qui-solid {
  border-top: 1px solid #b8b8d6; }

.border-t-primary-qui-double {
  border-top: 1px double #b8b8d6; }

.border-t-primary-qui-groove {
  border-top: 1px groove #b8b8d6; }

.border-t-primary-qui-ridge {
  border-top: 1px ridge #b8b8d6; }

.border-t-primary-qui-inset {
  border-top: 1px inset #b8b8d6; }

.border-t-primary-qui-outset {
  border-top: 1px outset #b8b8d6; }

.border-t-primary-qui-none {
  border-top: 1px none #b8b8d6; }

.border-t-primary-qui-hidden {
  border-top: 1px hidden #b8b8d6; }

.border-r-primary-qui {
  border-right: 1px solid #b8b8d6; }

.border-r-primary-qui-dotted {
  border-right: 1px dotted #b8b8d6; }

.border-r-primary-qui-dashed {
  border-right: 1px dashed #b8b8d6; }

.border-r-primary-qui-solid {
  border-right: 1px solid #b8b8d6; }

.border-r-primary-qui-double {
  border-right: 1px double #b8b8d6; }

.border-r-primary-qui-groove {
  border-right: 1px groove #b8b8d6; }

.border-r-primary-qui-ridge {
  border-right: 1px ridge #b8b8d6; }

.border-r-primary-qui-inset {
  border-right: 1px inset #b8b8d6; }

.border-r-primary-qui-outset {
  border-right: 1px outset #b8b8d6; }

.border-r-primary-qui-none {
  border-right: 1px none #b8b8d6; }

.border-r-primary-qui-hidden {
  border-right: 1px hidden #b8b8d6; }

.border-b-primary-qui {
  border-bottom: 1px solid #b8b8d6; }

.border-b-primary-qui-dotted {
  border-bottom: 1px dotted #b8b8d6; }

.border-b-primary-qui-dashed {
  border-bottom: 1px dashed #b8b8d6; }

.border-b-primary-qui-solid {
  border-bottom: 1px solid #b8b8d6; }

.border-b-primary-qui-double {
  border-bottom: 1px double #b8b8d6; }

.border-b-primary-qui-groove {
  border-bottom: 1px groove #b8b8d6; }

.border-b-primary-qui-ridge {
  border-bottom: 1px ridge #b8b8d6; }

.border-b-primary-qui-inset {
  border-bottom: 1px inset #b8b8d6; }

.border-b-primary-qui-outset {
  border-bottom: 1px outset #b8b8d6; }

.border-b-primary-qui-none {
  border-bottom: 1px none #b8b8d6; }

.border-b-primary-qui-hidden {
  border-bottom: 1px hidden #b8b8d6; }

.border-l-primary-qui {
  border-left: 1px solid #b8b8d6; }

.border-l-primary-qui-dotted {
  border-left: 1px dotted #b8b8d6; }

.border-l-primary-qui-dashed {
  border-left: 1px dashed #b8b8d6; }

.border-l-primary-qui-solid {
  border-left: 1px solid #b8b8d6; }

.border-l-primary-qui-double {
  border-left: 1px double #b8b8d6; }

.border-l-primary-qui-groove {
  border-left: 1px groove #b8b8d6; }

.border-l-primary-qui-ridge {
  border-left: 1px ridge #b8b8d6; }

.border-l-primary-qui-inset {
  border-left: 1px inset #b8b8d6; }

.border-l-primary-qui-outset {
  border-left: 1px outset #b8b8d6; }

.border-l-primary-qui-none {
  border-left: 1px none #b8b8d6; }

.border-l-primary-qui-hidden {
  border-left: 1px hidden #b8b8d6; }

.has-text-primary-light {
  color: #dbdbf0; }

.has-background-primary-light {
  background-color: #dbdbf0; }

.border-primary-light {
  border: 1px solid #dbdbf0; }

.border-m-primary-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dbdbf0;
  transform: translateY(-50%); }

.border-primary-light-dotted {
  border: 1px dotted #dbdbf0; }

.border-primary-light-dashed {
  border: 1px dashed #dbdbf0; }

.border-primary-light-solid {
  border: 1px solid #dbdbf0; }

.border-primary-light-double {
  border: 1px double #dbdbf0; }

.border-primary-light-groove {
  border: 1px groove #dbdbf0; }

.border-primary-light-ridge {
  border: 1px ridge #dbdbf0; }

.border-primary-light-inset {
  border: 1px inset #dbdbf0; }

.border-primary-light-outset {
  border: 1px outset #dbdbf0; }

.border-primary-light-none {
  border: 1px none #dbdbf0; }

.border-primary-light-hidden {
  border: 1px hidden #dbdbf0; }

.border-t-primary-light {
  border-top: 1px solid #dbdbf0; }

.border-t-primary-light-dotted {
  border-top: 1px dotted #dbdbf0; }

.border-t-primary-light-dashed {
  border-top: 1px dashed #dbdbf0; }

.border-t-primary-light-solid {
  border-top: 1px solid #dbdbf0; }

.border-t-primary-light-double {
  border-top: 1px double #dbdbf0; }

.border-t-primary-light-groove {
  border-top: 1px groove #dbdbf0; }

.border-t-primary-light-ridge {
  border-top: 1px ridge #dbdbf0; }

.border-t-primary-light-inset {
  border-top: 1px inset #dbdbf0; }

.border-t-primary-light-outset {
  border-top: 1px outset #dbdbf0; }

.border-t-primary-light-none {
  border-top: 1px none #dbdbf0; }

.border-t-primary-light-hidden {
  border-top: 1px hidden #dbdbf0; }

.border-r-primary-light {
  border-right: 1px solid #dbdbf0; }

.border-r-primary-light-dotted {
  border-right: 1px dotted #dbdbf0; }

.border-r-primary-light-dashed {
  border-right: 1px dashed #dbdbf0; }

.border-r-primary-light-solid {
  border-right: 1px solid #dbdbf0; }

.border-r-primary-light-double {
  border-right: 1px double #dbdbf0; }

.border-r-primary-light-groove {
  border-right: 1px groove #dbdbf0; }

.border-r-primary-light-ridge {
  border-right: 1px ridge #dbdbf0; }

.border-r-primary-light-inset {
  border-right: 1px inset #dbdbf0; }

.border-r-primary-light-outset {
  border-right: 1px outset #dbdbf0; }

.border-r-primary-light-none {
  border-right: 1px none #dbdbf0; }

.border-r-primary-light-hidden {
  border-right: 1px hidden #dbdbf0; }

.border-b-primary-light {
  border-bottom: 1px solid #dbdbf0; }

.border-b-primary-light-dotted {
  border-bottom: 1px dotted #dbdbf0; }

.border-b-primary-light-dashed {
  border-bottom: 1px dashed #dbdbf0; }

.border-b-primary-light-solid {
  border-bottom: 1px solid #dbdbf0; }

.border-b-primary-light-double {
  border-bottom: 1px double #dbdbf0; }

.border-b-primary-light-groove {
  border-bottom: 1px groove #dbdbf0; }

.border-b-primary-light-ridge {
  border-bottom: 1px ridge #dbdbf0; }

.border-b-primary-light-inset {
  border-bottom: 1px inset #dbdbf0; }

.border-b-primary-light-outset {
  border-bottom: 1px outset #dbdbf0; }

.border-b-primary-light-none {
  border-bottom: 1px none #dbdbf0; }

.border-b-primary-light-hidden {
  border-bottom: 1px hidden #dbdbf0; }

.border-l-primary-light {
  border-left: 1px solid #dbdbf0; }

.border-l-primary-light-dotted {
  border-left: 1px dotted #dbdbf0; }

.border-l-primary-light-dashed {
  border-left: 1px dashed #dbdbf0; }

.border-l-primary-light-solid {
  border-left: 1px solid #dbdbf0; }

.border-l-primary-light-double {
  border-left: 1px double #dbdbf0; }

.border-l-primary-light-groove {
  border-left: 1px groove #dbdbf0; }

.border-l-primary-light-ridge {
  border-left: 1px ridge #dbdbf0; }

.border-l-primary-light-inset {
  border-left: 1px inset #dbdbf0; }

.border-l-primary-light-outset {
  border-left: 1px outset #dbdbf0; }

.border-l-primary-light-none {
  border-left: 1px none #dbdbf0; }

.border-l-primary-light-hidden {
  border-left: 1px hidden #dbdbf0; }

.has-text-primary-lighter {
  color: #ededf7; }

.has-background-primary-lighter {
  background-color: #ededf7; }

.border-primary-lighter {
  border: 1px solid #ededf7; }

.border-m-primary-lighter:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ededf7;
  transform: translateY(-50%); }

.border-primary-lighter-dotted {
  border: 1px dotted #ededf7; }

.border-primary-lighter-dashed {
  border: 1px dashed #ededf7; }

.border-primary-lighter-solid {
  border: 1px solid #ededf7; }

.border-primary-lighter-double {
  border: 1px double #ededf7; }

.border-primary-lighter-groove {
  border: 1px groove #ededf7; }

.border-primary-lighter-ridge {
  border: 1px ridge #ededf7; }

.border-primary-lighter-inset {
  border: 1px inset #ededf7; }

.border-primary-lighter-outset {
  border: 1px outset #ededf7; }

.border-primary-lighter-none {
  border: 1px none #ededf7; }

.border-primary-lighter-hidden {
  border: 1px hidden #ededf7; }

.border-t-primary-lighter {
  border-top: 1px solid #ededf7; }

.border-t-primary-lighter-dotted {
  border-top: 1px dotted #ededf7; }

.border-t-primary-lighter-dashed {
  border-top: 1px dashed #ededf7; }

.border-t-primary-lighter-solid {
  border-top: 1px solid #ededf7; }

.border-t-primary-lighter-double {
  border-top: 1px double #ededf7; }

.border-t-primary-lighter-groove {
  border-top: 1px groove #ededf7; }

.border-t-primary-lighter-ridge {
  border-top: 1px ridge #ededf7; }

.border-t-primary-lighter-inset {
  border-top: 1px inset #ededf7; }

.border-t-primary-lighter-outset {
  border-top: 1px outset #ededf7; }

.border-t-primary-lighter-none {
  border-top: 1px none #ededf7; }

.border-t-primary-lighter-hidden {
  border-top: 1px hidden #ededf7; }

.border-r-primary-lighter {
  border-right: 1px solid #ededf7; }

.border-r-primary-lighter-dotted {
  border-right: 1px dotted #ededf7; }

.border-r-primary-lighter-dashed {
  border-right: 1px dashed #ededf7; }

.border-r-primary-lighter-solid {
  border-right: 1px solid #ededf7; }

.border-r-primary-lighter-double {
  border-right: 1px double #ededf7; }

.border-r-primary-lighter-groove {
  border-right: 1px groove #ededf7; }

.border-r-primary-lighter-ridge {
  border-right: 1px ridge #ededf7; }

.border-r-primary-lighter-inset {
  border-right: 1px inset #ededf7; }

.border-r-primary-lighter-outset {
  border-right: 1px outset #ededf7; }

.border-r-primary-lighter-none {
  border-right: 1px none #ededf7; }

.border-r-primary-lighter-hidden {
  border-right: 1px hidden #ededf7; }

.border-b-primary-lighter {
  border-bottom: 1px solid #ededf7; }

.border-b-primary-lighter-dotted {
  border-bottom: 1px dotted #ededf7; }

.border-b-primary-lighter-dashed {
  border-bottom: 1px dashed #ededf7; }

.border-b-primary-lighter-solid {
  border-bottom: 1px solid #ededf7; }

.border-b-primary-lighter-double {
  border-bottom: 1px double #ededf7; }

.border-b-primary-lighter-groove {
  border-bottom: 1px groove #ededf7; }

.border-b-primary-lighter-ridge {
  border-bottom: 1px ridge #ededf7; }

.border-b-primary-lighter-inset {
  border-bottom: 1px inset #ededf7; }

.border-b-primary-lighter-outset {
  border-bottom: 1px outset #ededf7; }

.border-b-primary-lighter-none {
  border-bottom: 1px none #ededf7; }

.border-b-primary-lighter-hidden {
  border-bottom: 1px hidden #ededf7; }

.border-l-primary-lighter {
  border-left: 1px solid #ededf7; }

.border-l-primary-lighter-dotted {
  border-left: 1px dotted #ededf7; }

.border-l-primary-lighter-dashed {
  border-left: 1px dashed #ededf7; }

.border-l-primary-lighter-solid {
  border-left: 1px solid #ededf7; }

.border-l-primary-lighter-double {
  border-left: 1px double #ededf7; }

.border-l-primary-lighter-groove {
  border-left: 1px groove #ededf7; }

.border-l-primary-lighter-ridge {
  border-left: 1px ridge #ededf7; }

.border-l-primary-lighter-inset {
  border-left: 1px inset #ededf7; }

.border-l-primary-lighter-outset {
  border-left: 1px outset #ededf7; }

.border-l-primary-lighter-none {
  border-left: 1px none #ededf7; }

.border-l-primary-lighter-hidden {
  border-left: 1px hidden #ededf7; }

.has-text-primary-lighbis {
  color: #f4f4fa; }

.has-background-primary-lighbis {
  background-color: #f4f4fa; }

.border-primary-lighbis {
  border: 1px solid #f4f4fa; }

.border-m-primary-lighbis:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f4f4fa;
  transform: translateY(-50%); }

.border-primary-lighbis-dotted {
  border: 1px dotted #f4f4fa; }

.border-primary-lighbis-dashed {
  border: 1px dashed #f4f4fa; }

.border-primary-lighbis-solid {
  border: 1px solid #f4f4fa; }

.border-primary-lighbis-double {
  border: 1px double #f4f4fa; }

.border-primary-lighbis-groove {
  border: 1px groove #f4f4fa; }

.border-primary-lighbis-ridge {
  border: 1px ridge #f4f4fa; }

.border-primary-lighbis-inset {
  border: 1px inset #f4f4fa; }

.border-primary-lighbis-outset {
  border: 1px outset #f4f4fa; }

.border-primary-lighbis-none {
  border: 1px none #f4f4fa; }

.border-primary-lighbis-hidden {
  border: 1px hidden #f4f4fa; }

.border-t-primary-lighbis {
  border-top: 1px solid #f4f4fa; }

.border-t-primary-lighbis-dotted {
  border-top: 1px dotted #f4f4fa; }

.border-t-primary-lighbis-dashed {
  border-top: 1px dashed #f4f4fa; }

.border-t-primary-lighbis-solid {
  border-top: 1px solid #f4f4fa; }

.border-t-primary-lighbis-double {
  border-top: 1px double #f4f4fa; }

.border-t-primary-lighbis-groove {
  border-top: 1px groove #f4f4fa; }

.border-t-primary-lighbis-ridge {
  border-top: 1px ridge #f4f4fa; }

.border-t-primary-lighbis-inset {
  border-top: 1px inset #f4f4fa; }

.border-t-primary-lighbis-outset {
  border-top: 1px outset #f4f4fa; }

.border-t-primary-lighbis-none {
  border-top: 1px none #f4f4fa; }

.border-t-primary-lighbis-hidden {
  border-top: 1px hidden #f4f4fa; }

.border-r-primary-lighbis {
  border-right: 1px solid #f4f4fa; }

.border-r-primary-lighbis-dotted {
  border-right: 1px dotted #f4f4fa; }

.border-r-primary-lighbis-dashed {
  border-right: 1px dashed #f4f4fa; }

.border-r-primary-lighbis-solid {
  border-right: 1px solid #f4f4fa; }

.border-r-primary-lighbis-double {
  border-right: 1px double #f4f4fa; }

.border-r-primary-lighbis-groove {
  border-right: 1px groove #f4f4fa; }

.border-r-primary-lighbis-ridge {
  border-right: 1px ridge #f4f4fa; }

.border-r-primary-lighbis-inset {
  border-right: 1px inset #f4f4fa; }

.border-r-primary-lighbis-outset {
  border-right: 1px outset #f4f4fa; }

.border-r-primary-lighbis-none {
  border-right: 1px none #f4f4fa; }

.border-r-primary-lighbis-hidden {
  border-right: 1px hidden #f4f4fa; }

.border-b-primary-lighbis {
  border-bottom: 1px solid #f4f4fa; }

.border-b-primary-lighbis-dotted {
  border-bottom: 1px dotted #f4f4fa; }

.border-b-primary-lighbis-dashed {
  border-bottom: 1px dashed #f4f4fa; }

.border-b-primary-lighbis-solid {
  border-bottom: 1px solid #f4f4fa; }

.border-b-primary-lighbis-double {
  border-bottom: 1px double #f4f4fa; }

.border-b-primary-lighbis-groove {
  border-bottom: 1px groove #f4f4fa; }

.border-b-primary-lighbis-ridge {
  border-bottom: 1px ridge #f4f4fa; }

.border-b-primary-lighbis-inset {
  border-bottom: 1px inset #f4f4fa; }

.border-b-primary-lighbis-outset {
  border-bottom: 1px outset #f4f4fa; }

.border-b-primary-lighbis-none {
  border-bottom: 1px none #f4f4fa; }

.border-b-primary-lighbis-hidden {
  border-bottom: 1px hidden #f4f4fa; }

.border-l-primary-lighbis {
  border-left: 1px solid #f4f4fa; }

.border-l-primary-lighbis-dotted {
  border-left: 1px dotted #f4f4fa; }

.border-l-primary-lighbis-dashed {
  border-left: 1px dashed #f4f4fa; }

.border-l-primary-lighbis-solid {
  border-left: 1px solid #f4f4fa; }

.border-l-primary-lighbis-double {
  border-left: 1px double #f4f4fa; }

.border-l-primary-lighbis-groove {
  border-left: 1px groove #f4f4fa; }

.border-l-primary-lighbis-ridge {
  border-left: 1px ridge #f4f4fa; }

.border-l-primary-lighbis-inset {
  border-left: 1px inset #f4f4fa; }

.border-l-primary-lighbis-outset {
  border-left: 1px outset #f4f4fa; }

.border-l-primary-lighbis-none {
  border-left: 1px none #f4f4fa; }

.border-l-primary-lighbis-hidden {
  border-left: 1px hidden #f4f4fa; }

.has-text-secondary {
  color: #eb6666; }

.has-background-secondary {
  background-color: #eb6666; }

.border-secondary {
  border: 1px solid #eb6666; }

.border-m-secondary:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #eb6666;
  transform: translateY(-50%); }

.border-secondary-dotted {
  border: 1px dotted #eb6666; }

.border-secondary-dashed {
  border: 1px dashed #eb6666; }

.border-secondary-solid {
  border: 1px solid #eb6666; }

.border-secondary-double {
  border: 1px double #eb6666; }

.border-secondary-groove {
  border: 1px groove #eb6666; }

.border-secondary-ridge {
  border: 1px ridge #eb6666; }

.border-secondary-inset {
  border: 1px inset #eb6666; }

.border-secondary-outset {
  border: 1px outset #eb6666; }

.border-secondary-none {
  border: 1px none #eb6666; }

.border-secondary-hidden {
  border: 1px hidden #eb6666; }

.border-t-secondary {
  border-top: 1px solid #eb6666; }

.border-t-secondary-dotted {
  border-top: 1px dotted #eb6666; }

.border-t-secondary-dashed {
  border-top: 1px dashed #eb6666; }

.border-t-secondary-solid {
  border-top: 1px solid #eb6666; }

.border-t-secondary-double {
  border-top: 1px double #eb6666; }

.border-t-secondary-groove {
  border-top: 1px groove #eb6666; }

.border-t-secondary-ridge {
  border-top: 1px ridge #eb6666; }

.border-t-secondary-inset {
  border-top: 1px inset #eb6666; }

.border-t-secondary-outset {
  border-top: 1px outset #eb6666; }

.border-t-secondary-none {
  border-top: 1px none #eb6666; }

.border-t-secondary-hidden {
  border-top: 1px hidden #eb6666; }

.border-r-secondary {
  border-right: 1px solid #eb6666; }

.border-r-secondary-dotted {
  border-right: 1px dotted #eb6666; }

.border-r-secondary-dashed {
  border-right: 1px dashed #eb6666; }

.border-r-secondary-solid {
  border-right: 1px solid #eb6666; }

.border-r-secondary-double {
  border-right: 1px double #eb6666; }

.border-r-secondary-groove {
  border-right: 1px groove #eb6666; }

.border-r-secondary-ridge {
  border-right: 1px ridge #eb6666; }

.border-r-secondary-inset {
  border-right: 1px inset #eb6666; }

.border-r-secondary-outset {
  border-right: 1px outset #eb6666; }

.border-r-secondary-none {
  border-right: 1px none #eb6666; }

.border-r-secondary-hidden {
  border-right: 1px hidden #eb6666; }

.border-b-secondary {
  border-bottom: 1px solid #eb6666; }

.border-b-secondary-dotted {
  border-bottom: 1px dotted #eb6666; }

.border-b-secondary-dashed {
  border-bottom: 1px dashed #eb6666; }

.border-b-secondary-solid {
  border-bottom: 1px solid #eb6666; }

.border-b-secondary-double {
  border-bottom: 1px double #eb6666; }

.border-b-secondary-groove {
  border-bottom: 1px groove #eb6666; }

.border-b-secondary-ridge {
  border-bottom: 1px ridge #eb6666; }

.border-b-secondary-inset {
  border-bottom: 1px inset #eb6666; }

.border-b-secondary-outset {
  border-bottom: 1px outset #eb6666; }

.border-b-secondary-none {
  border-bottom: 1px none #eb6666; }

.border-b-secondary-hidden {
  border-bottom: 1px hidden #eb6666; }

.border-l-secondary {
  border-left: 1px solid #eb6666; }

.border-l-secondary-dotted {
  border-left: 1px dotted #eb6666; }

.border-l-secondary-dashed {
  border-left: 1px dashed #eb6666; }

.border-l-secondary-solid {
  border-left: 1px solid #eb6666; }

.border-l-secondary-double {
  border-left: 1px double #eb6666; }

.border-l-secondary-groove {
  border-left: 1px groove #eb6666; }

.border-l-secondary-ridge {
  border-left: 1px ridge #eb6666; }

.border-l-secondary-inset {
  border-left: 1px inset #eb6666; }

.border-l-secondary-outset {
  border-left: 1px outset #eb6666; }

.border-l-secondary-none {
  border-left: 1px none #eb6666; }

.border-l-secondary-hidden {
  border-left: 1px hidden #eb6666; }

.has-text-danger {
  color: #cc3333; }

.has-background-danger {
  background-color: #cc3333; }

.border-danger {
  border: 1px solid #cc3333; }

.border-m-danger:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #cc3333;
  transform: translateY(-50%); }

.border-danger-dotted {
  border: 1px dotted #cc3333; }

.border-danger-dashed {
  border: 1px dashed #cc3333; }

.border-danger-solid {
  border: 1px solid #cc3333; }

.border-danger-double {
  border: 1px double #cc3333; }

.border-danger-groove {
  border: 1px groove #cc3333; }

.border-danger-ridge {
  border: 1px ridge #cc3333; }

.border-danger-inset {
  border: 1px inset #cc3333; }

.border-danger-outset {
  border: 1px outset #cc3333; }

.border-danger-none {
  border: 1px none #cc3333; }

.border-danger-hidden {
  border: 1px hidden #cc3333; }

.border-t-danger {
  border-top: 1px solid #cc3333; }

.border-t-danger-dotted {
  border-top: 1px dotted #cc3333; }

.border-t-danger-dashed {
  border-top: 1px dashed #cc3333; }

.border-t-danger-solid {
  border-top: 1px solid #cc3333; }

.border-t-danger-double {
  border-top: 1px double #cc3333; }

.border-t-danger-groove {
  border-top: 1px groove #cc3333; }

.border-t-danger-ridge {
  border-top: 1px ridge #cc3333; }

.border-t-danger-inset {
  border-top: 1px inset #cc3333; }

.border-t-danger-outset {
  border-top: 1px outset #cc3333; }

.border-t-danger-none {
  border-top: 1px none #cc3333; }

.border-t-danger-hidden {
  border-top: 1px hidden #cc3333; }

.border-r-danger {
  border-right: 1px solid #cc3333; }

.border-r-danger-dotted {
  border-right: 1px dotted #cc3333; }

.border-r-danger-dashed {
  border-right: 1px dashed #cc3333; }

.border-r-danger-solid {
  border-right: 1px solid #cc3333; }

.border-r-danger-double {
  border-right: 1px double #cc3333; }

.border-r-danger-groove {
  border-right: 1px groove #cc3333; }

.border-r-danger-ridge {
  border-right: 1px ridge #cc3333; }

.border-r-danger-inset {
  border-right: 1px inset #cc3333; }

.border-r-danger-outset {
  border-right: 1px outset #cc3333; }

.border-r-danger-none {
  border-right: 1px none #cc3333; }

.border-r-danger-hidden {
  border-right: 1px hidden #cc3333; }

.border-b-danger {
  border-bottom: 1px solid #cc3333; }

.border-b-danger-dotted {
  border-bottom: 1px dotted #cc3333; }

.border-b-danger-dashed {
  border-bottom: 1px dashed #cc3333; }

.border-b-danger-solid {
  border-bottom: 1px solid #cc3333; }

.border-b-danger-double {
  border-bottom: 1px double #cc3333; }

.border-b-danger-groove {
  border-bottom: 1px groove #cc3333; }

.border-b-danger-ridge {
  border-bottom: 1px ridge #cc3333; }

.border-b-danger-inset {
  border-bottom: 1px inset #cc3333; }

.border-b-danger-outset {
  border-bottom: 1px outset #cc3333; }

.border-b-danger-none {
  border-bottom: 1px none #cc3333; }

.border-b-danger-hidden {
  border-bottom: 1px hidden #cc3333; }

.border-l-danger {
  border-left: 1px solid #cc3333; }

.border-l-danger-dotted {
  border-left: 1px dotted #cc3333; }

.border-l-danger-dashed {
  border-left: 1px dashed #cc3333; }

.border-l-danger-solid {
  border-left: 1px solid #cc3333; }

.border-l-danger-double {
  border-left: 1px double #cc3333; }

.border-l-danger-groove {
  border-left: 1px groove #cc3333; }

.border-l-danger-ridge {
  border-left: 1px ridge #cc3333; }

.border-l-danger-inset {
  border-left: 1px inset #cc3333; }

.border-l-danger-outset {
  border-left: 1px outset #cc3333; }

.border-l-danger-none {
  border-left: 1px none #cc3333; }

.border-l-danger-hidden {
  border-left: 1px hidden #cc3333; }

.has-text-danger-light {
  color: #f6dada; }

.has-background-danger-light {
  background-color: #f6dada; }

.border-danger-light {
  border: 1px solid #f6dada; }

.border-m-danger-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f6dada;
  transform: translateY(-50%); }

.border-danger-light-dotted {
  border: 1px dotted #f6dada; }

.border-danger-light-dashed {
  border: 1px dashed #f6dada; }

.border-danger-light-solid {
  border: 1px solid #f6dada; }

.border-danger-light-double {
  border: 1px double #f6dada; }

.border-danger-light-groove {
  border: 1px groove #f6dada; }

.border-danger-light-ridge {
  border: 1px ridge #f6dada; }

.border-danger-light-inset {
  border: 1px inset #f6dada; }

.border-danger-light-outset {
  border: 1px outset #f6dada; }

.border-danger-light-none {
  border: 1px none #f6dada; }

.border-danger-light-hidden {
  border: 1px hidden #f6dada; }

.border-t-danger-light {
  border-top: 1px solid #f6dada; }

.border-t-danger-light-dotted {
  border-top: 1px dotted #f6dada; }

.border-t-danger-light-dashed {
  border-top: 1px dashed #f6dada; }

.border-t-danger-light-solid {
  border-top: 1px solid #f6dada; }

.border-t-danger-light-double {
  border-top: 1px double #f6dada; }

.border-t-danger-light-groove {
  border-top: 1px groove #f6dada; }

.border-t-danger-light-ridge {
  border-top: 1px ridge #f6dada; }

.border-t-danger-light-inset {
  border-top: 1px inset #f6dada; }

.border-t-danger-light-outset {
  border-top: 1px outset #f6dada; }

.border-t-danger-light-none {
  border-top: 1px none #f6dada; }

.border-t-danger-light-hidden {
  border-top: 1px hidden #f6dada; }

.border-r-danger-light {
  border-right: 1px solid #f6dada; }

.border-r-danger-light-dotted {
  border-right: 1px dotted #f6dada; }

.border-r-danger-light-dashed {
  border-right: 1px dashed #f6dada; }

.border-r-danger-light-solid {
  border-right: 1px solid #f6dada; }

.border-r-danger-light-double {
  border-right: 1px double #f6dada; }

.border-r-danger-light-groove {
  border-right: 1px groove #f6dada; }

.border-r-danger-light-ridge {
  border-right: 1px ridge #f6dada; }

.border-r-danger-light-inset {
  border-right: 1px inset #f6dada; }

.border-r-danger-light-outset {
  border-right: 1px outset #f6dada; }

.border-r-danger-light-none {
  border-right: 1px none #f6dada; }

.border-r-danger-light-hidden {
  border-right: 1px hidden #f6dada; }

.border-b-danger-light {
  border-bottom: 1px solid #f6dada; }

.border-b-danger-light-dotted {
  border-bottom: 1px dotted #f6dada; }

.border-b-danger-light-dashed {
  border-bottom: 1px dashed #f6dada; }

.border-b-danger-light-solid {
  border-bottom: 1px solid #f6dada; }

.border-b-danger-light-double {
  border-bottom: 1px double #f6dada; }

.border-b-danger-light-groove {
  border-bottom: 1px groove #f6dada; }

.border-b-danger-light-ridge {
  border-bottom: 1px ridge #f6dada; }

.border-b-danger-light-inset {
  border-bottom: 1px inset #f6dada; }

.border-b-danger-light-outset {
  border-bottom: 1px outset #f6dada; }

.border-b-danger-light-none {
  border-bottom: 1px none #f6dada; }

.border-b-danger-light-hidden {
  border-bottom: 1px hidden #f6dada; }

.border-l-danger-light {
  border-left: 1px solid #f6dada; }

.border-l-danger-light-dotted {
  border-left: 1px dotted #f6dada; }

.border-l-danger-light-dashed {
  border-left: 1px dashed #f6dada; }

.border-l-danger-light-solid {
  border-left: 1px solid #f6dada; }

.border-l-danger-light-double {
  border-left: 1px double #f6dada; }

.border-l-danger-light-groove {
  border-left: 1px groove #f6dada; }

.border-l-danger-light-ridge {
  border-left: 1px ridge #f6dada; }

.border-l-danger-light-inset {
  border-left: 1px inset #f6dada; }

.border-l-danger-light-outset {
  border-left: 1px outset #f6dada; }

.border-l-danger-light-none {
  border-left: 1px none #f6dada; }

.border-l-danger-light-hidden {
  border-left: 1px hidden #f6dada; }

.has-text-warning {
  color: #ffd500; }

.has-background-warning {
  background-color: #ffd500; }

.border-warning {
  border: 1px solid #ffd500; }

.border-m-warning:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ffd500;
  transform: translateY(-50%); }

.border-warning-dotted {
  border: 1px dotted #ffd500; }

.border-warning-dashed {
  border: 1px dashed #ffd500; }

.border-warning-solid {
  border: 1px solid #ffd500; }

.border-warning-double {
  border: 1px double #ffd500; }

.border-warning-groove {
  border: 1px groove #ffd500; }

.border-warning-ridge {
  border: 1px ridge #ffd500; }

.border-warning-inset {
  border: 1px inset #ffd500; }

.border-warning-outset {
  border: 1px outset #ffd500; }

.border-warning-none {
  border: 1px none #ffd500; }

.border-warning-hidden {
  border: 1px hidden #ffd500; }

.border-t-warning {
  border-top: 1px solid #ffd500; }

.border-t-warning-dotted {
  border-top: 1px dotted #ffd500; }

.border-t-warning-dashed {
  border-top: 1px dashed #ffd500; }

.border-t-warning-solid {
  border-top: 1px solid #ffd500; }

.border-t-warning-double {
  border-top: 1px double #ffd500; }

.border-t-warning-groove {
  border-top: 1px groove #ffd500; }

.border-t-warning-ridge {
  border-top: 1px ridge #ffd500; }

.border-t-warning-inset {
  border-top: 1px inset #ffd500; }

.border-t-warning-outset {
  border-top: 1px outset #ffd500; }

.border-t-warning-none {
  border-top: 1px none #ffd500; }

.border-t-warning-hidden {
  border-top: 1px hidden #ffd500; }

.border-r-warning {
  border-right: 1px solid #ffd500; }

.border-r-warning-dotted {
  border-right: 1px dotted #ffd500; }

.border-r-warning-dashed {
  border-right: 1px dashed #ffd500; }

.border-r-warning-solid {
  border-right: 1px solid #ffd500; }

.border-r-warning-double {
  border-right: 1px double #ffd500; }

.border-r-warning-groove {
  border-right: 1px groove #ffd500; }

.border-r-warning-ridge {
  border-right: 1px ridge #ffd500; }

.border-r-warning-inset {
  border-right: 1px inset #ffd500; }

.border-r-warning-outset {
  border-right: 1px outset #ffd500; }

.border-r-warning-none {
  border-right: 1px none #ffd500; }

.border-r-warning-hidden {
  border-right: 1px hidden #ffd500; }

.border-b-warning {
  border-bottom: 1px solid #ffd500; }

.border-b-warning-dotted {
  border-bottom: 1px dotted #ffd500; }

.border-b-warning-dashed {
  border-bottom: 1px dashed #ffd500; }

.border-b-warning-solid {
  border-bottom: 1px solid #ffd500; }

.border-b-warning-double {
  border-bottom: 1px double #ffd500; }

.border-b-warning-groove {
  border-bottom: 1px groove #ffd500; }

.border-b-warning-ridge {
  border-bottom: 1px ridge #ffd500; }

.border-b-warning-inset {
  border-bottom: 1px inset #ffd500; }

.border-b-warning-outset {
  border-bottom: 1px outset #ffd500; }

.border-b-warning-none {
  border-bottom: 1px none #ffd500; }

.border-b-warning-hidden {
  border-bottom: 1px hidden #ffd500; }

.border-l-warning {
  border-left: 1px solid #ffd500; }

.border-l-warning-dotted {
  border-left: 1px dotted #ffd500; }

.border-l-warning-dashed {
  border-left: 1px dashed #ffd500; }

.border-l-warning-solid {
  border-left: 1px solid #ffd500; }

.border-l-warning-double {
  border-left: 1px double #ffd500; }

.border-l-warning-groove {
  border-left: 1px groove #ffd500; }

.border-l-warning-ridge {
  border-left: 1px ridge #ffd500; }

.border-l-warning-inset {
  border-left: 1px inset #ffd500; }

.border-l-warning-outset {
  border-left: 1px outset #ffd500; }

.border-l-warning-none {
  border-left: 1px none #ffd500; }

.border-l-warning-hidden {
  border-left: 1px hidden #ffd500; }

.has-text-success {
  color: #66cc33; }

.has-background-success {
  background-color: #66cc33; }

.border-success {
  border: 1px solid #66cc33; }

.border-m-success:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #66cc33;
  transform: translateY(-50%); }

.border-success-dotted {
  border: 1px dotted #66cc33; }

.border-success-dashed {
  border: 1px dashed #66cc33; }

.border-success-solid {
  border: 1px solid #66cc33; }

.border-success-double {
  border: 1px double #66cc33; }

.border-success-groove {
  border: 1px groove #66cc33; }

.border-success-ridge {
  border: 1px ridge #66cc33; }

.border-success-inset {
  border: 1px inset #66cc33; }

.border-success-outset {
  border: 1px outset #66cc33; }

.border-success-none {
  border: 1px none #66cc33; }

.border-success-hidden {
  border: 1px hidden #66cc33; }

.border-t-success {
  border-top: 1px solid #66cc33; }

.border-t-success-dotted {
  border-top: 1px dotted #66cc33; }

.border-t-success-dashed {
  border-top: 1px dashed #66cc33; }

.border-t-success-solid {
  border-top: 1px solid #66cc33; }

.border-t-success-double {
  border-top: 1px double #66cc33; }

.border-t-success-groove {
  border-top: 1px groove #66cc33; }

.border-t-success-ridge {
  border-top: 1px ridge #66cc33; }

.border-t-success-inset {
  border-top: 1px inset #66cc33; }

.border-t-success-outset {
  border-top: 1px outset #66cc33; }

.border-t-success-none {
  border-top: 1px none #66cc33; }

.border-t-success-hidden {
  border-top: 1px hidden #66cc33; }

.border-r-success {
  border-right: 1px solid #66cc33; }

.border-r-success-dotted {
  border-right: 1px dotted #66cc33; }

.border-r-success-dashed {
  border-right: 1px dashed #66cc33; }

.border-r-success-solid {
  border-right: 1px solid #66cc33; }

.border-r-success-double {
  border-right: 1px double #66cc33; }

.border-r-success-groove {
  border-right: 1px groove #66cc33; }

.border-r-success-ridge {
  border-right: 1px ridge #66cc33; }

.border-r-success-inset {
  border-right: 1px inset #66cc33; }

.border-r-success-outset {
  border-right: 1px outset #66cc33; }

.border-r-success-none {
  border-right: 1px none #66cc33; }

.border-r-success-hidden {
  border-right: 1px hidden #66cc33; }

.border-b-success {
  border-bottom: 1px solid #66cc33; }

.border-b-success-dotted {
  border-bottom: 1px dotted #66cc33; }

.border-b-success-dashed {
  border-bottom: 1px dashed #66cc33; }

.border-b-success-solid {
  border-bottom: 1px solid #66cc33; }

.border-b-success-double {
  border-bottom: 1px double #66cc33; }

.border-b-success-groove {
  border-bottom: 1px groove #66cc33; }

.border-b-success-ridge {
  border-bottom: 1px ridge #66cc33; }

.border-b-success-inset {
  border-bottom: 1px inset #66cc33; }

.border-b-success-outset {
  border-bottom: 1px outset #66cc33; }

.border-b-success-none {
  border-bottom: 1px none #66cc33; }

.border-b-success-hidden {
  border-bottom: 1px hidden #66cc33; }

.border-l-success {
  border-left: 1px solid #66cc33; }

.border-l-success-dotted {
  border-left: 1px dotted #66cc33; }

.border-l-success-dashed {
  border-left: 1px dashed #66cc33; }

.border-l-success-solid {
  border-left: 1px solid #66cc33; }

.border-l-success-double {
  border-left: 1px double #66cc33; }

.border-l-success-groove {
  border-left: 1px groove #66cc33; }

.border-l-success-ridge {
  border-left: 1px ridge #66cc33; }

.border-l-success-inset {
  border-left: 1px inset #66cc33; }

.border-l-success-outset {
  border-left: 1px outset #66cc33; }

.border-l-success-none {
  border-left: 1px none #66cc33; }

.border-l-success-hidden {
  border-left: 1px hidden #66cc33; }

.has-text-success-light {
  color: #dbffe0; }

.has-background-success-light {
  background-color: #dbffe0; }

.border-success-light {
  border: 1px solid #dbffe0; }

.border-m-success-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dbffe0;
  transform: translateY(-50%); }

.border-success-light-dotted {
  border: 1px dotted #dbffe0; }

.border-success-light-dashed {
  border: 1px dashed #dbffe0; }

.border-success-light-solid {
  border: 1px solid #dbffe0; }

.border-success-light-double {
  border: 1px double #dbffe0; }

.border-success-light-groove {
  border: 1px groove #dbffe0; }

.border-success-light-ridge {
  border: 1px ridge #dbffe0; }

.border-success-light-inset {
  border: 1px inset #dbffe0; }

.border-success-light-outset {
  border: 1px outset #dbffe0; }

.border-success-light-none {
  border: 1px none #dbffe0; }

.border-success-light-hidden {
  border: 1px hidden #dbffe0; }

.border-t-success-light {
  border-top: 1px solid #dbffe0; }

.border-t-success-light-dotted {
  border-top: 1px dotted #dbffe0; }

.border-t-success-light-dashed {
  border-top: 1px dashed #dbffe0; }

.border-t-success-light-solid {
  border-top: 1px solid #dbffe0; }

.border-t-success-light-double {
  border-top: 1px double #dbffe0; }

.border-t-success-light-groove {
  border-top: 1px groove #dbffe0; }

.border-t-success-light-ridge {
  border-top: 1px ridge #dbffe0; }

.border-t-success-light-inset {
  border-top: 1px inset #dbffe0; }

.border-t-success-light-outset {
  border-top: 1px outset #dbffe0; }

.border-t-success-light-none {
  border-top: 1px none #dbffe0; }

.border-t-success-light-hidden {
  border-top: 1px hidden #dbffe0; }

.border-r-success-light {
  border-right: 1px solid #dbffe0; }

.border-r-success-light-dotted {
  border-right: 1px dotted #dbffe0; }

.border-r-success-light-dashed {
  border-right: 1px dashed #dbffe0; }

.border-r-success-light-solid {
  border-right: 1px solid #dbffe0; }

.border-r-success-light-double {
  border-right: 1px double #dbffe0; }

.border-r-success-light-groove {
  border-right: 1px groove #dbffe0; }

.border-r-success-light-ridge {
  border-right: 1px ridge #dbffe0; }

.border-r-success-light-inset {
  border-right: 1px inset #dbffe0; }

.border-r-success-light-outset {
  border-right: 1px outset #dbffe0; }

.border-r-success-light-none {
  border-right: 1px none #dbffe0; }

.border-r-success-light-hidden {
  border-right: 1px hidden #dbffe0; }

.border-b-success-light {
  border-bottom: 1px solid #dbffe0; }

.border-b-success-light-dotted {
  border-bottom: 1px dotted #dbffe0; }

.border-b-success-light-dashed {
  border-bottom: 1px dashed #dbffe0; }

.border-b-success-light-solid {
  border-bottom: 1px solid #dbffe0; }

.border-b-success-light-double {
  border-bottom: 1px double #dbffe0; }

.border-b-success-light-groove {
  border-bottom: 1px groove #dbffe0; }

.border-b-success-light-ridge {
  border-bottom: 1px ridge #dbffe0; }

.border-b-success-light-inset {
  border-bottom: 1px inset #dbffe0; }

.border-b-success-light-outset {
  border-bottom: 1px outset #dbffe0; }

.border-b-success-light-none {
  border-bottom: 1px none #dbffe0; }

.border-b-success-light-hidden {
  border-bottom: 1px hidden #dbffe0; }

.border-l-success-light {
  border-left: 1px solid #dbffe0; }

.border-l-success-light-dotted {
  border-left: 1px dotted #dbffe0; }

.border-l-success-light-dashed {
  border-left: 1px dashed #dbffe0; }

.border-l-success-light-solid {
  border-left: 1px solid #dbffe0; }

.border-l-success-light-double {
  border-left: 1px double #dbffe0; }

.border-l-success-light-groove {
  border-left: 1px groove #dbffe0; }

.border-l-success-light-ridge {
  border-left: 1px ridge #dbffe0; }

.border-l-success-light-inset {
  border-left: 1px inset #dbffe0; }

.border-l-success-light-outset {
  border-left: 1px outset #dbffe0; }

.border-l-success-light-none {
  border-left: 1px none #dbffe0; }

.border-l-success-light-hidden {
  border-left: 1px hidden #dbffe0; }

.has-text-info {
  color: #209fee; }

.has-background-info {
  background-color: #209fee; }

.border-info {
  border: 1px solid #209fee; }

.border-m-info:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #209fee;
  transform: translateY(-50%); }

.border-info-dotted {
  border: 1px dotted #209fee; }

.border-info-dashed {
  border: 1px dashed #209fee; }

.border-info-solid {
  border: 1px solid #209fee; }

.border-info-double {
  border: 1px double #209fee; }

.border-info-groove {
  border: 1px groove #209fee; }

.border-info-ridge {
  border: 1px ridge #209fee; }

.border-info-inset {
  border: 1px inset #209fee; }

.border-info-outset {
  border: 1px outset #209fee; }

.border-info-none {
  border: 1px none #209fee; }

.border-info-hidden {
  border: 1px hidden #209fee; }

.border-t-info {
  border-top: 1px solid #209fee; }

.border-t-info-dotted {
  border-top: 1px dotted #209fee; }

.border-t-info-dashed {
  border-top: 1px dashed #209fee; }

.border-t-info-solid {
  border-top: 1px solid #209fee; }

.border-t-info-double {
  border-top: 1px double #209fee; }

.border-t-info-groove {
  border-top: 1px groove #209fee; }

.border-t-info-ridge {
  border-top: 1px ridge #209fee; }

.border-t-info-inset {
  border-top: 1px inset #209fee; }

.border-t-info-outset {
  border-top: 1px outset #209fee; }

.border-t-info-none {
  border-top: 1px none #209fee; }

.border-t-info-hidden {
  border-top: 1px hidden #209fee; }

.border-r-info {
  border-right: 1px solid #209fee; }

.border-r-info-dotted {
  border-right: 1px dotted #209fee; }

.border-r-info-dashed {
  border-right: 1px dashed #209fee; }

.border-r-info-solid {
  border-right: 1px solid #209fee; }

.border-r-info-double {
  border-right: 1px double #209fee; }

.border-r-info-groove {
  border-right: 1px groove #209fee; }

.border-r-info-ridge {
  border-right: 1px ridge #209fee; }

.border-r-info-inset {
  border-right: 1px inset #209fee; }

.border-r-info-outset {
  border-right: 1px outset #209fee; }

.border-r-info-none {
  border-right: 1px none #209fee; }

.border-r-info-hidden {
  border-right: 1px hidden #209fee; }

.border-b-info {
  border-bottom: 1px solid #209fee; }

.border-b-info-dotted {
  border-bottom: 1px dotted #209fee; }

.border-b-info-dashed {
  border-bottom: 1px dashed #209fee; }

.border-b-info-solid {
  border-bottom: 1px solid #209fee; }

.border-b-info-double {
  border-bottom: 1px double #209fee; }

.border-b-info-groove {
  border-bottom: 1px groove #209fee; }

.border-b-info-ridge {
  border-bottom: 1px ridge #209fee; }

.border-b-info-inset {
  border-bottom: 1px inset #209fee; }

.border-b-info-outset {
  border-bottom: 1px outset #209fee; }

.border-b-info-none {
  border-bottom: 1px none #209fee; }

.border-b-info-hidden {
  border-bottom: 1px hidden #209fee; }

.border-l-info {
  border-left: 1px solid #209fee; }

.border-l-info-dotted {
  border-left: 1px dotted #209fee; }

.border-l-info-dashed {
  border-left: 1px dashed #209fee; }

.border-l-info-solid {
  border-left: 1px solid #209fee; }

.border-l-info-double {
  border-left: 1px double #209fee; }

.border-l-info-groove {
  border-left: 1px groove #209fee; }

.border-l-info-ridge {
  border-left: 1px ridge #209fee; }

.border-l-info-inset {
  border-left: 1px inset #209fee; }

.border-l-info-outset {
  border-left: 1px outset #209fee; }

.border-l-info-none {
  border-left: 1px none #209fee; }

.border-l-info-hidden {
  border-left: 1px hidden #209fee; }

.has-text-black {
  color: #0a0a0a; }

.has-background-black {
  background-color: #0a0a0a; }

.border-black {
  border: 1px solid #0a0a0a; }

.border-black-dotted {
  border: 1px dotted #0a0a0a; }

.border-black-dashed {
  border: 1px dashed #0a0a0a; }

.border-black-solid {
  border: 1px solid #0a0a0a; }

.border-black-double {
  border: 1px double #0a0a0a; }

.border-black-groove {
  border: 1px groove #0a0a0a; }

.border-black-ridge {
  border: 1px ridge #0a0a0a; }

.border-black-inset {
  border: 1px inset #0a0a0a; }

.border-black-outset {
  border: 1px outset #0a0a0a; }

.border-black-none {
  border: 1px none #0a0a0a; }

.border-black-hidden {
  border: 1px hidden #0a0a0a; }

.border-t-black {
  border-top: 1px solid #0a0a0a; }

.border-t-black-dotted {
  border-top: 1px dotted #0a0a0a; }

.border-t-black-dashed {
  border-top: 1px dashed #0a0a0a; }

.border-t-black-solid {
  border-top: 1px solid #0a0a0a; }

.border-t-black-double {
  border-top: 1px double #0a0a0a; }

.border-t-black-groove {
  border-top: 1px groove #0a0a0a; }

.border-t-black-ridge {
  border-top: 1px ridge #0a0a0a; }

.border-t-black-inset {
  border-top: 1px inset #0a0a0a; }

.border-t-black-outset {
  border-top: 1px outset #0a0a0a; }

.border-t-black-none {
  border-top: 1px none #0a0a0a; }

.border-t-black-hidden {
  border-top: 1px hidden #0a0a0a; }

.border-r-black {
  border-right: 1px solid #0a0a0a; }

.border-r-black-dotted {
  border-right: 1px dotted #0a0a0a; }

.border-r-black-dashed {
  border-right: 1px dashed #0a0a0a; }

.border-r-black-solid {
  border-right: 1px solid #0a0a0a; }

.border-r-black-double {
  border-right: 1px double #0a0a0a; }

.border-r-black-groove {
  border-right: 1px groove #0a0a0a; }

.border-r-black-ridge {
  border-right: 1px ridge #0a0a0a; }

.border-r-black-inset {
  border-right: 1px inset #0a0a0a; }

.border-r-black-outset {
  border-right: 1px outset #0a0a0a; }

.border-r-black-none {
  border-right: 1px none #0a0a0a; }

.border-r-black-hidden {
  border-right: 1px hidden #0a0a0a; }

.border-b-black {
  border-bottom: 1px solid #0a0a0a; }

.border-b-black-dotted {
  border-bottom: 1px dotted #0a0a0a; }

.border-b-black-dashed {
  border-bottom: 1px dashed #0a0a0a; }

.border-b-black-solid {
  border-bottom: 1px solid #0a0a0a; }

.border-b-black-double {
  border-bottom: 1px double #0a0a0a; }

.border-b-black-groove {
  border-bottom: 1px groove #0a0a0a; }

.border-b-black-ridge {
  border-bottom: 1px ridge #0a0a0a; }

.border-b-black-inset {
  border-bottom: 1px inset #0a0a0a; }

.border-b-black-outset {
  border-bottom: 1px outset #0a0a0a; }

.border-b-black-none {
  border-bottom: 1px none #0a0a0a; }

.border-b-black-hidden {
  border-bottom: 1px hidden #0a0a0a; }

.border-l-black {
  border-left: 1px solid #0a0a0a; }

.border-l-black-dotted {
  border-left: 1px dotted #0a0a0a; }

.border-l-black-dashed {
  border-left: 1px dashed #0a0a0a; }

.border-l-black-solid {
  border-left: 1px solid #0a0a0a; }

.border-l-black-double {
  border-left: 1px double #0a0a0a; }

.border-l-black-groove {
  border-left: 1px groove #0a0a0a; }

.border-l-black-ridge {
  border-left: 1px ridge #0a0a0a; }

.border-l-black-inset {
  border-left: 1px inset #0a0a0a; }

.border-l-black-outset {
  border-left: 1px outset #0a0a0a; }

.border-l-black-none {
  border-left: 1px none #0a0a0a; }

.border-l-black-hidden {
  border-left: 1px hidden #0a0a0a; }

.has-text-black-bis {
  color: #121212; }

.has-background-black-bis {
  background-color: #121212; }

.border-black-bis {
  border: 1px solid #121212; }

.border-black-bis-dotted {
  border: 1px dotted #121212; }

.border-black-bis-dashed {
  border: 1px dashed #121212; }

.border-black-bis-solid {
  border: 1px solid #121212; }

.border-black-bis-double {
  border: 1px double #121212; }

.border-black-bis-groove {
  border: 1px groove #121212; }

.border-black-bis-ridge {
  border: 1px ridge #121212; }

.border-black-bis-inset {
  border: 1px inset #121212; }

.border-black-bis-outset {
  border: 1px outset #121212; }

.border-black-bis-none {
  border: 1px none #121212; }

.border-black-bis-hidden {
  border: 1px hidden #121212; }

.border-t-black-bis {
  border-top: 1px solid #121212; }

.border-t-black-bis-dotted {
  border-top: 1px dotted #121212; }

.border-t-black-bis-dashed {
  border-top: 1px dashed #121212; }

.border-t-black-bis-solid {
  border-top: 1px solid #121212; }

.border-t-black-bis-double {
  border-top: 1px double #121212; }

.border-t-black-bis-groove {
  border-top: 1px groove #121212; }

.border-t-black-bis-ridge {
  border-top: 1px ridge #121212; }

.border-t-black-bis-inset {
  border-top: 1px inset #121212; }

.border-t-black-bis-outset {
  border-top: 1px outset #121212; }

.border-t-black-bis-none {
  border-top: 1px none #121212; }

.border-t-black-bis-hidden {
  border-top: 1px hidden #121212; }

.border-r-black-bis {
  border-right: 1px solid #121212; }

.border-r-black-bis-dotted {
  border-right: 1px dotted #121212; }

.border-r-black-bis-dashed {
  border-right: 1px dashed #121212; }

.border-r-black-bis-solid {
  border-right: 1px solid #121212; }

.border-r-black-bis-double {
  border-right: 1px double #121212; }

.border-r-black-bis-groove {
  border-right: 1px groove #121212; }

.border-r-black-bis-ridge {
  border-right: 1px ridge #121212; }

.border-r-black-bis-inset {
  border-right: 1px inset #121212; }

.border-r-black-bis-outset {
  border-right: 1px outset #121212; }

.border-r-black-bis-none {
  border-right: 1px none #121212; }

.border-r-black-bis-hidden {
  border-right: 1px hidden #121212; }

.border-b-black-bis {
  border-bottom: 1px solid #121212; }

.border-b-black-bis-dotted {
  border-bottom: 1px dotted #121212; }

.border-b-black-bis-dashed {
  border-bottom: 1px dashed #121212; }

.border-b-black-bis-solid {
  border-bottom: 1px solid #121212; }

.border-b-black-bis-double {
  border-bottom: 1px double #121212; }

.border-b-black-bis-groove {
  border-bottom: 1px groove #121212; }

.border-b-black-bis-ridge {
  border-bottom: 1px ridge #121212; }

.border-b-black-bis-inset {
  border-bottom: 1px inset #121212; }

.border-b-black-bis-outset {
  border-bottom: 1px outset #121212; }

.border-b-black-bis-none {
  border-bottom: 1px none #121212; }

.border-b-black-bis-hidden {
  border-bottom: 1px hidden #121212; }

.border-l-black-bis {
  border-left: 1px solid #121212; }

.border-l-black-bis-dotted {
  border-left: 1px dotted #121212; }

.border-l-black-bis-dashed {
  border-left: 1px dashed #121212; }

.border-l-black-bis-solid {
  border-left: 1px solid #121212; }

.border-l-black-bis-double {
  border-left: 1px double #121212; }

.border-l-black-bis-groove {
  border-left: 1px groove #121212; }

.border-l-black-bis-ridge {
  border-left: 1px ridge #121212; }

.border-l-black-bis-inset {
  border-left: 1px inset #121212; }

.border-l-black-bis-outset {
  border-left: 1px outset #121212; }

.border-l-black-bis-none {
  border-left: 1px none #121212; }

.border-l-black-bis-hidden {
  border-left: 1px hidden #121212; }

.has-text-black-ter {
  color: #242424; }

.has-background-black-ter {
  background-color: #242424; }

.border-black-ter {
  border: 1px solid #242424; }

.border-black-ter-dotted {
  border: 1px dotted #242424; }

.border-black-ter-dashed {
  border: 1px dashed #242424; }

.border-black-ter-solid {
  border: 1px solid #242424; }

.border-black-ter-double {
  border: 1px double #242424; }

.border-black-ter-groove {
  border: 1px groove #242424; }

.border-black-ter-ridge {
  border: 1px ridge #242424; }

.border-black-ter-inset {
  border: 1px inset #242424; }

.border-black-ter-outset {
  border: 1px outset #242424; }

.border-black-ter-none {
  border: 1px none #242424; }

.border-black-ter-hidden {
  border: 1px hidden #242424; }

.border-t-black-ter {
  border-top: 1px solid #242424; }

.border-t-black-ter-dotted {
  border-top: 1px dotted #242424; }

.border-t-black-ter-dashed {
  border-top: 1px dashed #242424; }

.border-t-black-ter-solid {
  border-top: 1px solid #242424; }

.border-t-black-ter-double {
  border-top: 1px double #242424; }

.border-t-black-ter-groove {
  border-top: 1px groove #242424; }

.border-t-black-ter-ridge {
  border-top: 1px ridge #242424; }

.border-t-black-ter-inset {
  border-top: 1px inset #242424; }

.border-t-black-ter-outset {
  border-top: 1px outset #242424; }

.border-t-black-ter-none {
  border-top: 1px none #242424; }

.border-t-black-ter-hidden {
  border-top: 1px hidden #242424; }

.border-r-black-ter {
  border-right: 1px solid #242424; }

.border-r-black-ter-dotted {
  border-right: 1px dotted #242424; }

.border-r-black-ter-dashed {
  border-right: 1px dashed #242424; }

.border-r-black-ter-solid {
  border-right: 1px solid #242424; }

.border-r-black-ter-double {
  border-right: 1px double #242424; }

.border-r-black-ter-groove {
  border-right: 1px groove #242424; }

.border-r-black-ter-ridge {
  border-right: 1px ridge #242424; }

.border-r-black-ter-inset {
  border-right: 1px inset #242424; }

.border-r-black-ter-outset {
  border-right: 1px outset #242424; }

.border-r-black-ter-none {
  border-right: 1px none #242424; }

.border-r-black-ter-hidden {
  border-right: 1px hidden #242424; }

.border-b-black-ter {
  border-bottom: 1px solid #242424; }

.border-b-black-ter-dotted {
  border-bottom: 1px dotted #242424; }

.border-b-black-ter-dashed {
  border-bottom: 1px dashed #242424; }

.border-b-black-ter-solid {
  border-bottom: 1px solid #242424; }

.border-b-black-ter-double {
  border-bottom: 1px double #242424; }

.border-b-black-ter-groove {
  border-bottom: 1px groove #242424; }

.border-b-black-ter-ridge {
  border-bottom: 1px ridge #242424; }

.border-b-black-ter-inset {
  border-bottom: 1px inset #242424; }

.border-b-black-ter-outset {
  border-bottom: 1px outset #242424; }

.border-b-black-ter-none {
  border-bottom: 1px none #242424; }

.border-b-black-ter-hidden {
  border-bottom: 1px hidden #242424; }

.border-l-black-ter {
  border-left: 1px solid #242424; }

.border-l-black-ter-dotted {
  border-left: 1px dotted #242424; }

.border-l-black-ter-dashed {
  border-left: 1px dashed #242424; }

.border-l-black-ter-solid {
  border-left: 1px solid #242424; }

.border-l-black-ter-double {
  border-left: 1px double #242424; }

.border-l-black-ter-groove {
  border-left: 1px groove #242424; }

.border-l-black-ter-ridge {
  border-left: 1px ridge #242424; }

.border-l-black-ter-inset {
  border-left: 1px inset #242424; }

.border-l-black-ter-outset {
  border-left: 1px outset #242424; }

.border-l-black-ter-none {
  border-left: 1px none #242424; }

.border-l-black-ter-hidden {
  border-left: 1px hidden #242424; }

.has-text-grey-darker {
  color: #363636; }

.has-background-grey-darker {
  background-color: #363636; }

.border-grey-darker {
  border: 1px solid #363636; }

.border-grey-darker-dotted {
  border: 1px dotted #363636; }

.border-grey-darker-dashed {
  border: 1px dashed #363636; }

.border-grey-darker-solid {
  border: 1px solid #363636; }

.border-grey-darker-double {
  border: 1px double #363636; }

.border-grey-darker-groove {
  border: 1px groove #363636; }

.border-grey-darker-ridge {
  border: 1px ridge #363636; }

.border-grey-darker-inset {
  border: 1px inset #363636; }

.border-grey-darker-outset {
  border: 1px outset #363636; }

.border-grey-darker-none {
  border: 1px none #363636; }

.border-grey-darker-hidden {
  border: 1px hidden #363636; }

.border-t-grey-darker {
  border-top: 1px solid #363636; }

.border-t-grey-darker-dotted {
  border-top: 1px dotted #363636; }

.border-t-grey-darker-dashed {
  border-top: 1px dashed #363636; }

.border-t-grey-darker-solid {
  border-top: 1px solid #363636; }

.border-t-grey-darker-double {
  border-top: 1px double #363636; }

.border-t-grey-darker-groove {
  border-top: 1px groove #363636; }

.border-t-grey-darker-ridge {
  border-top: 1px ridge #363636; }

.border-t-grey-darker-inset {
  border-top: 1px inset #363636; }

.border-t-grey-darker-outset {
  border-top: 1px outset #363636; }

.border-t-grey-darker-none {
  border-top: 1px none #363636; }

.border-t-grey-darker-hidden {
  border-top: 1px hidden #363636; }

.border-r-grey-darker {
  border-right: 1px solid #363636; }

.border-r-grey-darker-dotted {
  border-right: 1px dotted #363636; }

.border-r-grey-darker-dashed {
  border-right: 1px dashed #363636; }

.border-r-grey-darker-solid {
  border-right: 1px solid #363636; }

.border-r-grey-darker-double {
  border-right: 1px double #363636; }

.border-r-grey-darker-groove {
  border-right: 1px groove #363636; }

.border-r-grey-darker-ridge {
  border-right: 1px ridge #363636; }

.border-r-grey-darker-inset {
  border-right: 1px inset #363636; }

.border-r-grey-darker-outset {
  border-right: 1px outset #363636; }

.border-r-grey-darker-none {
  border-right: 1px none #363636; }

.border-r-grey-darker-hidden {
  border-right: 1px hidden #363636; }

.border-b-grey-darker {
  border-bottom: 1px solid #363636; }

.border-b-grey-darker-dotted {
  border-bottom: 1px dotted #363636; }

.border-b-grey-darker-dashed {
  border-bottom: 1px dashed #363636; }

.border-b-grey-darker-solid {
  border-bottom: 1px solid #363636; }

.border-b-grey-darker-double {
  border-bottom: 1px double #363636; }

.border-b-grey-darker-groove {
  border-bottom: 1px groove #363636; }

.border-b-grey-darker-ridge {
  border-bottom: 1px ridge #363636; }

.border-b-grey-darker-inset {
  border-bottom: 1px inset #363636; }

.border-b-grey-darker-outset {
  border-bottom: 1px outset #363636; }

.border-b-grey-darker-none {
  border-bottom: 1px none #363636; }

.border-b-grey-darker-hidden {
  border-bottom: 1px hidden #363636; }

.border-l-grey-darker {
  border-left: 1px solid #363636; }

.border-l-grey-darker-dotted {
  border-left: 1px dotted #363636; }

.border-l-grey-darker-dashed {
  border-left: 1px dashed #363636; }

.border-l-grey-darker-solid {
  border-left: 1px solid #363636; }

.border-l-grey-darker-double {
  border-left: 1px double #363636; }

.border-l-grey-darker-groove {
  border-left: 1px groove #363636; }

.border-l-grey-darker-ridge {
  border-left: 1px ridge #363636; }

.border-l-grey-darker-inset {
  border-left: 1px inset #363636; }

.border-l-grey-darker-outset {
  border-left: 1px outset #363636; }

.border-l-grey-darker-none {
  border-left: 1px none #363636; }

.border-l-grey-darker-hidden {
  border-left: 1px hidden #363636; }

.has-text-grey-dark {
  color: #4a4a4a; }

.has-background-grey-dark {
  background-color: #4a4a4a; }

.border-grey-dark {
  border: 1px solid #4a4a4a; }

.border-grey-dark-dotted {
  border: 1px dotted #4a4a4a; }

.border-grey-dark-dashed {
  border: 1px dashed #4a4a4a; }

.border-grey-dark-solid {
  border: 1px solid #4a4a4a; }

.border-grey-dark-double {
  border: 1px double #4a4a4a; }

.border-grey-dark-groove {
  border: 1px groove #4a4a4a; }

.border-grey-dark-ridge {
  border: 1px ridge #4a4a4a; }

.border-grey-dark-inset {
  border: 1px inset #4a4a4a; }

.border-grey-dark-outset {
  border: 1px outset #4a4a4a; }

.border-grey-dark-none {
  border: 1px none #4a4a4a; }

.border-grey-dark-hidden {
  border: 1px hidden #4a4a4a; }

.border-t-grey-dark {
  border-top: 1px solid #4a4a4a; }

.border-t-grey-dark-dotted {
  border-top: 1px dotted #4a4a4a; }

.border-t-grey-dark-dashed {
  border-top: 1px dashed #4a4a4a; }

.border-t-grey-dark-solid {
  border-top: 1px solid #4a4a4a; }

.border-t-grey-dark-double {
  border-top: 1px double #4a4a4a; }

.border-t-grey-dark-groove {
  border-top: 1px groove #4a4a4a; }

.border-t-grey-dark-ridge {
  border-top: 1px ridge #4a4a4a; }

.border-t-grey-dark-inset {
  border-top: 1px inset #4a4a4a; }

.border-t-grey-dark-outset {
  border-top: 1px outset #4a4a4a; }

.border-t-grey-dark-none {
  border-top: 1px none #4a4a4a; }

.border-t-grey-dark-hidden {
  border-top: 1px hidden #4a4a4a; }

.border-r-grey-dark {
  border-right: 1px solid #4a4a4a; }

.border-r-grey-dark-dotted {
  border-right: 1px dotted #4a4a4a; }

.border-r-grey-dark-dashed {
  border-right: 1px dashed #4a4a4a; }

.border-r-grey-dark-solid {
  border-right: 1px solid #4a4a4a; }

.border-r-grey-dark-double {
  border-right: 1px double #4a4a4a; }

.border-r-grey-dark-groove {
  border-right: 1px groove #4a4a4a; }

.border-r-grey-dark-ridge {
  border-right: 1px ridge #4a4a4a; }

.border-r-grey-dark-inset {
  border-right: 1px inset #4a4a4a; }

.border-r-grey-dark-outset {
  border-right: 1px outset #4a4a4a; }

.border-r-grey-dark-none {
  border-right: 1px none #4a4a4a; }

.border-r-grey-dark-hidden {
  border-right: 1px hidden #4a4a4a; }

.border-b-grey-dark {
  border-bottom: 1px solid #4a4a4a; }

.border-b-grey-dark-dotted {
  border-bottom: 1px dotted #4a4a4a; }

.border-b-grey-dark-dashed {
  border-bottom: 1px dashed #4a4a4a; }

.border-b-grey-dark-solid {
  border-bottom: 1px solid #4a4a4a; }

.border-b-grey-dark-double {
  border-bottom: 1px double #4a4a4a; }

.border-b-grey-dark-groove {
  border-bottom: 1px groove #4a4a4a; }

.border-b-grey-dark-ridge {
  border-bottom: 1px ridge #4a4a4a; }

.border-b-grey-dark-inset {
  border-bottom: 1px inset #4a4a4a; }

.border-b-grey-dark-outset {
  border-bottom: 1px outset #4a4a4a; }

.border-b-grey-dark-none {
  border-bottom: 1px none #4a4a4a; }

.border-b-grey-dark-hidden {
  border-bottom: 1px hidden #4a4a4a; }

.border-l-grey-dark {
  border-left: 1px solid #4a4a4a; }

.border-l-grey-dark-dotted {
  border-left: 1px dotted #4a4a4a; }

.border-l-grey-dark-dashed {
  border-left: 1px dashed #4a4a4a; }

.border-l-grey-dark-solid {
  border-left: 1px solid #4a4a4a; }

.border-l-grey-dark-double {
  border-left: 1px double #4a4a4a; }

.border-l-grey-dark-groove {
  border-left: 1px groove #4a4a4a; }

.border-l-grey-dark-ridge {
  border-left: 1px ridge #4a4a4a; }

.border-l-grey-dark-inset {
  border-left: 1px inset #4a4a4a; }

.border-l-grey-dark-outset {
  border-left: 1px outset #4a4a4a; }

.border-l-grey-dark-none {
  border-left: 1px none #4a4a4a; }

.border-l-grey-dark-hidden {
  border-left: 1px hidden #4a4a4a; }

.has-text-grey {
  color: #7a7a7a; }

.has-background-grey {
  background-color: #7a7a7a; }

.border-grey {
  border: 1px solid #7a7a7a; }

.border-grey-dotted {
  border: 1px dotted #7a7a7a; }

.border-grey-dashed {
  border: 1px dashed #7a7a7a; }

.border-grey-solid {
  border: 1px solid #7a7a7a; }

.border-grey-double {
  border: 1px double #7a7a7a; }

.border-grey-groove {
  border: 1px groove #7a7a7a; }

.border-grey-ridge {
  border: 1px ridge #7a7a7a; }

.border-grey-inset {
  border: 1px inset #7a7a7a; }

.border-grey-outset {
  border: 1px outset #7a7a7a; }

.border-grey-none {
  border: 1px none #7a7a7a; }

.border-grey-hidden {
  border: 1px hidden #7a7a7a; }

.border-t-grey {
  border-top: 1px solid #7a7a7a; }

.border-t-grey-dotted {
  border-top: 1px dotted #7a7a7a; }

.border-t-grey-dashed {
  border-top: 1px dashed #7a7a7a; }

.border-t-grey-solid {
  border-top: 1px solid #7a7a7a; }

.border-t-grey-double {
  border-top: 1px double #7a7a7a; }

.border-t-grey-groove {
  border-top: 1px groove #7a7a7a; }

.border-t-grey-ridge {
  border-top: 1px ridge #7a7a7a; }

.border-t-grey-inset {
  border-top: 1px inset #7a7a7a; }

.border-t-grey-outset {
  border-top: 1px outset #7a7a7a; }

.border-t-grey-none {
  border-top: 1px none #7a7a7a; }

.border-t-grey-hidden {
  border-top: 1px hidden #7a7a7a; }

.border-r-grey {
  border-right: 1px solid #7a7a7a; }

.border-r-grey-dotted {
  border-right: 1px dotted #7a7a7a; }

.border-r-grey-dashed {
  border-right: 1px dashed #7a7a7a; }

.border-r-grey-solid {
  border-right: 1px solid #7a7a7a; }

.border-r-grey-double {
  border-right: 1px double #7a7a7a; }

.border-r-grey-groove {
  border-right: 1px groove #7a7a7a; }

.border-r-grey-ridge {
  border-right: 1px ridge #7a7a7a; }

.border-r-grey-inset {
  border-right: 1px inset #7a7a7a; }

.border-r-grey-outset {
  border-right: 1px outset #7a7a7a; }

.border-r-grey-none {
  border-right: 1px none #7a7a7a; }

.border-r-grey-hidden {
  border-right: 1px hidden #7a7a7a; }

.border-b-grey {
  border-bottom: 1px solid #7a7a7a; }

.border-b-grey-dotted {
  border-bottom: 1px dotted #7a7a7a; }

.border-b-grey-dashed {
  border-bottom: 1px dashed #7a7a7a; }

.border-b-grey-solid {
  border-bottom: 1px solid #7a7a7a; }

.border-b-grey-double {
  border-bottom: 1px double #7a7a7a; }

.border-b-grey-groove {
  border-bottom: 1px groove #7a7a7a; }

.border-b-grey-ridge {
  border-bottom: 1px ridge #7a7a7a; }

.border-b-grey-inset {
  border-bottom: 1px inset #7a7a7a; }

.border-b-grey-outset {
  border-bottom: 1px outset #7a7a7a; }

.border-b-grey-none {
  border-bottom: 1px none #7a7a7a; }

.border-b-grey-hidden {
  border-bottom: 1px hidden #7a7a7a; }

.border-l-grey {
  border-left: 1px solid #7a7a7a; }

.border-l-grey-dotted {
  border-left: 1px dotted #7a7a7a; }

.border-l-grey-dashed {
  border-left: 1px dashed #7a7a7a; }

.border-l-grey-solid {
  border-left: 1px solid #7a7a7a; }

.border-l-grey-double {
  border-left: 1px double #7a7a7a; }

.border-l-grey-groove {
  border-left: 1px groove #7a7a7a; }

.border-l-grey-ridge {
  border-left: 1px ridge #7a7a7a; }

.border-l-grey-inset {
  border-left: 1px inset #7a7a7a; }

.border-l-grey-outset {
  border-left: 1px outset #7a7a7a; }

.border-l-grey-none {
  border-left: 1px none #7a7a7a; }

.border-l-grey-hidden {
  border-left: 1px hidden #7a7a7a; }

.has-text-grey-light {
  color: #b5b5b5; }

.has-background-grey-light {
  background-color: #b5b5b5; }

.border-grey-light {
  border: 1px solid #b5b5b5; }

.border-grey-light-dotted {
  border: 1px dotted #b5b5b5; }

.border-grey-light-dashed {
  border: 1px dashed #b5b5b5; }

.border-grey-light-solid {
  border: 1px solid #b5b5b5; }

.border-grey-light-double {
  border: 1px double #b5b5b5; }

.border-grey-light-groove {
  border: 1px groove #b5b5b5; }

.border-grey-light-ridge {
  border: 1px ridge #b5b5b5; }

.border-grey-light-inset {
  border: 1px inset #b5b5b5; }

.border-grey-light-outset {
  border: 1px outset #b5b5b5; }

.border-grey-light-none {
  border: 1px none #b5b5b5; }

.border-grey-light-hidden {
  border: 1px hidden #b5b5b5; }

.border-t-grey-light {
  border-top: 1px solid #b5b5b5; }

.border-t-grey-light-dotted {
  border-top: 1px dotted #b5b5b5; }

.border-t-grey-light-dashed {
  border-top: 1px dashed #b5b5b5; }

.border-t-grey-light-solid {
  border-top: 1px solid #b5b5b5; }

.border-t-grey-light-double {
  border-top: 1px double #b5b5b5; }

.border-t-grey-light-groove {
  border-top: 1px groove #b5b5b5; }

.border-t-grey-light-ridge {
  border-top: 1px ridge #b5b5b5; }

.border-t-grey-light-inset {
  border-top: 1px inset #b5b5b5; }

.border-t-grey-light-outset {
  border-top: 1px outset #b5b5b5; }

.border-t-grey-light-none {
  border-top: 1px none #b5b5b5; }

.border-t-grey-light-hidden {
  border-top: 1px hidden #b5b5b5; }

.border-r-grey-light {
  border-right: 1px solid #b5b5b5; }

.border-r-grey-light-dotted {
  border-right: 1px dotted #b5b5b5; }

.border-r-grey-light-dashed {
  border-right: 1px dashed #b5b5b5; }

.border-r-grey-light-solid {
  border-right: 1px solid #b5b5b5; }

.border-r-grey-light-double {
  border-right: 1px double #b5b5b5; }

.border-r-grey-light-groove {
  border-right: 1px groove #b5b5b5; }

.border-r-grey-light-ridge {
  border-right: 1px ridge #b5b5b5; }

.border-r-grey-light-inset {
  border-right: 1px inset #b5b5b5; }

.border-r-grey-light-outset {
  border-right: 1px outset #b5b5b5; }

.border-r-grey-light-none {
  border-right: 1px none #b5b5b5; }

.border-r-grey-light-hidden {
  border-right: 1px hidden #b5b5b5; }

.border-b-grey-light {
  border-bottom: 1px solid #b5b5b5; }

.border-b-grey-light-dotted {
  border-bottom: 1px dotted #b5b5b5; }

.border-b-grey-light-dashed {
  border-bottom: 1px dashed #b5b5b5; }

.border-b-grey-light-solid {
  border-bottom: 1px solid #b5b5b5; }

.border-b-grey-light-double {
  border-bottom: 1px double #b5b5b5; }

.border-b-grey-light-groove {
  border-bottom: 1px groove #b5b5b5; }

.border-b-grey-light-ridge {
  border-bottom: 1px ridge #b5b5b5; }

.border-b-grey-light-inset {
  border-bottom: 1px inset #b5b5b5; }

.border-b-grey-light-outset {
  border-bottom: 1px outset #b5b5b5; }

.border-b-grey-light-none {
  border-bottom: 1px none #b5b5b5; }

.border-b-grey-light-hidden {
  border-bottom: 1px hidden #b5b5b5; }

.border-l-grey-light {
  border-left: 1px solid #b5b5b5; }

.border-l-grey-light-dotted {
  border-left: 1px dotted #b5b5b5; }

.border-l-grey-light-dashed {
  border-left: 1px dashed #b5b5b5; }

.border-l-grey-light-solid {
  border-left: 1px solid #b5b5b5; }

.border-l-grey-light-double {
  border-left: 1px double #b5b5b5; }

.border-l-grey-light-groove {
  border-left: 1px groove #b5b5b5; }

.border-l-grey-light-ridge {
  border-left: 1px ridge #b5b5b5; }

.border-l-grey-light-inset {
  border-left: 1px inset #b5b5b5; }

.border-l-grey-light-outset {
  border-left: 1px outset #b5b5b5; }

.border-l-grey-light-none {
  border-left: 1px none #b5b5b5; }

.border-l-grey-light-hidden {
  border-left: 1px hidden #b5b5b5; }

.has-text-grey-lighter {
  color: #e6e6e6; }

.has-background-grey-lighter {
  background-color: #e6e6e6; }

.border-grey-lighter {
  border: 1px solid #e6e6e6; }

.border-grey-lighter-dotted {
  border: 1px dotted #e6e6e6; }

.border-grey-lighter-dashed {
  border: 1px dashed #e6e6e6; }

.border-grey-lighter-solid {
  border: 1px solid #e6e6e6; }

.border-grey-lighter-double {
  border: 1px double #e6e6e6; }

.border-grey-lighter-groove {
  border: 1px groove #e6e6e6; }

.border-grey-lighter-ridge {
  border: 1px ridge #e6e6e6; }

.border-grey-lighter-inset {
  border: 1px inset #e6e6e6; }

.border-grey-lighter-outset {
  border: 1px outset #e6e6e6; }

.border-grey-lighter-none {
  border: 1px none #e6e6e6; }

.border-grey-lighter-hidden {
  border: 1px hidden #e6e6e6; }

.border-t-grey-lighter {
  border-top: 1px solid #e6e6e6; }

.border-t-grey-lighter-dotted {
  border-top: 1px dotted #e6e6e6; }

.border-t-grey-lighter-dashed {
  border-top: 1px dashed #e6e6e6; }

.border-t-grey-lighter-solid {
  border-top: 1px solid #e6e6e6; }

.border-t-grey-lighter-double {
  border-top: 1px double #e6e6e6; }

.border-t-grey-lighter-groove {
  border-top: 1px groove #e6e6e6; }

.border-t-grey-lighter-ridge {
  border-top: 1px ridge #e6e6e6; }

.border-t-grey-lighter-inset {
  border-top: 1px inset #e6e6e6; }

.border-t-grey-lighter-outset {
  border-top: 1px outset #e6e6e6; }

.border-t-grey-lighter-none {
  border-top: 1px none #e6e6e6; }

.border-t-grey-lighter-hidden {
  border-top: 1px hidden #e6e6e6; }

.border-r-grey-lighter {
  border-right: 1px solid #e6e6e6; }

.border-r-grey-lighter-dotted {
  border-right: 1px dotted #e6e6e6; }

.border-r-grey-lighter-dashed {
  border-right: 1px dashed #e6e6e6; }

.border-r-grey-lighter-solid {
  border-right: 1px solid #e6e6e6; }

.border-r-grey-lighter-double {
  border-right: 1px double #e6e6e6; }

.border-r-grey-lighter-groove {
  border-right: 1px groove #e6e6e6; }

.border-r-grey-lighter-ridge {
  border-right: 1px ridge #e6e6e6; }

.border-r-grey-lighter-inset {
  border-right: 1px inset #e6e6e6; }

.border-r-grey-lighter-outset {
  border-right: 1px outset #e6e6e6; }

.border-r-grey-lighter-none {
  border-right: 1px none #e6e6e6; }

.border-r-grey-lighter-hidden {
  border-right: 1px hidden #e6e6e6; }

.border-b-grey-lighter {
  border-bottom: 1px solid #e6e6e6; }

.border-b-grey-lighter-dotted {
  border-bottom: 1px dotted #e6e6e6; }

.border-b-grey-lighter-dashed {
  border-bottom: 1px dashed #e6e6e6; }

.border-b-grey-lighter-solid {
  border-bottom: 1px solid #e6e6e6; }

.border-b-grey-lighter-double {
  border-bottom: 1px double #e6e6e6; }

.border-b-grey-lighter-groove {
  border-bottom: 1px groove #e6e6e6; }

.border-b-grey-lighter-ridge {
  border-bottom: 1px ridge #e6e6e6; }

.border-b-grey-lighter-inset {
  border-bottom: 1px inset #e6e6e6; }

.border-b-grey-lighter-outset {
  border-bottom: 1px outset #e6e6e6; }

.border-b-grey-lighter-none {
  border-bottom: 1px none #e6e6e6; }

.border-b-grey-lighter-hidden {
  border-bottom: 1px hidden #e6e6e6; }

.border-l-grey-lighter {
  border-left: 1px solid #e6e6e6; }

.border-l-grey-lighter-dotted {
  border-left: 1px dotted #e6e6e6; }

.border-l-grey-lighter-dashed {
  border-left: 1px dashed #e6e6e6; }

.border-l-grey-lighter-solid {
  border-left: 1px solid #e6e6e6; }

.border-l-grey-lighter-double {
  border-left: 1px double #e6e6e6; }

.border-l-grey-lighter-groove {
  border-left: 1px groove #e6e6e6; }

.border-l-grey-lighter-ridge {
  border-left: 1px ridge #e6e6e6; }

.border-l-grey-lighter-inset {
  border-left: 1px inset #e6e6e6; }

.border-l-grey-lighter-outset {
  border-left: 1px outset #e6e6e6; }

.border-l-grey-lighter-none {
  border-left: 1px none #e6e6e6; }

.border-l-grey-lighter-hidden {
  border-left: 1px hidden #e6e6e6; }

.has-text-white-ter {
  color: whitesmoke; }

.has-background-white-ter {
  background-color: whitesmoke; }

.border-white-ter {
  border: 1px solid whitesmoke; }

.border-white-ter-dotted {
  border: 1px dotted whitesmoke; }

.border-white-ter-dashed {
  border: 1px dashed whitesmoke; }

.border-white-ter-solid {
  border: 1px solid whitesmoke; }

.border-white-ter-double {
  border: 1px double whitesmoke; }

.border-white-ter-groove {
  border: 1px groove whitesmoke; }

.border-white-ter-ridge {
  border: 1px ridge whitesmoke; }

.border-white-ter-inset {
  border: 1px inset whitesmoke; }

.border-white-ter-outset {
  border: 1px outset whitesmoke; }

.border-white-ter-none {
  border: 1px none whitesmoke; }

.border-white-ter-hidden {
  border: 1px hidden whitesmoke; }

.border-t-white-ter {
  border-top: 1px solid whitesmoke; }

.border-t-white-ter-dotted {
  border-top: 1px dotted whitesmoke; }

.border-t-white-ter-dashed {
  border-top: 1px dashed whitesmoke; }

.border-t-white-ter-solid {
  border-top: 1px solid whitesmoke; }

.border-t-white-ter-double {
  border-top: 1px double whitesmoke; }

.border-t-white-ter-groove {
  border-top: 1px groove whitesmoke; }

.border-t-white-ter-ridge {
  border-top: 1px ridge whitesmoke; }

.border-t-white-ter-inset {
  border-top: 1px inset whitesmoke; }

.border-t-white-ter-outset {
  border-top: 1px outset whitesmoke; }

.border-t-white-ter-none {
  border-top: 1px none whitesmoke; }

.border-t-white-ter-hidden {
  border-top: 1px hidden whitesmoke; }

.border-r-white-ter {
  border-right: 1px solid whitesmoke; }

.border-r-white-ter-dotted {
  border-right: 1px dotted whitesmoke; }

.border-r-white-ter-dashed {
  border-right: 1px dashed whitesmoke; }

.border-r-white-ter-solid {
  border-right: 1px solid whitesmoke; }

.border-r-white-ter-double {
  border-right: 1px double whitesmoke; }

.border-r-white-ter-groove {
  border-right: 1px groove whitesmoke; }

.border-r-white-ter-ridge {
  border-right: 1px ridge whitesmoke; }

.border-r-white-ter-inset {
  border-right: 1px inset whitesmoke; }

.border-r-white-ter-outset {
  border-right: 1px outset whitesmoke; }

.border-r-white-ter-none {
  border-right: 1px none whitesmoke; }

.border-r-white-ter-hidden {
  border-right: 1px hidden whitesmoke; }

.border-b-white-ter {
  border-bottom: 1px solid whitesmoke; }

.border-b-white-ter-dotted {
  border-bottom: 1px dotted whitesmoke; }

.border-b-white-ter-dashed {
  border-bottom: 1px dashed whitesmoke; }

.border-b-white-ter-solid {
  border-bottom: 1px solid whitesmoke; }

.border-b-white-ter-double {
  border-bottom: 1px double whitesmoke; }

.border-b-white-ter-groove {
  border-bottom: 1px groove whitesmoke; }

.border-b-white-ter-ridge {
  border-bottom: 1px ridge whitesmoke; }

.border-b-white-ter-inset {
  border-bottom: 1px inset whitesmoke; }

.border-b-white-ter-outset {
  border-bottom: 1px outset whitesmoke; }

.border-b-white-ter-none {
  border-bottom: 1px none whitesmoke; }

.border-b-white-ter-hidden {
  border-bottom: 1px hidden whitesmoke; }

.border-l-white-ter {
  border-left: 1px solid whitesmoke; }

.border-l-white-ter-dotted {
  border-left: 1px dotted whitesmoke; }

.border-l-white-ter-dashed {
  border-left: 1px dashed whitesmoke; }

.border-l-white-ter-solid {
  border-left: 1px solid whitesmoke; }

.border-l-white-ter-double {
  border-left: 1px double whitesmoke; }

.border-l-white-ter-groove {
  border-left: 1px groove whitesmoke; }

.border-l-white-ter-ridge {
  border-left: 1px ridge whitesmoke; }

.border-l-white-ter-inset {
  border-left: 1px inset whitesmoke; }

.border-l-white-ter-outset {
  border-left: 1px outset whitesmoke; }

.border-l-white-ter-none {
  border-left: 1px none whitesmoke; }

.border-l-white-ter-hidden {
  border-left: 1px hidden whitesmoke; }

.has-text-white-bis {
  color: #fafafa; }

.has-background-white-bis {
  background-color: #fafafa; }

.border-white-bis {
  border: 1px solid #fafafa; }

.border-white-bis-dotted {
  border: 1px dotted #fafafa; }

.border-white-bis-dashed {
  border: 1px dashed #fafafa; }

.border-white-bis-solid {
  border: 1px solid #fafafa; }

.border-white-bis-double {
  border: 1px double #fafafa; }

.border-white-bis-groove {
  border: 1px groove #fafafa; }

.border-white-bis-ridge {
  border: 1px ridge #fafafa; }

.border-white-bis-inset {
  border: 1px inset #fafafa; }

.border-white-bis-outset {
  border: 1px outset #fafafa; }

.border-white-bis-none {
  border: 1px none #fafafa; }

.border-white-bis-hidden {
  border: 1px hidden #fafafa; }

.border-t-white-bis {
  border-top: 1px solid #fafafa; }

.border-t-white-bis-dotted {
  border-top: 1px dotted #fafafa; }

.border-t-white-bis-dashed {
  border-top: 1px dashed #fafafa; }

.border-t-white-bis-solid {
  border-top: 1px solid #fafafa; }

.border-t-white-bis-double {
  border-top: 1px double #fafafa; }

.border-t-white-bis-groove {
  border-top: 1px groove #fafafa; }

.border-t-white-bis-ridge {
  border-top: 1px ridge #fafafa; }

.border-t-white-bis-inset {
  border-top: 1px inset #fafafa; }

.border-t-white-bis-outset {
  border-top: 1px outset #fafafa; }

.border-t-white-bis-none {
  border-top: 1px none #fafafa; }

.border-t-white-bis-hidden {
  border-top: 1px hidden #fafafa; }

.border-r-white-bis {
  border-right: 1px solid #fafafa; }

.border-r-white-bis-dotted {
  border-right: 1px dotted #fafafa; }

.border-r-white-bis-dashed {
  border-right: 1px dashed #fafafa; }

.border-r-white-bis-solid {
  border-right: 1px solid #fafafa; }

.border-r-white-bis-double {
  border-right: 1px double #fafafa; }

.border-r-white-bis-groove {
  border-right: 1px groove #fafafa; }

.border-r-white-bis-ridge {
  border-right: 1px ridge #fafafa; }

.border-r-white-bis-inset {
  border-right: 1px inset #fafafa; }

.border-r-white-bis-outset {
  border-right: 1px outset #fafafa; }

.border-r-white-bis-none {
  border-right: 1px none #fafafa; }

.border-r-white-bis-hidden {
  border-right: 1px hidden #fafafa; }

.border-b-white-bis {
  border-bottom: 1px solid #fafafa; }

.border-b-white-bis-dotted {
  border-bottom: 1px dotted #fafafa; }

.border-b-white-bis-dashed {
  border-bottom: 1px dashed #fafafa; }

.border-b-white-bis-solid {
  border-bottom: 1px solid #fafafa; }

.border-b-white-bis-double {
  border-bottom: 1px double #fafafa; }

.border-b-white-bis-groove {
  border-bottom: 1px groove #fafafa; }

.border-b-white-bis-ridge {
  border-bottom: 1px ridge #fafafa; }

.border-b-white-bis-inset {
  border-bottom: 1px inset #fafafa; }

.border-b-white-bis-outset {
  border-bottom: 1px outset #fafafa; }

.border-b-white-bis-none {
  border-bottom: 1px none #fafafa; }

.border-b-white-bis-hidden {
  border-bottom: 1px hidden #fafafa; }

.border-l-white-bis {
  border-left: 1px solid #fafafa; }

.border-l-white-bis-dotted {
  border-left: 1px dotted #fafafa; }

.border-l-white-bis-dashed {
  border-left: 1px dashed #fafafa; }

.border-l-white-bis-solid {
  border-left: 1px solid #fafafa; }

.border-l-white-bis-double {
  border-left: 1px double #fafafa; }

.border-l-white-bis-groove {
  border-left: 1px groove #fafafa; }

.border-l-white-bis-ridge {
  border-left: 1px ridge #fafafa; }

.border-l-white-bis-inset {
  border-left: 1px inset #fafafa; }

.border-l-white-bis-outset {
  border-left: 1px outset #fafafa; }

.border-l-white-bis-none {
  border-left: 1px none #fafafa; }

.border-l-white-bis-hidden {
  border-left: 1px hidden #fafafa; }

.has-text-white {
  color: white; }

.has-background-white {
  background-color: white; }

.border-white {
  border: 1px solid white; }

.border-white-dotted {
  border: 1px dotted white; }

.border-white-dashed {
  border: 1px dashed white; }

.border-white-solid {
  border: 1px solid white; }

.border-white-double {
  border: 1px double white; }

.border-white-groove {
  border: 1px groove white; }

.border-white-ridge {
  border: 1px ridge white; }

.border-white-inset {
  border: 1px inset white; }

.border-white-outset {
  border: 1px outset white; }

.border-white-none {
  border: 1px none white; }

.border-white-hidden {
  border: 1px hidden white; }

.border-t-white {
  border-top: 1px solid white; }

.border-t-white-dotted {
  border-top: 1px dotted white; }

.border-t-white-dashed {
  border-top: 1px dashed white; }

.border-t-white-solid {
  border-top: 1px solid white; }

.border-t-white-double {
  border-top: 1px double white; }

.border-t-white-groove {
  border-top: 1px groove white; }

.border-t-white-ridge {
  border-top: 1px ridge white; }

.border-t-white-inset {
  border-top: 1px inset white; }

.border-t-white-outset {
  border-top: 1px outset white; }

.border-t-white-none {
  border-top: 1px none white; }

.border-t-white-hidden {
  border-top: 1px hidden white; }

.border-r-white {
  border-right: 1px solid white; }

.border-r-white-dotted {
  border-right: 1px dotted white; }

.border-r-white-dashed {
  border-right: 1px dashed white; }

.border-r-white-solid {
  border-right: 1px solid white; }

.border-r-white-double {
  border-right: 1px double white; }

.border-r-white-groove {
  border-right: 1px groove white; }

.border-r-white-ridge {
  border-right: 1px ridge white; }

.border-r-white-inset {
  border-right: 1px inset white; }

.border-r-white-outset {
  border-right: 1px outset white; }

.border-r-white-none {
  border-right: 1px none white; }

.border-r-white-hidden {
  border-right: 1px hidden white; }

.border-b-white {
  border-bottom: 1px solid white; }

.border-b-white-dotted {
  border-bottom: 1px dotted white; }

.border-b-white-dashed {
  border-bottom: 1px dashed white; }

.border-b-white-solid {
  border-bottom: 1px solid white; }

.border-b-white-double {
  border-bottom: 1px double white; }

.border-b-white-groove {
  border-bottom: 1px groove white; }

.border-b-white-ridge {
  border-bottom: 1px ridge white; }

.border-b-white-inset {
  border-bottom: 1px inset white; }

.border-b-white-outset {
  border-bottom: 1px outset white; }

.border-b-white-none {
  border-bottom: 1px none white; }

.border-b-white-hidden {
  border-bottom: 1px hidden white; }

.border-l-white {
  border-left: 1px solid white; }

.border-l-white-dotted {
  border-left: 1px dotted white; }

.border-l-white-dashed {
  border-left: 1px dashed white; }

.border-l-white-solid {
  border-left: 1px solid white; }

.border-l-white-double {
  border-left: 1px double white; }

.border-l-white-groove {
  border-left: 1px groove white; }

.border-l-white-ridge {
  border-left: 1px ridge white; }

.border-l-white-inset {
  border-left: 1px inset white; }

.border-l-white-outset {
  border-left: 1px outset white; }

.border-l-white-none {
  border-left: 1px none white; }

.border-l-white-hidden {
  border-left: 1px hidden white; }

.arrow-primary:after {
  content: '';
  position: absolute;
  top: 0;
  left: 30px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #2d2f7b;
  border-top: 0;
  margin-left: -20px;
  margin-top: -10px; }

.arrow-primary-bis:after {
  content: '';
  position: absolute;
  top: 0;
  left: 30px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #4d4db3;
  border-top: 0;
  margin-left: -20px;
  margin-top: -10px; }

.arrow-primary-ter:after {
  content: '';
  position: absolute;
  top: 0;
  left: 30px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #7a7ab8;
  border-top: 0;
  margin-left: -20px;
  margin-top: -10px; }

.arrow-primary-qua:after {
  content: '';
  position: absolute;
  top: 0;
  left: 30px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #b9b9e9;
  border-top: 0;
  margin-left: -20px;
  margin-top: -10px; }

.arrow-primary-qui:after {
  content: '';
  position: absolute;
  top: 0;
  left: 30px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #b8b8d6;
  border-top: 0;
  margin-left: -20px;
  margin-top: -10px; }

.arrow-primary-light:after {
  content: '';
  position: absolute;
  top: 0;
  left: 30px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #dbdbf0;
  border-top: 0;
  margin-left: -20px;
  margin-top: -10px; }

.arrow-primary-lighter:after {
  content: '';
  position: absolute;
  top: 0;
  left: 30px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #ededf7;
  border-top: 0;
  margin-left: -20px;
  margin-top: -10px; }

.arrow-primary-lighbis:after {
  content: '';
  position: absolute;
  top: 0;
  left: 30px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #f4f4fa;
  border-top: 0;
  margin-left: -20px;
  margin-top: -10px; }

.arrow-secondary:after {
  content: '';
  position: absolute;
  top: 0;
  left: 30px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #eb6666;
  border-top: 0;
  margin-left: -20px;
  margin-top: -10px; }

.arrow-danger:after {
  content: '';
  position: absolute;
  top: 0;
  left: 30px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #cc3333;
  border-top: 0;
  margin-left: -20px;
  margin-top: -10px; }

.arrow-danger-light:after {
  content: '';
  position: absolute;
  top: 0;
  left: 30px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #f6dada;
  border-top: 0;
  margin-left: -20px;
  margin-top: -10px; }

.arrow-warning:after {
  content: '';
  position: absolute;
  top: 0;
  left: 30px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #ffd500;
  border-top: 0;
  margin-left: -20px;
  margin-top: -10px; }

.arrow-success:after {
  content: '';
  position: absolute;
  top: 0;
  left: 30px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #66cc33;
  border-top: 0;
  margin-left: -20px;
  margin-top: -10px; }

.arrow-success-light:after {
  content: '';
  position: absolute;
  top: 0;
  left: 30px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #dbffe0;
  border-top: 0;
  margin-left: -20px;
  margin-top: -10px; }

.arrow-info:after {
  content: '';
  position: absolute;
  top: 0;
  left: 30px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #209fee;
  border-top: 0;
  margin-left: -20px;
  margin-top: -10px; }

.m-none {
  margin: 0; }

.p-none {
  padding: 0; }

.m-t-none {
  margin-top: 0; }

.p-t-none {
  padding-top: 0; }

.m-r-none {
  margin-right: 0; }

.p-r-none {
  padding-right: 0; }

.m-b-none {
  margin-bottom: 0; }

.p-b-none {
  padding-bottom: 0; }

.m-l-none {
  margin-left: 0; }

.p-l-none {
  padding-left: 0; }

.m-xxs {
  margin: 0.125rem; }

.p-xxs {
  padding: 0.125rem; }

.m-t-xxs {
  margin-top: 0.125rem; }

.p-t-xxs {
  padding-top: 0.125rem; }

.m-r-xxs {
  margin-right: 0.125rem; }

.p-r-xxs {
  padding-right: 0.125rem; }

.m-b-xxs {
  margin-bottom: 0.125rem; }

.p-b-xxs {
  padding-bottom: 0.125rem; }

.m-l-xxs {
  margin-left: 0.125rem; }

.p-l-xxs {
  padding-left: 0.125rem; }

.m-xs {
  margin: 0.25rem; }

.p-xs {
  padding: 0.25rem; }

.m-t-xs {
  margin-top: 0.25rem; }

.p-t-xs {
  padding-top: 0.25rem; }

.m-r-xs {
  margin-right: 0.25rem; }

.p-r-xs {
  padding-right: 0.25rem; }

.m-b-xs {
  margin-bottom: 0.25rem; }

.p-b-xs {
  padding-bottom: 0.25rem; }

.m-l-xs {
  margin-left: 0.25rem; }

.p-l-xs {
  padding-left: 0.25rem; }

.m-sm {
  margin: 0.6rem; }

.p-sm {
  padding: 0.6rem; }

.m-t-sm {
  margin-top: 0.6rem; }

.p-t-sm {
  padding-top: 0.6rem; }

.m-r-sm {
  margin-right: 0.6rem; }

.p-r-sm {
  padding-right: 0.6rem; }

.m-b-sm {
  margin-bottom: 0.6rem; }

.p-b-sm {
  padding-bottom: 0.6rem; }

.m-l-sm {
  margin-left: 0.6rem; }

.p-l-sm {
  padding-left: 0.6rem; }

.m-md {
  margin: 1rem; }

.p-md {
  padding: 1rem; }

.m-t-md {
  margin-top: 1rem; }

.p-t-md {
  padding-top: 1rem; }

.m-r-md {
  margin-right: 1rem; }

.p-r-md {
  padding-right: 1rem; }

.m-b-md {
  margin-bottom: 1rem; }

.p-b-md {
  padding-bottom: 1rem; }

.m-l-md {
  margin-left: 1rem; }

.p-l-md {
  padding-left: 1rem; }

.m-lg {
  margin: 2rem; }

.p-lg {
  padding: 2rem; }

.m-t-lg {
  margin-top: 2rem; }

.p-t-lg {
  padding-top: 2rem; }

.m-r-lg {
  margin-right: 2rem; }

.p-r-lg {
  padding-right: 2rem; }

.m-b-lg {
  margin-bottom: 2rem; }

.p-b-lg {
  padding-bottom: 2rem; }

.m-l-lg {
  margin-left: 2rem; }

.p-l-lg {
  padding-left: 2rem; }

.m-xl {
  margin: 4rem; }

.p-xl {
  padding: 4rem; }

.m-t-xl {
  margin-top: 4rem; }

.p-t-xl {
  padding-top: 4rem; }

.m-r-xl {
  margin-right: 4rem; }

.p-r-xl {
  padding-right: 4rem; }

.m-b-xl {
  margin-bottom: 4rem; }

.p-b-xl {
  padding-bottom: 4rem; }

.m-l-xl {
  margin-left: 4rem; }

.p-l-xl {
  padding-left: 4rem; }

.m-xxl {
  margin: 8rem; }

.p-xxl {
  padding: 8rem; }

.m-t-xxl {
  margin-top: 8rem; }

.p-t-xxl {
  padding-top: 8rem; }

.m-r-xxl {
  margin-right: 8rem; }

.p-r-xxl {
  padding-right: 8rem; }

.m-b-xxl {
  margin-bottom: 8rem; }

.p-b-xxl {
  padding-bottom: 8rem; }

.m-l-xxl {
  margin-left: 8rem; }

.p-l-xxl {
  padding-left: 8rem; }

.has-text-primary {
  color: #2d2f7b; }

.has-background-primary {
  background-color: #2d2f7b; }

.border-primary {
  border: 1px solid #2d2f7b; }

.border-m-primary:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #2d2f7b;
  transform: translateY(-50%); }

.border-primary-dotted {
  border: 1px dotted #2d2f7b; }

.border-primary-dashed {
  border: 1px dashed #2d2f7b; }

.border-primary-solid {
  border: 1px solid #2d2f7b; }

.border-primary-double {
  border: 1px double #2d2f7b; }

.border-primary-groove {
  border: 1px groove #2d2f7b; }

.border-primary-ridge {
  border: 1px ridge #2d2f7b; }

.border-primary-inset {
  border: 1px inset #2d2f7b; }

.border-primary-outset {
  border: 1px outset #2d2f7b; }

.border-primary-none {
  border: 1px none #2d2f7b; }

.border-primary-hidden {
  border: 1px hidden #2d2f7b; }

.border-t-primary {
  border-top: 1px solid #2d2f7b; }

.border-t-primary-dotted {
  border-top: 1px dotted #2d2f7b; }

.border-t-primary-dashed {
  border-top: 1px dashed #2d2f7b; }

.border-t-primary-solid {
  border-top: 1px solid #2d2f7b; }

.border-t-primary-double {
  border-top: 1px double #2d2f7b; }

.border-t-primary-groove {
  border-top: 1px groove #2d2f7b; }

.border-t-primary-ridge {
  border-top: 1px ridge #2d2f7b; }

.border-t-primary-inset {
  border-top: 1px inset #2d2f7b; }

.border-t-primary-outset {
  border-top: 1px outset #2d2f7b; }

.border-t-primary-none {
  border-top: 1px none #2d2f7b; }

.border-t-primary-hidden {
  border-top: 1px hidden #2d2f7b; }

.border-r-primary {
  border-right: 1px solid #2d2f7b; }

.border-r-primary-dotted {
  border-right: 1px dotted #2d2f7b; }

.border-r-primary-dashed {
  border-right: 1px dashed #2d2f7b; }

.border-r-primary-solid {
  border-right: 1px solid #2d2f7b; }

.border-r-primary-double {
  border-right: 1px double #2d2f7b; }

.border-r-primary-groove {
  border-right: 1px groove #2d2f7b; }

.border-r-primary-ridge {
  border-right: 1px ridge #2d2f7b; }

.border-r-primary-inset {
  border-right: 1px inset #2d2f7b; }

.border-r-primary-outset {
  border-right: 1px outset #2d2f7b; }

.border-r-primary-none {
  border-right: 1px none #2d2f7b; }

.border-r-primary-hidden {
  border-right: 1px hidden #2d2f7b; }

.border-b-primary {
  border-bottom: 1px solid #2d2f7b; }

.border-b-primary-dotted {
  border-bottom: 1px dotted #2d2f7b; }

.border-b-primary-dashed {
  border-bottom: 1px dashed #2d2f7b; }

.border-b-primary-solid {
  border-bottom: 1px solid #2d2f7b; }

.border-b-primary-double {
  border-bottom: 1px double #2d2f7b; }

.border-b-primary-groove {
  border-bottom: 1px groove #2d2f7b; }

.border-b-primary-ridge {
  border-bottom: 1px ridge #2d2f7b; }

.border-b-primary-inset {
  border-bottom: 1px inset #2d2f7b; }

.border-b-primary-outset {
  border-bottom: 1px outset #2d2f7b; }

.border-b-primary-none {
  border-bottom: 1px none #2d2f7b; }

.border-b-primary-hidden {
  border-bottom: 1px hidden #2d2f7b; }

.border-l-primary {
  border-left: 1px solid #2d2f7b; }

.border-l-primary-dotted {
  border-left: 1px dotted #2d2f7b; }

.border-l-primary-dashed {
  border-left: 1px dashed #2d2f7b; }

.border-l-primary-solid {
  border-left: 1px solid #2d2f7b; }

.border-l-primary-double {
  border-left: 1px double #2d2f7b; }

.border-l-primary-groove {
  border-left: 1px groove #2d2f7b; }

.border-l-primary-ridge {
  border-left: 1px ridge #2d2f7b; }

.border-l-primary-inset {
  border-left: 1px inset #2d2f7b; }

.border-l-primary-outset {
  border-left: 1px outset #2d2f7b; }

.border-l-primary-none {
  border-left: 1px none #2d2f7b; }

.border-l-primary-hidden {
  border-left: 1px hidden #2d2f7b; }

.has-text-primary-bis {
  color: #4d4db3; }

.has-background-primary-bis {
  background-color: #4d4db3; }

.border-primary-bis {
  border: 1px solid #4d4db3; }

.border-m-primary-bis:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #4d4db3;
  transform: translateY(-50%); }

.border-primary-bis-dotted {
  border: 1px dotted #4d4db3; }

.border-primary-bis-dashed {
  border: 1px dashed #4d4db3; }

.border-primary-bis-solid {
  border: 1px solid #4d4db3; }

.border-primary-bis-double {
  border: 1px double #4d4db3; }

.border-primary-bis-groove {
  border: 1px groove #4d4db3; }

.border-primary-bis-ridge {
  border: 1px ridge #4d4db3; }

.border-primary-bis-inset {
  border: 1px inset #4d4db3; }

.border-primary-bis-outset {
  border: 1px outset #4d4db3; }

.border-primary-bis-none {
  border: 1px none #4d4db3; }

.border-primary-bis-hidden {
  border: 1px hidden #4d4db3; }

.border-t-primary-bis {
  border-top: 1px solid #4d4db3; }

.border-t-primary-bis-dotted {
  border-top: 1px dotted #4d4db3; }

.border-t-primary-bis-dashed {
  border-top: 1px dashed #4d4db3; }

.border-t-primary-bis-solid {
  border-top: 1px solid #4d4db3; }

.border-t-primary-bis-double {
  border-top: 1px double #4d4db3; }

.border-t-primary-bis-groove {
  border-top: 1px groove #4d4db3; }

.border-t-primary-bis-ridge {
  border-top: 1px ridge #4d4db3; }

.border-t-primary-bis-inset {
  border-top: 1px inset #4d4db3; }

.border-t-primary-bis-outset {
  border-top: 1px outset #4d4db3; }

.border-t-primary-bis-none {
  border-top: 1px none #4d4db3; }

.border-t-primary-bis-hidden {
  border-top: 1px hidden #4d4db3; }

.border-r-primary-bis {
  border-right: 1px solid #4d4db3; }

.border-r-primary-bis-dotted {
  border-right: 1px dotted #4d4db3; }

.border-r-primary-bis-dashed {
  border-right: 1px dashed #4d4db3; }

.border-r-primary-bis-solid {
  border-right: 1px solid #4d4db3; }

.border-r-primary-bis-double {
  border-right: 1px double #4d4db3; }

.border-r-primary-bis-groove {
  border-right: 1px groove #4d4db3; }

.border-r-primary-bis-ridge {
  border-right: 1px ridge #4d4db3; }

.border-r-primary-bis-inset {
  border-right: 1px inset #4d4db3; }

.border-r-primary-bis-outset {
  border-right: 1px outset #4d4db3; }

.border-r-primary-bis-none {
  border-right: 1px none #4d4db3; }

.border-r-primary-bis-hidden {
  border-right: 1px hidden #4d4db3; }

.border-b-primary-bis {
  border-bottom: 1px solid #4d4db3; }

.border-b-primary-bis-dotted {
  border-bottom: 1px dotted #4d4db3; }

.border-b-primary-bis-dashed {
  border-bottom: 1px dashed #4d4db3; }

.border-b-primary-bis-solid {
  border-bottom: 1px solid #4d4db3; }

.border-b-primary-bis-double {
  border-bottom: 1px double #4d4db3; }

.border-b-primary-bis-groove {
  border-bottom: 1px groove #4d4db3; }

.border-b-primary-bis-ridge {
  border-bottom: 1px ridge #4d4db3; }

.border-b-primary-bis-inset {
  border-bottom: 1px inset #4d4db3; }

.border-b-primary-bis-outset {
  border-bottom: 1px outset #4d4db3; }

.border-b-primary-bis-none {
  border-bottom: 1px none #4d4db3; }

.border-b-primary-bis-hidden {
  border-bottom: 1px hidden #4d4db3; }

.border-l-primary-bis {
  border-left: 1px solid #4d4db3; }

.border-l-primary-bis-dotted {
  border-left: 1px dotted #4d4db3; }

.border-l-primary-bis-dashed {
  border-left: 1px dashed #4d4db3; }

.border-l-primary-bis-solid {
  border-left: 1px solid #4d4db3; }

.border-l-primary-bis-double {
  border-left: 1px double #4d4db3; }

.border-l-primary-bis-groove {
  border-left: 1px groove #4d4db3; }

.border-l-primary-bis-ridge {
  border-left: 1px ridge #4d4db3; }

.border-l-primary-bis-inset {
  border-left: 1px inset #4d4db3; }

.border-l-primary-bis-outset {
  border-left: 1px outset #4d4db3; }

.border-l-primary-bis-none {
  border-left: 1px none #4d4db3; }

.border-l-primary-bis-hidden {
  border-left: 1px hidden #4d4db3; }

.has-text-primary-ter {
  color: #7a7ab8; }

.has-background-primary-ter {
  background-color: #7a7ab8; }

.border-primary-ter {
  border: 1px solid #7a7ab8; }

.border-m-primary-ter:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #7a7ab8;
  transform: translateY(-50%); }

.border-primary-ter-dotted {
  border: 1px dotted #7a7ab8; }

.border-primary-ter-dashed {
  border: 1px dashed #7a7ab8; }

.border-primary-ter-solid {
  border: 1px solid #7a7ab8; }

.border-primary-ter-double {
  border: 1px double #7a7ab8; }

.border-primary-ter-groove {
  border: 1px groove #7a7ab8; }

.border-primary-ter-ridge {
  border: 1px ridge #7a7ab8; }

.border-primary-ter-inset {
  border: 1px inset #7a7ab8; }

.border-primary-ter-outset {
  border: 1px outset #7a7ab8; }

.border-primary-ter-none {
  border: 1px none #7a7ab8; }

.border-primary-ter-hidden {
  border: 1px hidden #7a7ab8; }

.border-t-primary-ter {
  border-top: 1px solid #7a7ab8; }

.border-t-primary-ter-dotted {
  border-top: 1px dotted #7a7ab8; }

.border-t-primary-ter-dashed {
  border-top: 1px dashed #7a7ab8; }

.border-t-primary-ter-solid {
  border-top: 1px solid #7a7ab8; }

.border-t-primary-ter-double {
  border-top: 1px double #7a7ab8; }

.border-t-primary-ter-groove {
  border-top: 1px groove #7a7ab8; }

.border-t-primary-ter-ridge {
  border-top: 1px ridge #7a7ab8; }

.border-t-primary-ter-inset {
  border-top: 1px inset #7a7ab8; }

.border-t-primary-ter-outset {
  border-top: 1px outset #7a7ab8; }

.border-t-primary-ter-none {
  border-top: 1px none #7a7ab8; }

.border-t-primary-ter-hidden {
  border-top: 1px hidden #7a7ab8; }

.border-r-primary-ter {
  border-right: 1px solid #7a7ab8; }

.border-r-primary-ter-dotted {
  border-right: 1px dotted #7a7ab8; }

.border-r-primary-ter-dashed {
  border-right: 1px dashed #7a7ab8; }

.border-r-primary-ter-solid {
  border-right: 1px solid #7a7ab8; }

.border-r-primary-ter-double {
  border-right: 1px double #7a7ab8; }

.border-r-primary-ter-groove {
  border-right: 1px groove #7a7ab8; }

.border-r-primary-ter-ridge {
  border-right: 1px ridge #7a7ab8; }

.border-r-primary-ter-inset {
  border-right: 1px inset #7a7ab8; }

.border-r-primary-ter-outset {
  border-right: 1px outset #7a7ab8; }

.border-r-primary-ter-none {
  border-right: 1px none #7a7ab8; }

.border-r-primary-ter-hidden {
  border-right: 1px hidden #7a7ab8; }

.border-b-primary-ter {
  border-bottom: 1px solid #7a7ab8; }

.border-b-primary-ter-dotted {
  border-bottom: 1px dotted #7a7ab8; }

.border-b-primary-ter-dashed {
  border-bottom: 1px dashed #7a7ab8; }

.border-b-primary-ter-solid {
  border-bottom: 1px solid #7a7ab8; }

.border-b-primary-ter-double {
  border-bottom: 1px double #7a7ab8; }

.border-b-primary-ter-groove {
  border-bottom: 1px groove #7a7ab8; }

.border-b-primary-ter-ridge {
  border-bottom: 1px ridge #7a7ab8; }

.border-b-primary-ter-inset {
  border-bottom: 1px inset #7a7ab8; }

.border-b-primary-ter-outset {
  border-bottom: 1px outset #7a7ab8; }

.border-b-primary-ter-none {
  border-bottom: 1px none #7a7ab8; }

.border-b-primary-ter-hidden {
  border-bottom: 1px hidden #7a7ab8; }

.border-l-primary-ter {
  border-left: 1px solid #7a7ab8; }

.border-l-primary-ter-dotted {
  border-left: 1px dotted #7a7ab8; }

.border-l-primary-ter-dashed {
  border-left: 1px dashed #7a7ab8; }

.border-l-primary-ter-solid {
  border-left: 1px solid #7a7ab8; }

.border-l-primary-ter-double {
  border-left: 1px double #7a7ab8; }

.border-l-primary-ter-groove {
  border-left: 1px groove #7a7ab8; }

.border-l-primary-ter-ridge {
  border-left: 1px ridge #7a7ab8; }

.border-l-primary-ter-inset {
  border-left: 1px inset #7a7ab8; }

.border-l-primary-ter-outset {
  border-left: 1px outset #7a7ab8; }

.border-l-primary-ter-none {
  border-left: 1px none #7a7ab8; }

.border-l-primary-ter-hidden {
  border-left: 1px hidden #7a7ab8; }

.has-text-primary-qua {
  color: #b9b9e9; }

.has-background-primary-qua {
  background-color: #b9b9e9; }

.border-primary-qua {
  border: 1px solid #b9b9e9; }

.border-m-primary-qua:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b9b9e9;
  transform: translateY(-50%); }

.border-primary-qua-dotted {
  border: 1px dotted #b9b9e9; }

.border-primary-qua-dashed {
  border: 1px dashed #b9b9e9; }

.border-primary-qua-solid {
  border: 1px solid #b9b9e9; }

.border-primary-qua-double {
  border: 1px double #b9b9e9; }

.border-primary-qua-groove {
  border: 1px groove #b9b9e9; }

.border-primary-qua-ridge {
  border: 1px ridge #b9b9e9; }

.border-primary-qua-inset {
  border: 1px inset #b9b9e9; }

.border-primary-qua-outset {
  border: 1px outset #b9b9e9; }

.border-primary-qua-none {
  border: 1px none #b9b9e9; }

.border-primary-qua-hidden {
  border: 1px hidden #b9b9e9; }

.border-t-primary-qua {
  border-top: 1px solid #b9b9e9; }

.border-t-primary-qua-dotted {
  border-top: 1px dotted #b9b9e9; }

.border-t-primary-qua-dashed {
  border-top: 1px dashed #b9b9e9; }

.border-t-primary-qua-solid {
  border-top: 1px solid #b9b9e9; }

.border-t-primary-qua-double {
  border-top: 1px double #b9b9e9; }

.border-t-primary-qua-groove {
  border-top: 1px groove #b9b9e9; }

.border-t-primary-qua-ridge {
  border-top: 1px ridge #b9b9e9; }

.border-t-primary-qua-inset {
  border-top: 1px inset #b9b9e9; }

.border-t-primary-qua-outset {
  border-top: 1px outset #b9b9e9; }

.border-t-primary-qua-none {
  border-top: 1px none #b9b9e9; }

.border-t-primary-qua-hidden {
  border-top: 1px hidden #b9b9e9; }

.border-r-primary-qua {
  border-right: 1px solid #b9b9e9; }

.border-r-primary-qua-dotted {
  border-right: 1px dotted #b9b9e9; }

.border-r-primary-qua-dashed {
  border-right: 1px dashed #b9b9e9; }

.border-r-primary-qua-solid {
  border-right: 1px solid #b9b9e9; }

.border-r-primary-qua-double {
  border-right: 1px double #b9b9e9; }

.border-r-primary-qua-groove {
  border-right: 1px groove #b9b9e9; }

.border-r-primary-qua-ridge {
  border-right: 1px ridge #b9b9e9; }

.border-r-primary-qua-inset {
  border-right: 1px inset #b9b9e9; }

.border-r-primary-qua-outset {
  border-right: 1px outset #b9b9e9; }

.border-r-primary-qua-none {
  border-right: 1px none #b9b9e9; }

.border-r-primary-qua-hidden {
  border-right: 1px hidden #b9b9e9; }

.border-b-primary-qua {
  border-bottom: 1px solid #b9b9e9; }

.border-b-primary-qua-dotted {
  border-bottom: 1px dotted #b9b9e9; }

.border-b-primary-qua-dashed {
  border-bottom: 1px dashed #b9b9e9; }

.border-b-primary-qua-solid {
  border-bottom: 1px solid #b9b9e9; }

.border-b-primary-qua-double {
  border-bottom: 1px double #b9b9e9; }

.border-b-primary-qua-groove {
  border-bottom: 1px groove #b9b9e9; }

.border-b-primary-qua-ridge {
  border-bottom: 1px ridge #b9b9e9; }

.border-b-primary-qua-inset {
  border-bottom: 1px inset #b9b9e9; }

.border-b-primary-qua-outset {
  border-bottom: 1px outset #b9b9e9; }

.border-b-primary-qua-none {
  border-bottom: 1px none #b9b9e9; }

.border-b-primary-qua-hidden {
  border-bottom: 1px hidden #b9b9e9; }

.border-l-primary-qua {
  border-left: 1px solid #b9b9e9; }

.border-l-primary-qua-dotted {
  border-left: 1px dotted #b9b9e9; }

.border-l-primary-qua-dashed {
  border-left: 1px dashed #b9b9e9; }

.border-l-primary-qua-solid {
  border-left: 1px solid #b9b9e9; }

.border-l-primary-qua-double {
  border-left: 1px double #b9b9e9; }

.border-l-primary-qua-groove {
  border-left: 1px groove #b9b9e9; }

.border-l-primary-qua-ridge {
  border-left: 1px ridge #b9b9e9; }

.border-l-primary-qua-inset {
  border-left: 1px inset #b9b9e9; }

.border-l-primary-qua-outset {
  border-left: 1px outset #b9b9e9; }

.border-l-primary-qua-none {
  border-left: 1px none #b9b9e9; }

.border-l-primary-qua-hidden {
  border-left: 1px hidden #b9b9e9; }

.has-text-primary-qui {
  color: #b8b8d6; }

.has-background-primary-qui {
  background-color: #b8b8d6; }

.border-primary-qui {
  border: 1px solid #b8b8d6; }

.border-m-primary-qui:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b8b8d6;
  transform: translateY(-50%); }

.border-primary-qui-dotted {
  border: 1px dotted #b8b8d6; }

.border-primary-qui-dashed {
  border: 1px dashed #b8b8d6; }

.border-primary-qui-solid {
  border: 1px solid #b8b8d6; }

.border-primary-qui-double {
  border: 1px double #b8b8d6; }

.border-primary-qui-groove {
  border: 1px groove #b8b8d6; }

.border-primary-qui-ridge {
  border: 1px ridge #b8b8d6; }

.border-primary-qui-inset {
  border: 1px inset #b8b8d6; }

.border-primary-qui-outset {
  border: 1px outset #b8b8d6; }

.border-primary-qui-none {
  border: 1px none #b8b8d6; }

.border-primary-qui-hidden {
  border: 1px hidden #b8b8d6; }

.border-t-primary-qui {
  border-top: 1px solid #b8b8d6; }

.border-t-primary-qui-dotted {
  border-top: 1px dotted #b8b8d6; }

.border-t-primary-qui-dashed {
  border-top: 1px dashed #b8b8d6; }

.border-t-primary-qui-solid {
  border-top: 1px solid #b8b8d6; }

.border-t-primary-qui-double {
  border-top: 1px double #b8b8d6; }

.border-t-primary-qui-groove {
  border-top: 1px groove #b8b8d6; }

.border-t-primary-qui-ridge {
  border-top: 1px ridge #b8b8d6; }

.border-t-primary-qui-inset {
  border-top: 1px inset #b8b8d6; }

.border-t-primary-qui-outset {
  border-top: 1px outset #b8b8d6; }

.border-t-primary-qui-none {
  border-top: 1px none #b8b8d6; }

.border-t-primary-qui-hidden {
  border-top: 1px hidden #b8b8d6; }

.border-r-primary-qui {
  border-right: 1px solid #b8b8d6; }

.border-r-primary-qui-dotted {
  border-right: 1px dotted #b8b8d6; }

.border-r-primary-qui-dashed {
  border-right: 1px dashed #b8b8d6; }

.border-r-primary-qui-solid {
  border-right: 1px solid #b8b8d6; }

.border-r-primary-qui-double {
  border-right: 1px double #b8b8d6; }

.border-r-primary-qui-groove {
  border-right: 1px groove #b8b8d6; }

.border-r-primary-qui-ridge {
  border-right: 1px ridge #b8b8d6; }

.border-r-primary-qui-inset {
  border-right: 1px inset #b8b8d6; }

.border-r-primary-qui-outset {
  border-right: 1px outset #b8b8d6; }

.border-r-primary-qui-none {
  border-right: 1px none #b8b8d6; }

.border-r-primary-qui-hidden {
  border-right: 1px hidden #b8b8d6; }

.border-b-primary-qui {
  border-bottom: 1px solid #b8b8d6; }

.border-b-primary-qui-dotted {
  border-bottom: 1px dotted #b8b8d6; }

.border-b-primary-qui-dashed {
  border-bottom: 1px dashed #b8b8d6; }

.border-b-primary-qui-solid {
  border-bottom: 1px solid #b8b8d6; }

.border-b-primary-qui-double {
  border-bottom: 1px double #b8b8d6; }

.border-b-primary-qui-groove {
  border-bottom: 1px groove #b8b8d6; }

.border-b-primary-qui-ridge {
  border-bottom: 1px ridge #b8b8d6; }

.border-b-primary-qui-inset {
  border-bottom: 1px inset #b8b8d6; }

.border-b-primary-qui-outset {
  border-bottom: 1px outset #b8b8d6; }

.border-b-primary-qui-none {
  border-bottom: 1px none #b8b8d6; }

.border-b-primary-qui-hidden {
  border-bottom: 1px hidden #b8b8d6; }

.border-l-primary-qui {
  border-left: 1px solid #b8b8d6; }

.border-l-primary-qui-dotted {
  border-left: 1px dotted #b8b8d6; }

.border-l-primary-qui-dashed {
  border-left: 1px dashed #b8b8d6; }

.border-l-primary-qui-solid {
  border-left: 1px solid #b8b8d6; }

.border-l-primary-qui-double {
  border-left: 1px double #b8b8d6; }

.border-l-primary-qui-groove {
  border-left: 1px groove #b8b8d6; }

.border-l-primary-qui-ridge {
  border-left: 1px ridge #b8b8d6; }

.border-l-primary-qui-inset {
  border-left: 1px inset #b8b8d6; }

.border-l-primary-qui-outset {
  border-left: 1px outset #b8b8d6; }

.border-l-primary-qui-none {
  border-left: 1px none #b8b8d6; }

.border-l-primary-qui-hidden {
  border-left: 1px hidden #b8b8d6; }

.has-text-primary-light {
  color: #dbdbf0; }

.has-background-primary-light {
  background-color: #dbdbf0; }

.border-primary-light {
  border: 1px solid #dbdbf0; }

.border-m-primary-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dbdbf0;
  transform: translateY(-50%); }

.border-primary-light-dotted {
  border: 1px dotted #dbdbf0; }

.border-primary-light-dashed {
  border: 1px dashed #dbdbf0; }

.border-primary-light-solid {
  border: 1px solid #dbdbf0; }

.border-primary-light-double {
  border: 1px double #dbdbf0; }

.border-primary-light-groove {
  border: 1px groove #dbdbf0; }

.border-primary-light-ridge {
  border: 1px ridge #dbdbf0; }

.border-primary-light-inset {
  border: 1px inset #dbdbf0; }

.border-primary-light-outset {
  border: 1px outset #dbdbf0; }

.border-primary-light-none {
  border: 1px none #dbdbf0; }

.border-primary-light-hidden {
  border: 1px hidden #dbdbf0; }

.border-t-primary-light {
  border-top: 1px solid #dbdbf0; }

.border-t-primary-light-dotted {
  border-top: 1px dotted #dbdbf0; }

.border-t-primary-light-dashed {
  border-top: 1px dashed #dbdbf0; }

.border-t-primary-light-solid {
  border-top: 1px solid #dbdbf0; }

.border-t-primary-light-double {
  border-top: 1px double #dbdbf0; }

.border-t-primary-light-groove {
  border-top: 1px groove #dbdbf0; }

.border-t-primary-light-ridge {
  border-top: 1px ridge #dbdbf0; }

.border-t-primary-light-inset {
  border-top: 1px inset #dbdbf0; }

.border-t-primary-light-outset {
  border-top: 1px outset #dbdbf0; }

.border-t-primary-light-none {
  border-top: 1px none #dbdbf0; }

.border-t-primary-light-hidden {
  border-top: 1px hidden #dbdbf0; }

.border-r-primary-light {
  border-right: 1px solid #dbdbf0; }

.border-r-primary-light-dotted {
  border-right: 1px dotted #dbdbf0; }

.border-r-primary-light-dashed {
  border-right: 1px dashed #dbdbf0; }

.border-r-primary-light-solid {
  border-right: 1px solid #dbdbf0; }

.border-r-primary-light-double {
  border-right: 1px double #dbdbf0; }

.border-r-primary-light-groove {
  border-right: 1px groove #dbdbf0; }

.border-r-primary-light-ridge {
  border-right: 1px ridge #dbdbf0; }

.border-r-primary-light-inset {
  border-right: 1px inset #dbdbf0; }

.border-r-primary-light-outset {
  border-right: 1px outset #dbdbf0; }

.border-r-primary-light-none {
  border-right: 1px none #dbdbf0; }

.border-r-primary-light-hidden {
  border-right: 1px hidden #dbdbf0; }

.border-b-primary-light {
  border-bottom: 1px solid #dbdbf0; }

.border-b-primary-light-dotted {
  border-bottom: 1px dotted #dbdbf0; }

.border-b-primary-light-dashed {
  border-bottom: 1px dashed #dbdbf0; }

.border-b-primary-light-solid {
  border-bottom: 1px solid #dbdbf0; }

.border-b-primary-light-double {
  border-bottom: 1px double #dbdbf0; }

.border-b-primary-light-groove {
  border-bottom: 1px groove #dbdbf0; }

.border-b-primary-light-ridge {
  border-bottom: 1px ridge #dbdbf0; }

.border-b-primary-light-inset {
  border-bottom: 1px inset #dbdbf0; }

.border-b-primary-light-outset {
  border-bottom: 1px outset #dbdbf0; }

.border-b-primary-light-none {
  border-bottom: 1px none #dbdbf0; }

.border-b-primary-light-hidden {
  border-bottom: 1px hidden #dbdbf0; }

.border-l-primary-light {
  border-left: 1px solid #dbdbf0; }

.border-l-primary-light-dotted {
  border-left: 1px dotted #dbdbf0; }

.border-l-primary-light-dashed {
  border-left: 1px dashed #dbdbf0; }

.border-l-primary-light-solid {
  border-left: 1px solid #dbdbf0; }

.border-l-primary-light-double {
  border-left: 1px double #dbdbf0; }

.border-l-primary-light-groove {
  border-left: 1px groove #dbdbf0; }

.border-l-primary-light-ridge {
  border-left: 1px ridge #dbdbf0; }

.border-l-primary-light-inset {
  border-left: 1px inset #dbdbf0; }

.border-l-primary-light-outset {
  border-left: 1px outset #dbdbf0; }

.border-l-primary-light-none {
  border-left: 1px none #dbdbf0; }

.border-l-primary-light-hidden {
  border-left: 1px hidden #dbdbf0; }

.has-text-primary-lighter {
  color: #ededf7; }

.has-background-primary-lighter {
  background-color: #ededf7; }

.border-primary-lighter {
  border: 1px solid #ededf7; }

.border-m-primary-lighter:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ededf7;
  transform: translateY(-50%); }

.border-primary-lighter-dotted {
  border: 1px dotted #ededf7; }

.border-primary-lighter-dashed {
  border: 1px dashed #ededf7; }

.border-primary-lighter-solid {
  border: 1px solid #ededf7; }

.border-primary-lighter-double {
  border: 1px double #ededf7; }

.border-primary-lighter-groove {
  border: 1px groove #ededf7; }

.border-primary-lighter-ridge {
  border: 1px ridge #ededf7; }

.border-primary-lighter-inset {
  border: 1px inset #ededf7; }

.border-primary-lighter-outset {
  border: 1px outset #ededf7; }

.border-primary-lighter-none {
  border: 1px none #ededf7; }

.border-primary-lighter-hidden {
  border: 1px hidden #ededf7; }

.border-t-primary-lighter {
  border-top: 1px solid #ededf7; }

.border-t-primary-lighter-dotted {
  border-top: 1px dotted #ededf7; }

.border-t-primary-lighter-dashed {
  border-top: 1px dashed #ededf7; }

.border-t-primary-lighter-solid {
  border-top: 1px solid #ededf7; }

.border-t-primary-lighter-double {
  border-top: 1px double #ededf7; }

.border-t-primary-lighter-groove {
  border-top: 1px groove #ededf7; }

.border-t-primary-lighter-ridge {
  border-top: 1px ridge #ededf7; }

.border-t-primary-lighter-inset {
  border-top: 1px inset #ededf7; }

.border-t-primary-lighter-outset {
  border-top: 1px outset #ededf7; }

.border-t-primary-lighter-none {
  border-top: 1px none #ededf7; }

.border-t-primary-lighter-hidden {
  border-top: 1px hidden #ededf7; }

.border-r-primary-lighter {
  border-right: 1px solid #ededf7; }

.border-r-primary-lighter-dotted {
  border-right: 1px dotted #ededf7; }

.border-r-primary-lighter-dashed {
  border-right: 1px dashed #ededf7; }

.border-r-primary-lighter-solid {
  border-right: 1px solid #ededf7; }

.border-r-primary-lighter-double {
  border-right: 1px double #ededf7; }

.border-r-primary-lighter-groove {
  border-right: 1px groove #ededf7; }

.border-r-primary-lighter-ridge {
  border-right: 1px ridge #ededf7; }

.border-r-primary-lighter-inset {
  border-right: 1px inset #ededf7; }

.border-r-primary-lighter-outset {
  border-right: 1px outset #ededf7; }

.border-r-primary-lighter-none {
  border-right: 1px none #ededf7; }

.border-r-primary-lighter-hidden {
  border-right: 1px hidden #ededf7; }

.border-b-primary-lighter {
  border-bottom: 1px solid #ededf7; }

.border-b-primary-lighter-dotted {
  border-bottom: 1px dotted #ededf7; }

.border-b-primary-lighter-dashed {
  border-bottom: 1px dashed #ededf7; }

.border-b-primary-lighter-solid {
  border-bottom: 1px solid #ededf7; }

.border-b-primary-lighter-double {
  border-bottom: 1px double #ededf7; }

.border-b-primary-lighter-groove {
  border-bottom: 1px groove #ededf7; }

.border-b-primary-lighter-ridge {
  border-bottom: 1px ridge #ededf7; }

.border-b-primary-lighter-inset {
  border-bottom: 1px inset #ededf7; }

.border-b-primary-lighter-outset {
  border-bottom: 1px outset #ededf7; }

.border-b-primary-lighter-none {
  border-bottom: 1px none #ededf7; }

.border-b-primary-lighter-hidden {
  border-bottom: 1px hidden #ededf7; }

.border-l-primary-lighter {
  border-left: 1px solid #ededf7; }

.border-l-primary-lighter-dotted {
  border-left: 1px dotted #ededf7; }

.border-l-primary-lighter-dashed {
  border-left: 1px dashed #ededf7; }

.border-l-primary-lighter-solid {
  border-left: 1px solid #ededf7; }

.border-l-primary-lighter-double {
  border-left: 1px double #ededf7; }

.border-l-primary-lighter-groove {
  border-left: 1px groove #ededf7; }

.border-l-primary-lighter-ridge {
  border-left: 1px ridge #ededf7; }

.border-l-primary-lighter-inset {
  border-left: 1px inset #ededf7; }

.border-l-primary-lighter-outset {
  border-left: 1px outset #ededf7; }

.border-l-primary-lighter-none {
  border-left: 1px none #ededf7; }

.border-l-primary-lighter-hidden {
  border-left: 1px hidden #ededf7; }

.has-text-primary-lighbis {
  color: #f4f4fa; }

.has-background-primary-lighbis {
  background-color: #f4f4fa; }

.border-primary-lighbis {
  border: 1px solid #f4f4fa; }

.border-m-primary-lighbis:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f4f4fa;
  transform: translateY(-50%); }

.border-primary-lighbis-dotted {
  border: 1px dotted #f4f4fa; }

.border-primary-lighbis-dashed {
  border: 1px dashed #f4f4fa; }

.border-primary-lighbis-solid {
  border: 1px solid #f4f4fa; }

.border-primary-lighbis-double {
  border: 1px double #f4f4fa; }

.border-primary-lighbis-groove {
  border: 1px groove #f4f4fa; }

.border-primary-lighbis-ridge {
  border: 1px ridge #f4f4fa; }

.border-primary-lighbis-inset {
  border: 1px inset #f4f4fa; }

.border-primary-lighbis-outset {
  border: 1px outset #f4f4fa; }

.border-primary-lighbis-none {
  border: 1px none #f4f4fa; }

.border-primary-lighbis-hidden {
  border: 1px hidden #f4f4fa; }

.border-t-primary-lighbis {
  border-top: 1px solid #f4f4fa; }

.border-t-primary-lighbis-dotted {
  border-top: 1px dotted #f4f4fa; }

.border-t-primary-lighbis-dashed {
  border-top: 1px dashed #f4f4fa; }

.border-t-primary-lighbis-solid {
  border-top: 1px solid #f4f4fa; }

.border-t-primary-lighbis-double {
  border-top: 1px double #f4f4fa; }

.border-t-primary-lighbis-groove {
  border-top: 1px groove #f4f4fa; }

.border-t-primary-lighbis-ridge {
  border-top: 1px ridge #f4f4fa; }

.border-t-primary-lighbis-inset {
  border-top: 1px inset #f4f4fa; }

.border-t-primary-lighbis-outset {
  border-top: 1px outset #f4f4fa; }

.border-t-primary-lighbis-none {
  border-top: 1px none #f4f4fa; }

.border-t-primary-lighbis-hidden {
  border-top: 1px hidden #f4f4fa; }

.border-r-primary-lighbis {
  border-right: 1px solid #f4f4fa; }

.border-r-primary-lighbis-dotted {
  border-right: 1px dotted #f4f4fa; }

.border-r-primary-lighbis-dashed {
  border-right: 1px dashed #f4f4fa; }

.border-r-primary-lighbis-solid {
  border-right: 1px solid #f4f4fa; }

.border-r-primary-lighbis-double {
  border-right: 1px double #f4f4fa; }

.border-r-primary-lighbis-groove {
  border-right: 1px groove #f4f4fa; }

.border-r-primary-lighbis-ridge {
  border-right: 1px ridge #f4f4fa; }

.border-r-primary-lighbis-inset {
  border-right: 1px inset #f4f4fa; }

.border-r-primary-lighbis-outset {
  border-right: 1px outset #f4f4fa; }

.border-r-primary-lighbis-none {
  border-right: 1px none #f4f4fa; }

.border-r-primary-lighbis-hidden {
  border-right: 1px hidden #f4f4fa; }

.border-b-primary-lighbis {
  border-bottom: 1px solid #f4f4fa; }

.border-b-primary-lighbis-dotted {
  border-bottom: 1px dotted #f4f4fa; }

.border-b-primary-lighbis-dashed {
  border-bottom: 1px dashed #f4f4fa; }

.border-b-primary-lighbis-solid {
  border-bottom: 1px solid #f4f4fa; }

.border-b-primary-lighbis-double {
  border-bottom: 1px double #f4f4fa; }

.border-b-primary-lighbis-groove {
  border-bottom: 1px groove #f4f4fa; }

.border-b-primary-lighbis-ridge {
  border-bottom: 1px ridge #f4f4fa; }

.border-b-primary-lighbis-inset {
  border-bottom: 1px inset #f4f4fa; }

.border-b-primary-lighbis-outset {
  border-bottom: 1px outset #f4f4fa; }

.border-b-primary-lighbis-none {
  border-bottom: 1px none #f4f4fa; }

.border-b-primary-lighbis-hidden {
  border-bottom: 1px hidden #f4f4fa; }

.border-l-primary-lighbis {
  border-left: 1px solid #f4f4fa; }

.border-l-primary-lighbis-dotted {
  border-left: 1px dotted #f4f4fa; }

.border-l-primary-lighbis-dashed {
  border-left: 1px dashed #f4f4fa; }

.border-l-primary-lighbis-solid {
  border-left: 1px solid #f4f4fa; }

.border-l-primary-lighbis-double {
  border-left: 1px double #f4f4fa; }

.border-l-primary-lighbis-groove {
  border-left: 1px groove #f4f4fa; }

.border-l-primary-lighbis-ridge {
  border-left: 1px ridge #f4f4fa; }

.border-l-primary-lighbis-inset {
  border-left: 1px inset #f4f4fa; }

.border-l-primary-lighbis-outset {
  border-left: 1px outset #f4f4fa; }

.border-l-primary-lighbis-none {
  border-left: 1px none #f4f4fa; }

.border-l-primary-lighbis-hidden {
  border-left: 1px hidden #f4f4fa; }

.has-text-secondary {
  color: #eb6666; }

.has-background-secondary {
  background-color: #eb6666; }

.border-secondary {
  border: 1px solid #eb6666; }

.border-m-secondary:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #eb6666;
  transform: translateY(-50%); }

.border-secondary-dotted {
  border: 1px dotted #eb6666; }

.border-secondary-dashed {
  border: 1px dashed #eb6666; }

.border-secondary-solid {
  border: 1px solid #eb6666; }

.border-secondary-double {
  border: 1px double #eb6666; }

.border-secondary-groove {
  border: 1px groove #eb6666; }

.border-secondary-ridge {
  border: 1px ridge #eb6666; }

.border-secondary-inset {
  border: 1px inset #eb6666; }

.border-secondary-outset {
  border: 1px outset #eb6666; }

.border-secondary-none {
  border: 1px none #eb6666; }

.border-secondary-hidden {
  border: 1px hidden #eb6666; }

.border-t-secondary {
  border-top: 1px solid #eb6666; }

.border-t-secondary-dotted {
  border-top: 1px dotted #eb6666; }

.border-t-secondary-dashed {
  border-top: 1px dashed #eb6666; }

.border-t-secondary-solid {
  border-top: 1px solid #eb6666; }

.border-t-secondary-double {
  border-top: 1px double #eb6666; }

.border-t-secondary-groove {
  border-top: 1px groove #eb6666; }

.border-t-secondary-ridge {
  border-top: 1px ridge #eb6666; }

.border-t-secondary-inset {
  border-top: 1px inset #eb6666; }

.border-t-secondary-outset {
  border-top: 1px outset #eb6666; }

.border-t-secondary-none {
  border-top: 1px none #eb6666; }

.border-t-secondary-hidden {
  border-top: 1px hidden #eb6666; }

.border-r-secondary {
  border-right: 1px solid #eb6666; }

.border-r-secondary-dotted {
  border-right: 1px dotted #eb6666; }

.border-r-secondary-dashed {
  border-right: 1px dashed #eb6666; }

.border-r-secondary-solid {
  border-right: 1px solid #eb6666; }

.border-r-secondary-double {
  border-right: 1px double #eb6666; }

.border-r-secondary-groove {
  border-right: 1px groove #eb6666; }

.border-r-secondary-ridge {
  border-right: 1px ridge #eb6666; }

.border-r-secondary-inset {
  border-right: 1px inset #eb6666; }

.border-r-secondary-outset {
  border-right: 1px outset #eb6666; }

.border-r-secondary-none {
  border-right: 1px none #eb6666; }

.border-r-secondary-hidden {
  border-right: 1px hidden #eb6666; }

.border-b-secondary {
  border-bottom: 1px solid #eb6666; }

.border-b-secondary-dotted {
  border-bottom: 1px dotted #eb6666; }

.border-b-secondary-dashed {
  border-bottom: 1px dashed #eb6666; }

.border-b-secondary-solid {
  border-bottom: 1px solid #eb6666; }

.border-b-secondary-double {
  border-bottom: 1px double #eb6666; }

.border-b-secondary-groove {
  border-bottom: 1px groove #eb6666; }

.border-b-secondary-ridge {
  border-bottom: 1px ridge #eb6666; }

.border-b-secondary-inset {
  border-bottom: 1px inset #eb6666; }

.border-b-secondary-outset {
  border-bottom: 1px outset #eb6666; }

.border-b-secondary-none {
  border-bottom: 1px none #eb6666; }

.border-b-secondary-hidden {
  border-bottom: 1px hidden #eb6666; }

.border-l-secondary {
  border-left: 1px solid #eb6666; }

.border-l-secondary-dotted {
  border-left: 1px dotted #eb6666; }

.border-l-secondary-dashed {
  border-left: 1px dashed #eb6666; }

.border-l-secondary-solid {
  border-left: 1px solid #eb6666; }

.border-l-secondary-double {
  border-left: 1px double #eb6666; }

.border-l-secondary-groove {
  border-left: 1px groove #eb6666; }

.border-l-secondary-ridge {
  border-left: 1px ridge #eb6666; }

.border-l-secondary-inset {
  border-left: 1px inset #eb6666; }

.border-l-secondary-outset {
  border-left: 1px outset #eb6666; }

.border-l-secondary-none {
  border-left: 1px none #eb6666; }

.border-l-secondary-hidden {
  border-left: 1px hidden #eb6666; }

.has-text-danger {
  color: #cc3333; }

.has-background-danger {
  background-color: #cc3333; }

.border-danger {
  border: 1px solid #cc3333; }

.border-m-danger:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #cc3333;
  transform: translateY(-50%); }

.border-danger-dotted {
  border: 1px dotted #cc3333; }

.border-danger-dashed {
  border: 1px dashed #cc3333; }

.border-danger-solid {
  border: 1px solid #cc3333; }

.border-danger-double {
  border: 1px double #cc3333; }

.border-danger-groove {
  border: 1px groove #cc3333; }

.border-danger-ridge {
  border: 1px ridge #cc3333; }

.border-danger-inset {
  border: 1px inset #cc3333; }

.border-danger-outset {
  border: 1px outset #cc3333; }

.border-danger-none {
  border: 1px none #cc3333; }

.border-danger-hidden {
  border: 1px hidden #cc3333; }

.border-t-danger {
  border-top: 1px solid #cc3333; }

.border-t-danger-dotted {
  border-top: 1px dotted #cc3333; }

.border-t-danger-dashed {
  border-top: 1px dashed #cc3333; }

.border-t-danger-solid {
  border-top: 1px solid #cc3333; }

.border-t-danger-double {
  border-top: 1px double #cc3333; }

.border-t-danger-groove {
  border-top: 1px groove #cc3333; }

.border-t-danger-ridge {
  border-top: 1px ridge #cc3333; }

.border-t-danger-inset {
  border-top: 1px inset #cc3333; }

.border-t-danger-outset {
  border-top: 1px outset #cc3333; }

.border-t-danger-none {
  border-top: 1px none #cc3333; }

.border-t-danger-hidden {
  border-top: 1px hidden #cc3333; }

.border-r-danger {
  border-right: 1px solid #cc3333; }

.border-r-danger-dotted {
  border-right: 1px dotted #cc3333; }

.border-r-danger-dashed {
  border-right: 1px dashed #cc3333; }

.border-r-danger-solid {
  border-right: 1px solid #cc3333; }

.border-r-danger-double {
  border-right: 1px double #cc3333; }

.border-r-danger-groove {
  border-right: 1px groove #cc3333; }

.border-r-danger-ridge {
  border-right: 1px ridge #cc3333; }

.border-r-danger-inset {
  border-right: 1px inset #cc3333; }

.border-r-danger-outset {
  border-right: 1px outset #cc3333; }

.border-r-danger-none {
  border-right: 1px none #cc3333; }

.border-r-danger-hidden {
  border-right: 1px hidden #cc3333; }

.border-b-danger {
  border-bottom: 1px solid #cc3333; }

.border-b-danger-dotted {
  border-bottom: 1px dotted #cc3333; }

.border-b-danger-dashed {
  border-bottom: 1px dashed #cc3333; }

.border-b-danger-solid {
  border-bottom: 1px solid #cc3333; }

.border-b-danger-double {
  border-bottom: 1px double #cc3333; }

.border-b-danger-groove {
  border-bottom: 1px groove #cc3333; }

.border-b-danger-ridge {
  border-bottom: 1px ridge #cc3333; }

.border-b-danger-inset {
  border-bottom: 1px inset #cc3333; }

.border-b-danger-outset {
  border-bottom: 1px outset #cc3333; }

.border-b-danger-none {
  border-bottom: 1px none #cc3333; }

.border-b-danger-hidden {
  border-bottom: 1px hidden #cc3333; }

.border-l-danger {
  border-left: 1px solid #cc3333; }

.border-l-danger-dotted {
  border-left: 1px dotted #cc3333; }

.border-l-danger-dashed {
  border-left: 1px dashed #cc3333; }

.border-l-danger-solid {
  border-left: 1px solid #cc3333; }

.border-l-danger-double {
  border-left: 1px double #cc3333; }

.border-l-danger-groove {
  border-left: 1px groove #cc3333; }

.border-l-danger-ridge {
  border-left: 1px ridge #cc3333; }

.border-l-danger-inset {
  border-left: 1px inset #cc3333; }

.border-l-danger-outset {
  border-left: 1px outset #cc3333; }

.border-l-danger-none {
  border-left: 1px none #cc3333; }

.border-l-danger-hidden {
  border-left: 1px hidden #cc3333; }

.has-text-danger-light {
  color: #f6dada; }

.has-background-danger-light {
  background-color: #f6dada; }

.border-danger-light {
  border: 1px solid #f6dada; }

.border-m-danger-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f6dada;
  transform: translateY(-50%); }

.border-danger-light-dotted {
  border: 1px dotted #f6dada; }

.border-danger-light-dashed {
  border: 1px dashed #f6dada; }

.border-danger-light-solid {
  border: 1px solid #f6dada; }

.border-danger-light-double {
  border: 1px double #f6dada; }

.border-danger-light-groove {
  border: 1px groove #f6dada; }

.border-danger-light-ridge {
  border: 1px ridge #f6dada; }

.border-danger-light-inset {
  border: 1px inset #f6dada; }

.border-danger-light-outset {
  border: 1px outset #f6dada; }

.border-danger-light-none {
  border: 1px none #f6dada; }

.border-danger-light-hidden {
  border: 1px hidden #f6dada; }

.border-t-danger-light {
  border-top: 1px solid #f6dada; }

.border-t-danger-light-dotted {
  border-top: 1px dotted #f6dada; }

.border-t-danger-light-dashed {
  border-top: 1px dashed #f6dada; }

.border-t-danger-light-solid {
  border-top: 1px solid #f6dada; }

.border-t-danger-light-double {
  border-top: 1px double #f6dada; }

.border-t-danger-light-groove {
  border-top: 1px groove #f6dada; }

.border-t-danger-light-ridge {
  border-top: 1px ridge #f6dada; }

.border-t-danger-light-inset {
  border-top: 1px inset #f6dada; }

.border-t-danger-light-outset {
  border-top: 1px outset #f6dada; }

.border-t-danger-light-none {
  border-top: 1px none #f6dada; }

.border-t-danger-light-hidden {
  border-top: 1px hidden #f6dada; }

.border-r-danger-light {
  border-right: 1px solid #f6dada; }

.border-r-danger-light-dotted {
  border-right: 1px dotted #f6dada; }

.border-r-danger-light-dashed {
  border-right: 1px dashed #f6dada; }

.border-r-danger-light-solid {
  border-right: 1px solid #f6dada; }

.border-r-danger-light-double {
  border-right: 1px double #f6dada; }

.border-r-danger-light-groove {
  border-right: 1px groove #f6dada; }

.border-r-danger-light-ridge {
  border-right: 1px ridge #f6dada; }

.border-r-danger-light-inset {
  border-right: 1px inset #f6dada; }

.border-r-danger-light-outset {
  border-right: 1px outset #f6dada; }

.border-r-danger-light-none {
  border-right: 1px none #f6dada; }

.border-r-danger-light-hidden {
  border-right: 1px hidden #f6dada; }

.border-b-danger-light {
  border-bottom: 1px solid #f6dada; }

.border-b-danger-light-dotted {
  border-bottom: 1px dotted #f6dada; }

.border-b-danger-light-dashed {
  border-bottom: 1px dashed #f6dada; }

.border-b-danger-light-solid {
  border-bottom: 1px solid #f6dada; }

.border-b-danger-light-double {
  border-bottom: 1px double #f6dada; }

.border-b-danger-light-groove {
  border-bottom: 1px groove #f6dada; }

.border-b-danger-light-ridge {
  border-bottom: 1px ridge #f6dada; }

.border-b-danger-light-inset {
  border-bottom: 1px inset #f6dada; }

.border-b-danger-light-outset {
  border-bottom: 1px outset #f6dada; }

.border-b-danger-light-none {
  border-bottom: 1px none #f6dada; }

.border-b-danger-light-hidden {
  border-bottom: 1px hidden #f6dada; }

.border-l-danger-light {
  border-left: 1px solid #f6dada; }

.border-l-danger-light-dotted {
  border-left: 1px dotted #f6dada; }

.border-l-danger-light-dashed {
  border-left: 1px dashed #f6dada; }

.border-l-danger-light-solid {
  border-left: 1px solid #f6dada; }

.border-l-danger-light-double {
  border-left: 1px double #f6dada; }

.border-l-danger-light-groove {
  border-left: 1px groove #f6dada; }

.border-l-danger-light-ridge {
  border-left: 1px ridge #f6dada; }

.border-l-danger-light-inset {
  border-left: 1px inset #f6dada; }

.border-l-danger-light-outset {
  border-left: 1px outset #f6dada; }

.border-l-danger-light-none {
  border-left: 1px none #f6dada; }

.border-l-danger-light-hidden {
  border-left: 1px hidden #f6dada; }

.has-text-warning {
  color: #ffd500; }

.has-background-warning {
  background-color: #ffd500; }

.border-warning {
  border: 1px solid #ffd500; }

.border-m-warning:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ffd500;
  transform: translateY(-50%); }

.border-warning-dotted {
  border: 1px dotted #ffd500; }

.border-warning-dashed {
  border: 1px dashed #ffd500; }

.border-warning-solid {
  border: 1px solid #ffd500; }

.border-warning-double {
  border: 1px double #ffd500; }

.border-warning-groove {
  border: 1px groove #ffd500; }

.border-warning-ridge {
  border: 1px ridge #ffd500; }

.border-warning-inset {
  border: 1px inset #ffd500; }

.border-warning-outset {
  border: 1px outset #ffd500; }

.border-warning-none {
  border: 1px none #ffd500; }

.border-warning-hidden {
  border: 1px hidden #ffd500; }

.border-t-warning {
  border-top: 1px solid #ffd500; }

.border-t-warning-dotted {
  border-top: 1px dotted #ffd500; }

.border-t-warning-dashed {
  border-top: 1px dashed #ffd500; }

.border-t-warning-solid {
  border-top: 1px solid #ffd500; }

.border-t-warning-double {
  border-top: 1px double #ffd500; }

.border-t-warning-groove {
  border-top: 1px groove #ffd500; }

.border-t-warning-ridge {
  border-top: 1px ridge #ffd500; }

.border-t-warning-inset {
  border-top: 1px inset #ffd500; }

.border-t-warning-outset {
  border-top: 1px outset #ffd500; }

.border-t-warning-none {
  border-top: 1px none #ffd500; }

.border-t-warning-hidden {
  border-top: 1px hidden #ffd500; }

.border-r-warning {
  border-right: 1px solid #ffd500; }

.border-r-warning-dotted {
  border-right: 1px dotted #ffd500; }

.border-r-warning-dashed {
  border-right: 1px dashed #ffd500; }

.border-r-warning-solid {
  border-right: 1px solid #ffd500; }

.border-r-warning-double {
  border-right: 1px double #ffd500; }

.border-r-warning-groove {
  border-right: 1px groove #ffd500; }

.border-r-warning-ridge {
  border-right: 1px ridge #ffd500; }

.border-r-warning-inset {
  border-right: 1px inset #ffd500; }

.border-r-warning-outset {
  border-right: 1px outset #ffd500; }

.border-r-warning-none {
  border-right: 1px none #ffd500; }

.border-r-warning-hidden {
  border-right: 1px hidden #ffd500; }

.border-b-warning {
  border-bottom: 1px solid #ffd500; }

.border-b-warning-dotted {
  border-bottom: 1px dotted #ffd500; }

.border-b-warning-dashed {
  border-bottom: 1px dashed #ffd500; }

.border-b-warning-solid {
  border-bottom: 1px solid #ffd500; }

.border-b-warning-double {
  border-bottom: 1px double #ffd500; }

.border-b-warning-groove {
  border-bottom: 1px groove #ffd500; }

.border-b-warning-ridge {
  border-bottom: 1px ridge #ffd500; }

.border-b-warning-inset {
  border-bottom: 1px inset #ffd500; }

.border-b-warning-outset {
  border-bottom: 1px outset #ffd500; }

.border-b-warning-none {
  border-bottom: 1px none #ffd500; }

.border-b-warning-hidden {
  border-bottom: 1px hidden #ffd500; }

.border-l-warning {
  border-left: 1px solid #ffd500; }

.border-l-warning-dotted {
  border-left: 1px dotted #ffd500; }

.border-l-warning-dashed {
  border-left: 1px dashed #ffd500; }

.border-l-warning-solid {
  border-left: 1px solid #ffd500; }

.border-l-warning-double {
  border-left: 1px double #ffd500; }

.border-l-warning-groove {
  border-left: 1px groove #ffd500; }

.border-l-warning-ridge {
  border-left: 1px ridge #ffd500; }

.border-l-warning-inset {
  border-left: 1px inset #ffd500; }

.border-l-warning-outset {
  border-left: 1px outset #ffd500; }

.border-l-warning-none {
  border-left: 1px none #ffd500; }

.border-l-warning-hidden {
  border-left: 1px hidden #ffd500; }

.has-text-success {
  color: #66cc33; }

.has-background-success {
  background-color: #66cc33; }

.border-success {
  border: 1px solid #66cc33; }

.border-m-success:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #66cc33;
  transform: translateY(-50%); }

.border-success-dotted {
  border: 1px dotted #66cc33; }

.border-success-dashed {
  border: 1px dashed #66cc33; }

.border-success-solid {
  border: 1px solid #66cc33; }

.border-success-double {
  border: 1px double #66cc33; }

.border-success-groove {
  border: 1px groove #66cc33; }

.border-success-ridge {
  border: 1px ridge #66cc33; }

.border-success-inset {
  border: 1px inset #66cc33; }

.border-success-outset {
  border: 1px outset #66cc33; }

.border-success-none {
  border: 1px none #66cc33; }

.border-success-hidden {
  border: 1px hidden #66cc33; }

.border-t-success {
  border-top: 1px solid #66cc33; }

.border-t-success-dotted {
  border-top: 1px dotted #66cc33; }

.border-t-success-dashed {
  border-top: 1px dashed #66cc33; }

.border-t-success-solid {
  border-top: 1px solid #66cc33; }

.border-t-success-double {
  border-top: 1px double #66cc33; }

.border-t-success-groove {
  border-top: 1px groove #66cc33; }

.border-t-success-ridge {
  border-top: 1px ridge #66cc33; }

.border-t-success-inset {
  border-top: 1px inset #66cc33; }

.border-t-success-outset {
  border-top: 1px outset #66cc33; }

.border-t-success-none {
  border-top: 1px none #66cc33; }

.border-t-success-hidden {
  border-top: 1px hidden #66cc33; }

.border-r-success {
  border-right: 1px solid #66cc33; }

.border-r-success-dotted {
  border-right: 1px dotted #66cc33; }

.border-r-success-dashed {
  border-right: 1px dashed #66cc33; }

.border-r-success-solid {
  border-right: 1px solid #66cc33; }

.border-r-success-double {
  border-right: 1px double #66cc33; }

.border-r-success-groove {
  border-right: 1px groove #66cc33; }

.border-r-success-ridge {
  border-right: 1px ridge #66cc33; }

.border-r-success-inset {
  border-right: 1px inset #66cc33; }

.border-r-success-outset {
  border-right: 1px outset #66cc33; }

.border-r-success-none {
  border-right: 1px none #66cc33; }

.border-r-success-hidden {
  border-right: 1px hidden #66cc33; }

.border-b-success {
  border-bottom: 1px solid #66cc33; }

.border-b-success-dotted {
  border-bottom: 1px dotted #66cc33; }

.border-b-success-dashed {
  border-bottom: 1px dashed #66cc33; }

.border-b-success-solid {
  border-bottom: 1px solid #66cc33; }

.border-b-success-double {
  border-bottom: 1px double #66cc33; }

.border-b-success-groove {
  border-bottom: 1px groove #66cc33; }

.border-b-success-ridge {
  border-bottom: 1px ridge #66cc33; }

.border-b-success-inset {
  border-bottom: 1px inset #66cc33; }

.border-b-success-outset {
  border-bottom: 1px outset #66cc33; }

.border-b-success-none {
  border-bottom: 1px none #66cc33; }

.border-b-success-hidden {
  border-bottom: 1px hidden #66cc33; }

.border-l-success {
  border-left: 1px solid #66cc33; }

.border-l-success-dotted {
  border-left: 1px dotted #66cc33; }

.border-l-success-dashed {
  border-left: 1px dashed #66cc33; }

.border-l-success-solid {
  border-left: 1px solid #66cc33; }

.border-l-success-double {
  border-left: 1px double #66cc33; }

.border-l-success-groove {
  border-left: 1px groove #66cc33; }

.border-l-success-ridge {
  border-left: 1px ridge #66cc33; }

.border-l-success-inset {
  border-left: 1px inset #66cc33; }

.border-l-success-outset {
  border-left: 1px outset #66cc33; }

.border-l-success-none {
  border-left: 1px none #66cc33; }

.border-l-success-hidden {
  border-left: 1px hidden #66cc33; }

.has-text-success-light {
  color: #dbffe0; }

.has-background-success-light {
  background-color: #dbffe0; }

.border-success-light {
  border: 1px solid #dbffe0; }

.border-m-success-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dbffe0;
  transform: translateY(-50%); }

.border-success-light-dotted {
  border: 1px dotted #dbffe0; }

.border-success-light-dashed {
  border: 1px dashed #dbffe0; }

.border-success-light-solid {
  border: 1px solid #dbffe0; }

.border-success-light-double {
  border: 1px double #dbffe0; }

.border-success-light-groove {
  border: 1px groove #dbffe0; }

.border-success-light-ridge {
  border: 1px ridge #dbffe0; }

.border-success-light-inset {
  border: 1px inset #dbffe0; }

.border-success-light-outset {
  border: 1px outset #dbffe0; }

.border-success-light-none {
  border: 1px none #dbffe0; }

.border-success-light-hidden {
  border: 1px hidden #dbffe0; }

.border-t-success-light {
  border-top: 1px solid #dbffe0; }

.border-t-success-light-dotted {
  border-top: 1px dotted #dbffe0; }

.border-t-success-light-dashed {
  border-top: 1px dashed #dbffe0; }

.border-t-success-light-solid {
  border-top: 1px solid #dbffe0; }

.border-t-success-light-double {
  border-top: 1px double #dbffe0; }

.border-t-success-light-groove {
  border-top: 1px groove #dbffe0; }

.border-t-success-light-ridge {
  border-top: 1px ridge #dbffe0; }

.border-t-success-light-inset {
  border-top: 1px inset #dbffe0; }

.border-t-success-light-outset {
  border-top: 1px outset #dbffe0; }

.border-t-success-light-none {
  border-top: 1px none #dbffe0; }

.border-t-success-light-hidden {
  border-top: 1px hidden #dbffe0; }

.border-r-success-light {
  border-right: 1px solid #dbffe0; }

.border-r-success-light-dotted {
  border-right: 1px dotted #dbffe0; }

.border-r-success-light-dashed {
  border-right: 1px dashed #dbffe0; }

.border-r-success-light-solid {
  border-right: 1px solid #dbffe0; }

.border-r-success-light-double {
  border-right: 1px double #dbffe0; }

.border-r-success-light-groove {
  border-right: 1px groove #dbffe0; }

.border-r-success-light-ridge {
  border-right: 1px ridge #dbffe0; }

.border-r-success-light-inset {
  border-right: 1px inset #dbffe0; }

.border-r-success-light-outset {
  border-right: 1px outset #dbffe0; }

.border-r-success-light-none {
  border-right: 1px none #dbffe0; }

.border-r-success-light-hidden {
  border-right: 1px hidden #dbffe0; }

.border-b-success-light {
  border-bottom: 1px solid #dbffe0; }

.border-b-success-light-dotted {
  border-bottom: 1px dotted #dbffe0; }

.border-b-success-light-dashed {
  border-bottom: 1px dashed #dbffe0; }

.border-b-success-light-solid {
  border-bottom: 1px solid #dbffe0; }

.border-b-success-light-double {
  border-bottom: 1px double #dbffe0; }

.border-b-success-light-groove {
  border-bottom: 1px groove #dbffe0; }

.border-b-success-light-ridge {
  border-bottom: 1px ridge #dbffe0; }

.border-b-success-light-inset {
  border-bottom: 1px inset #dbffe0; }

.border-b-success-light-outset {
  border-bottom: 1px outset #dbffe0; }

.border-b-success-light-none {
  border-bottom: 1px none #dbffe0; }

.border-b-success-light-hidden {
  border-bottom: 1px hidden #dbffe0; }

.border-l-success-light {
  border-left: 1px solid #dbffe0; }

.border-l-success-light-dotted {
  border-left: 1px dotted #dbffe0; }

.border-l-success-light-dashed {
  border-left: 1px dashed #dbffe0; }

.border-l-success-light-solid {
  border-left: 1px solid #dbffe0; }

.border-l-success-light-double {
  border-left: 1px double #dbffe0; }

.border-l-success-light-groove {
  border-left: 1px groove #dbffe0; }

.border-l-success-light-ridge {
  border-left: 1px ridge #dbffe0; }

.border-l-success-light-inset {
  border-left: 1px inset #dbffe0; }

.border-l-success-light-outset {
  border-left: 1px outset #dbffe0; }

.border-l-success-light-none {
  border-left: 1px none #dbffe0; }

.border-l-success-light-hidden {
  border-left: 1px hidden #dbffe0; }

.has-text-info {
  color: #209fee; }

.has-background-info {
  background-color: #209fee; }

.border-info {
  border: 1px solid #209fee; }

.border-m-info:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #209fee;
  transform: translateY(-50%); }

.border-info-dotted {
  border: 1px dotted #209fee; }

.border-info-dashed {
  border: 1px dashed #209fee; }

.border-info-solid {
  border: 1px solid #209fee; }

.border-info-double {
  border: 1px double #209fee; }

.border-info-groove {
  border: 1px groove #209fee; }

.border-info-ridge {
  border: 1px ridge #209fee; }

.border-info-inset {
  border: 1px inset #209fee; }

.border-info-outset {
  border: 1px outset #209fee; }

.border-info-none {
  border: 1px none #209fee; }

.border-info-hidden {
  border: 1px hidden #209fee; }

.border-t-info {
  border-top: 1px solid #209fee; }

.border-t-info-dotted {
  border-top: 1px dotted #209fee; }

.border-t-info-dashed {
  border-top: 1px dashed #209fee; }

.border-t-info-solid {
  border-top: 1px solid #209fee; }

.border-t-info-double {
  border-top: 1px double #209fee; }

.border-t-info-groove {
  border-top: 1px groove #209fee; }

.border-t-info-ridge {
  border-top: 1px ridge #209fee; }

.border-t-info-inset {
  border-top: 1px inset #209fee; }

.border-t-info-outset {
  border-top: 1px outset #209fee; }

.border-t-info-none {
  border-top: 1px none #209fee; }

.border-t-info-hidden {
  border-top: 1px hidden #209fee; }

.border-r-info {
  border-right: 1px solid #209fee; }

.border-r-info-dotted {
  border-right: 1px dotted #209fee; }

.border-r-info-dashed {
  border-right: 1px dashed #209fee; }

.border-r-info-solid {
  border-right: 1px solid #209fee; }

.border-r-info-double {
  border-right: 1px double #209fee; }

.border-r-info-groove {
  border-right: 1px groove #209fee; }

.border-r-info-ridge {
  border-right: 1px ridge #209fee; }

.border-r-info-inset {
  border-right: 1px inset #209fee; }

.border-r-info-outset {
  border-right: 1px outset #209fee; }

.border-r-info-none {
  border-right: 1px none #209fee; }

.border-r-info-hidden {
  border-right: 1px hidden #209fee; }

.border-b-info {
  border-bottom: 1px solid #209fee; }

.border-b-info-dotted {
  border-bottom: 1px dotted #209fee; }

.border-b-info-dashed {
  border-bottom: 1px dashed #209fee; }

.border-b-info-solid {
  border-bottom: 1px solid #209fee; }

.border-b-info-double {
  border-bottom: 1px double #209fee; }

.border-b-info-groove {
  border-bottom: 1px groove #209fee; }

.border-b-info-ridge {
  border-bottom: 1px ridge #209fee; }

.border-b-info-inset {
  border-bottom: 1px inset #209fee; }

.border-b-info-outset {
  border-bottom: 1px outset #209fee; }

.border-b-info-none {
  border-bottom: 1px none #209fee; }

.border-b-info-hidden {
  border-bottom: 1px hidden #209fee; }

.border-l-info {
  border-left: 1px solid #209fee; }

.border-l-info-dotted {
  border-left: 1px dotted #209fee; }

.border-l-info-dashed {
  border-left: 1px dashed #209fee; }

.border-l-info-solid {
  border-left: 1px solid #209fee; }

.border-l-info-double {
  border-left: 1px double #209fee; }

.border-l-info-groove {
  border-left: 1px groove #209fee; }

.border-l-info-ridge {
  border-left: 1px ridge #209fee; }

.border-l-info-inset {
  border-left: 1px inset #209fee; }

.border-l-info-outset {
  border-left: 1px outset #209fee; }

.border-l-info-none {
  border-left: 1px none #209fee; }

.border-l-info-hidden {
  border-left: 1px hidden #209fee; }

.has-text-black {
  color: #0a0a0a; }

.has-background-black {
  background-color: #0a0a0a; }

.border-black {
  border: 1px solid #0a0a0a; }

.border-black-dotted {
  border: 1px dotted #0a0a0a; }

.border-black-dashed {
  border: 1px dashed #0a0a0a; }

.border-black-solid {
  border: 1px solid #0a0a0a; }

.border-black-double {
  border: 1px double #0a0a0a; }

.border-black-groove {
  border: 1px groove #0a0a0a; }

.border-black-ridge {
  border: 1px ridge #0a0a0a; }

.border-black-inset {
  border: 1px inset #0a0a0a; }

.border-black-outset {
  border: 1px outset #0a0a0a; }

.border-black-none {
  border: 1px none #0a0a0a; }

.border-black-hidden {
  border: 1px hidden #0a0a0a; }

.border-t-black {
  border-top: 1px solid #0a0a0a; }

.border-t-black-dotted {
  border-top: 1px dotted #0a0a0a; }

.border-t-black-dashed {
  border-top: 1px dashed #0a0a0a; }

.border-t-black-solid {
  border-top: 1px solid #0a0a0a; }

.border-t-black-double {
  border-top: 1px double #0a0a0a; }

.border-t-black-groove {
  border-top: 1px groove #0a0a0a; }

.border-t-black-ridge {
  border-top: 1px ridge #0a0a0a; }

.border-t-black-inset {
  border-top: 1px inset #0a0a0a; }

.border-t-black-outset {
  border-top: 1px outset #0a0a0a; }

.border-t-black-none {
  border-top: 1px none #0a0a0a; }

.border-t-black-hidden {
  border-top: 1px hidden #0a0a0a; }

.border-r-black {
  border-right: 1px solid #0a0a0a; }

.border-r-black-dotted {
  border-right: 1px dotted #0a0a0a; }

.border-r-black-dashed {
  border-right: 1px dashed #0a0a0a; }

.border-r-black-solid {
  border-right: 1px solid #0a0a0a; }

.border-r-black-double {
  border-right: 1px double #0a0a0a; }

.border-r-black-groove {
  border-right: 1px groove #0a0a0a; }

.border-r-black-ridge {
  border-right: 1px ridge #0a0a0a; }

.border-r-black-inset {
  border-right: 1px inset #0a0a0a; }

.border-r-black-outset {
  border-right: 1px outset #0a0a0a; }

.border-r-black-none {
  border-right: 1px none #0a0a0a; }

.border-r-black-hidden {
  border-right: 1px hidden #0a0a0a; }

.border-b-black {
  border-bottom: 1px solid #0a0a0a; }

.border-b-black-dotted {
  border-bottom: 1px dotted #0a0a0a; }

.border-b-black-dashed {
  border-bottom: 1px dashed #0a0a0a; }

.border-b-black-solid {
  border-bottom: 1px solid #0a0a0a; }

.border-b-black-double {
  border-bottom: 1px double #0a0a0a; }

.border-b-black-groove {
  border-bottom: 1px groove #0a0a0a; }

.border-b-black-ridge {
  border-bottom: 1px ridge #0a0a0a; }

.border-b-black-inset {
  border-bottom: 1px inset #0a0a0a; }

.border-b-black-outset {
  border-bottom: 1px outset #0a0a0a; }

.border-b-black-none {
  border-bottom: 1px none #0a0a0a; }

.border-b-black-hidden {
  border-bottom: 1px hidden #0a0a0a; }

.border-l-black {
  border-left: 1px solid #0a0a0a; }

.border-l-black-dotted {
  border-left: 1px dotted #0a0a0a; }

.border-l-black-dashed {
  border-left: 1px dashed #0a0a0a; }

.border-l-black-solid {
  border-left: 1px solid #0a0a0a; }

.border-l-black-double {
  border-left: 1px double #0a0a0a; }

.border-l-black-groove {
  border-left: 1px groove #0a0a0a; }

.border-l-black-ridge {
  border-left: 1px ridge #0a0a0a; }

.border-l-black-inset {
  border-left: 1px inset #0a0a0a; }

.border-l-black-outset {
  border-left: 1px outset #0a0a0a; }

.border-l-black-none {
  border-left: 1px none #0a0a0a; }

.border-l-black-hidden {
  border-left: 1px hidden #0a0a0a; }

.has-text-black-bis {
  color: #121212; }

.has-background-black-bis {
  background-color: #121212; }

.border-black-bis {
  border: 1px solid #121212; }

.border-black-bis-dotted {
  border: 1px dotted #121212; }

.border-black-bis-dashed {
  border: 1px dashed #121212; }

.border-black-bis-solid {
  border: 1px solid #121212; }

.border-black-bis-double {
  border: 1px double #121212; }

.border-black-bis-groove {
  border: 1px groove #121212; }

.border-black-bis-ridge {
  border: 1px ridge #121212; }

.border-black-bis-inset {
  border: 1px inset #121212; }

.border-black-bis-outset {
  border: 1px outset #121212; }

.border-black-bis-none {
  border: 1px none #121212; }

.border-black-bis-hidden {
  border: 1px hidden #121212; }

.border-t-black-bis {
  border-top: 1px solid #121212; }

.border-t-black-bis-dotted {
  border-top: 1px dotted #121212; }

.border-t-black-bis-dashed {
  border-top: 1px dashed #121212; }

.border-t-black-bis-solid {
  border-top: 1px solid #121212; }

.border-t-black-bis-double {
  border-top: 1px double #121212; }

.border-t-black-bis-groove {
  border-top: 1px groove #121212; }

.border-t-black-bis-ridge {
  border-top: 1px ridge #121212; }

.border-t-black-bis-inset {
  border-top: 1px inset #121212; }

.border-t-black-bis-outset {
  border-top: 1px outset #121212; }

.border-t-black-bis-none {
  border-top: 1px none #121212; }

.border-t-black-bis-hidden {
  border-top: 1px hidden #121212; }

.border-r-black-bis {
  border-right: 1px solid #121212; }

.border-r-black-bis-dotted {
  border-right: 1px dotted #121212; }

.border-r-black-bis-dashed {
  border-right: 1px dashed #121212; }

.border-r-black-bis-solid {
  border-right: 1px solid #121212; }

.border-r-black-bis-double {
  border-right: 1px double #121212; }

.border-r-black-bis-groove {
  border-right: 1px groove #121212; }

.border-r-black-bis-ridge {
  border-right: 1px ridge #121212; }

.border-r-black-bis-inset {
  border-right: 1px inset #121212; }

.border-r-black-bis-outset {
  border-right: 1px outset #121212; }

.border-r-black-bis-none {
  border-right: 1px none #121212; }

.border-r-black-bis-hidden {
  border-right: 1px hidden #121212; }

.border-b-black-bis {
  border-bottom: 1px solid #121212; }

.border-b-black-bis-dotted {
  border-bottom: 1px dotted #121212; }

.border-b-black-bis-dashed {
  border-bottom: 1px dashed #121212; }

.border-b-black-bis-solid {
  border-bottom: 1px solid #121212; }

.border-b-black-bis-double {
  border-bottom: 1px double #121212; }

.border-b-black-bis-groove {
  border-bottom: 1px groove #121212; }

.border-b-black-bis-ridge {
  border-bottom: 1px ridge #121212; }

.border-b-black-bis-inset {
  border-bottom: 1px inset #121212; }

.border-b-black-bis-outset {
  border-bottom: 1px outset #121212; }

.border-b-black-bis-none {
  border-bottom: 1px none #121212; }

.border-b-black-bis-hidden {
  border-bottom: 1px hidden #121212; }

.border-l-black-bis {
  border-left: 1px solid #121212; }

.border-l-black-bis-dotted {
  border-left: 1px dotted #121212; }

.border-l-black-bis-dashed {
  border-left: 1px dashed #121212; }

.border-l-black-bis-solid {
  border-left: 1px solid #121212; }

.border-l-black-bis-double {
  border-left: 1px double #121212; }

.border-l-black-bis-groove {
  border-left: 1px groove #121212; }

.border-l-black-bis-ridge {
  border-left: 1px ridge #121212; }

.border-l-black-bis-inset {
  border-left: 1px inset #121212; }

.border-l-black-bis-outset {
  border-left: 1px outset #121212; }

.border-l-black-bis-none {
  border-left: 1px none #121212; }

.border-l-black-bis-hidden {
  border-left: 1px hidden #121212; }

.has-text-black-ter {
  color: #242424; }

.has-background-black-ter {
  background-color: #242424; }

.border-black-ter {
  border: 1px solid #242424; }

.border-black-ter-dotted {
  border: 1px dotted #242424; }

.border-black-ter-dashed {
  border: 1px dashed #242424; }

.border-black-ter-solid {
  border: 1px solid #242424; }

.border-black-ter-double {
  border: 1px double #242424; }

.border-black-ter-groove {
  border: 1px groove #242424; }

.border-black-ter-ridge {
  border: 1px ridge #242424; }

.border-black-ter-inset {
  border: 1px inset #242424; }

.border-black-ter-outset {
  border: 1px outset #242424; }

.border-black-ter-none {
  border: 1px none #242424; }

.border-black-ter-hidden {
  border: 1px hidden #242424; }

.border-t-black-ter {
  border-top: 1px solid #242424; }

.border-t-black-ter-dotted {
  border-top: 1px dotted #242424; }

.border-t-black-ter-dashed {
  border-top: 1px dashed #242424; }

.border-t-black-ter-solid {
  border-top: 1px solid #242424; }

.border-t-black-ter-double {
  border-top: 1px double #242424; }

.border-t-black-ter-groove {
  border-top: 1px groove #242424; }

.border-t-black-ter-ridge {
  border-top: 1px ridge #242424; }

.border-t-black-ter-inset {
  border-top: 1px inset #242424; }

.border-t-black-ter-outset {
  border-top: 1px outset #242424; }

.border-t-black-ter-none {
  border-top: 1px none #242424; }

.border-t-black-ter-hidden {
  border-top: 1px hidden #242424; }

.border-r-black-ter {
  border-right: 1px solid #242424; }

.border-r-black-ter-dotted {
  border-right: 1px dotted #242424; }

.border-r-black-ter-dashed {
  border-right: 1px dashed #242424; }

.border-r-black-ter-solid {
  border-right: 1px solid #242424; }

.border-r-black-ter-double {
  border-right: 1px double #242424; }

.border-r-black-ter-groove {
  border-right: 1px groove #242424; }

.border-r-black-ter-ridge {
  border-right: 1px ridge #242424; }

.border-r-black-ter-inset {
  border-right: 1px inset #242424; }

.border-r-black-ter-outset {
  border-right: 1px outset #242424; }

.border-r-black-ter-none {
  border-right: 1px none #242424; }

.border-r-black-ter-hidden {
  border-right: 1px hidden #242424; }

.border-b-black-ter {
  border-bottom: 1px solid #242424; }

.border-b-black-ter-dotted {
  border-bottom: 1px dotted #242424; }

.border-b-black-ter-dashed {
  border-bottom: 1px dashed #242424; }

.border-b-black-ter-solid {
  border-bottom: 1px solid #242424; }

.border-b-black-ter-double {
  border-bottom: 1px double #242424; }

.border-b-black-ter-groove {
  border-bottom: 1px groove #242424; }

.border-b-black-ter-ridge {
  border-bottom: 1px ridge #242424; }

.border-b-black-ter-inset {
  border-bottom: 1px inset #242424; }

.border-b-black-ter-outset {
  border-bottom: 1px outset #242424; }

.border-b-black-ter-none {
  border-bottom: 1px none #242424; }

.border-b-black-ter-hidden {
  border-bottom: 1px hidden #242424; }

.border-l-black-ter {
  border-left: 1px solid #242424; }

.border-l-black-ter-dotted {
  border-left: 1px dotted #242424; }

.border-l-black-ter-dashed {
  border-left: 1px dashed #242424; }

.border-l-black-ter-solid {
  border-left: 1px solid #242424; }

.border-l-black-ter-double {
  border-left: 1px double #242424; }

.border-l-black-ter-groove {
  border-left: 1px groove #242424; }

.border-l-black-ter-ridge {
  border-left: 1px ridge #242424; }

.border-l-black-ter-inset {
  border-left: 1px inset #242424; }

.border-l-black-ter-outset {
  border-left: 1px outset #242424; }

.border-l-black-ter-none {
  border-left: 1px none #242424; }

.border-l-black-ter-hidden {
  border-left: 1px hidden #242424; }

.has-text-grey-darker {
  color: #363636; }

.has-background-grey-darker {
  background-color: #363636; }

.border-grey-darker {
  border: 1px solid #363636; }

.border-grey-darker-dotted {
  border: 1px dotted #363636; }

.border-grey-darker-dashed {
  border: 1px dashed #363636; }

.border-grey-darker-solid {
  border: 1px solid #363636; }

.border-grey-darker-double {
  border: 1px double #363636; }

.border-grey-darker-groove {
  border: 1px groove #363636; }

.border-grey-darker-ridge {
  border: 1px ridge #363636; }

.border-grey-darker-inset {
  border: 1px inset #363636; }

.border-grey-darker-outset {
  border: 1px outset #363636; }

.border-grey-darker-none {
  border: 1px none #363636; }

.border-grey-darker-hidden {
  border: 1px hidden #363636; }

.border-t-grey-darker {
  border-top: 1px solid #363636; }

.border-t-grey-darker-dotted {
  border-top: 1px dotted #363636; }

.border-t-grey-darker-dashed {
  border-top: 1px dashed #363636; }

.border-t-grey-darker-solid {
  border-top: 1px solid #363636; }

.border-t-grey-darker-double {
  border-top: 1px double #363636; }

.border-t-grey-darker-groove {
  border-top: 1px groove #363636; }

.border-t-grey-darker-ridge {
  border-top: 1px ridge #363636; }

.border-t-grey-darker-inset {
  border-top: 1px inset #363636; }

.border-t-grey-darker-outset {
  border-top: 1px outset #363636; }

.border-t-grey-darker-none {
  border-top: 1px none #363636; }

.border-t-grey-darker-hidden {
  border-top: 1px hidden #363636; }

.border-r-grey-darker {
  border-right: 1px solid #363636; }

.border-r-grey-darker-dotted {
  border-right: 1px dotted #363636; }

.border-r-grey-darker-dashed {
  border-right: 1px dashed #363636; }

.border-r-grey-darker-solid {
  border-right: 1px solid #363636; }

.border-r-grey-darker-double {
  border-right: 1px double #363636; }

.border-r-grey-darker-groove {
  border-right: 1px groove #363636; }

.border-r-grey-darker-ridge {
  border-right: 1px ridge #363636; }

.border-r-grey-darker-inset {
  border-right: 1px inset #363636; }

.border-r-grey-darker-outset {
  border-right: 1px outset #363636; }

.border-r-grey-darker-none {
  border-right: 1px none #363636; }

.border-r-grey-darker-hidden {
  border-right: 1px hidden #363636; }

.border-b-grey-darker {
  border-bottom: 1px solid #363636; }

.border-b-grey-darker-dotted {
  border-bottom: 1px dotted #363636; }

.border-b-grey-darker-dashed {
  border-bottom: 1px dashed #363636; }

.border-b-grey-darker-solid {
  border-bottom: 1px solid #363636; }

.border-b-grey-darker-double {
  border-bottom: 1px double #363636; }

.border-b-grey-darker-groove {
  border-bottom: 1px groove #363636; }

.border-b-grey-darker-ridge {
  border-bottom: 1px ridge #363636; }

.border-b-grey-darker-inset {
  border-bottom: 1px inset #363636; }

.border-b-grey-darker-outset {
  border-bottom: 1px outset #363636; }

.border-b-grey-darker-none {
  border-bottom: 1px none #363636; }

.border-b-grey-darker-hidden {
  border-bottom: 1px hidden #363636; }

.border-l-grey-darker {
  border-left: 1px solid #363636; }

.border-l-grey-darker-dotted {
  border-left: 1px dotted #363636; }

.border-l-grey-darker-dashed {
  border-left: 1px dashed #363636; }

.border-l-grey-darker-solid {
  border-left: 1px solid #363636; }

.border-l-grey-darker-double {
  border-left: 1px double #363636; }

.border-l-grey-darker-groove {
  border-left: 1px groove #363636; }

.border-l-grey-darker-ridge {
  border-left: 1px ridge #363636; }

.border-l-grey-darker-inset {
  border-left: 1px inset #363636; }

.border-l-grey-darker-outset {
  border-left: 1px outset #363636; }

.border-l-grey-darker-none {
  border-left: 1px none #363636; }

.border-l-grey-darker-hidden {
  border-left: 1px hidden #363636; }

.has-text-grey-dark {
  color: #4a4a4a; }

.has-background-grey-dark {
  background-color: #4a4a4a; }

.border-grey-dark {
  border: 1px solid #4a4a4a; }

.border-grey-dark-dotted {
  border: 1px dotted #4a4a4a; }

.border-grey-dark-dashed {
  border: 1px dashed #4a4a4a; }

.border-grey-dark-solid {
  border: 1px solid #4a4a4a; }

.border-grey-dark-double {
  border: 1px double #4a4a4a; }

.border-grey-dark-groove {
  border: 1px groove #4a4a4a; }

.border-grey-dark-ridge {
  border: 1px ridge #4a4a4a; }

.border-grey-dark-inset {
  border: 1px inset #4a4a4a; }

.border-grey-dark-outset {
  border: 1px outset #4a4a4a; }

.border-grey-dark-none {
  border: 1px none #4a4a4a; }

.border-grey-dark-hidden {
  border: 1px hidden #4a4a4a; }

.border-t-grey-dark {
  border-top: 1px solid #4a4a4a; }

.border-t-grey-dark-dotted {
  border-top: 1px dotted #4a4a4a; }

.border-t-grey-dark-dashed {
  border-top: 1px dashed #4a4a4a; }

.border-t-grey-dark-solid {
  border-top: 1px solid #4a4a4a; }

.border-t-grey-dark-double {
  border-top: 1px double #4a4a4a; }

.border-t-grey-dark-groove {
  border-top: 1px groove #4a4a4a; }

.border-t-grey-dark-ridge {
  border-top: 1px ridge #4a4a4a; }

.border-t-grey-dark-inset {
  border-top: 1px inset #4a4a4a; }

.border-t-grey-dark-outset {
  border-top: 1px outset #4a4a4a; }

.border-t-grey-dark-none {
  border-top: 1px none #4a4a4a; }

.border-t-grey-dark-hidden {
  border-top: 1px hidden #4a4a4a; }

.border-r-grey-dark {
  border-right: 1px solid #4a4a4a; }

.border-r-grey-dark-dotted {
  border-right: 1px dotted #4a4a4a; }

.border-r-grey-dark-dashed {
  border-right: 1px dashed #4a4a4a; }

.border-r-grey-dark-solid {
  border-right: 1px solid #4a4a4a; }

.border-r-grey-dark-double {
  border-right: 1px double #4a4a4a; }

.border-r-grey-dark-groove {
  border-right: 1px groove #4a4a4a; }

.border-r-grey-dark-ridge {
  border-right: 1px ridge #4a4a4a; }

.border-r-grey-dark-inset {
  border-right: 1px inset #4a4a4a; }

.border-r-grey-dark-outset {
  border-right: 1px outset #4a4a4a; }

.border-r-grey-dark-none {
  border-right: 1px none #4a4a4a; }

.border-r-grey-dark-hidden {
  border-right: 1px hidden #4a4a4a; }

.border-b-grey-dark {
  border-bottom: 1px solid #4a4a4a; }

.border-b-grey-dark-dotted {
  border-bottom: 1px dotted #4a4a4a; }

.border-b-grey-dark-dashed {
  border-bottom: 1px dashed #4a4a4a; }

.border-b-grey-dark-solid {
  border-bottom: 1px solid #4a4a4a; }

.border-b-grey-dark-double {
  border-bottom: 1px double #4a4a4a; }

.border-b-grey-dark-groove {
  border-bottom: 1px groove #4a4a4a; }

.border-b-grey-dark-ridge {
  border-bottom: 1px ridge #4a4a4a; }

.border-b-grey-dark-inset {
  border-bottom: 1px inset #4a4a4a; }

.border-b-grey-dark-outset {
  border-bottom: 1px outset #4a4a4a; }

.border-b-grey-dark-none {
  border-bottom: 1px none #4a4a4a; }

.border-b-grey-dark-hidden {
  border-bottom: 1px hidden #4a4a4a; }

.border-l-grey-dark {
  border-left: 1px solid #4a4a4a; }

.border-l-grey-dark-dotted {
  border-left: 1px dotted #4a4a4a; }

.border-l-grey-dark-dashed {
  border-left: 1px dashed #4a4a4a; }

.border-l-grey-dark-solid {
  border-left: 1px solid #4a4a4a; }

.border-l-grey-dark-double {
  border-left: 1px double #4a4a4a; }

.border-l-grey-dark-groove {
  border-left: 1px groove #4a4a4a; }

.border-l-grey-dark-ridge {
  border-left: 1px ridge #4a4a4a; }

.border-l-grey-dark-inset {
  border-left: 1px inset #4a4a4a; }

.border-l-grey-dark-outset {
  border-left: 1px outset #4a4a4a; }

.border-l-grey-dark-none {
  border-left: 1px none #4a4a4a; }

.border-l-grey-dark-hidden {
  border-left: 1px hidden #4a4a4a; }

.has-text-grey {
  color: #7a7a7a; }

.has-background-grey {
  background-color: #7a7a7a; }

.border-grey {
  border: 1px solid #7a7a7a; }

.border-grey-dotted {
  border: 1px dotted #7a7a7a; }

.border-grey-dashed {
  border: 1px dashed #7a7a7a; }

.border-grey-solid {
  border: 1px solid #7a7a7a; }

.border-grey-double {
  border: 1px double #7a7a7a; }

.border-grey-groove {
  border: 1px groove #7a7a7a; }

.border-grey-ridge {
  border: 1px ridge #7a7a7a; }

.border-grey-inset {
  border: 1px inset #7a7a7a; }

.border-grey-outset {
  border: 1px outset #7a7a7a; }

.border-grey-none {
  border: 1px none #7a7a7a; }

.border-grey-hidden {
  border: 1px hidden #7a7a7a; }

.border-t-grey {
  border-top: 1px solid #7a7a7a; }

.border-t-grey-dotted {
  border-top: 1px dotted #7a7a7a; }

.border-t-grey-dashed {
  border-top: 1px dashed #7a7a7a; }

.border-t-grey-solid {
  border-top: 1px solid #7a7a7a; }

.border-t-grey-double {
  border-top: 1px double #7a7a7a; }

.border-t-grey-groove {
  border-top: 1px groove #7a7a7a; }

.border-t-grey-ridge {
  border-top: 1px ridge #7a7a7a; }

.border-t-grey-inset {
  border-top: 1px inset #7a7a7a; }

.border-t-grey-outset {
  border-top: 1px outset #7a7a7a; }

.border-t-grey-none {
  border-top: 1px none #7a7a7a; }

.border-t-grey-hidden {
  border-top: 1px hidden #7a7a7a; }

.border-r-grey {
  border-right: 1px solid #7a7a7a; }

.border-r-grey-dotted {
  border-right: 1px dotted #7a7a7a; }

.border-r-grey-dashed {
  border-right: 1px dashed #7a7a7a; }

.border-r-grey-solid {
  border-right: 1px solid #7a7a7a; }

.border-r-grey-double {
  border-right: 1px double #7a7a7a; }

.border-r-grey-groove {
  border-right: 1px groove #7a7a7a; }

.border-r-grey-ridge {
  border-right: 1px ridge #7a7a7a; }

.border-r-grey-inset {
  border-right: 1px inset #7a7a7a; }

.border-r-grey-outset {
  border-right: 1px outset #7a7a7a; }

.border-r-grey-none {
  border-right: 1px none #7a7a7a; }

.border-r-grey-hidden {
  border-right: 1px hidden #7a7a7a; }

.border-b-grey {
  border-bottom: 1px solid #7a7a7a; }

.border-b-grey-dotted {
  border-bottom: 1px dotted #7a7a7a; }

.border-b-grey-dashed {
  border-bottom: 1px dashed #7a7a7a; }

.border-b-grey-solid {
  border-bottom: 1px solid #7a7a7a; }

.border-b-grey-double {
  border-bottom: 1px double #7a7a7a; }

.border-b-grey-groove {
  border-bottom: 1px groove #7a7a7a; }

.border-b-grey-ridge {
  border-bottom: 1px ridge #7a7a7a; }

.border-b-grey-inset {
  border-bottom: 1px inset #7a7a7a; }

.border-b-grey-outset {
  border-bottom: 1px outset #7a7a7a; }

.border-b-grey-none {
  border-bottom: 1px none #7a7a7a; }

.border-b-grey-hidden {
  border-bottom: 1px hidden #7a7a7a; }

.border-l-grey {
  border-left: 1px solid #7a7a7a; }

.border-l-grey-dotted {
  border-left: 1px dotted #7a7a7a; }

.border-l-grey-dashed {
  border-left: 1px dashed #7a7a7a; }

.border-l-grey-solid {
  border-left: 1px solid #7a7a7a; }

.border-l-grey-double {
  border-left: 1px double #7a7a7a; }

.border-l-grey-groove {
  border-left: 1px groove #7a7a7a; }

.border-l-grey-ridge {
  border-left: 1px ridge #7a7a7a; }

.border-l-grey-inset {
  border-left: 1px inset #7a7a7a; }

.border-l-grey-outset {
  border-left: 1px outset #7a7a7a; }

.border-l-grey-none {
  border-left: 1px none #7a7a7a; }

.border-l-grey-hidden {
  border-left: 1px hidden #7a7a7a; }

.has-text-grey-light {
  color: #b5b5b5; }

.has-background-grey-light {
  background-color: #b5b5b5; }

.border-grey-light {
  border: 1px solid #b5b5b5; }

.border-grey-light-dotted {
  border: 1px dotted #b5b5b5; }

.border-grey-light-dashed {
  border: 1px dashed #b5b5b5; }

.border-grey-light-solid {
  border: 1px solid #b5b5b5; }

.border-grey-light-double {
  border: 1px double #b5b5b5; }

.border-grey-light-groove {
  border: 1px groove #b5b5b5; }

.border-grey-light-ridge {
  border: 1px ridge #b5b5b5; }

.border-grey-light-inset {
  border: 1px inset #b5b5b5; }

.border-grey-light-outset {
  border: 1px outset #b5b5b5; }

.border-grey-light-none {
  border: 1px none #b5b5b5; }

.border-grey-light-hidden {
  border: 1px hidden #b5b5b5; }

.border-t-grey-light {
  border-top: 1px solid #b5b5b5; }

.border-t-grey-light-dotted {
  border-top: 1px dotted #b5b5b5; }

.border-t-grey-light-dashed {
  border-top: 1px dashed #b5b5b5; }

.border-t-grey-light-solid {
  border-top: 1px solid #b5b5b5; }

.border-t-grey-light-double {
  border-top: 1px double #b5b5b5; }

.border-t-grey-light-groove {
  border-top: 1px groove #b5b5b5; }

.border-t-grey-light-ridge {
  border-top: 1px ridge #b5b5b5; }

.border-t-grey-light-inset {
  border-top: 1px inset #b5b5b5; }

.border-t-grey-light-outset {
  border-top: 1px outset #b5b5b5; }

.border-t-grey-light-none {
  border-top: 1px none #b5b5b5; }

.border-t-grey-light-hidden {
  border-top: 1px hidden #b5b5b5; }

.border-r-grey-light {
  border-right: 1px solid #b5b5b5; }

.border-r-grey-light-dotted {
  border-right: 1px dotted #b5b5b5; }

.border-r-grey-light-dashed {
  border-right: 1px dashed #b5b5b5; }

.border-r-grey-light-solid {
  border-right: 1px solid #b5b5b5; }

.border-r-grey-light-double {
  border-right: 1px double #b5b5b5; }

.border-r-grey-light-groove {
  border-right: 1px groove #b5b5b5; }

.border-r-grey-light-ridge {
  border-right: 1px ridge #b5b5b5; }

.border-r-grey-light-inset {
  border-right: 1px inset #b5b5b5; }

.border-r-grey-light-outset {
  border-right: 1px outset #b5b5b5; }

.border-r-grey-light-none {
  border-right: 1px none #b5b5b5; }

.border-r-grey-light-hidden {
  border-right: 1px hidden #b5b5b5; }

.border-b-grey-light {
  border-bottom: 1px solid #b5b5b5; }

.border-b-grey-light-dotted {
  border-bottom: 1px dotted #b5b5b5; }

.border-b-grey-light-dashed {
  border-bottom: 1px dashed #b5b5b5; }

.border-b-grey-light-solid {
  border-bottom: 1px solid #b5b5b5; }

.border-b-grey-light-double {
  border-bottom: 1px double #b5b5b5; }

.border-b-grey-light-groove {
  border-bottom: 1px groove #b5b5b5; }

.border-b-grey-light-ridge {
  border-bottom: 1px ridge #b5b5b5; }

.border-b-grey-light-inset {
  border-bottom: 1px inset #b5b5b5; }

.border-b-grey-light-outset {
  border-bottom: 1px outset #b5b5b5; }

.border-b-grey-light-none {
  border-bottom: 1px none #b5b5b5; }

.border-b-grey-light-hidden {
  border-bottom: 1px hidden #b5b5b5; }

.border-l-grey-light {
  border-left: 1px solid #b5b5b5; }

.border-l-grey-light-dotted {
  border-left: 1px dotted #b5b5b5; }

.border-l-grey-light-dashed {
  border-left: 1px dashed #b5b5b5; }

.border-l-grey-light-solid {
  border-left: 1px solid #b5b5b5; }

.border-l-grey-light-double {
  border-left: 1px double #b5b5b5; }

.border-l-grey-light-groove {
  border-left: 1px groove #b5b5b5; }

.border-l-grey-light-ridge {
  border-left: 1px ridge #b5b5b5; }

.border-l-grey-light-inset {
  border-left: 1px inset #b5b5b5; }

.border-l-grey-light-outset {
  border-left: 1px outset #b5b5b5; }

.border-l-grey-light-none {
  border-left: 1px none #b5b5b5; }

.border-l-grey-light-hidden {
  border-left: 1px hidden #b5b5b5; }

.has-text-grey-lighter {
  color: #e6e6e6; }

.has-background-grey-lighter {
  background-color: #e6e6e6; }

.border-grey-lighter {
  border: 1px solid #e6e6e6; }

.border-grey-lighter-dotted {
  border: 1px dotted #e6e6e6; }

.border-grey-lighter-dashed {
  border: 1px dashed #e6e6e6; }

.border-grey-lighter-solid {
  border: 1px solid #e6e6e6; }

.border-grey-lighter-double {
  border: 1px double #e6e6e6; }

.border-grey-lighter-groove {
  border: 1px groove #e6e6e6; }

.border-grey-lighter-ridge {
  border: 1px ridge #e6e6e6; }

.border-grey-lighter-inset {
  border: 1px inset #e6e6e6; }

.border-grey-lighter-outset {
  border: 1px outset #e6e6e6; }

.border-grey-lighter-none {
  border: 1px none #e6e6e6; }

.border-grey-lighter-hidden {
  border: 1px hidden #e6e6e6; }

.border-t-grey-lighter {
  border-top: 1px solid #e6e6e6; }

.border-t-grey-lighter-dotted {
  border-top: 1px dotted #e6e6e6; }

.border-t-grey-lighter-dashed {
  border-top: 1px dashed #e6e6e6; }

.border-t-grey-lighter-solid {
  border-top: 1px solid #e6e6e6; }

.border-t-grey-lighter-double {
  border-top: 1px double #e6e6e6; }

.border-t-grey-lighter-groove {
  border-top: 1px groove #e6e6e6; }

.border-t-grey-lighter-ridge {
  border-top: 1px ridge #e6e6e6; }

.border-t-grey-lighter-inset {
  border-top: 1px inset #e6e6e6; }

.border-t-grey-lighter-outset {
  border-top: 1px outset #e6e6e6; }

.border-t-grey-lighter-none {
  border-top: 1px none #e6e6e6; }

.border-t-grey-lighter-hidden {
  border-top: 1px hidden #e6e6e6; }

.border-r-grey-lighter {
  border-right: 1px solid #e6e6e6; }

.border-r-grey-lighter-dotted {
  border-right: 1px dotted #e6e6e6; }

.border-r-grey-lighter-dashed {
  border-right: 1px dashed #e6e6e6; }

.border-r-grey-lighter-solid {
  border-right: 1px solid #e6e6e6; }

.border-r-grey-lighter-double {
  border-right: 1px double #e6e6e6; }

.border-r-grey-lighter-groove {
  border-right: 1px groove #e6e6e6; }

.border-r-grey-lighter-ridge {
  border-right: 1px ridge #e6e6e6; }

.border-r-grey-lighter-inset {
  border-right: 1px inset #e6e6e6; }

.border-r-grey-lighter-outset {
  border-right: 1px outset #e6e6e6; }

.border-r-grey-lighter-none {
  border-right: 1px none #e6e6e6; }

.border-r-grey-lighter-hidden {
  border-right: 1px hidden #e6e6e6; }

.border-b-grey-lighter {
  border-bottom: 1px solid #e6e6e6; }

.border-b-grey-lighter-dotted {
  border-bottom: 1px dotted #e6e6e6; }

.border-b-grey-lighter-dashed {
  border-bottom: 1px dashed #e6e6e6; }

.border-b-grey-lighter-solid {
  border-bottom: 1px solid #e6e6e6; }

.border-b-grey-lighter-double {
  border-bottom: 1px double #e6e6e6; }

.border-b-grey-lighter-groove {
  border-bottom: 1px groove #e6e6e6; }

.border-b-grey-lighter-ridge {
  border-bottom: 1px ridge #e6e6e6; }

.border-b-grey-lighter-inset {
  border-bottom: 1px inset #e6e6e6; }

.border-b-grey-lighter-outset {
  border-bottom: 1px outset #e6e6e6; }

.border-b-grey-lighter-none {
  border-bottom: 1px none #e6e6e6; }

.border-b-grey-lighter-hidden {
  border-bottom: 1px hidden #e6e6e6; }

.border-l-grey-lighter {
  border-left: 1px solid #e6e6e6; }

.border-l-grey-lighter-dotted {
  border-left: 1px dotted #e6e6e6; }

.border-l-grey-lighter-dashed {
  border-left: 1px dashed #e6e6e6; }

.border-l-grey-lighter-solid {
  border-left: 1px solid #e6e6e6; }

.border-l-grey-lighter-double {
  border-left: 1px double #e6e6e6; }

.border-l-grey-lighter-groove {
  border-left: 1px groove #e6e6e6; }

.border-l-grey-lighter-ridge {
  border-left: 1px ridge #e6e6e6; }

.border-l-grey-lighter-inset {
  border-left: 1px inset #e6e6e6; }

.border-l-grey-lighter-outset {
  border-left: 1px outset #e6e6e6; }

.border-l-grey-lighter-none {
  border-left: 1px none #e6e6e6; }

.border-l-grey-lighter-hidden {
  border-left: 1px hidden #e6e6e6; }

.has-text-white-ter {
  color: whitesmoke; }

.has-background-white-ter {
  background-color: whitesmoke; }

.border-white-ter {
  border: 1px solid whitesmoke; }

.border-white-ter-dotted {
  border: 1px dotted whitesmoke; }

.border-white-ter-dashed {
  border: 1px dashed whitesmoke; }

.border-white-ter-solid {
  border: 1px solid whitesmoke; }

.border-white-ter-double {
  border: 1px double whitesmoke; }

.border-white-ter-groove {
  border: 1px groove whitesmoke; }

.border-white-ter-ridge {
  border: 1px ridge whitesmoke; }

.border-white-ter-inset {
  border: 1px inset whitesmoke; }

.border-white-ter-outset {
  border: 1px outset whitesmoke; }

.border-white-ter-none {
  border: 1px none whitesmoke; }

.border-white-ter-hidden {
  border: 1px hidden whitesmoke; }

.border-t-white-ter {
  border-top: 1px solid whitesmoke; }

.border-t-white-ter-dotted {
  border-top: 1px dotted whitesmoke; }

.border-t-white-ter-dashed {
  border-top: 1px dashed whitesmoke; }

.border-t-white-ter-solid {
  border-top: 1px solid whitesmoke; }

.border-t-white-ter-double {
  border-top: 1px double whitesmoke; }

.border-t-white-ter-groove {
  border-top: 1px groove whitesmoke; }

.border-t-white-ter-ridge {
  border-top: 1px ridge whitesmoke; }

.border-t-white-ter-inset {
  border-top: 1px inset whitesmoke; }

.border-t-white-ter-outset {
  border-top: 1px outset whitesmoke; }

.border-t-white-ter-none {
  border-top: 1px none whitesmoke; }

.border-t-white-ter-hidden {
  border-top: 1px hidden whitesmoke; }

.border-r-white-ter {
  border-right: 1px solid whitesmoke; }

.border-r-white-ter-dotted {
  border-right: 1px dotted whitesmoke; }

.border-r-white-ter-dashed {
  border-right: 1px dashed whitesmoke; }

.border-r-white-ter-solid {
  border-right: 1px solid whitesmoke; }

.border-r-white-ter-double {
  border-right: 1px double whitesmoke; }

.border-r-white-ter-groove {
  border-right: 1px groove whitesmoke; }

.border-r-white-ter-ridge {
  border-right: 1px ridge whitesmoke; }

.border-r-white-ter-inset {
  border-right: 1px inset whitesmoke; }

.border-r-white-ter-outset {
  border-right: 1px outset whitesmoke; }

.border-r-white-ter-none {
  border-right: 1px none whitesmoke; }

.border-r-white-ter-hidden {
  border-right: 1px hidden whitesmoke; }

.border-b-white-ter {
  border-bottom: 1px solid whitesmoke; }

.border-b-white-ter-dotted {
  border-bottom: 1px dotted whitesmoke; }

.border-b-white-ter-dashed {
  border-bottom: 1px dashed whitesmoke; }

.border-b-white-ter-solid {
  border-bottom: 1px solid whitesmoke; }

.border-b-white-ter-double {
  border-bottom: 1px double whitesmoke; }

.border-b-white-ter-groove {
  border-bottom: 1px groove whitesmoke; }

.border-b-white-ter-ridge {
  border-bottom: 1px ridge whitesmoke; }

.border-b-white-ter-inset {
  border-bottom: 1px inset whitesmoke; }

.border-b-white-ter-outset {
  border-bottom: 1px outset whitesmoke; }

.border-b-white-ter-none {
  border-bottom: 1px none whitesmoke; }

.border-b-white-ter-hidden {
  border-bottom: 1px hidden whitesmoke; }

.border-l-white-ter {
  border-left: 1px solid whitesmoke; }

.border-l-white-ter-dotted {
  border-left: 1px dotted whitesmoke; }

.border-l-white-ter-dashed {
  border-left: 1px dashed whitesmoke; }

.border-l-white-ter-solid {
  border-left: 1px solid whitesmoke; }

.border-l-white-ter-double {
  border-left: 1px double whitesmoke; }

.border-l-white-ter-groove {
  border-left: 1px groove whitesmoke; }

.border-l-white-ter-ridge {
  border-left: 1px ridge whitesmoke; }

.border-l-white-ter-inset {
  border-left: 1px inset whitesmoke; }

.border-l-white-ter-outset {
  border-left: 1px outset whitesmoke; }

.border-l-white-ter-none {
  border-left: 1px none whitesmoke; }

.border-l-white-ter-hidden {
  border-left: 1px hidden whitesmoke; }

.has-text-white-bis {
  color: #fafafa; }

.has-background-white-bis {
  background-color: #fafafa; }

.border-white-bis {
  border: 1px solid #fafafa; }

.border-white-bis-dotted {
  border: 1px dotted #fafafa; }

.border-white-bis-dashed {
  border: 1px dashed #fafafa; }

.border-white-bis-solid {
  border: 1px solid #fafafa; }

.border-white-bis-double {
  border: 1px double #fafafa; }

.border-white-bis-groove {
  border: 1px groove #fafafa; }

.border-white-bis-ridge {
  border: 1px ridge #fafafa; }

.border-white-bis-inset {
  border: 1px inset #fafafa; }

.border-white-bis-outset {
  border: 1px outset #fafafa; }

.border-white-bis-none {
  border: 1px none #fafafa; }

.border-white-bis-hidden {
  border: 1px hidden #fafafa; }

.border-t-white-bis {
  border-top: 1px solid #fafafa; }

.border-t-white-bis-dotted {
  border-top: 1px dotted #fafafa; }

.border-t-white-bis-dashed {
  border-top: 1px dashed #fafafa; }

.border-t-white-bis-solid {
  border-top: 1px solid #fafafa; }

.border-t-white-bis-double {
  border-top: 1px double #fafafa; }

.border-t-white-bis-groove {
  border-top: 1px groove #fafafa; }

.border-t-white-bis-ridge {
  border-top: 1px ridge #fafafa; }

.border-t-white-bis-inset {
  border-top: 1px inset #fafafa; }

.border-t-white-bis-outset {
  border-top: 1px outset #fafafa; }

.border-t-white-bis-none {
  border-top: 1px none #fafafa; }

.border-t-white-bis-hidden {
  border-top: 1px hidden #fafafa; }

.border-r-white-bis {
  border-right: 1px solid #fafafa; }

.border-r-white-bis-dotted {
  border-right: 1px dotted #fafafa; }

.border-r-white-bis-dashed {
  border-right: 1px dashed #fafafa; }

.border-r-white-bis-solid {
  border-right: 1px solid #fafafa; }

.border-r-white-bis-double {
  border-right: 1px double #fafafa; }

.border-r-white-bis-groove {
  border-right: 1px groove #fafafa; }

.border-r-white-bis-ridge {
  border-right: 1px ridge #fafafa; }

.border-r-white-bis-inset {
  border-right: 1px inset #fafafa; }

.border-r-white-bis-outset {
  border-right: 1px outset #fafafa; }

.border-r-white-bis-none {
  border-right: 1px none #fafafa; }

.border-r-white-bis-hidden {
  border-right: 1px hidden #fafafa; }

.border-b-white-bis {
  border-bottom: 1px solid #fafafa; }

.border-b-white-bis-dotted {
  border-bottom: 1px dotted #fafafa; }

.border-b-white-bis-dashed {
  border-bottom: 1px dashed #fafafa; }

.border-b-white-bis-solid {
  border-bottom: 1px solid #fafafa; }

.border-b-white-bis-double {
  border-bottom: 1px double #fafafa; }

.border-b-white-bis-groove {
  border-bottom: 1px groove #fafafa; }

.border-b-white-bis-ridge {
  border-bottom: 1px ridge #fafafa; }

.border-b-white-bis-inset {
  border-bottom: 1px inset #fafafa; }

.border-b-white-bis-outset {
  border-bottom: 1px outset #fafafa; }

.border-b-white-bis-none {
  border-bottom: 1px none #fafafa; }

.border-b-white-bis-hidden {
  border-bottom: 1px hidden #fafafa; }

.border-l-white-bis {
  border-left: 1px solid #fafafa; }

.border-l-white-bis-dotted {
  border-left: 1px dotted #fafafa; }

.border-l-white-bis-dashed {
  border-left: 1px dashed #fafafa; }

.border-l-white-bis-solid {
  border-left: 1px solid #fafafa; }

.border-l-white-bis-double {
  border-left: 1px double #fafafa; }

.border-l-white-bis-groove {
  border-left: 1px groove #fafafa; }

.border-l-white-bis-ridge {
  border-left: 1px ridge #fafafa; }

.border-l-white-bis-inset {
  border-left: 1px inset #fafafa; }

.border-l-white-bis-outset {
  border-left: 1px outset #fafafa; }

.border-l-white-bis-none {
  border-left: 1px none #fafafa; }

.border-l-white-bis-hidden {
  border-left: 1px hidden #fafafa; }

.has-text-white {
  color: white; }

.has-background-white {
  background-color: white; }

.border-white {
  border: 1px solid white; }

.border-white-dotted {
  border: 1px dotted white; }

.border-white-dashed {
  border: 1px dashed white; }

.border-white-solid {
  border: 1px solid white; }

.border-white-double {
  border: 1px double white; }

.border-white-groove {
  border: 1px groove white; }

.border-white-ridge {
  border: 1px ridge white; }

.border-white-inset {
  border: 1px inset white; }

.border-white-outset {
  border: 1px outset white; }

.border-white-none {
  border: 1px none white; }

.border-white-hidden {
  border: 1px hidden white; }

.border-t-white {
  border-top: 1px solid white; }

.border-t-white-dotted {
  border-top: 1px dotted white; }

.border-t-white-dashed {
  border-top: 1px dashed white; }

.border-t-white-solid {
  border-top: 1px solid white; }

.border-t-white-double {
  border-top: 1px double white; }

.border-t-white-groove {
  border-top: 1px groove white; }

.border-t-white-ridge {
  border-top: 1px ridge white; }

.border-t-white-inset {
  border-top: 1px inset white; }

.border-t-white-outset {
  border-top: 1px outset white; }

.border-t-white-none {
  border-top: 1px none white; }

.border-t-white-hidden {
  border-top: 1px hidden white; }

.border-r-white {
  border-right: 1px solid white; }

.border-r-white-dotted {
  border-right: 1px dotted white; }

.border-r-white-dashed {
  border-right: 1px dashed white; }

.border-r-white-solid {
  border-right: 1px solid white; }

.border-r-white-double {
  border-right: 1px double white; }

.border-r-white-groove {
  border-right: 1px groove white; }

.border-r-white-ridge {
  border-right: 1px ridge white; }

.border-r-white-inset {
  border-right: 1px inset white; }

.border-r-white-outset {
  border-right: 1px outset white; }

.border-r-white-none {
  border-right: 1px none white; }

.border-r-white-hidden {
  border-right: 1px hidden white; }

.border-b-white {
  border-bottom: 1px solid white; }

.border-b-white-dotted {
  border-bottom: 1px dotted white; }

.border-b-white-dashed {
  border-bottom: 1px dashed white; }

.border-b-white-solid {
  border-bottom: 1px solid white; }

.border-b-white-double {
  border-bottom: 1px double white; }

.border-b-white-groove {
  border-bottom: 1px groove white; }

.border-b-white-ridge {
  border-bottom: 1px ridge white; }

.border-b-white-inset {
  border-bottom: 1px inset white; }

.border-b-white-outset {
  border-bottom: 1px outset white; }

.border-b-white-none {
  border-bottom: 1px none white; }

.border-b-white-hidden {
  border-bottom: 1px hidden white; }

.border-l-white {
  border-left: 1px solid white; }

.border-l-white-dotted {
  border-left: 1px dotted white; }

.border-l-white-dashed {
  border-left: 1px dashed white; }

.border-l-white-solid {
  border-left: 1px solid white; }

.border-l-white-double {
  border-left: 1px double white; }

.border-l-white-groove {
  border-left: 1px groove white; }

.border-l-white-ridge {
  border-left: 1px ridge white; }

.border-l-white-inset {
  border-left: 1px inset white; }

.border-l-white-outset {
  border-left: 1px outset white; }

.border-l-white-none {
  border-left: 1px none white; }

.border-l-white-hidden {
  border-left: 1px hidden white; }

.DateInput_input__small {
  font-size: 14px !important; }

.CalendarDay__selected_span {
  background: #ededf7 !important;
  color: #7a7a7a !important;
  border: 1px solid #dbdbf0 !important; }

.CalendarDay__selected {
  background: #7a7ab8 !important;
  border: 1px solid #dbdbf0 !important;
  color: white; }

.DateInput_input__focused {
  border-bottom: 2px solid #dbdbf0 !important; }

.CalendarDay__selected:hover {
  background: #4d4db3 !important;
  color: white; }

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #ededf7 !important;
  color: #7a7ab8 !important;
  border: 1px solid #dbdbf0 !important; }

.SingleDatePickerInput {
  overflow: hidden !important; }

.DateRangePickerInput_clearDates {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 20px;
  height: 20px;
  top: 46% !important;
  margin: 0 !important; }

.SingleDatePickerInput_clearDate {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 20px;
  height: 20px;
  top: 17px !important; }

.DateRangePickerInput__showClearDates {
  padding-right: 0px !important; }

.m-none {
  margin: 0; }

.p-none {
  padding: 0; }

.m-t-none {
  margin-top: 0; }

.p-t-none {
  padding-top: 0; }

.m-r-none {
  margin-right: 0; }

.p-r-none {
  padding-right: 0; }

.m-b-none {
  margin-bottom: 0; }

.p-b-none {
  padding-bottom: 0; }

.m-l-none {
  margin-left: 0; }

.p-l-none {
  padding-left: 0; }

.m-xxs {
  margin: 0.125rem; }

.p-xxs {
  padding: 0.125rem; }

.m-t-xxs {
  margin-top: 0.125rem; }

.p-t-xxs {
  padding-top: 0.125rem; }

.m-r-xxs {
  margin-right: 0.125rem; }

.p-r-xxs {
  padding-right: 0.125rem; }

.m-b-xxs {
  margin-bottom: 0.125rem; }

.p-b-xxs {
  padding-bottom: 0.125rem; }

.m-l-xxs {
  margin-left: 0.125rem; }

.p-l-xxs {
  padding-left: 0.125rem; }

.m-xs {
  margin: 0.25rem; }

.p-xs {
  padding: 0.25rem; }

.m-t-xs {
  margin-top: 0.25rem; }

.p-t-xs {
  padding-top: 0.25rem; }

.m-r-xs {
  margin-right: 0.25rem; }

.p-r-xs {
  padding-right: 0.25rem; }

.m-b-xs {
  margin-bottom: 0.25rem; }

.p-b-xs {
  padding-bottom: 0.25rem; }

.m-l-xs {
  margin-left: 0.25rem; }

.p-l-xs {
  padding-left: 0.25rem; }

.m-sm {
  margin: 0.6rem; }

.p-sm {
  padding: 0.6rem; }

.m-t-sm {
  margin-top: 0.6rem; }

.p-t-sm {
  padding-top: 0.6rem; }

.m-r-sm {
  margin-right: 0.6rem; }

.p-r-sm {
  padding-right: 0.6rem; }

.m-b-sm {
  margin-bottom: 0.6rem; }

.p-b-sm {
  padding-bottom: 0.6rem; }

.m-l-sm {
  margin-left: 0.6rem; }

.p-l-sm {
  padding-left: 0.6rem; }

.m-md {
  margin: 1rem; }

.p-md {
  padding: 1rem; }

.m-t-md {
  margin-top: 1rem; }

.p-t-md {
  padding-top: 1rem; }

.m-r-md {
  margin-right: 1rem; }

.p-r-md {
  padding-right: 1rem; }

.m-b-md {
  margin-bottom: 1rem; }

.p-b-md {
  padding-bottom: 1rem; }

.m-l-md {
  margin-left: 1rem; }

.p-l-md {
  padding-left: 1rem; }

.m-lg {
  margin: 2rem; }

.p-lg {
  padding: 2rem; }

.m-t-lg {
  margin-top: 2rem; }

.p-t-lg {
  padding-top: 2rem; }

.m-r-lg {
  margin-right: 2rem; }

.p-r-lg {
  padding-right: 2rem; }

.m-b-lg {
  margin-bottom: 2rem; }

.p-b-lg {
  padding-bottom: 2rem; }

.m-l-lg {
  margin-left: 2rem; }

.p-l-lg {
  padding-left: 2rem; }

.m-xl {
  margin: 4rem; }

.p-xl {
  padding: 4rem; }

.m-t-xl {
  margin-top: 4rem; }

.p-t-xl {
  padding-top: 4rem; }

.m-r-xl {
  margin-right: 4rem; }

.p-r-xl {
  padding-right: 4rem; }

.m-b-xl {
  margin-bottom: 4rem; }

.p-b-xl {
  padding-bottom: 4rem; }

.m-l-xl {
  margin-left: 4rem; }

.p-l-xl {
  padding-left: 4rem; }

.m-xxl {
  margin: 8rem; }

.p-xxl {
  padding: 8rem; }

.m-t-xxl {
  margin-top: 8rem; }

.p-t-xxl {
  padding-top: 8rem; }

.m-r-xxl {
  margin-right: 8rem; }

.p-r-xxl {
  padding-right: 8rem; }

.m-b-xxl {
  margin-bottom: 8rem; }

.p-b-xxl {
  padding-bottom: 8rem; }

.m-l-xxl {
  margin-left: 8rem; }

.p-l-xxl {
  padding-left: 8rem; }

.has-text-primary {
  color: #2d2f7b; }

.has-background-primary {
  background-color: #2d2f7b; }

.border-primary {
  border: 1px solid #2d2f7b; }

.border-m-primary:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #2d2f7b;
  transform: translateY(-50%); }

.border-primary-dotted {
  border: 1px dotted #2d2f7b; }

.border-primary-dashed {
  border: 1px dashed #2d2f7b; }

.border-primary-solid {
  border: 1px solid #2d2f7b; }

.border-primary-double {
  border: 1px double #2d2f7b; }

.border-primary-groove {
  border: 1px groove #2d2f7b; }

.border-primary-ridge {
  border: 1px ridge #2d2f7b; }

.border-primary-inset {
  border: 1px inset #2d2f7b; }

.border-primary-outset {
  border: 1px outset #2d2f7b; }

.border-primary-none {
  border: 1px none #2d2f7b; }

.border-primary-hidden {
  border: 1px hidden #2d2f7b; }

.border-t-primary {
  border-top: 1px solid #2d2f7b; }

.border-t-primary-dotted {
  border-top: 1px dotted #2d2f7b; }

.border-t-primary-dashed {
  border-top: 1px dashed #2d2f7b; }

.border-t-primary-solid {
  border-top: 1px solid #2d2f7b; }

.border-t-primary-double {
  border-top: 1px double #2d2f7b; }

.border-t-primary-groove {
  border-top: 1px groove #2d2f7b; }

.border-t-primary-ridge {
  border-top: 1px ridge #2d2f7b; }

.border-t-primary-inset {
  border-top: 1px inset #2d2f7b; }

.border-t-primary-outset {
  border-top: 1px outset #2d2f7b; }

.border-t-primary-none {
  border-top: 1px none #2d2f7b; }

.border-t-primary-hidden {
  border-top: 1px hidden #2d2f7b; }

.border-r-primary {
  border-right: 1px solid #2d2f7b; }

.border-r-primary-dotted {
  border-right: 1px dotted #2d2f7b; }

.border-r-primary-dashed {
  border-right: 1px dashed #2d2f7b; }

.border-r-primary-solid {
  border-right: 1px solid #2d2f7b; }

.border-r-primary-double {
  border-right: 1px double #2d2f7b; }

.border-r-primary-groove {
  border-right: 1px groove #2d2f7b; }

.border-r-primary-ridge {
  border-right: 1px ridge #2d2f7b; }

.border-r-primary-inset {
  border-right: 1px inset #2d2f7b; }

.border-r-primary-outset {
  border-right: 1px outset #2d2f7b; }

.border-r-primary-none {
  border-right: 1px none #2d2f7b; }

.border-r-primary-hidden {
  border-right: 1px hidden #2d2f7b; }

.border-b-primary {
  border-bottom: 1px solid #2d2f7b; }

.border-b-primary-dotted {
  border-bottom: 1px dotted #2d2f7b; }

.border-b-primary-dashed {
  border-bottom: 1px dashed #2d2f7b; }

.border-b-primary-solid {
  border-bottom: 1px solid #2d2f7b; }

.border-b-primary-double {
  border-bottom: 1px double #2d2f7b; }

.border-b-primary-groove {
  border-bottom: 1px groove #2d2f7b; }

.border-b-primary-ridge {
  border-bottom: 1px ridge #2d2f7b; }

.border-b-primary-inset {
  border-bottom: 1px inset #2d2f7b; }

.border-b-primary-outset {
  border-bottom: 1px outset #2d2f7b; }

.border-b-primary-none {
  border-bottom: 1px none #2d2f7b; }

.border-b-primary-hidden {
  border-bottom: 1px hidden #2d2f7b; }

.border-l-primary {
  border-left: 1px solid #2d2f7b; }

.border-l-primary-dotted {
  border-left: 1px dotted #2d2f7b; }

.border-l-primary-dashed {
  border-left: 1px dashed #2d2f7b; }

.border-l-primary-solid {
  border-left: 1px solid #2d2f7b; }

.border-l-primary-double {
  border-left: 1px double #2d2f7b; }

.border-l-primary-groove {
  border-left: 1px groove #2d2f7b; }

.border-l-primary-ridge {
  border-left: 1px ridge #2d2f7b; }

.border-l-primary-inset {
  border-left: 1px inset #2d2f7b; }

.border-l-primary-outset {
  border-left: 1px outset #2d2f7b; }

.border-l-primary-none {
  border-left: 1px none #2d2f7b; }

.border-l-primary-hidden {
  border-left: 1px hidden #2d2f7b; }

.has-text-primary-bis {
  color: #4d4db3; }

.has-background-primary-bis {
  background-color: #4d4db3; }

.border-primary-bis {
  border: 1px solid #4d4db3; }

.border-m-primary-bis:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #4d4db3;
  transform: translateY(-50%); }

.border-primary-bis-dotted {
  border: 1px dotted #4d4db3; }

.border-primary-bis-dashed {
  border: 1px dashed #4d4db3; }

.border-primary-bis-solid {
  border: 1px solid #4d4db3; }

.border-primary-bis-double {
  border: 1px double #4d4db3; }

.border-primary-bis-groove {
  border: 1px groove #4d4db3; }

.border-primary-bis-ridge {
  border: 1px ridge #4d4db3; }

.border-primary-bis-inset {
  border: 1px inset #4d4db3; }

.border-primary-bis-outset {
  border: 1px outset #4d4db3; }

.border-primary-bis-none {
  border: 1px none #4d4db3; }

.border-primary-bis-hidden {
  border: 1px hidden #4d4db3; }

.border-t-primary-bis {
  border-top: 1px solid #4d4db3; }

.border-t-primary-bis-dotted {
  border-top: 1px dotted #4d4db3; }

.border-t-primary-bis-dashed {
  border-top: 1px dashed #4d4db3; }

.border-t-primary-bis-solid {
  border-top: 1px solid #4d4db3; }

.border-t-primary-bis-double {
  border-top: 1px double #4d4db3; }

.border-t-primary-bis-groove {
  border-top: 1px groove #4d4db3; }

.border-t-primary-bis-ridge {
  border-top: 1px ridge #4d4db3; }

.border-t-primary-bis-inset {
  border-top: 1px inset #4d4db3; }

.border-t-primary-bis-outset {
  border-top: 1px outset #4d4db3; }

.border-t-primary-bis-none {
  border-top: 1px none #4d4db3; }

.border-t-primary-bis-hidden {
  border-top: 1px hidden #4d4db3; }

.border-r-primary-bis {
  border-right: 1px solid #4d4db3; }

.border-r-primary-bis-dotted {
  border-right: 1px dotted #4d4db3; }

.border-r-primary-bis-dashed {
  border-right: 1px dashed #4d4db3; }

.border-r-primary-bis-solid {
  border-right: 1px solid #4d4db3; }

.border-r-primary-bis-double {
  border-right: 1px double #4d4db3; }

.border-r-primary-bis-groove {
  border-right: 1px groove #4d4db3; }

.border-r-primary-bis-ridge {
  border-right: 1px ridge #4d4db3; }

.border-r-primary-bis-inset {
  border-right: 1px inset #4d4db3; }

.border-r-primary-bis-outset {
  border-right: 1px outset #4d4db3; }

.border-r-primary-bis-none {
  border-right: 1px none #4d4db3; }

.border-r-primary-bis-hidden {
  border-right: 1px hidden #4d4db3; }

.border-b-primary-bis {
  border-bottom: 1px solid #4d4db3; }

.border-b-primary-bis-dotted {
  border-bottom: 1px dotted #4d4db3; }

.border-b-primary-bis-dashed {
  border-bottom: 1px dashed #4d4db3; }

.border-b-primary-bis-solid {
  border-bottom: 1px solid #4d4db3; }

.border-b-primary-bis-double {
  border-bottom: 1px double #4d4db3; }

.border-b-primary-bis-groove {
  border-bottom: 1px groove #4d4db3; }

.border-b-primary-bis-ridge {
  border-bottom: 1px ridge #4d4db3; }

.border-b-primary-bis-inset {
  border-bottom: 1px inset #4d4db3; }

.border-b-primary-bis-outset {
  border-bottom: 1px outset #4d4db3; }

.border-b-primary-bis-none {
  border-bottom: 1px none #4d4db3; }

.border-b-primary-bis-hidden {
  border-bottom: 1px hidden #4d4db3; }

.border-l-primary-bis {
  border-left: 1px solid #4d4db3; }

.border-l-primary-bis-dotted {
  border-left: 1px dotted #4d4db3; }

.border-l-primary-bis-dashed {
  border-left: 1px dashed #4d4db3; }

.border-l-primary-bis-solid {
  border-left: 1px solid #4d4db3; }

.border-l-primary-bis-double {
  border-left: 1px double #4d4db3; }

.border-l-primary-bis-groove {
  border-left: 1px groove #4d4db3; }

.border-l-primary-bis-ridge {
  border-left: 1px ridge #4d4db3; }

.border-l-primary-bis-inset {
  border-left: 1px inset #4d4db3; }

.border-l-primary-bis-outset {
  border-left: 1px outset #4d4db3; }

.border-l-primary-bis-none {
  border-left: 1px none #4d4db3; }

.border-l-primary-bis-hidden {
  border-left: 1px hidden #4d4db3; }

.has-text-primary-ter {
  color: #7a7ab8; }

.has-background-primary-ter {
  background-color: #7a7ab8; }

.border-primary-ter {
  border: 1px solid #7a7ab8; }

.border-m-primary-ter:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #7a7ab8;
  transform: translateY(-50%); }

.border-primary-ter-dotted {
  border: 1px dotted #7a7ab8; }

.border-primary-ter-dashed {
  border: 1px dashed #7a7ab8; }

.border-primary-ter-solid {
  border: 1px solid #7a7ab8; }

.border-primary-ter-double {
  border: 1px double #7a7ab8; }

.border-primary-ter-groove {
  border: 1px groove #7a7ab8; }

.border-primary-ter-ridge {
  border: 1px ridge #7a7ab8; }

.border-primary-ter-inset {
  border: 1px inset #7a7ab8; }

.border-primary-ter-outset {
  border: 1px outset #7a7ab8; }

.border-primary-ter-none {
  border: 1px none #7a7ab8; }

.border-primary-ter-hidden {
  border: 1px hidden #7a7ab8; }

.border-t-primary-ter {
  border-top: 1px solid #7a7ab8; }

.border-t-primary-ter-dotted {
  border-top: 1px dotted #7a7ab8; }

.border-t-primary-ter-dashed {
  border-top: 1px dashed #7a7ab8; }

.border-t-primary-ter-solid {
  border-top: 1px solid #7a7ab8; }

.border-t-primary-ter-double {
  border-top: 1px double #7a7ab8; }

.border-t-primary-ter-groove {
  border-top: 1px groove #7a7ab8; }

.border-t-primary-ter-ridge {
  border-top: 1px ridge #7a7ab8; }

.border-t-primary-ter-inset {
  border-top: 1px inset #7a7ab8; }

.border-t-primary-ter-outset {
  border-top: 1px outset #7a7ab8; }

.border-t-primary-ter-none {
  border-top: 1px none #7a7ab8; }

.border-t-primary-ter-hidden {
  border-top: 1px hidden #7a7ab8; }

.border-r-primary-ter {
  border-right: 1px solid #7a7ab8; }

.border-r-primary-ter-dotted {
  border-right: 1px dotted #7a7ab8; }

.border-r-primary-ter-dashed {
  border-right: 1px dashed #7a7ab8; }

.border-r-primary-ter-solid {
  border-right: 1px solid #7a7ab8; }

.border-r-primary-ter-double {
  border-right: 1px double #7a7ab8; }

.border-r-primary-ter-groove {
  border-right: 1px groove #7a7ab8; }

.border-r-primary-ter-ridge {
  border-right: 1px ridge #7a7ab8; }

.border-r-primary-ter-inset {
  border-right: 1px inset #7a7ab8; }

.border-r-primary-ter-outset {
  border-right: 1px outset #7a7ab8; }

.border-r-primary-ter-none {
  border-right: 1px none #7a7ab8; }

.border-r-primary-ter-hidden {
  border-right: 1px hidden #7a7ab8; }

.border-b-primary-ter {
  border-bottom: 1px solid #7a7ab8; }

.border-b-primary-ter-dotted {
  border-bottom: 1px dotted #7a7ab8; }

.border-b-primary-ter-dashed {
  border-bottom: 1px dashed #7a7ab8; }

.border-b-primary-ter-solid {
  border-bottom: 1px solid #7a7ab8; }

.border-b-primary-ter-double {
  border-bottom: 1px double #7a7ab8; }

.border-b-primary-ter-groove {
  border-bottom: 1px groove #7a7ab8; }

.border-b-primary-ter-ridge {
  border-bottom: 1px ridge #7a7ab8; }

.border-b-primary-ter-inset {
  border-bottom: 1px inset #7a7ab8; }

.border-b-primary-ter-outset {
  border-bottom: 1px outset #7a7ab8; }

.border-b-primary-ter-none {
  border-bottom: 1px none #7a7ab8; }

.border-b-primary-ter-hidden {
  border-bottom: 1px hidden #7a7ab8; }

.border-l-primary-ter {
  border-left: 1px solid #7a7ab8; }

.border-l-primary-ter-dotted {
  border-left: 1px dotted #7a7ab8; }

.border-l-primary-ter-dashed {
  border-left: 1px dashed #7a7ab8; }

.border-l-primary-ter-solid {
  border-left: 1px solid #7a7ab8; }

.border-l-primary-ter-double {
  border-left: 1px double #7a7ab8; }

.border-l-primary-ter-groove {
  border-left: 1px groove #7a7ab8; }

.border-l-primary-ter-ridge {
  border-left: 1px ridge #7a7ab8; }

.border-l-primary-ter-inset {
  border-left: 1px inset #7a7ab8; }

.border-l-primary-ter-outset {
  border-left: 1px outset #7a7ab8; }

.border-l-primary-ter-none {
  border-left: 1px none #7a7ab8; }

.border-l-primary-ter-hidden {
  border-left: 1px hidden #7a7ab8; }

.has-text-primary-qua {
  color: #b9b9e9; }

.has-background-primary-qua {
  background-color: #b9b9e9; }

.border-primary-qua {
  border: 1px solid #b9b9e9; }

.border-m-primary-qua:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b9b9e9;
  transform: translateY(-50%); }

.border-primary-qua-dotted {
  border: 1px dotted #b9b9e9; }

.border-primary-qua-dashed {
  border: 1px dashed #b9b9e9; }

.border-primary-qua-solid {
  border: 1px solid #b9b9e9; }

.border-primary-qua-double {
  border: 1px double #b9b9e9; }

.border-primary-qua-groove {
  border: 1px groove #b9b9e9; }

.border-primary-qua-ridge {
  border: 1px ridge #b9b9e9; }

.border-primary-qua-inset {
  border: 1px inset #b9b9e9; }

.border-primary-qua-outset {
  border: 1px outset #b9b9e9; }

.border-primary-qua-none {
  border: 1px none #b9b9e9; }

.border-primary-qua-hidden {
  border: 1px hidden #b9b9e9; }

.border-t-primary-qua {
  border-top: 1px solid #b9b9e9; }

.border-t-primary-qua-dotted {
  border-top: 1px dotted #b9b9e9; }

.border-t-primary-qua-dashed {
  border-top: 1px dashed #b9b9e9; }

.border-t-primary-qua-solid {
  border-top: 1px solid #b9b9e9; }

.border-t-primary-qua-double {
  border-top: 1px double #b9b9e9; }

.border-t-primary-qua-groove {
  border-top: 1px groove #b9b9e9; }

.border-t-primary-qua-ridge {
  border-top: 1px ridge #b9b9e9; }

.border-t-primary-qua-inset {
  border-top: 1px inset #b9b9e9; }

.border-t-primary-qua-outset {
  border-top: 1px outset #b9b9e9; }

.border-t-primary-qua-none {
  border-top: 1px none #b9b9e9; }

.border-t-primary-qua-hidden {
  border-top: 1px hidden #b9b9e9; }

.border-r-primary-qua {
  border-right: 1px solid #b9b9e9; }

.border-r-primary-qua-dotted {
  border-right: 1px dotted #b9b9e9; }

.border-r-primary-qua-dashed {
  border-right: 1px dashed #b9b9e9; }

.border-r-primary-qua-solid {
  border-right: 1px solid #b9b9e9; }

.border-r-primary-qua-double {
  border-right: 1px double #b9b9e9; }

.border-r-primary-qua-groove {
  border-right: 1px groove #b9b9e9; }

.border-r-primary-qua-ridge {
  border-right: 1px ridge #b9b9e9; }

.border-r-primary-qua-inset {
  border-right: 1px inset #b9b9e9; }

.border-r-primary-qua-outset {
  border-right: 1px outset #b9b9e9; }

.border-r-primary-qua-none {
  border-right: 1px none #b9b9e9; }

.border-r-primary-qua-hidden {
  border-right: 1px hidden #b9b9e9; }

.border-b-primary-qua {
  border-bottom: 1px solid #b9b9e9; }

.border-b-primary-qua-dotted {
  border-bottom: 1px dotted #b9b9e9; }

.border-b-primary-qua-dashed {
  border-bottom: 1px dashed #b9b9e9; }

.border-b-primary-qua-solid {
  border-bottom: 1px solid #b9b9e9; }

.border-b-primary-qua-double {
  border-bottom: 1px double #b9b9e9; }

.border-b-primary-qua-groove {
  border-bottom: 1px groove #b9b9e9; }

.border-b-primary-qua-ridge {
  border-bottom: 1px ridge #b9b9e9; }

.border-b-primary-qua-inset {
  border-bottom: 1px inset #b9b9e9; }

.border-b-primary-qua-outset {
  border-bottom: 1px outset #b9b9e9; }

.border-b-primary-qua-none {
  border-bottom: 1px none #b9b9e9; }

.border-b-primary-qua-hidden {
  border-bottom: 1px hidden #b9b9e9; }

.border-l-primary-qua {
  border-left: 1px solid #b9b9e9; }

.border-l-primary-qua-dotted {
  border-left: 1px dotted #b9b9e9; }

.border-l-primary-qua-dashed {
  border-left: 1px dashed #b9b9e9; }

.border-l-primary-qua-solid {
  border-left: 1px solid #b9b9e9; }

.border-l-primary-qua-double {
  border-left: 1px double #b9b9e9; }

.border-l-primary-qua-groove {
  border-left: 1px groove #b9b9e9; }

.border-l-primary-qua-ridge {
  border-left: 1px ridge #b9b9e9; }

.border-l-primary-qua-inset {
  border-left: 1px inset #b9b9e9; }

.border-l-primary-qua-outset {
  border-left: 1px outset #b9b9e9; }

.border-l-primary-qua-none {
  border-left: 1px none #b9b9e9; }

.border-l-primary-qua-hidden {
  border-left: 1px hidden #b9b9e9; }

.has-text-primary-qui {
  color: #b8b8d6; }

.has-background-primary-qui {
  background-color: #b8b8d6; }

.border-primary-qui {
  border: 1px solid #b8b8d6; }

.border-m-primary-qui:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b8b8d6;
  transform: translateY(-50%); }

.border-primary-qui-dotted {
  border: 1px dotted #b8b8d6; }

.border-primary-qui-dashed {
  border: 1px dashed #b8b8d6; }

.border-primary-qui-solid {
  border: 1px solid #b8b8d6; }

.border-primary-qui-double {
  border: 1px double #b8b8d6; }

.border-primary-qui-groove {
  border: 1px groove #b8b8d6; }

.border-primary-qui-ridge {
  border: 1px ridge #b8b8d6; }

.border-primary-qui-inset {
  border: 1px inset #b8b8d6; }

.border-primary-qui-outset {
  border: 1px outset #b8b8d6; }

.border-primary-qui-none {
  border: 1px none #b8b8d6; }

.border-primary-qui-hidden {
  border: 1px hidden #b8b8d6; }

.border-t-primary-qui {
  border-top: 1px solid #b8b8d6; }

.border-t-primary-qui-dotted {
  border-top: 1px dotted #b8b8d6; }

.border-t-primary-qui-dashed {
  border-top: 1px dashed #b8b8d6; }

.border-t-primary-qui-solid {
  border-top: 1px solid #b8b8d6; }

.border-t-primary-qui-double {
  border-top: 1px double #b8b8d6; }

.border-t-primary-qui-groove {
  border-top: 1px groove #b8b8d6; }

.border-t-primary-qui-ridge {
  border-top: 1px ridge #b8b8d6; }

.border-t-primary-qui-inset {
  border-top: 1px inset #b8b8d6; }

.border-t-primary-qui-outset {
  border-top: 1px outset #b8b8d6; }

.border-t-primary-qui-none {
  border-top: 1px none #b8b8d6; }

.border-t-primary-qui-hidden {
  border-top: 1px hidden #b8b8d6; }

.border-r-primary-qui {
  border-right: 1px solid #b8b8d6; }

.border-r-primary-qui-dotted {
  border-right: 1px dotted #b8b8d6; }

.border-r-primary-qui-dashed {
  border-right: 1px dashed #b8b8d6; }

.border-r-primary-qui-solid {
  border-right: 1px solid #b8b8d6; }

.border-r-primary-qui-double {
  border-right: 1px double #b8b8d6; }

.border-r-primary-qui-groove {
  border-right: 1px groove #b8b8d6; }

.border-r-primary-qui-ridge {
  border-right: 1px ridge #b8b8d6; }

.border-r-primary-qui-inset {
  border-right: 1px inset #b8b8d6; }

.border-r-primary-qui-outset {
  border-right: 1px outset #b8b8d6; }

.border-r-primary-qui-none {
  border-right: 1px none #b8b8d6; }

.border-r-primary-qui-hidden {
  border-right: 1px hidden #b8b8d6; }

.border-b-primary-qui {
  border-bottom: 1px solid #b8b8d6; }

.border-b-primary-qui-dotted {
  border-bottom: 1px dotted #b8b8d6; }

.border-b-primary-qui-dashed {
  border-bottom: 1px dashed #b8b8d6; }

.border-b-primary-qui-solid {
  border-bottom: 1px solid #b8b8d6; }

.border-b-primary-qui-double {
  border-bottom: 1px double #b8b8d6; }

.border-b-primary-qui-groove {
  border-bottom: 1px groove #b8b8d6; }

.border-b-primary-qui-ridge {
  border-bottom: 1px ridge #b8b8d6; }

.border-b-primary-qui-inset {
  border-bottom: 1px inset #b8b8d6; }

.border-b-primary-qui-outset {
  border-bottom: 1px outset #b8b8d6; }

.border-b-primary-qui-none {
  border-bottom: 1px none #b8b8d6; }

.border-b-primary-qui-hidden {
  border-bottom: 1px hidden #b8b8d6; }

.border-l-primary-qui {
  border-left: 1px solid #b8b8d6; }

.border-l-primary-qui-dotted {
  border-left: 1px dotted #b8b8d6; }

.border-l-primary-qui-dashed {
  border-left: 1px dashed #b8b8d6; }

.border-l-primary-qui-solid {
  border-left: 1px solid #b8b8d6; }

.border-l-primary-qui-double {
  border-left: 1px double #b8b8d6; }

.border-l-primary-qui-groove {
  border-left: 1px groove #b8b8d6; }

.border-l-primary-qui-ridge {
  border-left: 1px ridge #b8b8d6; }

.border-l-primary-qui-inset {
  border-left: 1px inset #b8b8d6; }

.border-l-primary-qui-outset {
  border-left: 1px outset #b8b8d6; }

.border-l-primary-qui-none {
  border-left: 1px none #b8b8d6; }

.border-l-primary-qui-hidden {
  border-left: 1px hidden #b8b8d6; }

.has-text-primary-light {
  color: #dbdbf0; }

.has-background-primary-light {
  background-color: #dbdbf0; }

.border-primary-light {
  border: 1px solid #dbdbf0; }

.border-m-primary-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dbdbf0;
  transform: translateY(-50%); }

.border-primary-light-dotted {
  border: 1px dotted #dbdbf0; }

.border-primary-light-dashed {
  border: 1px dashed #dbdbf0; }

.border-primary-light-solid {
  border: 1px solid #dbdbf0; }

.border-primary-light-double {
  border: 1px double #dbdbf0; }

.border-primary-light-groove {
  border: 1px groove #dbdbf0; }

.border-primary-light-ridge {
  border: 1px ridge #dbdbf0; }

.border-primary-light-inset {
  border: 1px inset #dbdbf0; }

.border-primary-light-outset {
  border: 1px outset #dbdbf0; }

.border-primary-light-none {
  border: 1px none #dbdbf0; }

.border-primary-light-hidden {
  border: 1px hidden #dbdbf0; }

.border-t-primary-light {
  border-top: 1px solid #dbdbf0; }

.border-t-primary-light-dotted {
  border-top: 1px dotted #dbdbf0; }

.border-t-primary-light-dashed {
  border-top: 1px dashed #dbdbf0; }

.border-t-primary-light-solid {
  border-top: 1px solid #dbdbf0; }

.border-t-primary-light-double {
  border-top: 1px double #dbdbf0; }

.border-t-primary-light-groove {
  border-top: 1px groove #dbdbf0; }

.border-t-primary-light-ridge {
  border-top: 1px ridge #dbdbf0; }

.border-t-primary-light-inset {
  border-top: 1px inset #dbdbf0; }

.border-t-primary-light-outset {
  border-top: 1px outset #dbdbf0; }

.border-t-primary-light-none {
  border-top: 1px none #dbdbf0; }

.border-t-primary-light-hidden {
  border-top: 1px hidden #dbdbf0; }

.border-r-primary-light {
  border-right: 1px solid #dbdbf0; }

.border-r-primary-light-dotted {
  border-right: 1px dotted #dbdbf0; }

.border-r-primary-light-dashed {
  border-right: 1px dashed #dbdbf0; }

.border-r-primary-light-solid {
  border-right: 1px solid #dbdbf0; }

.border-r-primary-light-double {
  border-right: 1px double #dbdbf0; }

.border-r-primary-light-groove {
  border-right: 1px groove #dbdbf0; }

.border-r-primary-light-ridge {
  border-right: 1px ridge #dbdbf0; }

.border-r-primary-light-inset {
  border-right: 1px inset #dbdbf0; }

.border-r-primary-light-outset {
  border-right: 1px outset #dbdbf0; }

.border-r-primary-light-none {
  border-right: 1px none #dbdbf0; }

.border-r-primary-light-hidden {
  border-right: 1px hidden #dbdbf0; }

.border-b-primary-light {
  border-bottom: 1px solid #dbdbf0; }

.border-b-primary-light-dotted {
  border-bottom: 1px dotted #dbdbf0; }

.border-b-primary-light-dashed {
  border-bottom: 1px dashed #dbdbf0; }

.border-b-primary-light-solid {
  border-bottom: 1px solid #dbdbf0; }

.border-b-primary-light-double {
  border-bottom: 1px double #dbdbf0; }

.border-b-primary-light-groove {
  border-bottom: 1px groove #dbdbf0; }

.border-b-primary-light-ridge {
  border-bottom: 1px ridge #dbdbf0; }

.border-b-primary-light-inset {
  border-bottom: 1px inset #dbdbf0; }

.border-b-primary-light-outset {
  border-bottom: 1px outset #dbdbf0; }

.border-b-primary-light-none {
  border-bottom: 1px none #dbdbf0; }

.border-b-primary-light-hidden {
  border-bottom: 1px hidden #dbdbf0; }

.border-l-primary-light {
  border-left: 1px solid #dbdbf0; }

.border-l-primary-light-dotted {
  border-left: 1px dotted #dbdbf0; }

.border-l-primary-light-dashed {
  border-left: 1px dashed #dbdbf0; }

.border-l-primary-light-solid {
  border-left: 1px solid #dbdbf0; }

.border-l-primary-light-double {
  border-left: 1px double #dbdbf0; }

.border-l-primary-light-groove {
  border-left: 1px groove #dbdbf0; }

.border-l-primary-light-ridge {
  border-left: 1px ridge #dbdbf0; }

.border-l-primary-light-inset {
  border-left: 1px inset #dbdbf0; }

.border-l-primary-light-outset {
  border-left: 1px outset #dbdbf0; }

.border-l-primary-light-none {
  border-left: 1px none #dbdbf0; }

.border-l-primary-light-hidden {
  border-left: 1px hidden #dbdbf0; }

.has-text-primary-lighter {
  color: #ededf7; }

.has-background-primary-lighter {
  background-color: #ededf7; }

.border-primary-lighter {
  border: 1px solid #ededf7; }

.border-m-primary-lighter:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ededf7;
  transform: translateY(-50%); }

.border-primary-lighter-dotted {
  border: 1px dotted #ededf7; }

.border-primary-lighter-dashed {
  border: 1px dashed #ededf7; }

.border-primary-lighter-solid {
  border: 1px solid #ededf7; }

.border-primary-lighter-double {
  border: 1px double #ededf7; }

.border-primary-lighter-groove {
  border: 1px groove #ededf7; }

.border-primary-lighter-ridge {
  border: 1px ridge #ededf7; }

.border-primary-lighter-inset {
  border: 1px inset #ededf7; }

.border-primary-lighter-outset {
  border: 1px outset #ededf7; }

.border-primary-lighter-none {
  border: 1px none #ededf7; }

.border-primary-lighter-hidden {
  border: 1px hidden #ededf7; }

.border-t-primary-lighter {
  border-top: 1px solid #ededf7; }

.border-t-primary-lighter-dotted {
  border-top: 1px dotted #ededf7; }

.border-t-primary-lighter-dashed {
  border-top: 1px dashed #ededf7; }

.border-t-primary-lighter-solid {
  border-top: 1px solid #ededf7; }

.border-t-primary-lighter-double {
  border-top: 1px double #ededf7; }

.border-t-primary-lighter-groove {
  border-top: 1px groove #ededf7; }

.border-t-primary-lighter-ridge {
  border-top: 1px ridge #ededf7; }

.border-t-primary-lighter-inset {
  border-top: 1px inset #ededf7; }

.border-t-primary-lighter-outset {
  border-top: 1px outset #ededf7; }

.border-t-primary-lighter-none {
  border-top: 1px none #ededf7; }

.border-t-primary-lighter-hidden {
  border-top: 1px hidden #ededf7; }

.border-r-primary-lighter {
  border-right: 1px solid #ededf7; }

.border-r-primary-lighter-dotted {
  border-right: 1px dotted #ededf7; }

.border-r-primary-lighter-dashed {
  border-right: 1px dashed #ededf7; }

.border-r-primary-lighter-solid {
  border-right: 1px solid #ededf7; }

.border-r-primary-lighter-double {
  border-right: 1px double #ededf7; }

.border-r-primary-lighter-groove {
  border-right: 1px groove #ededf7; }

.border-r-primary-lighter-ridge {
  border-right: 1px ridge #ededf7; }

.border-r-primary-lighter-inset {
  border-right: 1px inset #ededf7; }

.border-r-primary-lighter-outset {
  border-right: 1px outset #ededf7; }

.border-r-primary-lighter-none {
  border-right: 1px none #ededf7; }

.border-r-primary-lighter-hidden {
  border-right: 1px hidden #ededf7; }

.border-b-primary-lighter {
  border-bottom: 1px solid #ededf7; }

.border-b-primary-lighter-dotted {
  border-bottom: 1px dotted #ededf7; }

.border-b-primary-lighter-dashed {
  border-bottom: 1px dashed #ededf7; }

.border-b-primary-lighter-solid {
  border-bottom: 1px solid #ededf7; }

.border-b-primary-lighter-double {
  border-bottom: 1px double #ededf7; }

.border-b-primary-lighter-groove {
  border-bottom: 1px groove #ededf7; }

.border-b-primary-lighter-ridge {
  border-bottom: 1px ridge #ededf7; }

.border-b-primary-lighter-inset {
  border-bottom: 1px inset #ededf7; }

.border-b-primary-lighter-outset {
  border-bottom: 1px outset #ededf7; }

.border-b-primary-lighter-none {
  border-bottom: 1px none #ededf7; }

.border-b-primary-lighter-hidden {
  border-bottom: 1px hidden #ededf7; }

.border-l-primary-lighter {
  border-left: 1px solid #ededf7; }

.border-l-primary-lighter-dotted {
  border-left: 1px dotted #ededf7; }

.border-l-primary-lighter-dashed {
  border-left: 1px dashed #ededf7; }

.border-l-primary-lighter-solid {
  border-left: 1px solid #ededf7; }

.border-l-primary-lighter-double {
  border-left: 1px double #ededf7; }

.border-l-primary-lighter-groove {
  border-left: 1px groove #ededf7; }

.border-l-primary-lighter-ridge {
  border-left: 1px ridge #ededf7; }

.border-l-primary-lighter-inset {
  border-left: 1px inset #ededf7; }

.border-l-primary-lighter-outset {
  border-left: 1px outset #ededf7; }

.border-l-primary-lighter-none {
  border-left: 1px none #ededf7; }

.border-l-primary-lighter-hidden {
  border-left: 1px hidden #ededf7; }

.has-text-primary-lighbis {
  color: #f4f4fa; }

.has-background-primary-lighbis {
  background-color: #f4f4fa; }

.border-primary-lighbis {
  border: 1px solid #f4f4fa; }

.border-m-primary-lighbis:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f4f4fa;
  transform: translateY(-50%); }

.border-primary-lighbis-dotted {
  border: 1px dotted #f4f4fa; }

.border-primary-lighbis-dashed {
  border: 1px dashed #f4f4fa; }

.border-primary-lighbis-solid {
  border: 1px solid #f4f4fa; }

.border-primary-lighbis-double {
  border: 1px double #f4f4fa; }

.border-primary-lighbis-groove {
  border: 1px groove #f4f4fa; }

.border-primary-lighbis-ridge {
  border: 1px ridge #f4f4fa; }

.border-primary-lighbis-inset {
  border: 1px inset #f4f4fa; }

.border-primary-lighbis-outset {
  border: 1px outset #f4f4fa; }

.border-primary-lighbis-none {
  border: 1px none #f4f4fa; }

.border-primary-lighbis-hidden {
  border: 1px hidden #f4f4fa; }

.border-t-primary-lighbis {
  border-top: 1px solid #f4f4fa; }

.border-t-primary-lighbis-dotted {
  border-top: 1px dotted #f4f4fa; }

.border-t-primary-lighbis-dashed {
  border-top: 1px dashed #f4f4fa; }

.border-t-primary-lighbis-solid {
  border-top: 1px solid #f4f4fa; }

.border-t-primary-lighbis-double {
  border-top: 1px double #f4f4fa; }

.border-t-primary-lighbis-groove {
  border-top: 1px groove #f4f4fa; }

.border-t-primary-lighbis-ridge {
  border-top: 1px ridge #f4f4fa; }

.border-t-primary-lighbis-inset {
  border-top: 1px inset #f4f4fa; }

.border-t-primary-lighbis-outset {
  border-top: 1px outset #f4f4fa; }

.border-t-primary-lighbis-none {
  border-top: 1px none #f4f4fa; }

.border-t-primary-lighbis-hidden {
  border-top: 1px hidden #f4f4fa; }

.border-r-primary-lighbis {
  border-right: 1px solid #f4f4fa; }

.border-r-primary-lighbis-dotted {
  border-right: 1px dotted #f4f4fa; }

.border-r-primary-lighbis-dashed {
  border-right: 1px dashed #f4f4fa; }

.border-r-primary-lighbis-solid {
  border-right: 1px solid #f4f4fa; }

.border-r-primary-lighbis-double {
  border-right: 1px double #f4f4fa; }

.border-r-primary-lighbis-groove {
  border-right: 1px groove #f4f4fa; }

.border-r-primary-lighbis-ridge {
  border-right: 1px ridge #f4f4fa; }

.border-r-primary-lighbis-inset {
  border-right: 1px inset #f4f4fa; }

.border-r-primary-lighbis-outset {
  border-right: 1px outset #f4f4fa; }

.border-r-primary-lighbis-none {
  border-right: 1px none #f4f4fa; }

.border-r-primary-lighbis-hidden {
  border-right: 1px hidden #f4f4fa; }

.border-b-primary-lighbis {
  border-bottom: 1px solid #f4f4fa; }

.border-b-primary-lighbis-dotted {
  border-bottom: 1px dotted #f4f4fa; }

.border-b-primary-lighbis-dashed {
  border-bottom: 1px dashed #f4f4fa; }

.border-b-primary-lighbis-solid {
  border-bottom: 1px solid #f4f4fa; }

.border-b-primary-lighbis-double {
  border-bottom: 1px double #f4f4fa; }

.border-b-primary-lighbis-groove {
  border-bottom: 1px groove #f4f4fa; }

.border-b-primary-lighbis-ridge {
  border-bottom: 1px ridge #f4f4fa; }

.border-b-primary-lighbis-inset {
  border-bottom: 1px inset #f4f4fa; }

.border-b-primary-lighbis-outset {
  border-bottom: 1px outset #f4f4fa; }

.border-b-primary-lighbis-none {
  border-bottom: 1px none #f4f4fa; }

.border-b-primary-lighbis-hidden {
  border-bottom: 1px hidden #f4f4fa; }

.border-l-primary-lighbis {
  border-left: 1px solid #f4f4fa; }

.border-l-primary-lighbis-dotted {
  border-left: 1px dotted #f4f4fa; }

.border-l-primary-lighbis-dashed {
  border-left: 1px dashed #f4f4fa; }

.border-l-primary-lighbis-solid {
  border-left: 1px solid #f4f4fa; }

.border-l-primary-lighbis-double {
  border-left: 1px double #f4f4fa; }

.border-l-primary-lighbis-groove {
  border-left: 1px groove #f4f4fa; }

.border-l-primary-lighbis-ridge {
  border-left: 1px ridge #f4f4fa; }

.border-l-primary-lighbis-inset {
  border-left: 1px inset #f4f4fa; }

.border-l-primary-lighbis-outset {
  border-left: 1px outset #f4f4fa; }

.border-l-primary-lighbis-none {
  border-left: 1px none #f4f4fa; }

.border-l-primary-lighbis-hidden {
  border-left: 1px hidden #f4f4fa; }

.has-text-secondary {
  color: #eb6666; }

.has-background-secondary {
  background-color: #eb6666; }

.border-secondary {
  border: 1px solid #eb6666; }

.border-m-secondary:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #eb6666;
  transform: translateY(-50%); }

.border-secondary-dotted {
  border: 1px dotted #eb6666; }

.border-secondary-dashed {
  border: 1px dashed #eb6666; }

.border-secondary-solid {
  border: 1px solid #eb6666; }

.border-secondary-double {
  border: 1px double #eb6666; }

.border-secondary-groove {
  border: 1px groove #eb6666; }

.border-secondary-ridge {
  border: 1px ridge #eb6666; }

.border-secondary-inset {
  border: 1px inset #eb6666; }

.border-secondary-outset {
  border: 1px outset #eb6666; }

.border-secondary-none {
  border: 1px none #eb6666; }

.border-secondary-hidden {
  border: 1px hidden #eb6666; }

.border-t-secondary {
  border-top: 1px solid #eb6666; }

.border-t-secondary-dotted {
  border-top: 1px dotted #eb6666; }

.border-t-secondary-dashed {
  border-top: 1px dashed #eb6666; }

.border-t-secondary-solid {
  border-top: 1px solid #eb6666; }

.border-t-secondary-double {
  border-top: 1px double #eb6666; }

.border-t-secondary-groove {
  border-top: 1px groove #eb6666; }

.border-t-secondary-ridge {
  border-top: 1px ridge #eb6666; }

.border-t-secondary-inset {
  border-top: 1px inset #eb6666; }

.border-t-secondary-outset {
  border-top: 1px outset #eb6666; }

.border-t-secondary-none {
  border-top: 1px none #eb6666; }

.border-t-secondary-hidden {
  border-top: 1px hidden #eb6666; }

.border-r-secondary {
  border-right: 1px solid #eb6666; }

.border-r-secondary-dotted {
  border-right: 1px dotted #eb6666; }

.border-r-secondary-dashed {
  border-right: 1px dashed #eb6666; }

.border-r-secondary-solid {
  border-right: 1px solid #eb6666; }

.border-r-secondary-double {
  border-right: 1px double #eb6666; }

.border-r-secondary-groove {
  border-right: 1px groove #eb6666; }

.border-r-secondary-ridge {
  border-right: 1px ridge #eb6666; }

.border-r-secondary-inset {
  border-right: 1px inset #eb6666; }

.border-r-secondary-outset {
  border-right: 1px outset #eb6666; }

.border-r-secondary-none {
  border-right: 1px none #eb6666; }

.border-r-secondary-hidden {
  border-right: 1px hidden #eb6666; }

.border-b-secondary {
  border-bottom: 1px solid #eb6666; }

.border-b-secondary-dotted {
  border-bottom: 1px dotted #eb6666; }

.border-b-secondary-dashed {
  border-bottom: 1px dashed #eb6666; }

.border-b-secondary-solid {
  border-bottom: 1px solid #eb6666; }

.border-b-secondary-double {
  border-bottom: 1px double #eb6666; }

.border-b-secondary-groove {
  border-bottom: 1px groove #eb6666; }

.border-b-secondary-ridge {
  border-bottom: 1px ridge #eb6666; }

.border-b-secondary-inset {
  border-bottom: 1px inset #eb6666; }

.border-b-secondary-outset {
  border-bottom: 1px outset #eb6666; }

.border-b-secondary-none {
  border-bottom: 1px none #eb6666; }

.border-b-secondary-hidden {
  border-bottom: 1px hidden #eb6666; }

.border-l-secondary {
  border-left: 1px solid #eb6666; }

.border-l-secondary-dotted {
  border-left: 1px dotted #eb6666; }

.border-l-secondary-dashed {
  border-left: 1px dashed #eb6666; }

.border-l-secondary-solid {
  border-left: 1px solid #eb6666; }

.border-l-secondary-double {
  border-left: 1px double #eb6666; }

.border-l-secondary-groove {
  border-left: 1px groove #eb6666; }

.border-l-secondary-ridge {
  border-left: 1px ridge #eb6666; }

.border-l-secondary-inset {
  border-left: 1px inset #eb6666; }

.border-l-secondary-outset {
  border-left: 1px outset #eb6666; }

.border-l-secondary-none {
  border-left: 1px none #eb6666; }

.border-l-secondary-hidden {
  border-left: 1px hidden #eb6666; }

.has-text-danger {
  color: #cc3333; }

.has-background-danger {
  background-color: #cc3333; }

.border-danger {
  border: 1px solid #cc3333; }

.border-m-danger:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #cc3333;
  transform: translateY(-50%); }

.border-danger-dotted {
  border: 1px dotted #cc3333; }

.border-danger-dashed {
  border: 1px dashed #cc3333; }

.border-danger-solid {
  border: 1px solid #cc3333; }

.border-danger-double {
  border: 1px double #cc3333; }

.border-danger-groove {
  border: 1px groove #cc3333; }

.border-danger-ridge {
  border: 1px ridge #cc3333; }

.border-danger-inset {
  border: 1px inset #cc3333; }

.border-danger-outset {
  border: 1px outset #cc3333; }

.border-danger-none {
  border: 1px none #cc3333; }

.border-danger-hidden {
  border: 1px hidden #cc3333; }

.border-t-danger {
  border-top: 1px solid #cc3333; }

.border-t-danger-dotted {
  border-top: 1px dotted #cc3333; }

.border-t-danger-dashed {
  border-top: 1px dashed #cc3333; }

.border-t-danger-solid {
  border-top: 1px solid #cc3333; }

.border-t-danger-double {
  border-top: 1px double #cc3333; }

.border-t-danger-groove {
  border-top: 1px groove #cc3333; }

.border-t-danger-ridge {
  border-top: 1px ridge #cc3333; }

.border-t-danger-inset {
  border-top: 1px inset #cc3333; }

.border-t-danger-outset {
  border-top: 1px outset #cc3333; }

.border-t-danger-none {
  border-top: 1px none #cc3333; }

.border-t-danger-hidden {
  border-top: 1px hidden #cc3333; }

.border-r-danger {
  border-right: 1px solid #cc3333; }

.border-r-danger-dotted {
  border-right: 1px dotted #cc3333; }

.border-r-danger-dashed {
  border-right: 1px dashed #cc3333; }

.border-r-danger-solid {
  border-right: 1px solid #cc3333; }

.border-r-danger-double {
  border-right: 1px double #cc3333; }

.border-r-danger-groove {
  border-right: 1px groove #cc3333; }

.border-r-danger-ridge {
  border-right: 1px ridge #cc3333; }

.border-r-danger-inset {
  border-right: 1px inset #cc3333; }

.border-r-danger-outset {
  border-right: 1px outset #cc3333; }

.border-r-danger-none {
  border-right: 1px none #cc3333; }

.border-r-danger-hidden {
  border-right: 1px hidden #cc3333; }

.border-b-danger {
  border-bottom: 1px solid #cc3333; }

.border-b-danger-dotted {
  border-bottom: 1px dotted #cc3333; }

.border-b-danger-dashed {
  border-bottom: 1px dashed #cc3333; }

.border-b-danger-solid {
  border-bottom: 1px solid #cc3333; }

.border-b-danger-double {
  border-bottom: 1px double #cc3333; }

.border-b-danger-groove {
  border-bottom: 1px groove #cc3333; }

.border-b-danger-ridge {
  border-bottom: 1px ridge #cc3333; }

.border-b-danger-inset {
  border-bottom: 1px inset #cc3333; }

.border-b-danger-outset {
  border-bottom: 1px outset #cc3333; }

.border-b-danger-none {
  border-bottom: 1px none #cc3333; }

.border-b-danger-hidden {
  border-bottom: 1px hidden #cc3333; }

.border-l-danger {
  border-left: 1px solid #cc3333; }

.border-l-danger-dotted {
  border-left: 1px dotted #cc3333; }

.border-l-danger-dashed {
  border-left: 1px dashed #cc3333; }

.border-l-danger-solid {
  border-left: 1px solid #cc3333; }

.border-l-danger-double {
  border-left: 1px double #cc3333; }

.border-l-danger-groove {
  border-left: 1px groove #cc3333; }

.border-l-danger-ridge {
  border-left: 1px ridge #cc3333; }

.border-l-danger-inset {
  border-left: 1px inset #cc3333; }

.border-l-danger-outset {
  border-left: 1px outset #cc3333; }

.border-l-danger-none {
  border-left: 1px none #cc3333; }

.border-l-danger-hidden {
  border-left: 1px hidden #cc3333; }

.has-text-danger-light {
  color: #f6dada; }

.has-background-danger-light {
  background-color: #f6dada; }

.border-danger-light {
  border: 1px solid #f6dada; }

.border-m-danger-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f6dada;
  transform: translateY(-50%); }

.border-danger-light-dotted {
  border: 1px dotted #f6dada; }

.border-danger-light-dashed {
  border: 1px dashed #f6dada; }

.border-danger-light-solid {
  border: 1px solid #f6dada; }

.border-danger-light-double {
  border: 1px double #f6dada; }

.border-danger-light-groove {
  border: 1px groove #f6dada; }

.border-danger-light-ridge {
  border: 1px ridge #f6dada; }

.border-danger-light-inset {
  border: 1px inset #f6dada; }

.border-danger-light-outset {
  border: 1px outset #f6dada; }

.border-danger-light-none {
  border: 1px none #f6dada; }

.border-danger-light-hidden {
  border: 1px hidden #f6dada; }

.border-t-danger-light {
  border-top: 1px solid #f6dada; }

.border-t-danger-light-dotted {
  border-top: 1px dotted #f6dada; }

.border-t-danger-light-dashed {
  border-top: 1px dashed #f6dada; }

.border-t-danger-light-solid {
  border-top: 1px solid #f6dada; }

.border-t-danger-light-double {
  border-top: 1px double #f6dada; }

.border-t-danger-light-groove {
  border-top: 1px groove #f6dada; }

.border-t-danger-light-ridge {
  border-top: 1px ridge #f6dada; }

.border-t-danger-light-inset {
  border-top: 1px inset #f6dada; }

.border-t-danger-light-outset {
  border-top: 1px outset #f6dada; }

.border-t-danger-light-none {
  border-top: 1px none #f6dada; }

.border-t-danger-light-hidden {
  border-top: 1px hidden #f6dada; }

.border-r-danger-light {
  border-right: 1px solid #f6dada; }

.border-r-danger-light-dotted {
  border-right: 1px dotted #f6dada; }

.border-r-danger-light-dashed {
  border-right: 1px dashed #f6dada; }

.border-r-danger-light-solid {
  border-right: 1px solid #f6dada; }

.border-r-danger-light-double {
  border-right: 1px double #f6dada; }

.border-r-danger-light-groove {
  border-right: 1px groove #f6dada; }

.border-r-danger-light-ridge {
  border-right: 1px ridge #f6dada; }

.border-r-danger-light-inset {
  border-right: 1px inset #f6dada; }

.border-r-danger-light-outset {
  border-right: 1px outset #f6dada; }

.border-r-danger-light-none {
  border-right: 1px none #f6dada; }

.border-r-danger-light-hidden {
  border-right: 1px hidden #f6dada; }

.border-b-danger-light {
  border-bottom: 1px solid #f6dada; }

.border-b-danger-light-dotted {
  border-bottom: 1px dotted #f6dada; }

.border-b-danger-light-dashed {
  border-bottom: 1px dashed #f6dada; }

.border-b-danger-light-solid {
  border-bottom: 1px solid #f6dada; }

.border-b-danger-light-double {
  border-bottom: 1px double #f6dada; }

.border-b-danger-light-groove {
  border-bottom: 1px groove #f6dada; }

.border-b-danger-light-ridge {
  border-bottom: 1px ridge #f6dada; }

.border-b-danger-light-inset {
  border-bottom: 1px inset #f6dada; }

.border-b-danger-light-outset {
  border-bottom: 1px outset #f6dada; }

.border-b-danger-light-none {
  border-bottom: 1px none #f6dada; }

.border-b-danger-light-hidden {
  border-bottom: 1px hidden #f6dada; }

.border-l-danger-light {
  border-left: 1px solid #f6dada; }

.border-l-danger-light-dotted {
  border-left: 1px dotted #f6dada; }

.border-l-danger-light-dashed {
  border-left: 1px dashed #f6dada; }

.border-l-danger-light-solid {
  border-left: 1px solid #f6dada; }

.border-l-danger-light-double {
  border-left: 1px double #f6dada; }

.border-l-danger-light-groove {
  border-left: 1px groove #f6dada; }

.border-l-danger-light-ridge {
  border-left: 1px ridge #f6dada; }

.border-l-danger-light-inset {
  border-left: 1px inset #f6dada; }

.border-l-danger-light-outset {
  border-left: 1px outset #f6dada; }

.border-l-danger-light-none {
  border-left: 1px none #f6dada; }

.border-l-danger-light-hidden {
  border-left: 1px hidden #f6dada; }

.has-text-warning {
  color: #ffd500; }

.has-background-warning {
  background-color: #ffd500; }

.border-warning {
  border: 1px solid #ffd500; }

.border-m-warning:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ffd500;
  transform: translateY(-50%); }

.border-warning-dotted {
  border: 1px dotted #ffd500; }

.border-warning-dashed {
  border: 1px dashed #ffd500; }

.border-warning-solid {
  border: 1px solid #ffd500; }

.border-warning-double {
  border: 1px double #ffd500; }

.border-warning-groove {
  border: 1px groove #ffd500; }

.border-warning-ridge {
  border: 1px ridge #ffd500; }

.border-warning-inset {
  border: 1px inset #ffd500; }

.border-warning-outset {
  border: 1px outset #ffd500; }

.border-warning-none {
  border: 1px none #ffd500; }

.border-warning-hidden {
  border: 1px hidden #ffd500; }

.border-t-warning {
  border-top: 1px solid #ffd500; }

.border-t-warning-dotted {
  border-top: 1px dotted #ffd500; }

.border-t-warning-dashed {
  border-top: 1px dashed #ffd500; }

.border-t-warning-solid {
  border-top: 1px solid #ffd500; }

.border-t-warning-double {
  border-top: 1px double #ffd500; }

.border-t-warning-groove {
  border-top: 1px groove #ffd500; }

.border-t-warning-ridge {
  border-top: 1px ridge #ffd500; }

.border-t-warning-inset {
  border-top: 1px inset #ffd500; }

.border-t-warning-outset {
  border-top: 1px outset #ffd500; }

.border-t-warning-none {
  border-top: 1px none #ffd500; }

.border-t-warning-hidden {
  border-top: 1px hidden #ffd500; }

.border-r-warning {
  border-right: 1px solid #ffd500; }

.border-r-warning-dotted {
  border-right: 1px dotted #ffd500; }

.border-r-warning-dashed {
  border-right: 1px dashed #ffd500; }

.border-r-warning-solid {
  border-right: 1px solid #ffd500; }

.border-r-warning-double {
  border-right: 1px double #ffd500; }

.border-r-warning-groove {
  border-right: 1px groove #ffd500; }

.border-r-warning-ridge {
  border-right: 1px ridge #ffd500; }

.border-r-warning-inset {
  border-right: 1px inset #ffd500; }

.border-r-warning-outset {
  border-right: 1px outset #ffd500; }

.border-r-warning-none {
  border-right: 1px none #ffd500; }

.border-r-warning-hidden {
  border-right: 1px hidden #ffd500; }

.border-b-warning {
  border-bottom: 1px solid #ffd500; }

.border-b-warning-dotted {
  border-bottom: 1px dotted #ffd500; }

.border-b-warning-dashed {
  border-bottom: 1px dashed #ffd500; }

.border-b-warning-solid {
  border-bottom: 1px solid #ffd500; }

.border-b-warning-double {
  border-bottom: 1px double #ffd500; }

.border-b-warning-groove {
  border-bottom: 1px groove #ffd500; }

.border-b-warning-ridge {
  border-bottom: 1px ridge #ffd500; }

.border-b-warning-inset {
  border-bottom: 1px inset #ffd500; }

.border-b-warning-outset {
  border-bottom: 1px outset #ffd500; }

.border-b-warning-none {
  border-bottom: 1px none #ffd500; }

.border-b-warning-hidden {
  border-bottom: 1px hidden #ffd500; }

.border-l-warning {
  border-left: 1px solid #ffd500; }

.border-l-warning-dotted {
  border-left: 1px dotted #ffd500; }

.border-l-warning-dashed {
  border-left: 1px dashed #ffd500; }

.border-l-warning-solid {
  border-left: 1px solid #ffd500; }

.border-l-warning-double {
  border-left: 1px double #ffd500; }

.border-l-warning-groove {
  border-left: 1px groove #ffd500; }

.border-l-warning-ridge {
  border-left: 1px ridge #ffd500; }

.border-l-warning-inset {
  border-left: 1px inset #ffd500; }

.border-l-warning-outset {
  border-left: 1px outset #ffd500; }

.border-l-warning-none {
  border-left: 1px none #ffd500; }

.border-l-warning-hidden {
  border-left: 1px hidden #ffd500; }

.has-text-success {
  color: #66cc33; }

.has-background-success {
  background-color: #66cc33; }

.border-success {
  border: 1px solid #66cc33; }

.border-m-success:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #66cc33;
  transform: translateY(-50%); }

.border-success-dotted {
  border: 1px dotted #66cc33; }

.border-success-dashed {
  border: 1px dashed #66cc33; }

.border-success-solid {
  border: 1px solid #66cc33; }

.border-success-double {
  border: 1px double #66cc33; }

.border-success-groove {
  border: 1px groove #66cc33; }

.border-success-ridge {
  border: 1px ridge #66cc33; }

.border-success-inset {
  border: 1px inset #66cc33; }

.border-success-outset {
  border: 1px outset #66cc33; }

.border-success-none {
  border: 1px none #66cc33; }

.border-success-hidden {
  border: 1px hidden #66cc33; }

.border-t-success {
  border-top: 1px solid #66cc33; }

.border-t-success-dotted {
  border-top: 1px dotted #66cc33; }

.border-t-success-dashed {
  border-top: 1px dashed #66cc33; }

.border-t-success-solid {
  border-top: 1px solid #66cc33; }

.border-t-success-double {
  border-top: 1px double #66cc33; }

.border-t-success-groove {
  border-top: 1px groove #66cc33; }

.border-t-success-ridge {
  border-top: 1px ridge #66cc33; }

.border-t-success-inset {
  border-top: 1px inset #66cc33; }

.border-t-success-outset {
  border-top: 1px outset #66cc33; }

.border-t-success-none {
  border-top: 1px none #66cc33; }

.border-t-success-hidden {
  border-top: 1px hidden #66cc33; }

.border-r-success {
  border-right: 1px solid #66cc33; }

.border-r-success-dotted {
  border-right: 1px dotted #66cc33; }

.border-r-success-dashed {
  border-right: 1px dashed #66cc33; }

.border-r-success-solid {
  border-right: 1px solid #66cc33; }

.border-r-success-double {
  border-right: 1px double #66cc33; }

.border-r-success-groove {
  border-right: 1px groove #66cc33; }

.border-r-success-ridge {
  border-right: 1px ridge #66cc33; }

.border-r-success-inset {
  border-right: 1px inset #66cc33; }

.border-r-success-outset {
  border-right: 1px outset #66cc33; }

.border-r-success-none {
  border-right: 1px none #66cc33; }

.border-r-success-hidden {
  border-right: 1px hidden #66cc33; }

.border-b-success {
  border-bottom: 1px solid #66cc33; }

.border-b-success-dotted {
  border-bottom: 1px dotted #66cc33; }

.border-b-success-dashed {
  border-bottom: 1px dashed #66cc33; }

.border-b-success-solid {
  border-bottom: 1px solid #66cc33; }

.border-b-success-double {
  border-bottom: 1px double #66cc33; }

.border-b-success-groove {
  border-bottom: 1px groove #66cc33; }

.border-b-success-ridge {
  border-bottom: 1px ridge #66cc33; }

.border-b-success-inset {
  border-bottom: 1px inset #66cc33; }

.border-b-success-outset {
  border-bottom: 1px outset #66cc33; }

.border-b-success-none {
  border-bottom: 1px none #66cc33; }

.border-b-success-hidden {
  border-bottom: 1px hidden #66cc33; }

.border-l-success {
  border-left: 1px solid #66cc33; }

.border-l-success-dotted {
  border-left: 1px dotted #66cc33; }

.border-l-success-dashed {
  border-left: 1px dashed #66cc33; }

.border-l-success-solid {
  border-left: 1px solid #66cc33; }

.border-l-success-double {
  border-left: 1px double #66cc33; }

.border-l-success-groove {
  border-left: 1px groove #66cc33; }

.border-l-success-ridge {
  border-left: 1px ridge #66cc33; }

.border-l-success-inset {
  border-left: 1px inset #66cc33; }

.border-l-success-outset {
  border-left: 1px outset #66cc33; }

.border-l-success-none {
  border-left: 1px none #66cc33; }

.border-l-success-hidden {
  border-left: 1px hidden #66cc33; }

.has-text-success-light {
  color: #dbffe0; }

.has-background-success-light {
  background-color: #dbffe0; }

.border-success-light {
  border: 1px solid #dbffe0; }

.border-m-success-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dbffe0;
  transform: translateY(-50%); }

.border-success-light-dotted {
  border: 1px dotted #dbffe0; }

.border-success-light-dashed {
  border: 1px dashed #dbffe0; }

.border-success-light-solid {
  border: 1px solid #dbffe0; }

.border-success-light-double {
  border: 1px double #dbffe0; }

.border-success-light-groove {
  border: 1px groove #dbffe0; }

.border-success-light-ridge {
  border: 1px ridge #dbffe0; }

.border-success-light-inset {
  border: 1px inset #dbffe0; }

.border-success-light-outset {
  border: 1px outset #dbffe0; }

.border-success-light-none {
  border: 1px none #dbffe0; }

.border-success-light-hidden {
  border: 1px hidden #dbffe0; }

.border-t-success-light {
  border-top: 1px solid #dbffe0; }

.border-t-success-light-dotted {
  border-top: 1px dotted #dbffe0; }

.border-t-success-light-dashed {
  border-top: 1px dashed #dbffe0; }

.border-t-success-light-solid {
  border-top: 1px solid #dbffe0; }

.border-t-success-light-double {
  border-top: 1px double #dbffe0; }

.border-t-success-light-groove {
  border-top: 1px groove #dbffe0; }

.border-t-success-light-ridge {
  border-top: 1px ridge #dbffe0; }

.border-t-success-light-inset {
  border-top: 1px inset #dbffe0; }

.border-t-success-light-outset {
  border-top: 1px outset #dbffe0; }

.border-t-success-light-none {
  border-top: 1px none #dbffe0; }

.border-t-success-light-hidden {
  border-top: 1px hidden #dbffe0; }

.border-r-success-light {
  border-right: 1px solid #dbffe0; }

.border-r-success-light-dotted {
  border-right: 1px dotted #dbffe0; }

.border-r-success-light-dashed {
  border-right: 1px dashed #dbffe0; }

.border-r-success-light-solid {
  border-right: 1px solid #dbffe0; }

.border-r-success-light-double {
  border-right: 1px double #dbffe0; }

.border-r-success-light-groove {
  border-right: 1px groove #dbffe0; }

.border-r-success-light-ridge {
  border-right: 1px ridge #dbffe0; }

.border-r-success-light-inset {
  border-right: 1px inset #dbffe0; }

.border-r-success-light-outset {
  border-right: 1px outset #dbffe0; }

.border-r-success-light-none {
  border-right: 1px none #dbffe0; }

.border-r-success-light-hidden {
  border-right: 1px hidden #dbffe0; }

.border-b-success-light {
  border-bottom: 1px solid #dbffe0; }

.border-b-success-light-dotted {
  border-bottom: 1px dotted #dbffe0; }

.border-b-success-light-dashed {
  border-bottom: 1px dashed #dbffe0; }

.border-b-success-light-solid {
  border-bottom: 1px solid #dbffe0; }

.border-b-success-light-double {
  border-bottom: 1px double #dbffe0; }

.border-b-success-light-groove {
  border-bottom: 1px groove #dbffe0; }

.border-b-success-light-ridge {
  border-bottom: 1px ridge #dbffe0; }

.border-b-success-light-inset {
  border-bottom: 1px inset #dbffe0; }

.border-b-success-light-outset {
  border-bottom: 1px outset #dbffe0; }

.border-b-success-light-none {
  border-bottom: 1px none #dbffe0; }

.border-b-success-light-hidden {
  border-bottom: 1px hidden #dbffe0; }

.border-l-success-light {
  border-left: 1px solid #dbffe0; }

.border-l-success-light-dotted {
  border-left: 1px dotted #dbffe0; }

.border-l-success-light-dashed {
  border-left: 1px dashed #dbffe0; }

.border-l-success-light-solid {
  border-left: 1px solid #dbffe0; }

.border-l-success-light-double {
  border-left: 1px double #dbffe0; }

.border-l-success-light-groove {
  border-left: 1px groove #dbffe0; }

.border-l-success-light-ridge {
  border-left: 1px ridge #dbffe0; }

.border-l-success-light-inset {
  border-left: 1px inset #dbffe0; }

.border-l-success-light-outset {
  border-left: 1px outset #dbffe0; }

.border-l-success-light-none {
  border-left: 1px none #dbffe0; }

.border-l-success-light-hidden {
  border-left: 1px hidden #dbffe0; }

.has-text-info {
  color: #209fee; }

.has-background-info {
  background-color: #209fee; }

.border-info {
  border: 1px solid #209fee; }

.border-m-info:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #209fee;
  transform: translateY(-50%); }

.border-info-dotted {
  border: 1px dotted #209fee; }

.border-info-dashed {
  border: 1px dashed #209fee; }

.border-info-solid {
  border: 1px solid #209fee; }

.border-info-double {
  border: 1px double #209fee; }

.border-info-groove {
  border: 1px groove #209fee; }

.border-info-ridge {
  border: 1px ridge #209fee; }

.border-info-inset {
  border: 1px inset #209fee; }

.border-info-outset {
  border: 1px outset #209fee; }

.border-info-none {
  border: 1px none #209fee; }

.border-info-hidden {
  border: 1px hidden #209fee; }

.border-t-info {
  border-top: 1px solid #209fee; }

.border-t-info-dotted {
  border-top: 1px dotted #209fee; }

.border-t-info-dashed {
  border-top: 1px dashed #209fee; }

.border-t-info-solid {
  border-top: 1px solid #209fee; }

.border-t-info-double {
  border-top: 1px double #209fee; }

.border-t-info-groove {
  border-top: 1px groove #209fee; }

.border-t-info-ridge {
  border-top: 1px ridge #209fee; }

.border-t-info-inset {
  border-top: 1px inset #209fee; }

.border-t-info-outset {
  border-top: 1px outset #209fee; }

.border-t-info-none {
  border-top: 1px none #209fee; }

.border-t-info-hidden {
  border-top: 1px hidden #209fee; }

.border-r-info {
  border-right: 1px solid #209fee; }

.border-r-info-dotted {
  border-right: 1px dotted #209fee; }

.border-r-info-dashed {
  border-right: 1px dashed #209fee; }

.border-r-info-solid {
  border-right: 1px solid #209fee; }

.border-r-info-double {
  border-right: 1px double #209fee; }

.border-r-info-groove {
  border-right: 1px groove #209fee; }

.border-r-info-ridge {
  border-right: 1px ridge #209fee; }

.border-r-info-inset {
  border-right: 1px inset #209fee; }

.border-r-info-outset {
  border-right: 1px outset #209fee; }

.border-r-info-none {
  border-right: 1px none #209fee; }

.border-r-info-hidden {
  border-right: 1px hidden #209fee; }

.border-b-info {
  border-bottom: 1px solid #209fee; }

.border-b-info-dotted {
  border-bottom: 1px dotted #209fee; }

.border-b-info-dashed {
  border-bottom: 1px dashed #209fee; }

.border-b-info-solid {
  border-bottom: 1px solid #209fee; }

.border-b-info-double {
  border-bottom: 1px double #209fee; }

.border-b-info-groove {
  border-bottom: 1px groove #209fee; }

.border-b-info-ridge {
  border-bottom: 1px ridge #209fee; }

.border-b-info-inset {
  border-bottom: 1px inset #209fee; }

.border-b-info-outset {
  border-bottom: 1px outset #209fee; }

.border-b-info-none {
  border-bottom: 1px none #209fee; }

.border-b-info-hidden {
  border-bottom: 1px hidden #209fee; }

.border-l-info {
  border-left: 1px solid #209fee; }

.border-l-info-dotted {
  border-left: 1px dotted #209fee; }

.border-l-info-dashed {
  border-left: 1px dashed #209fee; }

.border-l-info-solid {
  border-left: 1px solid #209fee; }

.border-l-info-double {
  border-left: 1px double #209fee; }

.border-l-info-groove {
  border-left: 1px groove #209fee; }

.border-l-info-ridge {
  border-left: 1px ridge #209fee; }

.border-l-info-inset {
  border-left: 1px inset #209fee; }

.border-l-info-outset {
  border-left: 1px outset #209fee; }

.border-l-info-none {
  border-left: 1px none #209fee; }

.border-l-info-hidden {
  border-left: 1px hidden #209fee; }

.has-text-black {
  color: #0a0a0a; }

.has-background-black {
  background-color: #0a0a0a; }

.border-black {
  border: 1px solid #0a0a0a; }

.border-black-dotted {
  border: 1px dotted #0a0a0a; }

.border-black-dashed {
  border: 1px dashed #0a0a0a; }

.border-black-solid {
  border: 1px solid #0a0a0a; }

.border-black-double {
  border: 1px double #0a0a0a; }

.border-black-groove {
  border: 1px groove #0a0a0a; }

.border-black-ridge {
  border: 1px ridge #0a0a0a; }

.border-black-inset {
  border: 1px inset #0a0a0a; }

.border-black-outset {
  border: 1px outset #0a0a0a; }

.border-black-none {
  border: 1px none #0a0a0a; }

.border-black-hidden {
  border: 1px hidden #0a0a0a; }

.border-t-black {
  border-top: 1px solid #0a0a0a; }

.border-t-black-dotted {
  border-top: 1px dotted #0a0a0a; }

.border-t-black-dashed {
  border-top: 1px dashed #0a0a0a; }

.border-t-black-solid {
  border-top: 1px solid #0a0a0a; }

.border-t-black-double {
  border-top: 1px double #0a0a0a; }

.border-t-black-groove {
  border-top: 1px groove #0a0a0a; }

.border-t-black-ridge {
  border-top: 1px ridge #0a0a0a; }

.border-t-black-inset {
  border-top: 1px inset #0a0a0a; }

.border-t-black-outset {
  border-top: 1px outset #0a0a0a; }

.border-t-black-none {
  border-top: 1px none #0a0a0a; }

.border-t-black-hidden {
  border-top: 1px hidden #0a0a0a; }

.border-r-black {
  border-right: 1px solid #0a0a0a; }

.border-r-black-dotted {
  border-right: 1px dotted #0a0a0a; }

.border-r-black-dashed {
  border-right: 1px dashed #0a0a0a; }

.border-r-black-solid {
  border-right: 1px solid #0a0a0a; }

.border-r-black-double {
  border-right: 1px double #0a0a0a; }

.border-r-black-groove {
  border-right: 1px groove #0a0a0a; }

.border-r-black-ridge {
  border-right: 1px ridge #0a0a0a; }

.border-r-black-inset {
  border-right: 1px inset #0a0a0a; }

.border-r-black-outset {
  border-right: 1px outset #0a0a0a; }

.border-r-black-none {
  border-right: 1px none #0a0a0a; }

.border-r-black-hidden {
  border-right: 1px hidden #0a0a0a; }

.border-b-black {
  border-bottom: 1px solid #0a0a0a; }

.border-b-black-dotted {
  border-bottom: 1px dotted #0a0a0a; }

.border-b-black-dashed {
  border-bottom: 1px dashed #0a0a0a; }

.border-b-black-solid {
  border-bottom: 1px solid #0a0a0a; }

.border-b-black-double {
  border-bottom: 1px double #0a0a0a; }

.border-b-black-groove {
  border-bottom: 1px groove #0a0a0a; }

.border-b-black-ridge {
  border-bottom: 1px ridge #0a0a0a; }

.border-b-black-inset {
  border-bottom: 1px inset #0a0a0a; }

.border-b-black-outset {
  border-bottom: 1px outset #0a0a0a; }

.border-b-black-none {
  border-bottom: 1px none #0a0a0a; }

.border-b-black-hidden {
  border-bottom: 1px hidden #0a0a0a; }

.border-l-black {
  border-left: 1px solid #0a0a0a; }

.border-l-black-dotted {
  border-left: 1px dotted #0a0a0a; }

.border-l-black-dashed {
  border-left: 1px dashed #0a0a0a; }

.border-l-black-solid {
  border-left: 1px solid #0a0a0a; }

.border-l-black-double {
  border-left: 1px double #0a0a0a; }

.border-l-black-groove {
  border-left: 1px groove #0a0a0a; }

.border-l-black-ridge {
  border-left: 1px ridge #0a0a0a; }

.border-l-black-inset {
  border-left: 1px inset #0a0a0a; }

.border-l-black-outset {
  border-left: 1px outset #0a0a0a; }

.border-l-black-none {
  border-left: 1px none #0a0a0a; }

.border-l-black-hidden {
  border-left: 1px hidden #0a0a0a; }

.has-text-black-bis {
  color: #121212; }

.has-background-black-bis {
  background-color: #121212; }

.border-black-bis {
  border: 1px solid #121212; }

.border-black-bis-dotted {
  border: 1px dotted #121212; }

.border-black-bis-dashed {
  border: 1px dashed #121212; }

.border-black-bis-solid {
  border: 1px solid #121212; }

.border-black-bis-double {
  border: 1px double #121212; }

.border-black-bis-groove {
  border: 1px groove #121212; }

.border-black-bis-ridge {
  border: 1px ridge #121212; }

.border-black-bis-inset {
  border: 1px inset #121212; }

.border-black-bis-outset {
  border: 1px outset #121212; }

.border-black-bis-none {
  border: 1px none #121212; }

.border-black-bis-hidden {
  border: 1px hidden #121212; }

.border-t-black-bis {
  border-top: 1px solid #121212; }

.border-t-black-bis-dotted {
  border-top: 1px dotted #121212; }

.border-t-black-bis-dashed {
  border-top: 1px dashed #121212; }

.border-t-black-bis-solid {
  border-top: 1px solid #121212; }

.border-t-black-bis-double {
  border-top: 1px double #121212; }

.border-t-black-bis-groove {
  border-top: 1px groove #121212; }

.border-t-black-bis-ridge {
  border-top: 1px ridge #121212; }

.border-t-black-bis-inset {
  border-top: 1px inset #121212; }

.border-t-black-bis-outset {
  border-top: 1px outset #121212; }

.border-t-black-bis-none {
  border-top: 1px none #121212; }

.border-t-black-bis-hidden {
  border-top: 1px hidden #121212; }

.border-r-black-bis {
  border-right: 1px solid #121212; }

.border-r-black-bis-dotted {
  border-right: 1px dotted #121212; }

.border-r-black-bis-dashed {
  border-right: 1px dashed #121212; }

.border-r-black-bis-solid {
  border-right: 1px solid #121212; }

.border-r-black-bis-double {
  border-right: 1px double #121212; }

.border-r-black-bis-groove {
  border-right: 1px groove #121212; }

.border-r-black-bis-ridge {
  border-right: 1px ridge #121212; }

.border-r-black-bis-inset {
  border-right: 1px inset #121212; }

.border-r-black-bis-outset {
  border-right: 1px outset #121212; }

.border-r-black-bis-none {
  border-right: 1px none #121212; }

.border-r-black-bis-hidden {
  border-right: 1px hidden #121212; }

.border-b-black-bis {
  border-bottom: 1px solid #121212; }

.border-b-black-bis-dotted {
  border-bottom: 1px dotted #121212; }

.border-b-black-bis-dashed {
  border-bottom: 1px dashed #121212; }

.border-b-black-bis-solid {
  border-bottom: 1px solid #121212; }

.border-b-black-bis-double {
  border-bottom: 1px double #121212; }

.border-b-black-bis-groove {
  border-bottom: 1px groove #121212; }

.border-b-black-bis-ridge {
  border-bottom: 1px ridge #121212; }

.border-b-black-bis-inset {
  border-bottom: 1px inset #121212; }

.border-b-black-bis-outset {
  border-bottom: 1px outset #121212; }

.border-b-black-bis-none {
  border-bottom: 1px none #121212; }

.border-b-black-bis-hidden {
  border-bottom: 1px hidden #121212; }

.border-l-black-bis {
  border-left: 1px solid #121212; }

.border-l-black-bis-dotted {
  border-left: 1px dotted #121212; }

.border-l-black-bis-dashed {
  border-left: 1px dashed #121212; }

.border-l-black-bis-solid {
  border-left: 1px solid #121212; }

.border-l-black-bis-double {
  border-left: 1px double #121212; }

.border-l-black-bis-groove {
  border-left: 1px groove #121212; }

.border-l-black-bis-ridge {
  border-left: 1px ridge #121212; }

.border-l-black-bis-inset {
  border-left: 1px inset #121212; }

.border-l-black-bis-outset {
  border-left: 1px outset #121212; }

.border-l-black-bis-none {
  border-left: 1px none #121212; }

.border-l-black-bis-hidden {
  border-left: 1px hidden #121212; }

.has-text-black-ter {
  color: #242424; }

.has-background-black-ter {
  background-color: #242424; }

.border-black-ter {
  border: 1px solid #242424; }

.border-black-ter-dotted {
  border: 1px dotted #242424; }

.border-black-ter-dashed {
  border: 1px dashed #242424; }

.border-black-ter-solid {
  border: 1px solid #242424; }

.border-black-ter-double {
  border: 1px double #242424; }

.border-black-ter-groove {
  border: 1px groove #242424; }

.border-black-ter-ridge {
  border: 1px ridge #242424; }

.border-black-ter-inset {
  border: 1px inset #242424; }

.border-black-ter-outset {
  border: 1px outset #242424; }

.border-black-ter-none {
  border: 1px none #242424; }

.border-black-ter-hidden {
  border: 1px hidden #242424; }

.border-t-black-ter {
  border-top: 1px solid #242424; }

.border-t-black-ter-dotted {
  border-top: 1px dotted #242424; }

.border-t-black-ter-dashed {
  border-top: 1px dashed #242424; }

.border-t-black-ter-solid {
  border-top: 1px solid #242424; }

.border-t-black-ter-double {
  border-top: 1px double #242424; }

.border-t-black-ter-groove {
  border-top: 1px groove #242424; }

.border-t-black-ter-ridge {
  border-top: 1px ridge #242424; }

.border-t-black-ter-inset {
  border-top: 1px inset #242424; }

.border-t-black-ter-outset {
  border-top: 1px outset #242424; }

.border-t-black-ter-none {
  border-top: 1px none #242424; }

.border-t-black-ter-hidden {
  border-top: 1px hidden #242424; }

.border-r-black-ter {
  border-right: 1px solid #242424; }

.border-r-black-ter-dotted {
  border-right: 1px dotted #242424; }

.border-r-black-ter-dashed {
  border-right: 1px dashed #242424; }

.border-r-black-ter-solid {
  border-right: 1px solid #242424; }

.border-r-black-ter-double {
  border-right: 1px double #242424; }

.border-r-black-ter-groove {
  border-right: 1px groove #242424; }

.border-r-black-ter-ridge {
  border-right: 1px ridge #242424; }

.border-r-black-ter-inset {
  border-right: 1px inset #242424; }

.border-r-black-ter-outset {
  border-right: 1px outset #242424; }

.border-r-black-ter-none {
  border-right: 1px none #242424; }

.border-r-black-ter-hidden {
  border-right: 1px hidden #242424; }

.border-b-black-ter {
  border-bottom: 1px solid #242424; }

.border-b-black-ter-dotted {
  border-bottom: 1px dotted #242424; }

.border-b-black-ter-dashed {
  border-bottom: 1px dashed #242424; }

.border-b-black-ter-solid {
  border-bottom: 1px solid #242424; }

.border-b-black-ter-double {
  border-bottom: 1px double #242424; }

.border-b-black-ter-groove {
  border-bottom: 1px groove #242424; }

.border-b-black-ter-ridge {
  border-bottom: 1px ridge #242424; }

.border-b-black-ter-inset {
  border-bottom: 1px inset #242424; }

.border-b-black-ter-outset {
  border-bottom: 1px outset #242424; }

.border-b-black-ter-none {
  border-bottom: 1px none #242424; }

.border-b-black-ter-hidden {
  border-bottom: 1px hidden #242424; }

.border-l-black-ter {
  border-left: 1px solid #242424; }

.border-l-black-ter-dotted {
  border-left: 1px dotted #242424; }

.border-l-black-ter-dashed {
  border-left: 1px dashed #242424; }

.border-l-black-ter-solid {
  border-left: 1px solid #242424; }

.border-l-black-ter-double {
  border-left: 1px double #242424; }

.border-l-black-ter-groove {
  border-left: 1px groove #242424; }

.border-l-black-ter-ridge {
  border-left: 1px ridge #242424; }

.border-l-black-ter-inset {
  border-left: 1px inset #242424; }

.border-l-black-ter-outset {
  border-left: 1px outset #242424; }

.border-l-black-ter-none {
  border-left: 1px none #242424; }

.border-l-black-ter-hidden {
  border-left: 1px hidden #242424; }

.has-text-grey-darker {
  color: #363636; }

.has-background-grey-darker {
  background-color: #363636; }

.border-grey-darker {
  border: 1px solid #363636; }

.border-grey-darker-dotted {
  border: 1px dotted #363636; }

.border-grey-darker-dashed {
  border: 1px dashed #363636; }

.border-grey-darker-solid {
  border: 1px solid #363636; }

.border-grey-darker-double {
  border: 1px double #363636; }

.border-grey-darker-groove {
  border: 1px groove #363636; }

.border-grey-darker-ridge {
  border: 1px ridge #363636; }

.border-grey-darker-inset {
  border: 1px inset #363636; }

.border-grey-darker-outset {
  border: 1px outset #363636; }

.border-grey-darker-none {
  border: 1px none #363636; }

.border-grey-darker-hidden {
  border: 1px hidden #363636; }

.border-t-grey-darker {
  border-top: 1px solid #363636; }

.border-t-grey-darker-dotted {
  border-top: 1px dotted #363636; }

.border-t-grey-darker-dashed {
  border-top: 1px dashed #363636; }

.border-t-grey-darker-solid {
  border-top: 1px solid #363636; }

.border-t-grey-darker-double {
  border-top: 1px double #363636; }

.border-t-grey-darker-groove {
  border-top: 1px groove #363636; }

.border-t-grey-darker-ridge {
  border-top: 1px ridge #363636; }

.border-t-grey-darker-inset {
  border-top: 1px inset #363636; }

.border-t-grey-darker-outset {
  border-top: 1px outset #363636; }

.border-t-grey-darker-none {
  border-top: 1px none #363636; }

.border-t-grey-darker-hidden {
  border-top: 1px hidden #363636; }

.border-r-grey-darker {
  border-right: 1px solid #363636; }

.border-r-grey-darker-dotted {
  border-right: 1px dotted #363636; }

.border-r-grey-darker-dashed {
  border-right: 1px dashed #363636; }

.border-r-grey-darker-solid {
  border-right: 1px solid #363636; }

.border-r-grey-darker-double {
  border-right: 1px double #363636; }

.border-r-grey-darker-groove {
  border-right: 1px groove #363636; }

.border-r-grey-darker-ridge {
  border-right: 1px ridge #363636; }

.border-r-grey-darker-inset {
  border-right: 1px inset #363636; }

.border-r-grey-darker-outset {
  border-right: 1px outset #363636; }

.border-r-grey-darker-none {
  border-right: 1px none #363636; }

.border-r-grey-darker-hidden {
  border-right: 1px hidden #363636; }

.border-b-grey-darker {
  border-bottom: 1px solid #363636; }

.border-b-grey-darker-dotted {
  border-bottom: 1px dotted #363636; }

.border-b-grey-darker-dashed {
  border-bottom: 1px dashed #363636; }

.border-b-grey-darker-solid {
  border-bottom: 1px solid #363636; }

.border-b-grey-darker-double {
  border-bottom: 1px double #363636; }

.border-b-grey-darker-groove {
  border-bottom: 1px groove #363636; }

.border-b-grey-darker-ridge {
  border-bottom: 1px ridge #363636; }

.border-b-grey-darker-inset {
  border-bottom: 1px inset #363636; }

.border-b-grey-darker-outset {
  border-bottom: 1px outset #363636; }

.border-b-grey-darker-none {
  border-bottom: 1px none #363636; }

.border-b-grey-darker-hidden {
  border-bottom: 1px hidden #363636; }

.border-l-grey-darker {
  border-left: 1px solid #363636; }

.border-l-grey-darker-dotted {
  border-left: 1px dotted #363636; }

.border-l-grey-darker-dashed {
  border-left: 1px dashed #363636; }

.border-l-grey-darker-solid {
  border-left: 1px solid #363636; }

.border-l-grey-darker-double {
  border-left: 1px double #363636; }

.border-l-grey-darker-groove {
  border-left: 1px groove #363636; }

.border-l-grey-darker-ridge {
  border-left: 1px ridge #363636; }

.border-l-grey-darker-inset {
  border-left: 1px inset #363636; }

.border-l-grey-darker-outset {
  border-left: 1px outset #363636; }

.border-l-grey-darker-none {
  border-left: 1px none #363636; }

.border-l-grey-darker-hidden {
  border-left: 1px hidden #363636; }

.has-text-grey-dark {
  color: #4a4a4a; }

.has-background-grey-dark {
  background-color: #4a4a4a; }

.border-grey-dark {
  border: 1px solid #4a4a4a; }

.border-grey-dark-dotted {
  border: 1px dotted #4a4a4a; }

.border-grey-dark-dashed {
  border: 1px dashed #4a4a4a; }

.border-grey-dark-solid {
  border: 1px solid #4a4a4a; }

.border-grey-dark-double {
  border: 1px double #4a4a4a; }

.border-grey-dark-groove {
  border: 1px groove #4a4a4a; }

.border-grey-dark-ridge {
  border: 1px ridge #4a4a4a; }

.border-grey-dark-inset {
  border: 1px inset #4a4a4a; }

.border-grey-dark-outset {
  border: 1px outset #4a4a4a; }

.border-grey-dark-none {
  border: 1px none #4a4a4a; }

.border-grey-dark-hidden {
  border: 1px hidden #4a4a4a; }

.border-t-grey-dark {
  border-top: 1px solid #4a4a4a; }

.border-t-grey-dark-dotted {
  border-top: 1px dotted #4a4a4a; }

.border-t-grey-dark-dashed {
  border-top: 1px dashed #4a4a4a; }

.border-t-grey-dark-solid {
  border-top: 1px solid #4a4a4a; }

.border-t-grey-dark-double {
  border-top: 1px double #4a4a4a; }

.border-t-grey-dark-groove {
  border-top: 1px groove #4a4a4a; }

.border-t-grey-dark-ridge {
  border-top: 1px ridge #4a4a4a; }

.border-t-grey-dark-inset {
  border-top: 1px inset #4a4a4a; }

.border-t-grey-dark-outset {
  border-top: 1px outset #4a4a4a; }

.border-t-grey-dark-none {
  border-top: 1px none #4a4a4a; }

.border-t-grey-dark-hidden {
  border-top: 1px hidden #4a4a4a; }

.border-r-grey-dark {
  border-right: 1px solid #4a4a4a; }

.border-r-grey-dark-dotted {
  border-right: 1px dotted #4a4a4a; }

.border-r-grey-dark-dashed {
  border-right: 1px dashed #4a4a4a; }

.border-r-grey-dark-solid {
  border-right: 1px solid #4a4a4a; }

.border-r-grey-dark-double {
  border-right: 1px double #4a4a4a; }

.border-r-grey-dark-groove {
  border-right: 1px groove #4a4a4a; }

.border-r-grey-dark-ridge {
  border-right: 1px ridge #4a4a4a; }

.border-r-grey-dark-inset {
  border-right: 1px inset #4a4a4a; }

.border-r-grey-dark-outset {
  border-right: 1px outset #4a4a4a; }

.border-r-grey-dark-none {
  border-right: 1px none #4a4a4a; }

.border-r-grey-dark-hidden {
  border-right: 1px hidden #4a4a4a; }

.border-b-grey-dark {
  border-bottom: 1px solid #4a4a4a; }

.border-b-grey-dark-dotted {
  border-bottom: 1px dotted #4a4a4a; }

.border-b-grey-dark-dashed {
  border-bottom: 1px dashed #4a4a4a; }

.border-b-grey-dark-solid {
  border-bottom: 1px solid #4a4a4a; }

.border-b-grey-dark-double {
  border-bottom: 1px double #4a4a4a; }

.border-b-grey-dark-groove {
  border-bottom: 1px groove #4a4a4a; }

.border-b-grey-dark-ridge {
  border-bottom: 1px ridge #4a4a4a; }

.border-b-grey-dark-inset {
  border-bottom: 1px inset #4a4a4a; }

.border-b-grey-dark-outset {
  border-bottom: 1px outset #4a4a4a; }

.border-b-grey-dark-none {
  border-bottom: 1px none #4a4a4a; }

.border-b-grey-dark-hidden {
  border-bottom: 1px hidden #4a4a4a; }

.border-l-grey-dark {
  border-left: 1px solid #4a4a4a; }

.border-l-grey-dark-dotted {
  border-left: 1px dotted #4a4a4a; }

.border-l-grey-dark-dashed {
  border-left: 1px dashed #4a4a4a; }

.border-l-grey-dark-solid {
  border-left: 1px solid #4a4a4a; }

.border-l-grey-dark-double {
  border-left: 1px double #4a4a4a; }

.border-l-grey-dark-groove {
  border-left: 1px groove #4a4a4a; }

.border-l-grey-dark-ridge {
  border-left: 1px ridge #4a4a4a; }

.border-l-grey-dark-inset {
  border-left: 1px inset #4a4a4a; }

.border-l-grey-dark-outset {
  border-left: 1px outset #4a4a4a; }

.border-l-grey-dark-none {
  border-left: 1px none #4a4a4a; }

.border-l-grey-dark-hidden {
  border-left: 1px hidden #4a4a4a; }

.has-text-grey {
  color: #7a7a7a; }

.has-background-grey {
  background-color: #7a7a7a; }

.border-grey {
  border: 1px solid #7a7a7a; }

.border-grey-dotted {
  border: 1px dotted #7a7a7a; }

.border-grey-dashed {
  border: 1px dashed #7a7a7a; }

.border-grey-solid {
  border: 1px solid #7a7a7a; }

.border-grey-double {
  border: 1px double #7a7a7a; }

.border-grey-groove {
  border: 1px groove #7a7a7a; }

.border-grey-ridge {
  border: 1px ridge #7a7a7a; }

.border-grey-inset {
  border: 1px inset #7a7a7a; }

.border-grey-outset {
  border: 1px outset #7a7a7a; }

.border-grey-none {
  border: 1px none #7a7a7a; }

.border-grey-hidden {
  border: 1px hidden #7a7a7a; }

.border-t-grey {
  border-top: 1px solid #7a7a7a; }

.border-t-grey-dotted {
  border-top: 1px dotted #7a7a7a; }

.border-t-grey-dashed {
  border-top: 1px dashed #7a7a7a; }

.border-t-grey-solid {
  border-top: 1px solid #7a7a7a; }

.border-t-grey-double {
  border-top: 1px double #7a7a7a; }

.border-t-grey-groove {
  border-top: 1px groove #7a7a7a; }

.border-t-grey-ridge {
  border-top: 1px ridge #7a7a7a; }

.border-t-grey-inset {
  border-top: 1px inset #7a7a7a; }

.border-t-grey-outset {
  border-top: 1px outset #7a7a7a; }

.border-t-grey-none {
  border-top: 1px none #7a7a7a; }

.border-t-grey-hidden {
  border-top: 1px hidden #7a7a7a; }

.border-r-grey {
  border-right: 1px solid #7a7a7a; }

.border-r-grey-dotted {
  border-right: 1px dotted #7a7a7a; }

.border-r-grey-dashed {
  border-right: 1px dashed #7a7a7a; }

.border-r-grey-solid {
  border-right: 1px solid #7a7a7a; }

.border-r-grey-double {
  border-right: 1px double #7a7a7a; }

.border-r-grey-groove {
  border-right: 1px groove #7a7a7a; }

.border-r-grey-ridge {
  border-right: 1px ridge #7a7a7a; }

.border-r-grey-inset {
  border-right: 1px inset #7a7a7a; }

.border-r-grey-outset {
  border-right: 1px outset #7a7a7a; }

.border-r-grey-none {
  border-right: 1px none #7a7a7a; }

.border-r-grey-hidden {
  border-right: 1px hidden #7a7a7a; }

.border-b-grey {
  border-bottom: 1px solid #7a7a7a; }

.border-b-grey-dotted {
  border-bottom: 1px dotted #7a7a7a; }

.border-b-grey-dashed {
  border-bottom: 1px dashed #7a7a7a; }

.border-b-grey-solid {
  border-bottom: 1px solid #7a7a7a; }

.border-b-grey-double {
  border-bottom: 1px double #7a7a7a; }

.border-b-grey-groove {
  border-bottom: 1px groove #7a7a7a; }

.border-b-grey-ridge {
  border-bottom: 1px ridge #7a7a7a; }

.border-b-grey-inset {
  border-bottom: 1px inset #7a7a7a; }

.border-b-grey-outset {
  border-bottom: 1px outset #7a7a7a; }

.border-b-grey-none {
  border-bottom: 1px none #7a7a7a; }

.border-b-grey-hidden {
  border-bottom: 1px hidden #7a7a7a; }

.border-l-grey {
  border-left: 1px solid #7a7a7a; }

.border-l-grey-dotted {
  border-left: 1px dotted #7a7a7a; }

.border-l-grey-dashed {
  border-left: 1px dashed #7a7a7a; }

.border-l-grey-solid {
  border-left: 1px solid #7a7a7a; }

.border-l-grey-double {
  border-left: 1px double #7a7a7a; }

.border-l-grey-groove {
  border-left: 1px groove #7a7a7a; }

.border-l-grey-ridge {
  border-left: 1px ridge #7a7a7a; }

.border-l-grey-inset {
  border-left: 1px inset #7a7a7a; }

.border-l-grey-outset {
  border-left: 1px outset #7a7a7a; }

.border-l-grey-none {
  border-left: 1px none #7a7a7a; }

.border-l-grey-hidden {
  border-left: 1px hidden #7a7a7a; }

.has-text-grey-light {
  color: #b5b5b5; }

.has-background-grey-light {
  background-color: #b5b5b5; }

.border-grey-light {
  border: 1px solid #b5b5b5; }

.border-grey-light-dotted {
  border: 1px dotted #b5b5b5; }

.border-grey-light-dashed {
  border: 1px dashed #b5b5b5; }

.border-grey-light-solid {
  border: 1px solid #b5b5b5; }

.border-grey-light-double {
  border: 1px double #b5b5b5; }

.border-grey-light-groove {
  border: 1px groove #b5b5b5; }

.border-grey-light-ridge {
  border: 1px ridge #b5b5b5; }

.border-grey-light-inset {
  border: 1px inset #b5b5b5; }

.border-grey-light-outset {
  border: 1px outset #b5b5b5; }

.border-grey-light-none {
  border: 1px none #b5b5b5; }

.border-grey-light-hidden {
  border: 1px hidden #b5b5b5; }

.border-t-grey-light {
  border-top: 1px solid #b5b5b5; }

.border-t-grey-light-dotted {
  border-top: 1px dotted #b5b5b5; }

.border-t-grey-light-dashed {
  border-top: 1px dashed #b5b5b5; }

.border-t-grey-light-solid {
  border-top: 1px solid #b5b5b5; }

.border-t-grey-light-double {
  border-top: 1px double #b5b5b5; }

.border-t-grey-light-groove {
  border-top: 1px groove #b5b5b5; }

.border-t-grey-light-ridge {
  border-top: 1px ridge #b5b5b5; }

.border-t-grey-light-inset {
  border-top: 1px inset #b5b5b5; }

.border-t-grey-light-outset {
  border-top: 1px outset #b5b5b5; }

.border-t-grey-light-none {
  border-top: 1px none #b5b5b5; }

.border-t-grey-light-hidden {
  border-top: 1px hidden #b5b5b5; }

.border-r-grey-light {
  border-right: 1px solid #b5b5b5; }

.border-r-grey-light-dotted {
  border-right: 1px dotted #b5b5b5; }

.border-r-grey-light-dashed {
  border-right: 1px dashed #b5b5b5; }

.border-r-grey-light-solid {
  border-right: 1px solid #b5b5b5; }

.border-r-grey-light-double {
  border-right: 1px double #b5b5b5; }

.border-r-grey-light-groove {
  border-right: 1px groove #b5b5b5; }

.border-r-grey-light-ridge {
  border-right: 1px ridge #b5b5b5; }

.border-r-grey-light-inset {
  border-right: 1px inset #b5b5b5; }

.border-r-grey-light-outset {
  border-right: 1px outset #b5b5b5; }

.border-r-grey-light-none {
  border-right: 1px none #b5b5b5; }

.border-r-grey-light-hidden {
  border-right: 1px hidden #b5b5b5; }

.border-b-grey-light {
  border-bottom: 1px solid #b5b5b5; }

.border-b-grey-light-dotted {
  border-bottom: 1px dotted #b5b5b5; }

.border-b-grey-light-dashed {
  border-bottom: 1px dashed #b5b5b5; }

.border-b-grey-light-solid {
  border-bottom: 1px solid #b5b5b5; }

.border-b-grey-light-double {
  border-bottom: 1px double #b5b5b5; }

.border-b-grey-light-groove {
  border-bottom: 1px groove #b5b5b5; }

.border-b-grey-light-ridge {
  border-bottom: 1px ridge #b5b5b5; }

.border-b-grey-light-inset {
  border-bottom: 1px inset #b5b5b5; }

.border-b-grey-light-outset {
  border-bottom: 1px outset #b5b5b5; }

.border-b-grey-light-none {
  border-bottom: 1px none #b5b5b5; }

.border-b-grey-light-hidden {
  border-bottom: 1px hidden #b5b5b5; }

.border-l-grey-light {
  border-left: 1px solid #b5b5b5; }

.border-l-grey-light-dotted {
  border-left: 1px dotted #b5b5b5; }

.border-l-grey-light-dashed {
  border-left: 1px dashed #b5b5b5; }

.border-l-grey-light-solid {
  border-left: 1px solid #b5b5b5; }

.border-l-grey-light-double {
  border-left: 1px double #b5b5b5; }

.border-l-grey-light-groove {
  border-left: 1px groove #b5b5b5; }

.border-l-grey-light-ridge {
  border-left: 1px ridge #b5b5b5; }

.border-l-grey-light-inset {
  border-left: 1px inset #b5b5b5; }

.border-l-grey-light-outset {
  border-left: 1px outset #b5b5b5; }

.border-l-grey-light-none {
  border-left: 1px none #b5b5b5; }

.border-l-grey-light-hidden {
  border-left: 1px hidden #b5b5b5; }

.has-text-grey-lighter {
  color: #e6e6e6; }

.has-background-grey-lighter {
  background-color: #e6e6e6; }

.border-grey-lighter {
  border: 1px solid #e6e6e6; }

.border-grey-lighter-dotted {
  border: 1px dotted #e6e6e6; }

.border-grey-lighter-dashed {
  border: 1px dashed #e6e6e6; }

.border-grey-lighter-solid {
  border: 1px solid #e6e6e6; }

.border-grey-lighter-double {
  border: 1px double #e6e6e6; }

.border-grey-lighter-groove {
  border: 1px groove #e6e6e6; }

.border-grey-lighter-ridge {
  border: 1px ridge #e6e6e6; }

.border-grey-lighter-inset {
  border: 1px inset #e6e6e6; }

.border-grey-lighter-outset {
  border: 1px outset #e6e6e6; }

.border-grey-lighter-none {
  border: 1px none #e6e6e6; }

.border-grey-lighter-hidden {
  border: 1px hidden #e6e6e6; }

.border-t-grey-lighter {
  border-top: 1px solid #e6e6e6; }

.border-t-grey-lighter-dotted {
  border-top: 1px dotted #e6e6e6; }

.border-t-grey-lighter-dashed {
  border-top: 1px dashed #e6e6e6; }

.border-t-grey-lighter-solid {
  border-top: 1px solid #e6e6e6; }

.border-t-grey-lighter-double {
  border-top: 1px double #e6e6e6; }

.border-t-grey-lighter-groove {
  border-top: 1px groove #e6e6e6; }

.border-t-grey-lighter-ridge {
  border-top: 1px ridge #e6e6e6; }

.border-t-grey-lighter-inset {
  border-top: 1px inset #e6e6e6; }

.border-t-grey-lighter-outset {
  border-top: 1px outset #e6e6e6; }

.border-t-grey-lighter-none {
  border-top: 1px none #e6e6e6; }

.border-t-grey-lighter-hidden {
  border-top: 1px hidden #e6e6e6; }

.border-r-grey-lighter {
  border-right: 1px solid #e6e6e6; }

.border-r-grey-lighter-dotted {
  border-right: 1px dotted #e6e6e6; }

.border-r-grey-lighter-dashed {
  border-right: 1px dashed #e6e6e6; }

.border-r-grey-lighter-solid {
  border-right: 1px solid #e6e6e6; }

.border-r-grey-lighter-double {
  border-right: 1px double #e6e6e6; }

.border-r-grey-lighter-groove {
  border-right: 1px groove #e6e6e6; }

.border-r-grey-lighter-ridge {
  border-right: 1px ridge #e6e6e6; }

.border-r-grey-lighter-inset {
  border-right: 1px inset #e6e6e6; }

.border-r-grey-lighter-outset {
  border-right: 1px outset #e6e6e6; }

.border-r-grey-lighter-none {
  border-right: 1px none #e6e6e6; }

.border-r-grey-lighter-hidden {
  border-right: 1px hidden #e6e6e6; }

.border-b-grey-lighter {
  border-bottom: 1px solid #e6e6e6; }

.border-b-grey-lighter-dotted {
  border-bottom: 1px dotted #e6e6e6; }

.border-b-grey-lighter-dashed {
  border-bottom: 1px dashed #e6e6e6; }

.border-b-grey-lighter-solid {
  border-bottom: 1px solid #e6e6e6; }

.border-b-grey-lighter-double {
  border-bottom: 1px double #e6e6e6; }

.border-b-grey-lighter-groove {
  border-bottom: 1px groove #e6e6e6; }

.border-b-grey-lighter-ridge {
  border-bottom: 1px ridge #e6e6e6; }

.border-b-grey-lighter-inset {
  border-bottom: 1px inset #e6e6e6; }

.border-b-grey-lighter-outset {
  border-bottom: 1px outset #e6e6e6; }

.border-b-grey-lighter-none {
  border-bottom: 1px none #e6e6e6; }

.border-b-grey-lighter-hidden {
  border-bottom: 1px hidden #e6e6e6; }

.border-l-grey-lighter {
  border-left: 1px solid #e6e6e6; }

.border-l-grey-lighter-dotted {
  border-left: 1px dotted #e6e6e6; }

.border-l-grey-lighter-dashed {
  border-left: 1px dashed #e6e6e6; }

.border-l-grey-lighter-solid {
  border-left: 1px solid #e6e6e6; }

.border-l-grey-lighter-double {
  border-left: 1px double #e6e6e6; }

.border-l-grey-lighter-groove {
  border-left: 1px groove #e6e6e6; }

.border-l-grey-lighter-ridge {
  border-left: 1px ridge #e6e6e6; }

.border-l-grey-lighter-inset {
  border-left: 1px inset #e6e6e6; }

.border-l-grey-lighter-outset {
  border-left: 1px outset #e6e6e6; }

.border-l-grey-lighter-none {
  border-left: 1px none #e6e6e6; }

.border-l-grey-lighter-hidden {
  border-left: 1px hidden #e6e6e6; }

.has-text-white-ter {
  color: whitesmoke; }

.has-background-white-ter {
  background-color: whitesmoke; }

.border-white-ter {
  border: 1px solid whitesmoke; }

.border-white-ter-dotted {
  border: 1px dotted whitesmoke; }

.border-white-ter-dashed {
  border: 1px dashed whitesmoke; }

.border-white-ter-solid {
  border: 1px solid whitesmoke; }

.border-white-ter-double {
  border: 1px double whitesmoke; }

.border-white-ter-groove {
  border: 1px groove whitesmoke; }

.border-white-ter-ridge {
  border: 1px ridge whitesmoke; }

.border-white-ter-inset {
  border: 1px inset whitesmoke; }

.border-white-ter-outset {
  border: 1px outset whitesmoke; }

.border-white-ter-none {
  border: 1px none whitesmoke; }

.border-white-ter-hidden {
  border: 1px hidden whitesmoke; }

.border-t-white-ter {
  border-top: 1px solid whitesmoke; }

.border-t-white-ter-dotted {
  border-top: 1px dotted whitesmoke; }

.border-t-white-ter-dashed {
  border-top: 1px dashed whitesmoke; }

.border-t-white-ter-solid {
  border-top: 1px solid whitesmoke; }

.border-t-white-ter-double {
  border-top: 1px double whitesmoke; }

.border-t-white-ter-groove {
  border-top: 1px groove whitesmoke; }

.border-t-white-ter-ridge {
  border-top: 1px ridge whitesmoke; }

.border-t-white-ter-inset {
  border-top: 1px inset whitesmoke; }

.border-t-white-ter-outset {
  border-top: 1px outset whitesmoke; }

.border-t-white-ter-none {
  border-top: 1px none whitesmoke; }

.border-t-white-ter-hidden {
  border-top: 1px hidden whitesmoke; }

.border-r-white-ter {
  border-right: 1px solid whitesmoke; }

.border-r-white-ter-dotted {
  border-right: 1px dotted whitesmoke; }

.border-r-white-ter-dashed {
  border-right: 1px dashed whitesmoke; }

.border-r-white-ter-solid {
  border-right: 1px solid whitesmoke; }

.border-r-white-ter-double {
  border-right: 1px double whitesmoke; }

.border-r-white-ter-groove {
  border-right: 1px groove whitesmoke; }

.border-r-white-ter-ridge {
  border-right: 1px ridge whitesmoke; }

.border-r-white-ter-inset {
  border-right: 1px inset whitesmoke; }

.border-r-white-ter-outset {
  border-right: 1px outset whitesmoke; }

.border-r-white-ter-none {
  border-right: 1px none whitesmoke; }

.border-r-white-ter-hidden {
  border-right: 1px hidden whitesmoke; }

.border-b-white-ter {
  border-bottom: 1px solid whitesmoke; }

.border-b-white-ter-dotted {
  border-bottom: 1px dotted whitesmoke; }

.border-b-white-ter-dashed {
  border-bottom: 1px dashed whitesmoke; }

.border-b-white-ter-solid {
  border-bottom: 1px solid whitesmoke; }

.border-b-white-ter-double {
  border-bottom: 1px double whitesmoke; }

.border-b-white-ter-groove {
  border-bottom: 1px groove whitesmoke; }

.border-b-white-ter-ridge {
  border-bottom: 1px ridge whitesmoke; }

.border-b-white-ter-inset {
  border-bottom: 1px inset whitesmoke; }

.border-b-white-ter-outset {
  border-bottom: 1px outset whitesmoke; }

.border-b-white-ter-none {
  border-bottom: 1px none whitesmoke; }

.border-b-white-ter-hidden {
  border-bottom: 1px hidden whitesmoke; }

.border-l-white-ter {
  border-left: 1px solid whitesmoke; }

.border-l-white-ter-dotted {
  border-left: 1px dotted whitesmoke; }

.border-l-white-ter-dashed {
  border-left: 1px dashed whitesmoke; }

.border-l-white-ter-solid {
  border-left: 1px solid whitesmoke; }

.border-l-white-ter-double {
  border-left: 1px double whitesmoke; }

.border-l-white-ter-groove {
  border-left: 1px groove whitesmoke; }

.border-l-white-ter-ridge {
  border-left: 1px ridge whitesmoke; }

.border-l-white-ter-inset {
  border-left: 1px inset whitesmoke; }

.border-l-white-ter-outset {
  border-left: 1px outset whitesmoke; }

.border-l-white-ter-none {
  border-left: 1px none whitesmoke; }

.border-l-white-ter-hidden {
  border-left: 1px hidden whitesmoke; }

.has-text-white-bis {
  color: #fafafa; }

.has-background-white-bis {
  background-color: #fafafa; }

.border-white-bis {
  border: 1px solid #fafafa; }

.border-white-bis-dotted {
  border: 1px dotted #fafafa; }

.border-white-bis-dashed {
  border: 1px dashed #fafafa; }

.border-white-bis-solid {
  border: 1px solid #fafafa; }

.border-white-bis-double {
  border: 1px double #fafafa; }

.border-white-bis-groove {
  border: 1px groove #fafafa; }

.border-white-bis-ridge {
  border: 1px ridge #fafafa; }

.border-white-bis-inset {
  border: 1px inset #fafafa; }

.border-white-bis-outset {
  border: 1px outset #fafafa; }

.border-white-bis-none {
  border: 1px none #fafafa; }

.border-white-bis-hidden {
  border: 1px hidden #fafafa; }

.border-t-white-bis {
  border-top: 1px solid #fafafa; }

.border-t-white-bis-dotted {
  border-top: 1px dotted #fafafa; }

.border-t-white-bis-dashed {
  border-top: 1px dashed #fafafa; }

.border-t-white-bis-solid {
  border-top: 1px solid #fafafa; }

.border-t-white-bis-double {
  border-top: 1px double #fafafa; }

.border-t-white-bis-groove {
  border-top: 1px groove #fafafa; }

.border-t-white-bis-ridge {
  border-top: 1px ridge #fafafa; }

.border-t-white-bis-inset {
  border-top: 1px inset #fafafa; }

.border-t-white-bis-outset {
  border-top: 1px outset #fafafa; }

.border-t-white-bis-none {
  border-top: 1px none #fafafa; }

.border-t-white-bis-hidden {
  border-top: 1px hidden #fafafa; }

.border-r-white-bis {
  border-right: 1px solid #fafafa; }

.border-r-white-bis-dotted {
  border-right: 1px dotted #fafafa; }

.border-r-white-bis-dashed {
  border-right: 1px dashed #fafafa; }

.border-r-white-bis-solid {
  border-right: 1px solid #fafafa; }

.border-r-white-bis-double {
  border-right: 1px double #fafafa; }

.border-r-white-bis-groove {
  border-right: 1px groove #fafafa; }

.border-r-white-bis-ridge {
  border-right: 1px ridge #fafafa; }

.border-r-white-bis-inset {
  border-right: 1px inset #fafafa; }

.border-r-white-bis-outset {
  border-right: 1px outset #fafafa; }

.border-r-white-bis-none {
  border-right: 1px none #fafafa; }

.border-r-white-bis-hidden {
  border-right: 1px hidden #fafafa; }

.border-b-white-bis {
  border-bottom: 1px solid #fafafa; }

.border-b-white-bis-dotted {
  border-bottom: 1px dotted #fafafa; }

.border-b-white-bis-dashed {
  border-bottom: 1px dashed #fafafa; }

.border-b-white-bis-solid {
  border-bottom: 1px solid #fafafa; }

.border-b-white-bis-double {
  border-bottom: 1px double #fafafa; }

.border-b-white-bis-groove {
  border-bottom: 1px groove #fafafa; }

.border-b-white-bis-ridge {
  border-bottom: 1px ridge #fafafa; }

.border-b-white-bis-inset {
  border-bottom: 1px inset #fafafa; }

.border-b-white-bis-outset {
  border-bottom: 1px outset #fafafa; }

.border-b-white-bis-none {
  border-bottom: 1px none #fafafa; }

.border-b-white-bis-hidden {
  border-bottom: 1px hidden #fafafa; }

.border-l-white-bis {
  border-left: 1px solid #fafafa; }

.border-l-white-bis-dotted {
  border-left: 1px dotted #fafafa; }

.border-l-white-bis-dashed {
  border-left: 1px dashed #fafafa; }

.border-l-white-bis-solid {
  border-left: 1px solid #fafafa; }

.border-l-white-bis-double {
  border-left: 1px double #fafafa; }

.border-l-white-bis-groove {
  border-left: 1px groove #fafafa; }

.border-l-white-bis-ridge {
  border-left: 1px ridge #fafafa; }

.border-l-white-bis-inset {
  border-left: 1px inset #fafafa; }

.border-l-white-bis-outset {
  border-left: 1px outset #fafafa; }

.border-l-white-bis-none {
  border-left: 1px none #fafafa; }

.border-l-white-bis-hidden {
  border-left: 1px hidden #fafafa; }

.has-text-white {
  color: white; }

.has-background-white {
  background-color: white; }

.border-white {
  border: 1px solid white; }

.border-white-dotted {
  border: 1px dotted white; }

.border-white-dashed {
  border: 1px dashed white; }

.border-white-solid {
  border: 1px solid white; }

.border-white-double {
  border: 1px double white; }

.border-white-groove {
  border: 1px groove white; }

.border-white-ridge {
  border: 1px ridge white; }

.border-white-inset {
  border: 1px inset white; }

.border-white-outset {
  border: 1px outset white; }

.border-white-none {
  border: 1px none white; }

.border-white-hidden {
  border: 1px hidden white; }

.border-t-white {
  border-top: 1px solid white; }

.border-t-white-dotted {
  border-top: 1px dotted white; }

.border-t-white-dashed {
  border-top: 1px dashed white; }

.border-t-white-solid {
  border-top: 1px solid white; }

.border-t-white-double {
  border-top: 1px double white; }

.border-t-white-groove {
  border-top: 1px groove white; }

.border-t-white-ridge {
  border-top: 1px ridge white; }

.border-t-white-inset {
  border-top: 1px inset white; }

.border-t-white-outset {
  border-top: 1px outset white; }

.border-t-white-none {
  border-top: 1px none white; }

.border-t-white-hidden {
  border-top: 1px hidden white; }

.border-r-white {
  border-right: 1px solid white; }

.border-r-white-dotted {
  border-right: 1px dotted white; }

.border-r-white-dashed {
  border-right: 1px dashed white; }

.border-r-white-solid {
  border-right: 1px solid white; }

.border-r-white-double {
  border-right: 1px double white; }

.border-r-white-groove {
  border-right: 1px groove white; }

.border-r-white-ridge {
  border-right: 1px ridge white; }

.border-r-white-inset {
  border-right: 1px inset white; }

.border-r-white-outset {
  border-right: 1px outset white; }

.border-r-white-none {
  border-right: 1px none white; }

.border-r-white-hidden {
  border-right: 1px hidden white; }

.border-b-white {
  border-bottom: 1px solid white; }

.border-b-white-dotted {
  border-bottom: 1px dotted white; }

.border-b-white-dashed {
  border-bottom: 1px dashed white; }

.border-b-white-solid {
  border-bottom: 1px solid white; }

.border-b-white-double {
  border-bottom: 1px double white; }

.border-b-white-groove {
  border-bottom: 1px groove white; }

.border-b-white-ridge {
  border-bottom: 1px ridge white; }

.border-b-white-inset {
  border-bottom: 1px inset white; }

.border-b-white-outset {
  border-bottom: 1px outset white; }

.border-b-white-none {
  border-bottom: 1px none white; }

.border-b-white-hidden {
  border-bottom: 1px hidden white; }

.border-l-white {
  border-left: 1px solid white; }

.border-l-white-dotted {
  border-left: 1px dotted white; }

.border-l-white-dashed {
  border-left: 1px dashed white; }

.border-l-white-solid {
  border-left: 1px solid white; }

.border-l-white-double {
  border-left: 1px double white; }

.border-l-white-groove {
  border-left: 1px groove white; }

.border-l-white-ridge {
  border-left: 1px ridge white; }

.border-l-white-inset {
  border-left: 1px inset white; }

.border-l-white-outset {
  border-left: 1px outset white; }

.border-l-white-none {
  border-left: 1px none white; }

.border-l-white-hidden {
  border-left: 1px hidden white; }

._input_time {
  color: #7a7ab8;
  border: none;
  font-size: 1rem;
  border-bottom: 1px solid #dbdbf0;
  background: transparent;
  text-align: center;
  width: 60px;
  outline: none; }
  ._input_time:focus {
    border-bottom: 1px solid #7a7ab8; }

.m-none {
  margin: 0; }

.p-none {
  padding: 0; }

.m-t-none {
  margin-top: 0; }

.p-t-none {
  padding-top: 0; }

.m-r-none {
  margin-right: 0; }

.p-r-none {
  padding-right: 0; }

.m-b-none {
  margin-bottom: 0; }

.p-b-none {
  padding-bottom: 0; }

.m-l-none {
  margin-left: 0; }

.p-l-none {
  padding-left: 0; }

.m-xxs {
  margin: 0.125rem; }

.p-xxs {
  padding: 0.125rem; }

.m-t-xxs {
  margin-top: 0.125rem; }

.p-t-xxs {
  padding-top: 0.125rem; }

.m-r-xxs {
  margin-right: 0.125rem; }

.p-r-xxs {
  padding-right: 0.125rem; }

.m-b-xxs {
  margin-bottom: 0.125rem; }

.p-b-xxs {
  padding-bottom: 0.125rem; }

.m-l-xxs {
  margin-left: 0.125rem; }

.p-l-xxs {
  padding-left: 0.125rem; }

.m-xs {
  margin: 0.25rem; }

.p-xs {
  padding: 0.25rem; }

.m-t-xs {
  margin-top: 0.25rem; }

.p-t-xs {
  padding-top: 0.25rem; }

.m-r-xs {
  margin-right: 0.25rem; }

.p-r-xs {
  padding-right: 0.25rem; }

.m-b-xs {
  margin-bottom: 0.25rem; }

.p-b-xs {
  padding-bottom: 0.25rem; }

.m-l-xs {
  margin-left: 0.25rem; }

.p-l-xs {
  padding-left: 0.25rem; }

.m-sm {
  margin: 0.6rem; }

.p-sm {
  padding: 0.6rem; }

.m-t-sm {
  margin-top: 0.6rem; }

.p-t-sm {
  padding-top: 0.6rem; }

.m-r-sm {
  margin-right: 0.6rem; }

.p-r-sm {
  padding-right: 0.6rem; }

.m-b-sm {
  margin-bottom: 0.6rem; }

.p-b-sm {
  padding-bottom: 0.6rem; }

.m-l-sm {
  margin-left: 0.6rem; }

.p-l-sm {
  padding-left: 0.6rem; }

.m-md {
  margin: 1rem; }

.p-md {
  padding: 1rem; }

.m-t-md {
  margin-top: 1rem; }

.p-t-md {
  padding-top: 1rem; }

.m-r-md {
  margin-right: 1rem; }

.p-r-md {
  padding-right: 1rem; }

.m-b-md {
  margin-bottom: 1rem; }

.p-b-md {
  padding-bottom: 1rem; }

.m-l-md {
  margin-left: 1rem; }

.p-l-md {
  padding-left: 1rem; }

.m-lg {
  margin: 2rem; }

.p-lg {
  padding: 2rem; }

.m-t-lg {
  margin-top: 2rem; }

.p-t-lg {
  padding-top: 2rem; }

.m-r-lg {
  margin-right: 2rem; }

.p-r-lg {
  padding-right: 2rem; }

.m-b-lg {
  margin-bottom: 2rem; }

.p-b-lg {
  padding-bottom: 2rem; }

.m-l-lg {
  margin-left: 2rem; }

.p-l-lg {
  padding-left: 2rem; }

.m-xl {
  margin: 4rem; }

.p-xl {
  padding: 4rem; }

.m-t-xl {
  margin-top: 4rem; }

.p-t-xl {
  padding-top: 4rem; }

.m-r-xl {
  margin-right: 4rem; }

.p-r-xl {
  padding-right: 4rem; }

.m-b-xl {
  margin-bottom: 4rem; }

.p-b-xl {
  padding-bottom: 4rem; }

.m-l-xl {
  margin-left: 4rem; }

.p-l-xl {
  padding-left: 4rem; }

.m-xxl {
  margin: 8rem; }

.p-xxl {
  padding: 8rem; }

.m-t-xxl {
  margin-top: 8rem; }

.p-t-xxl {
  padding-top: 8rem; }

.m-r-xxl {
  margin-right: 8rem; }

.p-r-xxl {
  padding-right: 8rem; }

.m-b-xxl {
  margin-bottom: 8rem; }

.p-b-xxl {
  padding-bottom: 8rem; }

.m-l-xxl {
  margin-left: 8rem; }

.p-l-xxl {
  padding-left: 8rem; }

.has-text-primary {
  color: #2d2f7b; }

.has-background-primary {
  background-color: #2d2f7b; }

.border-primary {
  border: 1px solid #2d2f7b; }

.border-m-primary:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #2d2f7b;
  transform: translateY(-50%); }

.border-primary-dotted {
  border: 1px dotted #2d2f7b; }

.border-primary-dashed {
  border: 1px dashed #2d2f7b; }

.border-primary-solid {
  border: 1px solid #2d2f7b; }

.border-primary-double {
  border: 1px double #2d2f7b; }

.border-primary-groove {
  border: 1px groove #2d2f7b; }

.border-primary-ridge {
  border: 1px ridge #2d2f7b; }

.border-primary-inset {
  border: 1px inset #2d2f7b; }

.border-primary-outset {
  border: 1px outset #2d2f7b; }

.border-primary-none {
  border: 1px none #2d2f7b; }

.border-primary-hidden {
  border: 1px hidden #2d2f7b; }

.border-t-primary {
  border-top: 1px solid #2d2f7b; }

.border-t-primary-dotted {
  border-top: 1px dotted #2d2f7b; }

.border-t-primary-dashed {
  border-top: 1px dashed #2d2f7b; }

.border-t-primary-solid {
  border-top: 1px solid #2d2f7b; }

.border-t-primary-double {
  border-top: 1px double #2d2f7b; }

.border-t-primary-groove {
  border-top: 1px groove #2d2f7b; }

.border-t-primary-ridge {
  border-top: 1px ridge #2d2f7b; }

.border-t-primary-inset {
  border-top: 1px inset #2d2f7b; }

.border-t-primary-outset {
  border-top: 1px outset #2d2f7b; }

.border-t-primary-none {
  border-top: 1px none #2d2f7b; }

.border-t-primary-hidden {
  border-top: 1px hidden #2d2f7b; }

.border-r-primary {
  border-right: 1px solid #2d2f7b; }

.border-r-primary-dotted {
  border-right: 1px dotted #2d2f7b; }

.border-r-primary-dashed {
  border-right: 1px dashed #2d2f7b; }

.border-r-primary-solid {
  border-right: 1px solid #2d2f7b; }

.border-r-primary-double {
  border-right: 1px double #2d2f7b; }

.border-r-primary-groove {
  border-right: 1px groove #2d2f7b; }

.border-r-primary-ridge {
  border-right: 1px ridge #2d2f7b; }

.border-r-primary-inset {
  border-right: 1px inset #2d2f7b; }

.border-r-primary-outset {
  border-right: 1px outset #2d2f7b; }

.border-r-primary-none {
  border-right: 1px none #2d2f7b; }

.border-r-primary-hidden {
  border-right: 1px hidden #2d2f7b; }

.border-b-primary {
  border-bottom: 1px solid #2d2f7b; }

.border-b-primary-dotted {
  border-bottom: 1px dotted #2d2f7b; }

.border-b-primary-dashed {
  border-bottom: 1px dashed #2d2f7b; }

.border-b-primary-solid {
  border-bottom: 1px solid #2d2f7b; }

.border-b-primary-double {
  border-bottom: 1px double #2d2f7b; }

.border-b-primary-groove {
  border-bottom: 1px groove #2d2f7b; }

.border-b-primary-ridge {
  border-bottom: 1px ridge #2d2f7b; }

.border-b-primary-inset {
  border-bottom: 1px inset #2d2f7b; }

.border-b-primary-outset {
  border-bottom: 1px outset #2d2f7b; }

.border-b-primary-none {
  border-bottom: 1px none #2d2f7b; }

.border-b-primary-hidden {
  border-bottom: 1px hidden #2d2f7b; }

.border-l-primary {
  border-left: 1px solid #2d2f7b; }

.border-l-primary-dotted {
  border-left: 1px dotted #2d2f7b; }

.border-l-primary-dashed {
  border-left: 1px dashed #2d2f7b; }

.border-l-primary-solid {
  border-left: 1px solid #2d2f7b; }

.border-l-primary-double {
  border-left: 1px double #2d2f7b; }

.border-l-primary-groove {
  border-left: 1px groove #2d2f7b; }

.border-l-primary-ridge {
  border-left: 1px ridge #2d2f7b; }

.border-l-primary-inset {
  border-left: 1px inset #2d2f7b; }

.border-l-primary-outset {
  border-left: 1px outset #2d2f7b; }

.border-l-primary-none {
  border-left: 1px none #2d2f7b; }

.border-l-primary-hidden {
  border-left: 1px hidden #2d2f7b; }

.has-text-primary-bis {
  color: #4d4db3; }

.has-background-primary-bis {
  background-color: #4d4db3; }

.border-primary-bis {
  border: 1px solid #4d4db3; }

.border-m-primary-bis:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #4d4db3;
  transform: translateY(-50%); }

.border-primary-bis-dotted {
  border: 1px dotted #4d4db3; }

.border-primary-bis-dashed {
  border: 1px dashed #4d4db3; }

.border-primary-bis-solid {
  border: 1px solid #4d4db3; }

.border-primary-bis-double {
  border: 1px double #4d4db3; }

.border-primary-bis-groove {
  border: 1px groove #4d4db3; }

.border-primary-bis-ridge {
  border: 1px ridge #4d4db3; }

.border-primary-bis-inset {
  border: 1px inset #4d4db3; }

.border-primary-bis-outset {
  border: 1px outset #4d4db3; }

.border-primary-bis-none {
  border: 1px none #4d4db3; }

.border-primary-bis-hidden {
  border: 1px hidden #4d4db3; }

.border-t-primary-bis {
  border-top: 1px solid #4d4db3; }

.border-t-primary-bis-dotted {
  border-top: 1px dotted #4d4db3; }

.border-t-primary-bis-dashed {
  border-top: 1px dashed #4d4db3; }

.border-t-primary-bis-solid {
  border-top: 1px solid #4d4db3; }

.border-t-primary-bis-double {
  border-top: 1px double #4d4db3; }

.border-t-primary-bis-groove {
  border-top: 1px groove #4d4db3; }

.border-t-primary-bis-ridge {
  border-top: 1px ridge #4d4db3; }

.border-t-primary-bis-inset {
  border-top: 1px inset #4d4db3; }

.border-t-primary-bis-outset {
  border-top: 1px outset #4d4db3; }

.border-t-primary-bis-none {
  border-top: 1px none #4d4db3; }

.border-t-primary-bis-hidden {
  border-top: 1px hidden #4d4db3; }

.border-r-primary-bis {
  border-right: 1px solid #4d4db3; }

.border-r-primary-bis-dotted {
  border-right: 1px dotted #4d4db3; }

.border-r-primary-bis-dashed {
  border-right: 1px dashed #4d4db3; }

.border-r-primary-bis-solid {
  border-right: 1px solid #4d4db3; }

.border-r-primary-bis-double {
  border-right: 1px double #4d4db3; }

.border-r-primary-bis-groove {
  border-right: 1px groove #4d4db3; }

.border-r-primary-bis-ridge {
  border-right: 1px ridge #4d4db3; }

.border-r-primary-bis-inset {
  border-right: 1px inset #4d4db3; }

.border-r-primary-bis-outset {
  border-right: 1px outset #4d4db3; }

.border-r-primary-bis-none {
  border-right: 1px none #4d4db3; }

.border-r-primary-bis-hidden {
  border-right: 1px hidden #4d4db3; }

.border-b-primary-bis {
  border-bottom: 1px solid #4d4db3; }

.border-b-primary-bis-dotted {
  border-bottom: 1px dotted #4d4db3; }

.border-b-primary-bis-dashed {
  border-bottom: 1px dashed #4d4db3; }

.border-b-primary-bis-solid {
  border-bottom: 1px solid #4d4db3; }

.border-b-primary-bis-double {
  border-bottom: 1px double #4d4db3; }

.border-b-primary-bis-groove {
  border-bottom: 1px groove #4d4db3; }

.border-b-primary-bis-ridge {
  border-bottom: 1px ridge #4d4db3; }

.border-b-primary-bis-inset {
  border-bottom: 1px inset #4d4db3; }

.border-b-primary-bis-outset {
  border-bottom: 1px outset #4d4db3; }

.border-b-primary-bis-none {
  border-bottom: 1px none #4d4db3; }

.border-b-primary-bis-hidden {
  border-bottom: 1px hidden #4d4db3; }

.border-l-primary-bis {
  border-left: 1px solid #4d4db3; }

.border-l-primary-bis-dotted {
  border-left: 1px dotted #4d4db3; }

.border-l-primary-bis-dashed {
  border-left: 1px dashed #4d4db3; }

.border-l-primary-bis-solid {
  border-left: 1px solid #4d4db3; }

.border-l-primary-bis-double {
  border-left: 1px double #4d4db3; }

.border-l-primary-bis-groove {
  border-left: 1px groove #4d4db3; }

.border-l-primary-bis-ridge {
  border-left: 1px ridge #4d4db3; }

.border-l-primary-bis-inset {
  border-left: 1px inset #4d4db3; }

.border-l-primary-bis-outset {
  border-left: 1px outset #4d4db3; }

.border-l-primary-bis-none {
  border-left: 1px none #4d4db3; }

.border-l-primary-bis-hidden {
  border-left: 1px hidden #4d4db3; }

.has-text-primary-ter {
  color: #7a7ab8; }

.has-background-primary-ter {
  background-color: #7a7ab8; }

.border-primary-ter {
  border: 1px solid #7a7ab8; }

.border-m-primary-ter:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #7a7ab8;
  transform: translateY(-50%); }

.border-primary-ter-dotted {
  border: 1px dotted #7a7ab8; }

.border-primary-ter-dashed {
  border: 1px dashed #7a7ab8; }

.border-primary-ter-solid {
  border: 1px solid #7a7ab8; }

.border-primary-ter-double {
  border: 1px double #7a7ab8; }

.border-primary-ter-groove {
  border: 1px groove #7a7ab8; }

.border-primary-ter-ridge {
  border: 1px ridge #7a7ab8; }

.border-primary-ter-inset {
  border: 1px inset #7a7ab8; }

.border-primary-ter-outset {
  border: 1px outset #7a7ab8; }

.border-primary-ter-none {
  border: 1px none #7a7ab8; }

.border-primary-ter-hidden {
  border: 1px hidden #7a7ab8; }

.border-t-primary-ter {
  border-top: 1px solid #7a7ab8; }

.border-t-primary-ter-dotted {
  border-top: 1px dotted #7a7ab8; }

.border-t-primary-ter-dashed {
  border-top: 1px dashed #7a7ab8; }

.border-t-primary-ter-solid {
  border-top: 1px solid #7a7ab8; }

.border-t-primary-ter-double {
  border-top: 1px double #7a7ab8; }

.border-t-primary-ter-groove {
  border-top: 1px groove #7a7ab8; }

.border-t-primary-ter-ridge {
  border-top: 1px ridge #7a7ab8; }

.border-t-primary-ter-inset {
  border-top: 1px inset #7a7ab8; }

.border-t-primary-ter-outset {
  border-top: 1px outset #7a7ab8; }

.border-t-primary-ter-none {
  border-top: 1px none #7a7ab8; }

.border-t-primary-ter-hidden {
  border-top: 1px hidden #7a7ab8; }

.border-r-primary-ter {
  border-right: 1px solid #7a7ab8; }

.border-r-primary-ter-dotted {
  border-right: 1px dotted #7a7ab8; }

.border-r-primary-ter-dashed {
  border-right: 1px dashed #7a7ab8; }

.border-r-primary-ter-solid {
  border-right: 1px solid #7a7ab8; }

.border-r-primary-ter-double {
  border-right: 1px double #7a7ab8; }

.border-r-primary-ter-groove {
  border-right: 1px groove #7a7ab8; }

.border-r-primary-ter-ridge {
  border-right: 1px ridge #7a7ab8; }

.border-r-primary-ter-inset {
  border-right: 1px inset #7a7ab8; }

.border-r-primary-ter-outset {
  border-right: 1px outset #7a7ab8; }

.border-r-primary-ter-none {
  border-right: 1px none #7a7ab8; }

.border-r-primary-ter-hidden {
  border-right: 1px hidden #7a7ab8; }

.border-b-primary-ter {
  border-bottom: 1px solid #7a7ab8; }

.border-b-primary-ter-dotted {
  border-bottom: 1px dotted #7a7ab8; }

.border-b-primary-ter-dashed {
  border-bottom: 1px dashed #7a7ab8; }

.border-b-primary-ter-solid {
  border-bottom: 1px solid #7a7ab8; }

.border-b-primary-ter-double {
  border-bottom: 1px double #7a7ab8; }

.border-b-primary-ter-groove {
  border-bottom: 1px groove #7a7ab8; }

.border-b-primary-ter-ridge {
  border-bottom: 1px ridge #7a7ab8; }

.border-b-primary-ter-inset {
  border-bottom: 1px inset #7a7ab8; }

.border-b-primary-ter-outset {
  border-bottom: 1px outset #7a7ab8; }

.border-b-primary-ter-none {
  border-bottom: 1px none #7a7ab8; }

.border-b-primary-ter-hidden {
  border-bottom: 1px hidden #7a7ab8; }

.border-l-primary-ter {
  border-left: 1px solid #7a7ab8; }

.border-l-primary-ter-dotted {
  border-left: 1px dotted #7a7ab8; }

.border-l-primary-ter-dashed {
  border-left: 1px dashed #7a7ab8; }

.border-l-primary-ter-solid {
  border-left: 1px solid #7a7ab8; }

.border-l-primary-ter-double {
  border-left: 1px double #7a7ab8; }

.border-l-primary-ter-groove {
  border-left: 1px groove #7a7ab8; }

.border-l-primary-ter-ridge {
  border-left: 1px ridge #7a7ab8; }

.border-l-primary-ter-inset {
  border-left: 1px inset #7a7ab8; }

.border-l-primary-ter-outset {
  border-left: 1px outset #7a7ab8; }

.border-l-primary-ter-none {
  border-left: 1px none #7a7ab8; }

.border-l-primary-ter-hidden {
  border-left: 1px hidden #7a7ab8; }

.has-text-primary-qua {
  color: #b9b9e9; }

.has-background-primary-qua {
  background-color: #b9b9e9; }

.border-primary-qua {
  border: 1px solid #b9b9e9; }

.border-m-primary-qua:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b9b9e9;
  transform: translateY(-50%); }

.border-primary-qua-dotted {
  border: 1px dotted #b9b9e9; }

.border-primary-qua-dashed {
  border: 1px dashed #b9b9e9; }

.border-primary-qua-solid {
  border: 1px solid #b9b9e9; }

.border-primary-qua-double {
  border: 1px double #b9b9e9; }

.border-primary-qua-groove {
  border: 1px groove #b9b9e9; }

.border-primary-qua-ridge {
  border: 1px ridge #b9b9e9; }

.border-primary-qua-inset {
  border: 1px inset #b9b9e9; }

.border-primary-qua-outset {
  border: 1px outset #b9b9e9; }

.border-primary-qua-none {
  border: 1px none #b9b9e9; }

.border-primary-qua-hidden {
  border: 1px hidden #b9b9e9; }

.border-t-primary-qua {
  border-top: 1px solid #b9b9e9; }

.border-t-primary-qua-dotted {
  border-top: 1px dotted #b9b9e9; }

.border-t-primary-qua-dashed {
  border-top: 1px dashed #b9b9e9; }

.border-t-primary-qua-solid {
  border-top: 1px solid #b9b9e9; }

.border-t-primary-qua-double {
  border-top: 1px double #b9b9e9; }

.border-t-primary-qua-groove {
  border-top: 1px groove #b9b9e9; }

.border-t-primary-qua-ridge {
  border-top: 1px ridge #b9b9e9; }

.border-t-primary-qua-inset {
  border-top: 1px inset #b9b9e9; }

.border-t-primary-qua-outset {
  border-top: 1px outset #b9b9e9; }

.border-t-primary-qua-none {
  border-top: 1px none #b9b9e9; }

.border-t-primary-qua-hidden {
  border-top: 1px hidden #b9b9e9; }

.border-r-primary-qua {
  border-right: 1px solid #b9b9e9; }

.border-r-primary-qua-dotted {
  border-right: 1px dotted #b9b9e9; }

.border-r-primary-qua-dashed {
  border-right: 1px dashed #b9b9e9; }

.border-r-primary-qua-solid {
  border-right: 1px solid #b9b9e9; }

.border-r-primary-qua-double {
  border-right: 1px double #b9b9e9; }

.border-r-primary-qua-groove {
  border-right: 1px groove #b9b9e9; }

.border-r-primary-qua-ridge {
  border-right: 1px ridge #b9b9e9; }

.border-r-primary-qua-inset {
  border-right: 1px inset #b9b9e9; }

.border-r-primary-qua-outset {
  border-right: 1px outset #b9b9e9; }

.border-r-primary-qua-none {
  border-right: 1px none #b9b9e9; }

.border-r-primary-qua-hidden {
  border-right: 1px hidden #b9b9e9; }

.border-b-primary-qua {
  border-bottom: 1px solid #b9b9e9; }

.border-b-primary-qua-dotted {
  border-bottom: 1px dotted #b9b9e9; }

.border-b-primary-qua-dashed {
  border-bottom: 1px dashed #b9b9e9; }

.border-b-primary-qua-solid {
  border-bottom: 1px solid #b9b9e9; }

.border-b-primary-qua-double {
  border-bottom: 1px double #b9b9e9; }

.border-b-primary-qua-groove {
  border-bottom: 1px groove #b9b9e9; }

.border-b-primary-qua-ridge {
  border-bottom: 1px ridge #b9b9e9; }

.border-b-primary-qua-inset {
  border-bottom: 1px inset #b9b9e9; }

.border-b-primary-qua-outset {
  border-bottom: 1px outset #b9b9e9; }

.border-b-primary-qua-none {
  border-bottom: 1px none #b9b9e9; }

.border-b-primary-qua-hidden {
  border-bottom: 1px hidden #b9b9e9; }

.border-l-primary-qua {
  border-left: 1px solid #b9b9e9; }

.border-l-primary-qua-dotted {
  border-left: 1px dotted #b9b9e9; }

.border-l-primary-qua-dashed {
  border-left: 1px dashed #b9b9e9; }

.border-l-primary-qua-solid {
  border-left: 1px solid #b9b9e9; }

.border-l-primary-qua-double {
  border-left: 1px double #b9b9e9; }

.border-l-primary-qua-groove {
  border-left: 1px groove #b9b9e9; }

.border-l-primary-qua-ridge {
  border-left: 1px ridge #b9b9e9; }

.border-l-primary-qua-inset {
  border-left: 1px inset #b9b9e9; }

.border-l-primary-qua-outset {
  border-left: 1px outset #b9b9e9; }

.border-l-primary-qua-none {
  border-left: 1px none #b9b9e9; }

.border-l-primary-qua-hidden {
  border-left: 1px hidden #b9b9e9; }

.has-text-primary-qui {
  color: #b8b8d6; }

.has-background-primary-qui {
  background-color: #b8b8d6; }

.border-primary-qui {
  border: 1px solid #b8b8d6; }

.border-m-primary-qui:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b8b8d6;
  transform: translateY(-50%); }

.border-primary-qui-dotted {
  border: 1px dotted #b8b8d6; }

.border-primary-qui-dashed {
  border: 1px dashed #b8b8d6; }

.border-primary-qui-solid {
  border: 1px solid #b8b8d6; }

.border-primary-qui-double {
  border: 1px double #b8b8d6; }

.border-primary-qui-groove {
  border: 1px groove #b8b8d6; }

.border-primary-qui-ridge {
  border: 1px ridge #b8b8d6; }

.border-primary-qui-inset {
  border: 1px inset #b8b8d6; }

.border-primary-qui-outset {
  border: 1px outset #b8b8d6; }

.border-primary-qui-none {
  border: 1px none #b8b8d6; }

.border-primary-qui-hidden {
  border: 1px hidden #b8b8d6; }

.border-t-primary-qui {
  border-top: 1px solid #b8b8d6; }

.border-t-primary-qui-dotted {
  border-top: 1px dotted #b8b8d6; }

.border-t-primary-qui-dashed {
  border-top: 1px dashed #b8b8d6; }

.border-t-primary-qui-solid {
  border-top: 1px solid #b8b8d6; }

.border-t-primary-qui-double {
  border-top: 1px double #b8b8d6; }

.border-t-primary-qui-groove {
  border-top: 1px groove #b8b8d6; }

.border-t-primary-qui-ridge {
  border-top: 1px ridge #b8b8d6; }

.border-t-primary-qui-inset {
  border-top: 1px inset #b8b8d6; }

.border-t-primary-qui-outset {
  border-top: 1px outset #b8b8d6; }

.border-t-primary-qui-none {
  border-top: 1px none #b8b8d6; }

.border-t-primary-qui-hidden {
  border-top: 1px hidden #b8b8d6; }

.border-r-primary-qui {
  border-right: 1px solid #b8b8d6; }

.border-r-primary-qui-dotted {
  border-right: 1px dotted #b8b8d6; }

.border-r-primary-qui-dashed {
  border-right: 1px dashed #b8b8d6; }

.border-r-primary-qui-solid {
  border-right: 1px solid #b8b8d6; }

.border-r-primary-qui-double {
  border-right: 1px double #b8b8d6; }

.border-r-primary-qui-groove {
  border-right: 1px groove #b8b8d6; }

.border-r-primary-qui-ridge {
  border-right: 1px ridge #b8b8d6; }

.border-r-primary-qui-inset {
  border-right: 1px inset #b8b8d6; }

.border-r-primary-qui-outset {
  border-right: 1px outset #b8b8d6; }

.border-r-primary-qui-none {
  border-right: 1px none #b8b8d6; }

.border-r-primary-qui-hidden {
  border-right: 1px hidden #b8b8d6; }

.border-b-primary-qui {
  border-bottom: 1px solid #b8b8d6; }

.border-b-primary-qui-dotted {
  border-bottom: 1px dotted #b8b8d6; }

.border-b-primary-qui-dashed {
  border-bottom: 1px dashed #b8b8d6; }

.border-b-primary-qui-solid {
  border-bottom: 1px solid #b8b8d6; }

.border-b-primary-qui-double {
  border-bottom: 1px double #b8b8d6; }

.border-b-primary-qui-groove {
  border-bottom: 1px groove #b8b8d6; }

.border-b-primary-qui-ridge {
  border-bottom: 1px ridge #b8b8d6; }

.border-b-primary-qui-inset {
  border-bottom: 1px inset #b8b8d6; }

.border-b-primary-qui-outset {
  border-bottom: 1px outset #b8b8d6; }

.border-b-primary-qui-none {
  border-bottom: 1px none #b8b8d6; }

.border-b-primary-qui-hidden {
  border-bottom: 1px hidden #b8b8d6; }

.border-l-primary-qui {
  border-left: 1px solid #b8b8d6; }

.border-l-primary-qui-dotted {
  border-left: 1px dotted #b8b8d6; }

.border-l-primary-qui-dashed {
  border-left: 1px dashed #b8b8d6; }

.border-l-primary-qui-solid {
  border-left: 1px solid #b8b8d6; }

.border-l-primary-qui-double {
  border-left: 1px double #b8b8d6; }

.border-l-primary-qui-groove {
  border-left: 1px groove #b8b8d6; }

.border-l-primary-qui-ridge {
  border-left: 1px ridge #b8b8d6; }

.border-l-primary-qui-inset {
  border-left: 1px inset #b8b8d6; }

.border-l-primary-qui-outset {
  border-left: 1px outset #b8b8d6; }

.border-l-primary-qui-none {
  border-left: 1px none #b8b8d6; }

.border-l-primary-qui-hidden {
  border-left: 1px hidden #b8b8d6; }

.has-text-primary-light {
  color: #dbdbf0; }

.has-background-primary-light {
  background-color: #dbdbf0; }

.border-primary-light {
  border: 1px solid #dbdbf0; }

.border-m-primary-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dbdbf0;
  transform: translateY(-50%); }

.border-primary-light-dotted {
  border: 1px dotted #dbdbf0; }

.border-primary-light-dashed {
  border: 1px dashed #dbdbf0; }

.border-primary-light-solid {
  border: 1px solid #dbdbf0; }

.border-primary-light-double {
  border: 1px double #dbdbf0; }

.border-primary-light-groove {
  border: 1px groove #dbdbf0; }

.border-primary-light-ridge {
  border: 1px ridge #dbdbf0; }

.border-primary-light-inset {
  border: 1px inset #dbdbf0; }

.border-primary-light-outset {
  border: 1px outset #dbdbf0; }

.border-primary-light-none {
  border: 1px none #dbdbf0; }

.border-primary-light-hidden {
  border: 1px hidden #dbdbf0; }

.border-t-primary-light {
  border-top: 1px solid #dbdbf0; }

.border-t-primary-light-dotted {
  border-top: 1px dotted #dbdbf0; }

.border-t-primary-light-dashed {
  border-top: 1px dashed #dbdbf0; }

.border-t-primary-light-solid {
  border-top: 1px solid #dbdbf0; }

.border-t-primary-light-double {
  border-top: 1px double #dbdbf0; }

.border-t-primary-light-groove {
  border-top: 1px groove #dbdbf0; }

.border-t-primary-light-ridge {
  border-top: 1px ridge #dbdbf0; }

.border-t-primary-light-inset {
  border-top: 1px inset #dbdbf0; }

.border-t-primary-light-outset {
  border-top: 1px outset #dbdbf0; }

.border-t-primary-light-none {
  border-top: 1px none #dbdbf0; }

.border-t-primary-light-hidden {
  border-top: 1px hidden #dbdbf0; }

.border-r-primary-light {
  border-right: 1px solid #dbdbf0; }

.border-r-primary-light-dotted {
  border-right: 1px dotted #dbdbf0; }

.border-r-primary-light-dashed {
  border-right: 1px dashed #dbdbf0; }

.border-r-primary-light-solid {
  border-right: 1px solid #dbdbf0; }

.border-r-primary-light-double {
  border-right: 1px double #dbdbf0; }

.border-r-primary-light-groove {
  border-right: 1px groove #dbdbf0; }

.border-r-primary-light-ridge {
  border-right: 1px ridge #dbdbf0; }

.border-r-primary-light-inset {
  border-right: 1px inset #dbdbf0; }

.border-r-primary-light-outset {
  border-right: 1px outset #dbdbf0; }

.border-r-primary-light-none {
  border-right: 1px none #dbdbf0; }

.border-r-primary-light-hidden {
  border-right: 1px hidden #dbdbf0; }

.border-b-primary-light {
  border-bottom: 1px solid #dbdbf0; }

.border-b-primary-light-dotted {
  border-bottom: 1px dotted #dbdbf0; }

.border-b-primary-light-dashed {
  border-bottom: 1px dashed #dbdbf0; }

.border-b-primary-light-solid {
  border-bottom: 1px solid #dbdbf0; }

.border-b-primary-light-double {
  border-bottom: 1px double #dbdbf0; }

.border-b-primary-light-groove {
  border-bottom: 1px groove #dbdbf0; }

.border-b-primary-light-ridge {
  border-bottom: 1px ridge #dbdbf0; }

.border-b-primary-light-inset {
  border-bottom: 1px inset #dbdbf0; }

.border-b-primary-light-outset {
  border-bottom: 1px outset #dbdbf0; }

.border-b-primary-light-none {
  border-bottom: 1px none #dbdbf0; }

.border-b-primary-light-hidden {
  border-bottom: 1px hidden #dbdbf0; }

.border-l-primary-light {
  border-left: 1px solid #dbdbf0; }

.border-l-primary-light-dotted {
  border-left: 1px dotted #dbdbf0; }

.border-l-primary-light-dashed {
  border-left: 1px dashed #dbdbf0; }

.border-l-primary-light-solid {
  border-left: 1px solid #dbdbf0; }

.border-l-primary-light-double {
  border-left: 1px double #dbdbf0; }

.border-l-primary-light-groove {
  border-left: 1px groove #dbdbf0; }

.border-l-primary-light-ridge {
  border-left: 1px ridge #dbdbf0; }

.border-l-primary-light-inset {
  border-left: 1px inset #dbdbf0; }

.border-l-primary-light-outset {
  border-left: 1px outset #dbdbf0; }

.border-l-primary-light-none {
  border-left: 1px none #dbdbf0; }

.border-l-primary-light-hidden {
  border-left: 1px hidden #dbdbf0; }

.has-text-primary-lighter {
  color: #ededf7; }

.has-background-primary-lighter {
  background-color: #ededf7; }

.border-primary-lighter {
  border: 1px solid #ededf7; }

.border-m-primary-lighter:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ededf7;
  transform: translateY(-50%); }

.border-primary-lighter-dotted {
  border: 1px dotted #ededf7; }

.border-primary-lighter-dashed {
  border: 1px dashed #ededf7; }

.border-primary-lighter-solid {
  border: 1px solid #ededf7; }

.border-primary-lighter-double {
  border: 1px double #ededf7; }

.border-primary-lighter-groove {
  border: 1px groove #ededf7; }

.border-primary-lighter-ridge {
  border: 1px ridge #ededf7; }

.border-primary-lighter-inset {
  border: 1px inset #ededf7; }

.border-primary-lighter-outset {
  border: 1px outset #ededf7; }

.border-primary-lighter-none {
  border: 1px none #ededf7; }

.border-primary-lighter-hidden {
  border: 1px hidden #ededf7; }

.border-t-primary-lighter {
  border-top: 1px solid #ededf7; }

.border-t-primary-lighter-dotted {
  border-top: 1px dotted #ededf7; }

.border-t-primary-lighter-dashed {
  border-top: 1px dashed #ededf7; }

.border-t-primary-lighter-solid {
  border-top: 1px solid #ededf7; }

.border-t-primary-lighter-double {
  border-top: 1px double #ededf7; }

.border-t-primary-lighter-groove {
  border-top: 1px groove #ededf7; }

.border-t-primary-lighter-ridge {
  border-top: 1px ridge #ededf7; }

.border-t-primary-lighter-inset {
  border-top: 1px inset #ededf7; }

.border-t-primary-lighter-outset {
  border-top: 1px outset #ededf7; }

.border-t-primary-lighter-none {
  border-top: 1px none #ededf7; }

.border-t-primary-lighter-hidden {
  border-top: 1px hidden #ededf7; }

.border-r-primary-lighter {
  border-right: 1px solid #ededf7; }

.border-r-primary-lighter-dotted {
  border-right: 1px dotted #ededf7; }

.border-r-primary-lighter-dashed {
  border-right: 1px dashed #ededf7; }

.border-r-primary-lighter-solid {
  border-right: 1px solid #ededf7; }

.border-r-primary-lighter-double {
  border-right: 1px double #ededf7; }

.border-r-primary-lighter-groove {
  border-right: 1px groove #ededf7; }

.border-r-primary-lighter-ridge {
  border-right: 1px ridge #ededf7; }

.border-r-primary-lighter-inset {
  border-right: 1px inset #ededf7; }

.border-r-primary-lighter-outset {
  border-right: 1px outset #ededf7; }

.border-r-primary-lighter-none {
  border-right: 1px none #ededf7; }

.border-r-primary-lighter-hidden {
  border-right: 1px hidden #ededf7; }

.border-b-primary-lighter {
  border-bottom: 1px solid #ededf7; }

.border-b-primary-lighter-dotted {
  border-bottom: 1px dotted #ededf7; }

.border-b-primary-lighter-dashed {
  border-bottom: 1px dashed #ededf7; }

.border-b-primary-lighter-solid {
  border-bottom: 1px solid #ededf7; }

.border-b-primary-lighter-double {
  border-bottom: 1px double #ededf7; }

.border-b-primary-lighter-groove {
  border-bottom: 1px groove #ededf7; }

.border-b-primary-lighter-ridge {
  border-bottom: 1px ridge #ededf7; }

.border-b-primary-lighter-inset {
  border-bottom: 1px inset #ededf7; }

.border-b-primary-lighter-outset {
  border-bottom: 1px outset #ededf7; }

.border-b-primary-lighter-none {
  border-bottom: 1px none #ededf7; }

.border-b-primary-lighter-hidden {
  border-bottom: 1px hidden #ededf7; }

.border-l-primary-lighter {
  border-left: 1px solid #ededf7; }

.border-l-primary-lighter-dotted {
  border-left: 1px dotted #ededf7; }

.border-l-primary-lighter-dashed {
  border-left: 1px dashed #ededf7; }

.border-l-primary-lighter-solid {
  border-left: 1px solid #ededf7; }

.border-l-primary-lighter-double {
  border-left: 1px double #ededf7; }

.border-l-primary-lighter-groove {
  border-left: 1px groove #ededf7; }

.border-l-primary-lighter-ridge {
  border-left: 1px ridge #ededf7; }

.border-l-primary-lighter-inset {
  border-left: 1px inset #ededf7; }

.border-l-primary-lighter-outset {
  border-left: 1px outset #ededf7; }

.border-l-primary-lighter-none {
  border-left: 1px none #ededf7; }

.border-l-primary-lighter-hidden {
  border-left: 1px hidden #ededf7; }

.has-text-primary-lighbis {
  color: #f4f4fa; }

.has-background-primary-lighbis {
  background-color: #f4f4fa; }

.border-primary-lighbis {
  border: 1px solid #f4f4fa; }

.border-m-primary-lighbis:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f4f4fa;
  transform: translateY(-50%); }

.border-primary-lighbis-dotted {
  border: 1px dotted #f4f4fa; }

.border-primary-lighbis-dashed {
  border: 1px dashed #f4f4fa; }

.border-primary-lighbis-solid {
  border: 1px solid #f4f4fa; }

.border-primary-lighbis-double {
  border: 1px double #f4f4fa; }

.border-primary-lighbis-groove {
  border: 1px groove #f4f4fa; }

.border-primary-lighbis-ridge {
  border: 1px ridge #f4f4fa; }

.border-primary-lighbis-inset {
  border: 1px inset #f4f4fa; }

.border-primary-lighbis-outset {
  border: 1px outset #f4f4fa; }

.border-primary-lighbis-none {
  border: 1px none #f4f4fa; }

.border-primary-lighbis-hidden {
  border: 1px hidden #f4f4fa; }

.border-t-primary-lighbis {
  border-top: 1px solid #f4f4fa; }

.border-t-primary-lighbis-dotted {
  border-top: 1px dotted #f4f4fa; }

.border-t-primary-lighbis-dashed {
  border-top: 1px dashed #f4f4fa; }

.border-t-primary-lighbis-solid {
  border-top: 1px solid #f4f4fa; }

.border-t-primary-lighbis-double {
  border-top: 1px double #f4f4fa; }

.border-t-primary-lighbis-groove {
  border-top: 1px groove #f4f4fa; }

.border-t-primary-lighbis-ridge {
  border-top: 1px ridge #f4f4fa; }

.border-t-primary-lighbis-inset {
  border-top: 1px inset #f4f4fa; }

.border-t-primary-lighbis-outset {
  border-top: 1px outset #f4f4fa; }

.border-t-primary-lighbis-none {
  border-top: 1px none #f4f4fa; }

.border-t-primary-lighbis-hidden {
  border-top: 1px hidden #f4f4fa; }

.border-r-primary-lighbis {
  border-right: 1px solid #f4f4fa; }

.border-r-primary-lighbis-dotted {
  border-right: 1px dotted #f4f4fa; }

.border-r-primary-lighbis-dashed {
  border-right: 1px dashed #f4f4fa; }

.border-r-primary-lighbis-solid {
  border-right: 1px solid #f4f4fa; }

.border-r-primary-lighbis-double {
  border-right: 1px double #f4f4fa; }

.border-r-primary-lighbis-groove {
  border-right: 1px groove #f4f4fa; }

.border-r-primary-lighbis-ridge {
  border-right: 1px ridge #f4f4fa; }

.border-r-primary-lighbis-inset {
  border-right: 1px inset #f4f4fa; }

.border-r-primary-lighbis-outset {
  border-right: 1px outset #f4f4fa; }

.border-r-primary-lighbis-none {
  border-right: 1px none #f4f4fa; }

.border-r-primary-lighbis-hidden {
  border-right: 1px hidden #f4f4fa; }

.border-b-primary-lighbis {
  border-bottom: 1px solid #f4f4fa; }

.border-b-primary-lighbis-dotted {
  border-bottom: 1px dotted #f4f4fa; }

.border-b-primary-lighbis-dashed {
  border-bottom: 1px dashed #f4f4fa; }

.border-b-primary-lighbis-solid {
  border-bottom: 1px solid #f4f4fa; }

.border-b-primary-lighbis-double {
  border-bottom: 1px double #f4f4fa; }

.border-b-primary-lighbis-groove {
  border-bottom: 1px groove #f4f4fa; }

.border-b-primary-lighbis-ridge {
  border-bottom: 1px ridge #f4f4fa; }

.border-b-primary-lighbis-inset {
  border-bottom: 1px inset #f4f4fa; }

.border-b-primary-lighbis-outset {
  border-bottom: 1px outset #f4f4fa; }

.border-b-primary-lighbis-none {
  border-bottom: 1px none #f4f4fa; }

.border-b-primary-lighbis-hidden {
  border-bottom: 1px hidden #f4f4fa; }

.border-l-primary-lighbis {
  border-left: 1px solid #f4f4fa; }

.border-l-primary-lighbis-dotted {
  border-left: 1px dotted #f4f4fa; }

.border-l-primary-lighbis-dashed {
  border-left: 1px dashed #f4f4fa; }

.border-l-primary-lighbis-solid {
  border-left: 1px solid #f4f4fa; }

.border-l-primary-lighbis-double {
  border-left: 1px double #f4f4fa; }

.border-l-primary-lighbis-groove {
  border-left: 1px groove #f4f4fa; }

.border-l-primary-lighbis-ridge {
  border-left: 1px ridge #f4f4fa; }

.border-l-primary-lighbis-inset {
  border-left: 1px inset #f4f4fa; }

.border-l-primary-lighbis-outset {
  border-left: 1px outset #f4f4fa; }

.border-l-primary-lighbis-none {
  border-left: 1px none #f4f4fa; }

.border-l-primary-lighbis-hidden {
  border-left: 1px hidden #f4f4fa; }

.has-text-secondary {
  color: #eb6666; }

.has-background-secondary {
  background-color: #eb6666; }

.border-secondary {
  border: 1px solid #eb6666; }

.border-m-secondary:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #eb6666;
  transform: translateY(-50%); }

.border-secondary-dotted {
  border: 1px dotted #eb6666; }

.border-secondary-dashed {
  border: 1px dashed #eb6666; }

.border-secondary-solid {
  border: 1px solid #eb6666; }

.border-secondary-double {
  border: 1px double #eb6666; }

.border-secondary-groove {
  border: 1px groove #eb6666; }

.border-secondary-ridge {
  border: 1px ridge #eb6666; }

.border-secondary-inset {
  border: 1px inset #eb6666; }

.border-secondary-outset {
  border: 1px outset #eb6666; }

.border-secondary-none {
  border: 1px none #eb6666; }

.border-secondary-hidden {
  border: 1px hidden #eb6666; }

.border-t-secondary {
  border-top: 1px solid #eb6666; }

.border-t-secondary-dotted {
  border-top: 1px dotted #eb6666; }

.border-t-secondary-dashed {
  border-top: 1px dashed #eb6666; }

.border-t-secondary-solid {
  border-top: 1px solid #eb6666; }

.border-t-secondary-double {
  border-top: 1px double #eb6666; }

.border-t-secondary-groove {
  border-top: 1px groove #eb6666; }

.border-t-secondary-ridge {
  border-top: 1px ridge #eb6666; }

.border-t-secondary-inset {
  border-top: 1px inset #eb6666; }

.border-t-secondary-outset {
  border-top: 1px outset #eb6666; }

.border-t-secondary-none {
  border-top: 1px none #eb6666; }

.border-t-secondary-hidden {
  border-top: 1px hidden #eb6666; }

.border-r-secondary {
  border-right: 1px solid #eb6666; }

.border-r-secondary-dotted {
  border-right: 1px dotted #eb6666; }

.border-r-secondary-dashed {
  border-right: 1px dashed #eb6666; }

.border-r-secondary-solid {
  border-right: 1px solid #eb6666; }

.border-r-secondary-double {
  border-right: 1px double #eb6666; }

.border-r-secondary-groove {
  border-right: 1px groove #eb6666; }

.border-r-secondary-ridge {
  border-right: 1px ridge #eb6666; }

.border-r-secondary-inset {
  border-right: 1px inset #eb6666; }

.border-r-secondary-outset {
  border-right: 1px outset #eb6666; }

.border-r-secondary-none {
  border-right: 1px none #eb6666; }

.border-r-secondary-hidden {
  border-right: 1px hidden #eb6666; }

.border-b-secondary {
  border-bottom: 1px solid #eb6666; }

.border-b-secondary-dotted {
  border-bottom: 1px dotted #eb6666; }

.border-b-secondary-dashed {
  border-bottom: 1px dashed #eb6666; }

.border-b-secondary-solid {
  border-bottom: 1px solid #eb6666; }

.border-b-secondary-double {
  border-bottom: 1px double #eb6666; }

.border-b-secondary-groove {
  border-bottom: 1px groove #eb6666; }

.border-b-secondary-ridge {
  border-bottom: 1px ridge #eb6666; }

.border-b-secondary-inset {
  border-bottom: 1px inset #eb6666; }

.border-b-secondary-outset {
  border-bottom: 1px outset #eb6666; }

.border-b-secondary-none {
  border-bottom: 1px none #eb6666; }

.border-b-secondary-hidden {
  border-bottom: 1px hidden #eb6666; }

.border-l-secondary {
  border-left: 1px solid #eb6666; }

.border-l-secondary-dotted {
  border-left: 1px dotted #eb6666; }

.border-l-secondary-dashed {
  border-left: 1px dashed #eb6666; }

.border-l-secondary-solid {
  border-left: 1px solid #eb6666; }

.border-l-secondary-double {
  border-left: 1px double #eb6666; }

.border-l-secondary-groove {
  border-left: 1px groove #eb6666; }

.border-l-secondary-ridge {
  border-left: 1px ridge #eb6666; }

.border-l-secondary-inset {
  border-left: 1px inset #eb6666; }

.border-l-secondary-outset {
  border-left: 1px outset #eb6666; }

.border-l-secondary-none {
  border-left: 1px none #eb6666; }

.border-l-secondary-hidden {
  border-left: 1px hidden #eb6666; }

.has-text-danger {
  color: #cc3333; }

.has-background-danger {
  background-color: #cc3333; }

.border-danger {
  border: 1px solid #cc3333; }

.border-m-danger:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #cc3333;
  transform: translateY(-50%); }

.border-danger-dotted {
  border: 1px dotted #cc3333; }

.border-danger-dashed {
  border: 1px dashed #cc3333; }

.border-danger-solid {
  border: 1px solid #cc3333; }

.border-danger-double {
  border: 1px double #cc3333; }

.border-danger-groove {
  border: 1px groove #cc3333; }

.border-danger-ridge {
  border: 1px ridge #cc3333; }

.border-danger-inset {
  border: 1px inset #cc3333; }

.border-danger-outset {
  border: 1px outset #cc3333; }

.border-danger-none {
  border: 1px none #cc3333; }

.border-danger-hidden {
  border: 1px hidden #cc3333; }

.border-t-danger {
  border-top: 1px solid #cc3333; }

.border-t-danger-dotted {
  border-top: 1px dotted #cc3333; }

.border-t-danger-dashed {
  border-top: 1px dashed #cc3333; }

.border-t-danger-solid {
  border-top: 1px solid #cc3333; }

.border-t-danger-double {
  border-top: 1px double #cc3333; }

.border-t-danger-groove {
  border-top: 1px groove #cc3333; }

.border-t-danger-ridge {
  border-top: 1px ridge #cc3333; }

.border-t-danger-inset {
  border-top: 1px inset #cc3333; }

.border-t-danger-outset {
  border-top: 1px outset #cc3333; }

.border-t-danger-none {
  border-top: 1px none #cc3333; }

.border-t-danger-hidden {
  border-top: 1px hidden #cc3333; }

.border-r-danger {
  border-right: 1px solid #cc3333; }

.border-r-danger-dotted {
  border-right: 1px dotted #cc3333; }

.border-r-danger-dashed {
  border-right: 1px dashed #cc3333; }

.border-r-danger-solid {
  border-right: 1px solid #cc3333; }

.border-r-danger-double {
  border-right: 1px double #cc3333; }

.border-r-danger-groove {
  border-right: 1px groove #cc3333; }

.border-r-danger-ridge {
  border-right: 1px ridge #cc3333; }

.border-r-danger-inset {
  border-right: 1px inset #cc3333; }

.border-r-danger-outset {
  border-right: 1px outset #cc3333; }

.border-r-danger-none {
  border-right: 1px none #cc3333; }

.border-r-danger-hidden {
  border-right: 1px hidden #cc3333; }

.border-b-danger {
  border-bottom: 1px solid #cc3333; }

.border-b-danger-dotted {
  border-bottom: 1px dotted #cc3333; }

.border-b-danger-dashed {
  border-bottom: 1px dashed #cc3333; }

.border-b-danger-solid {
  border-bottom: 1px solid #cc3333; }

.border-b-danger-double {
  border-bottom: 1px double #cc3333; }

.border-b-danger-groove {
  border-bottom: 1px groove #cc3333; }

.border-b-danger-ridge {
  border-bottom: 1px ridge #cc3333; }

.border-b-danger-inset {
  border-bottom: 1px inset #cc3333; }

.border-b-danger-outset {
  border-bottom: 1px outset #cc3333; }

.border-b-danger-none {
  border-bottom: 1px none #cc3333; }

.border-b-danger-hidden {
  border-bottom: 1px hidden #cc3333; }

.border-l-danger {
  border-left: 1px solid #cc3333; }

.border-l-danger-dotted {
  border-left: 1px dotted #cc3333; }

.border-l-danger-dashed {
  border-left: 1px dashed #cc3333; }

.border-l-danger-solid {
  border-left: 1px solid #cc3333; }

.border-l-danger-double {
  border-left: 1px double #cc3333; }

.border-l-danger-groove {
  border-left: 1px groove #cc3333; }

.border-l-danger-ridge {
  border-left: 1px ridge #cc3333; }

.border-l-danger-inset {
  border-left: 1px inset #cc3333; }

.border-l-danger-outset {
  border-left: 1px outset #cc3333; }

.border-l-danger-none {
  border-left: 1px none #cc3333; }

.border-l-danger-hidden {
  border-left: 1px hidden #cc3333; }

.has-text-danger-light {
  color: #f6dada; }

.has-background-danger-light {
  background-color: #f6dada; }

.border-danger-light {
  border: 1px solid #f6dada; }

.border-m-danger-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f6dada;
  transform: translateY(-50%); }

.border-danger-light-dotted {
  border: 1px dotted #f6dada; }

.border-danger-light-dashed {
  border: 1px dashed #f6dada; }

.border-danger-light-solid {
  border: 1px solid #f6dada; }

.border-danger-light-double {
  border: 1px double #f6dada; }

.border-danger-light-groove {
  border: 1px groove #f6dada; }

.border-danger-light-ridge {
  border: 1px ridge #f6dada; }

.border-danger-light-inset {
  border: 1px inset #f6dada; }

.border-danger-light-outset {
  border: 1px outset #f6dada; }

.border-danger-light-none {
  border: 1px none #f6dada; }

.border-danger-light-hidden {
  border: 1px hidden #f6dada; }

.border-t-danger-light {
  border-top: 1px solid #f6dada; }

.border-t-danger-light-dotted {
  border-top: 1px dotted #f6dada; }

.border-t-danger-light-dashed {
  border-top: 1px dashed #f6dada; }

.border-t-danger-light-solid {
  border-top: 1px solid #f6dada; }

.border-t-danger-light-double {
  border-top: 1px double #f6dada; }

.border-t-danger-light-groove {
  border-top: 1px groove #f6dada; }

.border-t-danger-light-ridge {
  border-top: 1px ridge #f6dada; }

.border-t-danger-light-inset {
  border-top: 1px inset #f6dada; }

.border-t-danger-light-outset {
  border-top: 1px outset #f6dada; }

.border-t-danger-light-none {
  border-top: 1px none #f6dada; }

.border-t-danger-light-hidden {
  border-top: 1px hidden #f6dada; }

.border-r-danger-light {
  border-right: 1px solid #f6dada; }

.border-r-danger-light-dotted {
  border-right: 1px dotted #f6dada; }

.border-r-danger-light-dashed {
  border-right: 1px dashed #f6dada; }

.border-r-danger-light-solid {
  border-right: 1px solid #f6dada; }

.border-r-danger-light-double {
  border-right: 1px double #f6dada; }

.border-r-danger-light-groove {
  border-right: 1px groove #f6dada; }

.border-r-danger-light-ridge {
  border-right: 1px ridge #f6dada; }

.border-r-danger-light-inset {
  border-right: 1px inset #f6dada; }

.border-r-danger-light-outset {
  border-right: 1px outset #f6dada; }

.border-r-danger-light-none {
  border-right: 1px none #f6dada; }

.border-r-danger-light-hidden {
  border-right: 1px hidden #f6dada; }

.border-b-danger-light {
  border-bottom: 1px solid #f6dada; }

.border-b-danger-light-dotted {
  border-bottom: 1px dotted #f6dada; }

.border-b-danger-light-dashed {
  border-bottom: 1px dashed #f6dada; }

.border-b-danger-light-solid {
  border-bottom: 1px solid #f6dada; }

.border-b-danger-light-double {
  border-bottom: 1px double #f6dada; }

.border-b-danger-light-groove {
  border-bottom: 1px groove #f6dada; }

.border-b-danger-light-ridge {
  border-bottom: 1px ridge #f6dada; }

.border-b-danger-light-inset {
  border-bottom: 1px inset #f6dada; }

.border-b-danger-light-outset {
  border-bottom: 1px outset #f6dada; }

.border-b-danger-light-none {
  border-bottom: 1px none #f6dada; }

.border-b-danger-light-hidden {
  border-bottom: 1px hidden #f6dada; }

.border-l-danger-light {
  border-left: 1px solid #f6dada; }

.border-l-danger-light-dotted {
  border-left: 1px dotted #f6dada; }

.border-l-danger-light-dashed {
  border-left: 1px dashed #f6dada; }

.border-l-danger-light-solid {
  border-left: 1px solid #f6dada; }

.border-l-danger-light-double {
  border-left: 1px double #f6dada; }

.border-l-danger-light-groove {
  border-left: 1px groove #f6dada; }

.border-l-danger-light-ridge {
  border-left: 1px ridge #f6dada; }

.border-l-danger-light-inset {
  border-left: 1px inset #f6dada; }

.border-l-danger-light-outset {
  border-left: 1px outset #f6dada; }

.border-l-danger-light-none {
  border-left: 1px none #f6dada; }

.border-l-danger-light-hidden {
  border-left: 1px hidden #f6dada; }

.has-text-warning {
  color: #ffd500; }

.has-background-warning {
  background-color: #ffd500; }

.border-warning {
  border: 1px solid #ffd500; }

.border-m-warning:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ffd500;
  transform: translateY(-50%); }

.border-warning-dotted {
  border: 1px dotted #ffd500; }

.border-warning-dashed {
  border: 1px dashed #ffd500; }

.border-warning-solid {
  border: 1px solid #ffd500; }

.border-warning-double {
  border: 1px double #ffd500; }

.border-warning-groove {
  border: 1px groove #ffd500; }

.border-warning-ridge {
  border: 1px ridge #ffd500; }

.border-warning-inset {
  border: 1px inset #ffd500; }

.border-warning-outset {
  border: 1px outset #ffd500; }

.border-warning-none {
  border: 1px none #ffd500; }

.border-warning-hidden {
  border: 1px hidden #ffd500; }

.border-t-warning {
  border-top: 1px solid #ffd500; }

.border-t-warning-dotted {
  border-top: 1px dotted #ffd500; }

.border-t-warning-dashed {
  border-top: 1px dashed #ffd500; }

.border-t-warning-solid {
  border-top: 1px solid #ffd500; }

.border-t-warning-double {
  border-top: 1px double #ffd500; }

.border-t-warning-groove {
  border-top: 1px groove #ffd500; }

.border-t-warning-ridge {
  border-top: 1px ridge #ffd500; }

.border-t-warning-inset {
  border-top: 1px inset #ffd500; }

.border-t-warning-outset {
  border-top: 1px outset #ffd500; }

.border-t-warning-none {
  border-top: 1px none #ffd500; }

.border-t-warning-hidden {
  border-top: 1px hidden #ffd500; }

.border-r-warning {
  border-right: 1px solid #ffd500; }

.border-r-warning-dotted {
  border-right: 1px dotted #ffd500; }

.border-r-warning-dashed {
  border-right: 1px dashed #ffd500; }

.border-r-warning-solid {
  border-right: 1px solid #ffd500; }

.border-r-warning-double {
  border-right: 1px double #ffd500; }

.border-r-warning-groove {
  border-right: 1px groove #ffd500; }

.border-r-warning-ridge {
  border-right: 1px ridge #ffd500; }

.border-r-warning-inset {
  border-right: 1px inset #ffd500; }

.border-r-warning-outset {
  border-right: 1px outset #ffd500; }

.border-r-warning-none {
  border-right: 1px none #ffd500; }

.border-r-warning-hidden {
  border-right: 1px hidden #ffd500; }

.border-b-warning {
  border-bottom: 1px solid #ffd500; }

.border-b-warning-dotted {
  border-bottom: 1px dotted #ffd500; }

.border-b-warning-dashed {
  border-bottom: 1px dashed #ffd500; }

.border-b-warning-solid {
  border-bottom: 1px solid #ffd500; }

.border-b-warning-double {
  border-bottom: 1px double #ffd500; }

.border-b-warning-groove {
  border-bottom: 1px groove #ffd500; }

.border-b-warning-ridge {
  border-bottom: 1px ridge #ffd500; }

.border-b-warning-inset {
  border-bottom: 1px inset #ffd500; }

.border-b-warning-outset {
  border-bottom: 1px outset #ffd500; }

.border-b-warning-none {
  border-bottom: 1px none #ffd500; }

.border-b-warning-hidden {
  border-bottom: 1px hidden #ffd500; }

.border-l-warning {
  border-left: 1px solid #ffd500; }

.border-l-warning-dotted {
  border-left: 1px dotted #ffd500; }

.border-l-warning-dashed {
  border-left: 1px dashed #ffd500; }

.border-l-warning-solid {
  border-left: 1px solid #ffd500; }

.border-l-warning-double {
  border-left: 1px double #ffd500; }

.border-l-warning-groove {
  border-left: 1px groove #ffd500; }

.border-l-warning-ridge {
  border-left: 1px ridge #ffd500; }

.border-l-warning-inset {
  border-left: 1px inset #ffd500; }

.border-l-warning-outset {
  border-left: 1px outset #ffd500; }

.border-l-warning-none {
  border-left: 1px none #ffd500; }

.border-l-warning-hidden {
  border-left: 1px hidden #ffd500; }

.has-text-success {
  color: #66cc33; }

.has-background-success {
  background-color: #66cc33; }

.border-success {
  border: 1px solid #66cc33; }

.border-m-success:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #66cc33;
  transform: translateY(-50%); }

.border-success-dotted {
  border: 1px dotted #66cc33; }

.border-success-dashed {
  border: 1px dashed #66cc33; }

.border-success-solid {
  border: 1px solid #66cc33; }

.border-success-double {
  border: 1px double #66cc33; }

.border-success-groove {
  border: 1px groove #66cc33; }

.border-success-ridge {
  border: 1px ridge #66cc33; }

.border-success-inset {
  border: 1px inset #66cc33; }

.border-success-outset {
  border: 1px outset #66cc33; }

.border-success-none {
  border: 1px none #66cc33; }

.border-success-hidden {
  border: 1px hidden #66cc33; }

.border-t-success {
  border-top: 1px solid #66cc33; }

.border-t-success-dotted {
  border-top: 1px dotted #66cc33; }

.border-t-success-dashed {
  border-top: 1px dashed #66cc33; }

.border-t-success-solid {
  border-top: 1px solid #66cc33; }

.border-t-success-double {
  border-top: 1px double #66cc33; }

.border-t-success-groove {
  border-top: 1px groove #66cc33; }

.border-t-success-ridge {
  border-top: 1px ridge #66cc33; }

.border-t-success-inset {
  border-top: 1px inset #66cc33; }

.border-t-success-outset {
  border-top: 1px outset #66cc33; }

.border-t-success-none {
  border-top: 1px none #66cc33; }

.border-t-success-hidden {
  border-top: 1px hidden #66cc33; }

.border-r-success {
  border-right: 1px solid #66cc33; }

.border-r-success-dotted {
  border-right: 1px dotted #66cc33; }

.border-r-success-dashed {
  border-right: 1px dashed #66cc33; }

.border-r-success-solid {
  border-right: 1px solid #66cc33; }

.border-r-success-double {
  border-right: 1px double #66cc33; }

.border-r-success-groove {
  border-right: 1px groove #66cc33; }

.border-r-success-ridge {
  border-right: 1px ridge #66cc33; }

.border-r-success-inset {
  border-right: 1px inset #66cc33; }

.border-r-success-outset {
  border-right: 1px outset #66cc33; }

.border-r-success-none {
  border-right: 1px none #66cc33; }

.border-r-success-hidden {
  border-right: 1px hidden #66cc33; }

.border-b-success {
  border-bottom: 1px solid #66cc33; }

.border-b-success-dotted {
  border-bottom: 1px dotted #66cc33; }

.border-b-success-dashed {
  border-bottom: 1px dashed #66cc33; }

.border-b-success-solid {
  border-bottom: 1px solid #66cc33; }

.border-b-success-double {
  border-bottom: 1px double #66cc33; }

.border-b-success-groove {
  border-bottom: 1px groove #66cc33; }

.border-b-success-ridge {
  border-bottom: 1px ridge #66cc33; }

.border-b-success-inset {
  border-bottom: 1px inset #66cc33; }

.border-b-success-outset {
  border-bottom: 1px outset #66cc33; }

.border-b-success-none {
  border-bottom: 1px none #66cc33; }

.border-b-success-hidden {
  border-bottom: 1px hidden #66cc33; }

.border-l-success {
  border-left: 1px solid #66cc33; }

.border-l-success-dotted {
  border-left: 1px dotted #66cc33; }

.border-l-success-dashed {
  border-left: 1px dashed #66cc33; }

.border-l-success-solid {
  border-left: 1px solid #66cc33; }

.border-l-success-double {
  border-left: 1px double #66cc33; }

.border-l-success-groove {
  border-left: 1px groove #66cc33; }

.border-l-success-ridge {
  border-left: 1px ridge #66cc33; }

.border-l-success-inset {
  border-left: 1px inset #66cc33; }

.border-l-success-outset {
  border-left: 1px outset #66cc33; }

.border-l-success-none {
  border-left: 1px none #66cc33; }

.border-l-success-hidden {
  border-left: 1px hidden #66cc33; }

.has-text-success-light {
  color: #dbffe0; }

.has-background-success-light {
  background-color: #dbffe0; }

.border-success-light {
  border: 1px solid #dbffe0; }

.border-m-success-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dbffe0;
  transform: translateY(-50%); }

.border-success-light-dotted {
  border: 1px dotted #dbffe0; }

.border-success-light-dashed {
  border: 1px dashed #dbffe0; }

.border-success-light-solid {
  border: 1px solid #dbffe0; }

.border-success-light-double {
  border: 1px double #dbffe0; }

.border-success-light-groove {
  border: 1px groove #dbffe0; }

.border-success-light-ridge {
  border: 1px ridge #dbffe0; }

.border-success-light-inset {
  border: 1px inset #dbffe0; }

.border-success-light-outset {
  border: 1px outset #dbffe0; }

.border-success-light-none {
  border: 1px none #dbffe0; }

.border-success-light-hidden {
  border: 1px hidden #dbffe0; }

.border-t-success-light {
  border-top: 1px solid #dbffe0; }

.border-t-success-light-dotted {
  border-top: 1px dotted #dbffe0; }

.border-t-success-light-dashed {
  border-top: 1px dashed #dbffe0; }

.border-t-success-light-solid {
  border-top: 1px solid #dbffe0; }

.border-t-success-light-double {
  border-top: 1px double #dbffe0; }

.border-t-success-light-groove {
  border-top: 1px groove #dbffe0; }

.border-t-success-light-ridge {
  border-top: 1px ridge #dbffe0; }

.border-t-success-light-inset {
  border-top: 1px inset #dbffe0; }

.border-t-success-light-outset {
  border-top: 1px outset #dbffe0; }

.border-t-success-light-none {
  border-top: 1px none #dbffe0; }

.border-t-success-light-hidden {
  border-top: 1px hidden #dbffe0; }

.border-r-success-light {
  border-right: 1px solid #dbffe0; }

.border-r-success-light-dotted {
  border-right: 1px dotted #dbffe0; }

.border-r-success-light-dashed {
  border-right: 1px dashed #dbffe0; }

.border-r-success-light-solid {
  border-right: 1px solid #dbffe0; }

.border-r-success-light-double {
  border-right: 1px double #dbffe0; }

.border-r-success-light-groove {
  border-right: 1px groove #dbffe0; }

.border-r-success-light-ridge {
  border-right: 1px ridge #dbffe0; }

.border-r-success-light-inset {
  border-right: 1px inset #dbffe0; }

.border-r-success-light-outset {
  border-right: 1px outset #dbffe0; }

.border-r-success-light-none {
  border-right: 1px none #dbffe0; }

.border-r-success-light-hidden {
  border-right: 1px hidden #dbffe0; }

.border-b-success-light {
  border-bottom: 1px solid #dbffe0; }

.border-b-success-light-dotted {
  border-bottom: 1px dotted #dbffe0; }

.border-b-success-light-dashed {
  border-bottom: 1px dashed #dbffe0; }

.border-b-success-light-solid {
  border-bottom: 1px solid #dbffe0; }

.border-b-success-light-double {
  border-bottom: 1px double #dbffe0; }

.border-b-success-light-groove {
  border-bottom: 1px groove #dbffe0; }

.border-b-success-light-ridge {
  border-bottom: 1px ridge #dbffe0; }

.border-b-success-light-inset {
  border-bottom: 1px inset #dbffe0; }

.border-b-success-light-outset {
  border-bottom: 1px outset #dbffe0; }

.border-b-success-light-none {
  border-bottom: 1px none #dbffe0; }

.border-b-success-light-hidden {
  border-bottom: 1px hidden #dbffe0; }

.border-l-success-light {
  border-left: 1px solid #dbffe0; }

.border-l-success-light-dotted {
  border-left: 1px dotted #dbffe0; }

.border-l-success-light-dashed {
  border-left: 1px dashed #dbffe0; }

.border-l-success-light-solid {
  border-left: 1px solid #dbffe0; }

.border-l-success-light-double {
  border-left: 1px double #dbffe0; }

.border-l-success-light-groove {
  border-left: 1px groove #dbffe0; }

.border-l-success-light-ridge {
  border-left: 1px ridge #dbffe0; }

.border-l-success-light-inset {
  border-left: 1px inset #dbffe0; }

.border-l-success-light-outset {
  border-left: 1px outset #dbffe0; }

.border-l-success-light-none {
  border-left: 1px none #dbffe0; }

.border-l-success-light-hidden {
  border-left: 1px hidden #dbffe0; }

.has-text-info {
  color: #209fee; }

.has-background-info {
  background-color: #209fee; }

.border-info {
  border: 1px solid #209fee; }

.border-m-info:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #209fee;
  transform: translateY(-50%); }

.border-info-dotted {
  border: 1px dotted #209fee; }

.border-info-dashed {
  border: 1px dashed #209fee; }

.border-info-solid {
  border: 1px solid #209fee; }

.border-info-double {
  border: 1px double #209fee; }

.border-info-groove {
  border: 1px groove #209fee; }

.border-info-ridge {
  border: 1px ridge #209fee; }

.border-info-inset {
  border: 1px inset #209fee; }

.border-info-outset {
  border: 1px outset #209fee; }

.border-info-none {
  border: 1px none #209fee; }

.border-info-hidden {
  border: 1px hidden #209fee; }

.border-t-info {
  border-top: 1px solid #209fee; }

.border-t-info-dotted {
  border-top: 1px dotted #209fee; }

.border-t-info-dashed {
  border-top: 1px dashed #209fee; }

.border-t-info-solid {
  border-top: 1px solid #209fee; }

.border-t-info-double {
  border-top: 1px double #209fee; }

.border-t-info-groove {
  border-top: 1px groove #209fee; }

.border-t-info-ridge {
  border-top: 1px ridge #209fee; }

.border-t-info-inset {
  border-top: 1px inset #209fee; }

.border-t-info-outset {
  border-top: 1px outset #209fee; }

.border-t-info-none {
  border-top: 1px none #209fee; }

.border-t-info-hidden {
  border-top: 1px hidden #209fee; }

.border-r-info {
  border-right: 1px solid #209fee; }

.border-r-info-dotted {
  border-right: 1px dotted #209fee; }

.border-r-info-dashed {
  border-right: 1px dashed #209fee; }

.border-r-info-solid {
  border-right: 1px solid #209fee; }

.border-r-info-double {
  border-right: 1px double #209fee; }

.border-r-info-groove {
  border-right: 1px groove #209fee; }

.border-r-info-ridge {
  border-right: 1px ridge #209fee; }

.border-r-info-inset {
  border-right: 1px inset #209fee; }

.border-r-info-outset {
  border-right: 1px outset #209fee; }

.border-r-info-none {
  border-right: 1px none #209fee; }

.border-r-info-hidden {
  border-right: 1px hidden #209fee; }

.border-b-info {
  border-bottom: 1px solid #209fee; }

.border-b-info-dotted {
  border-bottom: 1px dotted #209fee; }

.border-b-info-dashed {
  border-bottom: 1px dashed #209fee; }

.border-b-info-solid {
  border-bottom: 1px solid #209fee; }

.border-b-info-double {
  border-bottom: 1px double #209fee; }

.border-b-info-groove {
  border-bottom: 1px groove #209fee; }

.border-b-info-ridge {
  border-bottom: 1px ridge #209fee; }

.border-b-info-inset {
  border-bottom: 1px inset #209fee; }

.border-b-info-outset {
  border-bottom: 1px outset #209fee; }

.border-b-info-none {
  border-bottom: 1px none #209fee; }

.border-b-info-hidden {
  border-bottom: 1px hidden #209fee; }

.border-l-info {
  border-left: 1px solid #209fee; }

.border-l-info-dotted {
  border-left: 1px dotted #209fee; }

.border-l-info-dashed {
  border-left: 1px dashed #209fee; }

.border-l-info-solid {
  border-left: 1px solid #209fee; }

.border-l-info-double {
  border-left: 1px double #209fee; }

.border-l-info-groove {
  border-left: 1px groove #209fee; }

.border-l-info-ridge {
  border-left: 1px ridge #209fee; }

.border-l-info-inset {
  border-left: 1px inset #209fee; }

.border-l-info-outset {
  border-left: 1px outset #209fee; }

.border-l-info-none {
  border-left: 1px none #209fee; }

.border-l-info-hidden {
  border-left: 1px hidden #209fee; }

.has-text-black {
  color: #0a0a0a; }

.has-background-black {
  background-color: #0a0a0a; }

.border-black {
  border: 1px solid #0a0a0a; }

.border-black-dotted {
  border: 1px dotted #0a0a0a; }

.border-black-dashed {
  border: 1px dashed #0a0a0a; }

.border-black-solid {
  border: 1px solid #0a0a0a; }

.border-black-double {
  border: 1px double #0a0a0a; }

.border-black-groove {
  border: 1px groove #0a0a0a; }

.border-black-ridge {
  border: 1px ridge #0a0a0a; }

.border-black-inset {
  border: 1px inset #0a0a0a; }

.border-black-outset {
  border: 1px outset #0a0a0a; }

.border-black-none {
  border: 1px none #0a0a0a; }

.border-black-hidden {
  border: 1px hidden #0a0a0a; }

.border-t-black {
  border-top: 1px solid #0a0a0a; }

.border-t-black-dotted {
  border-top: 1px dotted #0a0a0a; }

.border-t-black-dashed {
  border-top: 1px dashed #0a0a0a; }

.border-t-black-solid {
  border-top: 1px solid #0a0a0a; }

.border-t-black-double {
  border-top: 1px double #0a0a0a; }

.border-t-black-groove {
  border-top: 1px groove #0a0a0a; }

.border-t-black-ridge {
  border-top: 1px ridge #0a0a0a; }

.border-t-black-inset {
  border-top: 1px inset #0a0a0a; }

.border-t-black-outset {
  border-top: 1px outset #0a0a0a; }

.border-t-black-none {
  border-top: 1px none #0a0a0a; }

.border-t-black-hidden {
  border-top: 1px hidden #0a0a0a; }

.border-r-black {
  border-right: 1px solid #0a0a0a; }

.border-r-black-dotted {
  border-right: 1px dotted #0a0a0a; }

.border-r-black-dashed {
  border-right: 1px dashed #0a0a0a; }

.border-r-black-solid {
  border-right: 1px solid #0a0a0a; }

.border-r-black-double {
  border-right: 1px double #0a0a0a; }

.border-r-black-groove {
  border-right: 1px groove #0a0a0a; }

.border-r-black-ridge {
  border-right: 1px ridge #0a0a0a; }

.border-r-black-inset {
  border-right: 1px inset #0a0a0a; }

.border-r-black-outset {
  border-right: 1px outset #0a0a0a; }

.border-r-black-none {
  border-right: 1px none #0a0a0a; }

.border-r-black-hidden {
  border-right: 1px hidden #0a0a0a; }

.border-b-black {
  border-bottom: 1px solid #0a0a0a; }

.border-b-black-dotted {
  border-bottom: 1px dotted #0a0a0a; }

.border-b-black-dashed {
  border-bottom: 1px dashed #0a0a0a; }

.border-b-black-solid {
  border-bottom: 1px solid #0a0a0a; }

.border-b-black-double {
  border-bottom: 1px double #0a0a0a; }

.border-b-black-groove {
  border-bottom: 1px groove #0a0a0a; }

.border-b-black-ridge {
  border-bottom: 1px ridge #0a0a0a; }

.border-b-black-inset {
  border-bottom: 1px inset #0a0a0a; }

.border-b-black-outset {
  border-bottom: 1px outset #0a0a0a; }

.border-b-black-none {
  border-bottom: 1px none #0a0a0a; }

.border-b-black-hidden {
  border-bottom: 1px hidden #0a0a0a; }

.border-l-black {
  border-left: 1px solid #0a0a0a; }

.border-l-black-dotted {
  border-left: 1px dotted #0a0a0a; }

.border-l-black-dashed {
  border-left: 1px dashed #0a0a0a; }

.border-l-black-solid {
  border-left: 1px solid #0a0a0a; }

.border-l-black-double {
  border-left: 1px double #0a0a0a; }

.border-l-black-groove {
  border-left: 1px groove #0a0a0a; }

.border-l-black-ridge {
  border-left: 1px ridge #0a0a0a; }

.border-l-black-inset {
  border-left: 1px inset #0a0a0a; }

.border-l-black-outset {
  border-left: 1px outset #0a0a0a; }

.border-l-black-none {
  border-left: 1px none #0a0a0a; }

.border-l-black-hidden {
  border-left: 1px hidden #0a0a0a; }

.has-text-black-bis {
  color: #121212; }

.has-background-black-bis {
  background-color: #121212; }

.border-black-bis {
  border: 1px solid #121212; }

.border-black-bis-dotted {
  border: 1px dotted #121212; }

.border-black-bis-dashed {
  border: 1px dashed #121212; }

.border-black-bis-solid {
  border: 1px solid #121212; }

.border-black-bis-double {
  border: 1px double #121212; }

.border-black-bis-groove {
  border: 1px groove #121212; }

.border-black-bis-ridge {
  border: 1px ridge #121212; }

.border-black-bis-inset {
  border: 1px inset #121212; }

.border-black-bis-outset {
  border: 1px outset #121212; }

.border-black-bis-none {
  border: 1px none #121212; }

.border-black-bis-hidden {
  border: 1px hidden #121212; }

.border-t-black-bis {
  border-top: 1px solid #121212; }

.border-t-black-bis-dotted {
  border-top: 1px dotted #121212; }

.border-t-black-bis-dashed {
  border-top: 1px dashed #121212; }

.border-t-black-bis-solid {
  border-top: 1px solid #121212; }

.border-t-black-bis-double {
  border-top: 1px double #121212; }

.border-t-black-bis-groove {
  border-top: 1px groove #121212; }

.border-t-black-bis-ridge {
  border-top: 1px ridge #121212; }

.border-t-black-bis-inset {
  border-top: 1px inset #121212; }

.border-t-black-bis-outset {
  border-top: 1px outset #121212; }

.border-t-black-bis-none {
  border-top: 1px none #121212; }

.border-t-black-bis-hidden {
  border-top: 1px hidden #121212; }

.border-r-black-bis {
  border-right: 1px solid #121212; }

.border-r-black-bis-dotted {
  border-right: 1px dotted #121212; }

.border-r-black-bis-dashed {
  border-right: 1px dashed #121212; }

.border-r-black-bis-solid {
  border-right: 1px solid #121212; }

.border-r-black-bis-double {
  border-right: 1px double #121212; }

.border-r-black-bis-groove {
  border-right: 1px groove #121212; }

.border-r-black-bis-ridge {
  border-right: 1px ridge #121212; }

.border-r-black-bis-inset {
  border-right: 1px inset #121212; }

.border-r-black-bis-outset {
  border-right: 1px outset #121212; }

.border-r-black-bis-none {
  border-right: 1px none #121212; }

.border-r-black-bis-hidden {
  border-right: 1px hidden #121212; }

.border-b-black-bis {
  border-bottom: 1px solid #121212; }

.border-b-black-bis-dotted {
  border-bottom: 1px dotted #121212; }

.border-b-black-bis-dashed {
  border-bottom: 1px dashed #121212; }

.border-b-black-bis-solid {
  border-bottom: 1px solid #121212; }

.border-b-black-bis-double {
  border-bottom: 1px double #121212; }

.border-b-black-bis-groove {
  border-bottom: 1px groove #121212; }

.border-b-black-bis-ridge {
  border-bottom: 1px ridge #121212; }

.border-b-black-bis-inset {
  border-bottom: 1px inset #121212; }

.border-b-black-bis-outset {
  border-bottom: 1px outset #121212; }

.border-b-black-bis-none {
  border-bottom: 1px none #121212; }

.border-b-black-bis-hidden {
  border-bottom: 1px hidden #121212; }

.border-l-black-bis {
  border-left: 1px solid #121212; }

.border-l-black-bis-dotted {
  border-left: 1px dotted #121212; }

.border-l-black-bis-dashed {
  border-left: 1px dashed #121212; }

.border-l-black-bis-solid {
  border-left: 1px solid #121212; }

.border-l-black-bis-double {
  border-left: 1px double #121212; }

.border-l-black-bis-groove {
  border-left: 1px groove #121212; }

.border-l-black-bis-ridge {
  border-left: 1px ridge #121212; }

.border-l-black-bis-inset {
  border-left: 1px inset #121212; }

.border-l-black-bis-outset {
  border-left: 1px outset #121212; }

.border-l-black-bis-none {
  border-left: 1px none #121212; }

.border-l-black-bis-hidden {
  border-left: 1px hidden #121212; }

.has-text-black-ter {
  color: #242424; }

.has-background-black-ter {
  background-color: #242424; }

.border-black-ter {
  border: 1px solid #242424; }

.border-black-ter-dotted {
  border: 1px dotted #242424; }

.border-black-ter-dashed {
  border: 1px dashed #242424; }

.border-black-ter-solid {
  border: 1px solid #242424; }

.border-black-ter-double {
  border: 1px double #242424; }

.border-black-ter-groove {
  border: 1px groove #242424; }

.border-black-ter-ridge {
  border: 1px ridge #242424; }

.border-black-ter-inset {
  border: 1px inset #242424; }

.border-black-ter-outset {
  border: 1px outset #242424; }

.border-black-ter-none {
  border: 1px none #242424; }

.border-black-ter-hidden {
  border: 1px hidden #242424; }

.border-t-black-ter {
  border-top: 1px solid #242424; }

.border-t-black-ter-dotted {
  border-top: 1px dotted #242424; }

.border-t-black-ter-dashed {
  border-top: 1px dashed #242424; }

.border-t-black-ter-solid {
  border-top: 1px solid #242424; }

.border-t-black-ter-double {
  border-top: 1px double #242424; }

.border-t-black-ter-groove {
  border-top: 1px groove #242424; }

.border-t-black-ter-ridge {
  border-top: 1px ridge #242424; }

.border-t-black-ter-inset {
  border-top: 1px inset #242424; }

.border-t-black-ter-outset {
  border-top: 1px outset #242424; }

.border-t-black-ter-none {
  border-top: 1px none #242424; }

.border-t-black-ter-hidden {
  border-top: 1px hidden #242424; }

.border-r-black-ter {
  border-right: 1px solid #242424; }

.border-r-black-ter-dotted {
  border-right: 1px dotted #242424; }

.border-r-black-ter-dashed {
  border-right: 1px dashed #242424; }

.border-r-black-ter-solid {
  border-right: 1px solid #242424; }

.border-r-black-ter-double {
  border-right: 1px double #242424; }

.border-r-black-ter-groove {
  border-right: 1px groove #242424; }

.border-r-black-ter-ridge {
  border-right: 1px ridge #242424; }

.border-r-black-ter-inset {
  border-right: 1px inset #242424; }

.border-r-black-ter-outset {
  border-right: 1px outset #242424; }

.border-r-black-ter-none {
  border-right: 1px none #242424; }

.border-r-black-ter-hidden {
  border-right: 1px hidden #242424; }

.border-b-black-ter {
  border-bottom: 1px solid #242424; }

.border-b-black-ter-dotted {
  border-bottom: 1px dotted #242424; }

.border-b-black-ter-dashed {
  border-bottom: 1px dashed #242424; }

.border-b-black-ter-solid {
  border-bottom: 1px solid #242424; }

.border-b-black-ter-double {
  border-bottom: 1px double #242424; }

.border-b-black-ter-groove {
  border-bottom: 1px groove #242424; }

.border-b-black-ter-ridge {
  border-bottom: 1px ridge #242424; }

.border-b-black-ter-inset {
  border-bottom: 1px inset #242424; }

.border-b-black-ter-outset {
  border-bottom: 1px outset #242424; }

.border-b-black-ter-none {
  border-bottom: 1px none #242424; }

.border-b-black-ter-hidden {
  border-bottom: 1px hidden #242424; }

.border-l-black-ter {
  border-left: 1px solid #242424; }

.border-l-black-ter-dotted {
  border-left: 1px dotted #242424; }

.border-l-black-ter-dashed {
  border-left: 1px dashed #242424; }

.border-l-black-ter-solid {
  border-left: 1px solid #242424; }

.border-l-black-ter-double {
  border-left: 1px double #242424; }

.border-l-black-ter-groove {
  border-left: 1px groove #242424; }

.border-l-black-ter-ridge {
  border-left: 1px ridge #242424; }

.border-l-black-ter-inset {
  border-left: 1px inset #242424; }

.border-l-black-ter-outset {
  border-left: 1px outset #242424; }

.border-l-black-ter-none {
  border-left: 1px none #242424; }

.border-l-black-ter-hidden {
  border-left: 1px hidden #242424; }

.has-text-grey-darker {
  color: #363636; }

.has-background-grey-darker {
  background-color: #363636; }

.border-grey-darker {
  border: 1px solid #363636; }

.border-grey-darker-dotted {
  border: 1px dotted #363636; }

.border-grey-darker-dashed {
  border: 1px dashed #363636; }

.border-grey-darker-solid {
  border: 1px solid #363636; }

.border-grey-darker-double {
  border: 1px double #363636; }

.border-grey-darker-groove {
  border: 1px groove #363636; }

.border-grey-darker-ridge {
  border: 1px ridge #363636; }

.border-grey-darker-inset {
  border: 1px inset #363636; }

.border-grey-darker-outset {
  border: 1px outset #363636; }

.border-grey-darker-none {
  border: 1px none #363636; }

.border-grey-darker-hidden {
  border: 1px hidden #363636; }

.border-t-grey-darker {
  border-top: 1px solid #363636; }

.border-t-grey-darker-dotted {
  border-top: 1px dotted #363636; }

.border-t-grey-darker-dashed {
  border-top: 1px dashed #363636; }

.border-t-grey-darker-solid {
  border-top: 1px solid #363636; }

.border-t-grey-darker-double {
  border-top: 1px double #363636; }

.border-t-grey-darker-groove {
  border-top: 1px groove #363636; }

.border-t-grey-darker-ridge {
  border-top: 1px ridge #363636; }

.border-t-grey-darker-inset {
  border-top: 1px inset #363636; }

.border-t-grey-darker-outset {
  border-top: 1px outset #363636; }

.border-t-grey-darker-none {
  border-top: 1px none #363636; }

.border-t-grey-darker-hidden {
  border-top: 1px hidden #363636; }

.border-r-grey-darker {
  border-right: 1px solid #363636; }

.border-r-grey-darker-dotted {
  border-right: 1px dotted #363636; }

.border-r-grey-darker-dashed {
  border-right: 1px dashed #363636; }

.border-r-grey-darker-solid {
  border-right: 1px solid #363636; }

.border-r-grey-darker-double {
  border-right: 1px double #363636; }

.border-r-grey-darker-groove {
  border-right: 1px groove #363636; }

.border-r-grey-darker-ridge {
  border-right: 1px ridge #363636; }

.border-r-grey-darker-inset {
  border-right: 1px inset #363636; }

.border-r-grey-darker-outset {
  border-right: 1px outset #363636; }

.border-r-grey-darker-none {
  border-right: 1px none #363636; }

.border-r-grey-darker-hidden {
  border-right: 1px hidden #363636; }

.border-b-grey-darker {
  border-bottom: 1px solid #363636; }

.border-b-grey-darker-dotted {
  border-bottom: 1px dotted #363636; }

.border-b-grey-darker-dashed {
  border-bottom: 1px dashed #363636; }

.border-b-grey-darker-solid {
  border-bottom: 1px solid #363636; }

.border-b-grey-darker-double {
  border-bottom: 1px double #363636; }

.border-b-grey-darker-groove {
  border-bottom: 1px groove #363636; }

.border-b-grey-darker-ridge {
  border-bottom: 1px ridge #363636; }

.border-b-grey-darker-inset {
  border-bottom: 1px inset #363636; }

.border-b-grey-darker-outset {
  border-bottom: 1px outset #363636; }

.border-b-grey-darker-none {
  border-bottom: 1px none #363636; }

.border-b-grey-darker-hidden {
  border-bottom: 1px hidden #363636; }

.border-l-grey-darker {
  border-left: 1px solid #363636; }

.border-l-grey-darker-dotted {
  border-left: 1px dotted #363636; }

.border-l-grey-darker-dashed {
  border-left: 1px dashed #363636; }

.border-l-grey-darker-solid {
  border-left: 1px solid #363636; }

.border-l-grey-darker-double {
  border-left: 1px double #363636; }

.border-l-grey-darker-groove {
  border-left: 1px groove #363636; }

.border-l-grey-darker-ridge {
  border-left: 1px ridge #363636; }

.border-l-grey-darker-inset {
  border-left: 1px inset #363636; }

.border-l-grey-darker-outset {
  border-left: 1px outset #363636; }

.border-l-grey-darker-none {
  border-left: 1px none #363636; }

.border-l-grey-darker-hidden {
  border-left: 1px hidden #363636; }

.has-text-grey-dark {
  color: #4a4a4a; }

.has-background-grey-dark {
  background-color: #4a4a4a; }

.border-grey-dark {
  border: 1px solid #4a4a4a; }

.border-grey-dark-dotted {
  border: 1px dotted #4a4a4a; }

.border-grey-dark-dashed {
  border: 1px dashed #4a4a4a; }

.border-grey-dark-solid {
  border: 1px solid #4a4a4a; }

.border-grey-dark-double {
  border: 1px double #4a4a4a; }

.border-grey-dark-groove {
  border: 1px groove #4a4a4a; }

.border-grey-dark-ridge {
  border: 1px ridge #4a4a4a; }

.border-grey-dark-inset {
  border: 1px inset #4a4a4a; }

.border-grey-dark-outset {
  border: 1px outset #4a4a4a; }

.border-grey-dark-none {
  border: 1px none #4a4a4a; }

.border-grey-dark-hidden {
  border: 1px hidden #4a4a4a; }

.border-t-grey-dark {
  border-top: 1px solid #4a4a4a; }

.border-t-grey-dark-dotted {
  border-top: 1px dotted #4a4a4a; }

.border-t-grey-dark-dashed {
  border-top: 1px dashed #4a4a4a; }

.border-t-grey-dark-solid {
  border-top: 1px solid #4a4a4a; }

.border-t-grey-dark-double {
  border-top: 1px double #4a4a4a; }

.border-t-grey-dark-groove {
  border-top: 1px groove #4a4a4a; }

.border-t-grey-dark-ridge {
  border-top: 1px ridge #4a4a4a; }

.border-t-grey-dark-inset {
  border-top: 1px inset #4a4a4a; }

.border-t-grey-dark-outset {
  border-top: 1px outset #4a4a4a; }

.border-t-grey-dark-none {
  border-top: 1px none #4a4a4a; }

.border-t-grey-dark-hidden {
  border-top: 1px hidden #4a4a4a; }

.border-r-grey-dark {
  border-right: 1px solid #4a4a4a; }

.border-r-grey-dark-dotted {
  border-right: 1px dotted #4a4a4a; }

.border-r-grey-dark-dashed {
  border-right: 1px dashed #4a4a4a; }

.border-r-grey-dark-solid {
  border-right: 1px solid #4a4a4a; }

.border-r-grey-dark-double {
  border-right: 1px double #4a4a4a; }

.border-r-grey-dark-groove {
  border-right: 1px groove #4a4a4a; }

.border-r-grey-dark-ridge {
  border-right: 1px ridge #4a4a4a; }

.border-r-grey-dark-inset {
  border-right: 1px inset #4a4a4a; }

.border-r-grey-dark-outset {
  border-right: 1px outset #4a4a4a; }

.border-r-grey-dark-none {
  border-right: 1px none #4a4a4a; }

.border-r-grey-dark-hidden {
  border-right: 1px hidden #4a4a4a; }

.border-b-grey-dark {
  border-bottom: 1px solid #4a4a4a; }

.border-b-grey-dark-dotted {
  border-bottom: 1px dotted #4a4a4a; }

.border-b-grey-dark-dashed {
  border-bottom: 1px dashed #4a4a4a; }

.border-b-grey-dark-solid {
  border-bottom: 1px solid #4a4a4a; }

.border-b-grey-dark-double {
  border-bottom: 1px double #4a4a4a; }

.border-b-grey-dark-groove {
  border-bottom: 1px groove #4a4a4a; }

.border-b-grey-dark-ridge {
  border-bottom: 1px ridge #4a4a4a; }

.border-b-grey-dark-inset {
  border-bottom: 1px inset #4a4a4a; }

.border-b-grey-dark-outset {
  border-bottom: 1px outset #4a4a4a; }

.border-b-grey-dark-none {
  border-bottom: 1px none #4a4a4a; }

.border-b-grey-dark-hidden {
  border-bottom: 1px hidden #4a4a4a; }

.border-l-grey-dark {
  border-left: 1px solid #4a4a4a; }

.border-l-grey-dark-dotted {
  border-left: 1px dotted #4a4a4a; }

.border-l-grey-dark-dashed {
  border-left: 1px dashed #4a4a4a; }

.border-l-grey-dark-solid {
  border-left: 1px solid #4a4a4a; }

.border-l-grey-dark-double {
  border-left: 1px double #4a4a4a; }

.border-l-grey-dark-groove {
  border-left: 1px groove #4a4a4a; }

.border-l-grey-dark-ridge {
  border-left: 1px ridge #4a4a4a; }

.border-l-grey-dark-inset {
  border-left: 1px inset #4a4a4a; }

.border-l-grey-dark-outset {
  border-left: 1px outset #4a4a4a; }

.border-l-grey-dark-none {
  border-left: 1px none #4a4a4a; }

.border-l-grey-dark-hidden {
  border-left: 1px hidden #4a4a4a; }

.has-text-grey {
  color: #7a7a7a; }

.has-background-grey {
  background-color: #7a7a7a; }

.border-grey {
  border: 1px solid #7a7a7a; }

.border-grey-dotted {
  border: 1px dotted #7a7a7a; }

.border-grey-dashed {
  border: 1px dashed #7a7a7a; }

.border-grey-solid {
  border: 1px solid #7a7a7a; }

.border-grey-double {
  border: 1px double #7a7a7a; }

.border-grey-groove {
  border: 1px groove #7a7a7a; }

.border-grey-ridge {
  border: 1px ridge #7a7a7a; }

.border-grey-inset {
  border: 1px inset #7a7a7a; }

.border-grey-outset {
  border: 1px outset #7a7a7a; }

.border-grey-none {
  border: 1px none #7a7a7a; }

.border-grey-hidden {
  border: 1px hidden #7a7a7a; }

.border-t-grey {
  border-top: 1px solid #7a7a7a; }

.border-t-grey-dotted {
  border-top: 1px dotted #7a7a7a; }

.border-t-grey-dashed {
  border-top: 1px dashed #7a7a7a; }

.border-t-grey-solid {
  border-top: 1px solid #7a7a7a; }

.border-t-grey-double {
  border-top: 1px double #7a7a7a; }

.border-t-grey-groove {
  border-top: 1px groove #7a7a7a; }

.border-t-grey-ridge {
  border-top: 1px ridge #7a7a7a; }

.border-t-grey-inset {
  border-top: 1px inset #7a7a7a; }

.border-t-grey-outset {
  border-top: 1px outset #7a7a7a; }

.border-t-grey-none {
  border-top: 1px none #7a7a7a; }

.border-t-grey-hidden {
  border-top: 1px hidden #7a7a7a; }

.border-r-grey {
  border-right: 1px solid #7a7a7a; }

.border-r-grey-dotted {
  border-right: 1px dotted #7a7a7a; }

.border-r-grey-dashed {
  border-right: 1px dashed #7a7a7a; }

.border-r-grey-solid {
  border-right: 1px solid #7a7a7a; }

.border-r-grey-double {
  border-right: 1px double #7a7a7a; }

.border-r-grey-groove {
  border-right: 1px groove #7a7a7a; }

.border-r-grey-ridge {
  border-right: 1px ridge #7a7a7a; }

.border-r-grey-inset {
  border-right: 1px inset #7a7a7a; }

.border-r-grey-outset {
  border-right: 1px outset #7a7a7a; }

.border-r-grey-none {
  border-right: 1px none #7a7a7a; }

.border-r-grey-hidden {
  border-right: 1px hidden #7a7a7a; }

.border-b-grey {
  border-bottom: 1px solid #7a7a7a; }

.border-b-grey-dotted {
  border-bottom: 1px dotted #7a7a7a; }

.border-b-grey-dashed {
  border-bottom: 1px dashed #7a7a7a; }

.border-b-grey-solid {
  border-bottom: 1px solid #7a7a7a; }

.border-b-grey-double {
  border-bottom: 1px double #7a7a7a; }

.border-b-grey-groove {
  border-bottom: 1px groove #7a7a7a; }

.border-b-grey-ridge {
  border-bottom: 1px ridge #7a7a7a; }

.border-b-grey-inset {
  border-bottom: 1px inset #7a7a7a; }

.border-b-grey-outset {
  border-bottom: 1px outset #7a7a7a; }

.border-b-grey-none {
  border-bottom: 1px none #7a7a7a; }

.border-b-grey-hidden {
  border-bottom: 1px hidden #7a7a7a; }

.border-l-grey {
  border-left: 1px solid #7a7a7a; }

.border-l-grey-dotted {
  border-left: 1px dotted #7a7a7a; }

.border-l-grey-dashed {
  border-left: 1px dashed #7a7a7a; }

.border-l-grey-solid {
  border-left: 1px solid #7a7a7a; }

.border-l-grey-double {
  border-left: 1px double #7a7a7a; }

.border-l-grey-groove {
  border-left: 1px groove #7a7a7a; }

.border-l-grey-ridge {
  border-left: 1px ridge #7a7a7a; }

.border-l-grey-inset {
  border-left: 1px inset #7a7a7a; }

.border-l-grey-outset {
  border-left: 1px outset #7a7a7a; }

.border-l-grey-none {
  border-left: 1px none #7a7a7a; }

.border-l-grey-hidden {
  border-left: 1px hidden #7a7a7a; }

.has-text-grey-light {
  color: #b5b5b5; }

.has-background-grey-light {
  background-color: #b5b5b5; }

.border-grey-light {
  border: 1px solid #b5b5b5; }

.border-grey-light-dotted {
  border: 1px dotted #b5b5b5; }

.border-grey-light-dashed {
  border: 1px dashed #b5b5b5; }

.border-grey-light-solid {
  border: 1px solid #b5b5b5; }

.border-grey-light-double {
  border: 1px double #b5b5b5; }

.border-grey-light-groove {
  border: 1px groove #b5b5b5; }

.border-grey-light-ridge {
  border: 1px ridge #b5b5b5; }

.border-grey-light-inset {
  border: 1px inset #b5b5b5; }

.border-grey-light-outset {
  border: 1px outset #b5b5b5; }

.border-grey-light-none {
  border: 1px none #b5b5b5; }

.border-grey-light-hidden {
  border: 1px hidden #b5b5b5; }

.border-t-grey-light {
  border-top: 1px solid #b5b5b5; }

.border-t-grey-light-dotted {
  border-top: 1px dotted #b5b5b5; }

.border-t-grey-light-dashed {
  border-top: 1px dashed #b5b5b5; }

.border-t-grey-light-solid {
  border-top: 1px solid #b5b5b5; }

.border-t-grey-light-double {
  border-top: 1px double #b5b5b5; }

.border-t-grey-light-groove {
  border-top: 1px groove #b5b5b5; }

.border-t-grey-light-ridge {
  border-top: 1px ridge #b5b5b5; }

.border-t-grey-light-inset {
  border-top: 1px inset #b5b5b5; }

.border-t-grey-light-outset {
  border-top: 1px outset #b5b5b5; }

.border-t-grey-light-none {
  border-top: 1px none #b5b5b5; }

.border-t-grey-light-hidden {
  border-top: 1px hidden #b5b5b5; }

.border-r-grey-light {
  border-right: 1px solid #b5b5b5; }

.border-r-grey-light-dotted {
  border-right: 1px dotted #b5b5b5; }

.border-r-grey-light-dashed {
  border-right: 1px dashed #b5b5b5; }

.border-r-grey-light-solid {
  border-right: 1px solid #b5b5b5; }

.border-r-grey-light-double {
  border-right: 1px double #b5b5b5; }

.border-r-grey-light-groove {
  border-right: 1px groove #b5b5b5; }

.border-r-grey-light-ridge {
  border-right: 1px ridge #b5b5b5; }

.border-r-grey-light-inset {
  border-right: 1px inset #b5b5b5; }

.border-r-grey-light-outset {
  border-right: 1px outset #b5b5b5; }

.border-r-grey-light-none {
  border-right: 1px none #b5b5b5; }

.border-r-grey-light-hidden {
  border-right: 1px hidden #b5b5b5; }

.border-b-grey-light {
  border-bottom: 1px solid #b5b5b5; }

.border-b-grey-light-dotted {
  border-bottom: 1px dotted #b5b5b5; }

.border-b-grey-light-dashed {
  border-bottom: 1px dashed #b5b5b5; }

.border-b-grey-light-solid {
  border-bottom: 1px solid #b5b5b5; }

.border-b-grey-light-double {
  border-bottom: 1px double #b5b5b5; }

.border-b-grey-light-groove {
  border-bottom: 1px groove #b5b5b5; }

.border-b-grey-light-ridge {
  border-bottom: 1px ridge #b5b5b5; }

.border-b-grey-light-inset {
  border-bottom: 1px inset #b5b5b5; }

.border-b-grey-light-outset {
  border-bottom: 1px outset #b5b5b5; }

.border-b-grey-light-none {
  border-bottom: 1px none #b5b5b5; }

.border-b-grey-light-hidden {
  border-bottom: 1px hidden #b5b5b5; }

.border-l-grey-light {
  border-left: 1px solid #b5b5b5; }

.border-l-grey-light-dotted {
  border-left: 1px dotted #b5b5b5; }

.border-l-grey-light-dashed {
  border-left: 1px dashed #b5b5b5; }

.border-l-grey-light-solid {
  border-left: 1px solid #b5b5b5; }

.border-l-grey-light-double {
  border-left: 1px double #b5b5b5; }

.border-l-grey-light-groove {
  border-left: 1px groove #b5b5b5; }

.border-l-grey-light-ridge {
  border-left: 1px ridge #b5b5b5; }

.border-l-grey-light-inset {
  border-left: 1px inset #b5b5b5; }

.border-l-grey-light-outset {
  border-left: 1px outset #b5b5b5; }

.border-l-grey-light-none {
  border-left: 1px none #b5b5b5; }

.border-l-grey-light-hidden {
  border-left: 1px hidden #b5b5b5; }

.has-text-grey-lighter {
  color: #e6e6e6; }

.has-background-grey-lighter {
  background-color: #e6e6e6; }

.border-grey-lighter {
  border: 1px solid #e6e6e6; }

.border-grey-lighter-dotted {
  border: 1px dotted #e6e6e6; }

.border-grey-lighter-dashed {
  border: 1px dashed #e6e6e6; }

.border-grey-lighter-solid {
  border: 1px solid #e6e6e6; }

.border-grey-lighter-double {
  border: 1px double #e6e6e6; }

.border-grey-lighter-groove {
  border: 1px groove #e6e6e6; }

.border-grey-lighter-ridge {
  border: 1px ridge #e6e6e6; }

.border-grey-lighter-inset {
  border: 1px inset #e6e6e6; }

.border-grey-lighter-outset {
  border: 1px outset #e6e6e6; }

.border-grey-lighter-none {
  border: 1px none #e6e6e6; }

.border-grey-lighter-hidden {
  border: 1px hidden #e6e6e6; }

.border-t-grey-lighter {
  border-top: 1px solid #e6e6e6; }

.border-t-grey-lighter-dotted {
  border-top: 1px dotted #e6e6e6; }

.border-t-grey-lighter-dashed {
  border-top: 1px dashed #e6e6e6; }

.border-t-grey-lighter-solid {
  border-top: 1px solid #e6e6e6; }

.border-t-grey-lighter-double {
  border-top: 1px double #e6e6e6; }

.border-t-grey-lighter-groove {
  border-top: 1px groove #e6e6e6; }

.border-t-grey-lighter-ridge {
  border-top: 1px ridge #e6e6e6; }

.border-t-grey-lighter-inset {
  border-top: 1px inset #e6e6e6; }

.border-t-grey-lighter-outset {
  border-top: 1px outset #e6e6e6; }

.border-t-grey-lighter-none {
  border-top: 1px none #e6e6e6; }

.border-t-grey-lighter-hidden {
  border-top: 1px hidden #e6e6e6; }

.border-r-grey-lighter {
  border-right: 1px solid #e6e6e6; }

.border-r-grey-lighter-dotted {
  border-right: 1px dotted #e6e6e6; }

.border-r-grey-lighter-dashed {
  border-right: 1px dashed #e6e6e6; }

.border-r-grey-lighter-solid {
  border-right: 1px solid #e6e6e6; }

.border-r-grey-lighter-double {
  border-right: 1px double #e6e6e6; }

.border-r-grey-lighter-groove {
  border-right: 1px groove #e6e6e6; }

.border-r-grey-lighter-ridge {
  border-right: 1px ridge #e6e6e6; }

.border-r-grey-lighter-inset {
  border-right: 1px inset #e6e6e6; }

.border-r-grey-lighter-outset {
  border-right: 1px outset #e6e6e6; }

.border-r-grey-lighter-none {
  border-right: 1px none #e6e6e6; }

.border-r-grey-lighter-hidden {
  border-right: 1px hidden #e6e6e6; }

.border-b-grey-lighter {
  border-bottom: 1px solid #e6e6e6; }

.border-b-grey-lighter-dotted {
  border-bottom: 1px dotted #e6e6e6; }

.border-b-grey-lighter-dashed {
  border-bottom: 1px dashed #e6e6e6; }

.border-b-grey-lighter-solid {
  border-bottom: 1px solid #e6e6e6; }

.border-b-grey-lighter-double {
  border-bottom: 1px double #e6e6e6; }

.border-b-grey-lighter-groove {
  border-bottom: 1px groove #e6e6e6; }

.border-b-grey-lighter-ridge {
  border-bottom: 1px ridge #e6e6e6; }

.border-b-grey-lighter-inset {
  border-bottom: 1px inset #e6e6e6; }

.border-b-grey-lighter-outset {
  border-bottom: 1px outset #e6e6e6; }

.border-b-grey-lighter-none {
  border-bottom: 1px none #e6e6e6; }

.border-b-grey-lighter-hidden {
  border-bottom: 1px hidden #e6e6e6; }

.border-l-grey-lighter {
  border-left: 1px solid #e6e6e6; }

.border-l-grey-lighter-dotted {
  border-left: 1px dotted #e6e6e6; }

.border-l-grey-lighter-dashed {
  border-left: 1px dashed #e6e6e6; }

.border-l-grey-lighter-solid {
  border-left: 1px solid #e6e6e6; }

.border-l-grey-lighter-double {
  border-left: 1px double #e6e6e6; }

.border-l-grey-lighter-groove {
  border-left: 1px groove #e6e6e6; }

.border-l-grey-lighter-ridge {
  border-left: 1px ridge #e6e6e6; }

.border-l-grey-lighter-inset {
  border-left: 1px inset #e6e6e6; }

.border-l-grey-lighter-outset {
  border-left: 1px outset #e6e6e6; }

.border-l-grey-lighter-none {
  border-left: 1px none #e6e6e6; }

.border-l-grey-lighter-hidden {
  border-left: 1px hidden #e6e6e6; }

.has-text-white-ter {
  color: whitesmoke; }

.has-background-white-ter {
  background-color: whitesmoke; }

.border-white-ter {
  border: 1px solid whitesmoke; }

.border-white-ter-dotted {
  border: 1px dotted whitesmoke; }

.border-white-ter-dashed {
  border: 1px dashed whitesmoke; }

.border-white-ter-solid {
  border: 1px solid whitesmoke; }

.border-white-ter-double {
  border: 1px double whitesmoke; }

.border-white-ter-groove {
  border: 1px groove whitesmoke; }

.border-white-ter-ridge {
  border: 1px ridge whitesmoke; }

.border-white-ter-inset {
  border: 1px inset whitesmoke; }

.border-white-ter-outset {
  border: 1px outset whitesmoke; }

.border-white-ter-none {
  border: 1px none whitesmoke; }

.border-white-ter-hidden {
  border: 1px hidden whitesmoke; }

.border-t-white-ter {
  border-top: 1px solid whitesmoke; }

.border-t-white-ter-dotted {
  border-top: 1px dotted whitesmoke; }

.border-t-white-ter-dashed {
  border-top: 1px dashed whitesmoke; }

.border-t-white-ter-solid {
  border-top: 1px solid whitesmoke; }

.border-t-white-ter-double {
  border-top: 1px double whitesmoke; }

.border-t-white-ter-groove {
  border-top: 1px groove whitesmoke; }

.border-t-white-ter-ridge {
  border-top: 1px ridge whitesmoke; }

.border-t-white-ter-inset {
  border-top: 1px inset whitesmoke; }

.border-t-white-ter-outset {
  border-top: 1px outset whitesmoke; }

.border-t-white-ter-none {
  border-top: 1px none whitesmoke; }

.border-t-white-ter-hidden {
  border-top: 1px hidden whitesmoke; }

.border-r-white-ter {
  border-right: 1px solid whitesmoke; }

.border-r-white-ter-dotted {
  border-right: 1px dotted whitesmoke; }

.border-r-white-ter-dashed {
  border-right: 1px dashed whitesmoke; }

.border-r-white-ter-solid {
  border-right: 1px solid whitesmoke; }

.border-r-white-ter-double {
  border-right: 1px double whitesmoke; }

.border-r-white-ter-groove {
  border-right: 1px groove whitesmoke; }

.border-r-white-ter-ridge {
  border-right: 1px ridge whitesmoke; }

.border-r-white-ter-inset {
  border-right: 1px inset whitesmoke; }

.border-r-white-ter-outset {
  border-right: 1px outset whitesmoke; }

.border-r-white-ter-none {
  border-right: 1px none whitesmoke; }

.border-r-white-ter-hidden {
  border-right: 1px hidden whitesmoke; }

.border-b-white-ter {
  border-bottom: 1px solid whitesmoke; }

.border-b-white-ter-dotted {
  border-bottom: 1px dotted whitesmoke; }

.border-b-white-ter-dashed {
  border-bottom: 1px dashed whitesmoke; }

.border-b-white-ter-solid {
  border-bottom: 1px solid whitesmoke; }

.border-b-white-ter-double {
  border-bottom: 1px double whitesmoke; }

.border-b-white-ter-groove {
  border-bottom: 1px groove whitesmoke; }

.border-b-white-ter-ridge {
  border-bottom: 1px ridge whitesmoke; }

.border-b-white-ter-inset {
  border-bottom: 1px inset whitesmoke; }

.border-b-white-ter-outset {
  border-bottom: 1px outset whitesmoke; }

.border-b-white-ter-none {
  border-bottom: 1px none whitesmoke; }

.border-b-white-ter-hidden {
  border-bottom: 1px hidden whitesmoke; }

.border-l-white-ter {
  border-left: 1px solid whitesmoke; }

.border-l-white-ter-dotted {
  border-left: 1px dotted whitesmoke; }

.border-l-white-ter-dashed {
  border-left: 1px dashed whitesmoke; }

.border-l-white-ter-solid {
  border-left: 1px solid whitesmoke; }

.border-l-white-ter-double {
  border-left: 1px double whitesmoke; }

.border-l-white-ter-groove {
  border-left: 1px groove whitesmoke; }

.border-l-white-ter-ridge {
  border-left: 1px ridge whitesmoke; }

.border-l-white-ter-inset {
  border-left: 1px inset whitesmoke; }

.border-l-white-ter-outset {
  border-left: 1px outset whitesmoke; }

.border-l-white-ter-none {
  border-left: 1px none whitesmoke; }

.border-l-white-ter-hidden {
  border-left: 1px hidden whitesmoke; }

.has-text-white-bis {
  color: #fafafa; }

.has-background-white-bis {
  background-color: #fafafa; }

.border-white-bis {
  border: 1px solid #fafafa; }

.border-white-bis-dotted {
  border: 1px dotted #fafafa; }

.border-white-bis-dashed {
  border: 1px dashed #fafafa; }

.border-white-bis-solid {
  border: 1px solid #fafafa; }

.border-white-bis-double {
  border: 1px double #fafafa; }

.border-white-bis-groove {
  border: 1px groove #fafafa; }

.border-white-bis-ridge {
  border: 1px ridge #fafafa; }

.border-white-bis-inset {
  border: 1px inset #fafafa; }

.border-white-bis-outset {
  border: 1px outset #fafafa; }

.border-white-bis-none {
  border: 1px none #fafafa; }

.border-white-bis-hidden {
  border: 1px hidden #fafafa; }

.border-t-white-bis {
  border-top: 1px solid #fafafa; }

.border-t-white-bis-dotted {
  border-top: 1px dotted #fafafa; }

.border-t-white-bis-dashed {
  border-top: 1px dashed #fafafa; }

.border-t-white-bis-solid {
  border-top: 1px solid #fafafa; }

.border-t-white-bis-double {
  border-top: 1px double #fafafa; }

.border-t-white-bis-groove {
  border-top: 1px groove #fafafa; }

.border-t-white-bis-ridge {
  border-top: 1px ridge #fafafa; }

.border-t-white-bis-inset {
  border-top: 1px inset #fafafa; }

.border-t-white-bis-outset {
  border-top: 1px outset #fafafa; }

.border-t-white-bis-none {
  border-top: 1px none #fafafa; }

.border-t-white-bis-hidden {
  border-top: 1px hidden #fafafa; }

.border-r-white-bis {
  border-right: 1px solid #fafafa; }

.border-r-white-bis-dotted {
  border-right: 1px dotted #fafafa; }

.border-r-white-bis-dashed {
  border-right: 1px dashed #fafafa; }

.border-r-white-bis-solid {
  border-right: 1px solid #fafafa; }

.border-r-white-bis-double {
  border-right: 1px double #fafafa; }

.border-r-white-bis-groove {
  border-right: 1px groove #fafafa; }

.border-r-white-bis-ridge {
  border-right: 1px ridge #fafafa; }

.border-r-white-bis-inset {
  border-right: 1px inset #fafafa; }

.border-r-white-bis-outset {
  border-right: 1px outset #fafafa; }

.border-r-white-bis-none {
  border-right: 1px none #fafafa; }

.border-r-white-bis-hidden {
  border-right: 1px hidden #fafafa; }

.border-b-white-bis {
  border-bottom: 1px solid #fafafa; }

.border-b-white-bis-dotted {
  border-bottom: 1px dotted #fafafa; }

.border-b-white-bis-dashed {
  border-bottom: 1px dashed #fafafa; }

.border-b-white-bis-solid {
  border-bottom: 1px solid #fafafa; }

.border-b-white-bis-double {
  border-bottom: 1px double #fafafa; }

.border-b-white-bis-groove {
  border-bottom: 1px groove #fafafa; }

.border-b-white-bis-ridge {
  border-bottom: 1px ridge #fafafa; }

.border-b-white-bis-inset {
  border-bottom: 1px inset #fafafa; }

.border-b-white-bis-outset {
  border-bottom: 1px outset #fafafa; }

.border-b-white-bis-none {
  border-bottom: 1px none #fafafa; }

.border-b-white-bis-hidden {
  border-bottom: 1px hidden #fafafa; }

.border-l-white-bis {
  border-left: 1px solid #fafafa; }

.border-l-white-bis-dotted {
  border-left: 1px dotted #fafafa; }

.border-l-white-bis-dashed {
  border-left: 1px dashed #fafafa; }

.border-l-white-bis-solid {
  border-left: 1px solid #fafafa; }

.border-l-white-bis-double {
  border-left: 1px double #fafafa; }

.border-l-white-bis-groove {
  border-left: 1px groove #fafafa; }

.border-l-white-bis-ridge {
  border-left: 1px ridge #fafafa; }

.border-l-white-bis-inset {
  border-left: 1px inset #fafafa; }

.border-l-white-bis-outset {
  border-left: 1px outset #fafafa; }

.border-l-white-bis-none {
  border-left: 1px none #fafafa; }

.border-l-white-bis-hidden {
  border-left: 1px hidden #fafafa; }

.has-text-white {
  color: white; }

.has-background-white {
  background-color: white; }

.border-white {
  border: 1px solid white; }

.border-white-dotted {
  border: 1px dotted white; }

.border-white-dashed {
  border: 1px dashed white; }

.border-white-solid {
  border: 1px solid white; }

.border-white-double {
  border: 1px double white; }

.border-white-groove {
  border: 1px groove white; }

.border-white-ridge {
  border: 1px ridge white; }

.border-white-inset {
  border: 1px inset white; }

.border-white-outset {
  border: 1px outset white; }

.border-white-none {
  border: 1px none white; }

.border-white-hidden {
  border: 1px hidden white; }

.border-t-white {
  border-top: 1px solid white; }

.border-t-white-dotted {
  border-top: 1px dotted white; }

.border-t-white-dashed {
  border-top: 1px dashed white; }

.border-t-white-solid {
  border-top: 1px solid white; }

.border-t-white-double {
  border-top: 1px double white; }

.border-t-white-groove {
  border-top: 1px groove white; }

.border-t-white-ridge {
  border-top: 1px ridge white; }

.border-t-white-inset {
  border-top: 1px inset white; }

.border-t-white-outset {
  border-top: 1px outset white; }

.border-t-white-none {
  border-top: 1px none white; }

.border-t-white-hidden {
  border-top: 1px hidden white; }

.border-r-white {
  border-right: 1px solid white; }

.border-r-white-dotted {
  border-right: 1px dotted white; }

.border-r-white-dashed {
  border-right: 1px dashed white; }

.border-r-white-solid {
  border-right: 1px solid white; }

.border-r-white-double {
  border-right: 1px double white; }

.border-r-white-groove {
  border-right: 1px groove white; }

.border-r-white-ridge {
  border-right: 1px ridge white; }

.border-r-white-inset {
  border-right: 1px inset white; }

.border-r-white-outset {
  border-right: 1px outset white; }

.border-r-white-none {
  border-right: 1px none white; }

.border-r-white-hidden {
  border-right: 1px hidden white; }

.border-b-white {
  border-bottom: 1px solid white; }

.border-b-white-dotted {
  border-bottom: 1px dotted white; }

.border-b-white-dashed {
  border-bottom: 1px dashed white; }

.border-b-white-solid {
  border-bottom: 1px solid white; }

.border-b-white-double {
  border-bottom: 1px double white; }

.border-b-white-groove {
  border-bottom: 1px groove white; }

.border-b-white-ridge {
  border-bottom: 1px ridge white; }

.border-b-white-inset {
  border-bottom: 1px inset white; }

.border-b-white-outset {
  border-bottom: 1px outset white; }

.border-b-white-none {
  border-bottom: 1px none white; }

.border-b-white-hidden {
  border-bottom: 1px hidden white; }

.border-l-white {
  border-left: 1px solid white; }

.border-l-white-dotted {
  border-left: 1px dotted white; }

.border-l-white-dashed {
  border-left: 1px dashed white; }

.border-l-white-solid {
  border-left: 1px solid white; }

.border-l-white-double {
  border-left: 1px double white; }

.border-l-white-groove {
  border-left: 1px groove white; }

.border-l-white-ridge {
  border-left: 1px ridge white; }

.border-l-white-inset {
  border-left: 1px inset white; }

.border-l-white-outset {
  border-left: 1px outset white; }

.border-l-white-none {
  border-left: 1px none white; }

.border-l-white-hidden {
  border-left: 1px hidden white; }

@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px); }
  60% {
    opacity: 1;
    transform: translateX(-30px); }
  80% {
    transform: translateX(10px); }
  100% {
    transform: translateX(0); } }

@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px); }
  60% {
    opacity: 1;
    transform: translateX(-30px); }
  80% {
    transform: translateX(10px); }
  100% {
    transform: translateX(0); } }

@-webkit-keyframes slideIn {
  0% {
    width: 0%; }
  100% {
    width: 100%; } }

@keyframes slideIn {
  0% {
    width: 0%; }
  100% {
    width: 100%; } }

@-webkit-keyframes slideOut {
  0% {
    width: 100%; }
  100% {
    width: 0%; } }

@keyframes slideOut {
  0% {
    width: 100%; }
  100% {
    width: 0%; } }

@-webkit-keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes growth {
  0% {
    width: 48px;
    height: 48px; }
  100% {
    width: 320px;
    height: 568px; } }

@keyframes growth {
  0% {
    width: 48px;
    height: 48px; }
  100% {
    width: 320px;
    height: 568px; } }

@-webkit-keyframes slideDown {
  0% {
    max-height: 0px; }
  100% {
    max-height: 100vh; } }

@keyframes slideDown {
  0% {
    max-height: 0px; }
  100% {
    max-height: 100vh; } }

.animation-bounceInRight {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-delay: initial;
  animation-delay: 0s;
  animation-delay: initial; }

.animation-slideDown {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: slideDown;
  animation-name: slideDown;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-delay: initial;
  animation-delay: 0s;
  animation-delay: initial; }

.animation-slideDownFast {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: slideDown;
  animation-name: slideDown;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-delay: initial;
  animation-delay: 0s;
  animation-delay: initial; }

.animation-slideUp {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: slideDown;
  animation-name: slideDown;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-delay: initial;
  animation-delay: 0s;
  animation-delay: initial;
  overflow: hidden; }

.animation-slideIn {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-delay: initial;
  animation-delay: 0s;
  animation-delay: initial; }

.animation-sliteOut {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: slideOut;
  animation-name: slideOut;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-delay: initial;
  animation-delay: 0s;
  animation-delay: initial; }

.animation-spin {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: spin;
  animation-name: spin;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-delay: initial;
  animation-delay: 0s;
  animation-delay: initial; }

.animation-fadeIn {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
  -webkit-animation-delay: initial;
  animation-delay: 0s;
  animation-delay: initial; }

.animation-fadeInFast {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-delay: initial;
  animation-delay: 0s;
  animation-delay: initial; }

.animation-growth {
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: growth;
  animation-name: growth;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
  -webkit-animation-delay: initial;
  animation-delay: 0s;
  animation-delay: initial; }

.turn-90 {
  transition: .5s ease;
  border: none; }
  .turn-90::after {
    transform: scale(0.8);
    transition: all 0.3s ease; }
  .turn-90:hover {
    transform: rotate(90deg);
    transition: all 0.3s ease; }

.turn-360 {
  transition: .8s ease;
  border: none; }
  .turn-360::after {
    transform: scale(0.8);
    transition: all 0.5s ease; }
  .turn-360:hover {
    transform: rotate(360deg);
    transition: all 0.5s ease; }

.subBtn {
  cursor: pointer;
  color: #7a7ab8;
  background: white; }
  .subBtn.is-active {
    color: white;
    background: #7a7ab8; }

.sidebar {
  transition: 0.2s ease;
  width: 55px; }
  .sidebar:hover {
    width: 180px; }
  .sidebar.fix {
    transition: none;
    width: 180px; }
  .sidebar > .subBtn > .subLabel {
    display: none; }
  .sidebar:hover > .subBtn > .subLabel {
    display: inherit; }
  @media screen and (min-width: 1124px) {
    .sidebar {
      width: 180px; }
      .sidebar > .subBtn > .subLabel {
        display: inherit; } }

.board {
  width: 1024px;
  height: 100%;
  margin-left: auto;
  margin-right: auto; }
  @media only screen and (max-width: 1124px) {
    .board {
      width: 100%; } }
  @media only screen and (min-width: 1124px) {
    .board {
      width: 90%; } }

.pagination {
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: space-between; }

.m-none {
  margin: 0; }

.p-none {
  padding: 0; }

.m-t-none {
  margin-top: 0; }

.p-t-none {
  padding-top: 0; }

.m-r-none {
  margin-right: 0; }

.p-r-none {
  padding-right: 0; }

.m-b-none {
  margin-bottom: 0; }

.p-b-none {
  padding-bottom: 0; }

.m-l-none {
  margin-left: 0; }

.p-l-none {
  padding-left: 0; }

.m-xxs {
  margin: 0.125rem; }

.p-xxs {
  padding: 0.125rem; }

.m-t-xxs {
  margin-top: 0.125rem; }

.p-t-xxs {
  padding-top: 0.125rem; }

.m-r-xxs {
  margin-right: 0.125rem; }

.p-r-xxs {
  padding-right: 0.125rem; }

.m-b-xxs {
  margin-bottom: 0.125rem; }

.p-b-xxs {
  padding-bottom: 0.125rem; }

.m-l-xxs {
  margin-left: 0.125rem; }

.p-l-xxs {
  padding-left: 0.125rem; }

.m-xs {
  margin: 0.25rem; }

.p-xs {
  padding: 0.25rem; }

.m-t-xs {
  margin-top: 0.25rem; }

.p-t-xs {
  padding-top: 0.25rem; }

.m-r-xs {
  margin-right: 0.25rem; }

.p-r-xs {
  padding-right: 0.25rem; }

.m-b-xs {
  margin-bottom: 0.25rem; }

.p-b-xs {
  padding-bottom: 0.25rem; }

.m-l-xs {
  margin-left: 0.25rem; }

.p-l-xs {
  padding-left: 0.25rem; }

.m-sm {
  margin: 0.6rem; }

.p-sm {
  padding: 0.6rem; }

.m-t-sm {
  margin-top: 0.6rem; }

.p-t-sm {
  padding-top: 0.6rem; }

.m-r-sm {
  margin-right: 0.6rem; }

.p-r-sm {
  padding-right: 0.6rem; }

.m-b-sm {
  margin-bottom: 0.6rem; }

.p-b-sm {
  padding-bottom: 0.6rem; }

.m-l-sm {
  margin-left: 0.6rem; }

.p-l-sm {
  padding-left: 0.6rem; }

.m-md {
  margin: 1rem; }

.p-md {
  padding: 1rem; }

.m-t-md {
  margin-top: 1rem; }

.p-t-md {
  padding-top: 1rem; }

.m-r-md {
  margin-right: 1rem; }

.p-r-md {
  padding-right: 1rem; }

.m-b-md {
  margin-bottom: 1rem; }

.p-b-md {
  padding-bottom: 1rem; }

.m-l-md {
  margin-left: 1rem; }

.p-l-md {
  padding-left: 1rem; }

.m-lg {
  margin: 2rem; }

.p-lg {
  padding: 2rem; }

.m-t-lg {
  margin-top: 2rem; }

.p-t-lg {
  padding-top: 2rem; }

.m-r-lg {
  margin-right: 2rem; }

.p-r-lg {
  padding-right: 2rem; }

.m-b-lg {
  margin-bottom: 2rem; }

.p-b-lg {
  padding-bottom: 2rem; }

.m-l-lg {
  margin-left: 2rem; }

.p-l-lg {
  padding-left: 2rem; }

.m-xl {
  margin: 4rem; }

.p-xl {
  padding: 4rem; }

.m-t-xl {
  margin-top: 4rem; }

.p-t-xl {
  padding-top: 4rem; }

.m-r-xl {
  margin-right: 4rem; }

.p-r-xl {
  padding-right: 4rem; }

.m-b-xl {
  margin-bottom: 4rem; }

.p-b-xl {
  padding-bottom: 4rem; }

.m-l-xl {
  margin-left: 4rem; }

.p-l-xl {
  padding-left: 4rem; }

.m-xxl {
  margin: 8rem; }

.p-xxl {
  padding: 8rem; }

.m-t-xxl {
  margin-top: 8rem; }

.p-t-xxl {
  padding-top: 8rem; }

.m-r-xxl {
  margin-right: 8rem; }

.p-r-xxl {
  padding-right: 8rem; }

.m-b-xxl {
  margin-bottom: 8rem; }

.p-b-xxl {
  padding-bottom: 8rem; }

.m-l-xxl {
  margin-left: 8rem; }

.p-l-xxl {
  padding-left: 8rem; }

.has-text-primary {
  color: #2d2f7b; }

.has-background-primary {
  background-color: #2d2f7b; }

.border-primary {
  border: 1px solid #2d2f7b; }

.border-m-primary:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #2d2f7b;
  transform: translateY(-50%); }

.border-primary-dotted {
  border: 1px dotted #2d2f7b; }

.border-primary-dashed {
  border: 1px dashed #2d2f7b; }

.border-primary-solid {
  border: 1px solid #2d2f7b; }

.border-primary-double {
  border: 1px double #2d2f7b; }

.border-primary-groove {
  border: 1px groove #2d2f7b; }

.border-primary-ridge {
  border: 1px ridge #2d2f7b; }

.border-primary-inset {
  border: 1px inset #2d2f7b; }

.border-primary-outset {
  border: 1px outset #2d2f7b; }

.border-primary-none {
  border: 1px none #2d2f7b; }

.border-primary-hidden {
  border: 1px hidden #2d2f7b; }

.border-t-primary {
  border-top: 1px solid #2d2f7b; }

.border-t-primary-dotted {
  border-top: 1px dotted #2d2f7b; }

.border-t-primary-dashed {
  border-top: 1px dashed #2d2f7b; }

.border-t-primary-solid {
  border-top: 1px solid #2d2f7b; }

.border-t-primary-double {
  border-top: 1px double #2d2f7b; }

.border-t-primary-groove {
  border-top: 1px groove #2d2f7b; }

.border-t-primary-ridge {
  border-top: 1px ridge #2d2f7b; }

.border-t-primary-inset {
  border-top: 1px inset #2d2f7b; }

.border-t-primary-outset {
  border-top: 1px outset #2d2f7b; }

.border-t-primary-none {
  border-top: 1px none #2d2f7b; }

.border-t-primary-hidden {
  border-top: 1px hidden #2d2f7b; }

.border-r-primary {
  border-right: 1px solid #2d2f7b; }

.border-r-primary-dotted {
  border-right: 1px dotted #2d2f7b; }

.border-r-primary-dashed {
  border-right: 1px dashed #2d2f7b; }

.border-r-primary-solid {
  border-right: 1px solid #2d2f7b; }

.border-r-primary-double {
  border-right: 1px double #2d2f7b; }

.border-r-primary-groove {
  border-right: 1px groove #2d2f7b; }

.border-r-primary-ridge {
  border-right: 1px ridge #2d2f7b; }

.border-r-primary-inset {
  border-right: 1px inset #2d2f7b; }

.border-r-primary-outset {
  border-right: 1px outset #2d2f7b; }

.border-r-primary-none {
  border-right: 1px none #2d2f7b; }

.border-r-primary-hidden {
  border-right: 1px hidden #2d2f7b; }

.border-b-primary {
  border-bottom: 1px solid #2d2f7b; }

.border-b-primary-dotted {
  border-bottom: 1px dotted #2d2f7b; }

.border-b-primary-dashed {
  border-bottom: 1px dashed #2d2f7b; }

.border-b-primary-solid {
  border-bottom: 1px solid #2d2f7b; }

.border-b-primary-double {
  border-bottom: 1px double #2d2f7b; }

.border-b-primary-groove {
  border-bottom: 1px groove #2d2f7b; }

.border-b-primary-ridge {
  border-bottom: 1px ridge #2d2f7b; }

.border-b-primary-inset {
  border-bottom: 1px inset #2d2f7b; }

.border-b-primary-outset {
  border-bottom: 1px outset #2d2f7b; }

.border-b-primary-none {
  border-bottom: 1px none #2d2f7b; }

.border-b-primary-hidden {
  border-bottom: 1px hidden #2d2f7b; }

.border-l-primary {
  border-left: 1px solid #2d2f7b; }

.border-l-primary-dotted {
  border-left: 1px dotted #2d2f7b; }

.border-l-primary-dashed {
  border-left: 1px dashed #2d2f7b; }

.border-l-primary-solid {
  border-left: 1px solid #2d2f7b; }

.border-l-primary-double {
  border-left: 1px double #2d2f7b; }

.border-l-primary-groove {
  border-left: 1px groove #2d2f7b; }

.border-l-primary-ridge {
  border-left: 1px ridge #2d2f7b; }

.border-l-primary-inset {
  border-left: 1px inset #2d2f7b; }

.border-l-primary-outset {
  border-left: 1px outset #2d2f7b; }

.border-l-primary-none {
  border-left: 1px none #2d2f7b; }

.border-l-primary-hidden {
  border-left: 1px hidden #2d2f7b; }

.has-text-primary-bis {
  color: #4d4db3; }

.has-background-primary-bis {
  background-color: #4d4db3; }

.border-primary-bis {
  border: 1px solid #4d4db3; }

.border-m-primary-bis:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #4d4db3;
  transform: translateY(-50%); }

.border-primary-bis-dotted {
  border: 1px dotted #4d4db3; }

.border-primary-bis-dashed {
  border: 1px dashed #4d4db3; }

.border-primary-bis-solid {
  border: 1px solid #4d4db3; }

.border-primary-bis-double {
  border: 1px double #4d4db3; }

.border-primary-bis-groove {
  border: 1px groove #4d4db3; }

.border-primary-bis-ridge {
  border: 1px ridge #4d4db3; }

.border-primary-bis-inset {
  border: 1px inset #4d4db3; }

.border-primary-bis-outset {
  border: 1px outset #4d4db3; }

.border-primary-bis-none {
  border: 1px none #4d4db3; }

.border-primary-bis-hidden {
  border: 1px hidden #4d4db3; }

.border-t-primary-bis {
  border-top: 1px solid #4d4db3; }

.border-t-primary-bis-dotted {
  border-top: 1px dotted #4d4db3; }

.border-t-primary-bis-dashed {
  border-top: 1px dashed #4d4db3; }

.border-t-primary-bis-solid {
  border-top: 1px solid #4d4db3; }

.border-t-primary-bis-double {
  border-top: 1px double #4d4db3; }

.border-t-primary-bis-groove {
  border-top: 1px groove #4d4db3; }

.border-t-primary-bis-ridge {
  border-top: 1px ridge #4d4db3; }

.border-t-primary-bis-inset {
  border-top: 1px inset #4d4db3; }

.border-t-primary-bis-outset {
  border-top: 1px outset #4d4db3; }

.border-t-primary-bis-none {
  border-top: 1px none #4d4db3; }

.border-t-primary-bis-hidden {
  border-top: 1px hidden #4d4db3; }

.border-r-primary-bis {
  border-right: 1px solid #4d4db3; }

.border-r-primary-bis-dotted {
  border-right: 1px dotted #4d4db3; }

.border-r-primary-bis-dashed {
  border-right: 1px dashed #4d4db3; }

.border-r-primary-bis-solid {
  border-right: 1px solid #4d4db3; }

.border-r-primary-bis-double {
  border-right: 1px double #4d4db3; }

.border-r-primary-bis-groove {
  border-right: 1px groove #4d4db3; }

.border-r-primary-bis-ridge {
  border-right: 1px ridge #4d4db3; }

.border-r-primary-bis-inset {
  border-right: 1px inset #4d4db3; }

.border-r-primary-bis-outset {
  border-right: 1px outset #4d4db3; }

.border-r-primary-bis-none {
  border-right: 1px none #4d4db3; }

.border-r-primary-bis-hidden {
  border-right: 1px hidden #4d4db3; }

.border-b-primary-bis {
  border-bottom: 1px solid #4d4db3; }

.border-b-primary-bis-dotted {
  border-bottom: 1px dotted #4d4db3; }

.border-b-primary-bis-dashed {
  border-bottom: 1px dashed #4d4db3; }

.border-b-primary-bis-solid {
  border-bottom: 1px solid #4d4db3; }

.border-b-primary-bis-double {
  border-bottom: 1px double #4d4db3; }

.border-b-primary-bis-groove {
  border-bottom: 1px groove #4d4db3; }

.border-b-primary-bis-ridge {
  border-bottom: 1px ridge #4d4db3; }

.border-b-primary-bis-inset {
  border-bottom: 1px inset #4d4db3; }

.border-b-primary-bis-outset {
  border-bottom: 1px outset #4d4db3; }

.border-b-primary-bis-none {
  border-bottom: 1px none #4d4db3; }

.border-b-primary-bis-hidden {
  border-bottom: 1px hidden #4d4db3; }

.border-l-primary-bis {
  border-left: 1px solid #4d4db3; }

.border-l-primary-bis-dotted {
  border-left: 1px dotted #4d4db3; }

.border-l-primary-bis-dashed {
  border-left: 1px dashed #4d4db3; }

.border-l-primary-bis-solid {
  border-left: 1px solid #4d4db3; }

.border-l-primary-bis-double {
  border-left: 1px double #4d4db3; }

.border-l-primary-bis-groove {
  border-left: 1px groove #4d4db3; }

.border-l-primary-bis-ridge {
  border-left: 1px ridge #4d4db3; }

.border-l-primary-bis-inset {
  border-left: 1px inset #4d4db3; }

.border-l-primary-bis-outset {
  border-left: 1px outset #4d4db3; }

.border-l-primary-bis-none {
  border-left: 1px none #4d4db3; }

.border-l-primary-bis-hidden {
  border-left: 1px hidden #4d4db3; }

.has-text-primary-ter {
  color: #7a7ab8; }

.has-background-primary-ter {
  background-color: #7a7ab8; }

.border-primary-ter {
  border: 1px solid #7a7ab8; }

.border-m-primary-ter:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #7a7ab8;
  transform: translateY(-50%); }

.border-primary-ter-dotted {
  border: 1px dotted #7a7ab8; }

.border-primary-ter-dashed {
  border: 1px dashed #7a7ab8; }

.border-primary-ter-solid {
  border: 1px solid #7a7ab8; }

.border-primary-ter-double {
  border: 1px double #7a7ab8; }

.border-primary-ter-groove {
  border: 1px groove #7a7ab8; }

.border-primary-ter-ridge {
  border: 1px ridge #7a7ab8; }

.border-primary-ter-inset {
  border: 1px inset #7a7ab8; }

.border-primary-ter-outset {
  border: 1px outset #7a7ab8; }

.border-primary-ter-none {
  border: 1px none #7a7ab8; }

.border-primary-ter-hidden {
  border: 1px hidden #7a7ab8; }

.border-t-primary-ter {
  border-top: 1px solid #7a7ab8; }

.border-t-primary-ter-dotted {
  border-top: 1px dotted #7a7ab8; }

.border-t-primary-ter-dashed {
  border-top: 1px dashed #7a7ab8; }

.border-t-primary-ter-solid {
  border-top: 1px solid #7a7ab8; }

.border-t-primary-ter-double {
  border-top: 1px double #7a7ab8; }

.border-t-primary-ter-groove {
  border-top: 1px groove #7a7ab8; }

.border-t-primary-ter-ridge {
  border-top: 1px ridge #7a7ab8; }

.border-t-primary-ter-inset {
  border-top: 1px inset #7a7ab8; }

.border-t-primary-ter-outset {
  border-top: 1px outset #7a7ab8; }

.border-t-primary-ter-none {
  border-top: 1px none #7a7ab8; }

.border-t-primary-ter-hidden {
  border-top: 1px hidden #7a7ab8; }

.border-r-primary-ter {
  border-right: 1px solid #7a7ab8; }

.border-r-primary-ter-dotted {
  border-right: 1px dotted #7a7ab8; }

.border-r-primary-ter-dashed {
  border-right: 1px dashed #7a7ab8; }

.border-r-primary-ter-solid {
  border-right: 1px solid #7a7ab8; }

.border-r-primary-ter-double {
  border-right: 1px double #7a7ab8; }

.border-r-primary-ter-groove {
  border-right: 1px groove #7a7ab8; }

.border-r-primary-ter-ridge {
  border-right: 1px ridge #7a7ab8; }

.border-r-primary-ter-inset {
  border-right: 1px inset #7a7ab8; }

.border-r-primary-ter-outset {
  border-right: 1px outset #7a7ab8; }

.border-r-primary-ter-none {
  border-right: 1px none #7a7ab8; }

.border-r-primary-ter-hidden {
  border-right: 1px hidden #7a7ab8; }

.border-b-primary-ter {
  border-bottom: 1px solid #7a7ab8; }

.border-b-primary-ter-dotted {
  border-bottom: 1px dotted #7a7ab8; }

.border-b-primary-ter-dashed {
  border-bottom: 1px dashed #7a7ab8; }

.border-b-primary-ter-solid {
  border-bottom: 1px solid #7a7ab8; }

.border-b-primary-ter-double {
  border-bottom: 1px double #7a7ab8; }

.border-b-primary-ter-groove {
  border-bottom: 1px groove #7a7ab8; }

.border-b-primary-ter-ridge {
  border-bottom: 1px ridge #7a7ab8; }

.border-b-primary-ter-inset {
  border-bottom: 1px inset #7a7ab8; }

.border-b-primary-ter-outset {
  border-bottom: 1px outset #7a7ab8; }

.border-b-primary-ter-none {
  border-bottom: 1px none #7a7ab8; }

.border-b-primary-ter-hidden {
  border-bottom: 1px hidden #7a7ab8; }

.border-l-primary-ter {
  border-left: 1px solid #7a7ab8; }

.border-l-primary-ter-dotted {
  border-left: 1px dotted #7a7ab8; }

.border-l-primary-ter-dashed {
  border-left: 1px dashed #7a7ab8; }

.border-l-primary-ter-solid {
  border-left: 1px solid #7a7ab8; }

.border-l-primary-ter-double {
  border-left: 1px double #7a7ab8; }

.border-l-primary-ter-groove {
  border-left: 1px groove #7a7ab8; }

.border-l-primary-ter-ridge {
  border-left: 1px ridge #7a7ab8; }

.border-l-primary-ter-inset {
  border-left: 1px inset #7a7ab8; }

.border-l-primary-ter-outset {
  border-left: 1px outset #7a7ab8; }

.border-l-primary-ter-none {
  border-left: 1px none #7a7ab8; }

.border-l-primary-ter-hidden {
  border-left: 1px hidden #7a7ab8; }

.has-text-primary-qua {
  color: #b9b9e9; }

.has-background-primary-qua {
  background-color: #b9b9e9; }

.border-primary-qua {
  border: 1px solid #b9b9e9; }

.border-m-primary-qua:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b9b9e9;
  transform: translateY(-50%); }

.border-primary-qua-dotted {
  border: 1px dotted #b9b9e9; }

.border-primary-qua-dashed {
  border: 1px dashed #b9b9e9; }

.border-primary-qua-solid {
  border: 1px solid #b9b9e9; }

.border-primary-qua-double {
  border: 1px double #b9b9e9; }

.border-primary-qua-groove {
  border: 1px groove #b9b9e9; }

.border-primary-qua-ridge {
  border: 1px ridge #b9b9e9; }

.border-primary-qua-inset {
  border: 1px inset #b9b9e9; }

.border-primary-qua-outset {
  border: 1px outset #b9b9e9; }

.border-primary-qua-none {
  border: 1px none #b9b9e9; }

.border-primary-qua-hidden {
  border: 1px hidden #b9b9e9; }

.border-t-primary-qua {
  border-top: 1px solid #b9b9e9; }

.border-t-primary-qua-dotted {
  border-top: 1px dotted #b9b9e9; }

.border-t-primary-qua-dashed {
  border-top: 1px dashed #b9b9e9; }

.border-t-primary-qua-solid {
  border-top: 1px solid #b9b9e9; }

.border-t-primary-qua-double {
  border-top: 1px double #b9b9e9; }

.border-t-primary-qua-groove {
  border-top: 1px groove #b9b9e9; }

.border-t-primary-qua-ridge {
  border-top: 1px ridge #b9b9e9; }

.border-t-primary-qua-inset {
  border-top: 1px inset #b9b9e9; }

.border-t-primary-qua-outset {
  border-top: 1px outset #b9b9e9; }

.border-t-primary-qua-none {
  border-top: 1px none #b9b9e9; }

.border-t-primary-qua-hidden {
  border-top: 1px hidden #b9b9e9; }

.border-r-primary-qua {
  border-right: 1px solid #b9b9e9; }

.border-r-primary-qua-dotted {
  border-right: 1px dotted #b9b9e9; }

.border-r-primary-qua-dashed {
  border-right: 1px dashed #b9b9e9; }

.border-r-primary-qua-solid {
  border-right: 1px solid #b9b9e9; }

.border-r-primary-qua-double {
  border-right: 1px double #b9b9e9; }

.border-r-primary-qua-groove {
  border-right: 1px groove #b9b9e9; }

.border-r-primary-qua-ridge {
  border-right: 1px ridge #b9b9e9; }

.border-r-primary-qua-inset {
  border-right: 1px inset #b9b9e9; }

.border-r-primary-qua-outset {
  border-right: 1px outset #b9b9e9; }

.border-r-primary-qua-none {
  border-right: 1px none #b9b9e9; }

.border-r-primary-qua-hidden {
  border-right: 1px hidden #b9b9e9; }

.border-b-primary-qua {
  border-bottom: 1px solid #b9b9e9; }

.border-b-primary-qua-dotted {
  border-bottom: 1px dotted #b9b9e9; }

.border-b-primary-qua-dashed {
  border-bottom: 1px dashed #b9b9e9; }

.border-b-primary-qua-solid {
  border-bottom: 1px solid #b9b9e9; }

.border-b-primary-qua-double {
  border-bottom: 1px double #b9b9e9; }

.border-b-primary-qua-groove {
  border-bottom: 1px groove #b9b9e9; }

.border-b-primary-qua-ridge {
  border-bottom: 1px ridge #b9b9e9; }

.border-b-primary-qua-inset {
  border-bottom: 1px inset #b9b9e9; }

.border-b-primary-qua-outset {
  border-bottom: 1px outset #b9b9e9; }

.border-b-primary-qua-none {
  border-bottom: 1px none #b9b9e9; }

.border-b-primary-qua-hidden {
  border-bottom: 1px hidden #b9b9e9; }

.border-l-primary-qua {
  border-left: 1px solid #b9b9e9; }

.border-l-primary-qua-dotted {
  border-left: 1px dotted #b9b9e9; }

.border-l-primary-qua-dashed {
  border-left: 1px dashed #b9b9e9; }

.border-l-primary-qua-solid {
  border-left: 1px solid #b9b9e9; }

.border-l-primary-qua-double {
  border-left: 1px double #b9b9e9; }

.border-l-primary-qua-groove {
  border-left: 1px groove #b9b9e9; }

.border-l-primary-qua-ridge {
  border-left: 1px ridge #b9b9e9; }

.border-l-primary-qua-inset {
  border-left: 1px inset #b9b9e9; }

.border-l-primary-qua-outset {
  border-left: 1px outset #b9b9e9; }

.border-l-primary-qua-none {
  border-left: 1px none #b9b9e9; }

.border-l-primary-qua-hidden {
  border-left: 1px hidden #b9b9e9; }

.has-text-primary-qui {
  color: #b8b8d6; }

.has-background-primary-qui {
  background-color: #b8b8d6; }

.border-primary-qui {
  border: 1px solid #b8b8d6; }

.border-m-primary-qui:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b8b8d6;
  transform: translateY(-50%); }

.border-primary-qui-dotted {
  border: 1px dotted #b8b8d6; }

.border-primary-qui-dashed {
  border: 1px dashed #b8b8d6; }

.border-primary-qui-solid {
  border: 1px solid #b8b8d6; }

.border-primary-qui-double {
  border: 1px double #b8b8d6; }

.border-primary-qui-groove {
  border: 1px groove #b8b8d6; }

.border-primary-qui-ridge {
  border: 1px ridge #b8b8d6; }

.border-primary-qui-inset {
  border: 1px inset #b8b8d6; }

.border-primary-qui-outset {
  border: 1px outset #b8b8d6; }

.border-primary-qui-none {
  border: 1px none #b8b8d6; }

.border-primary-qui-hidden {
  border: 1px hidden #b8b8d6; }

.border-t-primary-qui {
  border-top: 1px solid #b8b8d6; }

.border-t-primary-qui-dotted {
  border-top: 1px dotted #b8b8d6; }

.border-t-primary-qui-dashed {
  border-top: 1px dashed #b8b8d6; }

.border-t-primary-qui-solid {
  border-top: 1px solid #b8b8d6; }

.border-t-primary-qui-double {
  border-top: 1px double #b8b8d6; }

.border-t-primary-qui-groove {
  border-top: 1px groove #b8b8d6; }

.border-t-primary-qui-ridge {
  border-top: 1px ridge #b8b8d6; }

.border-t-primary-qui-inset {
  border-top: 1px inset #b8b8d6; }

.border-t-primary-qui-outset {
  border-top: 1px outset #b8b8d6; }

.border-t-primary-qui-none {
  border-top: 1px none #b8b8d6; }

.border-t-primary-qui-hidden {
  border-top: 1px hidden #b8b8d6; }

.border-r-primary-qui {
  border-right: 1px solid #b8b8d6; }

.border-r-primary-qui-dotted {
  border-right: 1px dotted #b8b8d6; }

.border-r-primary-qui-dashed {
  border-right: 1px dashed #b8b8d6; }

.border-r-primary-qui-solid {
  border-right: 1px solid #b8b8d6; }

.border-r-primary-qui-double {
  border-right: 1px double #b8b8d6; }

.border-r-primary-qui-groove {
  border-right: 1px groove #b8b8d6; }

.border-r-primary-qui-ridge {
  border-right: 1px ridge #b8b8d6; }

.border-r-primary-qui-inset {
  border-right: 1px inset #b8b8d6; }

.border-r-primary-qui-outset {
  border-right: 1px outset #b8b8d6; }

.border-r-primary-qui-none {
  border-right: 1px none #b8b8d6; }

.border-r-primary-qui-hidden {
  border-right: 1px hidden #b8b8d6; }

.border-b-primary-qui {
  border-bottom: 1px solid #b8b8d6; }

.border-b-primary-qui-dotted {
  border-bottom: 1px dotted #b8b8d6; }

.border-b-primary-qui-dashed {
  border-bottom: 1px dashed #b8b8d6; }

.border-b-primary-qui-solid {
  border-bottom: 1px solid #b8b8d6; }

.border-b-primary-qui-double {
  border-bottom: 1px double #b8b8d6; }

.border-b-primary-qui-groove {
  border-bottom: 1px groove #b8b8d6; }

.border-b-primary-qui-ridge {
  border-bottom: 1px ridge #b8b8d6; }

.border-b-primary-qui-inset {
  border-bottom: 1px inset #b8b8d6; }

.border-b-primary-qui-outset {
  border-bottom: 1px outset #b8b8d6; }

.border-b-primary-qui-none {
  border-bottom: 1px none #b8b8d6; }

.border-b-primary-qui-hidden {
  border-bottom: 1px hidden #b8b8d6; }

.border-l-primary-qui {
  border-left: 1px solid #b8b8d6; }

.border-l-primary-qui-dotted {
  border-left: 1px dotted #b8b8d6; }

.border-l-primary-qui-dashed {
  border-left: 1px dashed #b8b8d6; }

.border-l-primary-qui-solid {
  border-left: 1px solid #b8b8d6; }

.border-l-primary-qui-double {
  border-left: 1px double #b8b8d6; }

.border-l-primary-qui-groove {
  border-left: 1px groove #b8b8d6; }

.border-l-primary-qui-ridge {
  border-left: 1px ridge #b8b8d6; }

.border-l-primary-qui-inset {
  border-left: 1px inset #b8b8d6; }

.border-l-primary-qui-outset {
  border-left: 1px outset #b8b8d6; }

.border-l-primary-qui-none {
  border-left: 1px none #b8b8d6; }

.border-l-primary-qui-hidden {
  border-left: 1px hidden #b8b8d6; }

.has-text-primary-light {
  color: #dbdbf0; }

.has-background-primary-light {
  background-color: #dbdbf0; }

.border-primary-light {
  border: 1px solid #dbdbf0; }

.border-m-primary-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dbdbf0;
  transform: translateY(-50%); }

.border-primary-light-dotted {
  border: 1px dotted #dbdbf0; }

.border-primary-light-dashed {
  border: 1px dashed #dbdbf0; }

.border-primary-light-solid {
  border: 1px solid #dbdbf0; }

.border-primary-light-double {
  border: 1px double #dbdbf0; }

.border-primary-light-groove {
  border: 1px groove #dbdbf0; }

.border-primary-light-ridge {
  border: 1px ridge #dbdbf0; }

.border-primary-light-inset {
  border: 1px inset #dbdbf0; }

.border-primary-light-outset {
  border: 1px outset #dbdbf0; }

.border-primary-light-none {
  border: 1px none #dbdbf0; }

.border-primary-light-hidden {
  border: 1px hidden #dbdbf0; }

.border-t-primary-light {
  border-top: 1px solid #dbdbf0; }

.border-t-primary-light-dotted {
  border-top: 1px dotted #dbdbf0; }

.border-t-primary-light-dashed {
  border-top: 1px dashed #dbdbf0; }

.border-t-primary-light-solid {
  border-top: 1px solid #dbdbf0; }

.border-t-primary-light-double {
  border-top: 1px double #dbdbf0; }

.border-t-primary-light-groove {
  border-top: 1px groove #dbdbf0; }

.border-t-primary-light-ridge {
  border-top: 1px ridge #dbdbf0; }

.border-t-primary-light-inset {
  border-top: 1px inset #dbdbf0; }

.border-t-primary-light-outset {
  border-top: 1px outset #dbdbf0; }

.border-t-primary-light-none {
  border-top: 1px none #dbdbf0; }

.border-t-primary-light-hidden {
  border-top: 1px hidden #dbdbf0; }

.border-r-primary-light {
  border-right: 1px solid #dbdbf0; }

.border-r-primary-light-dotted {
  border-right: 1px dotted #dbdbf0; }

.border-r-primary-light-dashed {
  border-right: 1px dashed #dbdbf0; }

.border-r-primary-light-solid {
  border-right: 1px solid #dbdbf0; }

.border-r-primary-light-double {
  border-right: 1px double #dbdbf0; }

.border-r-primary-light-groove {
  border-right: 1px groove #dbdbf0; }

.border-r-primary-light-ridge {
  border-right: 1px ridge #dbdbf0; }

.border-r-primary-light-inset {
  border-right: 1px inset #dbdbf0; }

.border-r-primary-light-outset {
  border-right: 1px outset #dbdbf0; }

.border-r-primary-light-none {
  border-right: 1px none #dbdbf0; }

.border-r-primary-light-hidden {
  border-right: 1px hidden #dbdbf0; }

.border-b-primary-light {
  border-bottom: 1px solid #dbdbf0; }

.border-b-primary-light-dotted {
  border-bottom: 1px dotted #dbdbf0; }

.border-b-primary-light-dashed {
  border-bottom: 1px dashed #dbdbf0; }

.border-b-primary-light-solid {
  border-bottom: 1px solid #dbdbf0; }

.border-b-primary-light-double {
  border-bottom: 1px double #dbdbf0; }

.border-b-primary-light-groove {
  border-bottom: 1px groove #dbdbf0; }

.border-b-primary-light-ridge {
  border-bottom: 1px ridge #dbdbf0; }

.border-b-primary-light-inset {
  border-bottom: 1px inset #dbdbf0; }

.border-b-primary-light-outset {
  border-bottom: 1px outset #dbdbf0; }

.border-b-primary-light-none {
  border-bottom: 1px none #dbdbf0; }

.border-b-primary-light-hidden {
  border-bottom: 1px hidden #dbdbf0; }

.border-l-primary-light {
  border-left: 1px solid #dbdbf0; }

.border-l-primary-light-dotted {
  border-left: 1px dotted #dbdbf0; }

.border-l-primary-light-dashed {
  border-left: 1px dashed #dbdbf0; }

.border-l-primary-light-solid {
  border-left: 1px solid #dbdbf0; }

.border-l-primary-light-double {
  border-left: 1px double #dbdbf0; }

.border-l-primary-light-groove {
  border-left: 1px groove #dbdbf0; }

.border-l-primary-light-ridge {
  border-left: 1px ridge #dbdbf0; }

.border-l-primary-light-inset {
  border-left: 1px inset #dbdbf0; }

.border-l-primary-light-outset {
  border-left: 1px outset #dbdbf0; }

.border-l-primary-light-none {
  border-left: 1px none #dbdbf0; }

.border-l-primary-light-hidden {
  border-left: 1px hidden #dbdbf0; }

.has-text-primary-lighter {
  color: #ededf7; }

.has-background-primary-lighter {
  background-color: #ededf7; }

.border-primary-lighter {
  border: 1px solid #ededf7; }

.border-m-primary-lighter:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ededf7;
  transform: translateY(-50%); }

.border-primary-lighter-dotted {
  border: 1px dotted #ededf7; }

.border-primary-lighter-dashed {
  border: 1px dashed #ededf7; }

.border-primary-lighter-solid {
  border: 1px solid #ededf7; }

.border-primary-lighter-double {
  border: 1px double #ededf7; }

.border-primary-lighter-groove {
  border: 1px groove #ededf7; }

.border-primary-lighter-ridge {
  border: 1px ridge #ededf7; }

.border-primary-lighter-inset {
  border: 1px inset #ededf7; }

.border-primary-lighter-outset {
  border: 1px outset #ededf7; }

.border-primary-lighter-none {
  border: 1px none #ededf7; }

.border-primary-lighter-hidden {
  border: 1px hidden #ededf7; }

.border-t-primary-lighter {
  border-top: 1px solid #ededf7; }

.border-t-primary-lighter-dotted {
  border-top: 1px dotted #ededf7; }

.border-t-primary-lighter-dashed {
  border-top: 1px dashed #ededf7; }

.border-t-primary-lighter-solid {
  border-top: 1px solid #ededf7; }

.border-t-primary-lighter-double {
  border-top: 1px double #ededf7; }

.border-t-primary-lighter-groove {
  border-top: 1px groove #ededf7; }

.border-t-primary-lighter-ridge {
  border-top: 1px ridge #ededf7; }

.border-t-primary-lighter-inset {
  border-top: 1px inset #ededf7; }

.border-t-primary-lighter-outset {
  border-top: 1px outset #ededf7; }

.border-t-primary-lighter-none {
  border-top: 1px none #ededf7; }

.border-t-primary-lighter-hidden {
  border-top: 1px hidden #ededf7; }

.border-r-primary-lighter {
  border-right: 1px solid #ededf7; }

.border-r-primary-lighter-dotted {
  border-right: 1px dotted #ededf7; }

.border-r-primary-lighter-dashed {
  border-right: 1px dashed #ededf7; }

.border-r-primary-lighter-solid {
  border-right: 1px solid #ededf7; }

.border-r-primary-lighter-double {
  border-right: 1px double #ededf7; }

.border-r-primary-lighter-groove {
  border-right: 1px groove #ededf7; }

.border-r-primary-lighter-ridge {
  border-right: 1px ridge #ededf7; }

.border-r-primary-lighter-inset {
  border-right: 1px inset #ededf7; }

.border-r-primary-lighter-outset {
  border-right: 1px outset #ededf7; }

.border-r-primary-lighter-none {
  border-right: 1px none #ededf7; }

.border-r-primary-lighter-hidden {
  border-right: 1px hidden #ededf7; }

.border-b-primary-lighter {
  border-bottom: 1px solid #ededf7; }

.border-b-primary-lighter-dotted {
  border-bottom: 1px dotted #ededf7; }

.border-b-primary-lighter-dashed {
  border-bottom: 1px dashed #ededf7; }

.border-b-primary-lighter-solid {
  border-bottom: 1px solid #ededf7; }

.border-b-primary-lighter-double {
  border-bottom: 1px double #ededf7; }

.border-b-primary-lighter-groove {
  border-bottom: 1px groove #ededf7; }

.border-b-primary-lighter-ridge {
  border-bottom: 1px ridge #ededf7; }

.border-b-primary-lighter-inset {
  border-bottom: 1px inset #ededf7; }

.border-b-primary-lighter-outset {
  border-bottom: 1px outset #ededf7; }

.border-b-primary-lighter-none {
  border-bottom: 1px none #ededf7; }

.border-b-primary-lighter-hidden {
  border-bottom: 1px hidden #ededf7; }

.border-l-primary-lighter {
  border-left: 1px solid #ededf7; }

.border-l-primary-lighter-dotted {
  border-left: 1px dotted #ededf7; }

.border-l-primary-lighter-dashed {
  border-left: 1px dashed #ededf7; }

.border-l-primary-lighter-solid {
  border-left: 1px solid #ededf7; }

.border-l-primary-lighter-double {
  border-left: 1px double #ededf7; }

.border-l-primary-lighter-groove {
  border-left: 1px groove #ededf7; }

.border-l-primary-lighter-ridge {
  border-left: 1px ridge #ededf7; }

.border-l-primary-lighter-inset {
  border-left: 1px inset #ededf7; }

.border-l-primary-lighter-outset {
  border-left: 1px outset #ededf7; }

.border-l-primary-lighter-none {
  border-left: 1px none #ededf7; }

.border-l-primary-lighter-hidden {
  border-left: 1px hidden #ededf7; }

.has-text-primary-lighbis {
  color: #f4f4fa; }

.has-background-primary-lighbis {
  background-color: #f4f4fa; }

.border-primary-lighbis {
  border: 1px solid #f4f4fa; }

.border-m-primary-lighbis:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f4f4fa;
  transform: translateY(-50%); }

.border-primary-lighbis-dotted {
  border: 1px dotted #f4f4fa; }

.border-primary-lighbis-dashed {
  border: 1px dashed #f4f4fa; }

.border-primary-lighbis-solid {
  border: 1px solid #f4f4fa; }

.border-primary-lighbis-double {
  border: 1px double #f4f4fa; }

.border-primary-lighbis-groove {
  border: 1px groove #f4f4fa; }

.border-primary-lighbis-ridge {
  border: 1px ridge #f4f4fa; }

.border-primary-lighbis-inset {
  border: 1px inset #f4f4fa; }

.border-primary-lighbis-outset {
  border: 1px outset #f4f4fa; }

.border-primary-lighbis-none {
  border: 1px none #f4f4fa; }

.border-primary-lighbis-hidden {
  border: 1px hidden #f4f4fa; }

.border-t-primary-lighbis {
  border-top: 1px solid #f4f4fa; }

.border-t-primary-lighbis-dotted {
  border-top: 1px dotted #f4f4fa; }

.border-t-primary-lighbis-dashed {
  border-top: 1px dashed #f4f4fa; }

.border-t-primary-lighbis-solid {
  border-top: 1px solid #f4f4fa; }

.border-t-primary-lighbis-double {
  border-top: 1px double #f4f4fa; }

.border-t-primary-lighbis-groove {
  border-top: 1px groove #f4f4fa; }

.border-t-primary-lighbis-ridge {
  border-top: 1px ridge #f4f4fa; }

.border-t-primary-lighbis-inset {
  border-top: 1px inset #f4f4fa; }

.border-t-primary-lighbis-outset {
  border-top: 1px outset #f4f4fa; }

.border-t-primary-lighbis-none {
  border-top: 1px none #f4f4fa; }

.border-t-primary-lighbis-hidden {
  border-top: 1px hidden #f4f4fa; }

.border-r-primary-lighbis {
  border-right: 1px solid #f4f4fa; }

.border-r-primary-lighbis-dotted {
  border-right: 1px dotted #f4f4fa; }

.border-r-primary-lighbis-dashed {
  border-right: 1px dashed #f4f4fa; }

.border-r-primary-lighbis-solid {
  border-right: 1px solid #f4f4fa; }

.border-r-primary-lighbis-double {
  border-right: 1px double #f4f4fa; }

.border-r-primary-lighbis-groove {
  border-right: 1px groove #f4f4fa; }

.border-r-primary-lighbis-ridge {
  border-right: 1px ridge #f4f4fa; }

.border-r-primary-lighbis-inset {
  border-right: 1px inset #f4f4fa; }

.border-r-primary-lighbis-outset {
  border-right: 1px outset #f4f4fa; }

.border-r-primary-lighbis-none {
  border-right: 1px none #f4f4fa; }

.border-r-primary-lighbis-hidden {
  border-right: 1px hidden #f4f4fa; }

.border-b-primary-lighbis {
  border-bottom: 1px solid #f4f4fa; }

.border-b-primary-lighbis-dotted {
  border-bottom: 1px dotted #f4f4fa; }

.border-b-primary-lighbis-dashed {
  border-bottom: 1px dashed #f4f4fa; }

.border-b-primary-lighbis-solid {
  border-bottom: 1px solid #f4f4fa; }

.border-b-primary-lighbis-double {
  border-bottom: 1px double #f4f4fa; }

.border-b-primary-lighbis-groove {
  border-bottom: 1px groove #f4f4fa; }

.border-b-primary-lighbis-ridge {
  border-bottom: 1px ridge #f4f4fa; }

.border-b-primary-lighbis-inset {
  border-bottom: 1px inset #f4f4fa; }

.border-b-primary-lighbis-outset {
  border-bottom: 1px outset #f4f4fa; }

.border-b-primary-lighbis-none {
  border-bottom: 1px none #f4f4fa; }

.border-b-primary-lighbis-hidden {
  border-bottom: 1px hidden #f4f4fa; }

.border-l-primary-lighbis {
  border-left: 1px solid #f4f4fa; }

.border-l-primary-lighbis-dotted {
  border-left: 1px dotted #f4f4fa; }

.border-l-primary-lighbis-dashed {
  border-left: 1px dashed #f4f4fa; }

.border-l-primary-lighbis-solid {
  border-left: 1px solid #f4f4fa; }

.border-l-primary-lighbis-double {
  border-left: 1px double #f4f4fa; }

.border-l-primary-lighbis-groove {
  border-left: 1px groove #f4f4fa; }

.border-l-primary-lighbis-ridge {
  border-left: 1px ridge #f4f4fa; }

.border-l-primary-lighbis-inset {
  border-left: 1px inset #f4f4fa; }

.border-l-primary-lighbis-outset {
  border-left: 1px outset #f4f4fa; }

.border-l-primary-lighbis-none {
  border-left: 1px none #f4f4fa; }

.border-l-primary-lighbis-hidden {
  border-left: 1px hidden #f4f4fa; }

.has-text-secondary {
  color: #eb6666; }

.has-background-secondary {
  background-color: #eb6666; }

.border-secondary {
  border: 1px solid #eb6666; }

.border-m-secondary:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #eb6666;
  transform: translateY(-50%); }

.border-secondary-dotted {
  border: 1px dotted #eb6666; }

.border-secondary-dashed {
  border: 1px dashed #eb6666; }

.border-secondary-solid {
  border: 1px solid #eb6666; }

.border-secondary-double {
  border: 1px double #eb6666; }

.border-secondary-groove {
  border: 1px groove #eb6666; }

.border-secondary-ridge {
  border: 1px ridge #eb6666; }

.border-secondary-inset {
  border: 1px inset #eb6666; }

.border-secondary-outset {
  border: 1px outset #eb6666; }

.border-secondary-none {
  border: 1px none #eb6666; }

.border-secondary-hidden {
  border: 1px hidden #eb6666; }

.border-t-secondary {
  border-top: 1px solid #eb6666; }

.border-t-secondary-dotted {
  border-top: 1px dotted #eb6666; }

.border-t-secondary-dashed {
  border-top: 1px dashed #eb6666; }

.border-t-secondary-solid {
  border-top: 1px solid #eb6666; }

.border-t-secondary-double {
  border-top: 1px double #eb6666; }

.border-t-secondary-groove {
  border-top: 1px groove #eb6666; }

.border-t-secondary-ridge {
  border-top: 1px ridge #eb6666; }

.border-t-secondary-inset {
  border-top: 1px inset #eb6666; }

.border-t-secondary-outset {
  border-top: 1px outset #eb6666; }

.border-t-secondary-none {
  border-top: 1px none #eb6666; }

.border-t-secondary-hidden {
  border-top: 1px hidden #eb6666; }

.border-r-secondary {
  border-right: 1px solid #eb6666; }

.border-r-secondary-dotted {
  border-right: 1px dotted #eb6666; }

.border-r-secondary-dashed {
  border-right: 1px dashed #eb6666; }

.border-r-secondary-solid {
  border-right: 1px solid #eb6666; }

.border-r-secondary-double {
  border-right: 1px double #eb6666; }

.border-r-secondary-groove {
  border-right: 1px groove #eb6666; }

.border-r-secondary-ridge {
  border-right: 1px ridge #eb6666; }

.border-r-secondary-inset {
  border-right: 1px inset #eb6666; }

.border-r-secondary-outset {
  border-right: 1px outset #eb6666; }

.border-r-secondary-none {
  border-right: 1px none #eb6666; }

.border-r-secondary-hidden {
  border-right: 1px hidden #eb6666; }

.border-b-secondary {
  border-bottom: 1px solid #eb6666; }

.border-b-secondary-dotted {
  border-bottom: 1px dotted #eb6666; }

.border-b-secondary-dashed {
  border-bottom: 1px dashed #eb6666; }

.border-b-secondary-solid {
  border-bottom: 1px solid #eb6666; }

.border-b-secondary-double {
  border-bottom: 1px double #eb6666; }

.border-b-secondary-groove {
  border-bottom: 1px groove #eb6666; }

.border-b-secondary-ridge {
  border-bottom: 1px ridge #eb6666; }

.border-b-secondary-inset {
  border-bottom: 1px inset #eb6666; }

.border-b-secondary-outset {
  border-bottom: 1px outset #eb6666; }

.border-b-secondary-none {
  border-bottom: 1px none #eb6666; }

.border-b-secondary-hidden {
  border-bottom: 1px hidden #eb6666; }

.border-l-secondary {
  border-left: 1px solid #eb6666; }

.border-l-secondary-dotted {
  border-left: 1px dotted #eb6666; }

.border-l-secondary-dashed {
  border-left: 1px dashed #eb6666; }

.border-l-secondary-solid {
  border-left: 1px solid #eb6666; }

.border-l-secondary-double {
  border-left: 1px double #eb6666; }

.border-l-secondary-groove {
  border-left: 1px groove #eb6666; }

.border-l-secondary-ridge {
  border-left: 1px ridge #eb6666; }

.border-l-secondary-inset {
  border-left: 1px inset #eb6666; }

.border-l-secondary-outset {
  border-left: 1px outset #eb6666; }

.border-l-secondary-none {
  border-left: 1px none #eb6666; }

.border-l-secondary-hidden {
  border-left: 1px hidden #eb6666; }

.has-text-danger {
  color: #cc3333; }

.has-background-danger {
  background-color: #cc3333; }

.border-danger {
  border: 1px solid #cc3333; }

.border-m-danger:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #cc3333;
  transform: translateY(-50%); }

.border-danger-dotted {
  border: 1px dotted #cc3333; }

.border-danger-dashed {
  border: 1px dashed #cc3333; }

.border-danger-solid {
  border: 1px solid #cc3333; }

.border-danger-double {
  border: 1px double #cc3333; }

.border-danger-groove {
  border: 1px groove #cc3333; }

.border-danger-ridge {
  border: 1px ridge #cc3333; }

.border-danger-inset {
  border: 1px inset #cc3333; }

.border-danger-outset {
  border: 1px outset #cc3333; }

.border-danger-none {
  border: 1px none #cc3333; }

.border-danger-hidden {
  border: 1px hidden #cc3333; }

.border-t-danger {
  border-top: 1px solid #cc3333; }

.border-t-danger-dotted {
  border-top: 1px dotted #cc3333; }

.border-t-danger-dashed {
  border-top: 1px dashed #cc3333; }

.border-t-danger-solid {
  border-top: 1px solid #cc3333; }

.border-t-danger-double {
  border-top: 1px double #cc3333; }

.border-t-danger-groove {
  border-top: 1px groove #cc3333; }

.border-t-danger-ridge {
  border-top: 1px ridge #cc3333; }

.border-t-danger-inset {
  border-top: 1px inset #cc3333; }

.border-t-danger-outset {
  border-top: 1px outset #cc3333; }

.border-t-danger-none {
  border-top: 1px none #cc3333; }

.border-t-danger-hidden {
  border-top: 1px hidden #cc3333; }

.border-r-danger {
  border-right: 1px solid #cc3333; }

.border-r-danger-dotted {
  border-right: 1px dotted #cc3333; }

.border-r-danger-dashed {
  border-right: 1px dashed #cc3333; }

.border-r-danger-solid {
  border-right: 1px solid #cc3333; }

.border-r-danger-double {
  border-right: 1px double #cc3333; }

.border-r-danger-groove {
  border-right: 1px groove #cc3333; }

.border-r-danger-ridge {
  border-right: 1px ridge #cc3333; }

.border-r-danger-inset {
  border-right: 1px inset #cc3333; }

.border-r-danger-outset {
  border-right: 1px outset #cc3333; }

.border-r-danger-none {
  border-right: 1px none #cc3333; }

.border-r-danger-hidden {
  border-right: 1px hidden #cc3333; }

.border-b-danger {
  border-bottom: 1px solid #cc3333; }

.border-b-danger-dotted {
  border-bottom: 1px dotted #cc3333; }

.border-b-danger-dashed {
  border-bottom: 1px dashed #cc3333; }

.border-b-danger-solid {
  border-bottom: 1px solid #cc3333; }

.border-b-danger-double {
  border-bottom: 1px double #cc3333; }

.border-b-danger-groove {
  border-bottom: 1px groove #cc3333; }

.border-b-danger-ridge {
  border-bottom: 1px ridge #cc3333; }

.border-b-danger-inset {
  border-bottom: 1px inset #cc3333; }

.border-b-danger-outset {
  border-bottom: 1px outset #cc3333; }

.border-b-danger-none {
  border-bottom: 1px none #cc3333; }

.border-b-danger-hidden {
  border-bottom: 1px hidden #cc3333; }

.border-l-danger {
  border-left: 1px solid #cc3333; }

.border-l-danger-dotted {
  border-left: 1px dotted #cc3333; }

.border-l-danger-dashed {
  border-left: 1px dashed #cc3333; }

.border-l-danger-solid {
  border-left: 1px solid #cc3333; }

.border-l-danger-double {
  border-left: 1px double #cc3333; }

.border-l-danger-groove {
  border-left: 1px groove #cc3333; }

.border-l-danger-ridge {
  border-left: 1px ridge #cc3333; }

.border-l-danger-inset {
  border-left: 1px inset #cc3333; }

.border-l-danger-outset {
  border-left: 1px outset #cc3333; }

.border-l-danger-none {
  border-left: 1px none #cc3333; }

.border-l-danger-hidden {
  border-left: 1px hidden #cc3333; }

.has-text-danger-light {
  color: #f6dada; }

.has-background-danger-light {
  background-color: #f6dada; }

.border-danger-light {
  border: 1px solid #f6dada; }

.border-m-danger-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f6dada;
  transform: translateY(-50%); }

.border-danger-light-dotted {
  border: 1px dotted #f6dada; }

.border-danger-light-dashed {
  border: 1px dashed #f6dada; }

.border-danger-light-solid {
  border: 1px solid #f6dada; }

.border-danger-light-double {
  border: 1px double #f6dada; }

.border-danger-light-groove {
  border: 1px groove #f6dada; }

.border-danger-light-ridge {
  border: 1px ridge #f6dada; }

.border-danger-light-inset {
  border: 1px inset #f6dada; }

.border-danger-light-outset {
  border: 1px outset #f6dada; }

.border-danger-light-none {
  border: 1px none #f6dada; }

.border-danger-light-hidden {
  border: 1px hidden #f6dada; }

.border-t-danger-light {
  border-top: 1px solid #f6dada; }

.border-t-danger-light-dotted {
  border-top: 1px dotted #f6dada; }

.border-t-danger-light-dashed {
  border-top: 1px dashed #f6dada; }

.border-t-danger-light-solid {
  border-top: 1px solid #f6dada; }

.border-t-danger-light-double {
  border-top: 1px double #f6dada; }

.border-t-danger-light-groove {
  border-top: 1px groove #f6dada; }

.border-t-danger-light-ridge {
  border-top: 1px ridge #f6dada; }

.border-t-danger-light-inset {
  border-top: 1px inset #f6dada; }

.border-t-danger-light-outset {
  border-top: 1px outset #f6dada; }

.border-t-danger-light-none {
  border-top: 1px none #f6dada; }

.border-t-danger-light-hidden {
  border-top: 1px hidden #f6dada; }

.border-r-danger-light {
  border-right: 1px solid #f6dada; }

.border-r-danger-light-dotted {
  border-right: 1px dotted #f6dada; }

.border-r-danger-light-dashed {
  border-right: 1px dashed #f6dada; }

.border-r-danger-light-solid {
  border-right: 1px solid #f6dada; }

.border-r-danger-light-double {
  border-right: 1px double #f6dada; }

.border-r-danger-light-groove {
  border-right: 1px groove #f6dada; }

.border-r-danger-light-ridge {
  border-right: 1px ridge #f6dada; }

.border-r-danger-light-inset {
  border-right: 1px inset #f6dada; }

.border-r-danger-light-outset {
  border-right: 1px outset #f6dada; }

.border-r-danger-light-none {
  border-right: 1px none #f6dada; }

.border-r-danger-light-hidden {
  border-right: 1px hidden #f6dada; }

.border-b-danger-light {
  border-bottom: 1px solid #f6dada; }

.border-b-danger-light-dotted {
  border-bottom: 1px dotted #f6dada; }

.border-b-danger-light-dashed {
  border-bottom: 1px dashed #f6dada; }

.border-b-danger-light-solid {
  border-bottom: 1px solid #f6dada; }

.border-b-danger-light-double {
  border-bottom: 1px double #f6dada; }

.border-b-danger-light-groove {
  border-bottom: 1px groove #f6dada; }

.border-b-danger-light-ridge {
  border-bottom: 1px ridge #f6dada; }

.border-b-danger-light-inset {
  border-bottom: 1px inset #f6dada; }

.border-b-danger-light-outset {
  border-bottom: 1px outset #f6dada; }

.border-b-danger-light-none {
  border-bottom: 1px none #f6dada; }

.border-b-danger-light-hidden {
  border-bottom: 1px hidden #f6dada; }

.border-l-danger-light {
  border-left: 1px solid #f6dada; }

.border-l-danger-light-dotted {
  border-left: 1px dotted #f6dada; }

.border-l-danger-light-dashed {
  border-left: 1px dashed #f6dada; }

.border-l-danger-light-solid {
  border-left: 1px solid #f6dada; }

.border-l-danger-light-double {
  border-left: 1px double #f6dada; }

.border-l-danger-light-groove {
  border-left: 1px groove #f6dada; }

.border-l-danger-light-ridge {
  border-left: 1px ridge #f6dada; }

.border-l-danger-light-inset {
  border-left: 1px inset #f6dada; }

.border-l-danger-light-outset {
  border-left: 1px outset #f6dada; }

.border-l-danger-light-none {
  border-left: 1px none #f6dada; }

.border-l-danger-light-hidden {
  border-left: 1px hidden #f6dada; }

.has-text-warning {
  color: #ffd500; }

.has-background-warning {
  background-color: #ffd500; }

.border-warning {
  border: 1px solid #ffd500; }

.border-m-warning:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ffd500;
  transform: translateY(-50%); }

.border-warning-dotted {
  border: 1px dotted #ffd500; }

.border-warning-dashed {
  border: 1px dashed #ffd500; }

.border-warning-solid {
  border: 1px solid #ffd500; }

.border-warning-double {
  border: 1px double #ffd500; }

.border-warning-groove {
  border: 1px groove #ffd500; }

.border-warning-ridge {
  border: 1px ridge #ffd500; }

.border-warning-inset {
  border: 1px inset #ffd500; }

.border-warning-outset {
  border: 1px outset #ffd500; }

.border-warning-none {
  border: 1px none #ffd500; }

.border-warning-hidden {
  border: 1px hidden #ffd500; }

.border-t-warning {
  border-top: 1px solid #ffd500; }

.border-t-warning-dotted {
  border-top: 1px dotted #ffd500; }

.border-t-warning-dashed {
  border-top: 1px dashed #ffd500; }

.border-t-warning-solid {
  border-top: 1px solid #ffd500; }

.border-t-warning-double {
  border-top: 1px double #ffd500; }

.border-t-warning-groove {
  border-top: 1px groove #ffd500; }

.border-t-warning-ridge {
  border-top: 1px ridge #ffd500; }

.border-t-warning-inset {
  border-top: 1px inset #ffd500; }

.border-t-warning-outset {
  border-top: 1px outset #ffd500; }

.border-t-warning-none {
  border-top: 1px none #ffd500; }

.border-t-warning-hidden {
  border-top: 1px hidden #ffd500; }

.border-r-warning {
  border-right: 1px solid #ffd500; }

.border-r-warning-dotted {
  border-right: 1px dotted #ffd500; }

.border-r-warning-dashed {
  border-right: 1px dashed #ffd500; }

.border-r-warning-solid {
  border-right: 1px solid #ffd500; }

.border-r-warning-double {
  border-right: 1px double #ffd500; }

.border-r-warning-groove {
  border-right: 1px groove #ffd500; }

.border-r-warning-ridge {
  border-right: 1px ridge #ffd500; }

.border-r-warning-inset {
  border-right: 1px inset #ffd500; }

.border-r-warning-outset {
  border-right: 1px outset #ffd500; }

.border-r-warning-none {
  border-right: 1px none #ffd500; }

.border-r-warning-hidden {
  border-right: 1px hidden #ffd500; }

.border-b-warning {
  border-bottom: 1px solid #ffd500; }

.border-b-warning-dotted {
  border-bottom: 1px dotted #ffd500; }

.border-b-warning-dashed {
  border-bottom: 1px dashed #ffd500; }

.border-b-warning-solid {
  border-bottom: 1px solid #ffd500; }

.border-b-warning-double {
  border-bottom: 1px double #ffd500; }

.border-b-warning-groove {
  border-bottom: 1px groove #ffd500; }

.border-b-warning-ridge {
  border-bottom: 1px ridge #ffd500; }

.border-b-warning-inset {
  border-bottom: 1px inset #ffd500; }

.border-b-warning-outset {
  border-bottom: 1px outset #ffd500; }

.border-b-warning-none {
  border-bottom: 1px none #ffd500; }

.border-b-warning-hidden {
  border-bottom: 1px hidden #ffd500; }

.border-l-warning {
  border-left: 1px solid #ffd500; }

.border-l-warning-dotted {
  border-left: 1px dotted #ffd500; }

.border-l-warning-dashed {
  border-left: 1px dashed #ffd500; }

.border-l-warning-solid {
  border-left: 1px solid #ffd500; }

.border-l-warning-double {
  border-left: 1px double #ffd500; }

.border-l-warning-groove {
  border-left: 1px groove #ffd500; }

.border-l-warning-ridge {
  border-left: 1px ridge #ffd500; }

.border-l-warning-inset {
  border-left: 1px inset #ffd500; }

.border-l-warning-outset {
  border-left: 1px outset #ffd500; }

.border-l-warning-none {
  border-left: 1px none #ffd500; }

.border-l-warning-hidden {
  border-left: 1px hidden #ffd500; }

.has-text-success {
  color: #66cc33; }

.has-background-success {
  background-color: #66cc33; }

.border-success {
  border: 1px solid #66cc33; }

.border-m-success:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #66cc33;
  transform: translateY(-50%); }

.border-success-dotted {
  border: 1px dotted #66cc33; }

.border-success-dashed {
  border: 1px dashed #66cc33; }

.border-success-solid {
  border: 1px solid #66cc33; }

.border-success-double {
  border: 1px double #66cc33; }

.border-success-groove {
  border: 1px groove #66cc33; }

.border-success-ridge {
  border: 1px ridge #66cc33; }

.border-success-inset {
  border: 1px inset #66cc33; }

.border-success-outset {
  border: 1px outset #66cc33; }

.border-success-none {
  border: 1px none #66cc33; }

.border-success-hidden {
  border: 1px hidden #66cc33; }

.border-t-success {
  border-top: 1px solid #66cc33; }

.border-t-success-dotted {
  border-top: 1px dotted #66cc33; }

.border-t-success-dashed {
  border-top: 1px dashed #66cc33; }

.border-t-success-solid {
  border-top: 1px solid #66cc33; }

.border-t-success-double {
  border-top: 1px double #66cc33; }

.border-t-success-groove {
  border-top: 1px groove #66cc33; }

.border-t-success-ridge {
  border-top: 1px ridge #66cc33; }

.border-t-success-inset {
  border-top: 1px inset #66cc33; }

.border-t-success-outset {
  border-top: 1px outset #66cc33; }

.border-t-success-none {
  border-top: 1px none #66cc33; }

.border-t-success-hidden {
  border-top: 1px hidden #66cc33; }

.border-r-success {
  border-right: 1px solid #66cc33; }

.border-r-success-dotted {
  border-right: 1px dotted #66cc33; }

.border-r-success-dashed {
  border-right: 1px dashed #66cc33; }

.border-r-success-solid {
  border-right: 1px solid #66cc33; }

.border-r-success-double {
  border-right: 1px double #66cc33; }

.border-r-success-groove {
  border-right: 1px groove #66cc33; }

.border-r-success-ridge {
  border-right: 1px ridge #66cc33; }

.border-r-success-inset {
  border-right: 1px inset #66cc33; }

.border-r-success-outset {
  border-right: 1px outset #66cc33; }

.border-r-success-none {
  border-right: 1px none #66cc33; }

.border-r-success-hidden {
  border-right: 1px hidden #66cc33; }

.border-b-success {
  border-bottom: 1px solid #66cc33; }

.border-b-success-dotted {
  border-bottom: 1px dotted #66cc33; }

.border-b-success-dashed {
  border-bottom: 1px dashed #66cc33; }

.border-b-success-solid {
  border-bottom: 1px solid #66cc33; }

.border-b-success-double {
  border-bottom: 1px double #66cc33; }

.border-b-success-groove {
  border-bottom: 1px groove #66cc33; }

.border-b-success-ridge {
  border-bottom: 1px ridge #66cc33; }

.border-b-success-inset {
  border-bottom: 1px inset #66cc33; }

.border-b-success-outset {
  border-bottom: 1px outset #66cc33; }

.border-b-success-none {
  border-bottom: 1px none #66cc33; }

.border-b-success-hidden {
  border-bottom: 1px hidden #66cc33; }

.border-l-success {
  border-left: 1px solid #66cc33; }

.border-l-success-dotted {
  border-left: 1px dotted #66cc33; }

.border-l-success-dashed {
  border-left: 1px dashed #66cc33; }

.border-l-success-solid {
  border-left: 1px solid #66cc33; }

.border-l-success-double {
  border-left: 1px double #66cc33; }

.border-l-success-groove {
  border-left: 1px groove #66cc33; }

.border-l-success-ridge {
  border-left: 1px ridge #66cc33; }

.border-l-success-inset {
  border-left: 1px inset #66cc33; }

.border-l-success-outset {
  border-left: 1px outset #66cc33; }

.border-l-success-none {
  border-left: 1px none #66cc33; }

.border-l-success-hidden {
  border-left: 1px hidden #66cc33; }

.has-text-success-light {
  color: #dbffe0; }

.has-background-success-light {
  background-color: #dbffe0; }

.border-success-light {
  border: 1px solid #dbffe0; }

.border-m-success-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dbffe0;
  transform: translateY(-50%); }

.border-success-light-dotted {
  border: 1px dotted #dbffe0; }

.border-success-light-dashed {
  border: 1px dashed #dbffe0; }

.border-success-light-solid {
  border: 1px solid #dbffe0; }

.border-success-light-double {
  border: 1px double #dbffe0; }

.border-success-light-groove {
  border: 1px groove #dbffe0; }

.border-success-light-ridge {
  border: 1px ridge #dbffe0; }

.border-success-light-inset {
  border: 1px inset #dbffe0; }

.border-success-light-outset {
  border: 1px outset #dbffe0; }

.border-success-light-none {
  border: 1px none #dbffe0; }

.border-success-light-hidden {
  border: 1px hidden #dbffe0; }

.border-t-success-light {
  border-top: 1px solid #dbffe0; }

.border-t-success-light-dotted {
  border-top: 1px dotted #dbffe0; }

.border-t-success-light-dashed {
  border-top: 1px dashed #dbffe0; }

.border-t-success-light-solid {
  border-top: 1px solid #dbffe0; }

.border-t-success-light-double {
  border-top: 1px double #dbffe0; }

.border-t-success-light-groove {
  border-top: 1px groove #dbffe0; }

.border-t-success-light-ridge {
  border-top: 1px ridge #dbffe0; }

.border-t-success-light-inset {
  border-top: 1px inset #dbffe0; }

.border-t-success-light-outset {
  border-top: 1px outset #dbffe0; }

.border-t-success-light-none {
  border-top: 1px none #dbffe0; }

.border-t-success-light-hidden {
  border-top: 1px hidden #dbffe0; }

.border-r-success-light {
  border-right: 1px solid #dbffe0; }

.border-r-success-light-dotted {
  border-right: 1px dotted #dbffe0; }

.border-r-success-light-dashed {
  border-right: 1px dashed #dbffe0; }

.border-r-success-light-solid {
  border-right: 1px solid #dbffe0; }

.border-r-success-light-double {
  border-right: 1px double #dbffe0; }

.border-r-success-light-groove {
  border-right: 1px groove #dbffe0; }

.border-r-success-light-ridge {
  border-right: 1px ridge #dbffe0; }

.border-r-success-light-inset {
  border-right: 1px inset #dbffe0; }

.border-r-success-light-outset {
  border-right: 1px outset #dbffe0; }

.border-r-success-light-none {
  border-right: 1px none #dbffe0; }

.border-r-success-light-hidden {
  border-right: 1px hidden #dbffe0; }

.border-b-success-light {
  border-bottom: 1px solid #dbffe0; }

.border-b-success-light-dotted {
  border-bottom: 1px dotted #dbffe0; }

.border-b-success-light-dashed {
  border-bottom: 1px dashed #dbffe0; }

.border-b-success-light-solid {
  border-bottom: 1px solid #dbffe0; }

.border-b-success-light-double {
  border-bottom: 1px double #dbffe0; }

.border-b-success-light-groove {
  border-bottom: 1px groove #dbffe0; }

.border-b-success-light-ridge {
  border-bottom: 1px ridge #dbffe0; }

.border-b-success-light-inset {
  border-bottom: 1px inset #dbffe0; }

.border-b-success-light-outset {
  border-bottom: 1px outset #dbffe0; }

.border-b-success-light-none {
  border-bottom: 1px none #dbffe0; }

.border-b-success-light-hidden {
  border-bottom: 1px hidden #dbffe0; }

.border-l-success-light {
  border-left: 1px solid #dbffe0; }

.border-l-success-light-dotted {
  border-left: 1px dotted #dbffe0; }

.border-l-success-light-dashed {
  border-left: 1px dashed #dbffe0; }

.border-l-success-light-solid {
  border-left: 1px solid #dbffe0; }

.border-l-success-light-double {
  border-left: 1px double #dbffe0; }

.border-l-success-light-groove {
  border-left: 1px groove #dbffe0; }

.border-l-success-light-ridge {
  border-left: 1px ridge #dbffe0; }

.border-l-success-light-inset {
  border-left: 1px inset #dbffe0; }

.border-l-success-light-outset {
  border-left: 1px outset #dbffe0; }

.border-l-success-light-none {
  border-left: 1px none #dbffe0; }

.border-l-success-light-hidden {
  border-left: 1px hidden #dbffe0; }

.has-text-info {
  color: #209fee; }

.has-background-info {
  background-color: #209fee; }

.border-info {
  border: 1px solid #209fee; }

.border-m-info:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #209fee;
  transform: translateY(-50%); }

.border-info-dotted {
  border: 1px dotted #209fee; }

.border-info-dashed {
  border: 1px dashed #209fee; }

.border-info-solid {
  border: 1px solid #209fee; }

.border-info-double {
  border: 1px double #209fee; }

.border-info-groove {
  border: 1px groove #209fee; }

.border-info-ridge {
  border: 1px ridge #209fee; }

.border-info-inset {
  border: 1px inset #209fee; }

.border-info-outset {
  border: 1px outset #209fee; }

.border-info-none {
  border: 1px none #209fee; }

.border-info-hidden {
  border: 1px hidden #209fee; }

.border-t-info {
  border-top: 1px solid #209fee; }

.border-t-info-dotted {
  border-top: 1px dotted #209fee; }

.border-t-info-dashed {
  border-top: 1px dashed #209fee; }

.border-t-info-solid {
  border-top: 1px solid #209fee; }

.border-t-info-double {
  border-top: 1px double #209fee; }

.border-t-info-groove {
  border-top: 1px groove #209fee; }

.border-t-info-ridge {
  border-top: 1px ridge #209fee; }

.border-t-info-inset {
  border-top: 1px inset #209fee; }

.border-t-info-outset {
  border-top: 1px outset #209fee; }

.border-t-info-none {
  border-top: 1px none #209fee; }

.border-t-info-hidden {
  border-top: 1px hidden #209fee; }

.border-r-info {
  border-right: 1px solid #209fee; }

.border-r-info-dotted {
  border-right: 1px dotted #209fee; }

.border-r-info-dashed {
  border-right: 1px dashed #209fee; }

.border-r-info-solid {
  border-right: 1px solid #209fee; }

.border-r-info-double {
  border-right: 1px double #209fee; }

.border-r-info-groove {
  border-right: 1px groove #209fee; }

.border-r-info-ridge {
  border-right: 1px ridge #209fee; }

.border-r-info-inset {
  border-right: 1px inset #209fee; }

.border-r-info-outset {
  border-right: 1px outset #209fee; }

.border-r-info-none {
  border-right: 1px none #209fee; }

.border-r-info-hidden {
  border-right: 1px hidden #209fee; }

.border-b-info {
  border-bottom: 1px solid #209fee; }

.border-b-info-dotted {
  border-bottom: 1px dotted #209fee; }

.border-b-info-dashed {
  border-bottom: 1px dashed #209fee; }

.border-b-info-solid {
  border-bottom: 1px solid #209fee; }

.border-b-info-double {
  border-bottom: 1px double #209fee; }

.border-b-info-groove {
  border-bottom: 1px groove #209fee; }

.border-b-info-ridge {
  border-bottom: 1px ridge #209fee; }

.border-b-info-inset {
  border-bottom: 1px inset #209fee; }

.border-b-info-outset {
  border-bottom: 1px outset #209fee; }

.border-b-info-none {
  border-bottom: 1px none #209fee; }

.border-b-info-hidden {
  border-bottom: 1px hidden #209fee; }

.border-l-info {
  border-left: 1px solid #209fee; }

.border-l-info-dotted {
  border-left: 1px dotted #209fee; }

.border-l-info-dashed {
  border-left: 1px dashed #209fee; }

.border-l-info-solid {
  border-left: 1px solid #209fee; }

.border-l-info-double {
  border-left: 1px double #209fee; }

.border-l-info-groove {
  border-left: 1px groove #209fee; }

.border-l-info-ridge {
  border-left: 1px ridge #209fee; }

.border-l-info-inset {
  border-left: 1px inset #209fee; }

.border-l-info-outset {
  border-left: 1px outset #209fee; }

.border-l-info-none {
  border-left: 1px none #209fee; }

.border-l-info-hidden {
  border-left: 1px hidden #209fee; }

.has-text-black {
  color: #0a0a0a; }

.has-background-black {
  background-color: #0a0a0a; }

.border-black {
  border: 1px solid #0a0a0a; }

.border-black-dotted {
  border: 1px dotted #0a0a0a; }

.border-black-dashed {
  border: 1px dashed #0a0a0a; }

.border-black-solid {
  border: 1px solid #0a0a0a; }

.border-black-double {
  border: 1px double #0a0a0a; }

.border-black-groove {
  border: 1px groove #0a0a0a; }

.border-black-ridge {
  border: 1px ridge #0a0a0a; }

.border-black-inset {
  border: 1px inset #0a0a0a; }

.border-black-outset {
  border: 1px outset #0a0a0a; }

.border-black-none {
  border: 1px none #0a0a0a; }

.border-black-hidden {
  border: 1px hidden #0a0a0a; }

.border-t-black {
  border-top: 1px solid #0a0a0a; }

.border-t-black-dotted {
  border-top: 1px dotted #0a0a0a; }

.border-t-black-dashed {
  border-top: 1px dashed #0a0a0a; }

.border-t-black-solid {
  border-top: 1px solid #0a0a0a; }

.border-t-black-double {
  border-top: 1px double #0a0a0a; }

.border-t-black-groove {
  border-top: 1px groove #0a0a0a; }

.border-t-black-ridge {
  border-top: 1px ridge #0a0a0a; }

.border-t-black-inset {
  border-top: 1px inset #0a0a0a; }

.border-t-black-outset {
  border-top: 1px outset #0a0a0a; }

.border-t-black-none {
  border-top: 1px none #0a0a0a; }

.border-t-black-hidden {
  border-top: 1px hidden #0a0a0a; }

.border-r-black {
  border-right: 1px solid #0a0a0a; }

.border-r-black-dotted {
  border-right: 1px dotted #0a0a0a; }

.border-r-black-dashed {
  border-right: 1px dashed #0a0a0a; }

.border-r-black-solid {
  border-right: 1px solid #0a0a0a; }

.border-r-black-double {
  border-right: 1px double #0a0a0a; }

.border-r-black-groove {
  border-right: 1px groove #0a0a0a; }

.border-r-black-ridge {
  border-right: 1px ridge #0a0a0a; }

.border-r-black-inset {
  border-right: 1px inset #0a0a0a; }

.border-r-black-outset {
  border-right: 1px outset #0a0a0a; }

.border-r-black-none {
  border-right: 1px none #0a0a0a; }

.border-r-black-hidden {
  border-right: 1px hidden #0a0a0a; }

.border-b-black {
  border-bottom: 1px solid #0a0a0a; }

.border-b-black-dotted {
  border-bottom: 1px dotted #0a0a0a; }

.border-b-black-dashed {
  border-bottom: 1px dashed #0a0a0a; }

.border-b-black-solid {
  border-bottom: 1px solid #0a0a0a; }

.border-b-black-double {
  border-bottom: 1px double #0a0a0a; }

.border-b-black-groove {
  border-bottom: 1px groove #0a0a0a; }

.border-b-black-ridge {
  border-bottom: 1px ridge #0a0a0a; }

.border-b-black-inset {
  border-bottom: 1px inset #0a0a0a; }

.border-b-black-outset {
  border-bottom: 1px outset #0a0a0a; }

.border-b-black-none {
  border-bottom: 1px none #0a0a0a; }

.border-b-black-hidden {
  border-bottom: 1px hidden #0a0a0a; }

.border-l-black {
  border-left: 1px solid #0a0a0a; }

.border-l-black-dotted {
  border-left: 1px dotted #0a0a0a; }

.border-l-black-dashed {
  border-left: 1px dashed #0a0a0a; }

.border-l-black-solid {
  border-left: 1px solid #0a0a0a; }

.border-l-black-double {
  border-left: 1px double #0a0a0a; }

.border-l-black-groove {
  border-left: 1px groove #0a0a0a; }

.border-l-black-ridge {
  border-left: 1px ridge #0a0a0a; }

.border-l-black-inset {
  border-left: 1px inset #0a0a0a; }

.border-l-black-outset {
  border-left: 1px outset #0a0a0a; }

.border-l-black-none {
  border-left: 1px none #0a0a0a; }

.border-l-black-hidden {
  border-left: 1px hidden #0a0a0a; }

.has-text-black-bis {
  color: #121212; }

.has-background-black-bis {
  background-color: #121212; }

.border-black-bis {
  border: 1px solid #121212; }

.border-black-bis-dotted {
  border: 1px dotted #121212; }

.border-black-bis-dashed {
  border: 1px dashed #121212; }

.border-black-bis-solid {
  border: 1px solid #121212; }

.border-black-bis-double {
  border: 1px double #121212; }

.border-black-bis-groove {
  border: 1px groove #121212; }

.border-black-bis-ridge {
  border: 1px ridge #121212; }

.border-black-bis-inset {
  border: 1px inset #121212; }

.border-black-bis-outset {
  border: 1px outset #121212; }

.border-black-bis-none {
  border: 1px none #121212; }

.border-black-bis-hidden {
  border: 1px hidden #121212; }

.border-t-black-bis {
  border-top: 1px solid #121212; }

.border-t-black-bis-dotted {
  border-top: 1px dotted #121212; }

.border-t-black-bis-dashed {
  border-top: 1px dashed #121212; }

.border-t-black-bis-solid {
  border-top: 1px solid #121212; }

.border-t-black-bis-double {
  border-top: 1px double #121212; }

.border-t-black-bis-groove {
  border-top: 1px groove #121212; }

.border-t-black-bis-ridge {
  border-top: 1px ridge #121212; }

.border-t-black-bis-inset {
  border-top: 1px inset #121212; }

.border-t-black-bis-outset {
  border-top: 1px outset #121212; }

.border-t-black-bis-none {
  border-top: 1px none #121212; }

.border-t-black-bis-hidden {
  border-top: 1px hidden #121212; }

.border-r-black-bis {
  border-right: 1px solid #121212; }

.border-r-black-bis-dotted {
  border-right: 1px dotted #121212; }

.border-r-black-bis-dashed {
  border-right: 1px dashed #121212; }

.border-r-black-bis-solid {
  border-right: 1px solid #121212; }

.border-r-black-bis-double {
  border-right: 1px double #121212; }

.border-r-black-bis-groove {
  border-right: 1px groove #121212; }

.border-r-black-bis-ridge {
  border-right: 1px ridge #121212; }

.border-r-black-bis-inset {
  border-right: 1px inset #121212; }

.border-r-black-bis-outset {
  border-right: 1px outset #121212; }

.border-r-black-bis-none {
  border-right: 1px none #121212; }

.border-r-black-bis-hidden {
  border-right: 1px hidden #121212; }

.border-b-black-bis {
  border-bottom: 1px solid #121212; }

.border-b-black-bis-dotted {
  border-bottom: 1px dotted #121212; }

.border-b-black-bis-dashed {
  border-bottom: 1px dashed #121212; }

.border-b-black-bis-solid {
  border-bottom: 1px solid #121212; }

.border-b-black-bis-double {
  border-bottom: 1px double #121212; }

.border-b-black-bis-groove {
  border-bottom: 1px groove #121212; }

.border-b-black-bis-ridge {
  border-bottom: 1px ridge #121212; }

.border-b-black-bis-inset {
  border-bottom: 1px inset #121212; }

.border-b-black-bis-outset {
  border-bottom: 1px outset #121212; }

.border-b-black-bis-none {
  border-bottom: 1px none #121212; }

.border-b-black-bis-hidden {
  border-bottom: 1px hidden #121212; }

.border-l-black-bis {
  border-left: 1px solid #121212; }

.border-l-black-bis-dotted {
  border-left: 1px dotted #121212; }

.border-l-black-bis-dashed {
  border-left: 1px dashed #121212; }

.border-l-black-bis-solid {
  border-left: 1px solid #121212; }

.border-l-black-bis-double {
  border-left: 1px double #121212; }

.border-l-black-bis-groove {
  border-left: 1px groove #121212; }

.border-l-black-bis-ridge {
  border-left: 1px ridge #121212; }

.border-l-black-bis-inset {
  border-left: 1px inset #121212; }

.border-l-black-bis-outset {
  border-left: 1px outset #121212; }

.border-l-black-bis-none {
  border-left: 1px none #121212; }

.border-l-black-bis-hidden {
  border-left: 1px hidden #121212; }

.has-text-black-ter {
  color: #242424; }

.has-background-black-ter {
  background-color: #242424; }

.border-black-ter {
  border: 1px solid #242424; }

.border-black-ter-dotted {
  border: 1px dotted #242424; }

.border-black-ter-dashed {
  border: 1px dashed #242424; }

.border-black-ter-solid {
  border: 1px solid #242424; }

.border-black-ter-double {
  border: 1px double #242424; }

.border-black-ter-groove {
  border: 1px groove #242424; }

.border-black-ter-ridge {
  border: 1px ridge #242424; }

.border-black-ter-inset {
  border: 1px inset #242424; }

.border-black-ter-outset {
  border: 1px outset #242424; }

.border-black-ter-none {
  border: 1px none #242424; }

.border-black-ter-hidden {
  border: 1px hidden #242424; }

.border-t-black-ter {
  border-top: 1px solid #242424; }

.border-t-black-ter-dotted {
  border-top: 1px dotted #242424; }

.border-t-black-ter-dashed {
  border-top: 1px dashed #242424; }

.border-t-black-ter-solid {
  border-top: 1px solid #242424; }

.border-t-black-ter-double {
  border-top: 1px double #242424; }

.border-t-black-ter-groove {
  border-top: 1px groove #242424; }

.border-t-black-ter-ridge {
  border-top: 1px ridge #242424; }

.border-t-black-ter-inset {
  border-top: 1px inset #242424; }

.border-t-black-ter-outset {
  border-top: 1px outset #242424; }

.border-t-black-ter-none {
  border-top: 1px none #242424; }

.border-t-black-ter-hidden {
  border-top: 1px hidden #242424; }

.border-r-black-ter {
  border-right: 1px solid #242424; }

.border-r-black-ter-dotted {
  border-right: 1px dotted #242424; }

.border-r-black-ter-dashed {
  border-right: 1px dashed #242424; }

.border-r-black-ter-solid {
  border-right: 1px solid #242424; }

.border-r-black-ter-double {
  border-right: 1px double #242424; }

.border-r-black-ter-groove {
  border-right: 1px groove #242424; }

.border-r-black-ter-ridge {
  border-right: 1px ridge #242424; }

.border-r-black-ter-inset {
  border-right: 1px inset #242424; }

.border-r-black-ter-outset {
  border-right: 1px outset #242424; }

.border-r-black-ter-none {
  border-right: 1px none #242424; }

.border-r-black-ter-hidden {
  border-right: 1px hidden #242424; }

.border-b-black-ter {
  border-bottom: 1px solid #242424; }

.border-b-black-ter-dotted {
  border-bottom: 1px dotted #242424; }

.border-b-black-ter-dashed {
  border-bottom: 1px dashed #242424; }

.border-b-black-ter-solid {
  border-bottom: 1px solid #242424; }

.border-b-black-ter-double {
  border-bottom: 1px double #242424; }

.border-b-black-ter-groove {
  border-bottom: 1px groove #242424; }

.border-b-black-ter-ridge {
  border-bottom: 1px ridge #242424; }

.border-b-black-ter-inset {
  border-bottom: 1px inset #242424; }

.border-b-black-ter-outset {
  border-bottom: 1px outset #242424; }

.border-b-black-ter-none {
  border-bottom: 1px none #242424; }

.border-b-black-ter-hidden {
  border-bottom: 1px hidden #242424; }

.border-l-black-ter {
  border-left: 1px solid #242424; }

.border-l-black-ter-dotted {
  border-left: 1px dotted #242424; }

.border-l-black-ter-dashed {
  border-left: 1px dashed #242424; }

.border-l-black-ter-solid {
  border-left: 1px solid #242424; }

.border-l-black-ter-double {
  border-left: 1px double #242424; }

.border-l-black-ter-groove {
  border-left: 1px groove #242424; }

.border-l-black-ter-ridge {
  border-left: 1px ridge #242424; }

.border-l-black-ter-inset {
  border-left: 1px inset #242424; }

.border-l-black-ter-outset {
  border-left: 1px outset #242424; }

.border-l-black-ter-none {
  border-left: 1px none #242424; }

.border-l-black-ter-hidden {
  border-left: 1px hidden #242424; }

.has-text-grey-darker {
  color: #363636; }

.has-background-grey-darker {
  background-color: #363636; }

.border-grey-darker {
  border: 1px solid #363636; }

.border-grey-darker-dotted {
  border: 1px dotted #363636; }

.border-grey-darker-dashed {
  border: 1px dashed #363636; }

.border-grey-darker-solid {
  border: 1px solid #363636; }

.border-grey-darker-double {
  border: 1px double #363636; }

.border-grey-darker-groove {
  border: 1px groove #363636; }

.border-grey-darker-ridge {
  border: 1px ridge #363636; }

.border-grey-darker-inset {
  border: 1px inset #363636; }

.border-grey-darker-outset {
  border: 1px outset #363636; }

.border-grey-darker-none {
  border: 1px none #363636; }

.border-grey-darker-hidden {
  border: 1px hidden #363636; }

.border-t-grey-darker {
  border-top: 1px solid #363636; }

.border-t-grey-darker-dotted {
  border-top: 1px dotted #363636; }

.border-t-grey-darker-dashed {
  border-top: 1px dashed #363636; }

.border-t-grey-darker-solid {
  border-top: 1px solid #363636; }

.border-t-grey-darker-double {
  border-top: 1px double #363636; }

.border-t-grey-darker-groove {
  border-top: 1px groove #363636; }

.border-t-grey-darker-ridge {
  border-top: 1px ridge #363636; }

.border-t-grey-darker-inset {
  border-top: 1px inset #363636; }

.border-t-grey-darker-outset {
  border-top: 1px outset #363636; }

.border-t-grey-darker-none {
  border-top: 1px none #363636; }

.border-t-grey-darker-hidden {
  border-top: 1px hidden #363636; }

.border-r-grey-darker {
  border-right: 1px solid #363636; }

.border-r-grey-darker-dotted {
  border-right: 1px dotted #363636; }

.border-r-grey-darker-dashed {
  border-right: 1px dashed #363636; }

.border-r-grey-darker-solid {
  border-right: 1px solid #363636; }

.border-r-grey-darker-double {
  border-right: 1px double #363636; }

.border-r-grey-darker-groove {
  border-right: 1px groove #363636; }

.border-r-grey-darker-ridge {
  border-right: 1px ridge #363636; }

.border-r-grey-darker-inset {
  border-right: 1px inset #363636; }

.border-r-grey-darker-outset {
  border-right: 1px outset #363636; }

.border-r-grey-darker-none {
  border-right: 1px none #363636; }

.border-r-grey-darker-hidden {
  border-right: 1px hidden #363636; }

.border-b-grey-darker {
  border-bottom: 1px solid #363636; }

.border-b-grey-darker-dotted {
  border-bottom: 1px dotted #363636; }

.border-b-grey-darker-dashed {
  border-bottom: 1px dashed #363636; }

.border-b-grey-darker-solid {
  border-bottom: 1px solid #363636; }

.border-b-grey-darker-double {
  border-bottom: 1px double #363636; }

.border-b-grey-darker-groove {
  border-bottom: 1px groove #363636; }

.border-b-grey-darker-ridge {
  border-bottom: 1px ridge #363636; }

.border-b-grey-darker-inset {
  border-bottom: 1px inset #363636; }

.border-b-grey-darker-outset {
  border-bottom: 1px outset #363636; }

.border-b-grey-darker-none {
  border-bottom: 1px none #363636; }

.border-b-grey-darker-hidden {
  border-bottom: 1px hidden #363636; }

.border-l-grey-darker {
  border-left: 1px solid #363636; }

.border-l-grey-darker-dotted {
  border-left: 1px dotted #363636; }

.border-l-grey-darker-dashed {
  border-left: 1px dashed #363636; }

.border-l-grey-darker-solid {
  border-left: 1px solid #363636; }

.border-l-grey-darker-double {
  border-left: 1px double #363636; }

.border-l-grey-darker-groove {
  border-left: 1px groove #363636; }

.border-l-grey-darker-ridge {
  border-left: 1px ridge #363636; }

.border-l-grey-darker-inset {
  border-left: 1px inset #363636; }

.border-l-grey-darker-outset {
  border-left: 1px outset #363636; }

.border-l-grey-darker-none {
  border-left: 1px none #363636; }

.border-l-grey-darker-hidden {
  border-left: 1px hidden #363636; }

.has-text-grey-dark {
  color: #4a4a4a; }

.has-background-grey-dark {
  background-color: #4a4a4a; }

.border-grey-dark {
  border: 1px solid #4a4a4a; }

.border-grey-dark-dotted {
  border: 1px dotted #4a4a4a; }

.border-grey-dark-dashed {
  border: 1px dashed #4a4a4a; }

.border-grey-dark-solid {
  border: 1px solid #4a4a4a; }

.border-grey-dark-double {
  border: 1px double #4a4a4a; }

.border-grey-dark-groove {
  border: 1px groove #4a4a4a; }

.border-grey-dark-ridge {
  border: 1px ridge #4a4a4a; }

.border-grey-dark-inset {
  border: 1px inset #4a4a4a; }

.border-grey-dark-outset {
  border: 1px outset #4a4a4a; }

.border-grey-dark-none {
  border: 1px none #4a4a4a; }

.border-grey-dark-hidden {
  border: 1px hidden #4a4a4a; }

.border-t-grey-dark {
  border-top: 1px solid #4a4a4a; }

.border-t-grey-dark-dotted {
  border-top: 1px dotted #4a4a4a; }

.border-t-grey-dark-dashed {
  border-top: 1px dashed #4a4a4a; }

.border-t-grey-dark-solid {
  border-top: 1px solid #4a4a4a; }

.border-t-grey-dark-double {
  border-top: 1px double #4a4a4a; }

.border-t-grey-dark-groove {
  border-top: 1px groove #4a4a4a; }

.border-t-grey-dark-ridge {
  border-top: 1px ridge #4a4a4a; }

.border-t-grey-dark-inset {
  border-top: 1px inset #4a4a4a; }

.border-t-grey-dark-outset {
  border-top: 1px outset #4a4a4a; }

.border-t-grey-dark-none {
  border-top: 1px none #4a4a4a; }

.border-t-grey-dark-hidden {
  border-top: 1px hidden #4a4a4a; }

.border-r-grey-dark {
  border-right: 1px solid #4a4a4a; }

.border-r-grey-dark-dotted {
  border-right: 1px dotted #4a4a4a; }

.border-r-grey-dark-dashed {
  border-right: 1px dashed #4a4a4a; }

.border-r-grey-dark-solid {
  border-right: 1px solid #4a4a4a; }

.border-r-grey-dark-double {
  border-right: 1px double #4a4a4a; }

.border-r-grey-dark-groove {
  border-right: 1px groove #4a4a4a; }

.border-r-grey-dark-ridge {
  border-right: 1px ridge #4a4a4a; }

.border-r-grey-dark-inset {
  border-right: 1px inset #4a4a4a; }

.border-r-grey-dark-outset {
  border-right: 1px outset #4a4a4a; }

.border-r-grey-dark-none {
  border-right: 1px none #4a4a4a; }

.border-r-grey-dark-hidden {
  border-right: 1px hidden #4a4a4a; }

.border-b-grey-dark {
  border-bottom: 1px solid #4a4a4a; }

.border-b-grey-dark-dotted {
  border-bottom: 1px dotted #4a4a4a; }

.border-b-grey-dark-dashed {
  border-bottom: 1px dashed #4a4a4a; }

.border-b-grey-dark-solid {
  border-bottom: 1px solid #4a4a4a; }

.border-b-grey-dark-double {
  border-bottom: 1px double #4a4a4a; }

.border-b-grey-dark-groove {
  border-bottom: 1px groove #4a4a4a; }

.border-b-grey-dark-ridge {
  border-bottom: 1px ridge #4a4a4a; }

.border-b-grey-dark-inset {
  border-bottom: 1px inset #4a4a4a; }

.border-b-grey-dark-outset {
  border-bottom: 1px outset #4a4a4a; }

.border-b-grey-dark-none {
  border-bottom: 1px none #4a4a4a; }

.border-b-grey-dark-hidden {
  border-bottom: 1px hidden #4a4a4a; }

.border-l-grey-dark {
  border-left: 1px solid #4a4a4a; }

.border-l-grey-dark-dotted {
  border-left: 1px dotted #4a4a4a; }

.border-l-grey-dark-dashed {
  border-left: 1px dashed #4a4a4a; }

.border-l-grey-dark-solid {
  border-left: 1px solid #4a4a4a; }

.border-l-grey-dark-double {
  border-left: 1px double #4a4a4a; }

.border-l-grey-dark-groove {
  border-left: 1px groove #4a4a4a; }

.border-l-grey-dark-ridge {
  border-left: 1px ridge #4a4a4a; }

.border-l-grey-dark-inset {
  border-left: 1px inset #4a4a4a; }

.border-l-grey-dark-outset {
  border-left: 1px outset #4a4a4a; }

.border-l-grey-dark-none {
  border-left: 1px none #4a4a4a; }

.border-l-grey-dark-hidden {
  border-left: 1px hidden #4a4a4a; }

.has-text-grey {
  color: #7a7a7a; }

.has-background-grey {
  background-color: #7a7a7a; }

.border-grey {
  border: 1px solid #7a7a7a; }

.border-grey-dotted {
  border: 1px dotted #7a7a7a; }

.border-grey-dashed {
  border: 1px dashed #7a7a7a; }

.border-grey-solid {
  border: 1px solid #7a7a7a; }

.border-grey-double {
  border: 1px double #7a7a7a; }

.border-grey-groove {
  border: 1px groove #7a7a7a; }

.border-grey-ridge {
  border: 1px ridge #7a7a7a; }

.border-grey-inset {
  border: 1px inset #7a7a7a; }

.border-grey-outset {
  border: 1px outset #7a7a7a; }

.border-grey-none {
  border: 1px none #7a7a7a; }

.border-grey-hidden {
  border: 1px hidden #7a7a7a; }

.border-t-grey {
  border-top: 1px solid #7a7a7a; }

.border-t-grey-dotted {
  border-top: 1px dotted #7a7a7a; }

.border-t-grey-dashed {
  border-top: 1px dashed #7a7a7a; }

.border-t-grey-solid {
  border-top: 1px solid #7a7a7a; }

.border-t-grey-double {
  border-top: 1px double #7a7a7a; }

.border-t-grey-groove {
  border-top: 1px groove #7a7a7a; }

.border-t-grey-ridge {
  border-top: 1px ridge #7a7a7a; }

.border-t-grey-inset {
  border-top: 1px inset #7a7a7a; }

.border-t-grey-outset {
  border-top: 1px outset #7a7a7a; }

.border-t-grey-none {
  border-top: 1px none #7a7a7a; }

.border-t-grey-hidden {
  border-top: 1px hidden #7a7a7a; }

.border-r-grey {
  border-right: 1px solid #7a7a7a; }

.border-r-grey-dotted {
  border-right: 1px dotted #7a7a7a; }

.border-r-grey-dashed {
  border-right: 1px dashed #7a7a7a; }

.border-r-grey-solid {
  border-right: 1px solid #7a7a7a; }

.border-r-grey-double {
  border-right: 1px double #7a7a7a; }

.border-r-grey-groove {
  border-right: 1px groove #7a7a7a; }

.border-r-grey-ridge {
  border-right: 1px ridge #7a7a7a; }

.border-r-grey-inset {
  border-right: 1px inset #7a7a7a; }

.border-r-grey-outset {
  border-right: 1px outset #7a7a7a; }

.border-r-grey-none {
  border-right: 1px none #7a7a7a; }

.border-r-grey-hidden {
  border-right: 1px hidden #7a7a7a; }

.border-b-grey {
  border-bottom: 1px solid #7a7a7a; }

.border-b-grey-dotted {
  border-bottom: 1px dotted #7a7a7a; }

.border-b-grey-dashed {
  border-bottom: 1px dashed #7a7a7a; }

.border-b-grey-solid {
  border-bottom: 1px solid #7a7a7a; }

.border-b-grey-double {
  border-bottom: 1px double #7a7a7a; }

.border-b-grey-groove {
  border-bottom: 1px groove #7a7a7a; }

.border-b-grey-ridge {
  border-bottom: 1px ridge #7a7a7a; }

.border-b-grey-inset {
  border-bottom: 1px inset #7a7a7a; }

.border-b-grey-outset {
  border-bottom: 1px outset #7a7a7a; }

.border-b-grey-none {
  border-bottom: 1px none #7a7a7a; }

.border-b-grey-hidden {
  border-bottom: 1px hidden #7a7a7a; }

.border-l-grey {
  border-left: 1px solid #7a7a7a; }

.border-l-grey-dotted {
  border-left: 1px dotted #7a7a7a; }

.border-l-grey-dashed {
  border-left: 1px dashed #7a7a7a; }

.border-l-grey-solid {
  border-left: 1px solid #7a7a7a; }

.border-l-grey-double {
  border-left: 1px double #7a7a7a; }

.border-l-grey-groove {
  border-left: 1px groove #7a7a7a; }

.border-l-grey-ridge {
  border-left: 1px ridge #7a7a7a; }

.border-l-grey-inset {
  border-left: 1px inset #7a7a7a; }

.border-l-grey-outset {
  border-left: 1px outset #7a7a7a; }

.border-l-grey-none {
  border-left: 1px none #7a7a7a; }

.border-l-grey-hidden {
  border-left: 1px hidden #7a7a7a; }

.has-text-grey-light {
  color: #b5b5b5; }

.has-background-grey-light {
  background-color: #b5b5b5; }

.border-grey-light {
  border: 1px solid #b5b5b5; }

.border-grey-light-dotted {
  border: 1px dotted #b5b5b5; }

.border-grey-light-dashed {
  border: 1px dashed #b5b5b5; }

.border-grey-light-solid {
  border: 1px solid #b5b5b5; }

.border-grey-light-double {
  border: 1px double #b5b5b5; }

.border-grey-light-groove {
  border: 1px groove #b5b5b5; }

.border-grey-light-ridge {
  border: 1px ridge #b5b5b5; }

.border-grey-light-inset {
  border: 1px inset #b5b5b5; }

.border-grey-light-outset {
  border: 1px outset #b5b5b5; }

.border-grey-light-none {
  border: 1px none #b5b5b5; }

.border-grey-light-hidden {
  border: 1px hidden #b5b5b5; }

.border-t-grey-light {
  border-top: 1px solid #b5b5b5; }

.border-t-grey-light-dotted {
  border-top: 1px dotted #b5b5b5; }

.border-t-grey-light-dashed {
  border-top: 1px dashed #b5b5b5; }

.border-t-grey-light-solid {
  border-top: 1px solid #b5b5b5; }

.border-t-grey-light-double {
  border-top: 1px double #b5b5b5; }

.border-t-grey-light-groove {
  border-top: 1px groove #b5b5b5; }

.border-t-grey-light-ridge {
  border-top: 1px ridge #b5b5b5; }

.border-t-grey-light-inset {
  border-top: 1px inset #b5b5b5; }

.border-t-grey-light-outset {
  border-top: 1px outset #b5b5b5; }

.border-t-grey-light-none {
  border-top: 1px none #b5b5b5; }

.border-t-grey-light-hidden {
  border-top: 1px hidden #b5b5b5; }

.border-r-grey-light {
  border-right: 1px solid #b5b5b5; }

.border-r-grey-light-dotted {
  border-right: 1px dotted #b5b5b5; }

.border-r-grey-light-dashed {
  border-right: 1px dashed #b5b5b5; }

.border-r-grey-light-solid {
  border-right: 1px solid #b5b5b5; }

.border-r-grey-light-double {
  border-right: 1px double #b5b5b5; }

.border-r-grey-light-groove {
  border-right: 1px groove #b5b5b5; }

.border-r-grey-light-ridge {
  border-right: 1px ridge #b5b5b5; }

.border-r-grey-light-inset {
  border-right: 1px inset #b5b5b5; }

.border-r-grey-light-outset {
  border-right: 1px outset #b5b5b5; }

.border-r-grey-light-none {
  border-right: 1px none #b5b5b5; }

.border-r-grey-light-hidden {
  border-right: 1px hidden #b5b5b5; }

.border-b-grey-light {
  border-bottom: 1px solid #b5b5b5; }

.border-b-grey-light-dotted {
  border-bottom: 1px dotted #b5b5b5; }

.border-b-grey-light-dashed {
  border-bottom: 1px dashed #b5b5b5; }

.border-b-grey-light-solid {
  border-bottom: 1px solid #b5b5b5; }

.border-b-grey-light-double {
  border-bottom: 1px double #b5b5b5; }

.border-b-grey-light-groove {
  border-bottom: 1px groove #b5b5b5; }

.border-b-grey-light-ridge {
  border-bottom: 1px ridge #b5b5b5; }

.border-b-grey-light-inset {
  border-bottom: 1px inset #b5b5b5; }

.border-b-grey-light-outset {
  border-bottom: 1px outset #b5b5b5; }

.border-b-grey-light-none {
  border-bottom: 1px none #b5b5b5; }

.border-b-grey-light-hidden {
  border-bottom: 1px hidden #b5b5b5; }

.border-l-grey-light {
  border-left: 1px solid #b5b5b5; }

.border-l-grey-light-dotted {
  border-left: 1px dotted #b5b5b5; }

.border-l-grey-light-dashed {
  border-left: 1px dashed #b5b5b5; }

.border-l-grey-light-solid {
  border-left: 1px solid #b5b5b5; }

.border-l-grey-light-double {
  border-left: 1px double #b5b5b5; }

.border-l-grey-light-groove {
  border-left: 1px groove #b5b5b5; }

.border-l-grey-light-ridge {
  border-left: 1px ridge #b5b5b5; }

.border-l-grey-light-inset {
  border-left: 1px inset #b5b5b5; }

.border-l-grey-light-outset {
  border-left: 1px outset #b5b5b5; }

.border-l-grey-light-none {
  border-left: 1px none #b5b5b5; }

.border-l-grey-light-hidden {
  border-left: 1px hidden #b5b5b5; }

.has-text-grey-lighter {
  color: #e6e6e6; }

.has-background-grey-lighter {
  background-color: #e6e6e6; }

.border-grey-lighter {
  border: 1px solid #e6e6e6; }

.border-grey-lighter-dotted {
  border: 1px dotted #e6e6e6; }

.border-grey-lighter-dashed {
  border: 1px dashed #e6e6e6; }

.border-grey-lighter-solid {
  border: 1px solid #e6e6e6; }

.border-grey-lighter-double {
  border: 1px double #e6e6e6; }

.border-grey-lighter-groove {
  border: 1px groove #e6e6e6; }

.border-grey-lighter-ridge {
  border: 1px ridge #e6e6e6; }

.border-grey-lighter-inset {
  border: 1px inset #e6e6e6; }

.border-grey-lighter-outset {
  border: 1px outset #e6e6e6; }

.border-grey-lighter-none {
  border: 1px none #e6e6e6; }

.border-grey-lighter-hidden {
  border: 1px hidden #e6e6e6; }

.border-t-grey-lighter {
  border-top: 1px solid #e6e6e6; }

.border-t-grey-lighter-dotted {
  border-top: 1px dotted #e6e6e6; }

.border-t-grey-lighter-dashed {
  border-top: 1px dashed #e6e6e6; }

.border-t-grey-lighter-solid {
  border-top: 1px solid #e6e6e6; }

.border-t-grey-lighter-double {
  border-top: 1px double #e6e6e6; }

.border-t-grey-lighter-groove {
  border-top: 1px groove #e6e6e6; }

.border-t-grey-lighter-ridge {
  border-top: 1px ridge #e6e6e6; }

.border-t-grey-lighter-inset {
  border-top: 1px inset #e6e6e6; }

.border-t-grey-lighter-outset {
  border-top: 1px outset #e6e6e6; }

.border-t-grey-lighter-none {
  border-top: 1px none #e6e6e6; }

.border-t-grey-lighter-hidden {
  border-top: 1px hidden #e6e6e6; }

.border-r-grey-lighter {
  border-right: 1px solid #e6e6e6; }

.border-r-grey-lighter-dotted {
  border-right: 1px dotted #e6e6e6; }

.border-r-grey-lighter-dashed {
  border-right: 1px dashed #e6e6e6; }

.border-r-grey-lighter-solid {
  border-right: 1px solid #e6e6e6; }

.border-r-grey-lighter-double {
  border-right: 1px double #e6e6e6; }

.border-r-grey-lighter-groove {
  border-right: 1px groove #e6e6e6; }

.border-r-grey-lighter-ridge {
  border-right: 1px ridge #e6e6e6; }

.border-r-grey-lighter-inset {
  border-right: 1px inset #e6e6e6; }

.border-r-grey-lighter-outset {
  border-right: 1px outset #e6e6e6; }

.border-r-grey-lighter-none {
  border-right: 1px none #e6e6e6; }

.border-r-grey-lighter-hidden {
  border-right: 1px hidden #e6e6e6; }

.border-b-grey-lighter {
  border-bottom: 1px solid #e6e6e6; }

.border-b-grey-lighter-dotted {
  border-bottom: 1px dotted #e6e6e6; }

.border-b-grey-lighter-dashed {
  border-bottom: 1px dashed #e6e6e6; }

.border-b-grey-lighter-solid {
  border-bottom: 1px solid #e6e6e6; }

.border-b-grey-lighter-double {
  border-bottom: 1px double #e6e6e6; }

.border-b-grey-lighter-groove {
  border-bottom: 1px groove #e6e6e6; }

.border-b-grey-lighter-ridge {
  border-bottom: 1px ridge #e6e6e6; }

.border-b-grey-lighter-inset {
  border-bottom: 1px inset #e6e6e6; }

.border-b-grey-lighter-outset {
  border-bottom: 1px outset #e6e6e6; }

.border-b-grey-lighter-none {
  border-bottom: 1px none #e6e6e6; }

.border-b-grey-lighter-hidden {
  border-bottom: 1px hidden #e6e6e6; }

.border-l-grey-lighter {
  border-left: 1px solid #e6e6e6; }

.border-l-grey-lighter-dotted {
  border-left: 1px dotted #e6e6e6; }

.border-l-grey-lighter-dashed {
  border-left: 1px dashed #e6e6e6; }

.border-l-grey-lighter-solid {
  border-left: 1px solid #e6e6e6; }

.border-l-grey-lighter-double {
  border-left: 1px double #e6e6e6; }

.border-l-grey-lighter-groove {
  border-left: 1px groove #e6e6e6; }

.border-l-grey-lighter-ridge {
  border-left: 1px ridge #e6e6e6; }

.border-l-grey-lighter-inset {
  border-left: 1px inset #e6e6e6; }

.border-l-grey-lighter-outset {
  border-left: 1px outset #e6e6e6; }

.border-l-grey-lighter-none {
  border-left: 1px none #e6e6e6; }

.border-l-grey-lighter-hidden {
  border-left: 1px hidden #e6e6e6; }

.has-text-white-ter {
  color: whitesmoke; }

.has-background-white-ter {
  background-color: whitesmoke; }

.border-white-ter {
  border: 1px solid whitesmoke; }

.border-white-ter-dotted {
  border: 1px dotted whitesmoke; }

.border-white-ter-dashed {
  border: 1px dashed whitesmoke; }

.border-white-ter-solid {
  border: 1px solid whitesmoke; }

.border-white-ter-double {
  border: 1px double whitesmoke; }

.border-white-ter-groove {
  border: 1px groove whitesmoke; }

.border-white-ter-ridge {
  border: 1px ridge whitesmoke; }

.border-white-ter-inset {
  border: 1px inset whitesmoke; }

.border-white-ter-outset {
  border: 1px outset whitesmoke; }

.border-white-ter-none {
  border: 1px none whitesmoke; }

.border-white-ter-hidden {
  border: 1px hidden whitesmoke; }

.border-t-white-ter {
  border-top: 1px solid whitesmoke; }

.border-t-white-ter-dotted {
  border-top: 1px dotted whitesmoke; }

.border-t-white-ter-dashed {
  border-top: 1px dashed whitesmoke; }

.border-t-white-ter-solid {
  border-top: 1px solid whitesmoke; }

.border-t-white-ter-double {
  border-top: 1px double whitesmoke; }

.border-t-white-ter-groove {
  border-top: 1px groove whitesmoke; }

.border-t-white-ter-ridge {
  border-top: 1px ridge whitesmoke; }

.border-t-white-ter-inset {
  border-top: 1px inset whitesmoke; }

.border-t-white-ter-outset {
  border-top: 1px outset whitesmoke; }

.border-t-white-ter-none {
  border-top: 1px none whitesmoke; }

.border-t-white-ter-hidden {
  border-top: 1px hidden whitesmoke; }

.border-r-white-ter {
  border-right: 1px solid whitesmoke; }

.border-r-white-ter-dotted {
  border-right: 1px dotted whitesmoke; }

.border-r-white-ter-dashed {
  border-right: 1px dashed whitesmoke; }

.border-r-white-ter-solid {
  border-right: 1px solid whitesmoke; }

.border-r-white-ter-double {
  border-right: 1px double whitesmoke; }

.border-r-white-ter-groove {
  border-right: 1px groove whitesmoke; }

.border-r-white-ter-ridge {
  border-right: 1px ridge whitesmoke; }

.border-r-white-ter-inset {
  border-right: 1px inset whitesmoke; }

.border-r-white-ter-outset {
  border-right: 1px outset whitesmoke; }

.border-r-white-ter-none {
  border-right: 1px none whitesmoke; }

.border-r-white-ter-hidden {
  border-right: 1px hidden whitesmoke; }

.border-b-white-ter {
  border-bottom: 1px solid whitesmoke; }

.border-b-white-ter-dotted {
  border-bottom: 1px dotted whitesmoke; }

.border-b-white-ter-dashed {
  border-bottom: 1px dashed whitesmoke; }

.border-b-white-ter-solid {
  border-bottom: 1px solid whitesmoke; }

.border-b-white-ter-double {
  border-bottom: 1px double whitesmoke; }

.border-b-white-ter-groove {
  border-bottom: 1px groove whitesmoke; }

.border-b-white-ter-ridge {
  border-bottom: 1px ridge whitesmoke; }

.border-b-white-ter-inset {
  border-bottom: 1px inset whitesmoke; }

.border-b-white-ter-outset {
  border-bottom: 1px outset whitesmoke; }

.border-b-white-ter-none {
  border-bottom: 1px none whitesmoke; }

.border-b-white-ter-hidden {
  border-bottom: 1px hidden whitesmoke; }

.border-l-white-ter {
  border-left: 1px solid whitesmoke; }

.border-l-white-ter-dotted {
  border-left: 1px dotted whitesmoke; }

.border-l-white-ter-dashed {
  border-left: 1px dashed whitesmoke; }

.border-l-white-ter-solid {
  border-left: 1px solid whitesmoke; }

.border-l-white-ter-double {
  border-left: 1px double whitesmoke; }

.border-l-white-ter-groove {
  border-left: 1px groove whitesmoke; }

.border-l-white-ter-ridge {
  border-left: 1px ridge whitesmoke; }

.border-l-white-ter-inset {
  border-left: 1px inset whitesmoke; }

.border-l-white-ter-outset {
  border-left: 1px outset whitesmoke; }

.border-l-white-ter-none {
  border-left: 1px none whitesmoke; }

.border-l-white-ter-hidden {
  border-left: 1px hidden whitesmoke; }

.has-text-white-bis {
  color: #fafafa; }

.has-background-white-bis {
  background-color: #fafafa; }

.border-white-bis {
  border: 1px solid #fafafa; }

.border-white-bis-dotted {
  border: 1px dotted #fafafa; }

.border-white-bis-dashed {
  border: 1px dashed #fafafa; }

.border-white-bis-solid {
  border: 1px solid #fafafa; }

.border-white-bis-double {
  border: 1px double #fafafa; }

.border-white-bis-groove {
  border: 1px groove #fafafa; }

.border-white-bis-ridge {
  border: 1px ridge #fafafa; }

.border-white-bis-inset {
  border: 1px inset #fafafa; }

.border-white-bis-outset {
  border: 1px outset #fafafa; }

.border-white-bis-none {
  border: 1px none #fafafa; }

.border-white-bis-hidden {
  border: 1px hidden #fafafa; }

.border-t-white-bis {
  border-top: 1px solid #fafafa; }

.border-t-white-bis-dotted {
  border-top: 1px dotted #fafafa; }

.border-t-white-bis-dashed {
  border-top: 1px dashed #fafafa; }

.border-t-white-bis-solid {
  border-top: 1px solid #fafafa; }

.border-t-white-bis-double {
  border-top: 1px double #fafafa; }

.border-t-white-bis-groove {
  border-top: 1px groove #fafafa; }

.border-t-white-bis-ridge {
  border-top: 1px ridge #fafafa; }

.border-t-white-bis-inset {
  border-top: 1px inset #fafafa; }

.border-t-white-bis-outset {
  border-top: 1px outset #fafafa; }

.border-t-white-bis-none {
  border-top: 1px none #fafafa; }

.border-t-white-bis-hidden {
  border-top: 1px hidden #fafafa; }

.border-r-white-bis {
  border-right: 1px solid #fafafa; }

.border-r-white-bis-dotted {
  border-right: 1px dotted #fafafa; }

.border-r-white-bis-dashed {
  border-right: 1px dashed #fafafa; }

.border-r-white-bis-solid {
  border-right: 1px solid #fafafa; }

.border-r-white-bis-double {
  border-right: 1px double #fafafa; }

.border-r-white-bis-groove {
  border-right: 1px groove #fafafa; }

.border-r-white-bis-ridge {
  border-right: 1px ridge #fafafa; }

.border-r-white-bis-inset {
  border-right: 1px inset #fafafa; }

.border-r-white-bis-outset {
  border-right: 1px outset #fafafa; }

.border-r-white-bis-none {
  border-right: 1px none #fafafa; }

.border-r-white-bis-hidden {
  border-right: 1px hidden #fafafa; }

.border-b-white-bis {
  border-bottom: 1px solid #fafafa; }

.border-b-white-bis-dotted {
  border-bottom: 1px dotted #fafafa; }

.border-b-white-bis-dashed {
  border-bottom: 1px dashed #fafafa; }

.border-b-white-bis-solid {
  border-bottom: 1px solid #fafafa; }

.border-b-white-bis-double {
  border-bottom: 1px double #fafafa; }

.border-b-white-bis-groove {
  border-bottom: 1px groove #fafafa; }

.border-b-white-bis-ridge {
  border-bottom: 1px ridge #fafafa; }

.border-b-white-bis-inset {
  border-bottom: 1px inset #fafafa; }

.border-b-white-bis-outset {
  border-bottom: 1px outset #fafafa; }

.border-b-white-bis-none {
  border-bottom: 1px none #fafafa; }

.border-b-white-bis-hidden {
  border-bottom: 1px hidden #fafafa; }

.border-l-white-bis {
  border-left: 1px solid #fafafa; }

.border-l-white-bis-dotted {
  border-left: 1px dotted #fafafa; }

.border-l-white-bis-dashed {
  border-left: 1px dashed #fafafa; }

.border-l-white-bis-solid {
  border-left: 1px solid #fafafa; }

.border-l-white-bis-double {
  border-left: 1px double #fafafa; }

.border-l-white-bis-groove {
  border-left: 1px groove #fafafa; }

.border-l-white-bis-ridge {
  border-left: 1px ridge #fafafa; }

.border-l-white-bis-inset {
  border-left: 1px inset #fafafa; }

.border-l-white-bis-outset {
  border-left: 1px outset #fafafa; }

.border-l-white-bis-none {
  border-left: 1px none #fafafa; }

.border-l-white-bis-hidden {
  border-left: 1px hidden #fafafa; }

.has-text-white {
  color: white; }

.has-background-white {
  background-color: white; }

.border-white {
  border: 1px solid white; }

.border-white-dotted {
  border: 1px dotted white; }

.border-white-dashed {
  border: 1px dashed white; }

.border-white-solid {
  border: 1px solid white; }

.border-white-double {
  border: 1px double white; }

.border-white-groove {
  border: 1px groove white; }

.border-white-ridge {
  border: 1px ridge white; }

.border-white-inset {
  border: 1px inset white; }

.border-white-outset {
  border: 1px outset white; }

.border-white-none {
  border: 1px none white; }

.border-white-hidden {
  border: 1px hidden white; }

.border-t-white {
  border-top: 1px solid white; }

.border-t-white-dotted {
  border-top: 1px dotted white; }

.border-t-white-dashed {
  border-top: 1px dashed white; }

.border-t-white-solid {
  border-top: 1px solid white; }

.border-t-white-double {
  border-top: 1px double white; }

.border-t-white-groove {
  border-top: 1px groove white; }

.border-t-white-ridge {
  border-top: 1px ridge white; }

.border-t-white-inset {
  border-top: 1px inset white; }

.border-t-white-outset {
  border-top: 1px outset white; }

.border-t-white-none {
  border-top: 1px none white; }

.border-t-white-hidden {
  border-top: 1px hidden white; }

.border-r-white {
  border-right: 1px solid white; }

.border-r-white-dotted {
  border-right: 1px dotted white; }

.border-r-white-dashed {
  border-right: 1px dashed white; }

.border-r-white-solid {
  border-right: 1px solid white; }

.border-r-white-double {
  border-right: 1px double white; }

.border-r-white-groove {
  border-right: 1px groove white; }

.border-r-white-ridge {
  border-right: 1px ridge white; }

.border-r-white-inset {
  border-right: 1px inset white; }

.border-r-white-outset {
  border-right: 1px outset white; }

.border-r-white-none {
  border-right: 1px none white; }

.border-r-white-hidden {
  border-right: 1px hidden white; }

.border-b-white {
  border-bottom: 1px solid white; }

.border-b-white-dotted {
  border-bottom: 1px dotted white; }

.border-b-white-dashed {
  border-bottom: 1px dashed white; }

.border-b-white-solid {
  border-bottom: 1px solid white; }

.border-b-white-double {
  border-bottom: 1px double white; }

.border-b-white-groove {
  border-bottom: 1px groove white; }

.border-b-white-ridge {
  border-bottom: 1px ridge white; }

.border-b-white-inset {
  border-bottom: 1px inset white; }

.border-b-white-outset {
  border-bottom: 1px outset white; }

.border-b-white-none {
  border-bottom: 1px none white; }

.border-b-white-hidden {
  border-bottom: 1px hidden white; }

.border-l-white {
  border-left: 1px solid white; }

.border-l-white-dotted {
  border-left: 1px dotted white; }

.border-l-white-dashed {
  border-left: 1px dashed white; }

.border-l-white-solid {
  border-left: 1px solid white; }

.border-l-white-double {
  border-left: 1px double white; }

.border-l-white-groove {
  border-left: 1px groove white; }

.border-l-white-ridge {
  border-left: 1px ridge white; }

.border-l-white-inset {
  border-left: 1px inset white; }

.border-l-white-outset {
  border-left: 1px outset white; }

.border-l-white-none {
  border-left: 1px none white; }

.border-l-white-hidden {
  border-left: 1px hidden white; }

/* IPHONE */
#iphone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #e6e6e6;
  background: #fcfcfc;
  border-radius: 25px;
  height: 440px;
  width: 248px; }

#iphone #screen {
  margin: 0 auto;
  background: white;
  border: 1px solid whitesmoke;
  border-radius: 25px;
  height: 432px;
  width: 240px; }

.m-none {
  margin: 0; }

.p-none {
  padding: 0; }

.m-t-none {
  margin-top: 0; }

.p-t-none {
  padding-top: 0; }

.m-r-none {
  margin-right: 0; }

.p-r-none {
  padding-right: 0; }

.m-b-none {
  margin-bottom: 0; }

.p-b-none {
  padding-bottom: 0; }

.m-l-none {
  margin-left: 0; }

.p-l-none {
  padding-left: 0; }

.m-xxs {
  margin: 0.125rem; }

.p-xxs {
  padding: 0.125rem; }

.m-t-xxs {
  margin-top: 0.125rem; }

.p-t-xxs {
  padding-top: 0.125rem; }

.m-r-xxs {
  margin-right: 0.125rem; }

.p-r-xxs {
  padding-right: 0.125rem; }

.m-b-xxs {
  margin-bottom: 0.125rem; }

.p-b-xxs {
  padding-bottom: 0.125rem; }

.m-l-xxs {
  margin-left: 0.125rem; }

.p-l-xxs {
  padding-left: 0.125rem; }

.m-xs {
  margin: 0.25rem; }

.p-xs {
  padding: 0.25rem; }

.m-t-xs {
  margin-top: 0.25rem; }

.p-t-xs {
  padding-top: 0.25rem; }

.m-r-xs {
  margin-right: 0.25rem; }

.p-r-xs {
  padding-right: 0.25rem; }

.m-b-xs {
  margin-bottom: 0.25rem; }

.p-b-xs {
  padding-bottom: 0.25rem; }

.m-l-xs {
  margin-left: 0.25rem; }

.p-l-xs {
  padding-left: 0.25rem; }

.m-sm {
  margin: 0.6rem; }

.p-sm {
  padding: 0.6rem; }

.m-t-sm {
  margin-top: 0.6rem; }

.p-t-sm {
  padding-top: 0.6rem; }

.m-r-sm {
  margin-right: 0.6rem; }

.p-r-sm {
  padding-right: 0.6rem; }

.m-b-sm {
  margin-bottom: 0.6rem; }

.p-b-sm {
  padding-bottom: 0.6rem; }

.m-l-sm {
  margin-left: 0.6rem; }

.p-l-sm {
  padding-left: 0.6rem; }

.m-md {
  margin: 1rem; }

.p-md {
  padding: 1rem; }

.m-t-md {
  margin-top: 1rem; }

.p-t-md {
  padding-top: 1rem; }

.m-r-md {
  margin-right: 1rem; }

.p-r-md {
  padding-right: 1rem; }

.m-b-md {
  margin-bottom: 1rem; }

.p-b-md {
  padding-bottom: 1rem; }

.m-l-md {
  margin-left: 1rem; }

.p-l-md {
  padding-left: 1rem; }

.m-lg {
  margin: 2rem; }

.p-lg {
  padding: 2rem; }

.m-t-lg {
  margin-top: 2rem; }

.p-t-lg {
  padding-top: 2rem; }

.m-r-lg {
  margin-right: 2rem; }

.p-r-lg {
  padding-right: 2rem; }

.m-b-lg {
  margin-bottom: 2rem; }

.p-b-lg {
  padding-bottom: 2rem; }

.m-l-lg {
  margin-left: 2rem; }

.p-l-lg {
  padding-left: 2rem; }

.m-xl {
  margin: 4rem; }

.p-xl {
  padding: 4rem; }

.m-t-xl {
  margin-top: 4rem; }

.p-t-xl {
  padding-top: 4rem; }

.m-r-xl {
  margin-right: 4rem; }

.p-r-xl {
  padding-right: 4rem; }

.m-b-xl {
  margin-bottom: 4rem; }

.p-b-xl {
  padding-bottom: 4rem; }

.m-l-xl {
  margin-left: 4rem; }

.p-l-xl {
  padding-left: 4rem; }

.m-xxl {
  margin: 8rem; }

.p-xxl {
  padding: 8rem; }

.m-t-xxl {
  margin-top: 8rem; }

.p-t-xxl {
  padding-top: 8rem; }

.m-r-xxl {
  margin-right: 8rem; }

.p-r-xxl {
  padding-right: 8rem; }

.m-b-xxl {
  margin-bottom: 8rem; }

.p-b-xxl {
  padding-bottom: 8rem; }

.m-l-xxl {
  margin-left: 8rem; }

.p-l-xxl {
  padding-left: 8rem; }

.has-text-primary {
  color: #2d2f7b; }

.has-background-primary {
  background-color: #2d2f7b; }

.border-primary {
  border: 1px solid #2d2f7b; }

.border-m-primary:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #2d2f7b;
  transform: translateY(-50%); }

.border-primary-dotted {
  border: 1px dotted #2d2f7b; }

.border-primary-dashed {
  border: 1px dashed #2d2f7b; }

.border-primary-solid {
  border: 1px solid #2d2f7b; }

.border-primary-double {
  border: 1px double #2d2f7b; }

.border-primary-groove {
  border: 1px groove #2d2f7b; }

.border-primary-ridge {
  border: 1px ridge #2d2f7b; }

.border-primary-inset {
  border: 1px inset #2d2f7b; }

.border-primary-outset {
  border: 1px outset #2d2f7b; }

.border-primary-none {
  border: 1px none #2d2f7b; }

.border-primary-hidden {
  border: 1px hidden #2d2f7b; }

.border-t-primary {
  border-top: 1px solid #2d2f7b; }

.border-t-primary-dotted {
  border-top: 1px dotted #2d2f7b; }

.border-t-primary-dashed {
  border-top: 1px dashed #2d2f7b; }

.border-t-primary-solid {
  border-top: 1px solid #2d2f7b; }

.border-t-primary-double {
  border-top: 1px double #2d2f7b; }

.border-t-primary-groove {
  border-top: 1px groove #2d2f7b; }

.border-t-primary-ridge {
  border-top: 1px ridge #2d2f7b; }

.border-t-primary-inset {
  border-top: 1px inset #2d2f7b; }

.border-t-primary-outset {
  border-top: 1px outset #2d2f7b; }

.border-t-primary-none {
  border-top: 1px none #2d2f7b; }

.border-t-primary-hidden {
  border-top: 1px hidden #2d2f7b; }

.border-r-primary {
  border-right: 1px solid #2d2f7b; }

.border-r-primary-dotted {
  border-right: 1px dotted #2d2f7b; }

.border-r-primary-dashed {
  border-right: 1px dashed #2d2f7b; }

.border-r-primary-solid {
  border-right: 1px solid #2d2f7b; }

.border-r-primary-double {
  border-right: 1px double #2d2f7b; }

.border-r-primary-groove {
  border-right: 1px groove #2d2f7b; }

.border-r-primary-ridge {
  border-right: 1px ridge #2d2f7b; }

.border-r-primary-inset {
  border-right: 1px inset #2d2f7b; }

.border-r-primary-outset {
  border-right: 1px outset #2d2f7b; }

.border-r-primary-none {
  border-right: 1px none #2d2f7b; }

.border-r-primary-hidden {
  border-right: 1px hidden #2d2f7b; }

.border-b-primary {
  border-bottom: 1px solid #2d2f7b; }

.border-b-primary-dotted {
  border-bottom: 1px dotted #2d2f7b; }

.border-b-primary-dashed {
  border-bottom: 1px dashed #2d2f7b; }

.border-b-primary-solid {
  border-bottom: 1px solid #2d2f7b; }

.border-b-primary-double {
  border-bottom: 1px double #2d2f7b; }

.border-b-primary-groove {
  border-bottom: 1px groove #2d2f7b; }

.border-b-primary-ridge {
  border-bottom: 1px ridge #2d2f7b; }

.border-b-primary-inset {
  border-bottom: 1px inset #2d2f7b; }

.border-b-primary-outset {
  border-bottom: 1px outset #2d2f7b; }

.border-b-primary-none {
  border-bottom: 1px none #2d2f7b; }

.border-b-primary-hidden {
  border-bottom: 1px hidden #2d2f7b; }

.border-l-primary {
  border-left: 1px solid #2d2f7b; }

.border-l-primary-dotted {
  border-left: 1px dotted #2d2f7b; }

.border-l-primary-dashed {
  border-left: 1px dashed #2d2f7b; }

.border-l-primary-solid {
  border-left: 1px solid #2d2f7b; }

.border-l-primary-double {
  border-left: 1px double #2d2f7b; }

.border-l-primary-groove {
  border-left: 1px groove #2d2f7b; }

.border-l-primary-ridge {
  border-left: 1px ridge #2d2f7b; }

.border-l-primary-inset {
  border-left: 1px inset #2d2f7b; }

.border-l-primary-outset {
  border-left: 1px outset #2d2f7b; }

.border-l-primary-none {
  border-left: 1px none #2d2f7b; }

.border-l-primary-hidden {
  border-left: 1px hidden #2d2f7b; }

.has-text-primary-bis {
  color: #4d4db3; }

.has-background-primary-bis {
  background-color: #4d4db3; }

.border-primary-bis {
  border: 1px solid #4d4db3; }

.border-m-primary-bis:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #4d4db3;
  transform: translateY(-50%); }

.border-primary-bis-dotted {
  border: 1px dotted #4d4db3; }

.border-primary-bis-dashed {
  border: 1px dashed #4d4db3; }

.border-primary-bis-solid {
  border: 1px solid #4d4db3; }

.border-primary-bis-double {
  border: 1px double #4d4db3; }

.border-primary-bis-groove {
  border: 1px groove #4d4db3; }

.border-primary-bis-ridge {
  border: 1px ridge #4d4db3; }

.border-primary-bis-inset {
  border: 1px inset #4d4db3; }

.border-primary-bis-outset {
  border: 1px outset #4d4db3; }

.border-primary-bis-none {
  border: 1px none #4d4db3; }

.border-primary-bis-hidden {
  border: 1px hidden #4d4db3; }

.border-t-primary-bis {
  border-top: 1px solid #4d4db3; }

.border-t-primary-bis-dotted {
  border-top: 1px dotted #4d4db3; }

.border-t-primary-bis-dashed {
  border-top: 1px dashed #4d4db3; }

.border-t-primary-bis-solid {
  border-top: 1px solid #4d4db3; }

.border-t-primary-bis-double {
  border-top: 1px double #4d4db3; }

.border-t-primary-bis-groove {
  border-top: 1px groove #4d4db3; }

.border-t-primary-bis-ridge {
  border-top: 1px ridge #4d4db3; }

.border-t-primary-bis-inset {
  border-top: 1px inset #4d4db3; }

.border-t-primary-bis-outset {
  border-top: 1px outset #4d4db3; }

.border-t-primary-bis-none {
  border-top: 1px none #4d4db3; }

.border-t-primary-bis-hidden {
  border-top: 1px hidden #4d4db3; }

.border-r-primary-bis {
  border-right: 1px solid #4d4db3; }

.border-r-primary-bis-dotted {
  border-right: 1px dotted #4d4db3; }

.border-r-primary-bis-dashed {
  border-right: 1px dashed #4d4db3; }

.border-r-primary-bis-solid {
  border-right: 1px solid #4d4db3; }

.border-r-primary-bis-double {
  border-right: 1px double #4d4db3; }

.border-r-primary-bis-groove {
  border-right: 1px groove #4d4db3; }

.border-r-primary-bis-ridge {
  border-right: 1px ridge #4d4db3; }

.border-r-primary-bis-inset {
  border-right: 1px inset #4d4db3; }

.border-r-primary-bis-outset {
  border-right: 1px outset #4d4db3; }

.border-r-primary-bis-none {
  border-right: 1px none #4d4db3; }

.border-r-primary-bis-hidden {
  border-right: 1px hidden #4d4db3; }

.border-b-primary-bis {
  border-bottom: 1px solid #4d4db3; }

.border-b-primary-bis-dotted {
  border-bottom: 1px dotted #4d4db3; }

.border-b-primary-bis-dashed {
  border-bottom: 1px dashed #4d4db3; }

.border-b-primary-bis-solid {
  border-bottom: 1px solid #4d4db3; }

.border-b-primary-bis-double {
  border-bottom: 1px double #4d4db3; }

.border-b-primary-bis-groove {
  border-bottom: 1px groove #4d4db3; }

.border-b-primary-bis-ridge {
  border-bottom: 1px ridge #4d4db3; }

.border-b-primary-bis-inset {
  border-bottom: 1px inset #4d4db3; }

.border-b-primary-bis-outset {
  border-bottom: 1px outset #4d4db3; }

.border-b-primary-bis-none {
  border-bottom: 1px none #4d4db3; }

.border-b-primary-bis-hidden {
  border-bottom: 1px hidden #4d4db3; }

.border-l-primary-bis {
  border-left: 1px solid #4d4db3; }

.border-l-primary-bis-dotted {
  border-left: 1px dotted #4d4db3; }

.border-l-primary-bis-dashed {
  border-left: 1px dashed #4d4db3; }

.border-l-primary-bis-solid {
  border-left: 1px solid #4d4db3; }

.border-l-primary-bis-double {
  border-left: 1px double #4d4db3; }

.border-l-primary-bis-groove {
  border-left: 1px groove #4d4db3; }

.border-l-primary-bis-ridge {
  border-left: 1px ridge #4d4db3; }

.border-l-primary-bis-inset {
  border-left: 1px inset #4d4db3; }

.border-l-primary-bis-outset {
  border-left: 1px outset #4d4db3; }

.border-l-primary-bis-none {
  border-left: 1px none #4d4db3; }

.border-l-primary-bis-hidden {
  border-left: 1px hidden #4d4db3; }

.has-text-primary-ter {
  color: #7a7ab8; }

.has-background-primary-ter {
  background-color: #7a7ab8; }

.border-primary-ter {
  border: 1px solid #7a7ab8; }

.border-m-primary-ter:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #7a7ab8;
  transform: translateY(-50%); }

.border-primary-ter-dotted {
  border: 1px dotted #7a7ab8; }

.border-primary-ter-dashed {
  border: 1px dashed #7a7ab8; }

.border-primary-ter-solid {
  border: 1px solid #7a7ab8; }

.border-primary-ter-double {
  border: 1px double #7a7ab8; }

.border-primary-ter-groove {
  border: 1px groove #7a7ab8; }

.border-primary-ter-ridge {
  border: 1px ridge #7a7ab8; }

.border-primary-ter-inset {
  border: 1px inset #7a7ab8; }

.border-primary-ter-outset {
  border: 1px outset #7a7ab8; }

.border-primary-ter-none {
  border: 1px none #7a7ab8; }

.border-primary-ter-hidden {
  border: 1px hidden #7a7ab8; }

.border-t-primary-ter {
  border-top: 1px solid #7a7ab8; }

.border-t-primary-ter-dotted {
  border-top: 1px dotted #7a7ab8; }

.border-t-primary-ter-dashed {
  border-top: 1px dashed #7a7ab8; }

.border-t-primary-ter-solid {
  border-top: 1px solid #7a7ab8; }

.border-t-primary-ter-double {
  border-top: 1px double #7a7ab8; }

.border-t-primary-ter-groove {
  border-top: 1px groove #7a7ab8; }

.border-t-primary-ter-ridge {
  border-top: 1px ridge #7a7ab8; }

.border-t-primary-ter-inset {
  border-top: 1px inset #7a7ab8; }

.border-t-primary-ter-outset {
  border-top: 1px outset #7a7ab8; }

.border-t-primary-ter-none {
  border-top: 1px none #7a7ab8; }

.border-t-primary-ter-hidden {
  border-top: 1px hidden #7a7ab8; }

.border-r-primary-ter {
  border-right: 1px solid #7a7ab8; }

.border-r-primary-ter-dotted {
  border-right: 1px dotted #7a7ab8; }

.border-r-primary-ter-dashed {
  border-right: 1px dashed #7a7ab8; }

.border-r-primary-ter-solid {
  border-right: 1px solid #7a7ab8; }

.border-r-primary-ter-double {
  border-right: 1px double #7a7ab8; }

.border-r-primary-ter-groove {
  border-right: 1px groove #7a7ab8; }

.border-r-primary-ter-ridge {
  border-right: 1px ridge #7a7ab8; }

.border-r-primary-ter-inset {
  border-right: 1px inset #7a7ab8; }

.border-r-primary-ter-outset {
  border-right: 1px outset #7a7ab8; }

.border-r-primary-ter-none {
  border-right: 1px none #7a7ab8; }

.border-r-primary-ter-hidden {
  border-right: 1px hidden #7a7ab8; }

.border-b-primary-ter {
  border-bottom: 1px solid #7a7ab8; }

.border-b-primary-ter-dotted {
  border-bottom: 1px dotted #7a7ab8; }

.border-b-primary-ter-dashed {
  border-bottom: 1px dashed #7a7ab8; }

.border-b-primary-ter-solid {
  border-bottom: 1px solid #7a7ab8; }

.border-b-primary-ter-double {
  border-bottom: 1px double #7a7ab8; }

.border-b-primary-ter-groove {
  border-bottom: 1px groove #7a7ab8; }

.border-b-primary-ter-ridge {
  border-bottom: 1px ridge #7a7ab8; }

.border-b-primary-ter-inset {
  border-bottom: 1px inset #7a7ab8; }

.border-b-primary-ter-outset {
  border-bottom: 1px outset #7a7ab8; }

.border-b-primary-ter-none {
  border-bottom: 1px none #7a7ab8; }

.border-b-primary-ter-hidden {
  border-bottom: 1px hidden #7a7ab8; }

.border-l-primary-ter {
  border-left: 1px solid #7a7ab8; }

.border-l-primary-ter-dotted {
  border-left: 1px dotted #7a7ab8; }

.border-l-primary-ter-dashed {
  border-left: 1px dashed #7a7ab8; }

.border-l-primary-ter-solid {
  border-left: 1px solid #7a7ab8; }

.border-l-primary-ter-double {
  border-left: 1px double #7a7ab8; }

.border-l-primary-ter-groove {
  border-left: 1px groove #7a7ab8; }

.border-l-primary-ter-ridge {
  border-left: 1px ridge #7a7ab8; }

.border-l-primary-ter-inset {
  border-left: 1px inset #7a7ab8; }

.border-l-primary-ter-outset {
  border-left: 1px outset #7a7ab8; }

.border-l-primary-ter-none {
  border-left: 1px none #7a7ab8; }

.border-l-primary-ter-hidden {
  border-left: 1px hidden #7a7ab8; }

.has-text-primary-qua {
  color: #b9b9e9; }

.has-background-primary-qua {
  background-color: #b9b9e9; }

.border-primary-qua {
  border: 1px solid #b9b9e9; }

.border-m-primary-qua:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b9b9e9;
  transform: translateY(-50%); }

.border-primary-qua-dotted {
  border: 1px dotted #b9b9e9; }

.border-primary-qua-dashed {
  border: 1px dashed #b9b9e9; }

.border-primary-qua-solid {
  border: 1px solid #b9b9e9; }

.border-primary-qua-double {
  border: 1px double #b9b9e9; }

.border-primary-qua-groove {
  border: 1px groove #b9b9e9; }

.border-primary-qua-ridge {
  border: 1px ridge #b9b9e9; }

.border-primary-qua-inset {
  border: 1px inset #b9b9e9; }

.border-primary-qua-outset {
  border: 1px outset #b9b9e9; }

.border-primary-qua-none {
  border: 1px none #b9b9e9; }

.border-primary-qua-hidden {
  border: 1px hidden #b9b9e9; }

.border-t-primary-qua {
  border-top: 1px solid #b9b9e9; }

.border-t-primary-qua-dotted {
  border-top: 1px dotted #b9b9e9; }

.border-t-primary-qua-dashed {
  border-top: 1px dashed #b9b9e9; }

.border-t-primary-qua-solid {
  border-top: 1px solid #b9b9e9; }

.border-t-primary-qua-double {
  border-top: 1px double #b9b9e9; }

.border-t-primary-qua-groove {
  border-top: 1px groove #b9b9e9; }

.border-t-primary-qua-ridge {
  border-top: 1px ridge #b9b9e9; }

.border-t-primary-qua-inset {
  border-top: 1px inset #b9b9e9; }

.border-t-primary-qua-outset {
  border-top: 1px outset #b9b9e9; }

.border-t-primary-qua-none {
  border-top: 1px none #b9b9e9; }

.border-t-primary-qua-hidden {
  border-top: 1px hidden #b9b9e9; }

.border-r-primary-qua {
  border-right: 1px solid #b9b9e9; }

.border-r-primary-qua-dotted {
  border-right: 1px dotted #b9b9e9; }

.border-r-primary-qua-dashed {
  border-right: 1px dashed #b9b9e9; }

.border-r-primary-qua-solid {
  border-right: 1px solid #b9b9e9; }

.border-r-primary-qua-double {
  border-right: 1px double #b9b9e9; }

.border-r-primary-qua-groove {
  border-right: 1px groove #b9b9e9; }

.border-r-primary-qua-ridge {
  border-right: 1px ridge #b9b9e9; }

.border-r-primary-qua-inset {
  border-right: 1px inset #b9b9e9; }

.border-r-primary-qua-outset {
  border-right: 1px outset #b9b9e9; }

.border-r-primary-qua-none {
  border-right: 1px none #b9b9e9; }

.border-r-primary-qua-hidden {
  border-right: 1px hidden #b9b9e9; }

.border-b-primary-qua {
  border-bottom: 1px solid #b9b9e9; }

.border-b-primary-qua-dotted {
  border-bottom: 1px dotted #b9b9e9; }

.border-b-primary-qua-dashed {
  border-bottom: 1px dashed #b9b9e9; }

.border-b-primary-qua-solid {
  border-bottom: 1px solid #b9b9e9; }

.border-b-primary-qua-double {
  border-bottom: 1px double #b9b9e9; }

.border-b-primary-qua-groove {
  border-bottom: 1px groove #b9b9e9; }

.border-b-primary-qua-ridge {
  border-bottom: 1px ridge #b9b9e9; }

.border-b-primary-qua-inset {
  border-bottom: 1px inset #b9b9e9; }

.border-b-primary-qua-outset {
  border-bottom: 1px outset #b9b9e9; }

.border-b-primary-qua-none {
  border-bottom: 1px none #b9b9e9; }

.border-b-primary-qua-hidden {
  border-bottom: 1px hidden #b9b9e9; }

.border-l-primary-qua {
  border-left: 1px solid #b9b9e9; }

.border-l-primary-qua-dotted {
  border-left: 1px dotted #b9b9e9; }

.border-l-primary-qua-dashed {
  border-left: 1px dashed #b9b9e9; }

.border-l-primary-qua-solid {
  border-left: 1px solid #b9b9e9; }

.border-l-primary-qua-double {
  border-left: 1px double #b9b9e9; }

.border-l-primary-qua-groove {
  border-left: 1px groove #b9b9e9; }

.border-l-primary-qua-ridge {
  border-left: 1px ridge #b9b9e9; }

.border-l-primary-qua-inset {
  border-left: 1px inset #b9b9e9; }

.border-l-primary-qua-outset {
  border-left: 1px outset #b9b9e9; }

.border-l-primary-qua-none {
  border-left: 1px none #b9b9e9; }

.border-l-primary-qua-hidden {
  border-left: 1px hidden #b9b9e9; }

.has-text-primary-qui {
  color: #b8b8d6; }

.has-background-primary-qui {
  background-color: #b8b8d6; }

.border-primary-qui {
  border: 1px solid #b8b8d6; }

.border-m-primary-qui:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b8b8d6;
  transform: translateY(-50%); }

.border-primary-qui-dotted {
  border: 1px dotted #b8b8d6; }

.border-primary-qui-dashed {
  border: 1px dashed #b8b8d6; }

.border-primary-qui-solid {
  border: 1px solid #b8b8d6; }

.border-primary-qui-double {
  border: 1px double #b8b8d6; }

.border-primary-qui-groove {
  border: 1px groove #b8b8d6; }

.border-primary-qui-ridge {
  border: 1px ridge #b8b8d6; }

.border-primary-qui-inset {
  border: 1px inset #b8b8d6; }

.border-primary-qui-outset {
  border: 1px outset #b8b8d6; }

.border-primary-qui-none {
  border: 1px none #b8b8d6; }

.border-primary-qui-hidden {
  border: 1px hidden #b8b8d6; }

.border-t-primary-qui {
  border-top: 1px solid #b8b8d6; }

.border-t-primary-qui-dotted {
  border-top: 1px dotted #b8b8d6; }

.border-t-primary-qui-dashed {
  border-top: 1px dashed #b8b8d6; }

.border-t-primary-qui-solid {
  border-top: 1px solid #b8b8d6; }

.border-t-primary-qui-double {
  border-top: 1px double #b8b8d6; }

.border-t-primary-qui-groove {
  border-top: 1px groove #b8b8d6; }

.border-t-primary-qui-ridge {
  border-top: 1px ridge #b8b8d6; }

.border-t-primary-qui-inset {
  border-top: 1px inset #b8b8d6; }

.border-t-primary-qui-outset {
  border-top: 1px outset #b8b8d6; }

.border-t-primary-qui-none {
  border-top: 1px none #b8b8d6; }

.border-t-primary-qui-hidden {
  border-top: 1px hidden #b8b8d6; }

.border-r-primary-qui {
  border-right: 1px solid #b8b8d6; }

.border-r-primary-qui-dotted {
  border-right: 1px dotted #b8b8d6; }

.border-r-primary-qui-dashed {
  border-right: 1px dashed #b8b8d6; }

.border-r-primary-qui-solid {
  border-right: 1px solid #b8b8d6; }

.border-r-primary-qui-double {
  border-right: 1px double #b8b8d6; }

.border-r-primary-qui-groove {
  border-right: 1px groove #b8b8d6; }

.border-r-primary-qui-ridge {
  border-right: 1px ridge #b8b8d6; }

.border-r-primary-qui-inset {
  border-right: 1px inset #b8b8d6; }

.border-r-primary-qui-outset {
  border-right: 1px outset #b8b8d6; }

.border-r-primary-qui-none {
  border-right: 1px none #b8b8d6; }

.border-r-primary-qui-hidden {
  border-right: 1px hidden #b8b8d6; }

.border-b-primary-qui {
  border-bottom: 1px solid #b8b8d6; }

.border-b-primary-qui-dotted {
  border-bottom: 1px dotted #b8b8d6; }

.border-b-primary-qui-dashed {
  border-bottom: 1px dashed #b8b8d6; }

.border-b-primary-qui-solid {
  border-bottom: 1px solid #b8b8d6; }

.border-b-primary-qui-double {
  border-bottom: 1px double #b8b8d6; }

.border-b-primary-qui-groove {
  border-bottom: 1px groove #b8b8d6; }

.border-b-primary-qui-ridge {
  border-bottom: 1px ridge #b8b8d6; }

.border-b-primary-qui-inset {
  border-bottom: 1px inset #b8b8d6; }

.border-b-primary-qui-outset {
  border-bottom: 1px outset #b8b8d6; }

.border-b-primary-qui-none {
  border-bottom: 1px none #b8b8d6; }

.border-b-primary-qui-hidden {
  border-bottom: 1px hidden #b8b8d6; }

.border-l-primary-qui {
  border-left: 1px solid #b8b8d6; }

.border-l-primary-qui-dotted {
  border-left: 1px dotted #b8b8d6; }

.border-l-primary-qui-dashed {
  border-left: 1px dashed #b8b8d6; }

.border-l-primary-qui-solid {
  border-left: 1px solid #b8b8d6; }

.border-l-primary-qui-double {
  border-left: 1px double #b8b8d6; }

.border-l-primary-qui-groove {
  border-left: 1px groove #b8b8d6; }

.border-l-primary-qui-ridge {
  border-left: 1px ridge #b8b8d6; }

.border-l-primary-qui-inset {
  border-left: 1px inset #b8b8d6; }

.border-l-primary-qui-outset {
  border-left: 1px outset #b8b8d6; }

.border-l-primary-qui-none {
  border-left: 1px none #b8b8d6; }

.border-l-primary-qui-hidden {
  border-left: 1px hidden #b8b8d6; }

.has-text-primary-light {
  color: #dbdbf0; }

.has-background-primary-light {
  background-color: #dbdbf0; }

.border-primary-light {
  border: 1px solid #dbdbf0; }

.border-m-primary-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dbdbf0;
  transform: translateY(-50%); }

.border-primary-light-dotted {
  border: 1px dotted #dbdbf0; }

.border-primary-light-dashed {
  border: 1px dashed #dbdbf0; }

.border-primary-light-solid {
  border: 1px solid #dbdbf0; }

.border-primary-light-double {
  border: 1px double #dbdbf0; }

.border-primary-light-groove {
  border: 1px groove #dbdbf0; }

.border-primary-light-ridge {
  border: 1px ridge #dbdbf0; }

.border-primary-light-inset {
  border: 1px inset #dbdbf0; }

.border-primary-light-outset {
  border: 1px outset #dbdbf0; }

.border-primary-light-none {
  border: 1px none #dbdbf0; }

.border-primary-light-hidden {
  border: 1px hidden #dbdbf0; }

.border-t-primary-light {
  border-top: 1px solid #dbdbf0; }

.border-t-primary-light-dotted {
  border-top: 1px dotted #dbdbf0; }

.border-t-primary-light-dashed {
  border-top: 1px dashed #dbdbf0; }

.border-t-primary-light-solid {
  border-top: 1px solid #dbdbf0; }

.border-t-primary-light-double {
  border-top: 1px double #dbdbf0; }

.border-t-primary-light-groove {
  border-top: 1px groove #dbdbf0; }

.border-t-primary-light-ridge {
  border-top: 1px ridge #dbdbf0; }

.border-t-primary-light-inset {
  border-top: 1px inset #dbdbf0; }

.border-t-primary-light-outset {
  border-top: 1px outset #dbdbf0; }

.border-t-primary-light-none {
  border-top: 1px none #dbdbf0; }

.border-t-primary-light-hidden {
  border-top: 1px hidden #dbdbf0; }

.border-r-primary-light {
  border-right: 1px solid #dbdbf0; }

.border-r-primary-light-dotted {
  border-right: 1px dotted #dbdbf0; }

.border-r-primary-light-dashed {
  border-right: 1px dashed #dbdbf0; }

.border-r-primary-light-solid {
  border-right: 1px solid #dbdbf0; }

.border-r-primary-light-double {
  border-right: 1px double #dbdbf0; }

.border-r-primary-light-groove {
  border-right: 1px groove #dbdbf0; }

.border-r-primary-light-ridge {
  border-right: 1px ridge #dbdbf0; }

.border-r-primary-light-inset {
  border-right: 1px inset #dbdbf0; }

.border-r-primary-light-outset {
  border-right: 1px outset #dbdbf0; }

.border-r-primary-light-none {
  border-right: 1px none #dbdbf0; }

.border-r-primary-light-hidden {
  border-right: 1px hidden #dbdbf0; }

.border-b-primary-light {
  border-bottom: 1px solid #dbdbf0; }

.border-b-primary-light-dotted {
  border-bottom: 1px dotted #dbdbf0; }

.border-b-primary-light-dashed {
  border-bottom: 1px dashed #dbdbf0; }

.border-b-primary-light-solid {
  border-bottom: 1px solid #dbdbf0; }

.border-b-primary-light-double {
  border-bottom: 1px double #dbdbf0; }

.border-b-primary-light-groove {
  border-bottom: 1px groove #dbdbf0; }

.border-b-primary-light-ridge {
  border-bottom: 1px ridge #dbdbf0; }

.border-b-primary-light-inset {
  border-bottom: 1px inset #dbdbf0; }

.border-b-primary-light-outset {
  border-bottom: 1px outset #dbdbf0; }

.border-b-primary-light-none {
  border-bottom: 1px none #dbdbf0; }

.border-b-primary-light-hidden {
  border-bottom: 1px hidden #dbdbf0; }

.border-l-primary-light {
  border-left: 1px solid #dbdbf0; }

.border-l-primary-light-dotted {
  border-left: 1px dotted #dbdbf0; }

.border-l-primary-light-dashed {
  border-left: 1px dashed #dbdbf0; }

.border-l-primary-light-solid {
  border-left: 1px solid #dbdbf0; }

.border-l-primary-light-double {
  border-left: 1px double #dbdbf0; }

.border-l-primary-light-groove {
  border-left: 1px groove #dbdbf0; }

.border-l-primary-light-ridge {
  border-left: 1px ridge #dbdbf0; }

.border-l-primary-light-inset {
  border-left: 1px inset #dbdbf0; }

.border-l-primary-light-outset {
  border-left: 1px outset #dbdbf0; }

.border-l-primary-light-none {
  border-left: 1px none #dbdbf0; }

.border-l-primary-light-hidden {
  border-left: 1px hidden #dbdbf0; }

.has-text-primary-lighter {
  color: #ededf7; }

.has-background-primary-lighter {
  background-color: #ededf7; }

.border-primary-lighter {
  border: 1px solid #ededf7; }

.border-m-primary-lighter:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ededf7;
  transform: translateY(-50%); }

.border-primary-lighter-dotted {
  border: 1px dotted #ededf7; }

.border-primary-lighter-dashed {
  border: 1px dashed #ededf7; }

.border-primary-lighter-solid {
  border: 1px solid #ededf7; }

.border-primary-lighter-double {
  border: 1px double #ededf7; }

.border-primary-lighter-groove {
  border: 1px groove #ededf7; }

.border-primary-lighter-ridge {
  border: 1px ridge #ededf7; }

.border-primary-lighter-inset {
  border: 1px inset #ededf7; }

.border-primary-lighter-outset {
  border: 1px outset #ededf7; }

.border-primary-lighter-none {
  border: 1px none #ededf7; }

.border-primary-lighter-hidden {
  border: 1px hidden #ededf7; }

.border-t-primary-lighter {
  border-top: 1px solid #ededf7; }

.border-t-primary-lighter-dotted {
  border-top: 1px dotted #ededf7; }

.border-t-primary-lighter-dashed {
  border-top: 1px dashed #ededf7; }

.border-t-primary-lighter-solid {
  border-top: 1px solid #ededf7; }

.border-t-primary-lighter-double {
  border-top: 1px double #ededf7; }

.border-t-primary-lighter-groove {
  border-top: 1px groove #ededf7; }

.border-t-primary-lighter-ridge {
  border-top: 1px ridge #ededf7; }

.border-t-primary-lighter-inset {
  border-top: 1px inset #ededf7; }

.border-t-primary-lighter-outset {
  border-top: 1px outset #ededf7; }

.border-t-primary-lighter-none {
  border-top: 1px none #ededf7; }

.border-t-primary-lighter-hidden {
  border-top: 1px hidden #ededf7; }

.border-r-primary-lighter {
  border-right: 1px solid #ededf7; }

.border-r-primary-lighter-dotted {
  border-right: 1px dotted #ededf7; }

.border-r-primary-lighter-dashed {
  border-right: 1px dashed #ededf7; }

.border-r-primary-lighter-solid {
  border-right: 1px solid #ededf7; }

.border-r-primary-lighter-double {
  border-right: 1px double #ededf7; }

.border-r-primary-lighter-groove {
  border-right: 1px groove #ededf7; }

.border-r-primary-lighter-ridge {
  border-right: 1px ridge #ededf7; }

.border-r-primary-lighter-inset {
  border-right: 1px inset #ededf7; }

.border-r-primary-lighter-outset {
  border-right: 1px outset #ededf7; }

.border-r-primary-lighter-none {
  border-right: 1px none #ededf7; }

.border-r-primary-lighter-hidden {
  border-right: 1px hidden #ededf7; }

.border-b-primary-lighter {
  border-bottom: 1px solid #ededf7; }

.border-b-primary-lighter-dotted {
  border-bottom: 1px dotted #ededf7; }

.border-b-primary-lighter-dashed {
  border-bottom: 1px dashed #ededf7; }

.border-b-primary-lighter-solid {
  border-bottom: 1px solid #ededf7; }

.border-b-primary-lighter-double {
  border-bottom: 1px double #ededf7; }

.border-b-primary-lighter-groove {
  border-bottom: 1px groove #ededf7; }

.border-b-primary-lighter-ridge {
  border-bottom: 1px ridge #ededf7; }

.border-b-primary-lighter-inset {
  border-bottom: 1px inset #ededf7; }

.border-b-primary-lighter-outset {
  border-bottom: 1px outset #ededf7; }

.border-b-primary-lighter-none {
  border-bottom: 1px none #ededf7; }

.border-b-primary-lighter-hidden {
  border-bottom: 1px hidden #ededf7; }

.border-l-primary-lighter {
  border-left: 1px solid #ededf7; }

.border-l-primary-lighter-dotted {
  border-left: 1px dotted #ededf7; }

.border-l-primary-lighter-dashed {
  border-left: 1px dashed #ededf7; }

.border-l-primary-lighter-solid {
  border-left: 1px solid #ededf7; }

.border-l-primary-lighter-double {
  border-left: 1px double #ededf7; }

.border-l-primary-lighter-groove {
  border-left: 1px groove #ededf7; }

.border-l-primary-lighter-ridge {
  border-left: 1px ridge #ededf7; }

.border-l-primary-lighter-inset {
  border-left: 1px inset #ededf7; }

.border-l-primary-lighter-outset {
  border-left: 1px outset #ededf7; }

.border-l-primary-lighter-none {
  border-left: 1px none #ededf7; }

.border-l-primary-lighter-hidden {
  border-left: 1px hidden #ededf7; }

.has-text-primary-lighbis {
  color: #f4f4fa; }

.has-background-primary-lighbis {
  background-color: #f4f4fa; }

.border-primary-lighbis {
  border: 1px solid #f4f4fa; }

.border-m-primary-lighbis:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f4f4fa;
  transform: translateY(-50%); }

.border-primary-lighbis-dotted {
  border: 1px dotted #f4f4fa; }

.border-primary-lighbis-dashed {
  border: 1px dashed #f4f4fa; }

.border-primary-lighbis-solid {
  border: 1px solid #f4f4fa; }

.border-primary-lighbis-double {
  border: 1px double #f4f4fa; }

.border-primary-lighbis-groove {
  border: 1px groove #f4f4fa; }

.border-primary-lighbis-ridge {
  border: 1px ridge #f4f4fa; }

.border-primary-lighbis-inset {
  border: 1px inset #f4f4fa; }

.border-primary-lighbis-outset {
  border: 1px outset #f4f4fa; }

.border-primary-lighbis-none {
  border: 1px none #f4f4fa; }

.border-primary-lighbis-hidden {
  border: 1px hidden #f4f4fa; }

.border-t-primary-lighbis {
  border-top: 1px solid #f4f4fa; }

.border-t-primary-lighbis-dotted {
  border-top: 1px dotted #f4f4fa; }

.border-t-primary-lighbis-dashed {
  border-top: 1px dashed #f4f4fa; }

.border-t-primary-lighbis-solid {
  border-top: 1px solid #f4f4fa; }

.border-t-primary-lighbis-double {
  border-top: 1px double #f4f4fa; }

.border-t-primary-lighbis-groove {
  border-top: 1px groove #f4f4fa; }

.border-t-primary-lighbis-ridge {
  border-top: 1px ridge #f4f4fa; }

.border-t-primary-lighbis-inset {
  border-top: 1px inset #f4f4fa; }

.border-t-primary-lighbis-outset {
  border-top: 1px outset #f4f4fa; }

.border-t-primary-lighbis-none {
  border-top: 1px none #f4f4fa; }

.border-t-primary-lighbis-hidden {
  border-top: 1px hidden #f4f4fa; }

.border-r-primary-lighbis {
  border-right: 1px solid #f4f4fa; }

.border-r-primary-lighbis-dotted {
  border-right: 1px dotted #f4f4fa; }

.border-r-primary-lighbis-dashed {
  border-right: 1px dashed #f4f4fa; }

.border-r-primary-lighbis-solid {
  border-right: 1px solid #f4f4fa; }

.border-r-primary-lighbis-double {
  border-right: 1px double #f4f4fa; }

.border-r-primary-lighbis-groove {
  border-right: 1px groove #f4f4fa; }

.border-r-primary-lighbis-ridge {
  border-right: 1px ridge #f4f4fa; }

.border-r-primary-lighbis-inset {
  border-right: 1px inset #f4f4fa; }

.border-r-primary-lighbis-outset {
  border-right: 1px outset #f4f4fa; }

.border-r-primary-lighbis-none {
  border-right: 1px none #f4f4fa; }

.border-r-primary-lighbis-hidden {
  border-right: 1px hidden #f4f4fa; }

.border-b-primary-lighbis {
  border-bottom: 1px solid #f4f4fa; }

.border-b-primary-lighbis-dotted {
  border-bottom: 1px dotted #f4f4fa; }

.border-b-primary-lighbis-dashed {
  border-bottom: 1px dashed #f4f4fa; }

.border-b-primary-lighbis-solid {
  border-bottom: 1px solid #f4f4fa; }

.border-b-primary-lighbis-double {
  border-bottom: 1px double #f4f4fa; }

.border-b-primary-lighbis-groove {
  border-bottom: 1px groove #f4f4fa; }

.border-b-primary-lighbis-ridge {
  border-bottom: 1px ridge #f4f4fa; }

.border-b-primary-lighbis-inset {
  border-bottom: 1px inset #f4f4fa; }

.border-b-primary-lighbis-outset {
  border-bottom: 1px outset #f4f4fa; }

.border-b-primary-lighbis-none {
  border-bottom: 1px none #f4f4fa; }

.border-b-primary-lighbis-hidden {
  border-bottom: 1px hidden #f4f4fa; }

.border-l-primary-lighbis {
  border-left: 1px solid #f4f4fa; }

.border-l-primary-lighbis-dotted {
  border-left: 1px dotted #f4f4fa; }

.border-l-primary-lighbis-dashed {
  border-left: 1px dashed #f4f4fa; }

.border-l-primary-lighbis-solid {
  border-left: 1px solid #f4f4fa; }

.border-l-primary-lighbis-double {
  border-left: 1px double #f4f4fa; }

.border-l-primary-lighbis-groove {
  border-left: 1px groove #f4f4fa; }

.border-l-primary-lighbis-ridge {
  border-left: 1px ridge #f4f4fa; }

.border-l-primary-lighbis-inset {
  border-left: 1px inset #f4f4fa; }

.border-l-primary-lighbis-outset {
  border-left: 1px outset #f4f4fa; }

.border-l-primary-lighbis-none {
  border-left: 1px none #f4f4fa; }

.border-l-primary-lighbis-hidden {
  border-left: 1px hidden #f4f4fa; }

.has-text-secondary {
  color: #eb6666; }

.has-background-secondary {
  background-color: #eb6666; }

.border-secondary {
  border: 1px solid #eb6666; }

.border-m-secondary:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #eb6666;
  transform: translateY(-50%); }

.border-secondary-dotted {
  border: 1px dotted #eb6666; }

.border-secondary-dashed {
  border: 1px dashed #eb6666; }

.border-secondary-solid {
  border: 1px solid #eb6666; }

.border-secondary-double {
  border: 1px double #eb6666; }

.border-secondary-groove {
  border: 1px groove #eb6666; }

.border-secondary-ridge {
  border: 1px ridge #eb6666; }

.border-secondary-inset {
  border: 1px inset #eb6666; }

.border-secondary-outset {
  border: 1px outset #eb6666; }

.border-secondary-none {
  border: 1px none #eb6666; }

.border-secondary-hidden {
  border: 1px hidden #eb6666; }

.border-t-secondary {
  border-top: 1px solid #eb6666; }

.border-t-secondary-dotted {
  border-top: 1px dotted #eb6666; }

.border-t-secondary-dashed {
  border-top: 1px dashed #eb6666; }

.border-t-secondary-solid {
  border-top: 1px solid #eb6666; }

.border-t-secondary-double {
  border-top: 1px double #eb6666; }

.border-t-secondary-groove {
  border-top: 1px groove #eb6666; }

.border-t-secondary-ridge {
  border-top: 1px ridge #eb6666; }

.border-t-secondary-inset {
  border-top: 1px inset #eb6666; }

.border-t-secondary-outset {
  border-top: 1px outset #eb6666; }

.border-t-secondary-none {
  border-top: 1px none #eb6666; }

.border-t-secondary-hidden {
  border-top: 1px hidden #eb6666; }

.border-r-secondary {
  border-right: 1px solid #eb6666; }

.border-r-secondary-dotted {
  border-right: 1px dotted #eb6666; }

.border-r-secondary-dashed {
  border-right: 1px dashed #eb6666; }

.border-r-secondary-solid {
  border-right: 1px solid #eb6666; }

.border-r-secondary-double {
  border-right: 1px double #eb6666; }

.border-r-secondary-groove {
  border-right: 1px groove #eb6666; }

.border-r-secondary-ridge {
  border-right: 1px ridge #eb6666; }

.border-r-secondary-inset {
  border-right: 1px inset #eb6666; }

.border-r-secondary-outset {
  border-right: 1px outset #eb6666; }

.border-r-secondary-none {
  border-right: 1px none #eb6666; }

.border-r-secondary-hidden {
  border-right: 1px hidden #eb6666; }

.border-b-secondary {
  border-bottom: 1px solid #eb6666; }

.border-b-secondary-dotted {
  border-bottom: 1px dotted #eb6666; }

.border-b-secondary-dashed {
  border-bottom: 1px dashed #eb6666; }

.border-b-secondary-solid {
  border-bottom: 1px solid #eb6666; }

.border-b-secondary-double {
  border-bottom: 1px double #eb6666; }

.border-b-secondary-groove {
  border-bottom: 1px groove #eb6666; }

.border-b-secondary-ridge {
  border-bottom: 1px ridge #eb6666; }

.border-b-secondary-inset {
  border-bottom: 1px inset #eb6666; }

.border-b-secondary-outset {
  border-bottom: 1px outset #eb6666; }

.border-b-secondary-none {
  border-bottom: 1px none #eb6666; }

.border-b-secondary-hidden {
  border-bottom: 1px hidden #eb6666; }

.border-l-secondary {
  border-left: 1px solid #eb6666; }

.border-l-secondary-dotted {
  border-left: 1px dotted #eb6666; }

.border-l-secondary-dashed {
  border-left: 1px dashed #eb6666; }

.border-l-secondary-solid {
  border-left: 1px solid #eb6666; }

.border-l-secondary-double {
  border-left: 1px double #eb6666; }

.border-l-secondary-groove {
  border-left: 1px groove #eb6666; }

.border-l-secondary-ridge {
  border-left: 1px ridge #eb6666; }

.border-l-secondary-inset {
  border-left: 1px inset #eb6666; }

.border-l-secondary-outset {
  border-left: 1px outset #eb6666; }

.border-l-secondary-none {
  border-left: 1px none #eb6666; }

.border-l-secondary-hidden {
  border-left: 1px hidden #eb6666; }

.has-text-danger {
  color: #cc3333; }

.has-background-danger {
  background-color: #cc3333; }

.border-danger {
  border: 1px solid #cc3333; }

.border-m-danger:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #cc3333;
  transform: translateY(-50%); }

.border-danger-dotted {
  border: 1px dotted #cc3333; }

.border-danger-dashed {
  border: 1px dashed #cc3333; }

.border-danger-solid {
  border: 1px solid #cc3333; }

.border-danger-double {
  border: 1px double #cc3333; }

.border-danger-groove {
  border: 1px groove #cc3333; }

.border-danger-ridge {
  border: 1px ridge #cc3333; }

.border-danger-inset {
  border: 1px inset #cc3333; }

.border-danger-outset {
  border: 1px outset #cc3333; }

.border-danger-none {
  border: 1px none #cc3333; }

.border-danger-hidden {
  border: 1px hidden #cc3333; }

.border-t-danger {
  border-top: 1px solid #cc3333; }

.border-t-danger-dotted {
  border-top: 1px dotted #cc3333; }

.border-t-danger-dashed {
  border-top: 1px dashed #cc3333; }

.border-t-danger-solid {
  border-top: 1px solid #cc3333; }

.border-t-danger-double {
  border-top: 1px double #cc3333; }

.border-t-danger-groove {
  border-top: 1px groove #cc3333; }

.border-t-danger-ridge {
  border-top: 1px ridge #cc3333; }

.border-t-danger-inset {
  border-top: 1px inset #cc3333; }

.border-t-danger-outset {
  border-top: 1px outset #cc3333; }

.border-t-danger-none {
  border-top: 1px none #cc3333; }

.border-t-danger-hidden {
  border-top: 1px hidden #cc3333; }

.border-r-danger {
  border-right: 1px solid #cc3333; }

.border-r-danger-dotted {
  border-right: 1px dotted #cc3333; }

.border-r-danger-dashed {
  border-right: 1px dashed #cc3333; }

.border-r-danger-solid {
  border-right: 1px solid #cc3333; }

.border-r-danger-double {
  border-right: 1px double #cc3333; }

.border-r-danger-groove {
  border-right: 1px groove #cc3333; }

.border-r-danger-ridge {
  border-right: 1px ridge #cc3333; }

.border-r-danger-inset {
  border-right: 1px inset #cc3333; }

.border-r-danger-outset {
  border-right: 1px outset #cc3333; }

.border-r-danger-none {
  border-right: 1px none #cc3333; }

.border-r-danger-hidden {
  border-right: 1px hidden #cc3333; }

.border-b-danger {
  border-bottom: 1px solid #cc3333; }

.border-b-danger-dotted {
  border-bottom: 1px dotted #cc3333; }

.border-b-danger-dashed {
  border-bottom: 1px dashed #cc3333; }

.border-b-danger-solid {
  border-bottom: 1px solid #cc3333; }

.border-b-danger-double {
  border-bottom: 1px double #cc3333; }

.border-b-danger-groove {
  border-bottom: 1px groove #cc3333; }

.border-b-danger-ridge {
  border-bottom: 1px ridge #cc3333; }

.border-b-danger-inset {
  border-bottom: 1px inset #cc3333; }

.border-b-danger-outset {
  border-bottom: 1px outset #cc3333; }

.border-b-danger-none {
  border-bottom: 1px none #cc3333; }

.border-b-danger-hidden {
  border-bottom: 1px hidden #cc3333; }

.border-l-danger {
  border-left: 1px solid #cc3333; }

.border-l-danger-dotted {
  border-left: 1px dotted #cc3333; }

.border-l-danger-dashed {
  border-left: 1px dashed #cc3333; }

.border-l-danger-solid {
  border-left: 1px solid #cc3333; }

.border-l-danger-double {
  border-left: 1px double #cc3333; }

.border-l-danger-groove {
  border-left: 1px groove #cc3333; }

.border-l-danger-ridge {
  border-left: 1px ridge #cc3333; }

.border-l-danger-inset {
  border-left: 1px inset #cc3333; }

.border-l-danger-outset {
  border-left: 1px outset #cc3333; }

.border-l-danger-none {
  border-left: 1px none #cc3333; }

.border-l-danger-hidden {
  border-left: 1px hidden #cc3333; }

.has-text-danger-light {
  color: #f6dada; }

.has-background-danger-light {
  background-color: #f6dada; }

.border-danger-light {
  border: 1px solid #f6dada; }

.border-m-danger-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f6dada;
  transform: translateY(-50%); }

.border-danger-light-dotted {
  border: 1px dotted #f6dada; }

.border-danger-light-dashed {
  border: 1px dashed #f6dada; }

.border-danger-light-solid {
  border: 1px solid #f6dada; }

.border-danger-light-double {
  border: 1px double #f6dada; }

.border-danger-light-groove {
  border: 1px groove #f6dada; }

.border-danger-light-ridge {
  border: 1px ridge #f6dada; }

.border-danger-light-inset {
  border: 1px inset #f6dada; }

.border-danger-light-outset {
  border: 1px outset #f6dada; }

.border-danger-light-none {
  border: 1px none #f6dada; }

.border-danger-light-hidden {
  border: 1px hidden #f6dada; }

.border-t-danger-light {
  border-top: 1px solid #f6dada; }

.border-t-danger-light-dotted {
  border-top: 1px dotted #f6dada; }

.border-t-danger-light-dashed {
  border-top: 1px dashed #f6dada; }

.border-t-danger-light-solid {
  border-top: 1px solid #f6dada; }

.border-t-danger-light-double {
  border-top: 1px double #f6dada; }

.border-t-danger-light-groove {
  border-top: 1px groove #f6dada; }

.border-t-danger-light-ridge {
  border-top: 1px ridge #f6dada; }

.border-t-danger-light-inset {
  border-top: 1px inset #f6dada; }

.border-t-danger-light-outset {
  border-top: 1px outset #f6dada; }

.border-t-danger-light-none {
  border-top: 1px none #f6dada; }

.border-t-danger-light-hidden {
  border-top: 1px hidden #f6dada; }

.border-r-danger-light {
  border-right: 1px solid #f6dada; }

.border-r-danger-light-dotted {
  border-right: 1px dotted #f6dada; }

.border-r-danger-light-dashed {
  border-right: 1px dashed #f6dada; }

.border-r-danger-light-solid {
  border-right: 1px solid #f6dada; }

.border-r-danger-light-double {
  border-right: 1px double #f6dada; }

.border-r-danger-light-groove {
  border-right: 1px groove #f6dada; }

.border-r-danger-light-ridge {
  border-right: 1px ridge #f6dada; }

.border-r-danger-light-inset {
  border-right: 1px inset #f6dada; }

.border-r-danger-light-outset {
  border-right: 1px outset #f6dada; }

.border-r-danger-light-none {
  border-right: 1px none #f6dada; }

.border-r-danger-light-hidden {
  border-right: 1px hidden #f6dada; }

.border-b-danger-light {
  border-bottom: 1px solid #f6dada; }

.border-b-danger-light-dotted {
  border-bottom: 1px dotted #f6dada; }

.border-b-danger-light-dashed {
  border-bottom: 1px dashed #f6dada; }

.border-b-danger-light-solid {
  border-bottom: 1px solid #f6dada; }

.border-b-danger-light-double {
  border-bottom: 1px double #f6dada; }

.border-b-danger-light-groove {
  border-bottom: 1px groove #f6dada; }

.border-b-danger-light-ridge {
  border-bottom: 1px ridge #f6dada; }

.border-b-danger-light-inset {
  border-bottom: 1px inset #f6dada; }

.border-b-danger-light-outset {
  border-bottom: 1px outset #f6dada; }

.border-b-danger-light-none {
  border-bottom: 1px none #f6dada; }

.border-b-danger-light-hidden {
  border-bottom: 1px hidden #f6dada; }

.border-l-danger-light {
  border-left: 1px solid #f6dada; }

.border-l-danger-light-dotted {
  border-left: 1px dotted #f6dada; }

.border-l-danger-light-dashed {
  border-left: 1px dashed #f6dada; }

.border-l-danger-light-solid {
  border-left: 1px solid #f6dada; }

.border-l-danger-light-double {
  border-left: 1px double #f6dada; }

.border-l-danger-light-groove {
  border-left: 1px groove #f6dada; }

.border-l-danger-light-ridge {
  border-left: 1px ridge #f6dada; }

.border-l-danger-light-inset {
  border-left: 1px inset #f6dada; }

.border-l-danger-light-outset {
  border-left: 1px outset #f6dada; }

.border-l-danger-light-none {
  border-left: 1px none #f6dada; }

.border-l-danger-light-hidden {
  border-left: 1px hidden #f6dada; }

.has-text-warning {
  color: #ffd500; }

.has-background-warning {
  background-color: #ffd500; }

.border-warning {
  border: 1px solid #ffd500; }

.border-m-warning:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ffd500;
  transform: translateY(-50%); }

.border-warning-dotted {
  border: 1px dotted #ffd500; }

.border-warning-dashed {
  border: 1px dashed #ffd500; }

.border-warning-solid {
  border: 1px solid #ffd500; }

.border-warning-double {
  border: 1px double #ffd500; }

.border-warning-groove {
  border: 1px groove #ffd500; }

.border-warning-ridge {
  border: 1px ridge #ffd500; }

.border-warning-inset {
  border: 1px inset #ffd500; }

.border-warning-outset {
  border: 1px outset #ffd500; }

.border-warning-none {
  border: 1px none #ffd500; }

.border-warning-hidden {
  border: 1px hidden #ffd500; }

.border-t-warning {
  border-top: 1px solid #ffd500; }

.border-t-warning-dotted {
  border-top: 1px dotted #ffd500; }

.border-t-warning-dashed {
  border-top: 1px dashed #ffd500; }

.border-t-warning-solid {
  border-top: 1px solid #ffd500; }

.border-t-warning-double {
  border-top: 1px double #ffd500; }

.border-t-warning-groove {
  border-top: 1px groove #ffd500; }

.border-t-warning-ridge {
  border-top: 1px ridge #ffd500; }

.border-t-warning-inset {
  border-top: 1px inset #ffd500; }

.border-t-warning-outset {
  border-top: 1px outset #ffd500; }

.border-t-warning-none {
  border-top: 1px none #ffd500; }

.border-t-warning-hidden {
  border-top: 1px hidden #ffd500; }

.border-r-warning {
  border-right: 1px solid #ffd500; }

.border-r-warning-dotted {
  border-right: 1px dotted #ffd500; }

.border-r-warning-dashed {
  border-right: 1px dashed #ffd500; }

.border-r-warning-solid {
  border-right: 1px solid #ffd500; }

.border-r-warning-double {
  border-right: 1px double #ffd500; }

.border-r-warning-groove {
  border-right: 1px groove #ffd500; }

.border-r-warning-ridge {
  border-right: 1px ridge #ffd500; }

.border-r-warning-inset {
  border-right: 1px inset #ffd500; }

.border-r-warning-outset {
  border-right: 1px outset #ffd500; }

.border-r-warning-none {
  border-right: 1px none #ffd500; }

.border-r-warning-hidden {
  border-right: 1px hidden #ffd500; }

.border-b-warning {
  border-bottom: 1px solid #ffd500; }

.border-b-warning-dotted {
  border-bottom: 1px dotted #ffd500; }

.border-b-warning-dashed {
  border-bottom: 1px dashed #ffd500; }

.border-b-warning-solid {
  border-bottom: 1px solid #ffd500; }

.border-b-warning-double {
  border-bottom: 1px double #ffd500; }

.border-b-warning-groove {
  border-bottom: 1px groove #ffd500; }

.border-b-warning-ridge {
  border-bottom: 1px ridge #ffd500; }

.border-b-warning-inset {
  border-bottom: 1px inset #ffd500; }

.border-b-warning-outset {
  border-bottom: 1px outset #ffd500; }

.border-b-warning-none {
  border-bottom: 1px none #ffd500; }

.border-b-warning-hidden {
  border-bottom: 1px hidden #ffd500; }

.border-l-warning {
  border-left: 1px solid #ffd500; }

.border-l-warning-dotted {
  border-left: 1px dotted #ffd500; }

.border-l-warning-dashed {
  border-left: 1px dashed #ffd500; }

.border-l-warning-solid {
  border-left: 1px solid #ffd500; }

.border-l-warning-double {
  border-left: 1px double #ffd500; }

.border-l-warning-groove {
  border-left: 1px groove #ffd500; }

.border-l-warning-ridge {
  border-left: 1px ridge #ffd500; }

.border-l-warning-inset {
  border-left: 1px inset #ffd500; }

.border-l-warning-outset {
  border-left: 1px outset #ffd500; }

.border-l-warning-none {
  border-left: 1px none #ffd500; }

.border-l-warning-hidden {
  border-left: 1px hidden #ffd500; }

.has-text-success {
  color: #66cc33; }

.has-background-success {
  background-color: #66cc33; }

.border-success {
  border: 1px solid #66cc33; }

.border-m-success:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #66cc33;
  transform: translateY(-50%); }

.border-success-dotted {
  border: 1px dotted #66cc33; }

.border-success-dashed {
  border: 1px dashed #66cc33; }

.border-success-solid {
  border: 1px solid #66cc33; }

.border-success-double {
  border: 1px double #66cc33; }

.border-success-groove {
  border: 1px groove #66cc33; }

.border-success-ridge {
  border: 1px ridge #66cc33; }

.border-success-inset {
  border: 1px inset #66cc33; }

.border-success-outset {
  border: 1px outset #66cc33; }

.border-success-none {
  border: 1px none #66cc33; }

.border-success-hidden {
  border: 1px hidden #66cc33; }

.border-t-success {
  border-top: 1px solid #66cc33; }

.border-t-success-dotted {
  border-top: 1px dotted #66cc33; }

.border-t-success-dashed {
  border-top: 1px dashed #66cc33; }

.border-t-success-solid {
  border-top: 1px solid #66cc33; }

.border-t-success-double {
  border-top: 1px double #66cc33; }

.border-t-success-groove {
  border-top: 1px groove #66cc33; }

.border-t-success-ridge {
  border-top: 1px ridge #66cc33; }

.border-t-success-inset {
  border-top: 1px inset #66cc33; }

.border-t-success-outset {
  border-top: 1px outset #66cc33; }

.border-t-success-none {
  border-top: 1px none #66cc33; }

.border-t-success-hidden {
  border-top: 1px hidden #66cc33; }

.border-r-success {
  border-right: 1px solid #66cc33; }

.border-r-success-dotted {
  border-right: 1px dotted #66cc33; }

.border-r-success-dashed {
  border-right: 1px dashed #66cc33; }

.border-r-success-solid {
  border-right: 1px solid #66cc33; }

.border-r-success-double {
  border-right: 1px double #66cc33; }

.border-r-success-groove {
  border-right: 1px groove #66cc33; }

.border-r-success-ridge {
  border-right: 1px ridge #66cc33; }

.border-r-success-inset {
  border-right: 1px inset #66cc33; }

.border-r-success-outset {
  border-right: 1px outset #66cc33; }

.border-r-success-none {
  border-right: 1px none #66cc33; }

.border-r-success-hidden {
  border-right: 1px hidden #66cc33; }

.border-b-success {
  border-bottom: 1px solid #66cc33; }

.border-b-success-dotted {
  border-bottom: 1px dotted #66cc33; }

.border-b-success-dashed {
  border-bottom: 1px dashed #66cc33; }

.border-b-success-solid {
  border-bottom: 1px solid #66cc33; }

.border-b-success-double {
  border-bottom: 1px double #66cc33; }

.border-b-success-groove {
  border-bottom: 1px groove #66cc33; }

.border-b-success-ridge {
  border-bottom: 1px ridge #66cc33; }

.border-b-success-inset {
  border-bottom: 1px inset #66cc33; }

.border-b-success-outset {
  border-bottom: 1px outset #66cc33; }

.border-b-success-none {
  border-bottom: 1px none #66cc33; }

.border-b-success-hidden {
  border-bottom: 1px hidden #66cc33; }

.border-l-success {
  border-left: 1px solid #66cc33; }

.border-l-success-dotted {
  border-left: 1px dotted #66cc33; }

.border-l-success-dashed {
  border-left: 1px dashed #66cc33; }

.border-l-success-solid {
  border-left: 1px solid #66cc33; }

.border-l-success-double {
  border-left: 1px double #66cc33; }

.border-l-success-groove {
  border-left: 1px groove #66cc33; }

.border-l-success-ridge {
  border-left: 1px ridge #66cc33; }

.border-l-success-inset {
  border-left: 1px inset #66cc33; }

.border-l-success-outset {
  border-left: 1px outset #66cc33; }

.border-l-success-none {
  border-left: 1px none #66cc33; }

.border-l-success-hidden {
  border-left: 1px hidden #66cc33; }

.has-text-success-light {
  color: #dbffe0; }

.has-background-success-light {
  background-color: #dbffe0; }

.border-success-light {
  border: 1px solid #dbffe0; }

.border-m-success-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dbffe0;
  transform: translateY(-50%); }

.border-success-light-dotted {
  border: 1px dotted #dbffe0; }

.border-success-light-dashed {
  border: 1px dashed #dbffe0; }

.border-success-light-solid {
  border: 1px solid #dbffe0; }

.border-success-light-double {
  border: 1px double #dbffe0; }

.border-success-light-groove {
  border: 1px groove #dbffe0; }

.border-success-light-ridge {
  border: 1px ridge #dbffe0; }

.border-success-light-inset {
  border: 1px inset #dbffe0; }

.border-success-light-outset {
  border: 1px outset #dbffe0; }

.border-success-light-none {
  border: 1px none #dbffe0; }

.border-success-light-hidden {
  border: 1px hidden #dbffe0; }

.border-t-success-light {
  border-top: 1px solid #dbffe0; }

.border-t-success-light-dotted {
  border-top: 1px dotted #dbffe0; }

.border-t-success-light-dashed {
  border-top: 1px dashed #dbffe0; }

.border-t-success-light-solid {
  border-top: 1px solid #dbffe0; }

.border-t-success-light-double {
  border-top: 1px double #dbffe0; }

.border-t-success-light-groove {
  border-top: 1px groove #dbffe0; }

.border-t-success-light-ridge {
  border-top: 1px ridge #dbffe0; }

.border-t-success-light-inset {
  border-top: 1px inset #dbffe0; }

.border-t-success-light-outset {
  border-top: 1px outset #dbffe0; }

.border-t-success-light-none {
  border-top: 1px none #dbffe0; }

.border-t-success-light-hidden {
  border-top: 1px hidden #dbffe0; }

.border-r-success-light {
  border-right: 1px solid #dbffe0; }

.border-r-success-light-dotted {
  border-right: 1px dotted #dbffe0; }

.border-r-success-light-dashed {
  border-right: 1px dashed #dbffe0; }

.border-r-success-light-solid {
  border-right: 1px solid #dbffe0; }

.border-r-success-light-double {
  border-right: 1px double #dbffe0; }

.border-r-success-light-groove {
  border-right: 1px groove #dbffe0; }

.border-r-success-light-ridge {
  border-right: 1px ridge #dbffe0; }

.border-r-success-light-inset {
  border-right: 1px inset #dbffe0; }

.border-r-success-light-outset {
  border-right: 1px outset #dbffe0; }

.border-r-success-light-none {
  border-right: 1px none #dbffe0; }

.border-r-success-light-hidden {
  border-right: 1px hidden #dbffe0; }

.border-b-success-light {
  border-bottom: 1px solid #dbffe0; }

.border-b-success-light-dotted {
  border-bottom: 1px dotted #dbffe0; }

.border-b-success-light-dashed {
  border-bottom: 1px dashed #dbffe0; }

.border-b-success-light-solid {
  border-bottom: 1px solid #dbffe0; }

.border-b-success-light-double {
  border-bottom: 1px double #dbffe0; }

.border-b-success-light-groove {
  border-bottom: 1px groove #dbffe0; }

.border-b-success-light-ridge {
  border-bottom: 1px ridge #dbffe0; }

.border-b-success-light-inset {
  border-bottom: 1px inset #dbffe0; }

.border-b-success-light-outset {
  border-bottom: 1px outset #dbffe0; }

.border-b-success-light-none {
  border-bottom: 1px none #dbffe0; }

.border-b-success-light-hidden {
  border-bottom: 1px hidden #dbffe0; }

.border-l-success-light {
  border-left: 1px solid #dbffe0; }

.border-l-success-light-dotted {
  border-left: 1px dotted #dbffe0; }

.border-l-success-light-dashed {
  border-left: 1px dashed #dbffe0; }

.border-l-success-light-solid {
  border-left: 1px solid #dbffe0; }

.border-l-success-light-double {
  border-left: 1px double #dbffe0; }

.border-l-success-light-groove {
  border-left: 1px groove #dbffe0; }

.border-l-success-light-ridge {
  border-left: 1px ridge #dbffe0; }

.border-l-success-light-inset {
  border-left: 1px inset #dbffe0; }

.border-l-success-light-outset {
  border-left: 1px outset #dbffe0; }

.border-l-success-light-none {
  border-left: 1px none #dbffe0; }

.border-l-success-light-hidden {
  border-left: 1px hidden #dbffe0; }

.has-text-info {
  color: #209fee; }

.has-background-info {
  background-color: #209fee; }

.border-info {
  border: 1px solid #209fee; }

.border-m-info:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #209fee;
  transform: translateY(-50%); }

.border-info-dotted {
  border: 1px dotted #209fee; }

.border-info-dashed {
  border: 1px dashed #209fee; }

.border-info-solid {
  border: 1px solid #209fee; }

.border-info-double {
  border: 1px double #209fee; }

.border-info-groove {
  border: 1px groove #209fee; }

.border-info-ridge {
  border: 1px ridge #209fee; }

.border-info-inset {
  border: 1px inset #209fee; }

.border-info-outset {
  border: 1px outset #209fee; }

.border-info-none {
  border: 1px none #209fee; }

.border-info-hidden {
  border: 1px hidden #209fee; }

.border-t-info {
  border-top: 1px solid #209fee; }

.border-t-info-dotted {
  border-top: 1px dotted #209fee; }

.border-t-info-dashed {
  border-top: 1px dashed #209fee; }

.border-t-info-solid {
  border-top: 1px solid #209fee; }

.border-t-info-double {
  border-top: 1px double #209fee; }

.border-t-info-groove {
  border-top: 1px groove #209fee; }

.border-t-info-ridge {
  border-top: 1px ridge #209fee; }

.border-t-info-inset {
  border-top: 1px inset #209fee; }

.border-t-info-outset {
  border-top: 1px outset #209fee; }

.border-t-info-none {
  border-top: 1px none #209fee; }

.border-t-info-hidden {
  border-top: 1px hidden #209fee; }

.border-r-info {
  border-right: 1px solid #209fee; }

.border-r-info-dotted {
  border-right: 1px dotted #209fee; }

.border-r-info-dashed {
  border-right: 1px dashed #209fee; }

.border-r-info-solid {
  border-right: 1px solid #209fee; }

.border-r-info-double {
  border-right: 1px double #209fee; }

.border-r-info-groove {
  border-right: 1px groove #209fee; }

.border-r-info-ridge {
  border-right: 1px ridge #209fee; }

.border-r-info-inset {
  border-right: 1px inset #209fee; }

.border-r-info-outset {
  border-right: 1px outset #209fee; }

.border-r-info-none {
  border-right: 1px none #209fee; }

.border-r-info-hidden {
  border-right: 1px hidden #209fee; }

.border-b-info {
  border-bottom: 1px solid #209fee; }

.border-b-info-dotted {
  border-bottom: 1px dotted #209fee; }

.border-b-info-dashed {
  border-bottom: 1px dashed #209fee; }

.border-b-info-solid {
  border-bottom: 1px solid #209fee; }

.border-b-info-double {
  border-bottom: 1px double #209fee; }

.border-b-info-groove {
  border-bottom: 1px groove #209fee; }

.border-b-info-ridge {
  border-bottom: 1px ridge #209fee; }

.border-b-info-inset {
  border-bottom: 1px inset #209fee; }

.border-b-info-outset {
  border-bottom: 1px outset #209fee; }

.border-b-info-none {
  border-bottom: 1px none #209fee; }

.border-b-info-hidden {
  border-bottom: 1px hidden #209fee; }

.border-l-info {
  border-left: 1px solid #209fee; }

.border-l-info-dotted {
  border-left: 1px dotted #209fee; }

.border-l-info-dashed {
  border-left: 1px dashed #209fee; }

.border-l-info-solid {
  border-left: 1px solid #209fee; }

.border-l-info-double {
  border-left: 1px double #209fee; }

.border-l-info-groove {
  border-left: 1px groove #209fee; }

.border-l-info-ridge {
  border-left: 1px ridge #209fee; }

.border-l-info-inset {
  border-left: 1px inset #209fee; }

.border-l-info-outset {
  border-left: 1px outset #209fee; }

.border-l-info-none {
  border-left: 1px none #209fee; }

.border-l-info-hidden {
  border-left: 1px hidden #209fee; }

.has-text-black {
  color: #0a0a0a; }

.has-background-black {
  background-color: #0a0a0a; }

.border-black {
  border: 1px solid #0a0a0a; }

.border-black-dotted {
  border: 1px dotted #0a0a0a; }

.border-black-dashed {
  border: 1px dashed #0a0a0a; }

.border-black-solid {
  border: 1px solid #0a0a0a; }

.border-black-double {
  border: 1px double #0a0a0a; }

.border-black-groove {
  border: 1px groove #0a0a0a; }

.border-black-ridge {
  border: 1px ridge #0a0a0a; }

.border-black-inset {
  border: 1px inset #0a0a0a; }

.border-black-outset {
  border: 1px outset #0a0a0a; }

.border-black-none {
  border: 1px none #0a0a0a; }

.border-black-hidden {
  border: 1px hidden #0a0a0a; }

.border-t-black {
  border-top: 1px solid #0a0a0a; }

.border-t-black-dotted {
  border-top: 1px dotted #0a0a0a; }

.border-t-black-dashed {
  border-top: 1px dashed #0a0a0a; }

.border-t-black-solid {
  border-top: 1px solid #0a0a0a; }

.border-t-black-double {
  border-top: 1px double #0a0a0a; }

.border-t-black-groove {
  border-top: 1px groove #0a0a0a; }

.border-t-black-ridge {
  border-top: 1px ridge #0a0a0a; }

.border-t-black-inset {
  border-top: 1px inset #0a0a0a; }

.border-t-black-outset {
  border-top: 1px outset #0a0a0a; }

.border-t-black-none {
  border-top: 1px none #0a0a0a; }

.border-t-black-hidden {
  border-top: 1px hidden #0a0a0a; }

.border-r-black {
  border-right: 1px solid #0a0a0a; }

.border-r-black-dotted {
  border-right: 1px dotted #0a0a0a; }

.border-r-black-dashed {
  border-right: 1px dashed #0a0a0a; }

.border-r-black-solid {
  border-right: 1px solid #0a0a0a; }

.border-r-black-double {
  border-right: 1px double #0a0a0a; }

.border-r-black-groove {
  border-right: 1px groove #0a0a0a; }

.border-r-black-ridge {
  border-right: 1px ridge #0a0a0a; }

.border-r-black-inset {
  border-right: 1px inset #0a0a0a; }

.border-r-black-outset {
  border-right: 1px outset #0a0a0a; }

.border-r-black-none {
  border-right: 1px none #0a0a0a; }

.border-r-black-hidden {
  border-right: 1px hidden #0a0a0a; }

.border-b-black {
  border-bottom: 1px solid #0a0a0a; }

.border-b-black-dotted {
  border-bottom: 1px dotted #0a0a0a; }

.border-b-black-dashed {
  border-bottom: 1px dashed #0a0a0a; }

.border-b-black-solid {
  border-bottom: 1px solid #0a0a0a; }

.border-b-black-double {
  border-bottom: 1px double #0a0a0a; }

.border-b-black-groove {
  border-bottom: 1px groove #0a0a0a; }

.border-b-black-ridge {
  border-bottom: 1px ridge #0a0a0a; }

.border-b-black-inset {
  border-bottom: 1px inset #0a0a0a; }

.border-b-black-outset {
  border-bottom: 1px outset #0a0a0a; }

.border-b-black-none {
  border-bottom: 1px none #0a0a0a; }

.border-b-black-hidden {
  border-bottom: 1px hidden #0a0a0a; }

.border-l-black {
  border-left: 1px solid #0a0a0a; }

.border-l-black-dotted {
  border-left: 1px dotted #0a0a0a; }

.border-l-black-dashed {
  border-left: 1px dashed #0a0a0a; }

.border-l-black-solid {
  border-left: 1px solid #0a0a0a; }

.border-l-black-double {
  border-left: 1px double #0a0a0a; }

.border-l-black-groove {
  border-left: 1px groove #0a0a0a; }

.border-l-black-ridge {
  border-left: 1px ridge #0a0a0a; }

.border-l-black-inset {
  border-left: 1px inset #0a0a0a; }

.border-l-black-outset {
  border-left: 1px outset #0a0a0a; }

.border-l-black-none {
  border-left: 1px none #0a0a0a; }

.border-l-black-hidden {
  border-left: 1px hidden #0a0a0a; }

.has-text-black-bis {
  color: #121212; }

.has-background-black-bis {
  background-color: #121212; }

.border-black-bis {
  border: 1px solid #121212; }

.border-black-bis-dotted {
  border: 1px dotted #121212; }

.border-black-bis-dashed {
  border: 1px dashed #121212; }

.border-black-bis-solid {
  border: 1px solid #121212; }

.border-black-bis-double {
  border: 1px double #121212; }

.border-black-bis-groove {
  border: 1px groove #121212; }

.border-black-bis-ridge {
  border: 1px ridge #121212; }

.border-black-bis-inset {
  border: 1px inset #121212; }

.border-black-bis-outset {
  border: 1px outset #121212; }

.border-black-bis-none {
  border: 1px none #121212; }

.border-black-bis-hidden {
  border: 1px hidden #121212; }

.border-t-black-bis {
  border-top: 1px solid #121212; }

.border-t-black-bis-dotted {
  border-top: 1px dotted #121212; }

.border-t-black-bis-dashed {
  border-top: 1px dashed #121212; }

.border-t-black-bis-solid {
  border-top: 1px solid #121212; }

.border-t-black-bis-double {
  border-top: 1px double #121212; }

.border-t-black-bis-groove {
  border-top: 1px groove #121212; }

.border-t-black-bis-ridge {
  border-top: 1px ridge #121212; }

.border-t-black-bis-inset {
  border-top: 1px inset #121212; }

.border-t-black-bis-outset {
  border-top: 1px outset #121212; }

.border-t-black-bis-none {
  border-top: 1px none #121212; }

.border-t-black-bis-hidden {
  border-top: 1px hidden #121212; }

.border-r-black-bis {
  border-right: 1px solid #121212; }

.border-r-black-bis-dotted {
  border-right: 1px dotted #121212; }

.border-r-black-bis-dashed {
  border-right: 1px dashed #121212; }

.border-r-black-bis-solid {
  border-right: 1px solid #121212; }

.border-r-black-bis-double {
  border-right: 1px double #121212; }

.border-r-black-bis-groove {
  border-right: 1px groove #121212; }

.border-r-black-bis-ridge {
  border-right: 1px ridge #121212; }

.border-r-black-bis-inset {
  border-right: 1px inset #121212; }

.border-r-black-bis-outset {
  border-right: 1px outset #121212; }

.border-r-black-bis-none {
  border-right: 1px none #121212; }

.border-r-black-bis-hidden {
  border-right: 1px hidden #121212; }

.border-b-black-bis {
  border-bottom: 1px solid #121212; }

.border-b-black-bis-dotted {
  border-bottom: 1px dotted #121212; }

.border-b-black-bis-dashed {
  border-bottom: 1px dashed #121212; }

.border-b-black-bis-solid {
  border-bottom: 1px solid #121212; }

.border-b-black-bis-double {
  border-bottom: 1px double #121212; }

.border-b-black-bis-groove {
  border-bottom: 1px groove #121212; }

.border-b-black-bis-ridge {
  border-bottom: 1px ridge #121212; }

.border-b-black-bis-inset {
  border-bottom: 1px inset #121212; }

.border-b-black-bis-outset {
  border-bottom: 1px outset #121212; }

.border-b-black-bis-none {
  border-bottom: 1px none #121212; }

.border-b-black-bis-hidden {
  border-bottom: 1px hidden #121212; }

.border-l-black-bis {
  border-left: 1px solid #121212; }

.border-l-black-bis-dotted {
  border-left: 1px dotted #121212; }

.border-l-black-bis-dashed {
  border-left: 1px dashed #121212; }

.border-l-black-bis-solid {
  border-left: 1px solid #121212; }

.border-l-black-bis-double {
  border-left: 1px double #121212; }

.border-l-black-bis-groove {
  border-left: 1px groove #121212; }

.border-l-black-bis-ridge {
  border-left: 1px ridge #121212; }

.border-l-black-bis-inset {
  border-left: 1px inset #121212; }

.border-l-black-bis-outset {
  border-left: 1px outset #121212; }

.border-l-black-bis-none {
  border-left: 1px none #121212; }

.border-l-black-bis-hidden {
  border-left: 1px hidden #121212; }

.has-text-black-ter {
  color: #242424; }

.has-background-black-ter {
  background-color: #242424; }

.border-black-ter {
  border: 1px solid #242424; }

.border-black-ter-dotted {
  border: 1px dotted #242424; }

.border-black-ter-dashed {
  border: 1px dashed #242424; }

.border-black-ter-solid {
  border: 1px solid #242424; }

.border-black-ter-double {
  border: 1px double #242424; }

.border-black-ter-groove {
  border: 1px groove #242424; }

.border-black-ter-ridge {
  border: 1px ridge #242424; }

.border-black-ter-inset {
  border: 1px inset #242424; }

.border-black-ter-outset {
  border: 1px outset #242424; }

.border-black-ter-none {
  border: 1px none #242424; }

.border-black-ter-hidden {
  border: 1px hidden #242424; }

.border-t-black-ter {
  border-top: 1px solid #242424; }

.border-t-black-ter-dotted {
  border-top: 1px dotted #242424; }

.border-t-black-ter-dashed {
  border-top: 1px dashed #242424; }

.border-t-black-ter-solid {
  border-top: 1px solid #242424; }

.border-t-black-ter-double {
  border-top: 1px double #242424; }

.border-t-black-ter-groove {
  border-top: 1px groove #242424; }

.border-t-black-ter-ridge {
  border-top: 1px ridge #242424; }

.border-t-black-ter-inset {
  border-top: 1px inset #242424; }

.border-t-black-ter-outset {
  border-top: 1px outset #242424; }

.border-t-black-ter-none {
  border-top: 1px none #242424; }

.border-t-black-ter-hidden {
  border-top: 1px hidden #242424; }

.border-r-black-ter {
  border-right: 1px solid #242424; }

.border-r-black-ter-dotted {
  border-right: 1px dotted #242424; }

.border-r-black-ter-dashed {
  border-right: 1px dashed #242424; }

.border-r-black-ter-solid {
  border-right: 1px solid #242424; }

.border-r-black-ter-double {
  border-right: 1px double #242424; }

.border-r-black-ter-groove {
  border-right: 1px groove #242424; }

.border-r-black-ter-ridge {
  border-right: 1px ridge #242424; }

.border-r-black-ter-inset {
  border-right: 1px inset #242424; }

.border-r-black-ter-outset {
  border-right: 1px outset #242424; }

.border-r-black-ter-none {
  border-right: 1px none #242424; }

.border-r-black-ter-hidden {
  border-right: 1px hidden #242424; }

.border-b-black-ter {
  border-bottom: 1px solid #242424; }

.border-b-black-ter-dotted {
  border-bottom: 1px dotted #242424; }

.border-b-black-ter-dashed {
  border-bottom: 1px dashed #242424; }

.border-b-black-ter-solid {
  border-bottom: 1px solid #242424; }

.border-b-black-ter-double {
  border-bottom: 1px double #242424; }

.border-b-black-ter-groove {
  border-bottom: 1px groove #242424; }

.border-b-black-ter-ridge {
  border-bottom: 1px ridge #242424; }

.border-b-black-ter-inset {
  border-bottom: 1px inset #242424; }

.border-b-black-ter-outset {
  border-bottom: 1px outset #242424; }

.border-b-black-ter-none {
  border-bottom: 1px none #242424; }

.border-b-black-ter-hidden {
  border-bottom: 1px hidden #242424; }

.border-l-black-ter {
  border-left: 1px solid #242424; }

.border-l-black-ter-dotted {
  border-left: 1px dotted #242424; }

.border-l-black-ter-dashed {
  border-left: 1px dashed #242424; }

.border-l-black-ter-solid {
  border-left: 1px solid #242424; }

.border-l-black-ter-double {
  border-left: 1px double #242424; }

.border-l-black-ter-groove {
  border-left: 1px groove #242424; }

.border-l-black-ter-ridge {
  border-left: 1px ridge #242424; }

.border-l-black-ter-inset {
  border-left: 1px inset #242424; }

.border-l-black-ter-outset {
  border-left: 1px outset #242424; }

.border-l-black-ter-none {
  border-left: 1px none #242424; }

.border-l-black-ter-hidden {
  border-left: 1px hidden #242424; }

.has-text-grey-darker {
  color: #363636; }

.has-background-grey-darker {
  background-color: #363636; }

.border-grey-darker {
  border: 1px solid #363636; }

.border-grey-darker-dotted {
  border: 1px dotted #363636; }

.border-grey-darker-dashed {
  border: 1px dashed #363636; }

.border-grey-darker-solid {
  border: 1px solid #363636; }

.border-grey-darker-double {
  border: 1px double #363636; }

.border-grey-darker-groove {
  border: 1px groove #363636; }

.border-grey-darker-ridge {
  border: 1px ridge #363636; }

.border-grey-darker-inset {
  border: 1px inset #363636; }

.border-grey-darker-outset {
  border: 1px outset #363636; }

.border-grey-darker-none {
  border: 1px none #363636; }

.border-grey-darker-hidden {
  border: 1px hidden #363636; }

.border-t-grey-darker {
  border-top: 1px solid #363636; }

.border-t-grey-darker-dotted {
  border-top: 1px dotted #363636; }

.border-t-grey-darker-dashed {
  border-top: 1px dashed #363636; }

.border-t-grey-darker-solid {
  border-top: 1px solid #363636; }

.border-t-grey-darker-double {
  border-top: 1px double #363636; }

.border-t-grey-darker-groove {
  border-top: 1px groove #363636; }

.border-t-grey-darker-ridge {
  border-top: 1px ridge #363636; }

.border-t-grey-darker-inset {
  border-top: 1px inset #363636; }

.border-t-grey-darker-outset {
  border-top: 1px outset #363636; }

.border-t-grey-darker-none {
  border-top: 1px none #363636; }

.border-t-grey-darker-hidden {
  border-top: 1px hidden #363636; }

.border-r-grey-darker {
  border-right: 1px solid #363636; }

.border-r-grey-darker-dotted {
  border-right: 1px dotted #363636; }

.border-r-grey-darker-dashed {
  border-right: 1px dashed #363636; }

.border-r-grey-darker-solid {
  border-right: 1px solid #363636; }

.border-r-grey-darker-double {
  border-right: 1px double #363636; }

.border-r-grey-darker-groove {
  border-right: 1px groove #363636; }

.border-r-grey-darker-ridge {
  border-right: 1px ridge #363636; }

.border-r-grey-darker-inset {
  border-right: 1px inset #363636; }

.border-r-grey-darker-outset {
  border-right: 1px outset #363636; }

.border-r-grey-darker-none {
  border-right: 1px none #363636; }

.border-r-grey-darker-hidden {
  border-right: 1px hidden #363636; }

.border-b-grey-darker {
  border-bottom: 1px solid #363636; }

.border-b-grey-darker-dotted {
  border-bottom: 1px dotted #363636; }

.border-b-grey-darker-dashed {
  border-bottom: 1px dashed #363636; }

.border-b-grey-darker-solid {
  border-bottom: 1px solid #363636; }

.border-b-grey-darker-double {
  border-bottom: 1px double #363636; }

.border-b-grey-darker-groove {
  border-bottom: 1px groove #363636; }

.border-b-grey-darker-ridge {
  border-bottom: 1px ridge #363636; }

.border-b-grey-darker-inset {
  border-bottom: 1px inset #363636; }

.border-b-grey-darker-outset {
  border-bottom: 1px outset #363636; }

.border-b-grey-darker-none {
  border-bottom: 1px none #363636; }

.border-b-grey-darker-hidden {
  border-bottom: 1px hidden #363636; }

.border-l-grey-darker {
  border-left: 1px solid #363636; }

.border-l-grey-darker-dotted {
  border-left: 1px dotted #363636; }

.border-l-grey-darker-dashed {
  border-left: 1px dashed #363636; }

.border-l-grey-darker-solid {
  border-left: 1px solid #363636; }

.border-l-grey-darker-double {
  border-left: 1px double #363636; }

.border-l-grey-darker-groove {
  border-left: 1px groove #363636; }

.border-l-grey-darker-ridge {
  border-left: 1px ridge #363636; }

.border-l-grey-darker-inset {
  border-left: 1px inset #363636; }

.border-l-grey-darker-outset {
  border-left: 1px outset #363636; }

.border-l-grey-darker-none {
  border-left: 1px none #363636; }

.border-l-grey-darker-hidden {
  border-left: 1px hidden #363636; }

.has-text-grey-dark {
  color: #4a4a4a; }

.has-background-grey-dark {
  background-color: #4a4a4a; }

.border-grey-dark {
  border: 1px solid #4a4a4a; }

.border-grey-dark-dotted {
  border: 1px dotted #4a4a4a; }

.border-grey-dark-dashed {
  border: 1px dashed #4a4a4a; }

.border-grey-dark-solid {
  border: 1px solid #4a4a4a; }

.border-grey-dark-double {
  border: 1px double #4a4a4a; }

.border-grey-dark-groove {
  border: 1px groove #4a4a4a; }

.border-grey-dark-ridge {
  border: 1px ridge #4a4a4a; }

.border-grey-dark-inset {
  border: 1px inset #4a4a4a; }

.border-grey-dark-outset {
  border: 1px outset #4a4a4a; }

.border-grey-dark-none {
  border: 1px none #4a4a4a; }

.border-grey-dark-hidden {
  border: 1px hidden #4a4a4a; }

.border-t-grey-dark {
  border-top: 1px solid #4a4a4a; }

.border-t-grey-dark-dotted {
  border-top: 1px dotted #4a4a4a; }

.border-t-grey-dark-dashed {
  border-top: 1px dashed #4a4a4a; }

.border-t-grey-dark-solid {
  border-top: 1px solid #4a4a4a; }

.border-t-grey-dark-double {
  border-top: 1px double #4a4a4a; }

.border-t-grey-dark-groove {
  border-top: 1px groove #4a4a4a; }

.border-t-grey-dark-ridge {
  border-top: 1px ridge #4a4a4a; }

.border-t-grey-dark-inset {
  border-top: 1px inset #4a4a4a; }

.border-t-grey-dark-outset {
  border-top: 1px outset #4a4a4a; }

.border-t-grey-dark-none {
  border-top: 1px none #4a4a4a; }

.border-t-grey-dark-hidden {
  border-top: 1px hidden #4a4a4a; }

.border-r-grey-dark {
  border-right: 1px solid #4a4a4a; }

.border-r-grey-dark-dotted {
  border-right: 1px dotted #4a4a4a; }

.border-r-grey-dark-dashed {
  border-right: 1px dashed #4a4a4a; }

.border-r-grey-dark-solid {
  border-right: 1px solid #4a4a4a; }

.border-r-grey-dark-double {
  border-right: 1px double #4a4a4a; }

.border-r-grey-dark-groove {
  border-right: 1px groove #4a4a4a; }

.border-r-grey-dark-ridge {
  border-right: 1px ridge #4a4a4a; }

.border-r-grey-dark-inset {
  border-right: 1px inset #4a4a4a; }

.border-r-grey-dark-outset {
  border-right: 1px outset #4a4a4a; }

.border-r-grey-dark-none {
  border-right: 1px none #4a4a4a; }

.border-r-grey-dark-hidden {
  border-right: 1px hidden #4a4a4a; }

.border-b-grey-dark {
  border-bottom: 1px solid #4a4a4a; }

.border-b-grey-dark-dotted {
  border-bottom: 1px dotted #4a4a4a; }

.border-b-grey-dark-dashed {
  border-bottom: 1px dashed #4a4a4a; }

.border-b-grey-dark-solid {
  border-bottom: 1px solid #4a4a4a; }

.border-b-grey-dark-double {
  border-bottom: 1px double #4a4a4a; }

.border-b-grey-dark-groove {
  border-bottom: 1px groove #4a4a4a; }

.border-b-grey-dark-ridge {
  border-bottom: 1px ridge #4a4a4a; }

.border-b-grey-dark-inset {
  border-bottom: 1px inset #4a4a4a; }

.border-b-grey-dark-outset {
  border-bottom: 1px outset #4a4a4a; }

.border-b-grey-dark-none {
  border-bottom: 1px none #4a4a4a; }

.border-b-grey-dark-hidden {
  border-bottom: 1px hidden #4a4a4a; }

.border-l-grey-dark {
  border-left: 1px solid #4a4a4a; }

.border-l-grey-dark-dotted {
  border-left: 1px dotted #4a4a4a; }

.border-l-grey-dark-dashed {
  border-left: 1px dashed #4a4a4a; }

.border-l-grey-dark-solid {
  border-left: 1px solid #4a4a4a; }

.border-l-grey-dark-double {
  border-left: 1px double #4a4a4a; }

.border-l-grey-dark-groove {
  border-left: 1px groove #4a4a4a; }

.border-l-grey-dark-ridge {
  border-left: 1px ridge #4a4a4a; }

.border-l-grey-dark-inset {
  border-left: 1px inset #4a4a4a; }

.border-l-grey-dark-outset {
  border-left: 1px outset #4a4a4a; }

.border-l-grey-dark-none {
  border-left: 1px none #4a4a4a; }

.border-l-grey-dark-hidden {
  border-left: 1px hidden #4a4a4a; }

.has-text-grey {
  color: #7a7a7a; }

.has-background-grey {
  background-color: #7a7a7a; }

.border-grey {
  border: 1px solid #7a7a7a; }

.border-grey-dotted {
  border: 1px dotted #7a7a7a; }

.border-grey-dashed {
  border: 1px dashed #7a7a7a; }

.border-grey-solid {
  border: 1px solid #7a7a7a; }

.border-grey-double {
  border: 1px double #7a7a7a; }

.border-grey-groove {
  border: 1px groove #7a7a7a; }

.border-grey-ridge {
  border: 1px ridge #7a7a7a; }

.border-grey-inset {
  border: 1px inset #7a7a7a; }

.border-grey-outset {
  border: 1px outset #7a7a7a; }

.border-grey-none {
  border: 1px none #7a7a7a; }

.border-grey-hidden {
  border: 1px hidden #7a7a7a; }

.border-t-grey {
  border-top: 1px solid #7a7a7a; }

.border-t-grey-dotted {
  border-top: 1px dotted #7a7a7a; }

.border-t-grey-dashed {
  border-top: 1px dashed #7a7a7a; }

.border-t-grey-solid {
  border-top: 1px solid #7a7a7a; }

.border-t-grey-double {
  border-top: 1px double #7a7a7a; }

.border-t-grey-groove {
  border-top: 1px groove #7a7a7a; }

.border-t-grey-ridge {
  border-top: 1px ridge #7a7a7a; }

.border-t-grey-inset {
  border-top: 1px inset #7a7a7a; }

.border-t-grey-outset {
  border-top: 1px outset #7a7a7a; }

.border-t-grey-none {
  border-top: 1px none #7a7a7a; }

.border-t-grey-hidden {
  border-top: 1px hidden #7a7a7a; }

.border-r-grey {
  border-right: 1px solid #7a7a7a; }

.border-r-grey-dotted {
  border-right: 1px dotted #7a7a7a; }

.border-r-grey-dashed {
  border-right: 1px dashed #7a7a7a; }

.border-r-grey-solid {
  border-right: 1px solid #7a7a7a; }

.border-r-grey-double {
  border-right: 1px double #7a7a7a; }

.border-r-grey-groove {
  border-right: 1px groove #7a7a7a; }

.border-r-grey-ridge {
  border-right: 1px ridge #7a7a7a; }

.border-r-grey-inset {
  border-right: 1px inset #7a7a7a; }

.border-r-grey-outset {
  border-right: 1px outset #7a7a7a; }

.border-r-grey-none {
  border-right: 1px none #7a7a7a; }

.border-r-grey-hidden {
  border-right: 1px hidden #7a7a7a; }

.border-b-grey {
  border-bottom: 1px solid #7a7a7a; }

.border-b-grey-dotted {
  border-bottom: 1px dotted #7a7a7a; }

.border-b-grey-dashed {
  border-bottom: 1px dashed #7a7a7a; }

.border-b-grey-solid {
  border-bottom: 1px solid #7a7a7a; }

.border-b-grey-double {
  border-bottom: 1px double #7a7a7a; }

.border-b-grey-groove {
  border-bottom: 1px groove #7a7a7a; }

.border-b-grey-ridge {
  border-bottom: 1px ridge #7a7a7a; }

.border-b-grey-inset {
  border-bottom: 1px inset #7a7a7a; }

.border-b-grey-outset {
  border-bottom: 1px outset #7a7a7a; }

.border-b-grey-none {
  border-bottom: 1px none #7a7a7a; }

.border-b-grey-hidden {
  border-bottom: 1px hidden #7a7a7a; }

.border-l-grey {
  border-left: 1px solid #7a7a7a; }

.border-l-grey-dotted {
  border-left: 1px dotted #7a7a7a; }

.border-l-grey-dashed {
  border-left: 1px dashed #7a7a7a; }

.border-l-grey-solid {
  border-left: 1px solid #7a7a7a; }

.border-l-grey-double {
  border-left: 1px double #7a7a7a; }

.border-l-grey-groove {
  border-left: 1px groove #7a7a7a; }

.border-l-grey-ridge {
  border-left: 1px ridge #7a7a7a; }

.border-l-grey-inset {
  border-left: 1px inset #7a7a7a; }

.border-l-grey-outset {
  border-left: 1px outset #7a7a7a; }

.border-l-grey-none {
  border-left: 1px none #7a7a7a; }

.border-l-grey-hidden {
  border-left: 1px hidden #7a7a7a; }

.has-text-grey-light {
  color: #b5b5b5; }

.has-background-grey-light {
  background-color: #b5b5b5; }

.border-grey-light {
  border: 1px solid #b5b5b5; }

.border-grey-light-dotted {
  border: 1px dotted #b5b5b5; }

.border-grey-light-dashed {
  border: 1px dashed #b5b5b5; }

.border-grey-light-solid {
  border: 1px solid #b5b5b5; }

.border-grey-light-double {
  border: 1px double #b5b5b5; }

.border-grey-light-groove {
  border: 1px groove #b5b5b5; }

.border-grey-light-ridge {
  border: 1px ridge #b5b5b5; }

.border-grey-light-inset {
  border: 1px inset #b5b5b5; }

.border-grey-light-outset {
  border: 1px outset #b5b5b5; }

.border-grey-light-none {
  border: 1px none #b5b5b5; }

.border-grey-light-hidden {
  border: 1px hidden #b5b5b5; }

.border-t-grey-light {
  border-top: 1px solid #b5b5b5; }

.border-t-grey-light-dotted {
  border-top: 1px dotted #b5b5b5; }

.border-t-grey-light-dashed {
  border-top: 1px dashed #b5b5b5; }

.border-t-grey-light-solid {
  border-top: 1px solid #b5b5b5; }

.border-t-grey-light-double {
  border-top: 1px double #b5b5b5; }

.border-t-grey-light-groove {
  border-top: 1px groove #b5b5b5; }

.border-t-grey-light-ridge {
  border-top: 1px ridge #b5b5b5; }

.border-t-grey-light-inset {
  border-top: 1px inset #b5b5b5; }

.border-t-grey-light-outset {
  border-top: 1px outset #b5b5b5; }

.border-t-grey-light-none {
  border-top: 1px none #b5b5b5; }

.border-t-grey-light-hidden {
  border-top: 1px hidden #b5b5b5; }

.border-r-grey-light {
  border-right: 1px solid #b5b5b5; }

.border-r-grey-light-dotted {
  border-right: 1px dotted #b5b5b5; }

.border-r-grey-light-dashed {
  border-right: 1px dashed #b5b5b5; }

.border-r-grey-light-solid {
  border-right: 1px solid #b5b5b5; }

.border-r-grey-light-double {
  border-right: 1px double #b5b5b5; }

.border-r-grey-light-groove {
  border-right: 1px groove #b5b5b5; }

.border-r-grey-light-ridge {
  border-right: 1px ridge #b5b5b5; }

.border-r-grey-light-inset {
  border-right: 1px inset #b5b5b5; }

.border-r-grey-light-outset {
  border-right: 1px outset #b5b5b5; }

.border-r-grey-light-none {
  border-right: 1px none #b5b5b5; }

.border-r-grey-light-hidden {
  border-right: 1px hidden #b5b5b5; }

.border-b-grey-light {
  border-bottom: 1px solid #b5b5b5; }

.border-b-grey-light-dotted {
  border-bottom: 1px dotted #b5b5b5; }

.border-b-grey-light-dashed {
  border-bottom: 1px dashed #b5b5b5; }

.border-b-grey-light-solid {
  border-bottom: 1px solid #b5b5b5; }

.border-b-grey-light-double {
  border-bottom: 1px double #b5b5b5; }

.border-b-grey-light-groove {
  border-bottom: 1px groove #b5b5b5; }

.border-b-grey-light-ridge {
  border-bottom: 1px ridge #b5b5b5; }

.border-b-grey-light-inset {
  border-bottom: 1px inset #b5b5b5; }

.border-b-grey-light-outset {
  border-bottom: 1px outset #b5b5b5; }

.border-b-grey-light-none {
  border-bottom: 1px none #b5b5b5; }

.border-b-grey-light-hidden {
  border-bottom: 1px hidden #b5b5b5; }

.border-l-grey-light {
  border-left: 1px solid #b5b5b5; }

.border-l-grey-light-dotted {
  border-left: 1px dotted #b5b5b5; }

.border-l-grey-light-dashed {
  border-left: 1px dashed #b5b5b5; }

.border-l-grey-light-solid {
  border-left: 1px solid #b5b5b5; }

.border-l-grey-light-double {
  border-left: 1px double #b5b5b5; }

.border-l-grey-light-groove {
  border-left: 1px groove #b5b5b5; }

.border-l-grey-light-ridge {
  border-left: 1px ridge #b5b5b5; }

.border-l-grey-light-inset {
  border-left: 1px inset #b5b5b5; }

.border-l-grey-light-outset {
  border-left: 1px outset #b5b5b5; }

.border-l-grey-light-none {
  border-left: 1px none #b5b5b5; }

.border-l-grey-light-hidden {
  border-left: 1px hidden #b5b5b5; }

.has-text-grey-lighter {
  color: #e6e6e6; }

.has-background-grey-lighter {
  background-color: #e6e6e6; }

.border-grey-lighter {
  border: 1px solid #e6e6e6; }

.border-grey-lighter-dotted {
  border: 1px dotted #e6e6e6; }

.border-grey-lighter-dashed {
  border: 1px dashed #e6e6e6; }

.border-grey-lighter-solid {
  border: 1px solid #e6e6e6; }

.border-grey-lighter-double {
  border: 1px double #e6e6e6; }

.border-grey-lighter-groove {
  border: 1px groove #e6e6e6; }

.border-grey-lighter-ridge {
  border: 1px ridge #e6e6e6; }

.border-grey-lighter-inset {
  border: 1px inset #e6e6e6; }

.border-grey-lighter-outset {
  border: 1px outset #e6e6e6; }

.border-grey-lighter-none {
  border: 1px none #e6e6e6; }

.border-grey-lighter-hidden {
  border: 1px hidden #e6e6e6; }

.border-t-grey-lighter {
  border-top: 1px solid #e6e6e6; }

.border-t-grey-lighter-dotted {
  border-top: 1px dotted #e6e6e6; }

.border-t-grey-lighter-dashed {
  border-top: 1px dashed #e6e6e6; }

.border-t-grey-lighter-solid {
  border-top: 1px solid #e6e6e6; }

.border-t-grey-lighter-double {
  border-top: 1px double #e6e6e6; }

.border-t-grey-lighter-groove {
  border-top: 1px groove #e6e6e6; }

.border-t-grey-lighter-ridge {
  border-top: 1px ridge #e6e6e6; }

.border-t-grey-lighter-inset {
  border-top: 1px inset #e6e6e6; }

.border-t-grey-lighter-outset {
  border-top: 1px outset #e6e6e6; }

.border-t-grey-lighter-none {
  border-top: 1px none #e6e6e6; }

.border-t-grey-lighter-hidden {
  border-top: 1px hidden #e6e6e6; }

.border-r-grey-lighter {
  border-right: 1px solid #e6e6e6; }

.border-r-grey-lighter-dotted {
  border-right: 1px dotted #e6e6e6; }

.border-r-grey-lighter-dashed {
  border-right: 1px dashed #e6e6e6; }

.border-r-grey-lighter-solid {
  border-right: 1px solid #e6e6e6; }

.border-r-grey-lighter-double {
  border-right: 1px double #e6e6e6; }

.border-r-grey-lighter-groove {
  border-right: 1px groove #e6e6e6; }

.border-r-grey-lighter-ridge {
  border-right: 1px ridge #e6e6e6; }

.border-r-grey-lighter-inset {
  border-right: 1px inset #e6e6e6; }

.border-r-grey-lighter-outset {
  border-right: 1px outset #e6e6e6; }

.border-r-grey-lighter-none {
  border-right: 1px none #e6e6e6; }

.border-r-grey-lighter-hidden {
  border-right: 1px hidden #e6e6e6; }

.border-b-grey-lighter {
  border-bottom: 1px solid #e6e6e6; }

.border-b-grey-lighter-dotted {
  border-bottom: 1px dotted #e6e6e6; }

.border-b-grey-lighter-dashed {
  border-bottom: 1px dashed #e6e6e6; }

.border-b-grey-lighter-solid {
  border-bottom: 1px solid #e6e6e6; }

.border-b-grey-lighter-double {
  border-bottom: 1px double #e6e6e6; }

.border-b-grey-lighter-groove {
  border-bottom: 1px groove #e6e6e6; }

.border-b-grey-lighter-ridge {
  border-bottom: 1px ridge #e6e6e6; }

.border-b-grey-lighter-inset {
  border-bottom: 1px inset #e6e6e6; }

.border-b-grey-lighter-outset {
  border-bottom: 1px outset #e6e6e6; }

.border-b-grey-lighter-none {
  border-bottom: 1px none #e6e6e6; }

.border-b-grey-lighter-hidden {
  border-bottom: 1px hidden #e6e6e6; }

.border-l-grey-lighter {
  border-left: 1px solid #e6e6e6; }

.border-l-grey-lighter-dotted {
  border-left: 1px dotted #e6e6e6; }

.border-l-grey-lighter-dashed {
  border-left: 1px dashed #e6e6e6; }

.border-l-grey-lighter-solid {
  border-left: 1px solid #e6e6e6; }

.border-l-grey-lighter-double {
  border-left: 1px double #e6e6e6; }

.border-l-grey-lighter-groove {
  border-left: 1px groove #e6e6e6; }

.border-l-grey-lighter-ridge {
  border-left: 1px ridge #e6e6e6; }

.border-l-grey-lighter-inset {
  border-left: 1px inset #e6e6e6; }

.border-l-grey-lighter-outset {
  border-left: 1px outset #e6e6e6; }

.border-l-grey-lighter-none {
  border-left: 1px none #e6e6e6; }

.border-l-grey-lighter-hidden {
  border-left: 1px hidden #e6e6e6; }

.has-text-white-ter {
  color: whitesmoke; }

.has-background-white-ter {
  background-color: whitesmoke; }

.border-white-ter {
  border: 1px solid whitesmoke; }

.border-white-ter-dotted {
  border: 1px dotted whitesmoke; }

.border-white-ter-dashed {
  border: 1px dashed whitesmoke; }

.border-white-ter-solid {
  border: 1px solid whitesmoke; }

.border-white-ter-double {
  border: 1px double whitesmoke; }

.border-white-ter-groove {
  border: 1px groove whitesmoke; }

.border-white-ter-ridge {
  border: 1px ridge whitesmoke; }

.border-white-ter-inset {
  border: 1px inset whitesmoke; }

.border-white-ter-outset {
  border: 1px outset whitesmoke; }

.border-white-ter-none {
  border: 1px none whitesmoke; }

.border-white-ter-hidden {
  border: 1px hidden whitesmoke; }

.border-t-white-ter {
  border-top: 1px solid whitesmoke; }

.border-t-white-ter-dotted {
  border-top: 1px dotted whitesmoke; }

.border-t-white-ter-dashed {
  border-top: 1px dashed whitesmoke; }

.border-t-white-ter-solid {
  border-top: 1px solid whitesmoke; }

.border-t-white-ter-double {
  border-top: 1px double whitesmoke; }

.border-t-white-ter-groove {
  border-top: 1px groove whitesmoke; }

.border-t-white-ter-ridge {
  border-top: 1px ridge whitesmoke; }

.border-t-white-ter-inset {
  border-top: 1px inset whitesmoke; }

.border-t-white-ter-outset {
  border-top: 1px outset whitesmoke; }

.border-t-white-ter-none {
  border-top: 1px none whitesmoke; }

.border-t-white-ter-hidden {
  border-top: 1px hidden whitesmoke; }

.border-r-white-ter {
  border-right: 1px solid whitesmoke; }

.border-r-white-ter-dotted {
  border-right: 1px dotted whitesmoke; }

.border-r-white-ter-dashed {
  border-right: 1px dashed whitesmoke; }

.border-r-white-ter-solid {
  border-right: 1px solid whitesmoke; }

.border-r-white-ter-double {
  border-right: 1px double whitesmoke; }

.border-r-white-ter-groove {
  border-right: 1px groove whitesmoke; }

.border-r-white-ter-ridge {
  border-right: 1px ridge whitesmoke; }

.border-r-white-ter-inset {
  border-right: 1px inset whitesmoke; }

.border-r-white-ter-outset {
  border-right: 1px outset whitesmoke; }

.border-r-white-ter-none {
  border-right: 1px none whitesmoke; }

.border-r-white-ter-hidden {
  border-right: 1px hidden whitesmoke; }

.border-b-white-ter {
  border-bottom: 1px solid whitesmoke; }

.border-b-white-ter-dotted {
  border-bottom: 1px dotted whitesmoke; }

.border-b-white-ter-dashed {
  border-bottom: 1px dashed whitesmoke; }

.border-b-white-ter-solid {
  border-bottom: 1px solid whitesmoke; }

.border-b-white-ter-double {
  border-bottom: 1px double whitesmoke; }

.border-b-white-ter-groove {
  border-bottom: 1px groove whitesmoke; }

.border-b-white-ter-ridge {
  border-bottom: 1px ridge whitesmoke; }

.border-b-white-ter-inset {
  border-bottom: 1px inset whitesmoke; }

.border-b-white-ter-outset {
  border-bottom: 1px outset whitesmoke; }

.border-b-white-ter-none {
  border-bottom: 1px none whitesmoke; }

.border-b-white-ter-hidden {
  border-bottom: 1px hidden whitesmoke; }

.border-l-white-ter {
  border-left: 1px solid whitesmoke; }

.border-l-white-ter-dotted {
  border-left: 1px dotted whitesmoke; }

.border-l-white-ter-dashed {
  border-left: 1px dashed whitesmoke; }

.border-l-white-ter-solid {
  border-left: 1px solid whitesmoke; }

.border-l-white-ter-double {
  border-left: 1px double whitesmoke; }

.border-l-white-ter-groove {
  border-left: 1px groove whitesmoke; }

.border-l-white-ter-ridge {
  border-left: 1px ridge whitesmoke; }

.border-l-white-ter-inset {
  border-left: 1px inset whitesmoke; }

.border-l-white-ter-outset {
  border-left: 1px outset whitesmoke; }

.border-l-white-ter-none {
  border-left: 1px none whitesmoke; }

.border-l-white-ter-hidden {
  border-left: 1px hidden whitesmoke; }

.has-text-white-bis {
  color: #fafafa; }

.has-background-white-bis {
  background-color: #fafafa; }

.border-white-bis {
  border: 1px solid #fafafa; }

.border-white-bis-dotted {
  border: 1px dotted #fafafa; }

.border-white-bis-dashed {
  border: 1px dashed #fafafa; }

.border-white-bis-solid {
  border: 1px solid #fafafa; }

.border-white-bis-double {
  border: 1px double #fafafa; }

.border-white-bis-groove {
  border: 1px groove #fafafa; }

.border-white-bis-ridge {
  border: 1px ridge #fafafa; }

.border-white-bis-inset {
  border: 1px inset #fafafa; }

.border-white-bis-outset {
  border: 1px outset #fafafa; }

.border-white-bis-none {
  border: 1px none #fafafa; }

.border-white-bis-hidden {
  border: 1px hidden #fafafa; }

.border-t-white-bis {
  border-top: 1px solid #fafafa; }

.border-t-white-bis-dotted {
  border-top: 1px dotted #fafafa; }

.border-t-white-bis-dashed {
  border-top: 1px dashed #fafafa; }

.border-t-white-bis-solid {
  border-top: 1px solid #fafafa; }

.border-t-white-bis-double {
  border-top: 1px double #fafafa; }

.border-t-white-bis-groove {
  border-top: 1px groove #fafafa; }

.border-t-white-bis-ridge {
  border-top: 1px ridge #fafafa; }

.border-t-white-bis-inset {
  border-top: 1px inset #fafafa; }

.border-t-white-bis-outset {
  border-top: 1px outset #fafafa; }

.border-t-white-bis-none {
  border-top: 1px none #fafafa; }

.border-t-white-bis-hidden {
  border-top: 1px hidden #fafafa; }

.border-r-white-bis {
  border-right: 1px solid #fafafa; }

.border-r-white-bis-dotted {
  border-right: 1px dotted #fafafa; }

.border-r-white-bis-dashed {
  border-right: 1px dashed #fafafa; }

.border-r-white-bis-solid {
  border-right: 1px solid #fafafa; }

.border-r-white-bis-double {
  border-right: 1px double #fafafa; }

.border-r-white-bis-groove {
  border-right: 1px groove #fafafa; }

.border-r-white-bis-ridge {
  border-right: 1px ridge #fafafa; }

.border-r-white-bis-inset {
  border-right: 1px inset #fafafa; }

.border-r-white-bis-outset {
  border-right: 1px outset #fafafa; }

.border-r-white-bis-none {
  border-right: 1px none #fafafa; }

.border-r-white-bis-hidden {
  border-right: 1px hidden #fafafa; }

.border-b-white-bis {
  border-bottom: 1px solid #fafafa; }

.border-b-white-bis-dotted {
  border-bottom: 1px dotted #fafafa; }

.border-b-white-bis-dashed {
  border-bottom: 1px dashed #fafafa; }

.border-b-white-bis-solid {
  border-bottom: 1px solid #fafafa; }

.border-b-white-bis-double {
  border-bottom: 1px double #fafafa; }

.border-b-white-bis-groove {
  border-bottom: 1px groove #fafafa; }

.border-b-white-bis-ridge {
  border-bottom: 1px ridge #fafafa; }

.border-b-white-bis-inset {
  border-bottom: 1px inset #fafafa; }

.border-b-white-bis-outset {
  border-bottom: 1px outset #fafafa; }

.border-b-white-bis-none {
  border-bottom: 1px none #fafafa; }

.border-b-white-bis-hidden {
  border-bottom: 1px hidden #fafafa; }

.border-l-white-bis {
  border-left: 1px solid #fafafa; }

.border-l-white-bis-dotted {
  border-left: 1px dotted #fafafa; }

.border-l-white-bis-dashed {
  border-left: 1px dashed #fafafa; }

.border-l-white-bis-solid {
  border-left: 1px solid #fafafa; }

.border-l-white-bis-double {
  border-left: 1px double #fafafa; }

.border-l-white-bis-groove {
  border-left: 1px groove #fafafa; }

.border-l-white-bis-ridge {
  border-left: 1px ridge #fafafa; }

.border-l-white-bis-inset {
  border-left: 1px inset #fafafa; }

.border-l-white-bis-outset {
  border-left: 1px outset #fafafa; }

.border-l-white-bis-none {
  border-left: 1px none #fafafa; }

.border-l-white-bis-hidden {
  border-left: 1px hidden #fafafa; }

.has-text-white {
  color: white; }

.has-background-white {
  background-color: white; }

.border-white {
  border: 1px solid white; }

.border-white-dotted {
  border: 1px dotted white; }

.border-white-dashed {
  border: 1px dashed white; }

.border-white-solid {
  border: 1px solid white; }

.border-white-double {
  border: 1px double white; }

.border-white-groove {
  border: 1px groove white; }

.border-white-ridge {
  border: 1px ridge white; }

.border-white-inset {
  border: 1px inset white; }

.border-white-outset {
  border: 1px outset white; }

.border-white-none {
  border: 1px none white; }

.border-white-hidden {
  border: 1px hidden white; }

.border-t-white {
  border-top: 1px solid white; }

.border-t-white-dotted {
  border-top: 1px dotted white; }

.border-t-white-dashed {
  border-top: 1px dashed white; }

.border-t-white-solid {
  border-top: 1px solid white; }

.border-t-white-double {
  border-top: 1px double white; }

.border-t-white-groove {
  border-top: 1px groove white; }

.border-t-white-ridge {
  border-top: 1px ridge white; }

.border-t-white-inset {
  border-top: 1px inset white; }

.border-t-white-outset {
  border-top: 1px outset white; }

.border-t-white-none {
  border-top: 1px none white; }

.border-t-white-hidden {
  border-top: 1px hidden white; }

.border-r-white {
  border-right: 1px solid white; }

.border-r-white-dotted {
  border-right: 1px dotted white; }

.border-r-white-dashed {
  border-right: 1px dashed white; }

.border-r-white-solid {
  border-right: 1px solid white; }

.border-r-white-double {
  border-right: 1px double white; }

.border-r-white-groove {
  border-right: 1px groove white; }

.border-r-white-ridge {
  border-right: 1px ridge white; }

.border-r-white-inset {
  border-right: 1px inset white; }

.border-r-white-outset {
  border-right: 1px outset white; }

.border-r-white-none {
  border-right: 1px none white; }

.border-r-white-hidden {
  border-right: 1px hidden white; }

.border-b-white {
  border-bottom: 1px solid white; }

.border-b-white-dotted {
  border-bottom: 1px dotted white; }

.border-b-white-dashed {
  border-bottom: 1px dashed white; }

.border-b-white-solid {
  border-bottom: 1px solid white; }

.border-b-white-double {
  border-bottom: 1px double white; }

.border-b-white-groove {
  border-bottom: 1px groove white; }

.border-b-white-ridge {
  border-bottom: 1px ridge white; }

.border-b-white-inset {
  border-bottom: 1px inset white; }

.border-b-white-outset {
  border-bottom: 1px outset white; }

.border-b-white-none {
  border-bottom: 1px none white; }

.border-b-white-hidden {
  border-bottom: 1px hidden white; }

.border-l-white {
  border-left: 1px solid white; }

.border-l-white-dotted {
  border-left: 1px dotted white; }

.border-l-white-dashed {
  border-left: 1px dashed white; }

.border-l-white-solid {
  border-left: 1px solid white; }

.border-l-white-double {
  border-left: 1px double white; }

.border-l-white-groove {
  border-left: 1px groove white; }

.border-l-white-ridge {
  border-left: 1px ridge white; }

.border-l-white-inset {
  border-left: 1px inset white; }

.border-l-white-outset {
  border-left: 1px outset white; }

.border-l-white-none {
  border-left: 1px none white; }

.border-l-white-hidden {
  border-left: 1px hidden white; }

.webchat_button {
  display: flex;
  padding: 7px;
  text-align: center;
  white-space: normal;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block;
  display: inline-block; }
  .webchat_button.quickreply-primary {
    min-width: 50px;
    border: 1px solid #2d2f7b;
    color: #2d2f7b; }
  .webchat_button.is-primary {
    color: #2d2f7b; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #2a2b72; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #2d2f7b; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #272868;
    border-color: transparent;
    color: #2d2f7b; }
  .webchat_button[disabled] {
    background-color: #2d2f7b;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-primary-bis {
    min-width: 50px;
    border: 1px solid #4d4db3;
    color: #4d4db3; }
  .webchat_button.is-primary-bis {
    color: #4d4db3; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #4949aa; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #4d4db3; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #4545a1;
    border-color: transparent;
    color: #4d4db3; }
  .webchat_button[disabled] {
    background-color: #4d4db3;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-primary-ter {
    min-width: 50px;
    border: 1px solid #7a7ab8;
    color: #7a7ab8; }
  .webchat_button.is-primary-ter {
    color: #7a7ab8; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #7272b3; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #7a7ab8; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #6a6aaf;
    border-color: transparent;
    color: #7a7ab8; }
  .webchat_button[disabled] {
    background-color: #7a7ab8;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-primary-qua {
    min-width: 50px;
    border: 1px solid #b9b9e9;
    color: #b9b9e9; }
  .webchat_button.is-primary-qua {
    color: #b9b9e9; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #b0b0e6; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #b9b9e9; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #a6a6e3;
    border-color: transparent;
    color: #b9b9e9; }
  .webchat_button[disabled] {
    background-color: #b9b9e9;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-primary-qui {
    min-width: 50px;
    border: 1px solid #b8b8d6;
    color: #b8b8d6; }
  .webchat_button.is-primary-qui {
    color: #b8b8d6; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #b0b0d1; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #b8b8d6; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #a8a8cd;
    border-color: transparent;
    color: #b8b8d6; }
  .webchat_button[disabled] {
    background-color: #b8b8d6;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-primary-light {
    min-width: 50px;
    border: 1px solid #dbdbf0;
    color: #dbdbf0; }
  .webchat_button.is-primary-light {
    color: #dbdbf0; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #d2d2ec; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #dbdbf0; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #c9c9e8;
    border-color: transparent;
    color: #dbdbf0; }
  .webchat_button[disabled] {
    background-color: #dbdbf0;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-primary-lighter {
    min-width: 50px;
    border: 1px solid #ededf7;
    color: #ededf7; }
  .webchat_button.is-primary-lighter {
    color: #ededf7; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #e4e4f4; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #ededf7; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #dbdbf0;
    border-color: transparent;
    color: #ededf7; }
  .webchat_button[disabled] {
    background-color: #ededf7;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-primary-lighbis {
    min-width: 50px;
    border: 1px solid #f4f4fa;
    color: #f4f4fa; }
  .webchat_button.is-primary-lighbis {
    color: #f4f4fa; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #ebebf7; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #f4f4fa; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #e2e2f3;
    border-color: transparent;
    color: #f4f4fa; }
  .webchat_button[disabled] {
    background-color: #f4f4fa;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-secondary {
    min-width: 50px;
    border: 1px solid #eb6666;
    color: #eb6666; }
  .webchat_button.is-secondary {
    color: #eb6666; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #ea5a5a; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #eb6666; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #e84f4f;
    border-color: transparent;
    color: #eb6666; }
  .webchat_button[disabled] {
    background-color: #eb6666;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-danger {
    min-width: 50px;
    border: 1px solid #cc3333;
    color: #cc3333; }
  .webchat_button.is-danger {
    color: #cc3333; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #c23030; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #cc3333; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #b82e2e;
    border-color: transparent;
    color: #cc3333; }
  .webchat_button[disabled] {
    background-color: #cc3333;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-danger-light {
    min-width: 50px;
    border: 1px solid #f6dada;
    color: #f6dada; }
  .webchat_button.is-danger-light {
    color: #f6dada; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #f3d0d0; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #f6dada; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #f1c6c6;
    border-color: transparent;
    color: #f6dada; }
  .webchat_button[disabled] {
    background-color: #f6dada;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-warning {
    min-width: 50px;
    border: 1px solid #ffd500;
    color: #ffd500; }
  .webchat_button.is-warning {
    color: #ffd500; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #f2ca00; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #ffd500; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #e6bf00;
    border-color: transparent;
    color: #ffd500; }
  .webchat_button[disabled] {
    background-color: #ffd500;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-success {
    min-width: 50px;
    border: 1px solid #66cc33;
    color: #66cc33; }
  .webchat_button.is-success {
    color: #66cc33; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #61c230; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #66cc33; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #5cb82e;
    border-color: transparent;
    color: #66cc33; }
  .webchat_button[disabled] {
    background-color: #66cc33;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-success-light {
    min-width: 50px;
    border: 1px solid #dbffe0;
    color: #dbffe0; }
  .webchat_button.is-success-light {
    color: #dbffe0; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #cfffd5; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #dbffe0; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #c2ffca;
    border-color: transparent;
    color: #dbffe0; }
  .webchat_button[disabled] {
    background-color: #dbffe0;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-info {
    min-width: 50px;
    border: 1px solid #209fee;
    color: #209fee; }
  .webchat_button.is-info {
    color: #209fee; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #149aed; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #209fee; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #1193e4;
    border-color: transparent;
    color: #209fee; }
  .webchat_button[disabled] {
    background-color: #209fee;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-black {
    min-width: 50px;
    border: 1px solid #0a0a0a;
    color: #0a0a0a; }
  .webchat_button.is-black {
    color: #0a0a0a; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #040404; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #0a0a0a; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: black;
    border-color: transparent;
    color: #0a0a0a; }
  .webchat_button[disabled] {
    background-color: #0a0a0a;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-black-bis {
    min-width: 50px;
    border: 1px solid #121212;
    color: #121212; }
  .webchat_button.is-black-bis {
    color: #121212; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #0b0b0b; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #121212; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #050505;
    border-color: transparent;
    color: #121212; }
  .webchat_button[disabled] {
    background-color: #121212;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-black-ter {
    min-width: 50px;
    border: 1px solid #242424;
    color: #242424; }
  .webchat_button.is-black-ter {
    color: #242424; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #1d1d1d; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #242424; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #171717;
    border-color: transparent;
    color: #242424; }
  .webchat_button[disabled] {
    background-color: #242424;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-grey-darker {
    min-width: 50px;
    border: 1px solid #363636;
    color: #363636; }
  .webchat_button.is-grey-darker {
    color: #363636; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #2f2f2f; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #363636; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #292929;
    border-color: transparent;
    color: #363636; }
  .webchat_button[disabled] {
    background-color: #363636;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-grey-dark {
    min-width: 50px;
    border: 1px solid #4a4a4a;
    color: #4a4a4a; }
  .webchat_button.is-grey-dark {
    color: #4a4a4a; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #444444; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #4a4a4a; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #3d3d3d;
    border-color: transparent;
    color: #4a4a4a; }
  .webchat_button[disabled] {
    background-color: #4a4a4a;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-grey {
    min-width: 50px;
    border: 1px solid #7a7a7a;
    color: #7a7a7a; }
  .webchat_button.is-grey {
    color: #7a7a7a; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #747474; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #7a7a7a; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #6e6e6e;
    border-color: transparent;
    color: #7a7a7a; }
  .webchat_button[disabled] {
    background-color: #7a7a7a;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-grey-light {
    min-width: 50px;
    border: 1px solid #b5b5b5;
    color: #b5b5b5; }
  .webchat_button.is-grey-light {
    color: #b5b5b5; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #afafaf; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #b5b5b5; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #a8a8a8;
    border-color: transparent;
    color: #b5b5b5; }
  .webchat_button[disabled] {
    background-color: #b5b5b5;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-grey-lighter {
    min-width: 50px;
    border: 1px solid #e6e6e6;
    color: #e6e6e6; }
  .webchat_button.is-grey-lighter {
    color: #e6e6e6; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #dfdfdf; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #e6e6e6; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #d9d9d9;
    border-color: transparent;
    color: #e6e6e6; }
  .webchat_button[disabled] {
    background-color: #e6e6e6;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-white-ter {
    min-width: 50px;
    border: 1px solid whitesmoke;
    color: whitesmoke; }
  .webchat_button.is-white-ter {
    color: whitesmoke; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #eeeeee; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: whitesmoke; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #e8e8e8;
    border-color: transparent;
    color: whitesmoke; }
  .webchat_button[disabled] {
    background-color: whitesmoke;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-white-bis {
    min-width: 50px;
    border: 1px solid #fafafa;
    color: #fafafa; }
  .webchat_button.is-white-bis {
    color: #fafafa; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #f4f4f4; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: #fafafa; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #ededed;
    border-color: transparent;
    color: #fafafa; }
  .webchat_button[disabled] {
    background-color: #fafafa;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.quickreply-white {
    min-width: 50px;
    border: 1px solid white;
    color: white; }
  .webchat_button.is-white {
    color: white; }
  .webchat_button:hover:not(.no-hover), .webchat_button.is-hovered {
    background-color: #f9f9f9; }
  .webchat_button:focus, .webchat_button.is-focused {
    border-color: transparent;
    background-color: white; }
  .webchat_button:active, .webchat_button.is-active {
    background-color: #f2f2f2;
    border-color: transparent;
    color: white; }
  .webchat_button[disabled] {
    background-color: white;
    border-color: transparent;
    box-shadow: none; }
  .webchat_button.is-loading {
    color: transparent;
    pointer-events: none; }
    .webchat_button.is-loading:after {
      color: "#eee";
      border-color: "#eee"; }

.webchat_icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem; }
  .webchat_icon.is-small {
    height: 1rem;
    width: 1rem; }
  .webchat_icon.is-medium {
    height: 2rem;
    width: 2rem; }
  .webchat_icon.is-large {
    height: 3rem;
    width: 3rem; }

.m-none {
  margin: 0; }

.p-none {
  padding: 0; }

.m-t-none {
  margin-top: 0; }

.p-t-none {
  padding-top: 0; }

.m-r-none {
  margin-right: 0; }

.p-r-none {
  padding-right: 0; }

.m-b-none {
  margin-bottom: 0; }

.p-b-none {
  padding-bottom: 0; }

.m-l-none {
  margin-left: 0; }

.p-l-none {
  padding-left: 0; }

.m-xxs {
  margin: 0.125rem; }

.p-xxs {
  padding: 0.125rem; }

.m-t-xxs {
  margin-top: 0.125rem; }

.p-t-xxs {
  padding-top: 0.125rem; }

.m-r-xxs {
  margin-right: 0.125rem; }

.p-r-xxs {
  padding-right: 0.125rem; }

.m-b-xxs {
  margin-bottom: 0.125rem; }

.p-b-xxs {
  padding-bottom: 0.125rem; }

.m-l-xxs {
  margin-left: 0.125rem; }

.p-l-xxs {
  padding-left: 0.125rem; }

.m-xs {
  margin: 0.25rem; }

.p-xs {
  padding: 0.25rem; }

.m-t-xs {
  margin-top: 0.25rem; }

.p-t-xs {
  padding-top: 0.25rem; }

.m-r-xs {
  margin-right: 0.25rem; }

.p-r-xs {
  padding-right: 0.25rem; }

.m-b-xs {
  margin-bottom: 0.25rem; }

.p-b-xs {
  padding-bottom: 0.25rem; }

.m-l-xs {
  margin-left: 0.25rem; }

.p-l-xs {
  padding-left: 0.25rem; }

.m-sm {
  margin: 0.6rem; }

.p-sm {
  padding: 0.6rem; }

.m-t-sm {
  margin-top: 0.6rem; }

.p-t-sm {
  padding-top: 0.6rem; }

.m-r-sm {
  margin-right: 0.6rem; }

.p-r-sm {
  padding-right: 0.6rem; }

.m-b-sm {
  margin-bottom: 0.6rem; }

.p-b-sm {
  padding-bottom: 0.6rem; }

.m-l-sm {
  margin-left: 0.6rem; }

.p-l-sm {
  padding-left: 0.6rem; }

.m-md {
  margin: 1rem; }

.p-md {
  padding: 1rem; }

.m-t-md {
  margin-top: 1rem; }

.p-t-md {
  padding-top: 1rem; }

.m-r-md {
  margin-right: 1rem; }

.p-r-md {
  padding-right: 1rem; }

.m-b-md {
  margin-bottom: 1rem; }

.p-b-md {
  padding-bottom: 1rem; }

.m-l-md {
  margin-left: 1rem; }

.p-l-md {
  padding-left: 1rem; }

.m-lg {
  margin: 2rem; }

.p-lg {
  padding: 2rem; }

.m-t-lg {
  margin-top: 2rem; }

.p-t-lg {
  padding-top: 2rem; }

.m-r-lg {
  margin-right: 2rem; }

.p-r-lg {
  padding-right: 2rem; }

.m-b-lg {
  margin-bottom: 2rem; }

.p-b-lg {
  padding-bottom: 2rem; }

.m-l-lg {
  margin-left: 2rem; }

.p-l-lg {
  padding-left: 2rem; }

.m-xl {
  margin: 4rem; }

.p-xl {
  padding: 4rem; }

.m-t-xl {
  margin-top: 4rem; }

.p-t-xl {
  padding-top: 4rem; }

.m-r-xl {
  margin-right: 4rem; }

.p-r-xl {
  padding-right: 4rem; }

.m-b-xl {
  margin-bottom: 4rem; }

.p-b-xl {
  padding-bottom: 4rem; }

.m-l-xl {
  margin-left: 4rem; }

.p-l-xl {
  padding-left: 4rem; }

.m-xxl {
  margin: 8rem; }

.p-xxl {
  padding: 8rem; }

.m-t-xxl {
  margin-top: 8rem; }

.p-t-xxl {
  padding-top: 8rem; }

.m-r-xxl {
  margin-right: 8rem; }

.p-r-xxl {
  padding-right: 8rem; }

.m-b-xxl {
  margin-bottom: 8rem; }

.p-b-xxl {
  padding-bottom: 8rem; }

.m-l-xxl {
  margin-left: 8rem; }

.p-l-xxl {
  padding-left: 8rem; }

.has-text-primary {
  color: #2d2f7b; }

.has-background-primary {
  background-color: #2d2f7b; }

.border-primary {
  border: 1px solid #2d2f7b; }

.border-m-primary:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #2d2f7b;
  transform: translateY(-50%); }

.border-primary-dotted {
  border: 1px dotted #2d2f7b; }

.border-primary-dashed {
  border: 1px dashed #2d2f7b; }

.border-primary-solid {
  border: 1px solid #2d2f7b; }

.border-primary-double {
  border: 1px double #2d2f7b; }

.border-primary-groove {
  border: 1px groove #2d2f7b; }

.border-primary-ridge {
  border: 1px ridge #2d2f7b; }

.border-primary-inset {
  border: 1px inset #2d2f7b; }

.border-primary-outset {
  border: 1px outset #2d2f7b; }

.border-primary-none {
  border: 1px none #2d2f7b; }

.border-primary-hidden {
  border: 1px hidden #2d2f7b; }

.border-t-primary {
  border-top: 1px solid #2d2f7b; }

.border-t-primary-dotted {
  border-top: 1px dotted #2d2f7b; }

.border-t-primary-dashed {
  border-top: 1px dashed #2d2f7b; }

.border-t-primary-solid {
  border-top: 1px solid #2d2f7b; }

.border-t-primary-double {
  border-top: 1px double #2d2f7b; }

.border-t-primary-groove {
  border-top: 1px groove #2d2f7b; }

.border-t-primary-ridge {
  border-top: 1px ridge #2d2f7b; }

.border-t-primary-inset {
  border-top: 1px inset #2d2f7b; }

.border-t-primary-outset {
  border-top: 1px outset #2d2f7b; }

.border-t-primary-none {
  border-top: 1px none #2d2f7b; }

.border-t-primary-hidden {
  border-top: 1px hidden #2d2f7b; }

.border-r-primary {
  border-right: 1px solid #2d2f7b; }

.border-r-primary-dotted {
  border-right: 1px dotted #2d2f7b; }

.border-r-primary-dashed {
  border-right: 1px dashed #2d2f7b; }

.border-r-primary-solid {
  border-right: 1px solid #2d2f7b; }

.border-r-primary-double {
  border-right: 1px double #2d2f7b; }

.border-r-primary-groove {
  border-right: 1px groove #2d2f7b; }

.border-r-primary-ridge {
  border-right: 1px ridge #2d2f7b; }

.border-r-primary-inset {
  border-right: 1px inset #2d2f7b; }

.border-r-primary-outset {
  border-right: 1px outset #2d2f7b; }

.border-r-primary-none {
  border-right: 1px none #2d2f7b; }

.border-r-primary-hidden {
  border-right: 1px hidden #2d2f7b; }

.border-b-primary {
  border-bottom: 1px solid #2d2f7b; }

.border-b-primary-dotted {
  border-bottom: 1px dotted #2d2f7b; }

.border-b-primary-dashed {
  border-bottom: 1px dashed #2d2f7b; }

.border-b-primary-solid {
  border-bottom: 1px solid #2d2f7b; }

.border-b-primary-double {
  border-bottom: 1px double #2d2f7b; }

.border-b-primary-groove {
  border-bottom: 1px groove #2d2f7b; }

.border-b-primary-ridge {
  border-bottom: 1px ridge #2d2f7b; }

.border-b-primary-inset {
  border-bottom: 1px inset #2d2f7b; }

.border-b-primary-outset {
  border-bottom: 1px outset #2d2f7b; }

.border-b-primary-none {
  border-bottom: 1px none #2d2f7b; }

.border-b-primary-hidden {
  border-bottom: 1px hidden #2d2f7b; }

.border-l-primary {
  border-left: 1px solid #2d2f7b; }

.border-l-primary-dotted {
  border-left: 1px dotted #2d2f7b; }

.border-l-primary-dashed {
  border-left: 1px dashed #2d2f7b; }

.border-l-primary-solid {
  border-left: 1px solid #2d2f7b; }

.border-l-primary-double {
  border-left: 1px double #2d2f7b; }

.border-l-primary-groove {
  border-left: 1px groove #2d2f7b; }

.border-l-primary-ridge {
  border-left: 1px ridge #2d2f7b; }

.border-l-primary-inset {
  border-left: 1px inset #2d2f7b; }

.border-l-primary-outset {
  border-left: 1px outset #2d2f7b; }

.border-l-primary-none {
  border-left: 1px none #2d2f7b; }

.border-l-primary-hidden {
  border-left: 1px hidden #2d2f7b; }

.has-text-primary-bis {
  color: #4d4db3; }

.has-background-primary-bis {
  background-color: #4d4db3; }

.border-primary-bis {
  border: 1px solid #4d4db3; }

.border-m-primary-bis:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #4d4db3;
  transform: translateY(-50%); }

.border-primary-bis-dotted {
  border: 1px dotted #4d4db3; }

.border-primary-bis-dashed {
  border: 1px dashed #4d4db3; }

.border-primary-bis-solid {
  border: 1px solid #4d4db3; }

.border-primary-bis-double {
  border: 1px double #4d4db3; }

.border-primary-bis-groove {
  border: 1px groove #4d4db3; }

.border-primary-bis-ridge {
  border: 1px ridge #4d4db3; }

.border-primary-bis-inset {
  border: 1px inset #4d4db3; }

.border-primary-bis-outset {
  border: 1px outset #4d4db3; }

.border-primary-bis-none {
  border: 1px none #4d4db3; }

.border-primary-bis-hidden {
  border: 1px hidden #4d4db3; }

.border-t-primary-bis {
  border-top: 1px solid #4d4db3; }

.border-t-primary-bis-dotted {
  border-top: 1px dotted #4d4db3; }

.border-t-primary-bis-dashed {
  border-top: 1px dashed #4d4db3; }

.border-t-primary-bis-solid {
  border-top: 1px solid #4d4db3; }

.border-t-primary-bis-double {
  border-top: 1px double #4d4db3; }

.border-t-primary-bis-groove {
  border-top: 1px groove #4d4db3; }

.border-t-primary-bis-ridge {
  border-top: 1px ridge #4d4db3; }

.border-t-primary-bis-inset {
  border-top: 1px inset #4d4db3; }

.border-t-primary-bis-outset {
  border-top: 1px outset #4d4db3; }

.border-t-primary-bis-none {
  border-top: 1px none #4d4db3; }

.border-t-primary-bis-hidden {
  border-top: 1px hidden #4d4db3; }

.border-r-primary-bis {
  border-right: 1px solid #4d4db3; }

.border-r-primary-bis-dotted {
  border-right: 1px dotted #4d4db3; }

.border-r-primary-bis-dashed {
  border-right: 1px dashed #4d4db3; }

.border-r-primary-bis-solid {
  border-right: 1px solid #4d4db3; }

.border-r-primary-bis-double {
  border-right: 1px double #4d4db3; }

.border-r-primary-bis-groove {
  border-right: 1px groove #4d4db3; }

.border-r-primary-bis-ridge {
  border-right: 1px ridge #4d4db3; }

.border-r-primary-bis-inset {
  border-right: 1px inset #4d4db3; }

.border-r-primary-bis-outset {
  border-right: 1px outset #4d4db3; }

.border-r-primary-bis-none {
  border-right: 1px none #4d4db3; }

.border-r-primary-bis-hidden {
  border-right: 1px hidden #4d4db3; }

.border-b-primary-bis {
  border-bottom: 1px solid #4d4db3; }

.border-b-primary-bis-dotted {
  border-bottom: 1px dotted #4d4db3; }

.border-b-primary-bis-dashed {
  border-bottom: 1px dashed #4d4db3; }

.border-b-primary-bis-solid {
  border-bottom: 1px solid #4d4db3; }

.border-b-primary-bis-double {
  border-bottom: 1px double #4d4db3; }

.border-b-primary-bis-groove {
  border-bottom: 1px groove #4d4db3; }

.border-b-primary-bis-ridge {
  border-bottom: 1px ridge #4d4db3; }

.border-b-primary-bis-inset {
  border-bottom: 1px inset #4d4db3; }

.border-b-primary-bis-outset {
  border-bottom: 1px outset #4d4db3; }

.border-b-primary-bis-none {
  border-bottom: 1px none #4d4db3; }

.border-b-primary-bis-hidden {
  border-bottom: 1px hidden #4d4db3; }

.border-l-primary-bis {
  border-left: 1px solid #4d4db3; }

.border-l-primary-bis-dotted {
  border-left: 1px dotted #4d4db3; }

.border-l-primary-bis-dashed {
  border-left: 1px dashed #4d4db3; }

.border-l-primary-bis-solid {
  border-left: 1px solid #4d4db3; }

.border-l-primary-bis-double {
  border-left: 1px double #4d4db3; }

.border-l-primary-bis-groove {
  border-left: 1px groove #4d4db3; }

.border-l-primary-bis-ridge {
  border-left: 1px ridge #4d4db3; }

.border-l-primary-bis-inset {
  border-left: 1px inset #4d4db3; }

.border-l-primary-bis-outset {
  border-left: 1px outset #4d4db3; }

.border-l-primary-bis-none {
  border-left: 1px none #4d4db3; }

.border-l-primary-bis-hidden {
  border-left: 1px hidden #4d4db3; }

.has-text-primary-ter {
  color: #7a7ab8; }

.has-background-primary-ter {
  background-color: #7a7ab8; }

.border-primary-ter {
  border: 1px solid #7a7ab8; }

.border-m-primary-ter:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #7a7ab8;
  transform: translateY(-50%); }

.border-primary-ter-dotted {
  border: 1px dotted #7a7ab8; }

.border-primary-ter-dashed {
  border: 1px dashed #7a7ab8; }

.border-primary-ter-solid {
  border: 1px solid #7a7ab8; }

.border-primary-ter-double {
  border: 1px double #7a7ab8; }

.border-primary-ter-groove {
  border: 1px groove #7a7ab8; }

.border-primary-ter-ridge {
  border: 1px ridge #7a7ab8; }

.border-primary-ter-inset {
  border: 1px inset #7a7ab8; }

.border-primary-ter-outset {
  border: 1px outset #7a7ab8; }

.border-primary-ter-none {
  border: 1px none #7a7ab8; }

.border-primary-ter-hidden {
  border: 1px hidden #7a7ab8; }

.border-t-primary-ter {
  border-top: 1px solid #7a7ab8; }

.border-t-primary-ter-dotted {
  border-top: 1px dotted #7a7ab8; }

.border-t-primary-ter-dashed {
  border-top: 1px dashed #7a7ab8; }

.border-t-primary-ter-solid {
  border-top: 1px solid #7a7ab8; }

.border-t-primary-ter-double {
  border-top: 1px double #7a7ab8; }

.border-t-primary-ter-groove {
  border-top: 1px groove #7a7ab8; }

.border-t-primary-ter-ridge {
  border-top: 1px ridge #7a7ab8; }

.border-t-primary-ter-inset {
  border-top: 1px inset #7a7ab8; }

.border-t-primary-ter-outset {
  border-top: 1px outset #7a7ab8; }

.border-t-primary-ter-none {
  border-top: 1px none #7a7ab8; }

.border-t-primary-ter-hidden {
  border-top: 1px hidden #7a7ab8; }

.border-r-primary-ter {
  border-right: 1px solid #7a7ab8; }

.border-r-primary-ter-dotted {
  border-right: 1px dotted #7a7ab8; }

.border-r-primary-ter-dashed {
  border-right: 1px dashed #7a7ab8; }

.border-r-primary-ter-solid {
  border-right: 1px solid #7a7ab8; }

.border-r-primary-ter-double {
  border-right: 1px double #7a7ab8; }

.border-r-primary-ter-groove {
  border-right: 1px groove #7a7ab8; }

.border-r-primary-ter-ridge {
  border-right: 1px ridge #7a7ab8; }

.border-r-primary-ter-inset {
  border-right: 1px inset #7a7ab8; }

.border-r-primary-ter-outset {
  border-right: 1px outset #7a7ab8; }

.border-r-primary-ter-none {
  border-right: 1px none #7a7ab8; }

.border-r-primary-ter-hidden {
  border-right: 1px hidden #7a7ab8; }

.border-b-primary-ter {
  border-bottom: 1px solid #7a7ab8; }

.border-b-primary-ter-dotted {
  border-bottom: 1px dotted #7a7ab8; }

.border-b-primary-ter-dashed {
  border-bottom: 1px dashed #7a7ab8; }

.border-b-primary-ter-solid {
  border-bottom: 1px solid #7a7ab8; }

.border-b-primary-ter-double {
  border-bottom: 1px double #7a7ab8; }

.border-b-primary-ter-groove {
  border-bottom: 1px groove #7a7ab8; }

.border-b-primary-ter-ridge {
  border-bottom: 1px ridge #7a7ab8; }

.border-b-primary-ter-inset {
  border-bottom: 1px inset #7a7ab8; }

.border-b-primary-ter-outset {
  border-bottom: 1px outset #7a7ab8; }

.border-b-primary-ter-none {
  border-bottom: 1px none #7a7ab8; }

.border-b-primary-ter-hidden {
  border-bottom: 1px hidden #7a7ab8; }

.border-l-primary-ter {
  border-left: 1px solid #7a7ab8; }

.border-l-primary-ter-dotted {
  border-left: 1px dotted #7a7ab8; }

.border-l-primary-ter-dashed {
  border-left: 1px dashed #7a7ab8; }

.border-l-primary-ter-solid {
  border-left: 1px solid #7a7ab8; }

.border-l-primary-ter-double {
  border-left: 1px double #7a7ab8; }

.border-l-primary-ter-groove {
  border-left: 1px groove #7a7ab8; }

.border-l-primary-ter-ridge {
  border-left: 1px ridge #7a7ab8; }

.border-l-primary-ter-inset {
  border-left: 1px inset #7a7ab8; }

.border-l-primary-ter-outset {
  border-left: 1px outset #7a7ab8; }

.border-l-primary-ter-none {
  border-left: 1px none #7a7ab8; }

.border-l-primary-ter-hidden {
  border-left: 1px hidden #7a7ab8; }

.has-text-primary-qua {
  color: #b9b9e9; }

.has-background-primary-qua {
  background-color: #b9b9e9; }

.border-primary-qua {
  border: 1px solid #b9b9e9; }

.border-m-primary-qua:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b9b9e9;
  transform: translateY(-50%); }

.border-primary-qua-dotted {
  border: 1px dotted #b9b9e9; }

.border-primary-qua-dashed {
  border: 1px dashed #b9b9e9; }

.border-primary-qua-solid {
  border: 1px solid #b9b9e9; }

.border-primary-qua-double {
  border: 1px double #b9b9e9; }

.border-primary-qua-groove {
  border: 1px groove #b9b9e9; }

.border-primary-qua-ridge {
  border: 1px ridge #b9b9e9; }

.border-primary-qua-inset {
  border: 1px inset #b9b9e9; }

.border-primary-qua-outset {
  border: 1px outset #b9b9e9; }

.border-primary-qua-none {
  border: 1px none #b9b9e9; }

.border-primary-qua-hidden {
  border: 1px hidden #b9b9e9; }

.border-t-primary-qua {
  border-top: 1px solid #b9b9e9; }

.border-t-primary-qua-dotted {
  border-top: 1px dotted #b9b9e9; }

.border-t-primary-qua-dashed {
  border-top: 1px dashed #b9b9e9; }

.border-t-primary-qua-solid {
  border-top: 1px solid #b9b9e9; }

.border-t-primary-qua-double {
  border-top: 1px double #b9b9e9; }

.border-t-primary-qua-groove {
  border-top: 1px groove #b9b9e9; }

.border-t-primary-qua-ridge {
  border-top: 1px ridge #b9b9e9; }

.border-t-primary-qua-inset {
  border-top: 1px inset #b9b9e9; }

.border-t-primary-qua-outset {
  border-top: 1px outset #b9b9e9; }

.border-t-primary-qua-none {
  border-top: 1px none #b9b9e9; }

.border-t-primary-qua-hidden {
  border-top: 1px hidden #b9b9e9; }

.border-r-primary-qua {
  border-right: 1px solid #b9b9e9; }

.border-r-primary-qua-dotted {
  border-right: 1px dotted #b9b9e9; }

.border-r-primary-qua-dashed {
  border-right: 1px dashed #b9b9e9; }

.border-r-primary-qua-solid {
  border-right: 1px solid #b9b9e9; }

.border-r-primary-qua-double {
  border-right: 1px double #b9b9e9; }

.border-r-primary-qua-groove {
  border-right: 1px groove #b9b9e9; }

.border-r-primary-qua-ridge {
  border-right: 1px ridge #b9b9e9; }

.border-r-primary-qua-inset {
  border-right: 1px inset #b9b9e9; }

.border-r-primary-qua-outset {
  border-right: 1px outset #b9b9e9; }

.border-r-primary-qua-none {
  border-right: 1px none #b9b9e9; }

.border-r-primary-qua-hidden {
  border-right: 1px hidden #b9b9e9; }

.border-b-primary-qua {
  border-bottom: 1px solid #b9b9e9; }

.border-b-primary-qua-dotted {
  border-bottom: 1px dotted #b9b9e9; }

.border-b-primary-qua-dashed {
  border-bottom: 1px dashed #b9b9e9; }

.border-b-primary-qua-solid {
  border-bottom: 1px solid #b9b9e9; }

.border-b-primary-qua-double {
  border-bottom: 1px double #b9b9e9; }

.border-b-primary-qua-groove {
  border-bottom: 1px groove #b9b9e9; }

.border-b-primary-qua-ridge {
  border-bottom: 1px ridge #b9b9e9; }

.border-b-primary-qua-inset {
  border-bottom: 1px inset #b9b9e9; }

.border-b-primary-qua-outset {
  border-bottom: 1px outset #b9b9e9; }

.border-b-primary-qua-none {
  border-bottom: 1px none #b9b9e9; }

.border-b-primary-qua-hidden {
  border-bottom: 1px hidden #b9b9e9; }

.border-l-primary-qua {
  border-left: 1px solid #b9b9e9; }

.border-l-primary-qua-dotted {
  border-left: 1px dotted #b9b9e9; }

.border-l-primary-qua-dashed {
  border-left: 1px dashed #b9b9e9; }

.border-l-primary-qua-solid {
  border-left: 1px solid #b9b9e9; }

.border-l-primary-qua-double {
  border-left: 1px double #b9b9e9; }

.border-l-primary-qua-groove {
  border-left: 1px groove #b9b9e9; }

.border-l-primary-qua-ridge {
  border-left: 1px ridge #b9b9e9; }

.border-l-primary-qua-inset {
  border-left: 1px inset #b9b9e9; }

.border-l-primary-qua-outset {
  border-left: 1px outset #b9b9e9; }

.border-l-primary-qua-none {
  border-left: 1px none #b9b9e9; }

.border-l-primary-qua-hidden {
  border-left: 1px hidden #b9b9e9; }

.has-text-primary-qui {
  color: #b8b8d6; }

.has-background-primary-qui {
  background-color: #b8b8d6; }

.border-primary-qui {
  border: 1px solid #b8b8d6; }

.border-m-primary-qui:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b8b8d6;
  transform: translateY(-50%); }

.border-primary-qui-dotted {
  border: 1px dotted #b8b8d6; }

.border-primary-qui-dashed {
  border: 1px dashed #b8b8d6; }

.border-primary-qui-solid {
  border: 1px solid #b8b8d6; }

.border-primary-qui-double {
  border: 1px double #b8b8d6; }

.border-primary-qui-groove {
  border: 1px groove #b8b8d6; }

.border-primary-qui-ridge {
  border: 1px ridge #b8b8d6; }

.border-primary-qui-inset {
  border: 1px inset #b8b8d6; }

.border-primary-qui-outset {
  border: 1px outset #b8b8d6; }

.border-primary-qui-none {
  border: 1px none #b8b8d6; }

.border-primary-qui-hidden {
  border: 1px hidden #b8b8d6; }

.border-t-primary-qui {
  border-top: 1px solid #b8b8d6; }

.border-t-primary-qui-dotted {
  border-top: 1px dotted #b8b8d6; }

.border-t-primary-qui-dashed {
  border-top: 1px dashed #b8b8d6; }

.border-t-primary-qui-solid {
  border-top: 1px solid #b8b8d6; }

.border-t-primary-qui-double {
  border-top: 1px double #b8b8d6; }

.border-t-primary-qui-groove {
  border-top: 1px groove #b8b8d6; }

.border-t-primary-qui-ridge {
  border-top: 1px ridge #b8b8d6; }

.border-t-primary-qui-inset {
  border-top: 1px inset #b8b8d6; }

.border-t-primary-qui-outset {
  border-top: 1px outset #b8b8d6; }

.border-t-primary-qui-none {
  border-top: 1px none #b8b8d6; }

.border-t-primary-qui-hidden {
  border-top: 1px hidden #b8b8d6; }

.border-r-primary-qui {
  border-right: 1px solid #b8b8d6; }

.border-r-primary-qui-dotted {
  border-right: 1px dotted #b8b8d6; }

.border-r-primary-qui-dashed {
  border-right: 1px dashed #b8b8d6; }

.border-r-primary-qui-solid {
  border-right: 1px solid #b8b8d6; }

.border-r-primary-qui-double {
  border-right: 1px double #b8b8d6; }

.border-r-primary-qui-groove {
  border-right: 1px groove #b8b8d6; }

.border-r-primary-qui-ridge {
  border-right: 1px ridge #b8b8d6; }

.border-r-primary-qui-inset {
  border-right: 1px inset #b8b8d6; }

.border-r-primary-qui-outset {
  border-right: 1px outset #b8b8d6; }

.border-r-primary-qui-none {
  border-right: 1px none #b8b8d6; }

.border-r-primary-qui-hidden {
  border-right: 1px hidden #b8b8d6; }

.border-b-primary-qui {
  border-bottom: 1px solid #b8b8d6; }

.border-b-primary-qui-dotted {
  border-bottom: 1px dotted #b8b8d6; }

.border-b-primary-qui-dashed {
  border-bottom: 1px dashed #b8b8d6; }

.border-b-primary-qui-solid {
  border-bottom: 1px solid #b8b8d6; }

.border-b-primary-qui-double {
  border-bottom: 1px double #b8b8d6; }

.border-b-primary-qui-groove {
  border-bottom: 1px groove #b8b8d6; }

.border-b-primary-qui-ridge {
  border-bottom: 1px ridge #b8b8d6; }

.border-b-primary-qui-inset {
  border-bottom: 1px inset #b8b8d6; }

.border-b-primary-qui-outset {
  border-bottom: 1px outset #b8b8d6; }

.border-b-primary-qui-none {
  border-bottom: 1px none #b8b8d6; }

.border-b-primary-qui-hidden {
  border-bottom: 1px hidden #b8b8d6; }

.border-l-primary-qui {
  border-left: 1px solid #b8b8d6; }

.border-l-primary-qui-dotted {
  border-left: 1px dotted #b8b8d6; }

.border-l-primary-qui-dashed {
  border-left: 1px dashed #b8b8d6; }

.border-l-primary-qui-solid {
  border-left: 1px solid #b8b8d6; }

.border-l-primary-qui-double {
  border-left: 1px double #b8b8d6; }

.border-l-primary-qui-groove {
  border-left: 1px groove #b8b8d6; }

.border-l-primary-qui-ridge {
  border-left: 1px ridge #b8b8d6; }

.border-l-primary-qui-inset {
  border-left: 1px inset #b8b8d6; }

.border-l-primary-qui-outset {
  border-left: 1px outset #b8b8d6; }

.border-l-primary-qui-none {
  border-left: 1px none #b8b8d6; }

.border-l-primary-qui-hidden {
  border-left: 1px hidden #b8b8d6; }

.has-text-primary-light {
  color: #dbdbf0; }

.has-background-primary-light {
  background-color: #dbdbf0; }

.border-primary-light {
  border: 1px solid #dbdbf0; }

.border-m-primary-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dbdbf0;
  transform: translateY(-50%); }

.border-primary-light-dotted {
  border: 1px dotted #dbdbf0; }

.border-primary-light-dashed {
  border: 1px dashed #dbdbf0; }

.border-primary-light-solid {
  border: 1px solid #dbdbf0; }

.border-primary-light-double {
  border: 1px double #dbdbf0; }

.border-primary-light-groove {
  border: 1px groove #dbdbf0; }

.border-primary-light-ridge {
  border: 1px ridge #dbdbf0; }

.border-primary-light-inset {
  border: 1px inset #dbdbf0; }

.border-primary-light-outset {
  border: 1px outset #dbdbf0; }

.border-primary-light-none {
  border: 1px none #dbdbf0; }

.border-primary-light-hidden {
  border: 1px hidden #dbdbf0; }

.border-t-primary-light {
  border-top: 1px solid #dbdbf0; }

.border-t-primary-light-dotted {
  border-top: 1px dotted #dbdbf0; }

.border-t-primary-light-dashed {
  border-top: 1px dashed #dbdbf0; }

.border-t-primary-light-solid {
  border-top: 1px solid #dbdbf0; }

.border-t-primary-light-double {
  border-top: 1px double #dbdbf0; }

.border-t-primary-light-groove {
  border-top: 1px groove #dbdbf0; }

.border-t-primary-light-ridge {
  border-top: 1px ridge #dbdbf0; }

.border-t-primary-light-inset {
  border-top: 1px inset #dbdbf0; }

.border-t-primary-light-outset {
  border-top: 1px outset #dbdbf0; }

.border-t-primary-light-none {
  border-top: 1px none #dbdbf0; }

.border-t-primary-light-hidden {
  border-top: 1px hidden #dbdbf0; }

.border-r-primary-light {
  border-right: 1px solid #dbdbf0; }

.border-r-primary-light-dotted {
  border-right: 1px dotted #dbdbf0; }

.border-r-primary-light-dashed {
  border-right: 1px dashed #dbdbf0; }

.border-r-primary-light-solid {
  border-right: 1px solid #dbdbf0; }

.border-r-primary-light-double {
  border-right: 1px double #dbdbf0; }

.border-r-primary-light-groove {
  border-right: 1px groove #dbdbf0; }

.border-r-primary-light-ridge {
  border-right: 1px ridge #dbdbf0; }

.border-r-primary-light-inset {
  border-right: 1px inset #dbdbf0; }

.border-r-primary-light-outset {
  border-right: 1px outset #dbdbf0; }

.border-r-primary-light-none {
  border-right: 1px none #dbdbf0; }

.border-r-primary-light-hidden {
  border-right: 1px hidden #dbdbf0; }

.border-b-primary-light {
  border-bottom: 1px solid #dbdbf0; }

.border-b-primary-light-dotted {
  border-bottom: 1px dotted #dbdbf0; }

.border-b-primary-light-dashed {
  border-bottom: 1px dashed #dbdbf0; }

.border-b-primary-light-solid {
  border-bottom: 1px solid #dbdbf0; }

.border-b-primary-light-double {
  border-bottom: 1px double #dbdbf0; }

.border-b-primary-light-groove {
  border-bottom: 1px groove #dbdbf0; }

.border-b-primary-light-ridge {
  border-bottom: 1px ridge #dbdbf0; }

.border-b-primary-light-inset {
  border-bottom: 1px inset #dbdbf0; }

.border-b-primary-light-outset {
  border-bottom: 1px outset #dbdbf0; }

.border-b-primary-light-none {
  border-bottom: 1px none #dbdbf0; }

.border-b-primary-light-hidden {
  border-bottom: 1px hidden #dbdbf0; }

.border-l-primary-light {
  border-left: 1px solid #dbdbf0; }

.border-l-primary-light-dotted {
  border-left: 1px dotted #dbdbf0; }

.border-l-primary-light-dashed {
  border-left: 1px dashed #dbdbf0; }

.border-l-primary-light-solid {
  border-left: 1px solid #dbdbf0; }

.border-l-primary-light-double {
  border-left: 1px double #dbdbf0; }

.border-l-primary-light-groove {
  border-left: 1px groove #dbdbf0; }

.border-l-primary-light-ridge {
  border-left: 1px ridge #dbdbf0; }

.border-l-primary-light-inset {
  border-left: 1px inset #dbdbf0; }

.border-l-primary-light-outset {
  border-left: 1px outset #dbdbf0; }

.border-l-primary-light-none {
  border-left: 1px none #dbdbf0; }

.border-l-primary-light-hidden {
  border-left: 1px hidden #dbdbf0; }

.has-text-primary-lighter {
  color: #ededf7; }

.has-background-primary-lighter {
  background-color: #ededf7; }

.border-primary-lighter {
  border: 1px solid #ededf7; }

.border-m-primary-lighter:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ededf7;
  transform: translateY(-50%); }

.border-primary-lighter-dotted {
  border: 1px dotted #ededf7; }

.border-primary-lighter-dashed {
  border: 1px dashed #ededf7; }

.border-primary-lighter-solid {
  border: 1px solid #ededf7; }

.border-primary-lighter-double {
  border: 1px double #ededf7; }

.border-primary-lighter-groove {
  border: 1px groove #ededf7; }

.border-primary-lighter-ridge {
  border: 1px ridge #ededf7; }

.border-primary-lighter-inset {
  border: 1px inset #ededf7; }

.border-primary-lighter-outset {
  border: 1px outset #ededf7; }

.border-primary-lighter-none {
  border: 1px none #ededf7; }

.border-primary-lighter-hidden {
  border: 1px hidden #ededf7; }

.border-t-primary-lighter {
  border-top: 1px solid #ededf7; }

.border-t-primary-lighter-dotted {
  border-top: 1px dotted #ededf7; }

.border-t-primary-lighter-dashed {
  border-top: 1px dashed #ededf7; }

.border-t-primary-lighter-solid {
  border-top: 1px solid #ededf7; }

.border-t-primary-lighter-double {
  border-top: 1px double #ededf7; }

.border-t-primary-lighter-groove {
  border-top: 1px groove #ededf7; }

.border-t-primary-lighter-ridge {
  border-top: 1px ridge #ededf7; }

.border-t-primary-lighter-inset {
  border-top: 1px inset #ededf7; }

.border-t-primary-lighter-outset {
  border-top: 1px outset #ededf7; }

.border-t-primary-lighter-none {
  border-top: 1px none #ededf7; }

.border-t-primary-lighter-hidden {
  border-top: 1px hidden #ededf7; }

.border-r-primary-lighter {
  border-right: 1px solid #ededf7; }

.border-r-primary-lighter-dotted {
  border-right: 1px dotted #ededf7; }

.border-r-primary-lighter-dashed {
  border-right: 1px dashed #ededf7; }

.border-r-primary-lighter-solid {
  border-right: 1px solid #ededf7; }

.border-r-primary-lighter-double {
  border-right: 1px double #ededf7; }

.border-r-primary-lighter-groove {
  border-right: 1px groove #ededf7; }

.border-r-primary-lighter-ridge {
  border-right: 1px ridge #ededf7; }

.border-r-primary-lighter-inset {
  border-right: 1px inset #ededf7; }

.border-r-primary-lighter-outset {
  border-right: 1px outset #ededf7; }

.border-r-primary-lighter-none {
  border-right: 1px none #ededf7; }

.border-r-primary-lighter-hidden {
  border-right: 1px hidden #ededf7; }

.border-b-primary-lighter {
  border-bottom: 1px solid #ededf7; }

.border-b-primary-lighter-dotted {
  border-bottom: 1px dotted #ededf7; }

.border-b-primary-lighter-dashed {
  border-bottom: 1px dashed #ededf7; }

.border-b-primary-lighter-solid {
  border-bottom: 1px solid #ededf7; }

.border-b-primary-lighter-double {
  border-bottom: 1px double #ededf7; }

.border-b-primary-lighter-groove {
  border-bottom: 1px groove #ededf7; }

.border-b-primary-lighter-ridge {
  border-bottom: 1px ridge #ededf7; }

.border-b-primary-lighter-inset {
  border-bottom: 1px inset #ededf7; }

.border-b-primary-lighter-outset {
  border-bottom: 1px outset #ededf7; }

.border-b-primary-lighter-none {
  border-bottom: 1px none #ededf7; }

.border-b-primary-lighter-hidden {
  border-bottom: 1px hidden #ededf7; }

.border-l-primary-lighter {
  border-left: 1px solid #ededf7; }

.border-l-primary-lighter-dotted {
  border-left: 1px dotted #ededf7; }

.border-l-primary-lighter-dashed {
  border-left: 1px dashed #ededf7; }

.border-l-primary-lighter-solid {
  border-left: 1px solid #ededf7; }

.border-l-primary-lighter-double {
  border-left: 1px double #ededf7; }

.border-l-primary-lighter-groove {
  border-left: 1px groove #ededf7; }

.border-l-primary-lighter-ridge {
  border-left: 1px ridge #ededf7; }

.border-l-primary-lighter-inset {
  border-left: 1px inset #ededf7; }

.border-l-primary-lighter-outset {
  border-left: 1px outset #ededf7; }

.border-l-primary-lighter-none {
  border-left: 1px none #ededf7; }

.border-l-primary-lighter-hidden {
  border-left: 1px hidden #ededf7; }

.has-text-primary-lighbis {
  color: #f4f4fa; }

.has-background-primary-lighbis {
  background-color: #f4f4fa; }

.border-primary-lighbis {
  border: 1px solid #f4f4fa; }

.border-m-primary-lighbis:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f4f4fa;
  transform: translateY(-50%); }

.border-primary-lighbis-dotted {
  border: 1px dotted #f4f4fa; }

.border-primary-lighbis-dashed {
  border: 1px dashed #f4f4fa; }

.border-primary-lighbis-solid {
  border: 1px solid #f4f4fa; }

.border-primary-lighbis-double {
  border: 1px double #f4f4fa; }

.border-primary-lighbis-groove {
  border: 1px groove #f4f4fa; }

.border-primary-lighbis-ridge {
  border: 1px ridge #f4f4fa; }

.border-primary-lighbis-inset {
  border: 1px inset #f4f4fa; }

.border-primary-lighbis-outset {
  border: 1px outset #f4f4fa; }

.border-primary-lighbis-none {
  border: 1px none #f4f4fa; }

.border-primary-lighbis-hidden {
  border: 1px hidden #f4f4fa; }

.border-t-primary-lighbis {
  border-top: 1px solid #f4f4fa; }

.border-t-primary-lighbis-dotted {
  border-top: 1px dotted #f4f4fa; }

.border-t-primary-lighbis-dashed {
  border-top: 1px dashed #f4f4fa; }

.border-t-primary-lighbis-solid {
  border-top: 1px solid #f4f4fa; }

.border-t-primary-lighbis-double {
  border-top: 1px double #f4f4fa; }

.border-t-primary-lighbis-groove {
  border-top: 1px groove #f4f4fa; }

.border-t-primary-lighbis-ridge {
  border-top: 1px ridge #f4f4fa; }

.border-t-primary-lighbis-inset {
  border-top: 1px inset #f4f4fa; }

.border-t-primary-lighbis-outset {
  border-top: 1px outset #f4f4fa; }

.border-t-primary-lighbis-none {
  border-top: 1px none #f4f4fa; }

.border-t-primary-lighbis-hidden {
  border-top: 1px hidden #f4f4fa; }

.border-r-primary-lighbis {
  border-right: 1px solid #f4f4fa; }

.border-r-primary-lighbis-dotted {
  border-right: 1px dotted #f4f4fa; }

.border-r-primary-lighbis-dashed {
  border-right: 1px dashed #f4f4fa; }

.border-r-primary-lighbis-solid {
  border-right: 1px solid #f4f4fa; }

.border-r-primary-lighbis-double {
  border-right: 1px double #f4f4fa; }

.border-r-primary-lighbis-groove {
  border-right: 1px groove #f4f4fa; }

.border-r-primary-lighbis-ridge {
  border-right: 1px ridge #f4f4fa; }

.border-r-primary-lighbis-inset {
  border-right: 1px inset #f4f4fa; }

.border-r-primary-lighbis-outset {
  border-right: 1px outset #f4f4fa; }

.border-r-primary-lighbis-none {
  border-right: 1px none #f4f4fa; }

.border-r-primary-lighbis-hidden {
  border-right: 1px hidden #f4f4fa; }

.border-b-primary-lighbis {
  border-bottom: 1px solid #f4f4fa; }

.border-b-primary-lighbis-dotted {
  border-bottom: 1px dotted #f4f4fa; }

.border-b-primary-lighbis-dashed {
  border-bottom: 1px dashed #f4f4fa; }

.border-b-primary-lighbis-solid {
  border-bottom: 1px solid #f4f4fa; }

.border-b-primary-lighbis-double {
  border-bottom: 1px double #f4f4fa; }

.border-b-primary-lighbis-groove {
  border-bottom: 1px groove #f4f4fa; }

.border-b-primary-lighbis-ridge {
  border-bottom: 1px ridge #f4f4fa; }

.border-b-primary-lighbis-inset {
  border-bottom: 1px inset #f4f4fa; }

.border-b-primary-lighbis-outset {
  border-bottom: 1px outset #f4f4fa; }

.border-b-primary-lighbis-none {
  border-bottom: 1px none #f4f4fa; }

.border-b-primary-lighbis-hidden {
  border-bottom: 1px hidden #f4f4fa; }

.border-l-primary-lighbis {
  border-left: 1px solid #f4f4fa; }

.border-l-primary-lighbis-dotted {
  border-left: 1px dotted #f4f4fa; }

.border-l-primary-lighbis-dashed {
  border-left: 1px dashed #f4f4fa; }

.border-l-primary-lighbis-solid {
  border-left: 1px solid #f4f4fa; }

.border-l-primary-lighbis-double {
  border-left: 1px double #f4f4fa; }

.border-l-primary-lighbis-groove {
  border-left: 1px groove #f4f4fa; }

.border-l-primary-lighbis-ridge {
  border-left: 1px ridge #f4f4fa; }

.border-l-primary-lighbis-inset {
  border-left: 1px inset #f4f4fa; }

.border-l-primary-lighbis-outset {
  border-left: 1px outset #f4f4fa; }

.border-l-primary-lighbis-none {
  border-left: 1px none #f4f4fa; }

.border-l-primary-lighbis-hidden {
  border-left: 1px hidden #f4f4fa; }

.has-text-secondary {
  color: #eb6666; }

.has-background-secondary {
  background-color: #eb6666; }

.border-secondary {
  border: 1px solid #eb6666; }

.border-m-secondary:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #eb6666;
  transform: translateY(-50%); }

.border-secondary-dotted {
  border: 1px dotted #eb6666; }

.border-secondary-dashed {
  border: 1px dashed #eb6666; }

.border-secondary-solid {
  border: 1px solid #eb6666; }

.border-secondary-double {
  border: 1px double #eb6666; }

.border-secondary-groove {
  border: 1px groove #eb6666; }

.border-secondary-ridge {
  border: 1px ridge #eb6666; }

.border-secondary-inset {
  border: 1px inset #eb6666; }

.border-secondary-outset {
  border: 1px outset #eb6666; }

.border-secondary-none {
  border: 1px none #eb6666; }

.border-secondary-hidden {
  border: 1px hidden #eb6666; }

.border-t-secondary {
  border-top: 1px solid #eb6666; }

.border-t-secondary-dotted {
  border-top: 1px dotted #eb6666; }

.border-t-secondary-dashed {
  border-top: 1px dashed #eb6666; }

.border-t-secondary-solid {
  border-top: 1px solid #eb6666; }

.border-t-secondary-double {
  border-top: 1px double #eb6666; }

.border-t-secondary-groove {
  border-top: 1px groove #eb6666; }

.border-t-secondary-ridge {
  border-top: 1px ridge #eb6666; }

.border-t-secondary-inset {
  border-top: 1px inset #eb6666; }

.border-t-secondary-outset {
  border-top: 1px outset #eb6666; }

.border-t-secondary-none {
  border-top: 1px none #eb6666; }

.border-t-secondary-hidden {
  border-top: 1px hidden #eb6666; }

.border-r-secondary {
  border-right: 1px solid #eb6666; }

.border-r-secondary-dotted {
  border-right: 1px dotted #eb6666; }

.border-r-secondary-dashed {
  border-right: 1px dashed #eb6666; }

.border-r-secondary-solid {
  border-right: 1px solid #eb6666; }

.border-r-secondary-double {
  border-right: 1px double #eb6666; }

.border-r-secondary-groove {
  border-right: 1px groove #eb6666; }

.border-r-secondary-ridge {
  border-right: 1px ridge #eb6666; }

.border-r-secondary-inset {
  border-right: 1px inset #eb6666; }

.border-r-secondary-outset {
  border-right: 1px outset #eb6666; }

.border-r-secondary-none {
  border-right: 1px none #eb6666; }

.border-r-secondary-hidden {
  border-right: 1px hidden #eb6666; }

.border-b-secondary {
  border-bottom: 1px solid #eb6666; }

.border-b-secondary-dotted {
  border-bottom: 1px dotted #eb6666; }

.border-b-secondary-dashed {
  border-bottom: 1px dashed #eb6666; }

.border-b-secondary-solid {
  border-bottom: 1px solid #eb6666; }

.border-b-secondary-double {
  border-bottom: 1px double #eb6666; }

.border-b-secondary-groove {
  border-bottom: 1px groove #eb6666; }

.border-b-secondary-ridge {
  border-bottom: 1px ridge #eb6666; }

.border-b-secondary-inset {
  border-bottom: 1px inset #eb6666; }

.border-b-secondary-outset {
  border-bottom: 1px outset #eb6666; }

.border-b-secondary-none {
  border-bottom: 1px none #eb6666; }

.border-b-secondary-hidden {
  border-bottom: 1px hidden #eb6666; }

.border-l-secondary {
  border-left: 1px solid #eb6666; }

.border-l-secondary-dotted {
  border-left: 1px dotted #eb6666; }

.border-l-secondary-dashed {
  border-left: 1px dashed #eb6666; }

.border-l-secondary-solid {
  border-left: 1px solid #eb6666; }

.border-l-secondary-double {
  border-left: 1px double #eb6666; }

.border-l-secondary-groove {
  border-left: 1px groove #eb6666; }

.border-l-secondary-ridge {
  border-left: 1px ridge #eb6666; }

.border-l-secondary-inset {
  border-left: 1px inset #eb6666; }

.border-l-secondary-outset {
  border-left: 1px outset #eb6666; }

.border-l-secondary-none {
  border-left: 1px none #eb6666; }

.border-l-secondary-hidden {
  border-left: 1px hidden #eb6666; }

.has-text-danger {
  color: #cc3333; }

.has-background-danger {
  background-color: #cc3333; }

.border-danger {
  border: 1px solid #cc3333; }

.border-m-danger:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #cc3333;
  transform: translateY(-50%); }

.border-danger-dotted {
  border: 1px dotted #cc3333; }

.border-danger-dashed {
  border: 1px dashed #cc3333; }

.border-danger-solid {
  border: 1px solid #cc3333; }

.border-danger-double {
  border: 1px double #cc3333; }

.border-danger-groove {
  border: 1px groove #cc3333; }

.border-danger-ridge {
  border: 1px ridge #cc3333; }

.border-danger-inset {
  border: 1px inset #cc3333; }

.border-danger-outset {
  border: 1px outset #cc3333; }

.border-danger-none {
  border: 1px none #cc3333; }

.border-danger-hidden {
  border: 1px hidden #cc3333; }

.border-t-danger {
  border-top: 1px solid #cc3333; }

.border-t-danger-dotted {
  border-top: 1px dotted #cc3333; }

.border-t-danger-dashed {
  border-top: 1px dashed #cc3333; }

.border-t-danger-solid {
  border-top: 1px solid #cc3333; }

.border-t-danger-double {
  border-top: 1px double #cc3333; }

.border-t-danger-groove {
  border-top: 1px groove #cc3333; }

.border-t-danger-ridge {
  border-top: 1px ridge #cc3333; }

.border-t-danger-inset {
  border-top: 1px inset #cc3333; }

.border-t-danger-outset {
  border-top: 1px outset #cc3333; }

.border-t-danger-none {
  border-top: 1px none #cc3333; }

.border-t-danger-hidden {
  border-top: 1px hidden #cc3333; }

.border-r-danger {
  border-right: 1px solid #cc3333; }

.border-r-danger-dotted {
  border-right: 1px dotted #cc3333; }

.border-r-danger-dashed {
  border-right: 1px dashed #cc3333; }

.border-r-danger-solid {
  border-right: 1px solid #cc3333; }

.border-r-danger-double {
  border-right: 1px double #cc3333; }

.border-r-danger-groove {
  border-right: 1px groove #cc3333; }

.border-r-danger-ridge {
  border-right: 1px ridge #cc3333; }

.border-r-danger-inset {
  border-right: 1px inset #cc3333; }

.border-r-danger-outset {
  border-right: 1px outset #cc3333; }

.border-r-danger-none {
  border-right: 1px none #cc3333; }

.border-r-danger-hidden {
  border-right: 1px hidden #cc3333; }

.border-b-danger {
  border-bottom: 1px solid #cc3333; }

.border-b-danger-dotted {
  border-bottom: 1px dotted #cc3333; }

.border-b-danger-dashed {
  border-bottom: 1px dashed #cc3333; }

.border-b-danger-solid {
  border-bottom: 1px solid #cc3333; }

.border-b-danger-double {
  border-bottom: 1px double #cc3333; }

.border-b-danger-groove {
  border-bottom: 1px groove #cc3333; }

.border-b-danger-ridge {
  border-bottom: 1px ridge #cc3333; }

.border-b-danger-inset {
  border-bottom: 1px inset #cc3333; }

.border-b-danger-outset {
  border-bottom: 1px outset #cc3333; }

.border-b-danger-none {
  border-bottom: 1px none #cc3333; }

.border-b-danger-hidden {
  border-bottom: 1px hidden #cc3333; }

.border-l-danger {
  border-left: 1px solid #cc3333; }

.border-l-danger-dotted {
  border-left: 1px dotted #cc3333; }

.border-l-danger-dashed {
  border-left: 1px dashed #cc3333; }

.border-l-danger-solid {
  border-left: 1px solid #cc3333; }

.border-l-danger-double {
  border-left: 1px double #cc3333; }

.border-l-danger-groove {
  border-left: 1px groove #cc3333; }

.border-l-danger-ridge {
  border-left: 1px ridge #cc3333; }

.border-l-danger-inset {
  border-left: 1px inset #cc3333; }

.border-l-danger-outset {
  border-left: 1px outset #cc3333; }

.border-l-danger-none {
  border-left: 1px none #cc3333; }

.border-l-danger-hidden {
  border-left: 1px hidden #cc3333; }

.has-text-danger-light {
  color: #f6dada; }

.has-background-danger-light {
  background-color: #f6dada; }

.border-danger-light {
  border: 1px solid #f6dada; }

.border-m-danger-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f6dada;
  transform: translateY(-50%); }

.border-danger-light-dotted {
  border: 1px dotted #f6dada; }

.border-danger-light-dashed {
  border: 1px dashed #f6dada; }

.border-danger-light-solid {
  border: 1px solid #f6dada; }

.border-danger-light-double {
  border: 1px double #f6dada; }

.border-danger-light-groove {
  border: 1px groove #f6dada; }

.border-danger-light-ridge {
  border: 1px ridge #f6dada; }

.border-danger-light-inset {
  border: 1px inset #f6dada; }

.border-danger-light-outset {
  border: 1px outset #f6dada; }

.border-danger-light-none {
  border: 1px none #f6dada; }

.border-danger-light-hidden {
  border: 1px hidden #f6dada; }

.border-t-danger-light {
  border-top: 1px solid #f6dada; }

.border-t-danger-light-dotted {
  border-top: 1px dotted #f6dada; }

.border-t-danger-light-dashed {
  border-top: 1px dashed #f6dada; }

.border-t-danger-light-solid {
  border-top: 1px solid #f6dada; }

.border-t-danger-light-double {
  border-top: 1px double #f6dada; }

.border-t-danger-light-groove {
  border-top: 1px groove #f6dada; }

.border-t-danger-light-ridge {
  border-top: 1px ridge #f6dada; }

.border-t-danger-light-inset {
  border-top: 1px inset #f6dada; }

.border-t-danger-light-outset {
  border-top: 1px outset #f6dada; }

.border-t-danger-light-none {
  border-top: 1px none #f6dada; }

.border-t-danger-light-hidden {
  border-top: 1px hidden #f6dada; }

.border-r-danger-light {
  border-right: 1px solid #f6dada; }

.border-r-danger-light-dotted {
  border-right: 1px dotted #f6dada; }

.border-r-danger-light-dashed {
  border-right: 1px dashed #f6dada; }

.border-r-danger-light-solid {
  border-right: 1px solid #f6dada; }

.border-r-danger-light-double {
  border-right: 1px double #f6dada; }

.border-r-danger-light-groove {
  border-right: 1px groove #f6dada; }

.border-r-danger-light-ridge {
  border-right: 1px ridge #f6dada; }

.border-r-danger-light-inset {
  border-right: 1px inset #f6dada; }

.border-r-danger-light-outset {
  border-right: 1px outset #f6dada; }

.border-r-danger-light-none {
  border-right: 1px none #f6dada; }

.border-r-danger-light-hidden {
  border-right: 1px hidden #f6dada; }

.border-b-danger-light {
  border-bottom: 1px solid #f6dada; }

.border-b-danger-light-dotted {
  border-bottom: 1px dotted #f6dada; }

.border-b-danger-light-dashed {
  border-bottom: 1px dashed #f6dada; }

.border-b-danger-light-solid {
  border-bottom: 1px solid #f6dada; }

.border-b-danger-light-double {
  border-bottom: 1px double #f6dada; }

.border-b-danger-light-groove {
  border-bottom: 1px groove #f6dada; }

.border-b-danger-light-ridge {
  border-bottom: 1px ridge #f6dada; }

.border-b-danger-light-inset {
  border-bottom: 1px inset #f6dada; }

.border-b-danger-light-outset {
  border-bottom: 1px outset #f6dada; }

.border-b-danger-light-none {
  border-bottom: 1px none #f6dada; }

.border-b-danger-light-hidden {
  border-bottom: 1px hidden #f6dada; }

.border-l-danger-light {
  border-left: 1px solid #f6dada; }

.border-l-danger-light-dotted {
  border-left: 1px dotted #f6dada; }

.border-l-danger-light-dashed {
  border-left: 1px dashed #f6dada; }

.border-l-danger-light-solid {
  border-left: 1px solid #f6dada; }

.border-l-danger-light-double {
  border-left: 1px double #f6dada; }

.border-l-danger-light-groove {
  border-left: 1px groove #f6dada; }

.border-l-danger-light-ridge {
  border-left: 1px ridge #f6dada; }

.border-l-danger-light-inset {
  border-left: 1px inset #f6dada; }

.border-l-danger-light-outset {
  border-left: 1px outset #f6dada; }

.border-l-danger-light-none {
  border-left: 1px none #f6dada; }

.border-l-danger-light-hidden {
  border-left: 1px hidden #f6dada; }

.has-text-warning {
  color: #ffd500; }

.has-background-warning {
  background-color: #ffd500; }

.border-warning {
  border: 1px solid #ffd500; }

.border-m-warning:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ffd500;
  transform: translateY(-50%); }

.border-warning-dotted {
  border: 1px dotted #ffd500; }

.border-warning-dashed {
  border: 1px dashed #ffd500; }

.border-warning-solid {
  border: 1px solid #ffd500; }

.border-warning-double {
  border: 1px double #ffd500; }

.border-warning-groove {
  border: 1px groove #ffd500; }

.border-warning-ridge {
  border: 1px ridge #ffd500; }

.border-warning-inset {
  border: 1px inset #ffd500; }

.border-warning-outset {
  border: 1px outset #ffd500; }

.border-warning-none {
  border: 1px none #ffd500; }

.border-warning-hidden {
  border: 1px hidden #ffd500; }

.border-t-warning {
  border-top: 1px solid #ffd500; }

.border-t-warning-dotted {
  border-top: 1px dotted #ffd500; }

.border-t-warning-dashed {
  border-top: 1px dashed #ffd500; }

.border-t-warning-solid {
  border-top: 1px solid #ffd500; }

.border-t-warning-double {
  border-top: 1px double #ffd500; }

.border-t-warning-groove {
  border-top: 1px groove #ffd500; }

.border-t-warning-ridge {
  border-top: 1px ridge #ffd500; }

.border-t-warning-inset {
  border-top: 1px inset #ffd500; }

.border-t-warning-outset {
  border-top: 1px outset #ffd500; }

.border-t-warning-none {
  border-top: 1px none #ffd500; }

.border-t-warning-hidden {
  border-top: 1px hidden #ffd500; }

.border-r-warning {
  border-right: 1px solid #ffd500; }

.border-r-warning-dotted {
  border-right: 1px dotted #ffd500; }

.border-r-warning-dashed {
  border-right: 1px dashed #ffd500; }

.border-r-warning-solid {
  border-right: 1px solid #ffd500; }

.border-r-warning-double {
  border-right: 1px double #ffd500; }

.border-r-warning-groove {
  border-right: 1px groove #ffd500; }

.border-r-warning-ridge {
  border-right: 1px ridge #ffd500; }

.border-r-warning-inset {
  border-right: 1px inset #ffd500; }

.border-r-warning-outset {
  border-right: 1px outset #ffd500; }

.border-r-warning-none {
  border-right: 1px none #ffd500; }

.border-r-warning-hidden {
  border-right: 1px hidden #ffd500; }

.border-b-warning {
  border-bottom: 1px solid #ffd500; }

.border-b-warning-dotted {
  border-bottom: 1px dotted #ffd500; }

.border-b-warning-dashed {
  border-bottom: 1px dashed #ffd500; }

.border-b-warning-solid {
  border-bottom: 1px solid #ffd500; }

.border-b-warning-double {
  border-bottom: 1px double #ffd500; }

.border-b-warning-groove {
  border-bottom: 1px groove #ffd500; }

.border-b-warning-ridge {
  border-bottom: 1px ridge #ffd500; }

.border-b-warning-inset {
  border-bottom: 1px inset #ffd500; }

.border-b-warning-outset {
  border-bottom: 1px outset #ffd500; }

.border-b-warning-none {
  border-bottom: 1px none #ffd500; }

.border-b-warning-hidden {
  border-bottom: 1px hidden #ffd500; }

.border-l-warning {
  border-left: 1px solid #ffd500; }

.border-l-warning-dotted {
  border-left: 1px dotted #ffd500; }

.border-l-warning-dashed {
  border-left: 1px dashed #ffd500; }

.border-l-warning-solid {
  border-left: 1px solid #ffd500; }

.border-l-warning-double {
  border-left: 1px double #ffd500; }

.border-l-warning-groove {
  border-left: 1px groove #ffd500; }

.border-l-warning-ridge {
  border-left: 1px ridge #ffd500; }

.border-l-warning-inset {
  border-left: 1px inset #ffd500; }

.border-l-warning-outset {
  border-left: 1px outset #ffd500; }

.border-l-warning-none {
  border-left: 1px none #ffd500; }

.border-l-warning-hidden {
  border-left: 1px hidden #ffd500; }

.has-text-success {
  color: #66cc33; }

.has-background-success {
  background-color: #66cc33; }

.border-success {
  border: 1px solid #66cc33; }

.border-m-success:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #66cc33;
  transform: translateY(-50%); }

.border-success-dotted {
  border: 1px dotted #66cc33; }

.border-success-dashed {
  border: 1px dashed #66cc33; }

.border-success-solid {
  border: 1px solid #66cc33; }

.border-success-double {
  border: 1px double #66cc33; }

.border-success-groove {
  border: 1px groove #66cc33; }

.border-success-ridge {
  border: 1px ridge #66cc33; }

.border-success-inset {
  border: 1px inset #66cc33; }

.border-success-outset {
  border: 1px outset #66cc33; }

.border-success-none {
  border: 1px none #66cc33; }

.border-success-hidden {
  border: 1px hidden #66cc33; }

.border-t-success {
  border-top: 1px solid #66cc33; }

.border-t-success-dotted {
  border-top: 1px dotted #66cc33; }

.border-t-success-dashed {
  border-top: 1px dashed #66cc33; }

.border-t-success-solid {
  border-top: 1px solid #66cc33; }

.border-t-success-double {
  border-top: 1px double #66cc33; }

.border-t-success-groove {
  border-top: 1px groove #66cc33; }

.border-t-success-ridge {
  border-top: 1px ridge #66cc33; }

.border-t-success-inset {
  border-top: 1px inset #66cc33; }

.border-t-success-outset {
  border-top: 1px outset #66cc33; }

.border-t-success-none {
  border-top: 1px none #66cc33; }

.border-t-success-hidden {
  border-top: 1px hidden #66cc33; }

.border-r-success {
  border-right: 1px solid #66cc33; }

.border-r-success-dotted {
  border-right: 1px dotted #66cc33; }

.border-r-success-dashed {
  border-right: 1px dashed #66cc33; }

.border-r-success-solid {
  border-right: 1px solid #66cc33; }

.border-r-success-double {
  border-right: 1px double #66cc33; }

.border-r-success-groove {
  border-right: 1px groove #66cc33; }

.border-r-success-ridge {
  border-right: 1px ridge #66cc33; }

.border-r-success-inset {
  border-right: 1px inset #66cc33; }

.border-r-success-outset {
  border-right: 1px outset #66cc33; }

.border-r-success-none {
  border-right: 1px none #66cc33; }

.border-r-success-hidden {
  border-right: 1px hidden #66cc33; }

.border-b-success {
  border-bottom: 1px solid #66cc33; }

.border-b-success-dotted {
  border-bottom: 1px dotted #66cc33; }

.border-b-success-dashed {
  border-bottom: 1px dashed #66cc33; }

.border-b-success-solid {
  border-bottom: 1px solid #66cc33; }

.border-b-success-double {
  border-bottom: 1px double #66cc33; }

.border-b-success-groove {
  border-bottom: 1px groove #66cc33; }

.border-b-success-ridge {
  border-bottom: 1px ridge #66cc33; }

.border-b-success-inset {
  border-bottom: 1px inset #66cc33; }

.border-b-success-outset {
  border-bottom: 1px outset #66cc33; }

.border-b-success-none {
  border-bottom: 1px none #66cc33; }

.border-b-success-hidden {
  border-bottom: 1px hidden #66cc33; }

.border-l-success {
  border-left: 1px solid #66cc33; }

.border-l-success-dotted {
  border-left: 1px dotted #66cc33; }

.border-l-success-dashed {
  border-left: 1px dashed #66cc33; }

.border-l-success-solid {
  border-left: 1px solid #66cc33; }

.border-l-success-double {
  border-left: 1px double #66cc33; }

.border-l-success-groove {
  border-left: 1px groove #66cc33; }

.border-l-success-ridge {
  border-left: 1px ridge #66cc33; }

.border-l-success-inset {
  border-left: 1px inset #66cc33; }

.border-l-success-outset {
  border-left: 1px outset #66cc33; }

.border-l-success-none {
  border-left: 1px none #66cc33; }

.border-l-success-hidden {
  border-left: 1px hidden #66cc33; }

.has-text-success-light {
  color: #dbffe0; }

.has-background-success-light {
  background-color: #dbffe0; }

.border-success-light {
  border: 1px solid #dbffe0; }

.border-m-success-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dbffe0;
  transform: translateY(-50%); }

.border-success-light-dotted {
  border: 1px dotted #dbffe0; }

.border-success-light-dashed {
  border: 1px dashed #dbffe0; }

.border-success-light-solid {
  border: 1px solid #dbffe0; }

.border-success-light-double {
  border: 1px double #dbffe0; }

.border-success-light-groove {
  border: 1px groove #dbffe0; }

.border-success-light-ridge {
  border: 1px ridge #dbffe0; }

.border-success-light-inset {
  border: 1px inset #dbffe0; }

.border-success-light-outset {
  border: 1px outset #dbffe0; }

.border-success-light-none {
  border: 1px none #dbffe0; }

.border-success-light-hidden {
  border: 1px hidden #dbffe0; }

.border-t-success-light {
  border-top: 1px solid #dbffe0; }

.border-t-success-light-dotted {
  border-top: 1px dotted #dbffe0; }

.border-t-success-light-dashed {
  border-top: 1px dashed #dbffe0; }

.border-t-success-light-solid {
  border-top: 1px solid #dbffe0; }

.border-t-success-light-double {
  border-top: 1px double #dbffe0; }

.border-t-success-light-groove {
  border-top: 1px groove #dbffe0; }

.border-t-success-light-ridge {
  border-top: 1px ridge #dbffe0; }

.border-t-success-light-inset {
  border-top: 1px inset #dbffe0; }

.border-t-success-light-outset {
  border-top: 1px outset #dbffe0; }

.border-t-success-light-none {
  border-top: 1px none #dbffe0; }

.border-t-success-light-hidden {
  border-top: 1px hidden #dbffe0; }

.border-r-success-light {
  border-right: 1px solid #dbffe0; }

.border-r-success-light-dotted {
  border-right: 1px dotted #dbffe0; }

.border-r-success-light-dashed {
  border-right: 1px dashed #dbffe0; }

.border-r-success-light-solid {
  border-right: 1px solid #dbffe0; }

.border-r-success-light-double {
  border-right: 1px double #dbffe0; }

.border-r-success-light-groove {
  border-right: 1px groove #dbffe0; }

.border-r-success-light-ridge {
  border-right: 1px ridge #dbffe0; }

.border-r-success-light-inset {
  border-right: 1px inset #dbffe0; }

.border-r-success-light-outset {
  border-right: 1px outset #dbffe0; }

.border-r-success-light-none {
  border-right: 1px none #dbffe0; }

.border-r-success-light-hidden {
  border-right: 1px hidden #dbffe0; }

.border-b-success-light {
  border-bottom: 1px solid #dbffe0; }

.border-b-success-light-dotted {
  border-bottom: 1px dotted #dbffe0; }

.border-b-success-light-dashed {
  border-bottom: 1px dashed #dbffe0; }

.border-b-success-light-solid {
  border-bottom: 1px solid #dbffe0; }

.border-b-success-light-double {
  border-bottom: 1px double #dbffe0; }

.border-b-success-light-groove {
  border-bottom: 1px groove #dbffe0; }

.border-b-success-light-ridge {
  border-bottom: 1px ridge #dbffe0; }

.border-b-success-light-inset {
  border-bottom: 1px inset #dbffe0; }

.border-b-success-light-outset {
  border-bottom: 1px outset #dbffe0; }

.border-b-success-light-none {
  border-bottom: 1px none #dbffe0; }

.border-b-success-light-hidden {
  border-bottom: 1px hidden #dbffe0; }

.border-l-success-light {
  border-left: 1px solid #dbffe0; }

.border-l-success-light-dotted {
  border-left: 1px dotted #dbffe0; }

.border-l-success-light-dashed {
  border-left: 1px dashed #dbffe0; }

.border-l-success-light-solid {
  border-left: 1px solid #dbffe0; }

.border-l-success-light-double {
  border-left: 1px double #dbffe0; }

.border-l-success-light-groove {
  border-left: 1px groove #dbffe0; }

.border-l-success-light-ridge {
  border-left: 1px ridge #dbffe0; }

.border-l-success-light-inset {
  border-left: 1px inset #dbffe0; }

.border-l-success-light-outset {
  border-left: 1px outset #dbffe0; }

.border-l-success-light-none {
  border-left: 1px none #dbffe0; }

.border-l-success-light-hidden {
  border-left: 1px hidden #dbffe0; }

.has-text-info {
  color: #209fee; }

.has-background-info {
  background-color: #209fee; }

.border-info {
  border: 1px solid #209fee; }

.border-m-info:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #209fee;
  transform: translateY(-50%); }

.border-info-dotted {
  border: 1px dotted #209fee; }

.border-info-dashed {
  border: 1px dashed #209fee; }

.border-info-solid {
  border: 1px solid #209fee; }

.border-info-double {
  border: 1px double #209fee; }

.border-info-groove {
  border: 1px groove #209fee; }

.border-info-ridge {
  border: 1px ridge #209fee; }

.border-info-inset {
  border: 1px inset #209fee; }

.border-info-outset {
  border: 1px outset #209fee; }

.border-info-none {
  border: 1px none #209fee; }

.border-info-hidden {
  border: 1px hidden #209fee; }

.border-t-info {
  border-top: 1px solid #209fee; }

.border-t-info-dotted {
  border-top: 1px dotted #209fee; }

.border-t-info-dashed {
  border-top: 1px dashed #209fee; }

.border-t-info-solid {
  border-top: 1px solid #209fee; }

.border-t-info-double {
  border-top: 1px double #209fee; }

.border-t-info-groove {
  border-top: 1px groove #209fee; }

.border-t-info-ridge {
  border-top: 1px ridge #209fee; }

.border-t-info-inset {
  border-top: 1px inset #209fee; }

.border-t-info-outset {
  border-top: 1px outset #209fee; }

.border-t-info-none {
  border-top: 1px none #209fee; }

.border-t-info-hidden {
  border-top: 1px hidden #209fee; }

.border-r-info {
  border-right: 1px solid #209fee; }

.border-r-info-dotted {
  border-right: 1px dotted #209fee; }

.border-r-info-dashed {
  border-right: 1px dashed #209fee; }

.border-r-info-solid {
  border-right: 1px solid #209fee; }

.border-r-info-double {
  border-right: 1px double #209fee; }

.border-r-info-groove {
  border-right: 1px groove #209fee; }

.border-r-info-ridge {
  border-right: 1px ridge #209fee; }

.border-r-info-inset {
  border-right: 1px inset #209fee; }

.border-r-info-outset {
  border-right: 1px outset #209fee; }

.border-r-info-none {
  border-right: 1px none #209fee; }

.border-r-info-hidden {
  border-right: 1px hidden #209fee; }

.border-b-info {
  border-bottom: 1px solid #209fee; }

.border-b-info-dotted {
  border-bottom: 1px dotted #209fee; }

.border-b-info-dashed {
  border-bottom: 1px dashed #209fee; }

.border-b-info-solid {
  border-bottom: 1px solid #209fee; }

.border-b-info-double {
  border-bottom: 1px double #209fee; }

.border-b-info-groove {
  border-bottom: 1px groove #209fee; }

.border-b-info-ridge {
  border-bottom: 1px ridge #209fee; }

.border-b-info-inset {
  border-bottom: 1px inset #209fee; }

.border-b-info-outset {
  border-bottom: 1px outset #209fee; }

.border-b-info-none {
  border-bottom: 1px none #209fee; }

.border-b-info-hidden {
  border-bottom: 1px hidden #209fee; }

.border-l-info {
  border-left: 1px solid #209fee; }

.border-l-info-dotted {
  border-left: 1px dotted #209fee; }

.border-l-info-dashed {
  border-left: 1px dashed #209fee; }

.border-l-info-solid {
  border-left: 1px solid #209fee; }

.border-l-info-double {
  border-left: 1px double #209fee; }

.border-l-info-groove {
  border-left: 1px groove #209fee; }

.border-l-info-ridge {
  border-left: 1px ridge #209fee; }

.border-l-info-inset {
  border-left: 1px inset #209fee; }

.border-l-info-outset {
  border-left: 1px outset #209fee; }

.border-l-info-none {
  border-left: 1px none #209fee; }

.border-l-info-hidden {
  border-left: 1px hidden #209fee; }

.has-text-black {
  color: #0a0a0a; }

.has-background-black {
  background-color: #0a0a0a; }

.border-black {
  border: 1px solid #0a0a0a; }

.border-black-dotted {
  border: 1px dotted #0a0a0a; }

.border-black-dashed {
  border: 1px dashed #0a0a0a; }

.border-black-solid {
  border: 1px solid #0a0a0a; }

.border-black-double {
  border: 1px double #0a0a0a; }

.border-black-groove {
  border: 1px groove #0a0a0a; }

.border-black-ridge {
  border: 1px ridge #0a0a0a; }

.border-black-inset {
  border: 1px inset #0a0a0a; }

.border-black-outset {
  border: 1px outset #0a0a0a; }

.border-black-none {
  border: 1px none #0a0a0a; }

.border-black-hidden {
  border: 1px hidden #0a0a0a; }

.border-t-black {
  border-top: 1px solid #0a0a0a; }

.border-t-black-dotted {
  border-top: 1px dotted #0a0a0a; }

.border-t-black-dashed {
  border-top: 1px dashed #0a0a0a; }

.border-t-black-solid {
  border-top: 1px solid #0a0a0a; }

.border-t-black-double {
  border-top: 1px double #0a0a0a; }

.border-t-black-groove {
  border-top: 1px groove #0a0a0a; }

.border-t-black-ridge {
  border-top: 1px ridge #0a0a0a; }

.border-t-black-inset {
  border-top: 1px inset #0a0a0a; }

.border-t-black-outset {
  border-top: 1px outset #0a0a0a; }

.border-t-black-none {
  border-top: 1px none #0a0a0a; }

.border-t-black-hidden {
  border-top: 1px hidden #0a0a0a; }

.border-r-black {
  border-right: 1px solid #0a0a0a; }

.border-r-black-dotted {
  border-right: 1px dotted #0a0a0a; }

.border-r-black-dashed {
  border-right: 1px dashed #0a0a0a; }

.border-r-black-solid {
  border-right: 1px solid #0a0a0a; }

.border-r-black-double {
  border-right: 1px double #0a0a0a; }

.border-r-black-groove {
  border-right: 1px groove #0a0a0a; }

.border-r-black-ridge {
  border-right: 1px ridge #0a0a0a; }

.border-r-black-inset {
  border-right: 1px inset #0a0a0a; }

.border-r-black-outset {
  border-right: 1px outset #0a0a0a; }

.border-r-black-none {
  border-right: 1px none #0a0a0a; }

.border-r-black-hidden {
  border-right: 1px hidden #0a0a0a; }

.border-b-black {
  border-bottom: 1px solid #0a0a0a; }

.border-b-black-dotted {
  border-bottom: 1px dotted #0a0a0a; }

.border-b-black-dashed {
  border-bottom: 1px dashed #0a0a0a; }

.border-b-black-solid {
  border-bottom: 1px solid #0a0a0a; }

.border-b-black-double {
  border-bottom: 1px double #0a0a0a; }

.border-b-black-groove {
  border-bottom: 1px groove #0a0a0a; }

.border-b-black-ridge {
  border-bottom: 1px ridge #0a0a0a; }

.border-b-black-inset {
  border-bottom: 1px inset #0a0a0a; }

.border-b-black-outset {
  border-bottom: 1px outset #0a0a0a; }

.border-b-black-none {
  border-bottom: 1px none #0a0a0a; }

.border-b-black-hidden {
  border-bottom: 1px hidden #0a0a0a; }

.border-l-black {
  border-left: 1px solid #0a0a0a; }

.border-l-black-dotted {
  border-left: 1px dotted #0a0a0a; }

.border-l-black-dashed {
  border-left: 1px dashed #0a0a0a; }

.border-l-black-solid {
  border-left: 1px solid #0a0a0a; }

.border-l-black-double {
  border-left: 1px double #0a0a0a; }

.border-l-black-groove {
  border-left: 1px groove #0a0a0a; }

.border-l-black-ridge {
  border-left: 1px ridge #0a0a0a; }

.border-l-black-inset {
  border-left: 1px inset #0a0a0a; }

.border-l-black-outset {
  border-left: 1px outset #0a0a0a; }

.border-l-black-none {
  border-left: 1px none #0a0a0a; }

.border-l-black-hidden {
  border-left: 1px hidden #0a0a0a; }

.has-text-black-bis {
  color: #121212; }

.has-background-black-bis {
  background-color: #121212; }

.border-black-bis {
  border: 1px solid #121212; }

.border-black-bis-dotted {
  border: 1px dotted #121212; }

.border-black-bis-dashed {
  border: 1px dashed #121212; }

.border-black-bis-solid {
  border: 1px solid #121212; }

.border-black-bis-double {
  border: 1px double #121212; }

.border-black-bis-groove {
  border: 1px groove #121212; }

.border-black-bis-ridge {
  border: 1px ridge #121212; }

.border-black-bis-inset {
  border: 1px inset #121212; }

.border-black-bis-outset {
  border: 1px outset #121212; }

.border-black-bis-none {
  border: 1px none #121212; }

.border-black-bis-hidden {
  border: 1px hidden #121212; }

.border-t-black-bis {
  border-top: 1px solid #121212; }

.border-t-black-bis-dotted {
  border-top: 1px dotted #121212; }

.border-t-black-bis-dashed {
  border-top: 1px dashed #121212; }

.border-t-black-bis-solid {
  border-top: 1px solid #121212; }

.border-t-black-bis-double {
  border-top: 1px double #121212; }

.border-t-black-bis-groove {
  border-top: 1px groove #121212; }

.border-t-black-bis-ridge {
  border-top: 1px ridge #121212; }

.border-t-black-bis-inset {
  border-top: 1px inset #121212; }

.border-t-black-bis-outset {
  border-top: 1px outset #121212; }

.border-t-black-bis-none {
  border-top: 1px none #121212; }

.border-t-black-bis-hidden {
  border-top: 1px hidden #121212; }

.border-r-black-bis {
  border-right: 1px solid #121212; }

.border-r-black-bis-dotted {
  border-right: 1px dotted #121212; }

.border-r-black-bis-dashed {
  border-right: 1px dashed #121212; }

.border-r-black-bis-solid {
  border-right: 1px solid #121212; }

.border-r-black-bis-double {
  border-right: 1px double #121212; }

.border-r-black-bis-groove {
  border-right: 1px groove #121212; }

.border-r-black-bis-ridge {
  border-right: 1px ridge #121212; }

.border-r-black-bis-inset {
  border-right: 1px inset #121212; }

.border-r-black-bis-outset {
  border-right: 1px outset #121212; }

.border-r-black-bis-none {
  border-right: 1px none #121212; }

.border-r-black-bis-hidden {
  border-right: 1px hidden #121212; }

.border-b-black-bis {
  border-bottom: 1px solid #121212; }

.border-b-black-bis-dotted {
  border-bottom: 1px dotted #121212; }

.border-b-black-bis-dashed {
  border-bottom: 1px dashed #121212; }

.border-b-black-bis-solid {
  border-bottom: 1px solid #121212; }

.border-b-black-bis-double {
  border-bottom: 1px double #121212; }

.border-b-black-bis-groove {
  border-bottom: 1px groove #121212; }

.border-b-black-bis-ridge {
  border-bottom: 1px ridge #121212; }

.border-b-black-bis-inset {
  border-bottom: 1px inset #121212; }

.border-b-black-bis-outset {
  border-bottom: 1px outset #121212; }

.border-b-black-bis-none {
  border-bottom: 1px none #121212; }

.border-b-black-bis-hidden {
  border-bottom: 1px hidden #121212; }

.border-l-black-bis {
  border-left: 1px solid #121212; }

.border-l-black-bis-dotted {
  border-left: 1px dotted #121212; }

.border-l-black-bis-dashed {
  border-left: 1px dashed #121212; }

.border-l-black-bis-solid {
  border-left: 1px solid #121212; }

.border-l-black-bis-double {
  border-left: 1px double #121212; }

.border-l-black-bis-groove {
  border-left: 1px groove #121212; }

.border-l-black-bis-ridge {
  border-left: 1px ridge #121212; }

.border-l-black-bis-inset {
  border-left: 1px inset #121212; }

.border-l-black-bis-outset {
  border-left: 1px outset #121212; }

.border-l-black-bis-none {
  border-left: 1px none #121212; }

.border-l-black-bis-hidden {
  border-left: 1px hidden #121212; }

.has-text-black-ter {
  color: #242424; }

.has-background-black-ter {
  background-color: #242424; }

.border-black-ter {
  border: 1px solid #242424; }

.border-black-ter-dotted {
  border: 1px dotted #242424; }

.border-black-ter-dashed {
  border: 1px dashed #242424; }

.border-black-ter-solid {
  border: 1px solid #242424; }

.border-black-ter-double {
  border: 1px double #242424; }

.border-black-ter-groove {
  border: 1px groove #242424; }

.border-black-ter-ridge {
  border: 1px ridge #242424; }

.border-black-ter-inset {
  border: 1px inset #242424; }

.border-black-ter-outset {
  border: 1px outset #242424; }

.border-black-ter-none {
  border: 1px none #242424; }

.border-black-ter-hidden {
  border: 1px hidden #242424; }

.border-t-black-ter {
  border-top: 1px solid #242424; }

.border-t-black-ter-dotted {
  border-top: 1px dotted #242424; }

.border-t-black-ter-dashed {
  border-top: 1px dashed #242424; }

.border-t-black-ter-solid {
  border-top: 1px solid #242424; }

.border-t-black-ter-double {
  border-top: 1px double #242424; }

.border-t-black-ter-groove {
  border-top: 1px groove #242424; }

.border-t-black-ter-ridge {
  border-top: 1px ridge #242424; }

.border-t-black-ter-inset {
  border-top: 1px inset #242424; }

.border-t-black-ter-outset {
  border-top: 1px outset #242424; }

.border-t-black-ter-none {
  border-top: 1px none #242424; }

.border-t-black-ter-hidden {
  border-top: 1px hidden #242424; }

.border-r-black-ter {
  border-right: 1px solid #242424; }

.border-r-black-ter-dotted {
  border-right: 1px dotted #242424; }

.border-r-black-ter-dashed {
  border-right: 1px dashed #242424; }

.border-r-black-ter-solid {
  border-right: 1px solid #242424; }

.border-r-black-ter-double {
  border-right: 1px double #242424; }

.border-r-black-ter-groove {
  border-right: 1px groove #242424; }

.border-r-black-ter-ridge {
  border-right: 1px ridge #242424; }

.border-r-black-ter-inset {
  border-right: 1px inset #242424; }

.border-r-black-ter-outset {
  border-right: 1px outset #242424; }

.border-r-black-ter-none {
  border-right: 1px none #242424; }

.border-r-black-ter-hidden {
  border-right: 1px hidden #242424; }

.border-b-black-ter {
  border-bottom: 1px solid #242424; }

.border-b-black-ter-dotted {
  border-bottom: 1px dotted #242424; }

.border-b-black-ter-dashed {
  border-bottom: 1px dashed #242424; }

.border-b-black-ter-solid {
  border-bottom: 1px solid #242424; }

.border-b-black-ter-double {
  border-bottom: 1px double #242424; }

.border-b-black-ter-groove {
  border-bottom: 1px groove #242424; }

.border-b-black-ter-ridge {
  border-bottom: 1px ridge #242424; }

.border-b-black-ter-inset {
  border-bottom: 1px inset #242424; }

.border-b-black-ter-outset {
  border-bottom: 1px outset #242424; }

.border-b-black-ter-none {
  border-bottom: 1px none #242424; }

.border-b-black-ter-hidden {
  border-bottom: 1px hidden #242424; }

.border-l-black-ter {
  border-left: 1px solid #242424; }

.border-l-black-ter-dotted {
  border-left: 1px dotted #242424; }

.border-l-black-ter-dashed {
  border-left: 1px dashed #242424; }

.border-l-black-ter-solid {
  border-left: 1px solid #242424; }

.border-l-black-ter-double {
  border-left: 1px double #242424; }

.border-l-black-ter-groove {
  border-left: 1px groove #242424; }

.border-l-black-ter-ridge {
  border-left: 1px ridge #242424; }

.border-l-black-ter-inset {
  border-left: 1px inset #242424; }

.border-l-black-ter-outset {
  border-left: 1px outset #242424; }

.border-l-black-ter-none {
  border-left: 1px none #242424; }

.border-l-black-ter-hidden {
  border-left: 1px hidden #242424; }

.has-text-grey-darker {
  color: #363636; }

.has-background-grey-darker {
  background-color: #363636; }

.border-grey-darker {
  border: 1px solid #363636; }

.border-grey-darker-dotted {
  border: 1px dotted #363636; }

.border-grey-darker-dashed {
  border: 1px dashed #363636; }

.border-grey-darker-solid {
  border: 1px solid #363636; }

.border-grey-darker-double {
  border: 1px double #363636; }

.border-grey-darker-groove {
  border: 1px groove #363636; }

.border-grey-darker-ridge {
  border: 1px ridge #363636; }

.border-grey-darker-inset {
  border: 1px inset #363636; }

.border-grey-darker-outset {
  border: 1px outset #363636; }

.border-grey-darker-none {
  border: 1px none #363636; }

.border-grey-darker-hidden {
  border: 1px hidden #363636; }

.border-t-grey-darker {
  border-top: 1px solid #363636; }

.border-t-grey-darker-dotted {
  border-top: 1px dotted #363636; }

.border-t-grey-darker-dashed {
  border-top: 1px dashed #363636; }

.border-t-grey-darker-solid {
  border-top: 1px solid #363636; }

.border-t-grey-darker-double {
  border-top: 1px double #363636; }

.border-t-grey-darker-groove {
  border-top: 1px groove #363636; }

.border-t-grey-darker-ridge {
  border-top: 1px ridge #363636; }

.border-t-grey-darker-inset {
  border-top: 1px inset #363636; }

.border-t-grey-darker-outset {
  border-top: 1px outset #363636; }

.border-t-grey-darker-none {
  border-top: 1px none #363636; }

.border-t-grey-darker-hidden {
  border-top: 1px hidden #363636; }

.border-r-grey-darker {
  border-right: 1px solid #363636; }

.border-r-grey-darker-dotted {
  border-right: 1px dotted #363636; }

.border-r-grey-darker-dashed {
  border-right: 1px dashed #363636; }

.border-r-grey-darker-solid {
  border-right: 1px solid #363636; }

.border-r-grey-darker-double {
  border-right: 1px double #363636; }

.border-r-grey-darker-groove {
  border-right: 1px groove #363636; }

.border-r-grey-darker-ridge {
  border-right: 1px ridge #363636; }

.border-r-grey-darker-inset {
  border-right: 1px inset #363636; }

.border-r-grey-darker-outset {
  border-right: 1px outset #363636; }

.border-r-grey-darker-none {
  border-right: 1px none #363636; }

.border-r-grey-darker-hidden {
  border-right: 1px hidden #363636; }

.border-b-grey-darker {
  border-bottom: 1px solid #363636; }

.border-b-grey-darker-dotted {
  border-bottom: 1px dotted #363636; }

.border-b-grey-darker-dashed {
  border-bottom: 1px dashed #363636; }

.border-b-grey-darker-solid {
  border-bottom: 1px solid #363636; }

.border-b-grey-darker-double {
  border-bottom: 1px double #363636; }

.border-b-grey-darker-groove {
  border-bottom: 1px groove #363636; }

.border-b-grey-darker-ridge {
  border-bottom: 1px ridge #363636; }

.border-b-grey-darker-inset {
  border-bottom: 1px inset #363636; }

.border-b-grey-darker-outset {
  border-bottom: 1px outset #363636; }

.border-b-grey-darker-none {
  border-bottom: 1px none #363636; }

.border-b-grey-darker-hidden {
  border-bottom: 1px hidden #363636; }

.border-l-grey-darker {
  border-left: 1px solid #363636; }

.border-l-grey-darker-dotted {
  border-left: 1px dotted #363636; }

.border-l-grey-darker-dashed {
  border-left: 1px dashed #363636; }

.border-l-grey-darker-solid {
  border-left: 1px solid #363636; }

.border-l-grey-darker-double {
  border-left: 1px double #363636; }

.border-l-grey-darker-groove {
  border-left: 1px groove #363636; }

.border-l-grey-darker-ridge {
  border-left: 1px ridge #363636; }

.border-l-grey-darker-inset {
  border-left: 1px inset #363636; }

.border-l-grey-darker-outset {
  border-left: 1px outset #363636; }

.border-l-grey-darker-none {
  border-left: 1px none #363636; }

.border-l-grey-darker-hidden {
  border-left: 1px hidden #363636; }

.has-text-grey-dark {
  color: #4a4a4a; }

.has-background-grey-dark {
  background-color: #4a4a4a; }

.border-grey-dark {
  border: 1px solid #4a4a4a; }

.border-grey-dark-dotted {
  border: 1px dotted #4a4a4a; }

.border-grey-dark-dashed {
  border: 1px dashed #4a4a4a; }

.border-grey-dark-solid {
  border: 1px solid #4a4a4a; }

.border-grey-dark-double {
  border: 1px double #4a4a4a; }

.border-grey-dark-groove {
  border: 1px groove #4a4a4a; }

.border-grey-dark-ridge {
  border: 1px ridge #4a4a4a; }

.border-grey-dark-inset {
  border: 1px inset #4a4a4a; }

.border-grey-dark-outset {
  border: 1px outset #4a4a4a; }

.border-grey-dark-none {
  border: 1px none #4a4a4a; }

.border-grey-dark-hidden {
  border: 1px hidden #4a4a4a; }

.border-t-grey-dark {
  border-top: 1px solid #4a4a4a; }

.border-t-grey-dark-dotted {
  border-top: 1px dotted #4a4a4a; }

.border-t-grey-dark-dashed {
  border-top: 1px dashed #4a4a4a; }

.border-t-grey-dark-solid {
  border-top: 1px solid #4a4a4a; }

.border-t-grey-dark-double {
  border-top: 1px double #4a4a4a; }

.border-t-grey-dark-groove {
  border-top: 1px groove #4a4a4a; }

.border-t-grey-dark-ridge {
  border-top: 1px ridge #4a4a4a; }

.border-t-grey-dark-inset {
  border-top: 1px inset #4a4a4a; }

.border-t-grey-dark-outset {
  border-top: 1px outset #4a4a4a; }

.border-t-grey-dark-none {
  border-top: 1px none #4a4a4a; }

.border-t-grey-dark-hidden {
  border-top: 1px hidden #4a4a4a; }

.border-r-grey-dark {
  border-right: 1px solid #4a4a4a; }

.border-r-grey-dark-dotted {
  border-right: 1px dotted #4a4a4a; }

.border-r-grey-dark-dashed {
  border-right: 1px dashed #4a4a4a; }

.border-r-grey-dark-solid {
  border-right: 1px solid #4a4a4a; }

.border-r-grey-dark-double {
  border-right: 1px double #4a4a4a; }

.border-r-grey-dark-groove {
  border-right: 1px groove #4a4a4a; }

.border-r-grey-dark-ridge {
  border-right: 1px ridge #4a4a4a; }

.border-r-grey-dark-inset {
  border-right: 1px inset #4a4a4a; }

.border-r-grey-dark-outset {
  border-right: 1px outset #4a4a4a; }

.border-r-grey-dark-none {
  border-right: 1px none #4a4a4a; }

.border-r-grey-dark-hidden {
  border-right: 1px hidden #4a4a4a; }

.border-b-grey-dark {
  border-bottom: 1px solid #4a4a4a; }

.border-b-grey-dark-dotted {
  border-bottom: 1px dotted #4a4a4a; }

.border-b-grey-dark-dashed {
  border-bottom: 1px dashed #4a4a4a; }

.border-b-grey-dark-solid {
  border-bottom: 1px solid #4a4a4a; }

.border-b-grey-dark-double {
  border-bottom: 1px double #4a4a4a; }

.border-b-grey-dark-groove {
  border-bottom: 1px groove #4a4a4a; }

.border-b-grey-dark-ridge {
  border-bottom: 1px ridge #4a4a4a; }

.border-b-grey-dark-inset {
  border-bottom: 1px inset #4a4a4a; }

.border-b-grey-dark-outset {
  border-bottom: 1px outset #4a4a4a; }

.border-b-grey-dark-none {
  border-bottom: 1px none #4a4a4a; }

.border-b-grey-dark-hidden {
  border-bottom: 1px hidden #4a4a4a; }

.border-l-grey-dark {
  border-left: 1px solid #4a4a4a; }

.border-l-grey-dark-dotted {
  border-left: 1px dotted #4a4a4a; }

.border-l-grey-dark-dashed {
  border-left: 1px dashed #4a4a4a; }

.border-l-grey-dark-solid {
  border-left: 1px solid #4a4a4a; }

.border-l-grey-dark-double {
  border-left: 1px double #4a4a4a; }

.border-l-grey-dark-groove {
  border-left: 1px groove #4a4a4a; }

.border-l-grey-dark-ridge {
  border-left: 1px ridge #4a4a4a; }

.border-l-grey-dark-inset {
  border-left: 1px inset #4a4a4a; }

.border-l-grey-dark-outset {
  border-left: 1px outset #4a4a4a; }

.border-l-grey-dark-none {
  border-left: 1px none #4a4a4a; }

.border-l-grey-dark-hidden {
  border-left: 1px hidden #4a4a4a; }

.has-text-grey {
  color: #7a7a7a; }

.has-background-grey {
  background-color: #7a7a7a; }

.border-grey {
  border: 1px solid #7a7a7a; }

.border-grey-dotted {
  border: 1px dotted #7a7a7a; }

.border-grey-dashed {
  border: 1px dashed #7a7a7a; }

.border-grey-solid {
  border: 1px solid #7a7a7a; }

.border-grey-double {
  border: 1px double #7a7a7a; }

.border-grey-groove {
  border: 1px groove #7a7a7a; }

.border-grey-ridge {
  border: 1px ridge #7a7a7a; }

.border-grey-inset {
  border: 1px inset #7a7a7a; }

.border-grey-outset {
  border: 1px outset #7a7a7a; }

.border-grey-none {
  border: 1px none #7a7a7a; }

.border-grey-hidden {
  border: 1px hidden #7a7a7a; }

.border-t-grey {
  border-top: 1px solid #7a7a7a; }

.border-t-grey-dotted {
  border-top: 1px dotted #7a7a7a; }

.border-t-grey-dashed {
  border-top: 1px dashed #7a7a7a; }

.border-t-grey-solid {
  border-top: 1px solid #7a7a7a; }

.border-t-grey-double {
  border-top: 1px double #7a7a7a; }

.border-t-grey-groove {
  border-top: 1px groove #7a7a7a; }

.border-t-grey-ridge {
  border-top: 1px ridge #7a7a7a; }

.border-t-grey-inset {
  border-top: 1px inset #7a7a7a; }

.border-t-grey-outset {
  border-top: 1px outset #7a7a7a; }

.border-t-grey-none {
  border-top: 1px none #7a7a7a; }

.border-t-grey-hidden {
  border-top: 1px hidden #7a7a7a; }

.border-r-grey {
  border-right: 1px solid #7a7a7a; }

.border-r-grey-dotted {
  border-right: 1px dotted #7a7a7a; }

.border-r-grey-dashed {
  border-right: 1px dashed #7a7a7a; }

.border-r-grey-solid {
  border-right: 1px solid #7a7a7a; }

.border-r-grey-double {
  border-right: 1px double #7a7a7a; }

.border-r-grey-groove {
  border-right: 1px groove #7a7a7a; }

.border-r-grey-ridge {
  border-right: 1px ridge #7a7a7a; }

.border-r-grey-inset {
  border-right: 1px inset #7a7a7a; }

.border-r-grey-outset {
  border-right: 1px outset #7a7a7a; }

.border-r-grey-none {
  border-right: 1px none #7a7a7a; }

.border-r-grey-hidden {
  border-right: 1px hidden #7a7a7a; }

.border-b-grey {
  border-bottom: 1px solid #7a7a7a; }

.border-b-grey-dotted {
  border-bottom: 1px dotted #7a7a7a; }

.border-b-grey-dashed {
  border-bottom: 1px dashed #7a7a7a; }

.border-b-grey-solid {
  border-bottom: 1px solid #7a7a7a; }

.border-b-grey-double {
  border-bottom: 1px double #7a7a7a; }

.border-b-grey-groove {
  border-bottom: 1px groove #7a7a7a; }

.border-b-grey-ridge {
  border-bottom: 1px ridge #7a7a7a; }

.border-b-grey-inset {
  border-bottom: 1px inset #7a7a7a; }

.border-b-grey-outset {
  border-bottom: 1px outset #7a7a7a; }

.border-b-grey-none {
  border-bottom: 1px none #7a7a7a; }

.border-b-grey-hidden {
  border-bottom: 1px hidden #7a7a7a; }

.border-l-grey {
  border-left: 1px solid #7a7a7a; }

.border-l-grey-dotted {
  border-left: 1px dotted #7a7a7a; }

.border-l-grey-dashed {
  border-left: 1px dashed #7a7a7a; }

.border-l-grey-solid {
  border-left: 1px solid #7a7a7a; }

.border-l-grey-double {
  border-left: 1px double #7a7a7a; }

.border-l-grey-groove {
  border-left: 1px groove #7a7a7a; }

.border-l-grey-ridge {
  border-left: 1px ridge #7a7a7a; }

.border-l-grey-inset {
  border-left: 1px inset #7a7a7a; }

.border-l-grey-outset {
  border-left: 1px outset #7a7a7a; }

.border-l-grey-none {
  border-left: 1px none #7a7a7a; }

.border-l-grey-hidden {
  border-left: 1px hidden #7a7a7a; }

.has-text-grey-light {
  color: #b5b5b5; }

.has-background-grey-light {
  background-color: #b5b5b5; }

.border-grey-light {
  border: 1px solid #b5b5b5; }

.border-grey-light-dotted {
  border: 1px dotted #b5b5b5; }

.border-grey-light-dashed {
  border: 1px dashed #b5b5b5; }

.border-grey-light-solid {
  border: 1px solid #b5b5b5; }

.border-grey-light-double {
  border: 1px double #b5b5b5; }

.border-grey-light-groove {
  border: 1px groove #b5b5b5; }

.border-grey-light-ridge {
  border: 1px ridge #b5b5b5; }

.border-grey-light-inset {
  border: 1px inset #b5b5b5; }

.border-grey-light-outset {
  border: 1px outset #b5b5b5; }

.border-grey-light-none {
  border: 1px none #b5b5b5; }

.border-grey-light-hidden {
  border: 1px hidden #b5b5b5; }

.border-t-grey-light {
  border-top: 1px solid #b5b5b5; }

.border-t-grey-light-dotted {
  border-top: 1px dotted #b5b5b5; }

.border-t-grey-light-dashed {
  border-top: 1px dashed #b5b5b5; }

.border-t-grey-light-solid {
  border-top: 1px solid #b5b5b5; }

.border-t-grey-light-double {
  border-top: 1px double #b5b5b5; }

.border-t-grey-light-groove {
  border-top: 1px groove #b5b5b5; }

.border-t-grey-light-ridge {
  border-top: 1px ridge #b5b5b5; }

.border-t-grey-light-inset {
  border-top: 1px inset #b5b5b5; }

.border-t-grey-light-outset {
  border-top: 1px outset #b5b5b5; }

.border-t-grey-light-none {
  border-top: 1px none #b5b5b5; }

.border-t-grey-light-hidden {
  border-top: 1px hidden #b5b5b5; }

.border-r-grey-light {
  border-right: 1px solid #b5b5b5; }

.border-r-grey-light-dotted {
  border-right: 1px dotted #b5b5b5; }

.border-r-grey-light-dashed {
  border-right: 1px dashed #b5b5b5; }

.border-r-grey-light-solid {
  border-right: 1px solid #b5b5b5; }

.border-r-grey-light-double {
  border-right: 1px double #b5b5b5; }

.border-r-grey-light-groove {
  border-right: 1px groove #b5b5b5; }

.border-r-grey-light-ridge {
  border-right: 1px ridge #b5b5b5; }

.border-r-grey-light-inset {
  border-right: 1px inset #b5b5b5; }

.border-r-grey-light-outset {
  border-right: 1px outset #b5b5b5; }

.border-r-grey-light-none {
  border-right: 1px none #b5b5b5; }

.border-r-grey-light-hidden {
  border-right: 1px hidden #b5b5b5; }

.border-b-grey-light {
  border-bottom: 1px solid #b5b5b5; }

.border-b-grey-light-dotted {
  border-bottom: 1px dotted #b5b5b5; }

.border-b-grey-light-dashed {
  border-bottom: 1px dashed #b5b5b5; }

.border-b-grey-light-solid {
  border-bottom: 1px solid #b5b5b5; }

.border-b-grey-light-double {
  border-bottom: 1px double #b5b5b5; }

.border-b-grey-light-groove {
  border-bottom: 1px groove #b5b5b5; }

.border-b-grey-light-ridge {
  border-bottom: 1px ridge #b5b5b5; }

.border-b-grey-light-inset {
  border-bottom: 1px inset #b5b5b5; }

.border-b-grey-light-outset {
  border-bottom: 1px outset #b5b5b5; }

.border-b-grey-light-none {
  border-bottom: 1px none #b5b5b5; }

.border-b-grey-light-hidden {
  border-bottom: 1px hidden #b5b5b5; }

.border-l-grey-light {
  border-left: 1px solid #b5b5b5; }

.border-l-grey-light-dotted {
  border-left: 1px dotted #b5b5b5; }

.border-l-grey-light-dashed {
  border-left: 1px dashed #b5b5b5; }

.border-l-grey-light-solid {
  border-left: 1px solid #b5b5b5; }

.border-l-grey-light-double {
  border-left: 1px double #b5b5b5; }

.border-l-grey-light-groove {
  border-left: 1px groove #b5b5b5; }

.border-l-grey-light-ridge {
  border-left: 1px ridge #b5b5b5; }

.border-l-grey-light-inset {
  border-left: 1px inset #b5b5b5; }

.border-l-grey-light-outset {
  border-left: 1px outset #b5b5b5; }

.border-l-grey-light-none {
  border-left: 1px none #b5b5b5; }

.border-l-grey-light-hidden {
  border-left: 1px hidden #b5b5b5; }

.has-text-grey-lighter {
  color: #e6e6e6; }

.has-background-grey-lighter {
  background-color: #e6e6e6; }

.border-grey-lighter {
  border: 1px solid #e6e6e6; }

.border-grey-lighter-dotted {
  border: 1px dotted #e6e6e6; }

.border-grey-lighter-dashed {
  border: 1px dashed #e6e6e6; }

.border-grey-lighter-solid {
  border: 1px solid #e6e6e6; }

.border-grey-lighter-double {
  border: 1px double #e6e6e6; }

.border-grey-lighter-groove {
  border: 1px groove #e6e6e6; }

.border-grey-lighter-ridge {
  border: 1px ridge #e6e6e6; }

.border-grey-lighter-inset {
  border: 1px inset #e6e6e6; }

.border-grey-lighter-outset {
  border: 1px outset #e6e6e6; }

.border-grey-lighter-none {
  border: 1px none #e6e6e6; }

.border-grey-lighter-hidden {
  border: 1px hidden #e6e6e6; }

.border-t-grey-lighter {
  border-top: 1px solid #e6e6e6; }

.border-t-grey-lighter-dotted {
  border-top: 1px dotted #e6e6e6; }

.border-t-grey-lighter-dashed {
  border-top: 1px dashed #e6e6e6; }

.border-t-grey-lighter-solid {
  border-top: 1px solid #e6e6e6; }

.border-t-grey-lighter-double {
  border-top: 1px double #e6e6e6; }

.border-t-grey-lighter-groove {
  border-top: 1px groove #e6e6e6; }

.border-t-grey-lighter-ridge {
  border-top: 1px ridge #e6e6e6; }

.border-t-grey-lighter-inset {
  border-top: 1px inset #e6e6e6; }

.border-t-grey-lighter-outset {
  border-top: 1px outset #e6e6e6; }

.border-t-grey-lighter-none {
  border-top: 1px none #e6e6e6; }

.border-t-grey-lighter-hidden {
  border-top: 1px hidden #e6e6e6; }

.border-r-grey-lighter {
  border-right: 1px solid #e6e6e6; }

.border-r-grey-lighter-dotted {
  border-right: 1px dotted #e6e6e6; }

.border-r-grey-lighter-dashed {
  border-right: 1px dashed #e6e6e6; }

.border-r-grey-lighter-solid {
  border-right: 1px solid #e6e6e6; }

.border-r-grey-lighter-double {
  border-right: 1px double #e6e6e6; }

.border-r-grey-lighter-groove {
  border-right: 1px groove #e6e6e6; }

.border-r-grey-lighter-ridge {
  border-right: 1px ridge #e6e6e6; }

.border-r-grey-lighter-inset {
  border-right: 1px inset #e6e6e6; }

.border-r-grey-lighter-outset {
  border-right: 1px outset #e6e6e6; }

.border-r-grey-lighter-none {
  border-right: 1px none #e6e6e6; }

.border-r-grey-lighter-hidden {
  border-right: 1px hidden #e6e6e6; }

.border-b-grey-lighter {
  border-bottom: 1px solid #e6e6e6; }

.border-b-grey-lighter-dotted {
  border-bottom: 1px dotted #e6e6e6; }

.border-b-grey-lighter-dashed {
  border-bottom: 1px dashed #e6e6e6; }

.border-b-grey-lighter-solid {
  border-bottom: 1px solid #e6e6e6; }

.border-b-grey-lighter-double {
  border-bottom: 1px double #e6e6e6; }

.border-b-grey-lighter-groove {
  border-bottom: 1px groove #e6e6e6; }

.border-b-grey-lighter-ridge {
  border-bottom: 1px ridge #e6e6e6; }

.border-b-grey-lighter-inset {
  border-bottom: 1px inset #e6e6e6; }

.border-b-grey-lighter-outset {
  border-bottom: 1px outset #e6e6e6; }

.border-b-grey-lighter-none {
  border-bottom: 1px none #e6e6e6; }

.border-b-grey-lighter-hidden {
  border-bottom: 1px hidden #e6e6e6; }

.border-l-grey-lighter {
  border-left: 1px solid #e6e6e6; }

.border-l-grey-lighter-dotted {
  border-left: 1px dotted #e6e6e6; }

.border-l-grey-lighter-dashed {
  border-left: 1px dashed #e6e6e6; }

.border-l-grey-lighter-solid {
  border-left: 1px solid #e6e6e6; }

.border-l-grey-lighter-double {
  border-left: 1px double #e6e6e6; }

.border-l-grey-lighter-groove {
  border-left: 1px groove #e6e6e6; }

.border-l-grey-lighter-ridge {
  border-left: 1px ridge #e6e6e6; }

.border-l-grey-lighter-inset {
  border-left: 1px inset #e6e6e6; }

.border-l-grey-lighter-outset {
  border-left: 1px outset #e6e6e6; }

.border-l-grey-lighter-none {
  border-left: 1px none #e6e6e6; }

.border-l-grey-lighter-hidden {
  border-left: 1px hidden #e6e6e6; }

.has-text-white-ter {
  color: whitesmoke; }

.has-background-white-ter {
  background-color: whitesmoke; }

.border-white-ter {
  border: 1px solid whitesmoke; }

.border-white-ter-dotted {
  border: 1px dotted whitesmoke; }

.border-white-ter-dashed {
  border: 1px dashed whitesmoke; }

.border-white-ter-solid {
  border: 1px solid whitesmoke; }

.border-white-ter-double {
  border: 1px double whitesmoke; }

.border-white-ter-groove {
  border: 1px groove whitesmoke; }

.border-white-ter-ridge {
  border: 1px ridge whitesmoke; }

.border-white-ter-inset {
  border: 1px inset whitesmoke; }

.border-white-ter-outset {
  border: 1px outset whitesmoke; }

.border-white-ter-none {
  border: 1px none whitesmoke; }

.border-white-ter-hidden {
  border: 1px hidden whitesmoke; }

.border-t-white-ter {
  border-top: 1px solid whitesmoke; }

.border-t-white-ter-dotted {
  border-top: 1px dotted whitesmoke; }

.border-t-white-ter-dashed {
  border-top: 1px dashed whitesmoke; }

.border-t-white-ter-solid {
  border-top: 1px solid whitesmoke; }

.border-t-white-ter-double {
  border-top: 1px double whitesmoke; }

.border-t-white-ter-groove {
  border-top: 1px groove whitesmoke; }

.border-t-white-ter-ridge {
  border-top: 1px ridge whitesmoke; }

.border-t-white-ter-inset {
  border-top: 1px inset whitesmoke; }

.border-t-white-ter-outset {
  border-top: 1px outset whitesmoke; }

.border-t-white-ter-none {
  border-top: 1px none whitesmoke; }

.border-t-white-ter-hidden {
  border-top: 1px hidden whitesmoke; }

.border-r-white-ter {
  border-right: 1px solid whitesmoke; }

.border-r-white-ter-dotted {
  border-right: 1px dotted whitesmoke; }

.border-r-white-ter-dashed {
  border-right: 1px dashed whitesmoke; }

.border-r-white-ter-solid {
  border-right: 1px solid whitesmoke; }

.border-r-white-ter-double {
  border-right: 1px double whitesmoke; }

.border-r-white-ter-groove {
  border-right: 1px groove whitesmoke; }

.border-r-white-ter-ridge {
  border-right: 1px ridge whitesmoke; }

.border-r-white-ter-inset {
  border-right: 1px inset whitesmoke; }

.border-r-white-ter-outset {
  border-right: 1px outset whitesmoke; }

.border-r-white-ter-none {
  border-right: 1px none whitesmoke; }

.border-r-white-ter-hidden {
  border-right: 1px hidden whitesmoke; }

.border-b-white-ter {
  border-bottom: 1px solid whitesmoke; }

.border-b-white-ter-dotted {
  border-bottom: 1px dotted whitesmoke; }

.border-b-white-ter-dashed {
  border-bottom: 1px dashed whitesmoke; }

.border-b-white-ter-solid {
  border-bottom: 1px solid whitesmoke; }

.border-b-white-ter-double {
  border-bottom: 1px double whitesmoke; }

.border-b-white-ter-groove {
  border-bottom: 1px groove whitesmoke; }

.border-b-white-ter-ridge {
  border-bottom: 1px ridge whitesmoke; }

.border-b-white-ter-inset {
  border-bottom: 1px inset whitesmoke; }

.border-b-white-ter-outset {
  border-bottom: 1px outset whitesmoke; }

.border-b-white-ter-none {
  border-bottom: 1px none whitesmoke; }

.border-b-white-ter-hidden {
  border-bottom: 1px hidden whitesmoke; }

.border-l-white-ter {
  border-left: 1px solid whitesmoke; }

.border-l-white-ter-dotted {
  border-left: 1px dotted whitesmoke; }

.border-l-white-ter-dashed {
  border-left: 1px dashed whitesmoke; }

.border-l-white-ter-solid {
  border-left: 1px solid whitesmoke; }

.border-l-white-ter-double {
  border-left: 1px double whitesmoke; }

.border-l-white-ter-groove {
  border-left: 1px groove whitesmoke; }

.border-l-white-ter-ridge {
  border-left: 1px ridge whitesmoke; }

.border-l-white-ter-inset {
  border-left: 1px inset whitesmoke; }

.border-l-white-ter-outset {
  border-left: 1px outset whitesmoke; }

.border-l-white-ter-none {
  border-left: 1px none whitesmoke; }

.border-l-white-ter-hidden {
  border-left: 1px hidden whitesmoke; }

.has-text-white-bis {
  color: #fafafa; }

.has-background-white-bis {
  background-color: #fafafa; }

.border-white-bis {
  border: 1px solid #fafafa; }

.border-white-bis-dotted {
  border: 1px dotted #fafafa; }

.border-white-bis-dashed {
  border: 1px dashed #fafafa; }

.border-white-bis-solid {
  border: 1px solid #fafafa; }

.border-white-bis-double {
  border: 1px double #fafafa; }

.border-white-bis-groove {
  border: 1px groove #fafafa; }

.border-white-bis-ridge {
  border: 1px ridge #fafafa; }

.border-white-bis-inset {
  border: 1px inset #fafafa; }

.border-white-bis-outset {
  border: 1px outset #fafafa; }

.border-white-bis-none {
  border: 1px none #fafafa; }

.border-white-bis-hidden {
  border: 1px hidden #fafafa; }

.border-t-white-bis {
  border-top: 1px solid #fafafa; }

.border-t-white-bis-dotted {
  border-top: 1px dotted #fafafa; }

.border-t-white-bis-dashed {
  border-top: 1px dashed #fafafa; }

.border-t-white-bis-solid {
  border-top: 1px solid #fafafa; }

.border-t-white-bis-double {
  border-top: 1px double #fafafa; }

.border-t-white-bis-groove {
  border-top: 1px groove #fafafa; }

.border-t-white-bis-ridge {
  border-top: 1px ridge #fafafa; }

.border-t-white-bis-inset {
  border-top: 1px inset #fafafa; }

.border-t-white-bis-outset {
  border-top: 1px outset #fafafa; }

.border-t-white-bis-none {
  border-top: 1px none #fafafa; }

.border-t-white-bis-hidden {
  border-top: 1px hidden #fafafa; }

.border-r-white-bis {
  border-right: 1px solid #fafafa; }

.border-r-white-bis-dotted {
  border-right: 1px dotted #fafafa; }

.border-r-white-bis-dashed {
  border-right: 1px dashed #fafafa; }

.border-r-white-bis-solid {
  border-right: 1px solid #fafafa; }

.border-r-white-bis-double {
  border-right: 1px double #fafafa; }

.border-r-white-bis-groove {
  border-right: 1px groove #fafafa; }

.border-r-white-bis-ridge {
  border-right: 1px ridge #fafafa; }

.border-r-white-bis-inset {
  border-right: 1px inset #fafafa; }

.border-r-white-bis-outset {
  border-right: 1px outset #fafafa; }

.border-r-white-bis-none {
  border-right: 1px none #fafafa; }

.border-r-white-bis-hidden {
  border-right: 1px hidden #fafafa; }

.border-b-white-bis {
  border-bottom: 1px solid #fafafa; }

.border-b-white-bis-dotted {
  border-bottom: 1px dotted #fafafa; }

.border-b-white-bis-dashed {
  border-bottom: 1px dashed #fafafa; }

.border-b-white-bis-solid {
  border-bottom: 1px solid #fafafa; }

.border-b-white-bis-double {
  border-bottom: 1px double #fafafa; }

.border-b-white-bis-groove {
  border-bottom: 1px groove #fafafa; }

.border-b-white-bis-ridge {
  border-bottom: 1px ridge #fafafa; }

.border-b-white-bis-inset {
  border-bottom: 1px inset #fafafa; }

.border-b-white-bis-outset {
  border-bottom: 1px outset #fafafa; }

.border-b-white-bis-none {
  border-bottom: 1px none #fafafa; }

.border-b-white-bis-hidden {
  border-bottom: 1px hidden #fafafa; }

.border-l-white-bis {
  border-left: 1px solid #fafafa; }

.border-l-white-bis-dotted {
  border-left: 1px dotted #fafafa; }

.border-l-white-bis-dashed {
  border-left: 1px dashed #fafafa; }

.border-l-white-bis-solid {
  border-left: 1px solid #fafafa; }

.border-l-white-bis-double {
  border-left: 1px double #fafafa; }

.border-l-white-bis-groove {
  border-left: 1px groove #fafafa; }

.border-l-white-bis-ridge {
  border-left: 1px ridge #fafafa; }

.border-l-white-bis-inset {
  border-left: 1px inset #fafafa; }

.border-l-white-bis-outset {
  border-left: 1px outset #fafafa; }

.border-l-white-bis-none {
  border-left: 1px none #fafafa; }

.border-l-white-bis-hidden {
  border-left: 1px hidden #fafafa; }

.has-text-white {
  color: white; }

.has-background-white {
  background-color: white; }

.border-white {
  border: 1px solid white; }

.border-white-dotted {
  border: 1px dotted white; }

.border-white-dashed {
  border: 1px dashed white; }

.border-white-solid {
  border: 1px solid white; }

.border-white-double {
  border: 1px double white; }

.border-white-groove {
  border: 1px groove white; }

.border-white-ridge {
  border: 1px ridge white; }

.border-white-inset {
  border: 1px inset white; }

.border-white-outset {
  border: 1px outset white; }

.border-white-none {
  border: 1px none white; }

.border-white-hidden {
  border: 1px hidden white; }

.border-t-white {
  border-top: 1px solid white; }

.border-t-white-dotted {
  border-top: 1px dotted white; }

.border-t-white-dashed {
  border-top: 1px dashed white; }

.border-t-white-solid {
  border-top: 1px solid white; }

.border-t-white-double {
  border-top: 1px double white; }

.border-t-white-groove {
  border-top: 1px groove white; }

.border-t-white-ridge {
  border-top: 1px ridge white; }

.border-t-white-inset {
  border-top: 1px inset white; }

.border-t-white-outset {
  border-top: 1px outset white; }

.border-t-white-none {
  border-top: 1px none white; }

.border-t-white-hidden {
  border-top: 1px hidden white; }

.border-r-white {
  border-right: 1px solid white; }

.border-r-white-dotted {
  border-right: 1px dotted white; }

.border-r-white-dashed {
  border-right: 1px dashed white; }

.border-r-white-solid {
  border-right: 1px solid white; }

.border-r-white-double {
  border-right: 1px double white; }

.border-r-white-groove {
  border-right: 1px groove white; }

.border-r-white-ridge {
  border-right: 1px ridge white; }

.border-r-white-inset {
  border-right: 1px inset white; }

.border-r-white-outset {
  border-right: 1px outset white; }

.border-r-white-none {
  border-right: 1px none white; }

.border-r-white-hidden {
  border-right: 1px hidden white; }

.border-b-white {
  border-bottom: 1px solid white; }

.border-b-white-dotted {
  border-bottom: 1px dotted white; }

.border-b-white-dashed {
  border-bottom: 1px dashed white; }

.border-b-white-solid {
  border-bottom: 1px solid white; }

.border-b-white-double {
  border-bottom: 1px double white; }

.border-b-white-groove {
  border-bottom: 1px groove white; }

.border-b-white-ridge {
  border-bottom: 1px ridge white; }

.border-b-white-inset {
  border-bottom: 1px inset white; }

.border-b-white-outset {
  border-bottom: 1px outset white; }

.border-b-white-none {
  border-bottom: 1px none white; }

.border-b-white-hidden {
  border-bottom: 1px hidden white; }

.border-l-white {
  border-left: 1px solid white; }

.border-l-white-dotted {
  border-left: 1px dotted white; }

.border-l-white-dashed {
  border-left: 1px dashed white; }

.border-l-white-solid {
  border-left: 1px solid white; }

.border-l-white-double {
  border-left: 1px double white; }

.border-l-white-groove {
  border-left: 1px groove white; }

.border-l-white-ridge {
  border-left: 1px ridge white; }

.border-l-white-inset {
  border-left: 1px inset white; }

.border-l-white-outset {
  border-left: 1px outset white; }

.border-l-white-none {
  border-left: 1px none white; }

.border-l-white-hidden {
  border-left: 1px hidden white; }

.webchat_control {
  position: absolute;
  border: 1px solid #e6e6e6;
  background-color: white;
  cursor: pointer;
  border-radius: 5px;
  top: 33%;
  padding: 7px; }
  .webchat_control.right {
    right: 0px; }
  .webchat_control.left {
    left: 0px; }
  .webchat_control:hover {
    background-color: #fafafa; }
  .webchat_control:focus, .webchat_control:active {
    background-color: #e6e6e6; }

.m-none {
  margin: 0; }

.p-none {
  padding: 0; }

.m-t-none {
  margin-top: 0; }

.p-t-none {
  padding-top: 0; }

.m-r-none {
  margin-right: 0; }

.p-r-none {
  padding-right: 0; }

.m-b-none {
  margin-bottom: 0; }

.p-b-none {
  padding-bottom: 0; }

.m-l-none {
  margin-left: 0; }

.p-l-none {
  padding-left: 0; }

.m-xxs {
  margin: 0.125rem; }

.p-xxs {
  padding: 0.125rem; }

.m-t-xxs {
  margin-top: 0.125rem; }

.p-t-xxs {
  padding-top: 0.125rem; }

.m-r-xxs {
  margin-right: 0.125rem; }

.p-r-xxs {
  padding-right: 0.125rem; }

.m-b-xxs {
  margin-bottom: 0.125rem; }

.p-b-xxs {
  padding-bottom: 0.125rem; }

.m-l-xxs {
  margin-left: 0.125rem; }

.p-l-xxs {
  padding-left: 0.125rem; }

.m-xs {
  margin: 0.25rem; }

.p-xs {
  padding: 0.25rem; }

.m-t-xs {
  margin-top: 0.25rem; }

.p-t-xs {
  padding-top: 0.25rem; }

.m-r-xs {
  margin-right: 0.25rem; }

.p-r-xs {
  padding-right: 0.25rem; }

.m-b-xs {
  margin-bottom: 0.25rem; }

.p-b-xs {
  padding-bottom: 0.25rem; }

.m-l-xs {
  margin-left: 0.25rem; }

.p-l-xs {
  padding-left: 0.25rem; }

.m-sm {
  margin: 0.6rem; }

.p-sm {
  padding: 0.6rem; }

.m-t-sm {
  margin-top: 0.6rem; }

.p-t-sm {
  padding-top: 0.6rem; }

.m-r-sm {
  margin-right: 0.6rem; }

.p-r-sm {
  padding-right: 0.6rem; }

.m-b-sm {
  margin-bottom: 0.6rem; }

.p-b-sm {
  padding-bottom: 0.6rem; }

.m-l-sm {
  margin-left: 0.6rem; }

.p-l-sm {
  padding-left: 0.6rem; }

.m-md {
  margin: 1rem; }

.p-md {
  padding: 1rem; }

.m-t-md {
  margin-top: 1rem; }

.p-t-md {
  padding-top: 1rem; }

.m-r-md {
  margin-right: 1rem; }

.p-r-md {
  padding-right: 1rem; }

.m-b-md {
  margin-bottom: 1rem; }

.p-b-md {
  padding-bottom: 1rem; }

.m-l-md {
  margin-left: 1rem; }

.p-l-md {
  padding-left: 1rem; }

.m-lg {
  margin: 2rem; }

.p-lg {
  padding: 2rem; }

.m-t-lg {
  margin-top: 2rem; }

.p-t-lg {
  padding-top: 2rem; }

.m-r-lg {
  margin-right: 2rem; }

.p-r-lg {
  padding-right: 2rem; }

.m-b-lg {
  margin-bottom: 2rem; }

.p-b-lg {
  padding-bottom: 2rem; }

.m-l-lg {
  margin-left: 2rem; }

.p-l-lg {
  padding-left: 2rem; }

.m-xl {
  margin: 4rem; }

.p-xl {
  padding: 4rem; }

.m-t-xl {
  margin-top: 4rem; }

.p-t-xl {
  padding-top: 4rem; }

.m-r-xl {
  margin-right: 4rem; }

.p-r-xl {
  padding-right: 4rem; }

.m-b-xl {
  margin-bottom: 4rem; }

.p-b-xl {
  padding-bottom: 4rem; }

.m-l-xl {
  margin-left: 4rem; }

.p-l-xl {
  padding-left: 4rem; }

.m-xxl {
  margin: 8rem; }

.p-xxl {
  padding: 8rem; }

.m-t-xxl {
  margin-top: 8rem; }

.p-t-xxl {
  padding-top: 8rem; }

.m-r-xxl {
  margin-right: 8rem; }

.p-r-xxl {
  padding-right: 8rem; }

.m-b-xxl {
  margin-bottom: 8rem; }

.p-b-xxl {
  padding-bottom: 8rem; }

.m-l-xxl {
  margin-left: 8rem; }

.p-l-xxl {
  padding-left: 8rem; }

.has-text-primary {
  color: #2d2f7b; }

.has-background-primary {
  background-color: #2d2f7b; }

.border-primary {
  border: 1px solid #2d2f7b; }

.border-m-primary:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #2d2f7b;
  transform: translateY(-50%); }

.border-primary-dotted {
  border: 1px dotted #2d2f7b; }

.border-primary-dashed {
  border: 1px dashed #2d2f7b; }

.border-primary-solid {
  border: 1px solid #2d2f7b; }

.border-primary-double {
  border: 1px double #2d2f7b; }

.border-primary-groove {
  border: 1px groove #2d2f7b; }

.border-primary-ridge {
  border: 1px ridge #2d2f7b; }

.border-primary-inset {
  border: 1px inset #2d2f7b; }

.border-primary-outset {
  border: 1px outset #2d2f7b; }

.border-primary-none {
  border: 1px none #2d2f7b; }

.border-primary-hidden {
  border: 1px hidden #2d2f7b; }

.border-t-primary {
  border-top: 1px solid #2d2f7b; }

.border-t-primary-dotted {
  border-top: 1px dotted #2d2f7b; }

.border-t-primary-dashed {
  border-top: 1px dashed #2d2f7b; }

.border-t-primary-solid {
  border-top: 1px solid #2d2f7b; }

.border-t-primary-double {
  border-top: 1px double #2d2f7b; }

.border-t-primary-groove {
  border-top: 1px groove #2d2f7b; }

.border-t-primary-ridge {
  border-top: 1px ridge #2d2f7b; }

.border-t-primary-inset {
  border-top: 1px inset #2d2f7b; }

.border-t-primary-outset {
  border-top: 1px outset #2d2f7b; }

.border-t-primary-none {
  border-top: 1px none #2d2f7b; }

.border-t-primary-hidden {
  border-top: 1px hidden #2d2f7b; }

.border-r-primary {
  border-right: 1px solid #2d2f7b; }

.border-r-primary-dotted {
  border-right: 1px dotted #2d2f7b; }

.border-r-primary-dashed {
  border-right: 1px dashed #2d2f7b; }

.border-r-primary-solid {
  border-right: 1px solid #2d2f7b; }

.border-r-primary-double {
  border-right: 1px double #2d2f7b; }

.border-r-primary-groove {
  border-right: 1px groove #2d2f7b; }

.border-r-primary-ridge {
  border-right: 1px ridge #2d2f7b; }

.border-r-primary-inset {
  border-right: 1px inset #2d2f7b; }

.border-r-primary-outset {
  border-right: 1px outset #2d2f7b; }

.border-r-primary-none {
  border-right: 1px none #2d2f7b; }

.border-r-primary-hidden {
  border-right: 1px hidden #2d2f7b; }

.border-b-primary {
  border-bottom: 1px solid #2d2f7b; }

.border-b-primary-dotted {
  border-bottom: 1px dotted #2d2f7b; }

.border-b-primary-dashed {
  border-bottom: 1px dashed #2d2f7b; }

.border-b-primary-solid {
  border-bottom: 1px solid #2d2f7b; }

.border-b-primary-double {
  border-bottom: 1px double #2d2f7b; }

.border-b-primary-groove {
  border-bottom: 1px groove #2d2f7b; }

.border-b-primary-ridge {
  border-bottom: 1px ridge #2d2f7b; }

.border-b-primary-inset {
  border-bottom: 1px inset #2d2f7b; }

.border-b-primary-outset {
  border-bottom: 1px outset #2d2f7b; }

.border-b-primary-none {
  border-bottom: 1px none #2d2f7b; }

.border-b-primary-hidden {
  border-bottom: 1px hidden #2d2f7b; }

.border-l-primary {
  border-left: 1px solid #2d2f7b; }

.border-l-primary-dotted {
  border-left: 1px dotted #2d2f7b; }

.border-l-primary-dashed {
  border-left: 1px dashed #2d2f7b; }

.border-l-primary-solid {
  border-left: 1px solid #2d2f7b; }

.border-l-primary-double {
  border-left: 1px double #2d2f7b; }

.border-l-primary-groove {
  border-left: 1px groove #2d2f7b; }

.border-l-primary-ridge {
  border-left: 1px ridge #2d2f7b; }

.border-l-primary-inset {
  border-left: 1px inset #2d2f7b; }

.border-l-primary-outset {
  border-left: 1px outset #2d2f7b; }

.border-l-primary-none {
  border-left: 1px none #2d2f7b; }

.border-l-primary-hidden {
  border-left: 1px hidden #2d2f7b; }

.has-text-primary-bis {
  color: #4d4db3; }

.has-background-primary-bis {
  background-color: #4d4db3; }

.border-primary-bis {
  border: 1px solid #4d4db3; }

.border-m-primary-bis:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #4d4db3;
  transform: translateY(-50%); }

.border-primary-bis-dotted {
  border: 1px dotted #4d4db3; }

.border-primary-bis-dashed {
  border: 1px dashed #4d4db3; }

.border-primary-bis-solid {
  border: 1px solid #4d4db3; }

.border-primary-bis-double {
  border: 1px double #4d4db3; }

.border-primary-bis-groove {
  border: 1px groove #4d4db3; }

.border-primary-bis-ridge {
  border: 1px ridge #4d4db3; }

.border-primary-bis-inset {
  border: 1px inset #4d4db3; }

.border-primary-bis-outset {
  border: 1px outset #4d4db3; }

.border-primary-bis-none {
  border: 1px none #4d4db3; }

.border-primary-bis-hidden {
  border: 1px hidden #4d4db3; }

.border-t-primary-bis {
  border-top: 1px solid #4d4db3; }

.border-t-primary-bis-dotted {
  border-top: 1px dotted #4d4db3; }

.border-t-primary-bis-dashed {
  border-top: 1px dashed #4d4db3; }

.border-t-primary-bis-solid {
  border-top: 1px solid #4d4db3; }

.border-t-primary-bis-double {
  border-top: 1px double #4d4db3; }

.border-t-primary-bis-groove {
  border-top: 1px groove #4d4db3; }

.border-t-primary-bis-ridge {
  border-top: 1px ridge #4d4db3; }

.border-t-primary-bis-inset {
  border-top: 1px inset #4d4db3; }

.border-t-primary-bis-outset {
  border-top: 1px outset #4d4db3; }

.border-t-primary-bis-none {
  border-top: 1px none #4d4db3; }

.border-t-primary-bis-hidden {
  border-top: 1px hidden #4d4db3; }

.border-r-primary-bis {
  border-right: 1px solid #4d4db3; }

.border-r-primary-bis-dotted {
  border-right: 1px dotted #4d4db3; }

.border-r-primary-bis-dashed {
  border-right: 1px dashed #4d4db3; }

.border-r-primary-bis-solid {
  border-right: 1px solid #4d4db3; }

.border-r-primary-bis-double {
  border-right: 1px double #4d4db3; }

.border-r-primary-bis-groove {
  border-right: 1px groove #4d4db3; }

.border-r-primary-bis-ridge {
  border-right: 1px ridge #4d4db3; }

.border-r-primary-bis-inset {
  border-right: 1px inset #4d4db3; }

.border-r-primary-bis-outset {
  border-right: 1px outset #4d4db3; }

.border-r-primary-bis-none {
  border-right: 1px none #4d4db3; }

.border-r-primary-bis-hidden {
  border-right: 1px hidden #4d4db3; }

.border-b-primary-bis {
  border-bottom: 1px solid #4d4db3; }

.border-b-primary-bis-dotted {
  border-bottom: 1px dotted #4d4db3; }

.border-b-primary-bis-dashed {
  border-bottom: 1px dashed #4d4db3; }

.border-b-primary-bis-solid {
  border-bottom: 1px solid #4d4db3; }

.border-b-primary-bis-double {
  border-bottom: 1px double #4d4db3; }

.border-b-primary-bis-groove {
  border-bottom: 1px groove #4d4db3; }

.border-b-primary-bis-ridge {
  border-bottom: 1px ridge #4d4db3; }

.border-b-primary-bis-inset {
  border-bottom: 1px inset #4d4db3; }

.border-b-primary-bis-outset {
  border-bottom: 1px outset #4d4db3; }

.border-b-primary-bis-none {
  border-bottom: 1px none #4d4db3; }

.border-b-primary-bis-hidden {
  border-bottom: 1px hidden #4d4db3; }

.border-l-primary-bis {
  border-left: 1px solid #4d4db3; }

.border-l-primary-bis-dotted {
  border-left: 1px dotted #4d4db3; }

.border-l-primary-bis-dashed {
  border-left: 1px dashed #4d4db3; }

.border-l-primary-bis-solid {
  border-left: 1px solid #4d4db3; }

.border-l-primary-bis-double {
  border-left: 1px double #4d4db3; }

.border-l-primary-bis-groove {
  border-left: 1px groove #4d4db3; }

.border-l-primary-bis-ridge {
  border-left: 1px ridge #4d4db3; }

.border-l-primary-bis-inset {
  border-left: 1px inset #4d4db3; }

.border-l-primary-bis-outset {
  border-left: 1px outset #4d4db3; }

.border-l-primary-bis-none {
  border-left: 1px none #4d4db3; }

.border-l-primary-bis-hidden {
  border-left: 1px hidden #4d4db3; }

.has-text-primary-ter {
  color: #7a7ab8; }

.has-background-primary-ter {
  background-color: #7a7ab8; }

.border-primary-ter {
  border: 1px solid #7a7ab8; }

.border-m-primary-ter:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #7a7ab8;
  transform: translateY(-50%); }

.border-primary-ter-dotted {
  border: 1px dotted #7a7ab8; }

.border-primary-ter-dashed {
  border: 1px dashed #7a7ab8; }

.border-primary-ter-solid {
  border: 1px solid #7a7ab8; }

.border-primary-ter-double {
  border: 1px double #7a7ab8; }

.border-primary-ter-groove {
  border: 1px groove #7a7ab8; }

.border-primary-ter-ridge {
  border: 1px ridge #7a7ab8; }

.border-primary-ter-inset {
  border: 1px inset #7a7ab8; }

.border-primary-ter-outset {
  border: 1px outset #7a7ab8; }

.border-primary-ter-none {
  border: 1px none #7a7ab8; }

.border-primary-ter-hidden {
  border: 1px hidden #7a7ab8; }

.border-t-primary-ter {
  border-top: 1px solid #7a7ab8; }

.border-t-primary-ter-dotted {
  border-top: 1px dotted #7a7ab8; }

.border-t-primary-ter-dashed {
  border-top: 1px dashed #7a7ab8; }

.border-t-primary-ter-solid {
  border-top: 1px solid #7a7ab8; }

.border-t-primary-ter-double {
  border-top: 1px double #7a7ab8; }

.border-t-primary-ter-groove {
  border-top: 1px groove #7a7ab8; }

.border-t-primary-ter-ridge {
  border-top: 1px ridge #7a7ab8; }

.border-t-primary-ter-inset {
  border-top: 1px inset #7a7ab8; }

.border-t-primary-ter-outset {
  border-top: 1px outset #7a7ab8; }

.border-t-primary-ter-none {
  border-top: 1px none #7a7ab8; }

.border-t-primary-ter-hidden {
  border-top: 1px hidden #7a7ab8; }

.border-r-primary-ter {
  border-right: 1px solid #7a7ab8; }

.border-r-primary-ter-dotted {
  border-right: 1px dotted #7a7ab8; }

.border-r-primary-ter-dashed {
  border-right: 1px dashed #7a7ab8; }

.border-r-primary-ter-solid {
  border-right: 1px solid #7a7ab8; }

.border-r-primary-ter-double {
  border-right: 1px double #7a7ab8; }

.border-r-primary-ter-groove {
  border-right: 1px groove #7a7ab8; }

.border-r-primary-ter-ridge {
  border-right: 1px ridge #7a7ab8; }

.border-r-primary-ter-inset {
  border-right: 1px inset #7a7ab8; }

.border-r-primary-ter-outset {
  border-right: 1px outset #7a7ab8; }

.border-r-primary-ter-none {
  border-right: 1px none #7a7ab8; }

.border-r-primary-ter-hidden {
  border-right: 1px hidden #7a7ab8; }

.border-b-primary-ter {
  border-bottom: 1px solid #7a7ab8; }

.border-b-primary-ter-dotted {
  border-bottom: 1px dotted #7a7ab8; }

.border-b-primary-ter-dashed {
  border-bottom: 1px dashed #7a7ab8; }

.border-b-primary-ter-solid {
  border-bottom: 1px solid #7a7ab8; }

.border-b-primary-ter-double {
  border-bottom: 1px double #7a7ab8; }

.border-b-primary-ter-groove {
  border-bottom: 1px groove #7a7ab8; }

.border-b-primary-ter-ridge {
  border-bottom: 1px ridge #7a7ab8; }

.border-b-primary-ter-inset {
  border-bottom: 1px inset #7a7ab8; }

.border-b-primary-ter-outset {
  border-bottom: 1px outset #7a7ab8; }

.border-b-primary-ter-none {
  border-bottom: 1px none #7a7ab8; }

.border-b-primary-ter-hidden {
  border-bottom: 1px hidden #7a7ab8; }

.border-l-primary-ter {
  border-left: 1px solid #7a7ab8; }

.border-l-primary-ter-dotted {
  border-left: 1px dotted #7a7ab8; }

.border-l-primary-ter-dashed {
  border-left: 1px dashed #7a7ab8; }

.border-l-primary-ter-solid {
  border-left: 1px solid #7a7ab8; }

.border-l-primary-ter-double {
  border-left: 1px double #7a7ab8; }

.border-l-primary-ter-groove {
  border-left: 1px groove #7a7ab8; }

.border-l-primary-ter-ridge {
  border-left: 1px ridge #7a7ab8; }

.border-l-primary-ter-inset {
  border-left: 1px inset #7a7ab8; }

.border-l-primary-ter-outset {
  border-left: 1px outset #7a7ab8; }

.border-l-primary-ter-none {
  border-left: 1px none #7a7ab8; }

.border-l-primary-ter-hidden {
  border-left: 1px hidden #7a7ab8; }

.has-text-primary-qua {
  color: #b9b9e9; }

.has-background-primary-qua {
  background-color: #b9b9e9; }

.border-primary-qua {
  border: 1px solid #b9b9e9; }

.border-m-primary-qua:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b9b9e9;
  transform: translateY(-50%); }

.border-primary-qua-dotted {
  border: 1px dotted #b9b9e9; }

.border-primary-qua-dashed {
  border: 1px dashed #b9b9e9; }

.border-primary-qua-solid {
  border: 1px solid #b9b9e9; }

.border-primary-qua-double {
  border: 1px double #b9b9e9; }

.border-primary-qua-groove {
  border: 1px groove #b9b9e9; }

.border-primary-qua-ridge {
  border: 1px ridge #b9b9e9; }

.border-primary-qua-inset {
  border: 1px inset #b9b9e9; }

.border-primary-qua-outset {
  border: 1px outset #b9b9e9; }

.border-primary-qua-none {
  border: 1px none #b9b9e9; }

.border-primary-qua-hidden {
  border: 1px hidden #b9b9e9; }

.border-t-primary-qua {
  border-top: 1px solid #b9b9e9; }

.border-t-primary-qua-dotted {
  border-top: 1px dotted #b9b9e9; }

.border-t-primary-qua-dashed {
  border-top: 1px dashed #b9b9e9; }

.border-t-primary-qua-solid {
  border-top: 1px solid #b9b9e9; }

.border-t-primary-qua-double {
  border-top: 1px double #b9b9e9; }

.border-t-primary-qua-groove {
  border-top: 1px groove #b9b9e9; }

.border-t-primary-qua-ridge {
  border-top: 1px ridge #b9b9e9; }

.border-t-primary-qua-inset {
  border-top: 1px inset #b9b9e9; }

.border-t-primary-qua-outset {
  border-top: 1px outset #b9b9e9; }

.border-t-primary-qua-none {
  border-top: 1px none #b9b9e9; }

.border-t-primary-qua-hidden {
  border-top: 1px hidden #b9b9e9; }

.border-r-primary-qua {
  border-right: 1px solid #b9b9e9; }

.border-r-primary-qua-dotted {
  border-right: 1px dotted #b9b9e9; }

.border-r-primary-qua-dashed {
  border-right: 1px dashed #b9b9e9; }

.border-r-primary-qua-solid {
  border-right: 1px solid #b9b9e9; }

.border-r-primary-qua-double {
  border-right: 1px double #b9b9e9; }

.border-r-primary-qua-groove {
  border-right: 1px groove #b9b9e9; }

.border-r-primary-qua-ridge {
  border-right: 1px ridge #b9b9e9; }

.border-r-primary-qua-inset {
  border-right: 1px inset #b9b9e9; }

.border-r-primary-qua-outset {
  border-right: 1px outset #b9b9e9; }

.border-r-primary-qua-none {
  border-right: 1px none #b9b9e9; }

.border-r-primary-qua-hidden {
  border-right: 1px hidden #b9b9e9; }

.border-b-primary-qua {
  border-bottom: 1px solid #b9b9e9; }

.border-b-primary-qua-dotted {
  border-bottom: 1px dotted #b9b9e9; }

.border-b-primary-qua-dashed {
  border-bottom: 1px dashed #b9b9e9; }

.border-b-primary-qua-solid {
  border-bottom: 1px solid #b9b9e9; }

.border-b-primary-qua-double {
  border-bottom: 1px double #b9b9e9; }

.border-b-primary-qua-groove {
  border-bottom: 1px groove #b9b9e9; }

.border-b-primary-qua-ridge {
  border-bottom: 1px ridge #b9b9e9; }

.border-b-primary-qua-inset {
  border-bottom: 1px inset #b9b9e9; }

.border-b-primary-qua-outset {
  border-bottom: 1px outset #b9b9e9; }

.border-b-primary-qua-none {
  border-bottom: 1px none #b9b9e9; }

.border-b-primary-qua-hidden {
  border-bottom: 1px hidden #b9b9e9; }

.border-l-primary-qua {
  border-left: 1px solid #b9b9e9; }

.border-l-primary-qua-dotted {
  border-left: 1px dotted #b9b9e9; }

.border-l-primary-qua-dashed {
  border-left: 1px dashed #b9b9e9; }

.border-l-primary-qua-solid {
  border-left: 1px solid #b9b9e9; }

.border-l-primary-qua-double {
  border-left: 1px double #b9b9e9; }

.border-l-primary-qua-groove {
  border-left: 1px groove #b9b9e9; }

.border-l-primary-qua-ridge {
  border-left: 1px ridge #b9b9e9; }

.border-l-primary-qua-inset {
  border-left: 1px inset #b9b9e9; }

.border-l-primary-qua-outset {
  border-left: 1px outset #b9b9e9; }

.border-l-primary-qua-none {
  border-left: 1px none #b9b9e9; }

.border-l-primary-qua-hidden {
  border-left: 1px hidden #b9b9e9; }

.has-text-primary-qui {
  color: #b8b8d6; }

.has-background-primary-qui {
  background-color: #b8b8d6; }

.border-primary-qui {
  border: 1px solid #b8b8d6; }

.border-m-primary-qui:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b8b8d6;
  transform: translateY(-50%); }

.border-primary-qui-dotted {
  border: 1px dotted #b8b8d6; }

.border-primary-qui-dashed {
  border: 1px dashed #b8b8d6; }

.border-primary-qui-solid {
  border: 1px solid #b8b8d6; }

.border-primary-qui-double {
  border: 1px double #b8b8d6; }

.border-primary-qui-groove {
  border: 1px groove #b8b8d6; }

.border-primary-qui-ridge {
  border: 1px ridge #b8b8d6; }

.border-primary-qui-inset {
  border: 1px inset #b8b8d6; }

.border-primary-qui-outset {
  border: 1px outset #b8b8d6; }

.border-primary-qui-none {
  border: 1px none #b8b8d6; }

.border-primary-qui-hidden {
  border: 1px hidden #b8b8d6; }

.border-t-primary-qui {
  border-top: 1px solid #b8b8d6; }

.border-t-primary-qui-dotted {
  border-top: 1px dotted #b8b8d6; }

.border-t-primary-qui-dashed {
  border-top: 1px dashed #b8b8d6; }

.border-t-primary-qui-solid {
  border-top: 1px solid #b8b8d6; }

.border-t-primary-qui-double {
  border-top: 1px double #b8b8d6; }

.border-t-primary-qui-groove {
  border-top: 1px groove #b8b8d6; }

.border-t-primary-qui-ridge {
  border-top: 1px ridge #b8b8d6; }

.border-t-primary-qui-inset {
  border-top: 1px inset #b8b8d6; }

.border-t-primary-qui-outset {
  border-top: 1px outset #b8b8d6; }

.border-t-primary-qui-none {
  border-top: 1px none #b8b8d6; }

.border-t-primary-qui-hidden {
  border-top: 1px hidden #b8b8d6; }

.border-r-primary-qui {
  border-right: 1px solid #b8b8d6; }

.border-r-primary-qui-dotted {
  border-right: 1px dotted #b8b8d6; }

.border-r-primary-qui-dashed {
  border-right: 1px dashed #b8b8d6; }

.border-r-primary-qui-solid {
  border-right: 1px solid #b8b8d6; }

.border-r-primary-qui-double {
  border-right: 1px double #b8b8d6; }

.border-r-primary-qui-groove {
  border-right: 1px groove #b8b8d6; }

.border-r-primary-qui-ridge {
  border-right: 1px ridge #b8b8d6; }

.border-r-primary-qui-inset {
  border-right: 1px inset #b8b8d6; }

.border-r-primary-qui-outset {
  border-right: 1px outset #b8b8d6; }

.border-r-primary-qui-none {
  border-right: 1px none #b8b8d6; }

.border-r-primary-qui-hidden {
  border-right: 1px hidden #b8b8d6; }

.border-b-primary-qui {
  border-bottom: 1px solid #b8b8d6; }

.border-b-primary-qui-dotted {
  border-bottom: 1px dotted #b8b8d6; }

.border-b-primary-qui-dashed {
  border-bottom: 1px dashed #b8b8d6; }

.border-b-primary-qui-solid {
  border-bottom: 1px solid #b8b8d6; }

.border-b-primary-qui-double {
  border-bottom: 1px double #b8b8d6; }

.border-b-primary-qui-groove {
  border-bottom: 1px groove #b8b8d6; }

.border-b-primary-qui-ridge {
  border-bottom: 1px ridge #b8b8d6; }

.border-b-primary-qui-inset {
  border-bottom: 1px inset #b8b8d6; }

.border-b-primary-qui-outset {
  border-bottom: 1px outset #b8b8d6; }

.border-b-primary-qui-none {
  border-bottom: 1px none #b8b8d6; }

.border-b-primary-qui-hidden {
  border-bottom: 1px hidden #b8b8d6; }

.border-l-primary-qui {
  border-left: 1px solid #b8b8d6; }

.border-l-primary-qui-dotted {
  border-left: 1px dotted #b8b8d6; }

.border-l-primary-qui-dashed {
  border-left: 1px dashed #b8b8d6; }

.border-l-primary-qui-solid {
  border-left: 1px solid #b8b8d6; }

.border-l-primary-qui-double {
  border-left: 1px double #b8b8d6; }

.border-l-primary-qui-groove {
  border-left: 1px groove #b8b8d6; }

.border-l-primary-qui-ridge {
  border-left: 1px ridge #b8b8d6; }

.border-l-primary-qui-inset {
  border-left: 1px inset #b8b8d6; }

.border-l-primary-qui-outset {
  border-left: 1px outset #b8b8d6; }

.border-l-primary-qui-none {
  border-left: 1px none #b8b8d6; }

.border-l-primary-qui-hidden {
  border-left: 1px hidden #b8b8d6; }

.has-text-primary-light {
  color: #dbdbf0; }

.has-background-primary-light {
  background-color: #dbdbf0; }

.border-primary-light {
  border: 1px solid #dbdbf0; }

.border-m-primary-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dbdbf0;
  transform: translateY(-50%); }

.border-primary-light-dotted {
  border: 1px dotted #dbdbf0; }

.border-primary-light-dashed {
  border: 1px dashed #dbdbf0; }

.border-primary-light-solid {
  border: 1px solid #dbdbf0; }

.border-primary-light-double {
  border: 1px double #dbdbf0; }

.border-primary-light-groove {
  border: 1px groove #dbdbf0; }

.border-primary-light-ridge {
  border: 1px ridge #dbdbf0; }

.border-primary-light-inset {
  border: 1px inset #dbdbf0; }

.border-primary-light-outset {
  border: 1px outset #dbdbf0; }

.border-primary-light-none {
  border: 1px none #dbdbf0; }

.border-primary-light-hidden {
  border: 1px hidden #dbdbf0; }

.border-t-primary-light {
  border-top: 1px solid #dbdbf0; }

.border-t-primary-light-dotted {
  border-top: 1px dotted #dbdbf0; }

.border-t-primary-light-dashed {
  border-top: 1px dashed #dbdbf0; }

.border-t-primary-light-solid {
  border-top: 1px solid #dbdbf0; }

.border-t-primary-light-double {
  border-top: 1px double #dbdbf0; }

.border-t-primary-light-groove {
  border-top: 1px groove #dbdbf0; }

.border-t-primary-light-ridge {
  border-top: 1px ridge #dbdbf0; }

.border-t-primary-light-inset {
  border-top: 1px inset #dbdbf0; }

.border-t-primary-light-outset {
  border-top: 1px outset #dbdbf0; }

.border-t-primary-light-none {
  border-top: 1px none #dbdbf0; }

.border-t-primary-light-hidden {
  border-top: 1px hidden #dbdbf0; }

.border-r-primary-light {
  border-right: 1px solid #dbdbf0; }

.border-r-primary-light-dotted {
  border-right: 1px dotted #dbdbf0; }

.border-r-primary-light-dashed {
  border-right: 1px dashed #dbdbf0; }

.border-r-primary-light-solid {
  border-right: 1px solid #dbdbf0; }

.border-r-primary-light-double {
  border-right: 1px double #dbdbf0; }

.border-r-primary-light-groove {
  border-right: 1px groove #dbdbf0; }

.border-r-primary-light-ridge {
  border-right: 1px ridge #dbdbf0; }

.border-r-primary-light-inset {
  border-right: 1px inset #dbdbf0; }

.border-r-primary-light-outset {
  border-right: 1px outset #dbdbf0; }

.border-r-primary-light-none {
  border-right: 1px none #dbdbf0; }

.border-r-primary-light-hidden {
  border-right: 1px hidden #dbdbf0; }

.border-b-primary-light {
  border-bottom: 1px solid #dbdbf0; }

.border-b-primary-light-dotted {
  border-bottom: 1px dotted #dbdbf0; }

.border-b-primary-light-dashed {
  border-bottom: 1px dashed #dbdbf0; }

.border-b-primary-light-solid {
  border-bottom: 1px solid #dbdbf0; }

.border-b-primary-light-double {
  border-bottom: 1px double #dbdbf0; }

.border-b-primary-light-groove {
  border-bottom: 1px groove #dbdbf0; }

.border-b-primary-light-ridge {
  border-bottom: 1px ridge #dbdbf0; }

.border-b-primary-light-inset {
  border-bottom: 1px inset #dbdbf0; }

.border-b-primary-light-outset {
  border-bottom: 1px outset #dbdbf0; }

.border-b-primary-light-none {
  border-bottom: 1px none #dbdbf0; }

.border-b-primary-light-hidden {
  border-bottom: 1px hidden #dbdbf0; }

.border-l-primary-light {
  border-left: 1px solid #dbdbf0; }

.border-l-primary-light-dotted {
  border-left: 1px dotted #dbdbf0; }

.border-l-primary-light-dashed {
  border-left: 1px dashed #dbdbf0; }

.border-l-primary-light-solid {
  border-left: 1px solid #dbdbf0; }

.border-l-primary-light-double {
  border-left: 1px double #dbdbf0; }

.border-l-primary-light-groove {
  border-left: 1px groove #dbdbf0; }

.border-l-primary-light-ridge {
  border-left: 1px ridge #dbdbf0; }

.border-l-primary-light-inset {
  border-left: 1px inset #dbdbf0; }

.border-l-primary-light-outset {
  border-left: 1px outset #dbdbf0; }

.border-l-primary-light-none {
  border-left: 1px none #dbdbf0; }

.border-l-primary-light-hidden {
  border-left: 1px hidden #dbdbf0; }

.has-text-primary-lighter {
  color: #ededf7; }

.has-background-primary-lighter {
  background-color: #ededf7; }

.border-primary-lighter {
  border: 1px solid #ededf7; }

.border-m-primary-lighter:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ededf7;
  transform: translateY(-50%); }

.border-primary-lighter-dotted {
  border: 1px dotted #ededf7; }

.border-primary-lighter-dashed {
  border: 1px dashed #ededf7; }

.border-primary-lighter-solid {
  border: 1px solid #ededf7; }

.border-primary-lighter-double {
  border: 1px double #ededf7; }

.border-primary-lighter-groove {
  border: 1px groove #ededf7; }

.border-primary-lighter-ridge {
  border: 1px ridge #ededf7; }

.border-primary-lighter-inset {
  border: 1px inset #ededf7; }

.border-primary-lighter-outset {
  border: 1px outset #ededf7; }

.border-primary-lighter-none {
  border: 1px none #ededf7; }

.border-primary-lighter-hidden {
  border: 1px hidden #ededf7; }

.border-t-primary-lighter {
  border-top: 1px solid #ededf7; }

.border-t-primary-lighter-dotted {
  border-top: 1px dotted #ededf7; }

.border-t-primary-lighter-dashed {
  border-top: 1px dashed #ededf7; }

.border-t-primary-lighter-solid {
  border-top: 1px solid #ededf7; }

.border-t-primary-lighter-double {
  border-top: 1px double #ededf7; }

.border-t-primary-lighter-groove {
  border-top: 1px groove #ededf7; }

.border-t-primary-lighter-ridge {
  border-top: 1px ridge #ededf7; }

.border-t-primary-lighter-inset {
  border-top: 1px inset #ededf7; }

.border-t-primary-lighter-outset {
  border-top: 1px outset #ededf7; }

.border-t-primary-lighter-none {
  border-top: 1px none #ededf7; }

.border-t-primary-lighter-hidden {
  border-top: 1px hidden #ededf7; }

.border-r-primary-lighter {
  border-right: 1px solid #ededf7; }

.border-r-primary-lighter-dotted {
  border-right: 1px dotted #ededf7; }

.border-r-primary-lighter-dashed {
  border-right: 1px dashed #ededf7; }

.border-r-primary-lighter-solid {
  border-right: 1px solid #ededf7; }

.border-r-primary-lighter-double {
  border-right: 1px double #ededf7; }

.border-r-primary-lighter-groove {
  border-right: 1px groove #ededf7; }

.border-r-primary-lighter-ridge {
  border-right: 1px ridge #ededf7; }

.border-r-primary-lighter-inset {
  border-right: 1px inset #ededf7; }

.border-r-primary-lighter-outset {
  border-right: 1px outset #ededf7; }

.border-r-primary-lighter-none {
  border-right: 1px none #ededf7; }

.border-r-primary-lighter-hidden {
  border-right: 1px hidden #ededf7; }

.border-b-primary-lighter {
  border-bottom: 1px solid #ededf7; }

.border-b-primary-lighter-dotted {
  border-bottom: 1px dotted #ededf7; }

.border-b-primary-lighter-dashed {
  border-bottom: 1px dashed #ededf7; }

.border-b-primary-lighter-solid {
  border-bottom: 1px solid #ededf7; }

.border-b-primary-lighter-double {
  border-bottom: 1px double #ededf7; }

.border-b-primary-lighter-groove {
  border-bottom: 1px groove #ededf7; }

.border-b-primary-lighter-ridge {
  border-bottom: 1px ridge #ededf7; }

.border-b-primary-lighter-inset {
  border-bottom: 1px inset #ededf7; }

.border-b-primary-lighter-outset {
  border-bottom: 1px outset #ededf7; }

.border-b-primary-lighter-none {
  border-bottom: 1px none #ededf7; }

.border-b-primary-lighter-hidden {
  border-bottom: 1px hidden #ededf7; }

.border-l-primary-lighter {
  border-left: 1px solid #ededf7; }

.border-l-primary-lighter-dotted {
  border-left: 1px dotted #ededf7; }

.border-l-primary-lighter-dashed {
  border-left: 1px dashed #ededf7; }

.border-l-primary-lighter-solid {
  border-left: 1px solid #ededf7; }

.border-l-primary-lighter-double {
  border-left: 1px double #ededf7; }

.border-l-primary-lighter-groove {
  border-left: 1px groove #ededf7; }

.border-l-primary-lighter-ridge {
  border-left: 1px ridge #ededf7; }

.border-l-primary-lighter-inset {
  border-left: 1px inset #ededf7; }

.border-l-primary-lighter-outset {
  border-left: 1px outset #ededf7; }

.border-l-primary-lighter-none {
  border-left: 1px none #ededf7; }

.border-l-primary-lighter-hidden {
  border-left: 1px hidden #ededf7; }

.has-text-primary-lighbis {
  color: #f4f4fa; }

.has-background-primary-lighbis {
  background-color: #f4f4fa; }

.border-primary-lighbis {
  border: 1px solid #f4f4fa; }

.border-m-primary-lighbis:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f4f4fa;
  transform: translateY(-50%); }

.border-primary-lighbis-dotted {
  border: 1px dotted #f4f4fa; }

.border-primary-lighbis-dashed {
  border: 1px dashed #f4f4fa; }

.border-primary-lighbis-solid {
  border: 1px solid #f4f4fa; }

.border-primary-lighbis-double {
  border: 1px double #f4f4fa; }

.border-primary-lighbis-groove {
  border: 1px groove #f4f4fa; }

.border-primary-lighbis-ridge {
  border: 1px ridge #f4f4fa; }

.border-primary-lighbis-inset {
  border: 1px inset #f4f4fa; }

.border-primary-lighbis-outset {
  border: 1px outset #f4f4fa; }

.border-primary-lighbis-none {
  border: 1px none #f4f4fa; }

.border-primary-lighbis-hidden {
  border: 1px hidden #f4f4fa; }

.border-t-primary-lighbis {
  border-top: 1px solid #f4f4fa; }

.border-t-primary-lighbis-dotted {
  border-top: 1px dotted #f4f4fa; }

.border-t-primary-lighbis-dashed {
  border-top: 1px dashed #f4f4fa; }

.border-t-primary-lighbis-solid {
  border-top: 1px solid #f4f4fa; }

.border-t-primary-lighbis-double {
  border-top: 1px double #f4f4fa; }

.border-t-primary-lighbis-groove {
  border-top: 1px groove #f4f4fa; }

.border-t-primary-lighbis-ridge {
  border-top: 1px ridge #f4f4fa; }

.border-t-primary-lighbis-inset {
  border-top: 1px inset #f4f4fa; }

.border-t-primary-lighbis-outset {
  border-top: 1px outset #f4f4fa; }

.border-t-primary-lighbis-none {
  border-top: 1px none #f4f4fa; }

.border-t-primary-lighbis-hidden {
  border-top: 1px hidden #f4f4fa; }

.border-r-primary-lighbis {
  border-right: 1px solid #f4f4fa; }

.border-r-primary-lighbis-dotted {
  border-right: 1px dotted #f4f4fa; }

.border-r-primary-lighbis-dashed {
  border-right: 1px dashed #f4f4fa; }

.border-r-primary-lighbis-solid {
  border-right: 1px solid #f4f4fa; }

.border-r-primary-lighbis-double {
  border-right: 1px double #f4f4fa; }

.border-r-primary-lighbis-groove {
  border-right: 1px groove #f4f4fa; }

.border-r-primary-lighbis-ridge {
  border-right: 1px ridge #f4f4fa; }

.border-r-primary-lighbis-inset {
  border-right: 1px inset #f4f4fa; }

.border-r-primary-lighbis-outset {
  border-right: 1px outset #f4f4fa; }

.border-r-primary-lighbis-none {
  border-right: 1px none #f4f4fa; }

.border-r-primary-lighbis-hidden {
  border-right: 1px hidden #f4f4fa; }

.border-b-primary-lighbis {
  border-bottom: 1px solid #f4f4fa; }

.border-b-primary-lighbis-dotted {
  border-bottom: 1px dotted #f4f4fa; }

.border-b-primary-lighbis-dashed {
  border-bottom: 1px dashed #f4f4fa; }

.border-b-primary-lighbis-solid {
  border-bottom: 1px solid #f4f4fa; }

.border-b-primary-lighbis-double {
  border-bottom: 1px double #f4f4fa; }

.border-b-primary-lighbis-groove {
  border-bottom: 1px groove #f4f4fa; }

.border-b-primary-lighbis-ridge {
  border-bottom: 1px ridge #f4f4fa; }

.border-b-primary-lighbis-inset {
  border-bottom: 1px inset #f4f4fa; }

.border-b-primary-lighbis-outset {
  border-bottom: 1px outset #f4f4fa; }

.border-b-primary-lighbis-none {
  border-bottom: 1px none #f4f4fa; }

.border-b-primary-lighbis-hidden {
  border-bottom: 1px hidden #f4f4fa; }

.border-l-primary-lighbis {
  border-left: 1px solid #f4f4fa; }

.border-l-primary-lighbis-dotted {
  border-left: 1px dotted #f4f4fa; }

.border-l-primary-lighbis-dashed {
  border-left: 1px dashed #f4f4fa; }

.border-l-primary-lighbis-solid {
  border-left: 1px solid #f4f4fa; }

.border-l-primary-lighbis-double {
  border-left: 1px double #f4f4fa; }

.border-l-primary-lighbis-groove {
  border-left: 1px groove #f4f4fa; }

.border-l-primary-lighbis-ridge {
  border-left: 1px ridge #f4f4fa; }

.border-l-primary-lighbis-inset {
  border-left: 1px inset #f4f4fa; }

.border-l-primary-lighbis-outset {
  border-left: 1px outset #f4f4fa; }

.border-l-primary-lighbis-none {
  border-left: 1px none #f4f4fa; }

.border-l-primary-lighbis-hidden {
  border-left: 1px hidden #f4f4fa; }

.has-text-secondary {
  color: #eb6666; }

.has-background-secondary {
  background-color: #eb6666; }

.border-secondary {
  border: 1px solid #eb6666; }

.border-m-secondary:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #eb6666;
  transform: translateY(-50%); }

.border-secondary-dotted {
  border: 1px dotted #eb6666; }

.border-secondary-dashed {
  border: 1px dashed #eb6666; }

.border-secondary-solid {
  border: 1px solid #eb6666; }

.border-secondary-double {
  border: 1px double #eb6666; }

.border-secondary-groove {
  border: 1px groove #eb6666; }

.border-secondary-ridge {
  border: 1px ridge #eb6666; }

.border-secondary-inset {
  border: 1px inset #eb6666; }

.border-secondary-outset {
  border: 1px outset #eb6666; }

.border-secondary-none {
  border: 1px none #eb6666; }

.border-secondary-hidden {
  border: 1px hidden #eb6666; }

.border-t-secondary {
  border-top: 1px solid #eb6666; }

.border-t-secondary-dotted {
  border-top: 1px dotted #eb6666; }

.border-t-secondary-dashed {
  border-top: 1px dashed #eb6666; }

.border-t-secondary-solid {
  border-top: 1px solid #eb6666; }

.border-t-secondary-double {
  border-top: 1px double #eb6666; }

.border-t-secondary-groove {
  border-top: 1px groove #eb6666; }

.border-t-secondary-ridge {
  border-top: 1px ridge #eb6666; }

.border-t-secondary-inset {
  border-top: 1px inset #eb6666; }

.border-t-secondary-outset {
  border-top: 1px outset #eb6666; }

.border-t-secondary-none {
  border-top: 1px none #eb6666; }

.border-t-secondary-hidden {
  border-top: 1px hidden #eb6666; }

.border-r-secondary {
  border-right: 1px solid #eb6666; }

.border-r-secondary-dotted {
  border-right: 1px dotted #eb6666; }

.border-r-secondary-dashed {
  border-right: 1px dashed #eb6666; }

.border-r-secondary-solid {
  border-right: 1px solid #eb6666; }

.border-r-secondary-double {
  border-right: 1px double #eb6666; }

.border-r-secondary-groove {
  border-right: 1px groove #eb6666; }

.border-r-secondary-ridge {
  border-right: 1px ridge #eb6666; }

.border-r-secondary-inset {
  border-right: 1px inset #eb6666; }

.border-r-secondary-outset {
  border-right: 1px outset #eb6666; }

.border-r-secondary-none {
  border-right: 1px none #eb6666; }

.border-r-secondary-hidden {
  border-right: 1px hidden #eb6666; }

.border-b-secondary {
  border-bottom: 1px solid #eb6666; }

.border-b-secondary-dotted {
  border-bottom: 1px dotted #eb6666; }

.border-b-secondary-dashed {
  border-bottom: 1px dashed #eb6666; }

.border-b-secondary-solid {
  border-bottom: 1px solid #eb6666; }

.border-b-secondary-double {
  border-bottom: 1px double #eb6666; }

.border-b-secondary-groove {
  border-bottom: 1px groove #eb6666; }

.border-b-secondary-ridge {
  border-bottom: 1px ridge #eb6666; }

.border-b-secondary-inset {
  border-bottom: 1px inset #eb6666; }

.border-b-secondary-outset {
  border-bottom: 1px outset #eb6666; }

.border-b-secondary-none {
  border-bottom: 1px none #eb6666; }

.border-b-secondary-hidden {
  border-bottom: 1px hidden #eb6666; }

.border-l-secondary {
  border-left: 1px solid #eb6666; }

.border-l-secondary-dotted {
  border-left: 1px dotted #eb6666; }

.border-l-secondary-dashed {
  border-left: 1px dashed #eb6666; }

.border-l-secondary-solid {
  border-left: 1px solid #eb6666; }

.border-l-secondary-double {
  border-left: 1px double #eb6666; }

.border-l-secondary-groove {
  border-left: 1px groove #eb6666; }

.border-l-secondary-ridge {
  border-left: 1px ridge #eb6666; }

.border-l-secondary-inset {
  border-left: 1px inset #eb6666; }

.border-l-secondary-outset {
  border-left: 1px outset #eb6666; }

.border-l-secondary-none {
  border-left: 1px none #eb6666; }

.border-l-secondary-hidden {
  border-left: 1px hidden #eb6666; }

.has-text-danger {
  color: #cc3333; }

.has-background-danger {
  background-color: #cc3333; }

.border-danger {
  border: 1px solid #cc3333; }

.border-m-danger:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #cc3333;
  transform: translateY(-50%); }

.border-danger-dotted {
  border: 1px dotted #cc3333; }

.border-danger-dashed {
  border: 1px dashed #cc3333; }

.border-danger-solid {
  border: 1px solid #cc3333; }

.border-danger-double {
  border: 1px double #cc3333; }

.border-danger-groove {
  border: 1px groove #cc3333; }

.border-danger-ridge {
  border: 1px ridge #cc3333; }

.border-danger-inset {
  border: 1px inset #cc3333; }

.border-danger-outset {
  border: 1px outset #cc3333; }

.border-danger-none {
  border: 1px none #cc3333; }

.border-danger-hidden {
  border: 1px hidden #cc3333; }

.border-t-danger {
  border-top: 1px solid #cc3333; }

.border-t-danger-dotted {
  border-top: 1px dotted #cc3333; }

.border-t-danger-dashed {
  border-top: 1px dashed #cc3333; }

.border-t-danger-solid {
  border-top: 1px solid #cc3333; }

.border-t-danger-double {
  border-top: 1px double #cc3333; }

.border-t-danger-groove {
  border-top: 1px groove #cc3333; }

.border-t-danger-ridge {
  border-top: 1px ridge #cc3333; }

.border-t-danger-inset {
  border-top: 1px inset #cc3333; }

.border-t-danger-outset {
  border-top: 1px outset #cc3333; }

.border-t-danger-none {
  border-top: 1px none #cc3333; }

.border-t-danger-hidden {
  border-top: 1px hidden #cc3333; }

.border-r-danger {
  border-right: 1px solid #cc3333; }

.border-r-danger-dotted {
  border-right: 1px dotted #cc3333; }

.border-r-danger-dashed {
  border-right: 1px dashed #cc3333; }

.border-r-danger-solid {
  border-right: 1px solid #cc3333; }

.border-r-danger-double {
  border-right: 1px double #cc3333; }

.border-r-danger-groove {
  border-right: 1px groove #cc3333; }

.border-r-danger-ridge {
  border-right: 1px ridge #cc3333; }

.border-r-danger-inset {
  border-right: 1px inset #cc3333; }

.border-r-danger-outset {
  border-right: 1px outset #cc3333; }

.border-r-danger-none {
  border-right: 1px none #cc3333; }

.border-r-danger-hidden {
  border-right: 1px hidden #cc3333; }

.border-b-danger {
  border-bottom: 1px solid #cc3333; }

.border-b-danger-dotted {
  border-bottom: 1px dotted #cc3333; }

.border-b-danger-dashed {
  border-bottom: 1px dashed #cc3333; }

.border-b-danger-solid {
  border-bottom: 1px solid #cc3333; }

.border-b-danger-double {
  border-bottom: 1px double #cc3333; }

.border-b-danger-groove {
  border-bottom: 1px groove #cc3333; }

.border-b-danger-ridge {
  border-bottom: 1px ridge #cc3333; }

.border-b-danger-inset {
  border-bottom: 1px inset #cc3333; }

.border-b-danger-outset {
  border-bottom: 1px outset #cc3333; }

.border-b-danger-none {
  border-bottom: 1px none #cc3333; }

.border-b-danger-hidden {
  border-bottom: 1px hidden #cc3333; }

.border-l-danger {
  border-left: 1px solid #cc3333; }

.border-l-danger-dotted {
  border-left: 1px dotted #cc3333; }

.border-l-danger-dashed {
  border-left: 1px dashed #cc3333; }

.border-l-danger-solid {
  border-left: 1px solid #cc3333; }

.border-l-danger-double {
  border-left: 1px double #cc3333; }

.border-l-danger-groove {
  border-left: 1px groove #cc3333; }

.border-l-danger-ridge {
  border-left: 1px ridge #cc3333; }

.border-l-danger-inset {
  border-left: 1px inset #cc3333; }

.border-l-danger-outset {
  border-left: 1px outset #cc3333; }

.border-l-danger-none {
  border-left: 1px none #cc3333; }

.border-l-danger-hidden {
  border-left: 1px hidden #cc3333; }

.has-text-danger-light {
  color: #f6dada; }

.has-background-danger-light {
  background-color: #f6dada; }

.border-danger-light {
  border: 1px solid #f6dada; }

.border-m-danger-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f6dada;
  transform: translateY(-50%); }

.border-danger-light-dotted {
  border: 1px dotted #f6dada; }

.border-danger-light-dashed {
  border: 1px dashed #f6dada; }

.border-danger-light-solid {
  border: 1px solid #f6dada; }

.border-danger-light-double {
  border: 1px double #f6dada; }

.border-danger-light-groove {
  border: 1px groove #f6dada; }

.border-danger-light-ridge {
  border: 1px ridge #f6dada; }

.border-danger-light-inset {
  border: 1px inset #f6dada; }

.border-danger-light-outset {
  border: 1px outset #f6dada; }

.border-danger-light-none {
  border: 1px none #f6dada; }

.border-danger-light-hidden {
  border: 1px hidden #f6dada; }

.border-t-danger-light {
  border-top: 1px solid #f6dada; }

.border-t-danger-light-dotted {
  border-top: 1px dotted #f6dada; }

.border-t-danger-light-dashed {
  border-top: 1px dashed #f6dada; }

.border-t-danger-light-solid {
  border-top: 1px solid #f6dada; }

.border-t-danger-light-double {
  border-top: 1px double #f6dada; }

.border-t-danger-light-groove {
  border-top: 1px groove #f6dada; }

.border-t-danger-light-ridge {
  border-top: 1px ridge #f6dada; }

.border-t-danger-light-inset {
  border-top: 1px inset #f6dada; }

.border-t-danger-light-outset {
  border-top: 1px outset #f6dada; }

.border-t-danger-light-none {
  border-top: 1px none #f6dada; }

.border-t-danger-light-hidden {
  border-top: 1px hidden #f6dada; }

.border-r-danger-light {
  border-right: 1px solid #f6dada; }

.border-r-danger-light-dotted {
  border-right: 1px dotted #f6dada; }

.border-r-danger-light-dashed {
  border-right: 1px dashed #f6dada; }

.border-r-danger-light-solid {
  border-right: 1px solid #f6dada; }

.border-r-danger-light-double {
  border-right: 1px double #f6dada; }

.border-r-danger-light-groove {
  border-right: 1px groove #f6dada; }

.border-r-danger-light-ridge {
  border-right: 1px ridge #f6dada; }

.border-r-danger-light-inset {
  border-right: 1px inset #f6dada; }

.border-r-danger-light-outset {
  border-right: 1px outset #f6dada; }

.border-r-danger-light-none {
  border-right: 1px none #f6dada; }

.border-r-danger-light-hidden {
  border-right: 1px hidden #f6dada; }

.border-b-danger-light {
  border-bottom: 1px solid #f6dada; }

.border-b-danger-light-dotted {
  border-bottom: 1px dotted #f6dada; }

.border-b-danger-light-dashed {
  border-bottom: 1px dashed #f6dada; }

.border-b-danger-light-solid {
  border-bottom: 1px solid #f6dada; }

.border-b-danger-light-double {
  border-bottom: 1px double #f6dada; }

.border-b-danger-light-groove {
  border-bottom: 1px groove #f6dada; }

.border-b-danger-light-ridge {
  border-bottom: 1px ridge #f6dada; }

.border-b-danger-light-inset {
  border-bottom: 1px inset #f6dada; }

.border-b-danger-light-outset {
  border-bottom: 1px outset #f6dada; }

.border-b-danger-light-none {
  border-bottom: 1px none #f6dada; }

.border-b-danger-light-hidden {
  border-bottom: 1px hidden #f6dada; }

.border-l-danger-light {
  border-left: 1px solid #f6dada; }

.border-l-danger-light-dotted {
  border-left: 1px dotted #f6dada; }

.border-l-danger-light-dashed {
  border-left: 1px dashed #f6dada; }

.border-l-danger-light-solid {
  border-left: 1px solid #f6dada; }

.border-l-danger-light-double {
  border-left: 1px double #f6dada; }

.border-l-danger-light-groove {
  border-left: 1px groove #f6dada; }

.border-l-danger-light-ridge {
  border-left: 1px ridge #f6dada; }

.border-l-danger-light-inset {
  border-left: 1px inset #f6dada; }

.border-l-danger-light-outset {
  border-left: 1px outset #f6dada; }

.border-l-danger-light-none {
  border-left: 1px none #f6dada; }

.border-l-danger-light-hidden {
  border-left: 1px hidden #f6dada; }

.has-text-warning {
  color: #ffd500; }

.has-background-warning {
  background-color: #ffd500; }

.border-warning {
  border: 1px solid #ffd500; }

.border-m-warning:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ffd500;
  transform: translateY(-50%); }

.border-warning-dotted {
  border: 1px dotted #ffd500; }

.border-warning-dashed {
  border: 1px dashed #ffd500; }

.border-warning-solid {
  border: 1px solid #ffd500; }

.border-warning-double {
  border: 1px double #ffd500; }

.border-warning-groove {
  border: 1px groove #ffd500; }

.border-warning-ridge {
  border: 1px ridge #ffd500; }

.border-warning-inset {
  border: 1px inset #ffd500; }

.border-warning-outset {
  border: 1px outset #ffd500; }

.border-warning-none {
  border: 1px none #ffd500; }

.border-warning-hidden {
  border: 1px hidden #ffd500; }

.border-t-warning {
  border-top: 1px solid #ffd500; }

.border-t-warning-dotted {
  border-top: 1px dotted #ffd500; }

.border-t-warning-dashed {
  border-top: 1px dashed #ffd500; }

.border-t-warning-solid {
  border-top: 1px solid #ffd500; }

.border-t-warning-double {
  border-top: 1px double #ffd500; }

.border-t-warning-groove {
  border-top: 1px groove #ffd500; }

.border-t-warning-ridge {
  border-top: 1px ridge #ffd500; }

.border-t-warning-inset {
  border-top: 1px inset #ffd500; }

.border-t-warning-outset {
  border-top: 1px outset #ffd500; }

.border-t-warning-none {
  border-top: 1px none #ffd500; }

.border-t-warning-hidden {
  border-top: 1px hidden #ffd500; }

.border-r-warning {
  border-right: 1px solid #ffd500; }

.border-r-warning-dotted {
  border-right: 1px dotted #ffd500; }

.border-r-warning-dashed {
  border-right: 1px dashed #ffd500; }

.border-r-warning-solid {
  border-right: 1px solid #ffd500; }

.border-r-warning-double {
  border-right: 1px double #ffd500; }

.border-r-warning-groove {
  border-right: 1px groove #ffd500; }

.border-r-warning-ridge {
  border-right: 1px ridge #ffd500; }

.border-r-warning-inset {
  border-right: 1px inset #ffd500; }

.border-r-warning-outset {
  border-right: 1px outset #ffd500; }

.border-r-warning-none {
  border-right: 1px none #ffd500; }

.border-r-warning-hidden {
  border-right: 1px hidden #ffd500; }

.border-b-warning {
  border-bottom: 1px solid #ffd500; }

.border-b-warning-dotted {
  border-bottom: 1px dotted #ffd500; }

.border-b-warning-dashed {
  border-bottom: 1px dashed #ffd500; }

.border-b-warning-solid {
  border-bottom: 1px solid #ffd500; }

.border-b-warning-double {
  border-bottom: 1px double #ffd500; }

.border-b-warning-groove {
  border-bottom: 1px groove #ffd500; }

.border-b-warning-ridge {
  border-bottom: 1px ridge #ffd500; }

.border-b-warning-inset {
  border-bottom: 1px inset #ffd500; }

.border-b-warning-outset {
  border-bottom: 1px outset #ffd500; }

.border-b-warning-none {
  border-bottom: 1px none #ffd500; }

.border-b-warning-hidden {
  border-bottom: 1px hidden #ffd500; }

.border-l-warning {
  border-left: 1px solid #ffd500; }

.border-l-warning-dotted {
  border-left: 1px dotted #ffd500; }

.border-l-warning-dashed {
  border-left: 1px dashed #ffd500; }

.border-l-warning-solid {
  border-left: 1px solid #ffd500; }

.border-l-warning-double {
  border-left: 1px double #ffd500; }

.border-l-warning-groove {
  border-left: 1px groove #ffd500; }

.border-l-warning-ridge {
  border-left: 1px ridge #ffd500; }

.border-l-warning-inset {
  border-left: 1px inset #ffd500; }

.border-l-warning-outset {
  border-left: 1px outset #ffd500; }

.border-l-warning-none {
  border-left: 1px none #ffd500; }

.border-l-warning-hidden {
  border-left: 1px hidden #ffd500; }

.has-text-success {
  color: #66cc33; }

.has-background-success {
  background-color: #66cc33; }

.border-success {
  border: 1px solid #66cc33; }

.border-m-success:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #66cc33;
  transform: translateY(-50%); }

.border-success-dotted {
  border: 1px dotted #66cc33; }

.border-success-dashed {
  border: 1px dashed #66cc33; }

.border-success-solid {
  border: 1px solid #66cc33; }

.border-success-double {
  border: 1px double #66cc33; }

.border-success-groove {
  border: 1px groove #66cc33; }

.border-success-ridge {
  border: 1px ridge #66cc33; }

.border-success-inset {
  border: 1px inset #66cc33; }

.border-success-outset {
  border: 1px outset #66cc33; }

.border-success-none {
  border: 1px none #66cc33; }

.border-success-hidden {
  border: 1px hidden #66cc33; }

.border-t-success {
  border-top: 1px solid #66cc33; }

.border-t-success-dotted {
  border-top: 1px dotted #66cc33; }

.border-t-success-dashed {
  border-top: 1px dashed #66cc33; }

.border-t-success-solid {
  border-top: 1px solid #66cc33; }

.border-t-success-double {
  border-top: 1px double #66cc33; }

.border-t-success-groove {
  border-top: 1px groove #66cc33; }

.border-t-success-ridge {
  border-top: 1px ridge #66cc33; }

.border-t-success-inset {
  border-top: 1px inset #66cc33; }

.border-t-success-outset {
  border-top: 1px outset #66cc33; }

.border-t-success-none {
  border-top: 1px none #66cc33; }

.border-t-success-hidden {
  border-top: 1px hidden #66cc33; }

.border-r-success {
  border-right: 1px solid #66cc33; }

.border-r-success-dotted {
  border-right: 1px dotted #66cc33; }

.border-r-success-dashed {
  border-right: 1px dashed #66cc33; }

.border-r-success-solid {
  border-right: 1px solid #66cc33; }

.border-r-success-double {
  border-right: 1px double #66cc33; }

.border-r-success-groove {
  border-right: 1px groove #66cc33; }

.border-r-success-ridge {
  border-right: 1px ridge #66cc33; }

.border-r-success-inset {
  border-right: 1px inset #66cc33; }

.border-r-success-outset {
  border-right: 1px outset #66cc33; }

.border-r-success-none {
  border-right: 1px none #66cc33; }

.border-r-success-hidden {
  border-right: 1px hidden #66cc33; }

.border-b-success {
  border-bottom: 1px solid #66cc33; }

.border-b-success-dotted {
  border-bottom: 1px dotted #66cc33; }

.border-b-success-dashed {
  border-bottom: 1px dashed #66cc33; }

.border-b-success-solid {
  border-bottom: 1px solid #66cc33; }

.border-b-success-double {
  border-bottom: 1px double #66cc33; }

.border-b-success-groove {
  border-bottom: 1px groove #66cc33; }

.border-b-success-ridge {
  border-bottom: 1px ridge #66cc33; }

.border-b-success-inset {
  border-bottom: 1px inset #66cc33; }

.border-b-success-outset {
  border-bottom: 1px outset #66cc33; }

.border-b-success-none {
  border-bottom: 1px none #66cc33; }

.border-b-success-hidden {
  border-bottom: 1px hidden #66cc33; }

.border-l-success {
  border-left: 1px solid #66cc33; }

.border-l-success-dotted {
  border-left: 1px dotted #66cc33; }

.border-l-success-dashed {
  border-left: 1px dashed #66cc33; }

.border-l-success-solid {
  border-left: 1px solid #66cc33; }

.border-l-success-double {
  border-left: 1px double #66cc33; }

.border-l-success-groove {
  border-left: 1px groove #66cc33; }

.border-l-success-ridge {
  border-left: 1px ridge #66cc33; }

.border-l-success-inset {
  border-left: 1px inset #66cc33; }

.border-l-success-outset {
  border-left: 1px outset #66cc33; }

.border-l-success-none {
  border-left: 1px none #66cc33; }

.border-l-success-hidden {
  border-left: 1px hidden #66cc33; }

.has-text-success-light {
  color: #dbffe0; }

.has-background-success-light {
  background-color: #dbffe0; }

.border-success-light {
  border: 1px solid #dbffe0; }

.border-m-success-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dbffe0;
  transform: translateY(-50%); }

.border-success-light-dotted {
  border: 1px dotted #dbffe0; }

.border-success-light-dashed {
  border: 1px dashed #dbffe0; }

.border-success-light-solid {
  border: 1px solid #dbffe0; }

.border-success-light-double {
  border: 1px double #dbffe0; }

.border-success-light-groove {
  border: 1px groove #dbffe0; }

.border-success-light-ridge {
  border: 1px ridge #dbffe0; }

.border-success-light-inset {
  border: 1px inset #dbffe0; }

.border-success-light-outset {
  border: 1px outset #dbffe0; }

.border-success-light-none {
  border: 1px none #dbffe0; }

.border-success-light-hidden {
  border: 1px hidden #dbffe0; }

.border-t-success-light {
  border-top: 1px solid #dbffe0; }

.border-t-success-light-dotted {
  border-top: 1px dotted #dbffe0; }

.border-t-success-light-dashed {
  border-top: 1px dashed #dbffe0; }

.border-t-success-light-solid {
  border-top: 1px solid #dbffe0; }

.border-t-success-light-double {
  border-top: 1px double #dbffe0; }

.border-t-success-light-groove {
  border-top: 1px groove #dbffe0; }

.border-t-success-light-ridge {
  border-top: 1px ridge #dbffe0; }

.border-t-success-light-inset {
  border-top: 1px inset #dbffe0; }

.border-t-success-light-outset {
  border-top: 1px outset #dbffe0; }

.border-t-success-light-none {
  border-top: 1px none #dbffe0; }

.border-t-success-light-hidden {
  border-top: 1px hidden #dbffe0; }

.border-r-success-light {
  border-right: 1px solid #dbffe0; }

.border-r-success-light-dotted {
  border-right: 1px dotted #dbffe0; }

.border-r-success-light-dashed {
  border-right: 1px dashed #dbffe0; }

.border-r-success-light-solid {
  border-right: 1px solid #dbffe0; }

.border-r-success-light-double {
  border-right: 1px double #dbffe0; }

.border-r-success-light-groove {
  border-right: 1px groove #dbffe0; }

.border-r-success-light-ridge {
  border-right: 1px ridge #dbffe0; }

.border-r-success-light-inset {
  border-right: 1px inset #dbffe0; }

.border-r-success-light-outset {
  border-right: 1px outset #dbffe0; }

.border-r-success-light-none {
  border-right: 1px none #dbffe0; }

.border-r-success-light-hidden {
  border-right: 1px hidden #dbffe0; }

.border-b-success-light {
  border-bottom: 1px solid #dbffe0; }

.border-b-success-light-dotted {
  border-bottom: 1px dotted #dbffe0; }

.border-b-success-light-dashed {
  border-bottom: 1px dashed #dbffe0; }

.border-b-success-light-solid {
  border-bottom: 1px solid #dbffe0; }

.border-b-success-light-double {
  border-bottom: 1px double #dbffe0; }

.border-b-success-light-groove {
  border-bottom: 1px groove #dbffe0; }

.border-b-success-light-ridge {
  border-bottom: 1px ridge #dbffe0; }

.border-b-success-light-inset {
  border-bottom: 1px inset #dbffe0; }

.border-b-success-light-outset {
  border-bottom: 1px outset #dbffe0; }

.border-b-success-light-none {
  border-bottom: 1px none #dbffe0; }

.border-b-success-light-hidden {
  border-bottom: 1px hidden #dbffe0; }

.border-l-success-light {
  border-left: 1px solid #dbffe0; }

.border-l-success-light-dotted {
  border-left: 1px dotted #dbffe0; }

.border-l-success-light-dashed {
  border-left: 1px dashed #dbffe0; }

.border-l-success-light-solid {
  border-left: 1px solid #dbffe0; }

.border-l-success-light-double {
  border-left: 1px double #dbffe0; }

.border-l-success-light-groove {
  border-left: 1px groove #dbffe0; }

.border-l-success-light-ridge {
  border-left: 1px ridge #dbffe0; }

.border-l-success-light-inset {
  border-left: 1px inset #dbffe0; }

.border-l-success-light-outset {
  border-left: 1px outset #dbffe0; }

.border-l-success-light-none {
  border-left: 1px none #dbffe0; }

.border-l-success-light-hidden {
  border-left: 1px hidden #dbffe0; }

.has-text-info {
  color: #209fee; }

.has-background-info {
  background-color: #209fee; }

.border-info {
  border: 1px solid #209fee; }

.border-m-info:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #209fee;
  transform: translateY(-50%); }

.border-info-dotted {
  border: 1px dotted #209fee; }

.border-info-dashed {
  border: 1px dashed #209fee; }

.border-info-solid {
  border: 1px solid #209fee; }

.border-info-double {
  border: 1px double #209fee; }

.border-info-groove {
  border: 1px groove #209fee; }

.border-info-ridge {
  border: 1px ridge #209fee; }

.border-info-inset {
  border: 1px inset #209fee; }

.border-info-outset {
  border: 1px outset #209fee; }

.border-info-none {
  border: 1px none #209fee; }

.border-info-hidden {
  border: 1px hidden #209fee; }

.border-t-info {
  border-top: 1px solid #209fee; }

.border-t-info-dotted {
  border-top: 1px dotted #209fee; }

.border-t-info-dashed {
  border-top: 1px dashed #209fee; }

.border-t-info-solid {
  border-top: 1px solid #209fee; }

.border-t-info-double {
  border-top: 1px double #209fee; }

.border-t-info-groove {
  border-top: 1px groove #209fee; }

.border-t-info-ridge {
  border-top: 1px ridge #209fee; }

.border-t-info-inset {
  border-top: 1px inset #209fee; }

.border-t-info-outset {
  border-top: 1px outset #209fee; }

.border-t-info-none {
  border-top: 1px none #209fee; }

.border-t-info-hidden {
  border-top: 1px hidden #209fee; }

.border-r-info {
  border-right: 1px solid #209fee; }

.border-r-info-dotted {
  border-right: 1px dotted #209fee; }

.border-r-info-dashed {
  border-right: 1px dashed #209fee; }

.border-r-info-solid {
  border-right: 1px solid #209fee; }

.border-r-info-double {
  border-right: 1px double #209fee; }

.border-r-info-groove {
  border-right: 1px groove #209fee; }

.border-r-info-ridge {
  border-right: 1px ridge #209fee; }

.border-r-info-inset {
  border-right: 1px inset #209fee; }

.border-r-info-outset {
  border-right: 1px outset #209fee; }

.border-r-info-none {
  border-right: 1px none #209fee; }

.border-r-info-hidden {
  border-right: 1px hidden #209fee; }

.border-b-info {
  border-bottom: 1px solid #209fee; }

.border-b-info-dotted {
  border-bottom: 1px dotted #209fee; }

.border-b-info-dashed {
  border-bottom: 1px dashed #209fee; }

.border-b-info-solid {
  border-bottom: 1px solid #209fee; }

.border-b-info-double {
  border-bottom: 1px double #209fee; }

.border-b-info-groove {
  border-bottom: 1px groove #209fee; }

.border-b-info-ridge {
  border-bottom: 1px ridge #209fee; }

.border-b-info-inset {
  border-bottom: 1px inset #209fee; }

.border-b-info-outset {
  border-bottom: 1px outset #209fee; }

.border-b-info-none {
  border-bottom: 1px none #209fee; }

.border-b-info-hidden {
  border-bottom: 1px hidden #209fee; }

.border-l-info {
  border-left: 1px solid #209fee; }

.border-l-info-dotted {
  border-left: 1px dotted #209fee; }

.border-l-info-dashed {
  border-left: 1px dashed #209fee; }

.border-l-info-solid {
  border-left: 1px solid #209fee; }

.border-l-info-double {
  border-left: 1px double #209fee; }

.border-l-info-groove {
  border-left: 1px groove #209fee; }

.border-l-info-ridge {
  border-left: 1px ridge #209fee; }

.border-l-info-inset {
  border-left: 1px inset #209fee; }

.border-l-info-outset {
  border-left: 1px outset #209fee; }

.border-l-info-none {
  border-left: 1px none #209fee; }

.border-l-info-hidden {
  border-left: 1px hidden #209fee; }

.has-text-black {
  color: #0a0a0a; }

.has-background-black {
  background-color: #0a0a0a; }

.border-black {
  border: 1px solid #0a0a0a; }

.border-black-dotted {
  border: 1px dotted #0a0a0a; }

.border-black-dashed {
  border: 1px dashed #0a0a0a; }

.border-black-solid {
  border: 1px solid #0a0a0a; }

.border-black-double {
  border: 1px double #0a0a0a; }

.border-black-groove {
  border: 1px groove #0a0a0a; }

.border-black-ridge {
  border: 1px ridge #0a0a0a; }

.border-black-inset {
  border: 1px inset #0a0a0a; }

.border-black-outset {
  border: 1px outset #0a0a0a; }

.border-black-none {
  border: 1px none #0a0a0a; }

.border-black-hidden {
  border: 1px hidden #0a0a0a; }

.border-t-black {
  border-top: 1px solid #0a0a0a; }

.border-t-black-dotted {
  border-top: 1px dotted #0a0a0a; }

.border-t-black-dashed {
  border-top: 1px dashed #0a0a0a; }

.border-t-black-solid {
  border-top: 1px solid #0a0a0a; }

.border-t-black-double {
  border-top: 1px double #0a0a0a; }

.border-t-black-groove {
  border-top: 1px groove #0a0a0a; }

.border-t-black-ridge {
  border-top: 1px ridge #0a0a0a; }

.border-t-black-inset {
  border-top: 1px inset #0a0a0a; }

.border-t-black-outset {
  border-top: 1px outset #0a0a0a; }

.border-t-black-none {
  border-top: 1px none #0a0a0a; }

.border-t-black-hidden {
  border-top: 1px hidden #0a0a0a; }

.border-r-black {
  border-right: 1px solid #0a0a0a; }

.border-r-black-dotted {
  border-right: 1px dotted #0a0a0a; }

.border-r-black-dashed {
  border-right: 1px dashed #0a0a0a; }

.border-r-black-solid {
  border-right: 1px solid #0a0a0a; }

.border-r-black-double {
  border-right: 1px double #0a0a0a; }

.border-r-black-groove {
  border-right: 1px groove #0a0a0a; }

.border-r-black-ridge {
  border-right: 1px ridge #0a0a0a; }

.border-r-black-inset {
  border-right: 1px inset #0a0a0a; }

.border-r-black-outset {
  border-right: 1px outset #0a0a0a; }

.border-r-black-none {
  border-right: 1px none #0a0a0a; }

.border-r-black-hidden {
  border-right: 1px hidden #0a0a0a; }

.border-b-black {
  border-bottom: 1px solid #0a0a0a; }

.border-b-black-dotted {
  border-bottom: 1px dotted #0a0a0a; }

.border-b-black-dashed {
  border-bottom: 1px dashed #0a0a0a; }

.border-b-black-solid {
  border-bottom: 1px solid #0a0a0a; }

.border-b-black-double {
  border-bottom: 1px double #0a0a0a; }

.border-b-black-groove {
  border-bottom: 1px groove #0a0a0a; }

.border-b-black-ridge {
  border-bottom: 1px ridge #0a0a0a; }

.border-b-black-inset {
  border-bottom: 1px inset #0a0a0a; }

.border-b-black-outset {
  border-bottom: 1px outset #0a0a0a; }

.border-b-black-none {
  border-bottom: 1px none #0a0a0a; }

.border-b-black-hidden {
  border-bottom: 1px hidden #0a0a0a; }

.border-l-black {
  border-left: 1px solid #0a0a0a; }

.border-l-black-dotted {
  border-left: 1px dotted #0a0a0a; }

.border-l-black-dashed {
  border-left: 1px dashed #0a0a0a; }

.border-l-black-solid {
  border-left: 1px solid #0a0a0a; }

.border-l-black-double {
  border-left: 1px double #0a0a0a; }

.border-l-black-groove {
  border-left: 1px groove #0a0a0a; }

.border-l-black-ridge {
  border-left: 1px ridge #0a0a0a; }

.border-l-black-inset {
  border-left: 1px inset #0a0a0a; }

.border-l-black-outset {
  border-left: 1px outset #0a0a0a; }

.border-l-black-none {
  border-left: 1px none #0a0a0a; }

.border-l-black-hidden {
  border-left: 1px hidden #0a0a0a; }

.has-text-black-bis {
  color: #121212; }

.has-background-black-bis {
  background-color: #121212; }

.border-black-bis {
  border: 1px solid #121212; }

.border-black-bis-dotted {
  border: 1px dotted #121212; }

.border-black-bis-dashed {
  border: 1px dashed #121212; }

.border-black-bis-solid {
  border: 1px solid #121212; }

.border-black-bis-double {
  border: 1px double #121212; }

.border-black-bis-groove {
  border: 1px groove #121212; }

.border-black-bis-ridge {
  border: 1px ridge #121212; }

.border-black-bis-inset {
  border: 1px inset #121212; }

.border-black-bis-outset {
  border: 1px outset #121212; }

.border-black-bis-none {
  border: 1px none #121212; }

.border-black-bis-hidden {
  border: 1px hidden #121212; }

.border-t-black-bis {
  border-top: 1px solid #121212; }

.border-t-black-bis-dotted {
  border-top: 1px dotted #121212; }

.border-t-black-bis-dashed {
  border-top: 1px dashed #121212; }

.border-t-black-bis-solid {
  border-top: 1px solid #121212; }

.border-t-black-bis-double {
  border-top: 1px double #121212; }

.border-t-black-bis-groove {
  border-top: 1px groove #121212; }

.border-t-black-bis-ridge {
  border-top: 1px ridge #121212; }

.border-t-black-bis-inset {
  border-top: 1px inset #121212; }

.border-t-black-bis-outset {
  border-top: 1px outset #121212; }

.border-t-black-bis-none {
  border-top: 1px none #121212; }

.border-t-black-bis-hidden {
  border-top: 1px hidden #121212; }

.border-r-black-bis {
  border-right: 1px solid #121212; }

.border-r-black-bis-dotted {
  border-right: 1px dotted #121212; }

.border-r-black-bis-dashed {
  border-right: 1px dashed #121212; }

.border-r-black-bis-solid {
  border-right: 1px solid #121212; }

.border-r-black-bis-double {
  border-right: 1px double #121212; }

.border-r-black-bis-groove {
  border-right: 1px groove #121212; }

.border-r-black-bis-ridge {
  border-right: 1px ridge #121212; }

.border-r-black-bis-inset {
  border-right: 1px inset #121212; }

.border-r-black-bis-outset {
  border-right: 1px outset #121212; }

.border-r-black-bis-none {
  border-right: 1px none #121212; }

.border-r-black-bis-hidden {
  border-right: 1px hidden #121212; }

.border-b-black-bis {
  border-bottom: 1px solid #121212; }

.border-b-black-bis-dotted {
  border-bottom: 1px dotted #121212; }

.border-b-black-bis-dashed {
  border-bottom: 1px dashed #121212; }

.border-b-black-bis-solid {
  border-bottom: 1px solid #121212; }

.border-b-black-bis-double {
  border-bottom: 1px double #121212; }

.border-b-black-bis-groove {
  border-bottom: 1px groove #121212; }

.border-b-black-bis-ridge {
  border-bottom: 1px ridge #121212; }

.border-b-black-bis-inset {
  border-bottom: 1px inset #121212; }

.border-b-black-bis-outset {
  border-bottom: 1px outset #121212; }

.border-b-black-bis-none {
  border-bottom: 1px none #121212; }

.border-b-black-bis-hidden {
  border-bottom: 1px hidden #121212; }

.border-l-black-bis {
  border-left: 1px solid #121212; }

.border-l-black-bis-dotted {
  border-left: 1px dotted #121212; }

.border-l-black-bis-dashed {
  border-left: 1px dashed #121212; }

.border-l-black-bis-solid {
  border-left: 1px solid #121212; }

.border-l-black-bis-double {
  border-left: 1px double #121212; }

.border-l-black-bis-groove {
  border-left: 1px groove #121212; }

.border-l-black-bis-ridge {
  border-left: 1px ridge #121212; }

.border-l-black-bis-inset {
  border-left: 1px inset #121212; }

.border-l-black-bis-outset {
  border-left: 1px outset #121212; }

.border-l-black-bis-none {
  border-left: 1px none #121212; }

.border-l-black-bis-hidden {
  border-left: 1px hidden #121212; }

.has-text-black-ter {
  color: #242424; }

.has-background-black-ter {
  background-color: #242424; }

.border-black-ter {
  border: 1px solid #242424; }

.border-black-ter-dotted {
  border: 1px dotted #242424; }

.border-black-ter-dashed {
  border: 1px dashed #242424; }

.border-black-ter-solid {
  border: 1px solid #242424; }

.border-black-ter-double {
  border: 1px double #242424; }

.border-black-ter-groove {
  border: 1px groove #242424; }

.border-black-ter-ridge {
  border: 1px ridge #242424; }

.border-black-ter-inset {
  border: 1px inset #242424; }

.border-black-ter-outset {
  border: 1px outset #242424; }

.border-black-ter-none {
  border: 1px none #242424; }

.border-black-ter-hidden {
  border: 1px hidden #242424; }

.border-t-black-ter {
  border-top: 1px solid #242424; }

.border-t-black-ter-dotted {
  border-top: 1px dotted #242424; }

.border-t-black-ter-dashed {
  border-top: 1px dashed #242424; }

.border-t-black-ter-solid {
  border-top: 1px solid #242424; }

.border-t-black-ter-double {
  border-top: 1px double #242424; }

.border-t-black-ter-groove {
  border-top: 1px groove #242424; }

.border-t-black-ter-ridge {
  border-top: 1px ridge #242424; }

.border-t-black-ter-inset {
  border-top: 1px inset #242424; }

.border-t-black-ter-outset {
  border-top: 1px outset #242424; }

.border-t-black-ter-none {
  border-top: 1px none #242424; }

.border-t-black-ter-hidden {
  border-top: 1px hidden #242424; }

.border-r-black-ter {
  border-right: 1px solid #242424; }

.border-r-black-ter-dotted {
  border-right: 1px dotted #242424; }

.border-r-black-ter-dashed {
  border-right: 1px dashed #242424; }

.border-r-black-ter-solid {
  border-right: 1px solid #242424; }

.border-r-black-ter-double {
  border-right: 1px double #242424; }

.border-r-black-ter-groove {
  border-right: 1px groove #242424; }

.border-r-black-ter-ridge {
  border-right: 1px ridge #242424; }

.border-r-black-ter-inset {
  border-right: 1px inset #242424; }

.border-r-black-ter-outset {
  border-right: 1px outset #242424; }

.border-r-black-ter-none {
  border-right: 1px none #242424; }

.border-r-black-ter-hidden {
  border-right: 1px hidden #242424; }

.border-b-black-ter {
  border-bottom: 1px solid #242424; }

.border-b-black-ter-dotted {
  border-bottom: 1px dotted #242424; }

.border-b-black-ter-dashed {
  border-bottom: 1px dashed #242424; }

.border-b-black-ter-solid {
  border-bottom: 1px solid #242424; }

.border-b-black-ter-double {
  border-bottom: 1px double #242424; }

.border-b-black-ter-groove {
  border-bottom: 1px groove #242424; }

.border-b-black-ter-ridge {
  border-bottom: 1px ridge #242424; }

.border-b-black-ter-inset {
  border-bottom: 1px inset #242424; }

.border-b-black-ter-outset {
  border-bottom: 1px outset #242424; }

.border-b-black-ter-none {
  border-bottom: 1px none #242424; }

.border-b-black-ter-hidden {
  border-bottom: 1px hidden #242424; }

.border-l-black-ter {
  border-left: 1px solid #242424; }

.border-l-black-ter-dotted {
  border-left: 1px dotted #242424; }

.border-l-black-ter-dashed {
  border-left: 1px dashed #242424; }

.border-l-black-ter-solid {
  border-left: 1px solid #242424; }

.border-l-black-ter-double {
  border-left: 1px double #242424; }

.border-l-black-ter-groove {
  border-left: 1px groove #242424; }

.border-l-black-ter-ridge {
  border-left: 1px ridge #242424; }

.border-l-black-ter-inset {
  border-left: 1px inset #242424; }

.border-l-black-ter-outset {
  border-left: 1px outset #242424; }

.border-l-black-ter-none {
  border-left: 1px none #242424; }

.border-l-black-ter-hidden {
  border-left: 1px hidden #242424; }

.has-text-grey-darker {
  color: #363636; }

.has-background-grey-darker {
  background-color: #363636; }

.border-grey-darker {
  border: 1px solid #363636; }

.border-grey-darker-dotted {
  border: 1px dotted #363636; }

.border-grey-darker-dashed {
  border: 1px dashed #363636; }

.border-grey-darker-solid {
  border: 1px solid #363636; }

.border-grey-darker-double {
  border: 1px double #363636; }

.border-grey-darker-groove {
  border: 1px groove #363636; }

.border-grey-darker-ridge {
  border: 1px ridge #363636; }

.border-grey-darker-inset {
  border: 1px inset #363636; }

.border-grey-darker-outset {
  border: 1px outset #363636; }

.border-grey-darker-none {
  border: 1px none #363636; }

.border-grey-darker-hidden {
  border: 1px hidden #363636; }

.border-t-grey-darker {
  border-top: 1px solid #363636; }

.border-t-grey-darker-dotted {
  border-top: 1px dotted #363636; }

.border-t-grey-darker-dashed {
  border-top: 1px dashed #363636; }

.border-t-grey-darker-solid {
  border-top: 1px solid #363636; }

.border-t-grey-darker-double {
  border-top: 1px double #363636; }

.border-t-grey-darker-groove {
  border-top: 1px groove #363636; }

.border-t-grey-darker-ridge {
  border-top: 1px ridge #363636; }

.border-t-grey-darker-inset {
  border-top: 1px inset #363636; }

.border-t-grey-darker-outset {
  border-top: 1px outset #363636; }

.border-t-grey-darker-none {
  border-top: 1px none #363636; }

.border-t-grey-darker-hidden {
  border-top: 1px hidden #363636; }

.border-r-grey-darker {
  border-right: 1px solid #363636; }

.border-r-grey-darker-dotted {
  border-right: 1px dotted #363636; }

.border-r-grey-darker-dashed {
  border-right: 1px dashed #363636; }

.border-r-grey-darker-solid {
  border-right: 1px solid #363636; }

.border-r-grey-darker-double {
  border-right: 1px double #363636; }

.border-r-grey-darker-groove {
  border-right: 1px groove #363636; }

.border-r-grey-darker-ridge {
  border-right: 1px ridge #363636; }

.border-r-grey-darker-inset {
  border-right: 1px inset #363636; }

.border-r-grey-darker-outset {
  border-right: 1px outset #363636; }

.border-r-grey-darker-none {
  border-right: 1px none #363636; }

.border-r-grey-darker-hidden {
  border-right: 1px hidden #363636; }

.border-b-grey-darker {
  border-bottom: 1px solid #363636; }

.border-b-grey-darker-dotted {
  border-bottom: 1px dotted #363636; }

.border-b-grey-darker-dashed {
  border-bottom: 1px dashed #363636; }

.border-b-grey-darker-solid {
  border-bottom: 1px solid #363636; }

.border-b-grey-darker-double {
  border-bottom: 1px double #363636; }

.border-b-grey-darker-groove {
  border-bottom: 1px groove #363636; }

.border-b-grey-darker-ridge {
  border-bottom: 1px ridge #363636; }

.border-b-grey-darker-inset {
  border-bottom: 1px inset #363636; }

.border-b-grey-darker-outset {
  border-bottom: 1px outset #363636; }

.border-b-grey-darker-none {
  border-bottom: 1px none #363636; }

.border-b-grey-darker-hidden {
  border-bottom: 1px hidden #363636; }

.border-l-grey-darker {
  border-left: 1px solid #363636; }

.border-l-grey-darker-dotted {
  border-left: 1px dotted #363636; }

.border-l-grey-darker-dashed {
  border-left: 1px dashed #363636; }

.border-l-grey-darker-solid {
  border-left: 1px solid #363636; }

.border-l-grey-darker-double {
  border-left: 1px double #363636; }

.border-l-grey-darker-groove {
  border-left: 1px groove #363636; }

.border-l-grey-darker-ridge {
  border-left: 1px ridge #363636; }

.border-l-grey-darker-inset {
  border-left: 1px inset #363636; }

.border-l-grey-darker-outset {
  border-left: 1px outset #363636; }

.border-l-grey-darker-none {
  border-left: 1px none #363636; }

.border-l-grey-darker-hidden {
  border-left: 1px hidden #363636; }

.has-text-grey-dark {
  color: #4a4a4a; }

.has-background-grey-dark {
  background-color: #4a4a4a; }

.border-grey-dark {
  border: 1px solid #4a4a4a; }

.border-grey-dark-dotted {
  border: 1px dotted #4a4a4a; }

.border-grey-dark-dashed {
  border: 1px dashed #4a4a4a; }

.border-grey-dark-solid {
  border: 1px solid #4a4a4a; }

.border-grey-dark-double {
  border: 1px double #4a4a4a; }

.border-grey-dark-groove {
  border: 1px groove #4a4a4a; }

.border-grey-dark-ridge {
  border: 1px ridge #4a4a4a; }

.border-grey-dark-inset {
  border: 1px inset #4a4a4a; }

.border-grey-dark-outset {
  border: 1px outset #4a4a4a; }

.border-grey-dark-none {
  border: 1px none #4a4a4a; }

.border-grey-dark-hidden {
  border: 1px hidden #4a4a4a; }

.border-t-grey-dark {
  border-top: 1px solid #4a4a4a; }

.border-t-grey-dark-dotted {
  border-top: 1px dotted #4a4a4a; }

.border-t-grey-dark-dashed {
  border-top: 1px dashed #4a4a4a; }

.border-t-grey-dark-solid {
  border-top: 1px solid #4a4a4a; }

.border-t-grey-dark-double {
  border-top: 1px double #4a4a4a; }

.border-t-grey-dark-groove {
  border-top: 1px groove #4a4a4a; }

.border-t-grey-dark-ridge {
  border-top: 1px ridge #4a4a4a; }

.border-t-grey-dark-inset {
  border-top: 1px inset #4a4a4a; }

.border-t-grey-dark-outset {
  border-top: 1px outset #4a4a4a; }

.border-t-grey-dark-none {
  border-top: 1px none #4a4a4a; }

.border-t-grey-dark-hidden {
  border-top: 1px hidden #4a4a4a; }

.border-r-grey-dark {
  border-right: 1px solid #4a4a4a; }

.border-r-grey-dark-dotted {
  border-right: 1px dotted #4a4a4a; }

.border-r-grey-dark-dashed {
  border-right: 1px dashed #4a4a4a; }

.border-r-grey-dark-solid {
  border-right: 1px solid #4a4a4a; }

.border-r-grey-dark-double {
  border-right: 1px double #4a4a4a; }

.border-r-grey-dark-groove {
  border-right: 1px groove #4a4a4a; }

.border-r-grey-dark-ridge {
  border-right: 1px ridge #4a4a4a; }

.border-r-grey-dark-inset {
  border-right: 1px inset #4a4a4a; }

.border-r-grey-dark-outset {
  border-right: 1px outset #4a4a4a; }

.border-r-grey-dark-none {
  border-right: 1px none #4a4a4a; }

.border-r-grey-dark-hidden {
  border-right: 1px hidden #4a4a4a; }

.border-b-grey-dark {
  border-bottom: 1px solid #4a4a4a; }

.border-b-grey-dark-dotted {
  border-bottom: 1px dotted #4a4a4a; }

.border-b-grey-dark-dashed {
  border-bottom: 1px dashed #4a4a4a; }

.border-b-grey-dark-solid {
  border-bottom: 1px solid #4a4a4a; }

.border-b-grey-dark-double {
  border-bottom: 1px double #4a4a4a; }

.border-b-grey-dark-groove {
  border-bottom: 1px groove #4a4a4a; }

.border-b-grey-dark-ridge {
  border-bottom: 1px ridge #4a4a4a; }

.border-b-grey-dark-inset {
  border-bottom: 1px inset #4a4a4a; }

.border-b-grey-dark-outset {
  border-bottom: 1px outset #4a4a4a; }

.border-b-grey-dark-none {
  border-bottom: 1px none #4a4a4a; }

.border-b-grey-dark-hidden {
  border-bottom: 1px hidden #4a4a4a; }

.border-l-grey-dark {
  border-left: 1px solid #4a4a4a; }

.border-l-grey-dark-dotted {
  border-left: 1px dotted #4a4a4a; }

.border-l-grey-dark-dashed {
  border-left: 1px dashed #4a4a4a; }

.border-l-grey-dark-solid {
  border-left: 1px solid #4a4a4a; }

.border-l-grey-dark-double {
  border-left: 1px double #4a4a4a; }

.border-l-grey-dark-groove {
  border-left: 1px groove #4a4a4a; }

.border-l-grey-dark-ridge {
  border-left: 1px ridge #4a4a4a; }

.border-l-grey-dark-inset {
  border-left: 1px inset #4a4a4a; }

.border-l-grey-dark-outset {
  border-left: 1px outset #4a4a4a; }

.border-l-grey-dark-none {
  border-left: 1px none #4a4a4a; }

.border-l-grey-dark-hidden {
  border-left: 1px hidden #4a4a4a; }

.has-text-grey {
  color: #7a7a7a; }

.has-background-grey {
  background-color: #7a7a7a; }

.border-grey {
  border: 1px solid #7a7a7a; }

.border-grey-dotted {
  border: 1px dotted #7a7a7a; }

.border-grey-dashed {
  border: 1px dashed #7a7a7a; }

.border-grey-solid {
  border: 1px solid #7a7a7a; }

.border-grey-double {
  border: 1px double #7a7a7a; }

.border-grey-groove {
  border: 1px groove #7a7a7a; }

.border-grey-ridge {
  border: 1px ridge #7a7a7a; }

.border-grey-inset {
  border: 1px inset #7a7a7a; }

.border-grey-outset {
  border: 1px outset #7a7a7a; }

.border-grey-none {
  border: 1px none #7a7a7a; }

.border-grey-hidden {
  border: 1px hidden #7a7a7a; }

.border-t-grey {
  border-top: 1px solid #7a7a7a; }

.border-t-grey-dotted {
  border-top: 1px dotted #7a7a7a; }

.border-t-grey-dashed {
  border-top: 1px dashed #7a7a7a; }

.border-t-grey-solid {
  border-top: 1px solid #7a7a7a; }

.border-t-grey-double {
  border-top: 1px double #7a7a7a; }

.border-t-grey-groove {
  border-top: 1px groove #7a7a7a; }

.border-t-grey-ridge {
  border-top: 1px ridge #7a7a7a; }

.border-t-grey-inset {
  border-top: 1px inset #7a7a7a; }

.border-t-grey-outset {
  border-top: 1px outset #7a7a7a; }

.border-t-grey-none {
  border-top: 1px none #7a7a7a; }

.border-t-grey-hidden {
  border-top: 1px hidden #7a7a7a; }

.border-r-grey {
  border-right: 1px solid #7a7a7a; }

.border-r-grey-dotted {
  border-right: 1px dotted #7a7a7a; }

.border-r-grey-dashed {
  border-right: 1px dashed #7a7a7a; }

.border-r-grey-solid {
  border-right: 1px solid #7a7a7a; }

.border-r-grey-double {
  border-right: 1px double #7a7a7a; }

.border-r-grey-groove {
  border-right: 1px groove #7a7a7a; }

.border-r-grey-ridge {
  border-right: 1px ridge #7a7a7a; }

.border-r-grey-inset {
  border-right: 1px inset #7a7a7a; }

.border-r-grey-outset {
  border-right: 1px outset #7a7a7a; }

.border-r-grey-none {
  border-right: 1px none #7a7a7a; }

.border-r-grey-hidden {
  border-right: 1px hidden #7a7a7a; }

.border-b-grey {
  border-bottom: 1px solid #7a7a7a; }

.border-b-grey-dotted {
  border-bottom: 1px dotted #7a7a7a; }

.border-b-grey-dashed {
  border-bottom: 1px dashed #7a7a7a; }

.border-b-grey-solid {
  border-bottom: 1px solid #7a7a7a; }

.border-b-grey-double {
  border-bottom: 1px double #7a7a7a; }

.border-b-grey-groove {
  border-bottom: 1px groove #7a7a7a; }

.border-b-grey-ridge {
  border-bottom: 1px ridge #7a7a7a; }

.border-b-grey-inset {
  border-bottom: 1px inset #7a7a7a; }

.border-b-grey-outset {
  border-bottom: 1px outset #7a7a7a; }

.border-b-grey-none {
  border-bottom: 1px none #7a7a7a; }

.border-b-grey-hidden {
  border-bottom: 1px hidden #7a7a7a; }

.border-l-grey {
  border-left: 1px solid #7a7a7a; }

.border-l-grey-dotted {
  border-left: 1px dotted #7a7a7a; }

.border-l-grey-dashed {
  border-left: 1px dashed #7a7a7a; }

.border-l-grey-solid {
  border-left: 1px solid #7a7a7a; }

.border-l-grey-double {
  border-left: 1px double #7a7a7a; }

.border-l-grey-groove {
  border-left: 1px groove #7a7a7a; }

.border-l-grey-ridge {
  border-left: 1px ridge #7a7a7a; }

.border-l-grey-inset {
  border-left: 1px inset #7a7a7a; }

.border-l-grey-outset {
  border-left: 1px outset #7a7a7a; }

.border-l-grey-none {
  border-left: 1px none #7a7a7a; }

.border-l-grey-hidden {
  border-left: 1px hidden #7a7a7a; }

.has-text-grey-light {
  color: #b5b5b5; }

.has-background-grey-light {
  background-color: #b5b5b5; }

.border-grey-light {
  border: 1px solid #b5b5b5; }

.border-grey-light-dotted {
  border: 1px dotted #b5b5b5; }

.border-grey-light-dashed {
  border: 1px dashed #b5b5b5; }

.border-grey-light-solid {
  border: 1px solid #b5b5b5; }

.border-grey-light-double {
  border: 1px double #b5b5b5; }

.border-grey-light-groove {
  border: 1px groove #b5b5b5; }

.border-grey-light-ridge {
  border: 1px ridge #b5b5b5; }

.border-grey-light-inset {
  border: 1px inset #b5b5b5; }

.border-grey-light-outset {
  border: 1px outset #b5b5b5; }

.border-grey-light-none {
  border: 1px none #b5b5b5; }

.border-grey-light-hidden {
  border: 1px hidden #b5b5b5; }

.border-t-grey-light {
  border-top: 1px solid #b5b5b5; }

.border-t-grey-light-dotted {
  border-top: 1px dotted #b5b5b5; }

.border-t-grey-light-dashed {
  border-top: 1px dashed #b5b5b5; }

.border-t-grey-light-solid {
  border-top: 1px solid #b5b5b5; }

.border-t-grey-light-double {
  border-top: 1px double #b5b5b5; }

.border-t-grey-light-groove {
  border-top: 1px groove #b5b5b5; }

.border-t-grey-light-ridge {
  border-top: 1px ridge #b5b5b5; }

.border-t-grey-light-inset {
  border-top: 1px inset #b5b5b5; }

.border-t-grey-light-outset {
  border-top: 1px outset #b5b5b5; }

.border-t-grey-light-none {
  border-top: 1px none #b5b5b5; }

.border-t-grey-light-hidden {
  border-top: 1px hidden #b5b5b5; }

.border-r-grey-light {
  border-right: 1px solid #b5b5b5; }

.border-r-grey-light-dotted {
  border-right: 1px dotted #b5b5b5; }

.border-r-grey-light-dashed {
  border-right: 1px dashed #b5b5b5; }

.border-r-grey-light-solid {
  border-right: 1px solid #b5b5b5; }

.border-r-grey-light-double {
  border-right: 1px double #b5b5b5; }

.border-r-grey-light-groove {
  border-right: 1px groove #b5b5b5; }

.border-r-grey-light-ridge {
  border-right: 1px ridge #b5b5b5; }

.border-r-grey-light-inset {
  border-right: 1px inset #b5b5b5; }

.border-r-grey-light-outset {
  border-right: 1px outset #b5b5b5; }

.border-r-grey-light-none {
  border-right: 1px none #b5b5b5; }

.border-r-grey-light-hidden {
  border-right: 1px hidden #b5b5b5; }

.border-b-grey-light {
  border-bottom: 1px solid #b5b5b5; }

.border-b-grey-light-dotted {
  border-bottom: 1px dotted #b5b5b5; }

.border-b-grey-light-dashed {
  border-bottom: 1px dashed #b5b5b5; }

.border-b-grey-light-solid {
  border-bottom: 1px solid #b5b5b5; }

.border-b-grey-light-double {
  border-bottom: 1px double #b5b5b5; }

.border-b-grey-light-groove {
  border-bottom: 1px groove #b5b5b5; }

.border-b-grey-light-ridge {
  border-bottom: 1px ridge #b5b5b5; }

.border-b-grey-light-inset {
  border-bottom: 1px inset #b5b5b5; }

.border-b-grey-light-outset {
  border-bottom: 1px outset #b5b5b5; }

.border-b-grey-light-none {
  border-bottom: 1px none #b5b5b5; }

.border-b-grey-light-hidden {
  border-bottom: 1px hidden #b5b5b5; }

.border-l-grey-light {
  border-left: 1px solid #b5b5b5; }

.border-l-grey-light-dotted {
  border-left: 1px dotted #b5b5b5; }

.border-l-grey-light-dashed {
  border-left: 1px dashed #b5b5b5; }

.border-l-grey-light-solid {
  border-left: 1px solid #b5b5b5; }

.border-l-grey-light-double {
  border-left: 1px double #b5b5b5; }

.border-l-grey-light-groove {
  border-left: 1px groove #b5b5b5; }

.border-l-grey-light-ridge {
  border-left: 1px ridge #b5b5b5; }

.border-l-grey-light-inset {
  border-left: 1px inset #b5b5b5; }

.border-l-grey-light-outset {
  border-left: 1px outset #b5b5b5; }

.border-l-grey-light-none {
  border-left: 1px none #b5b5b5; }

.border-l-grey-light-hidden {
  border-left: 1px hidden #b5b5b5; }

.has-text-grey-lighter {
  color: #e6e6e6; }

.has-background-grey-lighter {
  background-color: #e6e6e6; }

.border-grey-lighter {
  border: 1px solid #e6e6e6; }

.border-grey-lighter-dotted {
  border: 1px dotted #e6e6e6; }

.border-grey-lighter-dashed {
  border: 1px dashed #e6e6e6; }

.border-grey-lighter-solid {
  border: 1px solid #e6e6e6; }

.border-grey-lighter-double {
  border: 1px double #e6e6e6; }

.border-grey-lighter-groove {
  border: 1px groove #e6e6e6; }

.border-grey-lighter-ridge {
  border: 1px ridge #e6e6e6; }

.border-grey-lighter-inset {
  border: 1px inset #e6e6e6; }

.border-grey-lighter-outset {
  border: 1px outset #e6e6e6; }

.border-grey-lighter-none {
  border: 1px none #e6e6e6; }

.border-grey-lighter-hidden {
  border: 1px hidden #e6e6e6; }

.border-t-grey-lighter {
  border-top: 1px solid #e6e6e6; }

.border-t-grey-lighter-dotted {
  border-top: 1px dotted #e6e6e6; }

.border-t-grey-lighter-dashed {
  border-top: 1px dashed #e6e6e6; }

.border-t-grey-lighter-solid {
  border-top: 1px solid #e6e6e6; }

.border-t-grey-lighter-double {
  border-top: 1px double #e6e6e6; }

.border-t-grey-lighter-groove {
  border-top: 1px groove #e6e6e6; }

.border-t-grey-lighter-ridge {
  border-top: 1px ridge #e6e6e6; }

.border-t-grey-lighter-inset {
  border-top: 1px inset #e6e6e6; }

.border-t-grey-lighter-outset {
  border-top: 1px outset #e6e6e6; }

.border-t-grey-lighter-none {
  border-top: 1px none #e6e6e6; }

.border-t-grey-lighter-hidden {
  border-top: 1px hidden #e6e6e6; }

.border-r-grey-lighter {
  border-right: 1px solid #e6e6e6; }

.border-r-grey-lighter-dotted {
  border-right: 1px dotted #e6e6e6; }

.border-r-grey-lighter-dashed {
  border-right: 1px dashed #e6e6e6; }

.border-r-grey-lighter-solid {
  border-right: 1px solid #e6e6e6; }

.border-r-grey-lighter-double {
  border-right: 1px double #e6e6e6; }

.border-r-grey-lighter-groove {
  border-right: 1px groove #e6e6e6; }

.border-r-grey-lighter-ridge {
  border-right: 1px ridge #e6e6e6; }

.border-r-grey-lighter-inset {
  border-right: 1px inset #e6e6e6; }

.border-r-grey-lighter-outset {
  border-right: 1px outset #e6e6e6; }

.border-r-grey-lighter-none {
  border-right: 1px none #e6e6e6; }

.border-r-grey-lighter-hidden {
  border-right: 1px hidden #e6e6e6; }

.border-b-grey-lighter {
  border-bottom: 1px solid #e6e6e6; }

.border-b-grey-lighter-dotted {
  border-bottom: 1px dotted #e6e6e6; }

.border-b-grey-lighter-dashed {
  border-bottom: 1px dashed #e6e6e6; }

.border-b-grey-lighter-solid {
  border-bottom: 1px solid #e6e6e6; }

.border-b-grey-lighter-double {
  border-bottom: 1px double #e6e6e6; }

.border-b-grey-lighter-groove {
  border-bottom: 1px groove #e6e6e6; }

.border-b-grey-lighter-ridge {
  border-bottom: 1px ridge #e6e6e6; }

.border-b-grey-lighter-inset {
  border-bottom: 1px inset #e6e6e6; }

.border-b-grey-lighter-outset {
  border-bottom: 1px outset #e6e6e6; }

.border-b-grey-lighter-none {
  border-bottom: 1px none #e6e6e6; }

.border-b-grey-lighter-hidden {
  border-bottom: 1px hidden #e6e6e6; }

.border-l-grey-lighter {
  border-left: 1px solid #e6e6e6; }

.border-l-grey-lighter-dotted {
  border-left: 1px dotted #e6e6e6; }

.border-l-grey-lighter-dashed {
  border-left: 1px dashed #e6e6e6; }

.border-l-grey-lighter-solid {
  border-left: 1px solid #e6e6e6; }

.border-l-grey-lighter-double {
  border-left: 1px double #e6e6e6; }

.border-l-grey-lighter-groove {
  border-left: 1px groove #e6e6e6; }

.border-l-grey-lighter-ridge {
  border-left: 1px ridge #e6e6e6; }

.border-l-grey-lighter-inset {
  border-left: 1px inset #e6e6e6; }

.border-l-grey-lighter-outset {
  border-left: 1px outset #e6e6e6; }

.border-l-grey-lighter-none {
  border-left: 1px none #e6e6e6; }

.border-l-grey-lighter-hidden {
  border-left: 1px hidden #e6e6e6; }

.has-text-white-ter {
  color: whitesmoke; }

.has-background-white-ter {
  background-color: whitesmoke; }

.border-white-ter {
  border: 1px solid whitesmoke; }

.border-white-ter-dotted {
  border: 1px dotted whitesmoke; }

.border-white-ter-dashed {
  border: 1px dashed whitesmoke; }

.border-white-ter-solid {
  border: 1px solid whitesmoke; }

.border-white-ter-double {
  border: 1px double whitesmoke; }

.border-white-ter-groove {
  border: 1px groove whitesmoke; }

.border-white-ter-ridge {
  border: 1px ridge whitesmoke; }

.border-white-ter-inset {
  border: 1px inset whitesmoke; }

.border-white-ter-outset {
  border: 1px outset whitesmoke; }

.border-white-ter-none {
  border: 1px none whitesmoke; }

.border-white-ter-hidden {
  border: 1px hidden whitesmoke; }

.border-t-white-ter {
  border-top: 1px solid whitesmoke; }

.border-t-white-ter-dotted {
  border-top: 1px dotted whitesmoke; }

.border-t-white-ter-dashed {
  border-top: 1px dashed whitesmoke; }

.border-t-white-ter-solid {
  border-top: 1px solid whitesmoke; }

.border-t-white-ter-double {
  border-top: 1px double whitesmoke; }

.border-t-white-ter-groove {
  border-top: 1px groove whitesmoke; }

.border-t-white-ter-ridge {
  border-top: 1px ridge whitesmoke; }

.border-t-white-ter-inset {
  border-top: 1px inset whitesmoke; }

.border-t-white-ter-outset {
  border-top: 1px outset whitesmoke; }

.border-t-white-ter-none {
  border-top: 1px none whitesmoke; }

.border-t-white-ter-hidden {
  border-top: 1px hidden whitesmoke; }

.border-r-white-ter {
  border-right: 1px solid whitesmoke; }

.border-r-white-ter-dotted {
  border-right: 1px dotted whitesmoke; }

.border-r-white-ter-dashed {
  border-right: 1px dashed whitesmoke; }

.border-r-white-ter-solid {
  border-right: 1px solid whitesmoke; }

.border-r-white-ter-double {
  border-right: 1px double whitesmoke; }

.border-r-white-ter-groove {
  border-right: 1px groove whitesmoke; }

.border-r-white-ter-ridge {
  border-right: 1px ridge whitesmoke; }

.border-r-white-ter-inset {
  border-right: 1px inset whitesmoke; }

.border-r-white-ter-outset {
  border-right: 1px outset whitesmoke; }

.border-r-white-ter-none {
  border-right: 1px none whitesmoke; }

.border-r-white-ter-hidden {
  border-right: 1px hidden whitesmoke; }

.border-b-white-ter {
  border-bottom: 1px solid whitesmoke; }

.border-b-white-ter-dotted {
  border-bottom: 1px dotted whitesmoke; }

.border-b-white-ter-dashed {
  border-bottom: 1px dashed whitesmoke; }

.border-b-white-ter-solid {
  border-bottom: 1px solid whitesmoke; }

.border-b-white-ter-double {
  border-bottom: 1px double whitesmoke; }

.border-b-white-ter-groove {
  border-bottom: 1px groove whitesmoke; }

.border-b-white-ter-ridge {
  border-bottom: 1px ridge whitesmoke; }

.border-b-white-ter-inset {
  border-bottom: 1px inset whitesmoke; }

.border-b-white-ter-outset {
  border-bottom: 1px outset whitesmoke; }

.border-b-white-ter-none {
  border-bottom: 1px none whitesmoke; }

.border-b-white-ter-hidden {
  border-bottom: 1px hidden whitesmoke; }

.border-l-white-ter {
  border-left: 1px solid whitesmoke; }

.border-l-white-ter-dotted {
  border-left: 1px dotted whitesmoke; }

.border-l-white-ter-dashed {
  border-left: 1px dashed whitesmoke; }

.border-l-white-ter-solid {
  border-left: 1px solid whitesmoke; }

.border-l-white-ter-double {
  border-left: 1px double whitesmoke; }

.border-l-white-ter-groove {
  border-left: 1px groove whitesmoke; }

.border-l-white-ter-ridge {
  border-left: 1px ridge whitesmoke; }

.border-l-white-ter-inset {
  border-left: 1px inset whitesmoke; }

.border-l-white-ter-outset {
  border-left: 1px outset whitesmoke; }

.border-l-white-ter-none {
  border-left: 1px none whitesmoke; }

.border-l-white-ter-hidden {
  border-left: 1px hidden whitesmoke; }

.has-text-white-bis {
  color: #fafafa; }

.has-background-white-bis {
  background-color: #fafafa; }

.border-white-bis {
  border: 1px solid #fafafa; }

.border-white-bis-dotted {
  border: 1px dotted #fafafa; }

.border-white-bis-dashed {
  border: 1px dashed #fafafa; }

.border-white-bis-solid {
  border: 1px solid #fafafa; }

.border-white-bis-double {
  border: 1px double #fafafa; }

.border-white-bis-groove {
  border: 1px groove #fafafa; }

.border-white-bis-ridge {
  border: 1px ridge #fafafa; }

.border-white-bis-inset {
  border: 1px inset #fafafa; }

.border-white-bis-outset {
  border: 1px outset #fafafa; }

.border-white-bis-none {
  border: 1px none #fafafa; }

.border-white-bis-hidden {
  border: 1px hidden #fafafa; }

.border-t-white-bis {
  border-top: 1px solid #fafafa; }

.border-t-white-bis-dotted {
  border-top: 1px dotted #fafafa; }

.border-t-white-bis-dashed {
  border-top: 1px dashed #fafafa; }

.border-t-white-bis-solid {
  border-top: 1px solid #fafafa; }

.border-t-white-bis-double {
  border-top: 1px double #fafafa; }

.border-t-white-bis-groove {
  border-top: 1px groove #fafafa; }

.border-t-white-bis-ridge {
  border-top: 1px ridge #fafafa; }

.border-t-white-bis-inset {
  border-top: 1px inset #fafafa; }

.border-t-white-bis-outset {
  border-top: 1px outset #fafafa; }

.border-t-white-bis-none {
  border-top: 1px none #fafafa; }

.border-t-white-bis-hidden {
  border-top: 1px hidden #fafafa; }

.border-r-white-bis {
  border-right: 1px solid #fafafa; }

.border-r-white-bis-dotted {
  border-right: 1px dotted #fafafa; }

.border-r-white-bis-dashed {
  border-right: 1px dashed #fafafa; }

.border-r-white-bis-solid {
  border-right: 1px solid #fafafa; }

.border-r-white-bis-double {
  border-right: 1px double #fafafa; }

.border-r-white-bis-groove {
  border-right: 1px groove #fafafa; }

.border-r-white-bis-ridge {
  border-right: 1px ridge #fafafa; }

.border-r-white-bis-inset {
  border-right: 1px inset #fafafa; }

.border-r-white-bis-outset {
  border-right: 1px outset #fafafa; }

.border-r-white-bis-none {
  border-right: 1px none #fafafa; }

.border-r-white-bis-hidden {
  border-right: 1px hidden #fafafa; }

.border-b-white-bis {
  border-bottom: 1px solid #fafafa; }

.border-b-white-bis-dotted {
  border-bottom: 1px dotted #fafafa; }

.border-b-white-bis-dashed {
  border-bottom: 1px dashed #fafafa; }

.border-b-white-bis-solid {
  border-bottom: 1px solid #fafafa; }

.border-b-white-bis-double {
  border-bottom: 1px double #fafafa; }

.border-b-white-bis-groove {
  border-bottom: 1px groove #fafafa; }

.border-b-white-bis-ridge {
  border-bottom: 1px ridge #fafafa; }

.border-b-white-bis-inset {
  border-bottom: 1px inset #fafafa; }

.border-b-white-bis-outset {
  border-bottom: 1px outset #fafafa; }

.border-b-white-bis-none {
  border-bottom: 1px none #fafafa; }

.border-b-white-bis-hidden {
  border-bottom: 1px hidden #fafafa; }

.border-l-white-bis {
  border-left: 1px solid #fafafa; }

.border-l-white-bis-dotted {
  border-left: 1px dotted #fafafa; }

.border-l-white-bis-dashed {
  border-left: 1px dashed #fafafa; }

.border-l-white-bis-solid {
  border-left: 1px solid #fafafa; }

.border-l-white-bis-double {
  border-left: 1px double #fafafa; }

.border-l-white-bis-groove {
  border-left: 1px groove #fafafa; }

.border-l-white-bis-ridge {
  border-left: 1px ridge #fafafa; }

.border-l-white-bis-inset {
  border-left: 1px inset #fafafa; }

.border-l-white-bis-outset {
  border-left: 1px outset #fafafa; }

.border-l-white-bis-none {
  border-left: 1px none #fafafa; }

.border-l-white-bis-hidden {
  border-left: 1px hidden #fafafa; }

.has-text-white {
  color: white; }

.has-background-white {
  background-color: white; }

.border-white {
  border: 1px solid white; }

.border-white-dotted {
  border: 1px dotted white; }

.border-white-dashed {
  border: 1px dashed white; }

.border-white-solid {
  border: 1px solid white; }

.border-white-double {
  border: 1px double white; }

.border-white-groove {
  border: 1px groove white; }

.border-white-ridge {
  border: 1px ridge white; }

.border-white-inset {
  border: 1px inset white; }

.border-white-outset {
  border: 1px outset white; }

.border-white-none {
  border: 1px none white; }

.border-white-hidden {
  border: 1px hidden white; }

.border-t-white {
  border-top: 1px solid white; }

.border-t-white-dotted {
  border-top: 1px dotted white; }

.border-t-white-dashed {
  border-top: 1px dashed white; }

.border-t-white-solid {
  border-top: 1px solid white; }

.border-t-white-double {
  border-top: 1px double white; }

.border-t-white-groove {
  border-top: 1px groove white; }

.border-t-white-ridge {
  border-top: 1px ridge white; }

.border-t-white-inset {
  border-top: 1px inset white; }

.border-t-white-outset {
  border-top: 1px outset white; }

.border-t-white-none {
  border-top: 1px none white; }

.border-t-white-hidden {
  border-top: 1px hidden white; }

.border-r-white {
  border-right: 1px solid white; }

.border-r-white-dotted {
  border-right: 1px dotted white; }

.border-r-white-dashed {
  border-right: 1px dashed white; }

.border-r-white-solid {
  border-right: 1px solid white; }

.border-r-white-double {
  border-right: 1px double white; }

.border-r-white-groove {
  border-right: 1px groove white; }

.border-r-white-ridge {
  border-right: 1px ridge white; }

.border-r-white-inset {
  border-right: 1px inset white; }

.border-r-white-outset {
  border-right: 1px outset white; }

.border-r-white-none {
  border-right: 1px none white; }

.border-r-white-hidden {
  border-right: 1px hidden white; }

.border-b-white {
  border-bottom: 1px solid white; }

.border-b-white-dotted {
  border-bottom: 1px dotted white; }

.border-b-white-dashed {
  border-bottom: 1px dashed white; }

.border-b-white-solid {
  border-bottom: 1px solid white; }

.border-b-white-double {
  border-bottom: 1px double white; }

.border-b-white-groove {
  border-bottom: 1px groove white; }

.border-b-white-ridge {
  border-bottom: 1px ridge white; }

.border-b-white-inset {
  border-bottom: 1px inset white; }

.border-b-white-outset {
  border-bottom: 1px outset white; }

.border-b-white-none {
  border-bottom: 1px none white; }

.border-b-white-hidden {
  border-bottom: 1px hidden white; }

.border-l-white {
  border-left: 1px solid white; }

.border-l-white-dotted {
  border-left: 1px dotted white; }

.border-l-white-dashed {
  border-left: 1px dashed white; }

.border-l-white-solid {
  border-left: 1px solid white; }

.border-l-white-double {
  border-left: 1px double white; }

.border-l-white-groove {
  border-left: 1px groove white; }

.border-l-white-ridge {
  border-left: 1px ridge white; }

.border-l-white-inset {
  border-left: 1px inset white; }

.border-l-white-outset {
  border-left: 1px outset white; }

.border-l-white-none {
  border-left: 1px none white; }

.border-l-white-hidden {
  border-left: 1px hidden white; }

.webchat_link {
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.125rem; }
  .webchat_link.has-icon:hover {
    border-radius: 50%;
    background: whitesmoke; }
  .webchat_link.has-icon:focus, .webchat_link.has-icon:active {
    border-radius: 50%;
    background: #e6e6e6; }

.text-link {
  color: inherit; }

.m-none {
  margin: 0; }

.p-none {
  padding: 0; }

.m-t-none {
  margin-top: 0; }

.p-t-none {
  padding-top: 0; }

.m-r-none {
  margin-right: 0; }

.p-r-none {
  padding-right: 0; }

.m-b-none {
  margin-bottom: 0; }

.p-b-none {
  padding-bottom: 0; }

.m-l-none {
  margin-left: 0; }

.p-l-none {
  padding-left: 0; }

.m-xxs {
  margin: 0.125rem; }

.p-xxs {
  padding: 0.125rem; }

.m-t-xxs {
  margin-top: 0.125rem; }

.p-t-xxs {
  padding-top: 0.125rem; }

.m-r-xxs {
  margin-right: 0.125rem; }

.p-r-xxs {
  padding-right: 0.125rem; }

.m-b-xxs {
  margin-bottom: 0.125rem; }

.p-b-xxs {
  padding-bottom: 0.125rem; }

.m-l-xxs {
  margin-left: 0.125rem; }

.p-l-xxs {
  padding-left: 0.125rem; }

.m-xs {
  margin: 0.25rem; }

.p-xs {
  padding: 0.25rem; }

.m-t-xs {
  margin-top: 0.25rem; }

.p-t-xs {
  padding-top: 0.25rem; }

.m-r-xs {
  margin-right: 0.25rem; }

.p-r-xs {
  padding-right: 0.25rem; }

.m-b-xs {
  margin-bottom: 0.25rem; }

.p-b-xs {
  padding-bottom: 0.25rem; }

.m-l-xs {
  margin-left: 0.25rem; }

.p-l-xs {
  padding-left: 0.25rem; }

.m-sm {
  margin: 0.6rem; }

.p-sm {
  padding: 0.6rem; }

.m-t-sm {
  margin-top: 0.6rem; }

.p-t-sm {
  padding-top: 0.6rem; }

.m-r-sm {
  margin-right: 0.6rem; }

.p-r-sm {
  padding-right: 0.6rem; }

.m-b-sm {
  margin-bottom: 0.6rem; }

.p-b-sm {
  padding-bottom: 0.6rem; }

.m-l-sm {
  margin-left: 0.6rem; }

.p-l-sm {
  padding-left: 0.6rem; }

.m-md {
  margin: 1rem; }

.p-md {
  padding: 1rem; }

.m-t-md {
  margin-top: 1rem; }

.p-t-md {
  padding-top: 1rem; }

.m-r-md {
  margin-right: 1rem; }

.p-r-md {
  padding-right: 1rem; }

.m-b-md {
  margin-bottom: 1rem; }

.p-b-md {
  padding-bottom: 1rem; }

.m-l-md {
  margin-left: 1rem; }

.p-l-md {
  padding-left: 1rem; }

.m-lg {
  margin: 2rem; }

.p-lg {
  padding: 2rem; }

.m-t-lg {
  margin-top: 2rem; }

.p-t-lg {
  padding-top: 2rem; }

.m-r-lg {
  margin-right: 2rem; }

.p-r-lg {
  padding-right: 2rem; }

.m-b-lg {
  margin-bottom: 2rem; }

.p-b-lg {
  padding-bottom: 2rem; }

.m-l-lg {
  margin-left: 2rem; }

.p-l-lg {
  padding-left: 2rem; }

.m-xl {
  margin: 4rem; }

.p-xl {
  padding: 4rem; }

.m-t-xl {
  margin-top: 4rem; }

.p-t-xl {
  padding-top: 4rem; }

.m-r-xl {
  margin-right: 4rem; }

.p-r-xl {
  padding-right: 4rem; }

.m-b-xl {
  margin-bottom: 4rem; }

.p-b-xl {
  padding-bottom: 4rem; }

.m-l-xl {
  margin-left: 4rem; }

.p-l-xl {
  padding-left: 4rem; }

.m-xxl {
  margin: 8rem; }

.p-xxl {
  padding: 8rem; }

.m-t-xxl {
  margin-top: 8rem; }

.p-t-xxl {
  padding-top: 8rem; }

.m-r-xxl {
  margin-right: 8rem; }

.p-r-xxl {
  padding-right: 8rem; }

.m-b-xxl {
  margin-bottom: 8rem; }

.p-b-xxl {
  padding-bottom: 8rem; }

.m-l-xxl {
  margin-left: 8rem; }

.p-l-xxl {
  padding-left: 8rem; }

.has-text-primary {
  color: #2d2f7b; }

.has-background-primary {
  background-color: #2d2f7b; }

.border-primary {
  border: 1px solid #2d2f7b; }

.border-m-primary:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #2d2f7b;
  transform: translateY(-50%); }

.border-primary-dotted {
  border: 1px dotted #2d2f7b; }

.border-primary-dashed {
  border: 1px dashed #2d2f7b; }

.border-primary-solid {
  border: 1px solid #2d2f7b; }

.border-primary-double {
  border: 1px double #2d2f7b; }

.border-primary-groove {
  border: 1px groove #2d2f7b; }

.border-primary-ridge {
  border: 1px ridge #2d2f7b; }

.border-primary-inset {
  border: 1px inset #2d2f7b; }

.border-primary-outset {
  border: 1px outset #2d2f7b; }

.border-primary-none {
  border: 1px none #2d2f7b; }

.border-primary-hidden {
  border: 1px hidden #2d2f7b; }

.border-t-primary {
  border-top: 1px solid #2d2f7b; }

.border-t-primary-dotted {
  border-top: 1px dotted #2d2f7b; }

.border-t-primary-dashed {
  border-top: 1px dashed #2d2f7b; }

.border-t-primary-solid {
  border-top: 1px solid #2d2f7b; }

.border-t-primary-double {
  border-top: 1px double #2d2f7b; }

.border-t-primary-groove {
  border-top: 1px groove #2d2f7b; }

.border-t-primary-ridge {
  border-top: 1px ridge #2d2f7b; }

.border-t-primary-inset {
  border-top: 1px inset #2d2f7b; }

.border-t-primary-outset {
  border-top: 1px outset #2d2f7b; }

.border-t-primary-none {
  border-top: 1px none #2d2f7b; }

.border-t-primary-hidden {
  border-top: 1px hidden #2d2f7b; }

.border-r-primary {
  border-right: 1px solid #2d2f7b; }

.border-r-primary-dotted {
  border-right: 1px dotted #2d2f7b; }

.border-r-primary-dashed {
  border-right: 1px dashed #2d2f7b; }

.border-r-primary-solid {
  border-right: 1px solid #2d2f7b; }

.border-r-primary-double {
  border-right: 1px double #2d2f7b; }

.border-r-primary-groove {
  border-right: 1px groove #2d2f7b; }

.border-r-primary-ridge {
  border-right: 1px ridge #2d2f7b; }

.border-r-primary-inset {
  border-right: 1px inset #2d2f7b; }

.border-r-primary-outset {
  border-right: 1px outset #2d2f7b; }

.border-r-primary-none {
  border-right: 1px none #2d2f7b; }

.border-r-primary-hidden {
  border-right: 1px hidden #2d2f7b; }

.border-b-primary {
  border-bottom: 1px solid #2d2f7b; }

.border-b-primary-dotted {
  border-bottom: 1px dotted #2d2f7b; }

.border-b-primary-dashed {
  border-bottom: 1px dashed #2d2f7b; }

.border-b-primary-solid {
  border-bottom: 1px solid #2d2f7b; }

.border-b-primary-double {
  border-bottom: 1px double #2d2f7b; }

.border-b-primary-groove {
  border-bottom: 1px groove #2d2f7b; }

.border-b-primary-ridge {
  border-bottom: 1px ridge #2d2f7b; }

.border-b-primary-inset {
  border-bottom: 1px inset #2d2f7b; }

.border-b-primary-outset {
  border-bottom: 1px outset #2d2f7b; }

.border-b-primary-none {
  border-bottom: 1px none #2d2f7b; }

.border-b-primary-hidden {
  border-bottom: 1px hidden #2d2f7b; }

.border-l-primary {
  border-left: 1px solid #2d2f7b; }

.border-l-primary-dotted {
  border-left: 1px dotted #2d2f7b; }

.border-l-primary-dashed {
  border-left: 1px dashed #2d2f7b; }

.border-l-primary-solid {
  border-left: 1px solid #2d2f7b; }

.border-l-primary-double {
  border-left: 1px double #2d2f7b; }

.border-l-primary-groove {
  border-left: 1px groove #2d2f7b; }

.border-l-primary-ridge {
  border-left: 1px ridge #2d2f7b; }

.border-l-primary-inset {
  border-left: 1px inset #2d2f7b; }

.border-l-primary-outset {
  border-left: 1px outset #2d2f7b; }

.border-l-primary-none {
  border-left: 1px none #2d2f7b; }

.border-l-primary-hidden {
  border-left: 1px hidden #2d2f7b; }

.has-text-primary-bis {
  color: #4d4db3; }

.has-background-primary-bis {
  background-color: #4d4db3; }

.border-primary-bis {
  border: 1px solid #4d4db3; }

.border-m-primary-bis:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #4d4db3;
  transform: translateY(-50%); }

.border-primary-bis-dotted {
  border: 1px dotted #4d4db3; }

.border-primary-bis-dashed {
  border: 1px dashed #4d4db3; }

.border-primary-bis-solid {
  border: 1px solid #4d4db3; }

.border-primary-bis-double {
  border: 1px double #4d4db3; }

.border-primary-bis-groove {
  border: 1px groove #4d4db3; }

.border-primary-bis-ridge {
  border: 1px ridge #4d4db3; }

.border-primary-bis-inset {
  border: 1px inset #4d4db3; }

.border-primary-bis-outset {
  border: 1px outset #4d4db3; }

.border-primary-bis-none {
  border: 1px none #4d4db3; }

.border-primary-bis-hidden {
  border: 1px hidden #4d4db3; }

.border-t-primary-bis {
  border-top: 1px solid #4d4db3; }

.border-t-primary-bis-dotted {
  border-top: 1px dotted #4d4db3; }

.border-t-primary-bis-dashed {
  border-top: 1px dashed #4d4db3; }

.border-t-primary-bis-solid {
  border-top: 1px solid #4d4db3; }

.border-t-primary-bis-double {
  border-top: 1px double #4d4db3; }

.border-t-primary-bis-groove {
  border-top: 1px groove #4d4db3; }

.border-t-primary-bis-ridge {
  border-top: 1px ridge #4d4db3; }

.border-t-primary-bis-inset {
  border-top: 1px inset #4d4db3; }

.border-t-primary-bis-outset {
  border-top: 1px outset #4d4db3; }

.border-t-primary-bis-none {
  border-top: 1px none #4d4db3; }

.border-t-primary-bis-hidden {
  border-top: 1px hidden #4d4db3; }

.border-r-primary-bis {
  border-right: 1px solid #4d4db3; }

.border-r-primary-bis-dotted {
  border-right: 1px dotted #4d4db3; }

.border-r-primary-bis-dashed {
  border-right: 1px dashed #4d4db3; }

.border-r-primary-bis-solid {
  border-right: 1px solid #4d4db3; }

.border-r-primary-bis-double {
  border-right: 1px double #4d4db3; }

.border-r-primary-bis-groove {
  border-right: 1px groove #4d4db3; }

.border-r-primary-bis-ridge {
  border-right: 1px ridge #4d4db3; }

.border-r-primary-bis-inset {
  border-right: 1px inset #4d4db3; }

.border-r-primary-bis-outset {
  border-right: 1px outset #4d4db3; }

.border-r-primary-bis-none {
  border-right: 1px none #4d4db3; }

.border-r-primary-bis-hidden {
  border-right: 1px hidden #4d4db3; }

.border-b-primary-bis {
  border-bottom: 1px solid #4d4db3; }

.border-b-primary-bis-dotted {
  border-bottom: 1px dotted #4d4db3; }

.border-b-primary-bis-dashed {
  border-bottom: 1px dashed #4d4db3; }

.border-b-primary-bis-solid {
  border-bottom: 1px solid #4d4db3; }

.border-b-primary-bis-double {
  border-bottom: 1px double #4d4db3; }

.border-b-primary-bis-groove {
  border-bottom: 1px groove #4d4db3; }

.border-b-primary-bis-ridge {
  border-bottom: 1px ridge #4d4db3; }

.border-b-primary-bis-inset {
  border-bottom: 1px inset #4d4db3; }

.border-b-primary-bis-outset {
  border-bottom: 1px outset #4d4db3; }

.border-b-primary-bis-none {
  border-bottom: 1px none #4d4db3; }

.border-b-primary-bis-hidden {
  border-bottom: 1px hidden #4d4db3; }

.border-l-primary-bis {
  border-left: 1px solid #4d4db3; }

.border-l-primary-bis-dotted {
  border-left: 1px dotted #4d4db3; }

.border-l-primary-bis-dashed {
  border-left: 1px dashed #4d4db3; }

.border-l-primary-bis-solid {
  border-left: 1px solid #4d4db3; }

.border-l-primary-bis-double {
  border-left: 1px double #4d4db3; }

.border-l-primary-bis-groove {
  border-left: 1px groove #4d4db3; }

.border-l-primary-bis-ridge {
  border-left: 1px ridge #4d4db3; }

.border-l-primary-bis-inset {
  border-left: 1px inset #4d4db3; }

.border-l-primary-bis-outset {
  border-left: 1px outset #4d4db3; }

.border-l-primary-bis-none {
  border-left: 1px none #4d4db3; }

.border-l-primary-bis-hidden {
  border-left: 1px hidden #4d4db3; }

.has-text-primary-ter {
  color: #7a7ab8; }

.has-background-primary-ter {
  background-color: #7a7ab8; }

.border-primary-ter {
  border: 1px solid #7a7ab8; }

.border-m-primary-ter:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #7a7ab8;
  transform: translateY(-50%); }

.border-primary-ter-dotted {
  border: 1px dotted #7a7ab8; }

.border-primary-ter-dashed {
  border: 1px dashed #7a7ab8; }

.border-primary-ter-solid {
  border: 1px solid #7a7ab8; }

.border-primary-ter-double {
  border: 1px double #7a7ab8; }

.border-primary-ter-groove {
  border: 1px groove #7a7ab8; }

.border-primary-ter-ridge {
  border: 1px ridge #7a7ab8; }

.border-primary-ter-inset {
  border: 1px inset #7a7ab8; }

.border-primary-ter-outset {
  border: 1px outset #7a7ab8; }

.border-primary-ter-none {
  border: 1px none #7a7ab8; }

.border-primary-ter-hidden {
  border: 1px hidden #7a7ab8; }

.border-t-primary-ter {
  border-top: 1px solid #7a7ab8; }

.border-t-primary-ter-dotted {
  border-top: 1px dotted #7a7ab8; }

.border-t-primary-ter-dashed {
  border-top: 1px dashed #7a7ab8; }

.border-t-primary-ter-solid {
  border-top: 1px solid #7a7ab8; }

.border-t-primary-ter-double {
  border-top: 1px double #7a7ab8; }

.border-t-primary-ter-groove {
  border-top: 1px groove #7a7ab8; }

.border-t-primary-ter-ridge {
  border-top: 1px ridge #7a7ab8; }

.border-t-primary-ter-inset {
  border-top: 1px inset #7a7ab8; }

.border-t-primary-ter-outset {
  border-top: 1px outset #7a7ab8; }

.border-t-primary-ter-none {
  border-top: 1px none #7a7ab8; }

.border-t-primary-ter-hidden {
  border-top: 1px hidden #7a7ab8; }

.border-r-primary-ter {
  border-right: 1px solid #7a7ab8; }

.border-r-primary-ter-dotted {
  border-right: 1px dotted #7a7ab8; }

.border-r-primary-ter-dashed {
  border-right: 1px dashed #7a7ab8; }

.border-r-primary-ter-solid {
  border-right: 1px solid #7a7ab8; }

.border-r-primary-ter-double {
  border-right: 1px double #7a7ab8; }

.border-r-primary-ter-groove {
  border-right: 1px groove #7a7ab8; }

.border-r-primary-ter-ridge {
  border-right: 1px ridge #7a7ab8; }

.border-r-primary-ter-inset {
  border-right: 1px inset #7a7ab8; }

.border-r-primary-ter-outset {
  border-right: 1px outset #7a7ab8; }

.border-r-primary-ter-none {
  border-right: 1px none #7a7ab8; }

.border-r-primary-ter-hidden {
  border-right: 1px hidden #7a7ab8; }

.border-b-primary-ter {
  border-bottom: 1px solid #7a7ab8; }

.border-b-primary-ter-dotted {
  border-bottom: 1px dotted #7a7ab8; }

.border-b-primary-ter-dashed {
  border-bottom: 1px dashed #7a7ab8; }

.border-b-primary-ter-solid {
  border-bottom: 1px solid #7a7ab8; }

.border-b-primary-ter-double {
  border-bottom: 1px double #7a7ab8; }

.border-b-primary-ter-groove {
  border-bottom: 1px groove #7a7ab8; }

.border-b-primary-ter-ridge {
  border-bottom: 1px ridge #7a7ab8; }

.border-b-primary-ter-inset {
  border-bottom: 1px inset #7a7ab8; }

.border-b-primary-ter-outset {
  border-bottom: 1px outset #7a7ab8; }

.border-b-primary-ter-none {
  border-bottom: 1px none #7a7ab8; }

.border-b-primary-ter-hidden {
  border-bottom: 1px hidden #7a7ab8; }

.border-l-primary-ter {
  border-left: 1px solid #7a7ab8; }

.border-l-primary-ter-dotted {
  border-left: 1px dotted #7a7ab8; }

.border-l-primary-ter-dashed {
  border-left: 1px dashed #7a7ab8; }

.border-l-primary-ter-solid {
  border-left: 1px solid #7a7ab8; }

.border-l-primary-ter-double {
  border-left: 1px double #7a7ab8; }

.border-l-primary-ter-groove {
  border-left: 1px groove #7a7ab8; }

.border-l-primary-ter-ridge {
  border-left: 1px ridge #7a7ab8; }

.border-l-primary-ter-inset {
  border-left: 1px inset #7a7ab8; }

.border-l-primary-ter-outset {
  border-left: 1px outset #7a7ab8; }

.border-l-primary-ter-none {
  border-left: 1px none #7a7ab8; }

.border-l-primary-ter-hidden {
  border-left: 1px hidden #7a7ab8; }

.has-text-primary-qua {
  color: #b9b9e9; }

.has-background-primary-qua {
  background-color: #b9b9e9; }

.border-primary-qua {
  border: 1px solid #b9b9e9; }

.border-m-primary-qua:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b9b9e9;
  transform: translateY(-50%); }

.border-primary-qua-dotted {
  border: 1px dotted #b9b9e9; }

.border-primary-qua-dashed {
  border: 1px dashed #b9b9e9; }

.border-primary-qua-solid {
  border: 1px solid #b9b9e9; }

.border-primary-qua-double {
  border: 1px double #b9b9e9; }

.border-primary-qua-groove {
  border: 1px groove #b9b9e9; }

.border-primary-qua-ridge {
  border: 1px ridge #b9b9e9; }

.border-primary-qua-inset {
  border: 1px inset #b9b9e9; }

.border-primary-qua-outset {
  border: 1px outset #b9b9e9; }

.border-primary-qua-none {
  border: 1px none #b9b9e9; }

.border-primary-qua-hidden {
  border: 1px hidden #b9b9e9; }

.border-t-primary-qua {
  border-top: 1px solid #b9b9e9; }

.border-t-primary-qua-dotted {
  border-top: 1px dotted #b9b9e9; }

.border-t-primary-qua-dashed {
  border-top: 1px dashed #b9b9e9; }

.border-t-primary-qua-solid {
  border-top: 1px solid #b9b9e9; }

.border-t-primary-qua-double {
  border-top: 1px double #b9b9e9; }

.border-t-primary-qua-groove {
  border-top: 1px groove #b9b9e9; }

.border-t-primary-qua-ridge {
  border-top: 1px ridge #b9b9e9; }

.border-t-primary-qua-inset {
  border-top: 1px inset #b9b9e9; }

.border-t-primary-qua-outset {
  border-top: 1px outset #b9b9e9; }

.border-t-primary-qua-none {
  border-top: 1px none #b9b9e9; }

.border-t-primary-qua-hidden {
  border-top: 1px hidden #b9b9e9; }

.border-r-primary-qua {
  border-right: 1px solid #b9b9e9; }

.border-r-primary-qua-dotted {
  border-right: 1px dotted #b9b9e9; }

.border-r-primary-qua-dashed {
  border-right: 1px dashed #b9b9e9; }

.border-r-primary-qua-solid {
  border-right: 1px solid #b9b9e9; }

.border-r-primary-qua-double {
  border-right: 1px double #b9b9e9; }

.border-r-primary-qua-groove {
  border-right: 1px groove #b9b9e9; }

.border-r-primary-qua-ridge {
  border-right: 1px ridge #b9b9e9; }

.border-r-primary-qua-inset {
  border-right: 1px inset #b9b9e9; }

.border-r-primary-qua-outset {
  border-right: 1px outset #b9b9e9; }

.border-r-primary-qua-none {
  border-right: 1px none #b9b9e9; }

.border-r-primary-qua-hidden {
  border-right: 1px hidden #b9b9e9; }

.border-b-primary-qua {
  border-bottom: 1px solid #b9b9e9; }

.border-b-primary-qua-dotted {
  border-bottom: 1px dotted #b9b9e9; }

.border-b-primary-qua-dashed {
  border-bottom: 1px dashed #b9b9e9; }

.border-b-primary-qua-solid {
  border-bottom: 1px solid #b9b9e9; }

.border-b-primary-qua-double {
  border-bottom: 1px double #b9b9e9; }

.border-b-primary-qua-groove {
  border-bottom: 1px groove #b9b9e9; }

.border-b-primary-qua-ridge {
  border-bottom: 1px ridge #b9b9e9; }

.border-b-primary-qua-inset {
  border-bottom: 1px inset #b9b9e9; }

.border-b-primary-qua-outset {
  border-bottom: 1px outset #b9b9e9; }

.border-b-primary-qua-none {
  border-bottom: 1px none #b9b9e9; }

.border-b-primary-qua-hidden {
  border-bottom: 1px hidden #b9b9e9; }

.border-l-primary-qua {
  border-left: 1px solid #b9b9e9; }

.border-l-primary-qua-dotted {
  border-left: 1px dotted #b9b9e9; }

.border-l-primary-qua-dashed {
  border-left: 1px dashed #b9b9e9; }

.border-l-primary-qua-solid {
  border-left: 1px solid #b9b9e9; }

.border-l-primary-qua-double {
  border-left: 1px double #b9b9e9; }

.border-l-primary-qua-groove {
  border-left: 1px groove #b9b9e9; }

.border-l-primary-qua-ridge {
  border-left: 1px ridge #b9b9e9; }

.border-l-primary-qua-inset {
  border-left: 1px inset #b9b9e9; }

.border-l-primary-qua-outset {
  border-left: 1px outset #b9b9e9; }

.border-l-primary-qua-none {
  border-left: 1px none #b9b9e9; }

.border-l-primary-qua-hidden {
  border-left: 1px hidden #b9b9e9; }

.has-text-primary-qui {
  color: #b8b8d6; }

.has-background-primary-qui {
  background-color: #b8b8d6; }

.border-primary-qui {
  border: 1px solid #b8b8d6; }

.border-m-primary-qui:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b8b8d6;
  transform: translateY(-50%); }

.border-primary-qui-dotted {
  border: 1px dotted #b8b8d6; }

.border-primary-qui-dashed {
  border: 1px dashed #b8b8d6; }

.border-primary-qui-solid {
  border: 1px solid #b8b8d6; }

.border-primary-qui-double {
  border: 1px double #b8b8d6; }

.border-primary-qui-groove {
  border: 1px groove #b8b8d6; }

.border-primary-qui-ridge {
  border: 1px ridge #b8b8d6; }

.border-primary-qui-inset {
  border: 1px inset #b8b8d6; }

.border-primary-qui-outset {
  border: 1px outset #b8b8d6; }

.border-primary-qui-none {
  border: 1px none #b8b8d6; }

.border-primary-qui-hidden {
  border: 1px hidden #b8b8d6; }

.border-t-primary-qui {
  border-top: 1px solid #b8b8d6; }

.border-t-primary-qui-dotted {
  border-top: 1px dotted #b8b8d6; }

.border-t-primary-qui-dashed {
  border-top: 1px dashed #b8b8d6; }

.border-t-primary-qui-solid {
  border-top: 1px solid #b8b8d6; }

.border-t-primary-qui-double {
  border-top: 1px double #b8b8d6; }

.border-t-primary-qui-groove {
  border-top: 1px groove #b8b8d6; }

.border-t-primary-qui-ridge {
  border-top: 1px ridge #b8b8d6; }

.border-t-primary-qui-inset {
  border-top: 1px inset #b8b8d6; }

.border-t-primary-qui-outset {
  border-top: 1px outset #b8b8d6; }

.border-t-primary-qui-none {
  border-top: 1px none #b8b8d6; }

.border-t-primary-qui-hidden {
  border-top: 1px hidden #b8b8d6; }

.border-r-primary-qui {
  border-right: 1px solid #b8b8d6; }

.border-r-primary-qui-dotted {
  border-right: 1px dotted #b8b8d6; }

.border-r-primary-qui-dashed {
  border-right: 1px dashed #b8b8d6; }

.border-r-primary-qui-solid {
  border-right: 1px solid #b8b8d6; }

.border-r-primary-qui-double {
  border-right: 1px double #b8b8d6; }

.border-r-primary-qui-groove {
  border-right: 1px groove #b8b8d6; }

.border-r-primary-qui-ridge {
  border-right: 1px ridge #b8b8d6; }

.border-r-primary-qui-inset {
  border-right: 1px inset #b8b8d6; }

.border-r-primary-qui-outset {
  border-right: 1px outset #b8b8d6; }

.border-r-primary-qui-none {
  border-right: 1px none #b8b8d6; }

.border-r-primary-qui-hidden {
  border-right: 1px hidden #b8b8d6; }

.border-b-primary-qui {
  border-bottom: 1px solid #b8b8d6; }

.border-b-primary-qui-dotted {
  border-bottom: 1px dotted #b8b8d6; }

.border-b-primary-qui-dashed {
  border-bottom: 1px dashed #b8b8d6; }

.border-b-primary-qui-solid {
  border-bottom: 1px solid #b8b8d6; }

.border-b-primary-qui-double {
  border-bottom: 1px double #b8b8d6; }

.border-b-primary-qui-groove {
  border-bottom: 1px groove #b8b8d6; }

.border-b-primary-qui-ridge {
  border-bottom: 1px ridge #b8b8d6; }

.border-b-primary-qui-inset {
  border-bottom: 1px inset #b8b8d6; }

.border-b-primary-qui-outset {
  border-bottom: 1px outset #b8b8d6; }

.border-b-primary-qui-none {
  border-bottom: 1px none #b8b8d6; }

.border-b-primary-qui-hidden {
  border-bottom: 1px hidden #b8b8d6; }

.border-l-primary-qui {
  border-left: 1px solid #b8b8d6; }

.border-l-primary-qui-dotted {
  border-left: 1px dotted #b8b8d6; }

.border-l-primary-qui-dashed {
  border-left: 1px dashed #b8b8d6; }

.border-l-primary-qui-solid {
  border-left: 1px solid #b8b8d6; }

.border-l-primary-qui-double {
  border-left: 1px double #b8b8d6; }

.border-l-primary-qui-groove {
  border-left: 1px groove #b8b8d6; }

.border-l-primary-qui-ridge {
  border-left: 1px ridge #b8b8d6; }

.border-l-primary-qui-inset {
  border-left: 1px inset #b8b8d6; }

.border-l-primary-qui-outset {
  border-left: 1px outset #b8b8d6; }

.border-l-primary-qui-none {
  border-left: 1px none #b8b8d6; }

.border-l-primary-qui-hidden {
  border-left: 1px hidden #b8b8d6; }

.has-text-primary-light {
  color: #dbdbf0; }

.has-background-primary-light {
  background-color: #dbdbf0; }

.border-primary-light {
  border: 1px solid #dbdbf0; }

.border-m-primary-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dbdbf0;
  transform: translateY(-50%); }

.border-primary-light-dotted {
  border: 1px dotted #dbdbf0; }

.border-primary-light-dashed {
  border: 1px dashed #dbdbf0; }

.border-primary-light-solid {
  border: 1px solid #dbdbf0; }

.border-primary-light-double {
  border: 1px double #dbdbf0; }

.border-primary-light-groove {
  border: 1px groove #dbdbf0; }

.border-primary-light-ridge {
  border: 1px ridge #dbdbf0; }

.border-primary-light-inset {
  border: 1px inset #dbdbf0; }

.border-primary-light-outset {
  border: 1px outset #dbdbf0; }

.border-primary-light-none {
  border: 1px none #dbdbf0; }

.border-primary-light-hidden {
  border: 1px hidden #dbdbf0; }

.border-t-primary-light {
  border-top: 1px solid #dbdbf0; }

.border-t-primary-light-dotted {
  border-top: 1px dotted #dbdbf0; }

.border-t-primary-light-dashed {
  border-top: 1px dashed #dbdbf0; }

.border-t-primary-light-solid {
  border-top: 1px solid #dbdbf0; }

.border-t-primary-light-double {
  border-top: 1px double #dbdbf0; }

.border-t-primary-light-groove {
  border-top: 1px groove #dbdbf0; }

.border-t-primary-light-ridge {
  border-top: 1px ridge #dbdbf0; }

.border-t-primary-light-inset {
  border-top: 1px inset #dbdbf0; }

.border-t-primary-light-outset {
  border-top: 1px outset #dbdbf0; }

.border-t-primary-light-none {
  border-top: 1px none #dbdbf0; }

.border-t-primary-light-hidden {
  border-top: 1px hidden #dbdbf0; }

.border-r-primary-light {
  border-right: 1px solid #dbdbf0; }

.border-r-primary-light-dotted {
  border-right: 1px dotted #dbdbf0; }

.border-r-primary-light-dashed {
  border-right: 1px dashed #dbdbf0; }

.border-r-primary-light-solid {
  border-right: 1px solid #dbdbf0; }

.border-r-primary-light-double {
  border-right: 1px double #dbdbf0; }

.border-r-primary-light-groove {
  border-right: 1px groove #dbdbf0; }

.border-r-primary-light-ridge {
  border-right: 1px ridge #dbdbf0; }

.border-r-primary-light-inset {
  border-right: 1px inset #dbdbf0; }

.border-r-primary-light-outset {
  border-right: 1px outset #dbdbf0; }

.border-r-primary-light-none {
  border-right: 1px none #dbdbf0; }

.border-r-primary-light-hidden {
  border-right: 1px hidden #dbdbf0; }

.border-b-primary-light {
  border-bottom: 1px solid #dbdbf0; }

.border-b-primary-light-dotted {
  border-bottom: 1px dotted #dbdbf0; }

.border-b-primary-light-dashed {
  border-bottom: 1px dashed #dbdbf0; }

.border-b-primary-light-solid {
  border-bottom: 1px solid #dbdbf0; }

.border-b-primary-light-double {
  border-bottom: 1px double #dbdbf0; }

.border-b-primary-light-groove {
  border-bottom: 1px groove #dbdbf0; }

.border-b-primary-light-ridge {
  border-bottom: 1px ridge #dbdbf0; }

.border-b-primary-light-inset {
  border-bottom: 1px inset #dbdbf0; }

.border-b-primary-light-outset {
  border-bottom: 1px outset #dbdbf0; }

.border-b-primary-light-none {
  border-bottom: 1px none #dbdbf0; }

.border-b-primary-light-hidden {
  border-bottom: 1px hidden #dbdbf0; }

.border-l-primary-light {
  border-left: 1px solid #dbdbf0; }

.border-l-primary-light-dotted {
  border-left: 1px dotted #dbdbf0; }

.border-l-primary-light-dashed {
  border-left: 1px dashed #dbdbf0; }

.border-l-primary-light-solid {
  border-left: 1px solid #dbdbf0; }

.border-l-primary-light-double {
  border-left: 1px double #dbdbf0; }

.border-l-primary-light-groove {
  border-left: 1px groove #dbdbf0; }

.border-l-primary-light-ridge {
  border-left: 1px ridge #dbdbf0; }

.border-l-primary-light-inset {
  border-left: 1px inset #dbdbf0; }

.border-l-primary-light-outset {
  border-left: 1px outset #dbdbf0; }

.border-l-primary-light-none {
  border-left: 1px none #dbdbf0; }

.border-l-primary-light-hidden {
  border-left: 1px hidden #dbdbf0; }

.has-text-primary-lighter {
  color: #ededf7; }

.has-background-primary-lighter {
  background-color: #ededf7; }

.border-primary-lighter {
  border: 1px solid #ededf7; }

.border-m-primary-lighter:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ededf7;
  transform: translateY(-50%); }

.border-primary-lighter-dotted {
  border: 1px dotted #ededf7; }

.border-primary-lighter-dashed {
  border: 1px dashed #ededf7; }

.border-primary-lighter-solid {
  border: 1px solid #ededf7; }

.border-primary-lighter-double {
  border: 1px double #ededf7; }

.border-primary-lighter-groove {
  border: 1px groove #ededf7; }

.border-primary-lighter-ridge {
  border: 1px ridge #ededf7; }

.border-primary-lighter-inset {
  border: 1px inset #ededf7; }

.border-primary-lighter-outset {
  border: 1px outset #ededf7; }

.border-primary-lighter-none {
  border: 1px none #ededf7; }

.border-primary-lighter-hidden {
  border: 1px hidden #ededf7; }

.border-t-primary-lighter {
  border-top: 1px solid #ededf7; }

.border-t-primary-lighter-dotted {
  border-top: 1px dotted #ededf7; }

.border-t-primary-lighter-dashed {
  border-top: 1px dashed #ededf7; }

.border-t-primary-lighter-solid {
  border-top: 1px solid #ededf7; }

.border-t-primary-lighter-double {
  border-top: 1px double #ededf7; }

.border-t-primary-lighter-groove {
  border-top: 1px groove #ededf7; }

.border-t-primary-lighter-ridge {
  border-top: 1px ridge #ededf7; }

.border-t-primary-lighter-inset {
  border-top: 1px inset #ededf7; }

.border-t-primary-lighter-outset {
  border-top: 1px outset #ededf7; }

.border-t-primary-lighter-none {
  border-top: 1px none #ededf7; }

.border-t-primary-lighter-hidden {
  border-top: 1px hidden #ededf7; }

.border-r-primary-lighter {
  border-right: 1px solid #ededf7; }

.border-r-primary-lighter-dotted {
  border-right: 1px dotted #ededf7; }

.border-r-primary-lighter-dashed {
  border-right: 1px dashed #ededf7; }

.border-r-primary-lighter-solid {
  border-right: 1px solid #ededf7; }

.border-r-primary-lighter-double {
  border-right: 1px double #ededf7; }

.border-r-primary-lighter-groove {
  border-right: 1px groove #ededf7; }

.border-r-primary-lighter-ridge {
  border-right: 1px ridge #ededf7; }

.border-r-primary-lighter-inset {
  border-right: 1px inset #ededf7; }

.border-r-primary-lighter-outset {
  border-right: 1px outset #ededf7; }

.border-r-primary-lighter-none {
  border-right: 1px none #ededf7; }

.border-r-primary-lighter-hidden {
  border-right: 1px hidden #ededf7; }

.border-b-primary-lighter {
  border-bottom: 1px solid #ededf7; }

.border-b-primary-lighter-dotted {
  border-bottom: 1px dotted #ededf7; }

.border-b-primary-lighter-dashed {
  border-bottom: 1px dashed #ededf7; }

.border-b-primary-lighter-solid {
  border-bottom: 1px solid #ededf7; }

.border-b-primary-lighter-double {
  border-bottom: 1px double #ededf7; }

.border-b-primary-lighter-groove {
  border-bottom: 1px groove #ededf7; }

.border-b-primary-lighter-ridge {
  border-bottom: 1px ridge #ededf7; }

.border-b-primary-lighter-inset {
  border-bottom: 1px inset #ededf7; }

.border-b-primary-lighter-outset {
  border-bottom: 1px outset #ededf7; }

.border-b-primary-lighter-none {
  border-bottom: 1px none #ededf7; }

.border-b-primary-lighter-hidden {
  border-bottom: 1px hidden #ededf7; }

.border-l-primary-lighter {
  border-left: 1px solid #ededf7; }

.border-l-primary-lighter-dotted {
  border-left: 1px dotted #ededf7; }

.border-l-primary-lighter-dashed {
  border-left: 1px dashed #ededf7; }

.border-l-primary-lighter-solid {
  border-left: 1px solid #ededf7; }

.border-l-primary-lighter-double {
  border-left: 1px double #ededf7; }

.border-l-primary-lighter-groove {
  border-left: 1px groove #ededf7; }

.border-l-primary-lighter-ridge {
  border-left: 1px ridge #ededf7; }

.border-l-primary-lighter-inset {
  border-left: 1px inset #ededf7; }

.border-l-primary-lighter-outset {
  border-left: 1px outset #ededf7; }

.border-l-primary-lighter-none {
  border-left: 1px none #ededf7; }

.border-l-primary-lighter-hidden {
  border-left: 1px hidden #ededf7; }

.has-text-primary-lighbis {
  color: #f4f4fa; }

.has-background-primary-lighbis {
  background-color: #f4f4fa; }

.border-primary-lighbis {
  border: 1px solid #f4f4fa; }

.border-m-primary-lighbis:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f4f4fa;
  transform: translateY(-50%); }

.border-primary-lighbis-dotted {
  border: 1px dotted #f4f4fa; }

.border-primary-lighbis-dashed {
  border: 1px dashed #f4f4fa; }

.border-primary-lighbis-solid {
  border: 1px solid #f4f4fa; }

.border-primary-lighbis-double {
  border: 1px double #f4f4fa; }

.border-primary-lighbis-groove {
  border: 1px groove #f4f4fa; }

.border-primary-lighbis-ridge {
  border: 1px ridge #f4f4fa; }

.border-primary-lighbis-inset {
  border: 1px inset #f4f4fa; }

.border-primary-lighbis-outset {
  border: 1px outset #f4f4fa; }

.border-primary-lighbis-none {
  border: 1px none #f4f4fa; }

.border-primary-lighbis-hidden {
  border: 1px hidden #f4f4fa; }

.border-t-primary-lighbis {
  border-top: 1px solid #f4f4fa; }

.border-t-primary-lighbis-dotted {
  border-top: 1px dotted #f4f4fa; }

.border-t-primary-lighbis-dashed {
  border-top: 1px dashed #f4f4fa; }

.border-t-primary-lighbis-solid {
  border-top: 1px solid #f4f4fa; }

.border-t-primary-lighbis-double {
  border-top: 1px double #f4f4fa; }

.border-t-primary-lighbis-groove {
  border-top: 1px groove #f4f4fa; }

.border-t-primary-lighbis-ridge {
  border-top: 1px ridge #f4f4fa; }

.border-t-primary-lighbis-inset {
  border-top: 1px inset #f4f4fa; }

.border-t-primary-lighbis-outset {
  border-top: 1px outset #f4f4fa; }

.border-t-primary-lighbis-none {
  border-top: 1px none #f4f4fa; }

.border-t-primary-lighbis-hidden {
  border-top: 1px hidden #f4f4fa; }

.border-r-primary-lighbis {
  border-right: 1px solid #f4f4fa; }

.border-r-primary-lighbis-dotted {
  border-right: 1px dotted #f4f4fa; }

.border-r-primary-lighbis-dashed {
  border-right: 1px dashed #f4f4fa; }

.border-r-primary-lighbis-solid {
  border-right: 1px solid #f4f4fa; }

.border-r-primary-lighbis-double {
  border-right: 1px double #f4f4fa; }

.border-r-primary-lighbis-groove {
  border-right: 1px groove #f4f4fa; }

.border-r-primary-lighbis-ridge {
  border-right: 1px ridge #f4f4fa; }

.border-r-primary-lighbis-inset {
  border-right: 1px inset #f4f4fa; }

.border-r-primary-lighbis-outset {
  border-right: 1px outset #f4f4fa; }

.border-r-primary-lighbis-none {
  border-right: 1px none #f4f4fa; }

.border-r-primary-lighbis-hidden {
  border-right: 1px hidden #f4f4fa; }

.border-b-primary-lighbis {
  border-bottom: 1px solid #f4f4fa; }

.border-b-primary-lighbis-dotted {
  border-bottom: 1px dotted #f4f4fa; }

.border-b-primary-lighbis-dashed {
  border-bottom: 1px dashed #f4f4fa; }

.border-b-primary-lighbis-solid {
  border-bottom: 1px solid #f4f4fa; }

.border-b-primary-lighbis-double {
  border-bottom: 1px double #f4f4fa; }

.border-b-primary-lighbis-groove {
  border-bottom: 1px groove #f4f4fa; }

.border-b-primary-lighbis-ridge {
  border-bottom: 1px ridge #f4f4fa; }

.border-b-primary-lighbis-inset {
  border-bottom: 1px inset #f4f4fa; }

.border-b-primary-lighbis-outset {
  border-bottom: 1px outset #f4f4fa; }

.border-b-primary-lighbis-none {
  border-bottom: 1px none #f4f4fa; }

.border-b-primary-lighbis-hidden {
  border-bottom: 1px hidden #f4f4fa; }

.border-l-primary-lighbis {
  border-left: 1px solid #f4f4fa; }

.border-l-primary-lighbis-dotted {
  border-left: 1px dotted #f4f4fa; }

.border-l-primary-lighbis-dashed {
  border-left: 1px dashed #f4f4fa; }

.border-l-primary-lighbis-solid {
  border-left: 1px solid #f4f4fa; }

.border-l-primary-lighbis-double {
  border-left: 1px double #f4f4fa; }

.border-l-primary-lighbis-groove {
  border-left: 1px groove #f4f4fa; }

.border-l-primary-lighbis-ridge {
  border-left: 1px ridge #f4f4fa; }

.border-l-primary-lighbis-inset {
  border-left: 1px inset #f4f4fa; }

.border-l-primary-lighbis-outset {
  border-left: 1px outset #f4f4fa; }

.border-l-primary-lighbis-none {
  border-left: 1px none #f4f4fa; }

.border-l-primary-lighbis-hidden {
  border-left: 1px hidden #f4f4fa; }

.has-text-secondary {
  color: #eb6666; }

.has-background-secondary {
  background-color: #eb6666; }

.border-secondary {
  border: 1px solid #eb6666; }

.border-m-secondary:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #eb6666;
  transform: translateY(-50%); }

.border-secondary-dotted {
  border: 1px dotted #eb6666; }

.border-secondary-dashed {
  border: 1px dashed #eb6666; }

.border-secondary-solid {
  border: 1px solid #eb6666; }

.border-secondary-double {
  border: 1px double #eb6666; }

.border-secondary-groove {
  border: 1px groove #eb6666; }

.border-secondary-ridge {
  border: 1px ridge #eb6666; }

.border-secondary-inset {
  border: 1px inset #eb6666; }

.border-secondary-outset {
  border: 1px outset #eb6666; }

.border-secondary-none {
  border: 1px none #eb6666; }

.border-secondary-hidden {
  border: 1px hidden #eb6666; }

.border-t-secondary {
  border-top: 1px solid #eb6666; }

.border-t-secondary-dotted {
  border-top: 1px dotted #eb6666; }

.border-t-secondary-dashed {
  border-top: 1px dashed #eb6666; }

.border-t-secondary-solid {
  border-top: 1px solid #eb6666; }

.border-t-secondary-double {
  border-top: 1px double #eb6666; }

.border-t-secondary-groove {
  border-top: 1px groove #eb6666; }

.border-t-secondary-ridge {
  border-top: 1px ridge #eb6666; }

.border-t-secondary-inset {
  border-top: 1px inset #eb6666; }

.border-t-secondary-outset {
  border-top: 1px outset #eb6666; }

.border-t-secondary-none {
  border-top: 1px none #eb6666; }

.border-t-secondary-hidden {
  border-top: 1px hidden #eb6666; }

.border-r-secondary {
  border-right: 1px solid #eb6666; }

.border-r-secondary-dotted {
  border-right: 1px dotted #eb6666; }

.border-r-secondary-dashed {
  border-right: 1px dashed #eb6666; }

.border-r-secondary-solid {
  border-right: 1px solid #eb6666; }

.border-r-secondary-double {
  border-right: 1px double #eb6666; }

.border-r-secondary-groove {
  border-right: 1px groove #eb6666; }

.border-r-secondary-ridge {
  border-right: 1px ridge #eb6666; }

.border-r-secondary-inset {
  border-right: 1px inset #eb6666; }

.border-r-secondary-outset {
  border-right: 1px outset #eb6666; }

.border-r-secondary-none {
  border-right: 1px none #eb6666; }

.border-r-secondary-hidden {
  border-right: 1px hidden #eb6666; }

.border-b-secondary {
  border-bottom: 1px solid #eb6666; }

.border-b-secondary-dotted {
  border-bottom: 1px dotted #eb6666; }

.border-b-secondary-dashed {
  border-bottom: 1px dashed #eb6666; }

.border-b-secondary-solid {
  border-bottom: 1px solid #eb6666; }

.border-b-secondary-double {
  border-bottom: 1px double #eb6666; }

.border-b-secondary-groove {
  border-bottom: 1px groove #eb6666; }

.border-b-secondary-ridge {
  border-bottom: 1px ridge #eb6666; }

.border-b-secondary-inset {
  border-bottom: 1px inset #eb6666; }

.border-b-secondary-outset {
  border-bottom: 1px outset #eb6666; }

.border-b-secondary-none {
  border-bottom: 1px none #eb6666; }

.border-b-secondary-hidden {
  border-bottom: 1px hidden #eb6666; }

.border-l-secondary {
  border-left: 1px solid #eb6666; }

.border-l-secondary-dotted {
  border-left: 1px dotted #eb6666; }

.border-l-secondary-dashed {
  border-left: 1px dashed #eb6666; }

.border-l-secondary-solid {
  border-left: 1px solid #eb6666; }

.border-l-secondary-double {
  border-left: 1px double #eb6666; }

.border-l-secondary-groove {
  border-left: 1px groove #eb6666; }

.border-l-secondary-ridge {
  border-left: 1px ridge #eb6666; }

.border-l-secondary-inset {
  border-left: 1px inset #eb6666; }

.border-l-secondary-outset {
  border-left: 1px outset #eb6666; }

.border-l-secondary-none {
  border-left: 1px none #eb6666; }

.border-l-secondary-hidden {
  border-left: 1px hidden #eb6666; }

.has-text-danger {
  color: #cc3333; }

.has-background-danger {
  background-color: #cc3333; }

.border-danger {
  border: 1px solid #cc3333; }

.border-m-danger:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #cc3333;
  transform: translateY(-50%); }

.border-danger-dotted {
  border: 1px dotted #cc3333; }

.border-danger-dashed {
  border: 1px dashed #cc3333; }

.border-danger-solid {
  border: 1px solid #cc3333; }

.border-danger-double {
  border: 1px double #cc3333; }

.border-danger-groove {
  border: 1px groove #cc3333; }

.border-danger-ridge {
  border: 1px ridge #cc3333; }

.border-danger-inset {
  border: 1px inset #cc3333; }

.border-danger-outset {
  border: 1px outset #cc3333; }

.border-danger-none {
  border: 1px none #cc3333; }

.border-danger-hidden {
  border: 1px hidden #cc3333; }

.border-t-danger {
  border-top: 1px solid #cc3333; }

.border-t-danger-dotted {
  border-top: 1px dotted #cc3333; }

.border-t-danger-dashed {
  border-top: 1px dashed #cc3333; }

.border-t-danger-solid {
  border-top: 1px solid #cc3333; }

.border-t-danger-double {
  border-top: 1px double #cc3333; }

.border-t-danger-groove {
  border-top: 1px groove #cc3333; }

.border-t-danger-ridge {
  border-top: 1px ridge #cc3333; }

.border-t-danger-inset {
  border-top: 1px inset #cc3333; }

.border-t-danger-outset {
  border-top: 1px outset #cc3333; }

.border-t-danger-none {
  border-top: 1px none #cc3333; }

.border-t-danger-hidden {
  border-top: 1px hidden #cc3333; }

.border-r-danger {
  border-right: 1px solid #cc3333; }

.border-r-danger-dotted {
  border-right: 1px dotted #cc3333; }

.border-r-danger-dashed {
  border-right: 1px dashed #cc3333; }

.border-r-danger-solid {
  border-right: 1px solid #cc3333; }

.border-r-danger-double {
  border-right: 1px double #cc3333; }

.border-r-danger-groove {
  border-right: 1px groove #cc3333; }

.border-r-danger-ridge {
  border-right: 1px ridge #cc3333; }

.border-r-danger-inset {
  border-right: 1px inset #cc3333; }

.border-r-danger-outset {
  border-right: 1px outset #cc3333; }

.border-r-danger-none {
  border-right: 1px none #cc3333; }

.border-r-danger-hidden {
  border-right: 1px hidden #cc3333; }

.border-b-danger {
  border-bottom: 1px solid #cc3333; }

.border-b-danger-dotted {
  border-bottom: 1px dotted #cc3333; }

.border-b-danger-dashed {
  border-bottom: 1px dashed #cc3333; }

.border-b-danger-solid {
  border-bottom: 1px solid #cc3333; }

.border-b-danger-double {
  border-bottom: 1px double #cc3333; }

.border-b-danger-groove {
  border-bottom: 1px groove #cc3333; }

.border-b-danger-ridge {
  border-bottom: 1px ridge #cc3333; }

.border-b-danger-inset {
  border-bottom: 1px inset #cc3333; }

.border-b-danger-outset {
  border-bottom: 1px outset #cc3333; }

.border-b-danger-none {
  border-bottom: 1px none #cc3333; }

.border-b-danger-hidden {
  border-bottom: 1px hidden #cc3333; }

.border-l-danger {
  border-left: 1px solid #cc3333; }

.border-l-danger-dotted {
  border-left: 1px dotted #cc3333; }

.border-l-danger-dashed {
  border-left: 1px dashed #cc3333; }

.border-l-danger-solid {
  border-left: 1px solid #cc3333; }

.border-l-danger-double {
  border-left: 1px double #cc3333; }

.border-l-danger-groove {
  border-left: 1px groove #cc3333; }

.border-l-danger-ridge {
  border-left: 1px ridge #cc3333; }

.border-l-danger-inset {
  border-left: 1px inset #cc3333; }

.border-l-danger-outset {
  border-left: 1px outset #cc3333; }

.border-l-danger-none {
  border-left: 1px none #cc3333; }

.border-l-danger-hidden {
  border-left: 1px hidden #cc3333; }

.has-text-danger-light {
  color: #f6dada; }

.has-background-danger-light {
  background-color: #f6dada; }

.border-danger-light {
  border: 1px solid #f6dada; }

.border-m-danger-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #f6dada;
  transform: translateY(-50%); }

.border-danger-light-dotted {
  border: 1px dotted #f6dada; }

.border-danger-light-dashed {
  border: 1px dashed #f6dada; }

.border-danger-light-solid {
  border: 1px solid #f6dada; }

.border-danger-light-double {
  border: 1px double #f6dada; }

.border-danger-light-groove {
  border: 1px groove #f6dada; }

.border-danger-light-ridge {
  border: 1px ridge #f6dada; }

.border-danger-light-inset {
  border: 1px inset #f6dada; }

.border-danger-light-outset {
  border: 1px outset #f6dada; }

.border-danger-light-none {
  border: 1px none #f6dada; }

.border-danger-light-hidden {
  border: 1px hidden #f6dada; }

.border-t-danger-light {
  border-top: 1px solid #f6dada; }

.border-t-danger-light-dotted {
  border-top: 1px dotted #f6dada; }

.border-t-danger-light-dashed {
  border-top: 1px dashed #f6dada; }

.border-t-danger-light-solid {
  border-top: 1px solid #f6dada; }

.border-t-danger-light-double {
  border-top: 1px double #f6dada; }

.border-t-danger-light-groove {
  border-top: 1px groove #f6dada; }

.border-t-danger-light-ridge {
  border-top: 1px ridge #f6dada; }

.border-t-danger-light-inset {
  border-top: 1px inset #f6dada; }

.border-t-danger-light-outset {
  border-top: 1px outset #f6dada; }

.border-t-danger-light-none {
  border-top: 1px none #f6dada; }

.border-t-danger-light-hidden {
  border-top: 1px hidden #f6dada; }

.border-r-danger-light {
  border-right: 1px solid #f6dada; }

.border-r-danger-light-dotted {
  border-right: 1px dotted #f6dada; }

.border-r-danger-light-dashed {
  border-right: 1px dashed #f6dada; }

.border-r-danger-light-solid {
  border-right: 1px solid #f6dada; }

.border-r-danger-light-double {
  border-right: 1px double #f6dada; }

.border-r-danger-light-groove {
  border-right: 1px groove #f6dada; }

.border-r-danger-light-ridge {
  border-right: 1px ridge #f6dada; }

.border-r-danger-light-inset {
  border-right: 1px inset #f6dada; }

.border-r-danger-light-outset {
  border-right: 1px outset #f6dada; }

.border-r-danger-light-none {
  border-right: 1px none #f6dada; }

.border-r-danger-light-hidden {
  border-right: 1px hidden #f6dada; }

.border-b-danger-light {
  border-bottom: 1px solid #f6dada; }

.border-b-danger-light-dotted {
  border-bottom: 1px dotted #f6dada; }

.border-b-danger-light-dashed {
  border-bottom: 1px dashed #f6dada; }

.border-b-danger-light-solid {
  border-bottom: 1px solid #f6dada; }

.border-b-danger-light-double {
  border-bottom: 1px double #f6dada; }

.border-b-danger-light-groove {
  border-bottom: 1px groove #f6dada; }

.border-b-danger-light-ridge {
  border-bottom: 1px ridge #f6dada; }

.border-b-danger-light-inset {
  border-bottom: 1px inset #f6dada; }

.border-b-danger-light-outset {
  border-bottom: 1px outset #f6dada; }

.border-b-danger-light-none {
  border-bottom: 1px none #f6dada; }

.border-b-danger-light-hidden {
  border-bottom: 1px hidden #f6dada; }

.border-l-danger-light {
  border-left: 1px solid #f6dada; }

.border-l-danger-light-dotted {
  border-left: 1px dotted #f6dada; }

.border-l-danger-light-dashed {
  border-left: 1px dashed #f6dada; }

.border-l-danger-light-solid {
  border-left: 1px solid #f6dada; }

.border-l-danger-light-double {
  border-left: 1px double #f6dada; }

.border-l-danger-light-groove {
  border-left: 1px groove #f6dada; }

.border-l-danger-light-ridge {
  border-left: 1px ridge #f6dada; }

.border-l-danger-light-inset {
  border-left: 1px inset #f6dada; }

.border-l-danger-light-outset {
  border-left: 1px outset #f6dada; }

.border-l-danger-light-none {
  border-left: 1px none #f6dada; }

.border-l-danger-light-hidden {
  border-left: 1px hidden #f6dada; }

.has-text-warning {
  color: #ffd500; }

.has-background-warning {
  background-color: #ffd500; }

.border-warning {
  border: 1px solid #ffd500; }

.border-m-warning:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ffd500;
  transform: translateY(-50%); }

.border-warning-dotted {
  border: 1px dotted #ffd500; }

.border-warning-dashed {
  border: 1px dashed #ffd500; }

.border-warning-solid {
  border: 1px solid #ffd500; }

.border-warning-double {
  border: 1px double #ffd500; }

.border-warning-groove {
  border: 1px groove #ffd500; }

.border-warning-ridge {
  border: 1px ridge #ffd500; }

.border-warning-inset {
  border: 1px inset #ffd500; }

.border-warning-outset {
  border: 1px outset #ffd500; }

.border-warning-none {
  border: 1px none #ffd500; }

.border-warning-hidden {
  border: 1px hidden #ffd500; }

.border-t-warning {
  border-top: 1px solid #ffd500; }

.border-t-warning-dotted {
  border-top: 1px dotted #ffd500; }

.border-t-warning-dashed {
  border-top: 1px dashed #ffd500; }

.border-t-warning-solid {
  border-top: 1px solid #ffd500; }

.border-t-warning-double {
  border-top: 1px double #ffd500; }

.border-t-warning-groove {
  border-top: 1px groove #ffd500; }

.border-t-warning-ridge {
  border-top: 1px ridge #ffd500; }

.border-t-warning-inset {
  border-top: 1px inset #ffd500; }

.border-t-warning-outset {
  border-top: 1px outset #ffd500; }

.border-t-warning-none {
  border-top: 1px none #ffd500; }

.border-t-warning-hidden {
  border-top: 1px hidden #ffd500; }

.border-r-warning {
  border-right: 1px solid #ffd500; }

.border-r-warning-dotted {
  border-right: 1px dotted #ffd500; }

.border-r-warning-dashed {
  border-right: 1px dashed #ffd500; }

.border-r-warning-solid {
  border-right: 1px solid #ffd500; }

.border-r-warning-double {
  border-right: 1px double #ffd500; }

.border-r-warning-groove {
  border-right: 1px groove #ffd500; }

.border-r-warning-ridge {
  border-right: 1px ridge #ffd500; }

.border-r-warning-inset {
  border-right: 1px inset #ffd500; }

.border-r-warning-outset {
  border-right: 1px outset #ffd500; }

.border-r-warning-none {
  border-right: 1px none #ffd500; }

.border-r-warning-hidden {
  border-right: 1px hidden #ffd500; }

.border-b-warning {
  border-bottom: 1px solid #ffd500; }

.border-b-warning-dotted {
  border-bottom: 1px dotted #ffd500; }

.border-b-warning-dashed {
  border-bottom: 1px dashed #ffd500; }

.border-b-warning-solid {
  border-bottom: 1px solid #ffd500; }

.border-b-warning-double {
  border-bottom: 1px double #ffd500; }

.border-b-warning-groove {
  border-bottom: 1px groove #ffd500; }

.border-b-warning-ridge {
  border-bottom: 1px ridge #ffd500; }

.border-b-warning-inset {
  border-bottom: 1px inset #ffd500; }

.border-b-warning-outset {
  border-bottom: 1px outset #ffd500; }

.border-b-warning-none {
  border-bottom: 1px none #ffd500; }

.border-b-warning-hidden {
  border-bottom: 1px hidden #ffd500; }

.border-l-warning {
  border-left: 1px solid #ffd500; }

.border-l-warning-dotted {
  border-left: 1px dotted #ffd500; }

.border-l-warning-dashed {
  border-left: 1px dashed #ffd500; }

.border-l-warning-solid {
  border-left: 1px solid #ffd500; }

.border-l-warning-double {
  border-left: 1px double #ffd500; }

.border-l-warning-groove {
  border-left: 1px groove #ffd500; }

.border-l-warning-ridge {
  border-left: 1px ridge #ffd500; }

.border-l-warning-inset {
  border-left: 1px inset #ffd500; }

.border-l-warning-outset {
  border-left: 1px outset #ffd500; }

.border-l-warning-none {
  border-left: 1px none #ffd500; }

.border-l-warning-hidden {
  border-left: 1px hidden #ffd500; }

.has-text-success {
  color: #66cc33; }

.has-background-success {
  background-color: #66cc33; }

.border-success {
  border: 1px solid #66cc33; }

.border-m-success:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #66cc33;
  transform: translateY(-50%); }

.border-success-dotted {
  border: 1px dotted #66cc33; }

.border-success-dashed {
  border: 1px dashed #66cc33; }

.border-success-solid {
  border: 1px solid #66cc33; }

.border-success-double {
  border: 1px double #66cc33; }

.border-success-groove {
  border: 1px groove #66cc33; }

.border-success-ridge {
  border: 1px ridge #66cc33; }

.border-success-inset {
  border: 1px inset #66cc33; }

.border-success-outset {
  border: 1px outset #66cc33; }

.border-success-none {
  border: 1px none #66cc33; }

.border-success-hidden {
  border: 1px hidden #66cc33; }

.border-t-success {
  border-top: 1px solid #66cc33; }

.border-t-success-dotted {
  border-top: 1px dotted #66cc33; }

.border-t-success-dashed {
  border-top: 1px dashed #66cc33; }

.border-t-success-solid {
  border-top: 1px solid #66cc33; }

.border-t-success-double {
  border-top: 1px double #66cc33; }

.border-t-success-groove {
  border-top: 1px groove #66cc33; }

.border-t-success-ridge {
  border-top: 1px ridge #66cc33; }

.border-t-success-inset {
  border-top: 1px inset #66cc33; }

.border-t-success-outset {
  border-top: 1px outset #66cc33; }

.border-t-success-none {
  border-top: 1px none #66cc33; }

.border-t-success-hidden {
  border-top: 1px hidden #66cc33; }

.border-r-success {
  border-right: 1px solid #66cc33; }

.border-r-success-dotted {
  border-right: 1px dotted #66cc33; }

.border-r-success-dashed {
  border-right: 1px dashed #66cc33; }

.border-r-success-solid {
  border-right: 1px solid #66cc33; }

.border-r-success-double {
  border-right: 1px double #66cc33; }

.border-r-success-groove {
  border-right: 1px groove #66cc33; }

.border-r-success-ridge {
  border-right: 1px ridge #66cc33; }

.border-r-success-inset {
  border-right: 1px inset #66cc33; }

.border-r-success-outset {
  border-right: 1px outset #66cc33; }

.border-r-success-none {
  border-right: 1px none #66cc33; }

.border-r-success-hidden {
  border-right: 1px hidden #66cc33; }

.border-b-success {
  border-bottom: 1px solid #66cc33; }

.border-b-success-dotted {
  border-bottom: 1px dotted #66cc33; }

.border-b-success-dashed {
  border-bottom: 1px dashed #66cc33; }

.border-b-success-solid {
  border-bottom: 1px solid #66cc33; }

.border-b-success-double {
  border-bottom: 1px double #66cc33; }

.border-b-success-groove {
  border-bottom: 1px groove #66cc33; }

.border-b-success-ridge {
  border-bottom: 1px ridge #66cc33; }

.border-b-success-inset {
  border-bottom: 1px inset #66cc33; }

.border-b-success-outset {
  border-bottom: 1px outset #66cc33; }

.border-b-success-none {
  border-bottom: 1px none #66cc33; }

.border-b-success-hidden {
  border-bottom: 1px hidden #66cc33; }

.border-l-success {
  border-left: 1px solid #66cc33; }

.border-l-success-dotted {
  border-left: 1px dotted #66cc33; }

.border-l-success-dashed {
  border-left: 1px dashed #66cc33; }

.border-l-success-solid {
  border-left: 1px solid #66cc33; }

.border-l-success-double {
  border-left: 1px double #66cc33; }

.border-l-success-groove {
  border-left: 1px groove #66cc33; }

.border-l-success-ridge {
  border-left: 1px ridge #66cc33; }

.border-l-success-inset {
  border-left: 1px inset #66cc33; }

.border-l-success-outset {
  border-left: 1px outset #66cc33; }

.border-l-success-none {
  border-left: 1px none #66cc33; }

.border-l-success-hidden {
  border-left: 1px hidden #66cc33; }

.has-text-success-light {
  color: #dbffe0; }

.has-background-success-light {
  background-color: #dbffe0; }

.border-success-light {
  border: 1px solid #dbffe0; }

.border-m-success-light:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dbffe0;
  transform: translateY(-50%); }

.border-success-light-dotted {
  border: 1px dotted #dbffe0; }

.border-success-light-dashed {
  border: 1px dashed #dbffe0; }

.border-success-light-solid {
  border: 1px solid #dbffe0; }

.border-success-light-double {
  border: 1px double #dbffe0; }

.border-success-light-groove {
  border: 1px groove #dbffe0; }

.border-success-light-ridge {
  border: 1px ridge #dbffe0; }

.border-success-light-inset {
  border: 1px inset #dbffe0; }

.border-success-light-outset {
  border: 1px outset #dbffe0; }

.border-success-light-none {
  border: 1px none #dbffe0; }

.border-success-light-hidden {
  border: 1px hidden #dbffe0; }

.border-t-success-light {
  border-top: 1px solid #dbffe0; }

.border-t-success-light-dotted {
  border-top: 1px dotted #dbffe0; }

.border-t-success-light-dashed {
  border-top: 1px dashed #dbffe0; }

.border-t-success-light-solid {
  border-top: 1px solid #dbffe0; }

.border-t-success-light-double {
  border-top: 1px double #dbffe0; }

.border-t-success-light-groove {
  border-top: 1px groove #dbffe0; }

.border-t-success-light-ridge {
  border-top: 1px ridge #dbffe0; }

.border-t-success-light-inset {
  border-top: 1px inset #dbffe0; }

.border-t-success-light-outset {
  border-top: 1px outset #dbffe0; }

.border-t-success-light-none {
  border-top: 1px none #dbffe0; }

.border-t-success-light-hidden {
  border-top: 1px hidden #dbffe0; }

.border-r-success-light {
  border-right: 1px solid #dbffe0; }

.border-r-success-light-dotted {
  border-right: 1px dotted #dbffe0; }

.border-r-success-light-dashed {
  border-right: 1px dashed #dbffe0; }

.border-r-success-light-solid {
  border-right: 1px solid #dbffe0; }

.border-r-success-light-double {
  border-right: 1px double #dbffe0; }

.border-r-success-light-groove {
  border-right: 1px groove #dbffe0; }

.border-r-success-light-ridge {
  border-right: 1px ridge #dbffe0; }

.border-r-success-light-inset {
  border-right: 1px inset #dbffe0; }

.border-r-success-light-outset {
  border-right: 1px outset #dbffe0; }

.border-r-success-light-none {
  border-right: 1px none #dbffe0; }

.border-r-success-light-hidden {
  border-right: 1px hidden #dbffe0; }

.border-b-success-light {
  border-bottom: 1px solid #dbffe0; }

.border-b-success-light-dotted {
  border-bottom: 1px dotted #dbffe0; }

.border-b-success-light-dashed {
  border-bottom: 1px dashed #dbffe0; }

.border-b-success-light-solid {
  border-bottom: 1px solid #dbffe0; }

.border-b-success-light-double {
  border-bottom: 1px double #dbffe0; }

.border-b-success-light-groove {
  border-bottom: 1px groove #dbffe0; }

.border-b-success-light-ridge {
  border-bottom: 1px ridge #dbffe0; }

.border-b-success-light-inset {
  border-bottom: 1px inset #dbffe0; }

.border-b-success-light-outset {
  border-bottom: 1px outset #dbffe0; }

.border-b-success-light-none {
  border-bottom: 1px none #dbffe0; }

.border-b-success-light-hidden {
  border-bottom: 1px hidden #dbffe0; }

.border-l-success-light {
  border-left: 1px solid #dbffe0; }

.border-l-success-light-dotted {
  border-left: 1px dotted #dbffe0; }

.border-l-success-light-dashed {
  border-left: 1px dashed #dbffe0; }

.border-l-success-light-solid {
  border-left: 1px solid #dbffe0; }

.border-l-success-light-double {
  border-left: 1px double #dbffe0; }

.border-l-success-light-groove {
  border-left: 1px groove #dbffe0; }

.border-l-success-light-ridge {
  border-left: 1px ridge #dbffe0; }

.border-l-success-light-inset {
  border-left: 1px inset #dbffe0; }

.border-l-success-light-outset {
  border-left: 1px outset #dbffe0; }

.border-l-success-light-none {
  border-left: 1px none #dbffe0; }

.border-l-success-light-hidden {
  border-left: 1px hidden #dbffe0; }

.has-text-info {
  color: #209fee; }

.has-background-info {
  background-color: #209fee; }

.border-info {
  border: 1px solid #209fee; }

.border-m-info:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #209fee;
  transform: translateY(-50%); }

.border-info-dotted {
  border: 1px dotted #209fee; }

.border-info-dashed {
  border: 1px dashed #209fee; }

.border-info-solid {
  border: 1px solid #209fee; }

.border-info-double {
  border: 1px double #209fee; }

.border-info-groove {
  border: 1px groove #209fee; }

.border-info-ridge {
  border: 1px ridge #209fee; }

.border-info-inset {
  border: 1px inset #209fee; }

.border-info-outset {
  border: 1px outset #209fee; }

.border-info-none {
  border: 1px none #209fee; }

.border-info-hidden {
  border: 1px hidden #209fee; }

.border-t-info {
  border-top: 1px solid #209fee; }

.border-t-info-dotted {
  border-top: 1px dotted #209fee; }

.border-t-info-dashed {
  border-top: 1px dashed #209fee; }

.border-t-info-solid {
  border-top: 1px solid #209fee; }

.border-t-info-double {
  border-top: 1px double #209fee; }

.border-t-info-groove {
  border-top: 1px groove #209fee; }

.border-t-info-ridge {
  border-top: 1px ridge #209fee; }

.border-t-info-inset {
  border-top: 1px inset #209fee; }

.border-t-info-outset {
  border-top: 1px outset #209fee; }

.border-t-info-none {
  border-top: 1px none #209fee; }

.border-t-info-hidden {
  border-top: 1px hidden #209fee; }

.border-r-info {
  border-right: 1px solid #209fee; }

.border-r-info-dotted {
  border-right: 1px dotted #209fee; }

.border-r-info-dashed {
  border-right: 1px dashed #209fee; }

.border-r-info-solid {
  border-right: 1px solid #209fee; }

.border-r-info-double {
  border-right: 1px double #209fee; }

.border-r-info-groove {
  border-right: 1px groove #209fee; }

.border-r-info-ridge {
  border-right: 1px ridge #209fee; }

.border-r-info-inset {
  border-right: 1px inset #209fee; }

.border-r-info-outset {
  border-right: 1px outset #209fee; }

.border-r-info-none {
  border-right: 1px none #209fee; }

.border-r-info-hidden {
  border-right: 1px hidden #209fee; }

.border-b-info {
  border-bottom: 1px solid #209fee; }

.border-b-info-dotted {
  border-bottom: 1px dotted #209fee; }

.border-b-info-dashed {
  border-bottom: 1px dashed #209fee; }

.border-b-info-solid {
  border-bottom: 1px solid #209fee; }

.border-b-info-double {
  border-bottom: 1px double #209fee; }

.border-b-info-groove {
  border-bottom: 1px groove #209fee; }

.border-b-info-ridge {
  border-bottom: 1px ridge #209fee; }

.border-b-info-inset {
  border-bottom: 1px inset #209fee; }

.border-b-info-outset {
  border-bottom: 1px outset #209fee; }

.border-b-info-none {
  border-bottom: 1px none #209fee; }

.border-b-info-hidden {
  border-bottom: 1px hidden #209fee; }

.border-l-info {
  border-left: 1px solid #209fee; }

.border-l-info-dotted {
  border-left: 1px dotted #209fee; }

.border-l-info-dashed {
  border-left: 1px dashed #209fee; }

.border-l-info-solid {
  border-left: 1px solid #209fee; }

.border-l-info-double {
  border-left: 1px double #209fee; }

.border-l-info-groove {
  border-left: 1px groove #209fee; }

.border-l-info-ridge {
  border-left: 1px ridge #209fee; }

.border-l-info-inset {
  border-left: 1px inset #209fee; }

.border-l-info-outset {
  border-left: 1px outset #209fee; }

.border-l-info-none {
  border-left: 1px none #209fee; }

.border-l-info-hidden {
  border-left: 1px hidden #209fee; }

.has-text-black {
  color: #0a0a0a; }

.has-background-black {
  background-color: #0a0a0a; }

.border-black {
  border: 1px solid #0a0a0a; }

.border-black-dotted {
  border: 1px dotted #0a0a0a; }

.border-black-dashed {
  border: 1px dashed #0a0a0a; }

.border-black-solid {
  border: 1px solid #0a0a0a; }

.border-black-double {
  border: 1px double #0a0a0a; }

.border-black-groove {
  border: 1px groove #0a0a0a; }

.border-black-ridge {
  border: 1px ridge #0a0a0a; }

.border-black-inset {
  border: 1px inset #0a0a0a; }

.border-black-outset {
  border: 1px outset #0a0a0a; }

.border-black-none {
  border: 1px none #0a0a0a; }

.border-black-hidden {
  border: 1px hidden #0a0a0a; }

.border-t-black {
  border-top: 1px solid #0a0a0a; }

.border-t-black-dotted {
  border-top: 1px dotted #0a0a0a; }

.border-t-black-dashed {
  border-top: 1px dashed #0a0a0a; }

.border-t-black-solid {
  border-top: 1px solid #0a0a0a; }

.border-t-black-double {
  border-top: 1px double #0a0a0a; }

.border-t-black-groove {
  border-top: 1px groove #0a0a0a; }

.border-t-black-ridge {
  border-top: 1px ridge #0a0a0a; }

.border-t-black-inset {
  border-top: 1px inset #0a0a0a; }

.border-t-black-outset {
  border-top: 1px outset #0a0a0a; }

.border-t-black-none {
  border-top: 1px none #0a0a0a; }

.border-t-black-hidden {
  border-top: 1px hidden #0a0a0a; }

.border-r-black {
  border-right: 1px solid #0a0a0a; }

.border-r-black-dotted {
  border-right: 1px dotted #0a0a0a; }

.border-r-black-dashed {
  border-right: 1px dashed #0a0a0a; }

.border-r-black-solid {
  border-right: 1px solid #0a0a0a; }

.border-r-black-double {
  border-right: 1px double #0a0a0a; }

.border-r-black-groove {
  border-right: 1px groove #0a0a0a; }

.border-r-black-ridge {
  border-right: 1px ridge #0a0a0a; }

.border-r-black-inset {
  border-right: 1px inset #0a0a0a; }

.border-r-black-outset {
  border-right: 1px outset #0a0a0a; }

.border-r-black-none {
  border-right: 1px none #0a0a0a; }

.border-r-black-hidden {
  border-right: 1px hidden #0a0a0a; }

.border-b-black {
  border-bottom: 1px solid #0a0a0a; }

.border-b-black-dotted {
  border-bottom: 1px dotted #0a0a0a; }

.border-b-black-dashed {
  border-bottom: 1px dashed #0a0a0a; }

.border-b-black-solid {
  border-bottom: 1px solid #0a0a0a; }

.border-b-black-double {
  border-bottom: 1px double #0a0a0a; }

.border-b-black-groove {
  border-bottom: 1px groove #0a0a0a; }

.border-b-black-ridge {
  border-bottom: 1px ridge #0a0a0a; }

.border-b-black-inset {
  border-bottom: 1px inset #0a0a0a; }

.border-b-black-outset {
  border-bottom: 1px outset #0a0a0a; }

.border-b-black-none {
  border-bottom: 1px none #0a0a0a; }

.border-b-black-hidden {
  border-bottom: 1px hidden #0a0a0a; }

.border-l-black {
  border-left: 1px solid #0a0a0a; }

.border-l-black-dotted {
  border-left: 1px dotted #0a0a0a; }

.border-l-black-dashed {
  border-left: 1px dashed #0a0a0a; }

.border-l-black-solid {
  border-left: 1px solid #0a0a0a; }

.border-l-black-double {
  border-left: 1px double #0a0a0a; }

.border-l-black-groove {
  border-left: 1px groove #0a0a0a; }

.border-l-black-ridge {
  border-left: 1px ridge #0a0a0a; }

.border-l-black-inset {
  border-left: 1px inset #0a0a0a; }

.border-l-black-outset {
  border-left: 1px outset #0a0a0a; }

.border-l-black-none {
  border-left: 1px none #0a0a0a; }

.border-l-black-hidden {
  border-left: 1px hidden #0a0a0a; }

.has-text-black-bis {
  color: #121212; }

.has-background-black-bis {
  background-color: #121212; }

.border-black-bis {
  border: 1px solid #121212; }

.border-black-bis-dotted {
  border: 1px dotted #121212; }

.border-black-bis-dashed {
  border: 1px dashed #121212; }

.border-black-bis-solid {
  border: 1px solid #121212; }

.border-black-bis-double {
  border: 1px double #121212; }

.border-black-bis-groove {
  border: 1px groove #121212; }

.border-black-bis-ridge {
  border: 1px ridge #121212; }

.border-black-bis-inset {
  border: 1px inset #121212; }

.border-black-bis-outset {
  border: 1px outset #121212; }

.border-black-bis-none {
  border: 1px none #121212; }

.border-black-bis-hidden {
  border: 1px hidden #121212; }

.border-t-black-bis {
  border-top: 1px solid #121212; }

.border-t-black-bis-dotted {
  border-top: 1px dotted #121212; }

.border-t-black-bis-dashed {
  border-top: 1px dashed #121212; }

.border-t-black-bis-solid {
  border-top: 1px solid #121212; }

.border-t-black-bis-double {
  border-top: 1px double #121212; }

.border-t-black-bis-groove {
  border-top: 1px groove #121212; }

.border-t-black-bis-ridge {
  border-top: 1px ridge #121212; }

.border-t-black-bis-inset {
  border-top: 1px inset #121212; }

.border-t-black-bis-outset {
  border-top: 1px outset #121212; }

.border-t-black-bis-none {
  border-top: 1px none #121212; }

.border-t-black-bis-hidden {
  border-top: 1px hidden #121212; }

.border-r-black-bis {
  border-right: 1px solid #121212; }

.border-r-black-bis-dotted {
  border-right: 1px dotted #121212; }

.border-r-black-bis-dashed {
  border-right: 1px dashed #121212; }

.border-r-black-bis-solid {
  border-right: 1px solid #121212; }

.border-r-black-bis-double {
  border-right: 1px double #121212; }

.border-r-black-bis-groove {
  border-right: 1px groove #121212; }

.border-r-black-bis-ridge {
  border-right: 1px ridge #121212; }

.border-r-black-bis-inset {
  border-right: 1px inset #121212; }

.border-r-black-bis-outset {
  border-right: 1px outset #121212; }

.border-r-black-bis-none {
  border-right: 1px none #121212; }

.border-r-black-bis-hidden {
  border-right: 1px hidden #121212; }

.border-b-black-bis {
  border-bottom: 1px solid #121212; }

.border-b-black-bis-dotted {
  border-bottom: 1px dotted #121212; }

.border-b-black-bis-dashed {
  border-bottom: 1px dashed #121212; }

.border-b-black-bis-solid {
  border-bottom: 1px solid #121212; }

.border-b-black-bis-double {
  border-bottom: 1px double #121212; }

.border-b-black-bis-groove {
  border-bottom: 1px groove #121212; }

.border-b-black-bis-ridge {
  border-bottom: 1px ridge #121212; }

.border-b-black-bis-inset {
  border-bottom: 1px inset #121212; }

.border-b-black-bis-outset {
  border-bottom: 1px outset #121212; }

.border-b-black-bis-none {
  border-bottom: 1px none #121212; }

.border-b-black-bis-hidden {
  border-bottom: 1px hidden #121212; }

.border-l-black-bis {
  border-left: 1px solid #121212; }

.border-l-black-bis-dotted {
  border-left: 1px dotted #121212; }

.border-l-black-bis-dashed {
  border-left: 1px dashed #121212; }

.border-l-black-bis-solid {
  border-left: 1px solid #121212; }

.border-l-black-bis-double {
  border-left: 1px double #121212; }

.border-l-black-bis-groove {
  border-left: 1px groove #121212; }

.border-l-black-bis-ridge {
  border-left: 1px ridge #121212; }

.border-l-black-bis-inset {
  border-left: 1px inset #121212; }

.border-l-black-bis-outset {
  border-left: 1px outset #121212; }

.border-l-black-bis-none {
  border-left: 1px none #121212; }

.border-l-black-bis-hidden {
  border-left: 1px hidden #121212; }

.has-text-black-ter {
  color: #242424; }

.has-background-black-ter {
  background-color: #242424; }

.border-black-ter {
  border: 1px solid #242424; }

.border-black-ter-dotted {
  border: 1px dotted #242424; }

.border-black-ter-dashed {
  border: 1px dashed #242424; }

.border-black-ter-solid {
  border: 1px solid #242424; }

.border-black-ter-double {
  border: 1px double #242424; }

.border-black-ter-groove {
  border: 1px groove #242424; }

.border-black-ter-ridge {
  border: 1px ridge #242424; }

.border-black-ter-inset {
  border: 1px inset #242424; }

.border-black-ter-outset {
  border: 1px outset #242424; }

.border-black-ter-none {
  border: 1px none #242424; }

.border-black-ter-hidden {
  border: 1px hidden #242424; }

.border-t-black-ter {
  border-top: 1px solid #242424; }

.border-t-black-ter-dotted {
  border-top: 1px dotted #242424; }

.border-t-black-ter-dashed {
  border-top: 1px dashed #242424; }

.border-t-black-ter-solid {
  border-top: 1px solid #242424; }

.border-t-black-ter-double {
  border-top: 1px double #242424; }

.border-t-black-ter-groove {
  border-top: 1px groove #242424; }

.border-t-black-ter-ridge {
  border-top: 1px ridge #242424; }

.border-t-black-ter-inset {
  border-top: 1px inset #242424; }

.border-t-black-ter-outset {
  border-top: 1px outset #242424; }

.border-t-black-ter-none {
  border-top: 1px none #242424; }

.border-t-black-ter-hidden {
  border-top: 1px hidden #242424; }

.border-r-black-ter {
  border-right: 1px solid #242424; }

.border-r-black-ter-dotted {
  border-right: 1px dotted #242424; }

.border-r-black-ter-dashed {
  border-right: 1px dashed #242424; }

.border-r-black-ter-solid {
  border-right: 1px solid #242424; }

.border-r-black-ter-double {
  border-right: 1px double #242424; }

.border-r-black-ter-groove {
  border-right: 1px groove #242424; }

.border-r-black-ter-ridge {
  border-right: 1px ridge #242424; }

.border-r-black-ter-inset {
  border-right: 1px inset #242424; }

.border-r-black-ter-outset {
  border-right: 1px outset #242424; }

.border-r-black-ter-none {
  border-right: 1px none #242424; }

.border-r-black-ter-hidden {
  border-right: 1px hidden #242424; }

.border-b-black-ter {
  border-bottom: 1px solid #242424; }

.border-b-black-ter-dotted {
  border-bottom: 1px dotted #242424; }

.border-b-black-ter-dashed {
  border-bottom: 1px dashed #242424; }

.border-b-black-ter-solid {
  border-bottom: 1px solid #242424; }

.border-b-black-ter-double {
  border-bottom: 1px double #242424; }

.border-b-black-ter-groove {
  border-bottom: 1px groove #242424; }

.border-b-black-ter-ridge {
  border-bottom: 1px ridge #242424; }

.border-b-black-ter-inset {
  border-bottom: 1px inset #242424; }

.border-b-black-ter-outset {
  border-bottom: 1px outset #242424; }

.border-b-black-ter-none {
  border-bottom: 1px none #242424; }

.border-b-black-ter-hidden {
  border-bottom: 1px hidden #242424; }

.border-l-black-ter {
  border-left: 1px solid #242424; }

.border-l-black-ter-dotted {
  border-left: 1px dotted #242424; }

.border-l-black-ter-dashed {
  border-left: 1px dashed #242424; }

.border-l-black-ter-solid {
  border-left: 1px solid #242424; }

.border-l-black-ter-double {
  border-left: 1px double #242424; }

.border-l-black-ter-groove {
  border-left: 1px groove #242424; }

.border-l-black-ter-ridge {
  border-left: 1px ridge #242424; }

.border-l-black-ter-inset {
  border-left: 1px inset #242424; }

.border-l-black-ter-outset {
  border-left: 1px outset #242424; }

.border-l-black-ter-none {
  border-left: 1px none #242424; }

.border-l-black-ter-hidden {
  border-left: 1px hidden #242424; }

.has-text-grey-darker {
  color: #363636; }

.has-background-grey-darker {
  background-color: #363636; }

.border-grey-darker {
  border: 1px solid #363636; }

.border-grey-darker-dotted {
  border: 1px dotted #363636; }

.border-grey-darker-dashed {
  border: 1px dashed #363636; }

.border-grey-darker-solid {
  border: 1px solid #363636; }

.border-grey-darker-double {
  border: 1px double #363636; }

.border-grey-darker-groove {
  border: 1px groove #363636; }

.border-grey-darker-ridge {
  border: 1px ridge #363636; }

.border-grey-darker-inset {
  border: 1px inset #363636; }

.border-grey-darker-outset {
  border: 1px outset #363636; }

.border-grey-darker-none {
  border: 1px none #363636; }

.border-grey-darker-hidden {
  border: 1px hidden #363636; }

.border-t-grey-darker {
  border-top: 1px solid #363636; }

.border-t-grey-darker-dotted {
  border-top: 1px dotted #363636; }

.border-t-grey-darker-dashed {
  border-top: 1px dashed #363636; }

.border-t-grey-darker-solid {
  border-top: 1px solid #363636; }

.border-t-grey-darker-double {
  border-top: 1px double #363636; }

.border-t-grey-darker-groove {
  border-top: 1px groove #363636; }

.border-t-grey-darker-ridge {
  border-top: 1px ridge #363636; }

.border-t-grey-darker-inset {
  border-top: 1px inset #363636; }

.border-t-grey-darker-outset {
  border-top: 1px outset #363636; }

.border-t-grey-darker-none {
  border-top: 1px none #363636; }

.border-t-grey-darker-hidden {
  border-top: 1px hidden #363636; }

.border-r-grey-darker {
  border-right: 1px solid #363636; }

.border-r-grey-darker-dotted {
  border-right: 1px dotted #363636; }

.border-r-grey-darker-dashed {
  border-right: 1px dashed #363636; }

.border-r-grey-darker-solid {
  border-right: 1px solid #363636; }

.border-r-grey-darker-double {
  border-right: 1px double #363636; }

.border-r-grey-darker-groove {
  border-right: 1px groove #363636; }

.border-r-grey-darker-ridge {
  border-right: 1px ridge #363636; }

.border-r-grey-darker-inset {
  border-right: 1px inset #363636; }

.border-r-grey-darker-outset {
  border-right: 1px outset #363636; }

.border-r-grey-darker-none {
  border-right: 1px none #363636; }

.border-r-grey-darker-hidden {
  border-right: 1px hidden #363636; }

.border-b-grey-darker {
  border-bottom: 1px solid #363636; }

.border-b-grey-darker-dotted {
  border-bottom: 1px dotted #363636; }

.border-b-grey-darker-dashed {
  border-bottom: 1px dashed #363636; }

.border-b-grey-darker-solid {
  border-bottom: 1px solid #363636; }

.border-b-grey-darker-double {
  border-bottom: 1px double #363636; }

.border-b-grey-darker-groove {
  border-bottom: 1px groove #363636; }

.border-b-grey-darker-ridge {
  border-bottom: 1px ridge #363636; }

.border-b-grey-darker-inset {
  border-bottom: 1px inset #363636; }

.border-b-grey-darker-outset {
  border-bottom: 1px outset #363636; }

.border-b-grey-darker-none {
  border-bottom: 1px none #363636; }

.border-b-grey-darker-hidden {
  border-bottom: 1px hidden #363636; }

.border-l-grey-darker {
  border-left: 1px solid #363636; }

.border-l-grey-darker-dotted {
  border-left: 1px dotted #363636; }

.border-l-grey-darker-dashed {
  border-left: 1px dashed #363636; }

.border-l-grey-darker-solid {
  border-left: 1px solid #363636; }

.border-l-grey-darker-double {
  border-left: 1px double #363636; }

.border-l-grey-darker-groove {
  border-left: 1px groove #363636; }

.border-l-grey-darker-ridge {
  border-left: 1px ridge #363636; }

.border-l-grey-darker-inset {
  border-left: 1px inset #363636; }

.border-l-grey-darker-outset {
  border-left: 1px outset #363636; }

.border-l-grey-darker-none {
  border-left: 1px none #363636; }

.border-l-grey-darker-hidden {
  border-left: 1px hidden #363636; }

.has-text-grey-dark {
  color: #4a4a4a; }

.has-background-grey-dark {
  background-color: #4a4a4a; }

.border-grey-dark {
  border: 1px solid #4a4a4a; }

.border-grey-dark-dotted {
  border: 1px dotted #4a4a4a; }

.border-grey-dark-dashed {
  border: 1px dashed #4a4a4a; }

.border-grey-dark-solid {
  border: 1px solid #4a4a4a; }

.border-grey-dark-double {
  border: 1px double #4a4a4a; }

.border-grey-dark-groove {
  border: 1px groove #4a4a4a; }

.border-grey-dark-ridge {
  border: 1px ridge #4a4a4a; }

.border-grey-dark-inset {
  border: 1px inset #4a4a4a; }

.border-grey-dark-outset {
  border: 1px outset #4a4a4a; }

.border-grey-dark-none {
  border: 1px none #4a4a4a; }

.border-grey-dark-hidden {
  border: 1px hidden #4a4a4a; }

.border-t-grey-dark {
  border-top: 1px solid #4a4a4a; }

.border-t-grey-dark-dotted {
  border-top: 1px dotted #4a4a4a; }

.border-t-grey-dark-dashed {
  border-top: 1px dashed #4a4a4a; }

.border-t-grey-dark-solid {
  border-top: 1px solid #4a4a4a; }

.border-t-grey-dark-double {
  border-top: 1px double #4a4a4a; }

.border-t-grey-dark-groove {
  border-top: 1px groove #4a4a4a; }

.border-t-grey-dark-ridge {
  border-top: 1px ridge #4a4a4a; }

.border-t-grey-dark-inset {
  border-top: 1px inset #4a4a4a; }

.border-t-grey-dark-outset {
  border-top: 1px outset #4a4a4a; }

.border-t-grey-dark-none {
  border-top: 1px none #4a4a4a; }

.border-t-grey-dark-hidden {
  border-top: 1px hidden #4a4a4a; }

.border-r-grey-dark {
  border-right: 1px solid #4a4a4a; }

.border-r-grey-dark-dotted {
  border-right: 1px dotted #4a4a4a; }

.border-r-grey-dark-dashed {
  border-right: 1px dashed #4a4a4a; }

.border-r-grey-dark-solid {
  border-right: 1px solid #4a4a4a; }

.border-r-grey-dark-double {
  border-right: 1px double #4a4a4a; }

.border-r-grey-dark-groove {
  border-right: 1px groove #4a4a4a; }

.border-r-grey-dark-ridge {
  border-right: 1px ridge #4a4a4a; }

.border-r-grey-dark-inset {
  border-right: 1px inset #4a4a4a; }

.border-r-grey-dark-outset {
  border-right: 1px outset #4a4a4a; }

.border-r-grey-dark-none {
  border-right: 1px none #4a4a4a; }

.border-r-grey-dark-hidden {
  border-right: 1px hidden #4a4a4a; }

.border-b-grey-dark {
  border-bottom: 1px solid #4a4a4a; }

.border-b-grey-dark-dotted {
  border-bottom: 1px dotted #4a4a4a; }

.border-b-grey-dark-dashed {
  border-bottom: 1px dashed #4a4a4a; }

.border-b-grey-dark-solid {
  border-bottom: 1px solid #4a4a4a; }

.border-b-grey-dark-double {
  border-bottom: 1px double #4a4a4a; }

.border-b-grey-dark-groove {
  border-bottom: 1px groove #4a4a4a; }

.border-b-grey-dark-ridge {
  border-bottom: 1px ridge #4a4a4a; }

.border-b-grey-dark-inset {
  border-bottom: 1px inset #4a4a4a; }

.border-b-grey-dark-outset {
  border-bottom: 1px outset #4a4a4a; }

.border-b-grey-dark-none {
  border-bottom: 1px none #4a4a4a; }

.border-b-grey-dark-hidden {
  border-bottom: 1px hidden #4a4a4a; }

.border-l-grey-dark {
  border-left: 1px solid #4a4a4a; }

.border-l-grey-dark-dotted {
  border-left: 1px dotted #4a4a4a; }

.border-l-grey-dark-dashed {
  border-left: 1px dashed #4a4a4a; }

.border-l-grey-dark-solid {
  border-left: 1px solid #4a4a4a; }

.border-l-grey-dark-double {
  border-left: 1px double #4a4a4a; }

.border-l-grey-dark-groove {
  border-left: 1px groove #4a4a4a; }

.border-l-grey-dark-ridge {
  border-left: 1px ridge #4a4a4a; }

.border-l-grey-dark-inset {
  border-left: 1px inset #4a4a4a; }

.border-l-grey-dark-outset {
  border-left: 1px outset #4a4a4a; }

.border-l-grey-dark-none {
  border-left: 1px none #4a4a4a; }

.border-l-grey-dark-hidden {
  border-left: 1px hidden #4a4a4a; }

.has-text-grey {
  color: #7a7a7a; }

.has-background-grey {
  background-color: #7a7a7a; }

.border-grey {
  border: 1px solid #7a7a7a; }

.border-grey-dotted {
  border: 1px dotted #7a7a7a; }

.border-grey-dashed {
  border: 1px dashed #7a7a7a; }

.border-grey-solid {
  border: 1px solid #7a7a7a; }

.border-grey-double {
  border: 1px double #7a7a7a; }

.border-grey-groove {
  border: 1px groove #7a7a7a; }

.border-grey-ridge {
  border: 1px ridge #7a7a7a; }

.border-grey-inset {
  border: 1px inset #7a7a7a; }

.border-grey-outset {
  border: 1px outset #7a7a7a; }

.border-grey-none {
  border: 1px none #7a7a7a; }

.border-grey-hidden {
  border: 1px hidden #7a7a7a; }

.border-t-grey {
  border-top: 1px solid #7a7a7a; }

.border-t-grey-dotted {
  border-top: 1px dotted #7a7a7a; }

.border-t-grey-dashed {
  border-top: 1px dashed #7a7a7a; }

.border-t-grey-solid {
  border-top: 1px solid #7a7a7a; }

.border-t-grey-double {
  border-top: 1px double #7a7a7a; }

.border-t-grey-groove {
  border-top: 1px groove #7a7a7a; }

.border-t-grey-ridge {
  border-top: 1px ridge #7a7a7a; }

.border-t-grey-inset {
  border-top: 1px inset #7a7a7a; }

.border-t-grey-outset {
  border-top: 1px outset #7a7a7a; }

.border-t-grey-none {
  border-top: 1px none #7a7a7a; }

.border-t-grey-hidden {
  border-top: 1px hidden #7a7a7a; }

.border-r-grey {
  border-right: 1px solid #7a7a7a; }

.border-r-grey-dotted {
  border-right: 1px dotted #7a7a7a; }

.border-r-grey-dashed {
  border-right: 1px dashed #7a7a7a; }

.border-r-grey-solid {
  border-right: 1px solid #7a7a7a; }

.border-r-grey-double {
  border-right: 1px double #7a7a7a; }

.border-r-grey-groove {
  border-right: 1px groove #7a7a7a; }

.border-r-grey-ridge {
  border-right: 1px ridge #7a7a7a; }

.border-r-grey-inset {
  border-right: 1px inset #7a7a7a; }

.border-r-grey-outset {
  border-right: 1px outset #7a7a7a; }

.border-r-grey-none {
  border-right: 1px none #7a7a7a; }

.border-r-grey-hidden {
  border-right: 1px hidden #7a7a7a; }

.border-b-grey {
  border-bottom: 1px solid #7a7a7a; }

.border-b-grey-dotted {
  border-bottom: 1px dotted #7a7a7a; }

.border-b-grey-dashed {
  border-bottom: 1px dashed #7a7a7a; }

.border-b-grey-solid {
  border-bottom: 1px solid #7a7a7a; }

.border-b-grey-double {
  border-bottom: 1px double #7a7a7a; }

.border-b-grey-groove {
  border-bottom: 1px groove #7a7a7a; }

.border-b-grey-ridge {
  border-bottom: 1px ridge #7a7a7a; }

.border-b-grey-inset {
  border-bottom: 1px inset #7a7a7a; }

.border-b-grey-outset {
  border-bottom: 1px outset #7a7a7a; }

.border-b-grey-none {
  border-bottom: 1px none #7a7a7a; }

.border-b-grey-hidden {
  border-bottom: 1px hidden #7a7a7a; }

.border-l-grey {
  border-left: 1px solid #7a7a7a; }

.border-l-grey-dotted {
  border-left: 1px dotted #7a7a7a; }

.border-l-grey-dashed {
  border-left: 1px dashed #7a7a7a; }

.border-l-grey-solid {
  border-left: 1px solid #7a7a7a; }

.border-l-grey-double {
  border-left: 1px double #7a7a7a; }

.border-l-grey-groove {
  border-left: 1px groove #7a7a7a; }

.border-l-grey-ridge {
  border-left: 1px ridge #7a7a7a; }

.border-l-grey-inset {
  border-left: 1px inset #7a7a7a; }

.border-l-grey-outset {
  border-left: 1px outset #7a7a7a; }

.border-l-grey-none {
  border-left: 1px none #7a7a7a; }

.border-l-grey-hidden {
  border-left: 1px hidden #7a7a7a; }

.has-text-grey-light {
  color: #b5b5b5; }

.has-background-grey-light {
  background-color: #b5b5b5; }

.border-grey-light {
  border: 1px solid #b5b5b5; }

.border-grey-light-dotted {
  border: 1px dotted #b5b5b5; }

.border-grey-light-dashed {
  border: 1px dashed #b5b5b5; }

.border-grey-light-solid {
  border: 1px solid #b5b5b5; }

.border-grey-light-double {
  border: 1px double #b5b5b5; }

.border-grey-light-groove {
  border: 1px groove #b5b5b5; }

.border-grey-light-ridge {
  border: 1px ridge #b5b5b5; }

.border-grey-light-inset {
  border: 1px inset #b5b5b5; }

.border-grey-light-outset {
  border: 1px outset #b5b5b5; }

.border-grey-light-none {
  border: 1px none #b5b5b5; }

.border-grey-light-hidden {
  border: 1px hidden #b5b5b5; }

.border-t-grey-light {
  border-top: 1px solid #b5b5b5; }

.border-t-grey-light-dotted {
  border-top: 1px dotted #b5b5b5; }

.border-t-grey-light-dashed {
  border-top: 1px dashed #b5b5b5; }

.border-t-grey-light-solid {
  border-top: 1px solid #b5b5b5; }

.border-t-grey-light-double {
  border-top: 1px double #b5b5b5; }

.border-t-grey-light-groove {
  border-top: 1px groove #b5b5b5; }

.border-t-grey-light-ridge {
  border-top: 1px ridge #b5b5b5; }

.border-t-grey-light-inset {
  border-top: 1px inset #b5b5b5; }

.border-t-grey-light-outset {
  border-top: 1px outset #b5b5b5; }

.border-t-grey-light-none {
  border-top: 1px none #b5b5b5; }

.border-t-grey-light-hidden {
  border-top: 1px hidden #b5b5b5; }

.border-r-grey-light {
  border-right: 1px solid #b5b5b5; }

.border-r-grey-light-dotted {
  border-right: 1px dotted #b5b5b5; }

.border-r-grey-light-dashed {
  border-right: 1px dashed #b5b5b5; }

.border-r-grey-light-solid {
  border-right: 1px solid #b5b5b5; }

.border-r-grey-light-double {
  border-right: 1px double #b5b5b5; }

.border-r-grey-light-groove {
  border-right: 1px groove #b5b5b5; }

.border-r-grey-light-ridge {
  border-right: 1px ridge #b5b5b5; }

.border-r-grey-light-inset {
  border-right: 1px inset #b5b5b5; }

.border-r-grey-light-outset {
  border-right: 1px outset #b5b5b5; }

.border-r-grey-light-none {
  border-right: 1px none #b5b5b5; }

.border-r-grey-light-hidden {
  border-right: 1px hidden #b5b5b5; }

.border-b-grey-light {
  border-bottom: 1px solid #b5b5b5; }

.border-b-grey-light-dotted {
  border-bottom: 1px dotted #b5b5b5; }

.border-b-grey-light-dashed {
  border-bottom: 1px dashed #b5b5b5; }

.border-b-grey-light-solid {
  border-bottom: 1px solid #b5b5b5; }

.border-b-grey-light-double {
  border-bottom: 1px double #b5b5b5; }

.border-b-grey-light-groove {
  border-bottom: 1px groove #b5b5b5; }

.border-b-grey-light-ridge {
  border-bottom: 1px ridge #b5b5b5; }

.border-b-grey-light-inset {
  border-bottom: 1px inset #b5b5b5; }

.border-b-grey-light-outset {
  border-bottom: 1px outset #b5b5b5; }

.border-b-grey-light-none {
  border-bottom: 1px none #b5b5b5; }

.border-b-grey-light-hidden {
  border-bottom: 1px hidden #b5b5b5; }

.border-l-grey-light {
  border-left: 1px solid #b5b5b5; }

.border-l-grey-light-dotted {
  border-left: 1px dotted #b5b5b5; }

.border-l-grey-light-dashed {
  border-left: 1px dashed #b5b5b5; }

.border-l-grey-light-solid {
  border-left: 1px solid #b5b5b5; }

.border-l-grey-light-double {
  border-left: 1px double #b5b5b5; }

.border-l-grey-light-groove {
  border-left: 1px groove #b5b5b5; }

.border-l-grey-light-ridge {
  border-left: 1px ridge #b5b5b5; }

.border-l-grey-light-inset {
  border-left: 1px inset #b5b5b5; }

.border-l-grey-light-outset {
  border-left: 1px outset #b5b5b5; }

.border-l-grey-light-none {
  border-left: 1px none #b5b5b5; }

.border-l-grey-light-hidden {
  border-left: 1px hidden #b5b5b5; }

.has-text-grey-lighter {
  color: #e6e6e6; }

.has-background-grey-lighter {
  background-color: #e6e6e6; }

.border-grey-lighter {
  border: 1px solid #e6e6e6; }

.border-grey-lighter-dotted {
  border: 1px dotted #e6e6e6; }

.border-grey-lighter-dashed {
  border: 1px dashed #e6e6e6; }

.border-grey-lighter-solid {
  border: 1px solid #e6e6e6; }

.border-grey-lighter-double {
  border: 1px double #e6e6e6; }

.border-grey-lighter-groove {
  border: 1px groove #e6e6e6; }

.border-grey-lighter-ridge {
  border: 1px ridge #e6e6e6; }

.border-grey-lighter-inset {
  border: 1px inset #e6e6e6; }

.border-grey-lighter-outset {
  border: 1px outset #e6e6e6; }

.border-grey-lighter-none {
  border: 1px none #e6e6e6; }

.border-grey-lighter-hidden {
  border: 1px hidden #e6e6e6; }

.border-t-grey-lighter {
  border-top: 1px solid #e6e6e6; }

.border-t-grey-lighter-dotted {
  border-top: 1px dotted #e6e6e6; }

.border-t-grey-lighter-dashed {
  border-top: 1px dashed #e6e6e6; }

.border-t-grey-lighter-solid {
  border-top: 1px solid #e6e6e6; }

.border-t-grey-lighter-double {
  border-top: 1px double #e6e6e6; }

.border-t-grey-lighter-groove {
  border-top: 1px groove #e6e6e6; }

.border-t-grey-lighter-ridge {
  border-top: 1px ridge #e6e6e6; }

.border-t-grey-lighter-inset {
  border-top: 1px inset #e6e6e6; }

.border-t-grey-lighter-outset {
  border-top: 1px outset #e6e6e6; }

.border-t-grey-lighter-none {
  border-top: 1px none #e6e6e6; }

.border-t-grey-lighter-hidden {
  border-top: 1px hidden #e6e6e6; }

.border-r-grey-lighter {
  border-right: 1px solid #e6e6e6; }

.border-r-grey-lighter-dotted {
  border-right: 1px dotted #e6e6e6; }

.border-r-grey-lighter-dashed {
  border-right: 1px dashed #e6e6e6; }

.border-r-grey-lighter-solid {
  border-right: 1px solid #e6e6e6; }

.border-r-grey-lighter-double {
  border-right: 1px double #e6e6e6; }

.border-r-grey-lighter-groove {
  border-right: 1px groove #e6e6e6; }

.border-r-grey-lighter-ridge {
  border-right: 1px ridge #e6e6e6; }

.border-r-grey-lighter-inset {
  border-right: 1px inset #e6e6e6; }

.border-r-grey-lighter-outset {
  border-right: 1px outset #e6e6e6; }

.border-r-grey-lighter-none {
  border-right: 1px none #e6e6e6; }

.border-r-grey-lighter-hidden {
  border-right: 1px hidden #e6e6e6; }

.border-b-grey-lighter {
  border-bottom: 1px solid #e6e6e6; }

.border-b-grey-lighter-dotted {
  border-bottom: 1px dotted #e6e6e6; }

.border-b-grey-lighter-dashed {
  border-bottom: 1px dashed #e6e6e6; }

.border-b-grey-lighter-solid {
  border-bottom: 1px solid #e6e6e6; }

.border-b-grey-lighter-double {
  border-bottom: 1px double #e6e6e6; }

.border-b-grey-lighter-groove {
  border-bottom: 1px groove #e6e6e6; }

.border-b-grey-lighter-ridge {
  border-bottom: 1px ridge #e6e6e6; }

.border-b-grey-lighter-inset {
  border-bottom: 1px inset #e6e6e6; }

.border-b-grey-lighter-outset {
  border-bottom: 1px outset #e6e6e6; }

.border-b-grey-lighter-none {
  border-bottom: 1px none #e6e6e6; }

.border-b-grey-lighter-hidden {
  border-bottom: 1px hidden #e6e6e6; }

.border-l-grey-lighter {
  border-left: 1px solid #e6e6e6; }

.border-l-grey-lighter-dotted {
  border-left: 1px dotted #e6e6e6; }

.border-l-grey-lighter-dashed {
  border-left: 1px dashed #e6e6e6; }

.border-l-grey-lighter-solid {
  border-left: 1px solid #e6e6e6; }

.border-l-grey-lighter-double {
  border-left: 1px double #e6e6e6; }

.border-l-grey-lighter-groove {
  border-left: 1px groove #e6e6e6; }

.border-l-grey-lighter-ridge {
  border-left: 1px ridge #e6e6e6; }

.border-l-grey-lighter-inset {
  border-left: 1px inset #e6e6e6; }

.border-l-grey-lighter-outset {
  border-left: 1px outset #e6e6e6; }

.border-l-grey-lighter-none {
  border-left: 1px none #e6e6e6; }

.border-l-grey-lighter-hidden {
  border-left: 1px hidden #e6e6e6; }

.has-text-white-ter {
  color: whitesmoke; }

.has-background-white-ter {
  background-color: whitesmoke; }

.border-white-ter {
  border: 1px solid whitesmoke; }

.border-white-ter-dotted {
  border: 1px dotted whitesmoke; }

.border-white-ter-dashed {
  border: 1px dashed whitesmoke; }

.border-white-ter-solid {
  border: 1px solid whitesmoke; }

.border-white-ter-double {
  border: 1px double whitesmoke; }

.border-white-ter-groove {
  border: 1px groove whitesmoke; }

.border-white-ter-ridge {
  border: 1px ridge whitesmoke; }

.border-white-ter-inset {
  border: 1px inset whitesmoke; }

.border-white-ter-outset {
  border: 1px outset whitesmoke; }

.border-white-ter-none {
  border: 1px none whitesmoke; }

.border-white-ter-hidden {
  border: 1px hidden whitesmoke; }

.border-t-white-ter {
  border-top: 1px solid whitesmoke; }

.border-t-white-ter-dotted {
  border-top: 1px dotted whitesmoke; }

.border-t-white-ter-dashed {
  border-top: 1px dashed whitesmoke; }

.border-t-white-ter-solid {
  border-top: 1px solid whitesmoke; }

.border-t-white-ter-double {
  border-top: 1px double whitesmoke; }

.border-t-white-ter-groove {
  border-top: 1px groove whitesmoke; }

.border-t-white-ter-ridge {
  border-top: 1px ridge whitesmoke; }

.border-t-white-ter-inset {
  border-top: 1px inset whitesmoke; }

.border-t-white-ter-outset {
  border-top: 1px outset whitesmoke; }

.border-t-white-ter-none {
  border-top: 1px none whitesmoke; }

.border-t-white-ter-hidden {
  border-top: 1px hidden whitesmoke; }

.border-r-white-ter {
  border-right: 1px solid whitesmoke; }

.border-r-white-ter-dotted {
  border-right: 1px dotted whitesmoke; }

.border-r-white-ter-dashed {
  border-right: 1px dashed whitesmoke; }

.border-r-white-ter-solid {
  border-right: 1px solid whitesmoke; }

.border-r-white-ter-double {
  border-right: 1px double whitesmoke; }

.border-r-white-ter-groove {
  border-right: 1px groove whitesmoke; }

.border-r-white-ter-ridge {
  border-right: 1px ridge whitesmoke; }

.border-r-white-ter-inset {
  border-right: 1px inset whitesmoke; }

.border-r-white-ter-outset {
  border-right: 1px outset whitesmoke; }

.border-r-white-ter-none {
  border-right: 1px none whitesmoke; }

.border-r-white-ter-hidden {
  border-right: 1px hidden whitesmoke; }

.border-b-white-ter {
  border-bottom: 1px solid whitesmoke; }

.border-b-white-ter-dotted {
  border-bottom: 1px dotted whitesmoke; }

.border-b-white-ter-dashed {
  border-bottom: 1px dashed whitesmoke; }

.border-b-white-ter-solid {
  border-bottom: 1px solid whitesmoke; }

.border-b-white-ter-double {
  border-bottom: 1px double whitesmoke; }

.border-b-white-ter-groove {
  border-bottom: 1px groove whitesmoke; }

.border-b-white-ter-ridge {
  border-bottom: 1px ridge whitesmoke; }

.border-b-white-ter-inset {
  border-bottom: 1px inset whitesmoke; }

.border-b-white-ter-outset {
  border-bottom: 1px outset whitesmoke; }

.border-b-white-ter-none {
  border-bottom: 1px none whitesmoke; }

.border-b-white-ter-hidden {
  border-bottom: 1px hidden whitesmoke; }

.border-l-white-ter {
  border-left: 1px solid whitesmoke; }

.border-l-white-ter-dotted {
  border-left: 1px dotted whitesmoke; }

.border-l-white-ter-dashed {
  border-left: 1px dashed whitesmoke; }

.border-l-white-ter-solid {
  border-left: 1px solid whitesmoke; }

.border-l-white-ter-double {
  border-left: 1px double whitesmoke; }

.border-l-white-ter-groove {
  border-left: 1px groove whitesmoke; }

.border-l-white-ter-ridge {
  border-left: 1px ridge whitesmoke; }

.border-l-white-ter-inset {
  border-left: 1px inset whitesmoke; }

.border-l-white-ter-outset {
  border-left: 1px outset whitesmoke; }

.border-l-white-ter-none {
  border-left: 1px none whitesmoke; }

.border-l-white-ter-hidden {
  border-left: 1px hidden whitesmoke; }

.has-text-white-bis {
  color: #fafafa; }

.has-background-white-bis {
  background-color: #fafafa; }

.border-white-bis {
  border: 1px solid #fafafa; }

.border-white-bis-dotted {
  border: 1px dotted #fafafa; }

.border-white-bis-dashed {
  border: 1px dashed #fafafa; }

.border-white-bis-solid {
  border: 1px solid #fafafa; }

.border-white-bis-double {
  border: 1px double #fafafa; }

.border-white-bis-groove {
  border: 1px groove #fafafa; }

.border-white-bis-ridge {
  border: 1px ridge #fafafa; }

.border-white-bis-inset {
  border: 1px inset #fafafa; }

.border-white-bis-outset {
  border: 1px outset #fafafa; }

.border-white-bis-none {
  border: 1px none #fafafa; }

.border-white-bis-hidden {
  border: 1px hidden #fafafa; }

.border-t-white-bis {
  border-top: 1px solid #fafafa; }

.border-t-white-bis-dotted {
  border-top: 1px dotted #fafafa; }

.border-t-white-bis-dashed {
  border-top: 1px dashed #fafafa; }

.border-t-white-bis-solid {
  border-top: 1px solid #fafafa; }

.border-t-white-bis-double {
  border-top: 1px double #fafafa; }

.border-t-white-bis-groove {
  border-top: 1px groove #fafafa; }

.border-t-white-bis-ridge {
  border-top: 1px ridge #fafafa; }

.border-t-white-bis-inset {
  border-top: 1px inset #fafafa; }

.border-t-white-bis-outset {
  border-top: 1px outset #fafafa; }

.border-t-white-bis-none {
  border-top: 1px none #fafafa; }

.border-t-white-bis-hidden {
  border-top: 1px hidden #fafafa; }

.border-r-white-bis {
  border-right: 1px solid #fafafa; }

.border-r-white-bis-dotted {
  border-right: 1px dotted #fafafa; }

.border-r-white-bis-dashed {
  border-right: 1px dashed #fafafa; }

.border-r-white-bis-solid {
  border-right: 1px solid #fafafa; }

.border-r-white-bis-double {
  border-right: 1px double #fafafa; }

.border-r-white-bis-groove {
  border-right: 1px groove #fafafa; }

.border-r-white-bis-ridge {
  border-right: 1px ridge #fafafa; }

.border-r-white-bis-inset {
  border-right: 1px inset #fafafa; }

.border-r-white-bis-outset {
  border-right: 1px outset #fafafa; }

.border-r-white-bis-none {
  border-right: 1px none #fafafa; }

.border-r-white-bis-hidden {
  border-right: 1px hidden #fafafa; }

.border-b-white-bis {
  border-bottom: 1px solid #fafafa; }

.border-b-white-bis-dotted {
  border-bottom: 1px dotted #fafafa; }

.border-b-white-bis-dashed {
  border-bottom: 1px dashed #fafafa; }

.border-b-white-bis-solid {
  border-bottom: 1px solid #fafafa; }

.border-b-white-bis-double {
  border-bottom: 1px double #fafafa; }

.border-b-white-bis-groove {
  border-bottom: 1px groove #fafafa; }

.border-b-white-bis-ridge {
  border-bottom: 1px ridge #fafafa; }

.border-b-white-bis-inset {
  border-bottom: 1px inset #fafafa; }

.border-b-white-bis-outset {
  border-bottom: 1px outset #fafafa; }

.border-b-white-bis-none {
  border-bottom: 1px none #fafafa; }

.border-b-white-bis-hidden {
  border-bottom: 1px hidden #fafafa; }

.border-l-white-bis {
  border-left: 1px solid #fafafa; }

.border-l-white-bis-dotted {
  border-left: 1px dotted #fafafa; }

.border-l-white-bis-dashed {
  border-left: 1px dashed #fafafa; }

.border-l-white-bis-solid {
  border-left: 1px solid #fafafa; }

.border-l-white-bis-double {
  border-left: 1px double #fafafa; }

.border-l-white-bis-groove {
  border-left: 1px groove #fafafa; }

.border-l-white-bis-ridge {
  border-left: 1px ridge #fafafa; }

.border-l-white-bis-inset {
  border-left: 1px inset #fafafa; }

.border-l-white-bis-outset {
  border-left: 1px outset #fafafa; }

.border-l-white-bis-none {
  border-left: 1px none #fafafa; }

.border-l-white-bis-hidden {
  border-left: 1px hidden #fafafa; }

.has-text-white {
  color: white; }

.has-background-white {
  background-color: white; }

.border-white {
  border: 1px solid white; }

.border-white-dotted {
  border: 1px dotted white; }

.border-white-dashed {
  border: 1px dashed white; }

.border-white-solid {
  border: 1px solid white; }

.border-white-double {
  border: 1px double white; }

.border-white-groove {
  border: 1px groove white; }

.border-white-ridge {
  border: 1px ridge white; }

.border-white-inset {
  border: 1px inset white; }

.border-white-outset {
  border: 1px outset white; }

.border-white-none {
  border: 1px none white; }

.border-white-hidden {
  border: 1px hidden white; }

.border-t-white {
  border-top: 1px solid white; }

.border-t-white-dotted {
  border-top: 1px dotted white; }

.border-t-white-dashed {
  border-top: 1px dashed white; }

.border-t-white-solid {
  border-top: 1px solid white; }

.border-t-white-double {
  border-top: 1px double white; }

.border-t-white-groove {
  border-top: 1px groove white; }

.border-t-white-ridge {
  border-top: 1px ridge white; }

.border-t-white-inset {
  border-top: 1px inset white; }

.border-t-white-outset {
  border-top: 1px outset white; }

.border-t-white-none {
  border-top: 1px none white; }

.border-t-white-hidden {
  border-top: 1px hidden white; }

.border-r-white {
  border-right: 1px solid white; }

.border-r-white-dotted {
  border-right: 1px dotted white; }

.border-r-white-dashed {
  border-right: 1px dashed white; }

.border-r-white-solid {
  border-right: 1px solid white; }

.border-r-white-double {
  border-right: 1px double white; }

.border-r-white-groove {
  border-right: 1px groove white; }

.border-r-white-ridge {
  border-right: 1px ridge white; }

.border-r-white-inset {
  border-right: 1px inset white; }

.border-r-white-outset {
  border-right: 1px outset white; }

.border-r-white-none {
  border-right: 1px none white; }

.border-r-white-hidden {
  border-right: 1px hidden white; }

.border-b-white {
  border-bottom: 1px solid white; }

.border-b-white-dotted {
  border-bottom: 1px dotted white; }

.border-b-white-dashed {
  border-bottom: 1px dashed white; }

.border-b-white-solid {
  border-bottom: 1px solid white; }

.border-b-white-double {
  border-bottom: 1px double white; }

.border-b-white-groove {
  border-bottom: 1px groove white; }

.border-b-white-ridge {
  border-bottom: 1px ridge white; }

.border-b-white-inset {
  border-bottom: 1px inset white; }

.border-b-white-outset {
  border-bottom: 1px outset white; }

.border-b-white-none {
  border-bottom: 1px none white; }

.border-b-white-hidden {
  border-bottom: 1px hidden white; }

.border-l-white {
  border-left: 1px solid white; }

.border-l-white-dotted {
  border-left: 1px dotted white; }

.border-l-white-dashed {
  border-left: 1px dashed white; }

.border-l-white-solid {
  border-left: 1px solid white; }

.border-l-white-double {
  border-left: 1px double white; }

.border-l-white-groove {
  border-left: 1px groove white; }

.border-l-white-ridge {
  border-left: 1px ridge white; }

.border-l-white-inset {
  border-left: 1px inset white; }

.border-l-white-outset {
  border-left: 1px outset white; }

.border-l-white-none {
  border-left: 1px none white; }

.border-l-white-hidden {
  border-left: 1px hidden white; }

.webchat_card {
  border: 1px solid #eee;
  background-color: white;
  width: 230px; }
  .webchat_card > a > .image {
    height: 160px;
    max-width: 100%; }
  .webchat_card > .title {
    font-weight: bold; }
  .webchat_card > .subtitle {
    font-size: 12px;
    color: #b5b5b5; }
  .webchat_card > .items {
    flex-grow: 1; }

.webchat_mailto {
  position: relative;
  margin: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden; }
  .webchat_mailto > .mailTo_container {
    padding: 0.5rem;
    display: flex;
    flex-direction: column; }

.webchat_mailTo_mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }

.webchat_mailTo_message {
  padding: 1rem; }

.webchat_mailTo_label {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem; }

.webchat_mailTo_error {
  font-size: 0.8rem;
  color: #ff3232; }

.webchat_mailTo_textarea {
  font-size: 1rem;
  resize: none;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 0.5rem;
  outline: none; }
  .webchat_mailTo_textarea.error {
    border: 1px solid red; }

.webchat_mailTo_input {
  padding: 0.5rem;
  border: 1px solid #eee;
  border-radius: 5px;
  font-size: 1rem;
  outline: none; }
  .webchat_mailTo_input.error {
    border: 1px solid red; }

.webchat_mailTo_button {
  margin-top: 0.5rem; }

.hideScroll {
  overflow: -moz-scrollbars-none !important;
  -ms-overflow-style: none !important; }
  .hideScroll ::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    background: transparent !important; }

@-moz-document url-prefix() {
  .hideScroll {
    overflow: -moz-scrollbars-none !important;
    margin-right: -16px;
    overflow: auto !important;
    -ms-overflow-style: none; }
    .hideScroll ::-webkit-scrollbar {
      display: none !important;
      width: 0px;
      background: transparent; } }

:-moz-any(#content, #appcontent) browser {
  margin-right: -14px !important;
  overflow-y: scroll;
  margin-bottom: -14px !important;
  overflow-x: scroll; }

.inline-block {
  display: inline-block; }

.fill-available {
  width: 100%;
  width: -moz-available;
  width: -webkit-stretch;
  width: -webkit-fill-available;
  width: stretch; }

.image,
.video {
  width: 100%;
  height: 100%;
  max-width: 360px;
  object-fit: cover; }
  .image.square,
  .video.square {
    width: 90px;
    height: 90px; }

.audio {
  max-width: 420px; }

.contain {
  object-fit: contain; }

.text-align-center {
  text-align: center; }

.text-align-right {
  text-align: right; }

.text-align-left {
  text-align: left; }

.word-break-break-word {
  -ms-word-break: break-word;
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
      hyphens: auto; }

.iecrap {
  max-width: 220px; }

.iecrapbis {
  display: block; }

.iecrapter {
  margin-right: 2rem;
  margin-bottom: 16px; }

.space-pre {
  white-space: pre; }

.white-space-pre {
  white-space: pre; }

.space-nowrap {
  white-space: nowrap; }

.white-space-nowrap {
  white-space: nowrap; }

.space-pre-line {
  white-space: pre-line; }

.white-space-pre-line {
  white-space: pre-line; }

.space-initial {
  white-space: normal;
  white-space: initial; }

.white-space-initial {
  white-space: normal;
  white-space: initial; }

.alert {
  margin-top: 10px;
  color: white;
  font-size: 12px;
  transition: .5s ease;
  outline: none;
  height: 50px;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: -ms-fit-content;
  width: fit-content; }
  .alert__icon {
    margin-right: 2%;
    color: #db7070; }

.size-box-number {
  width: 40%;
  border-bottom: 1px solid #e4e0f5;
  border-top: none;
  border-right: none;
  border-left: none;
  outline: none;
  padding: 2%;
  color: #5540bf; }

.danger-box {
  border: 1px solid #f7dede;
  border-radius: 10px;
  background-color: #fcf3f3;
  color: #cc3333; }

.border-solid {
  border: 1px solid #e4e0f5; }

.border-dashed {
  border: 1px dashed #e4e0f5; }

.border-left-dashed {
  border-left: 1px dashed #e4e0f5; }

.border-right-dashed {
  border-right: 1px dashed #e4e0f5; }

.danger_alert {
  border: 1px solid #f7dede !important;
  background-color: #fcf3f3 !important;
  color: #cc3333 !important; }

.period_hover:hover {
  transform: scale(1.05);
  background-color: #7a7ab8;
  color: white; }

.period_hover--danger:hover {
  transform: scale(1.1);
  background-color: #f7dede;
  color: white; }

.color_hover--danger:hover {
  color: #cc3333; }

.w-80p {
  width: 80%; }

.w-50p {
  width: 50%; }

.w-100 {
  width: 100%; }

.w-50 {
  width: 50%; }

.border {
  border: solid 1px #c7c8ea; }

.break-word {
  word-wrap: break-word; }

.opacity-middle {
  opacity: 0.5; }

.transition {
  transition: .3s ease-out; }

.border-bottom {
  border-bottom: 1px solid #dadbf1; }

.p-relative {
  position: relative; }

.w-18 {
  width: 18%; }

.w-30 {
  width: 30%; }

.w-31 {
  width: 31%; }

.opacity-middle {
  opacity: 0.5; }

.analytics_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%; }

.translateY:hover {
  transform: translateY(-3px); }

@media only screen and (max-width: 37.5em) {
  .dataTab {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    height: 100%; } }

@media only screen and (max-width: 37.5em) {
  .dataBloc {
    height: 100px;
    width: 80%; } }

@media only screen and (max-width: 56.25em) {
  .tabRank {
    display: flex  !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: center !important; } }

@media only screen and (max-width: 56.25em) {
  .tabPortBloc {
    width: 100%;
    margin-bottom: 3%; } }

@media only screen and (max-width: 37.5em) {
  .tabPortBloc {
    width: 130%; } }

.clipPath-1 {
  position: absolute;
  background-color: #6F9FD8;
  height: 100%;
  width: 100%;
  -webkit-clip-path: polygon(53% 87%, 88% 76%, 100% 70%, 100% 100%, 25% 100%, 0 100%, 16% 89%);
  clip-path: polygon(53% 87%, 88% 76%, 100% 70%, 100% 100%, 25% 100%, 0 100%, 16% 89%);
  border-radius: 6%; }

.clipPath-1:before {
  content: '';
  position: absolute;
  margin-top: 2px;
  background-color: #b1c6df;
  height: 100%;
  width: 100%;
  -webkit-clip-path: polygon(53% 87%, 88% 76%, 100% 70%, 100% 100%, 25% 100%, 0 100%, 16% 89%);
  clip-path: polygon(53% 87%, 88% 76%, 100% 70%, 100% 100%, 25% 100%, 0 100%, 16% 89%);
  border-radius: 6%; }

.clipPath-2 {
  position: absolute;
  background-color: #BFD641;
  height: 100%;
  width: 100%;
  -webkit-clip-path: polygon(46% 75%, 76% 63%, 100% 53%, 100% 100%, 0 100%, 0 91%, 11% 79%);
  clip-path: polygon(46% 75%, 76% 63%, 100% 53%, 100% 100%, 0 100%, 0 91%, 11% 79%);
  border-radius: 6%; }

.clipPath-2:before {
  content: '';
  position: absolute;
  margin-top: 2px;
  background-color: #e4e9c6;
  height: 100%;
  width: 100%;
  -webkit-clip-path: polygon(46% 75%, 76% 63%, 100% 53%, 100% 100%, 0 100%, 0 91%, 11% 79%);
  clip-path: polygon(46% 75%, 76% 63%, 100% 53%, 100% 100%, 0 100%, 0 91%, 11% 79%);
  border-radius: 6%; }

.clipPath-3 {
  position: absolute;
  background-color: #FAE03C;
  height: 100%;
  width: 100%;
  -webkit-clip-path: polygon(48% 86%, 78% 80%, 100% 72%, 100% 100%, 0 100%, 0 81%, 29% 95%);
  clip-path: polygon(48% 86%, 78% 80%, 100% 72%, 100% 100%, 0 100%, 0 81%, 29% 95%);
  border-radius: 6%; }

.clipPath-3:before {
  content: '';
  position: absolute;
  margin-top: 2px;
  background-color: #f3ecc6;
  height: 100%;
  width: 100%;
  -webkit-clip-path: polygon(48% 86%, 78% 80%, 100% 72%, 100% 100%, 0 100%, 0 81%, 29% 95%);
  clip-path: polygon(48% 86%, 78% 80%, 100% 72%, 100% 100%, 0 100%, 0 81%, 29% 95%);
  border-radius: 6%; }

.clipPath-4 {
  position: absolute;
  background-color: #ff000070;
  height: 100%;
  width: 100%;
  -webkit-clip-path: polygon(53% 84%, 78% 80%, 100% 63%, 100% 100%, 0 100%, 0 81%, 23% 78%);
  clip-path: polygon(53% 84%, 78% 80%, 100% 63%, 100% 100%, 0 100%, 0 81%, 23% 78%);
  border-radius: 6%; }

.clipPath-4:before {
  content: '';
  position: absolute;
  margin-top: 2px;
  background-color: #ffe6e6;
  height: 100%;
  width: 100%;
  -webkit-clip-path: polygon(53% 84%, 78% 80%, 100% 63%, 100% 100%, 0 100%, 0 81%, 23% 78%);
  clip-path: polygon(53% 84%, 78% 80%, 100% 63%, 100% 100%, 0 100%, 0 81%, 23% 78%);
  border-radius: 6%; }

.clipPath-5 {
  position: absolute;
  background-color: #98DDDE;
  height: 100%;
  width: 100%;
  -webkit-clip-path: polygon(53% 84%, 78% 90%, 100% 77%, 100% 100%, 0 100%, 0 100%, 21% 93%);
  clip-path: polygon(53% 84%, 78% 90%, 100% 77%, 100% 100%, 0 100%, 0 100%, 21% 93%);
  border-radius: 6%; }

.clipPath-5:before {
  content: '';
  position: absolute;
  margin-top: 2px;
  background-color: #d6e6e6;
  height: 100%;
  width: 100%;
  -webkit-clip-path: polygon(53% 84%, 78% 90%, 100% 77%, 100% 100%, 0 100%, 0 100%, 21% 93%);
  clip-path: polygon(53% 84%, 78% 90%, 100% 77%, 100% 100%, 0 100%, 0 100%, 21% 93%);
  border-radius: 6%; }

.clipPath-6 {
  position: absolute;
  background-color: #9966ff8f;
  height: 100%;
  width: 100%;
  -webkit-clip-path: polygon(51% 81%, 76% 74%, 100% 63%, 100% 100%, 0 100%, 0 100%, 36% 86%);
  clip-path: polygon(51% 81%, 76% 74%, 100% 63%, 100% 100%, 0 100%, 0 100%, 36% 86%);
  border-radius: 6%; }

.clipPath-6:before {
  content: '';
  position: absolute;
  margin-top: 2px;
  background-color: #eee6ff;
  height: 100%;
  width: 100%;
  -webkit-clip-path: polygon(51% 81%, 76% 74%, 100% 63%, 100% 100%, 0 100%, 0 100%, 36% 86%);
  clip-path: polygon(51% 81%, 76% 74%, 100% 63%, 100% 100%, 0 100%, 0 100%, 36% 86%);
  border-radius: 6%; }

.clipPath-7 {
  position: absolute;
  background-color: #ff99cc9a;
  height: 100%;
  width: 100%;
  -webkit-clip-path: polygon(57% 92%, 74% 79%, 100% 63%, 100% 100%, 0 100%, 0 91%, 11% 89%);
  clip-path: polygon(57% 92%, 74% 79%, 100% 63%, 100% 100%, 0 100%, 0 91%, 11% 89%);
  border-radius: 6%; }

.clipPath-7:before {
  content: '';
  position: absolute;
  margin-top: 2px;
  background-color: #ffe6f2;
  height: 100%;
  width: 100%;
  -webkit-clip-path: polygon(57% 92%, 74% 79%, 100% 63%, 100% 100%, 0 100%, 0 91%, 11% 89%);
  clip-path: polygon(57% 92%, 74% 79%, 100% 63%, 100% 100%, 0 100%, 0 91%, 11% 89%);
  border-radius: 6%; }

.clipPath-8 {
  position: absolute;
  background-color: #00cc999c;
  height: 100%;
  width: 100%;
  -webkit-clip-path: polygon(53% 85%, 74% 79%, 100% 73%, 100% 100%, 0 100%, 0 91%, 11% 89%);
  clip-path: polygon(53% 85%, 74% 79%, 100% 73%, 100% 100%, 0 100%, 0 91%, 11% 89%);
  border-radius: 6%; }

.clipPath-8:before {
  content: '';
  position: absolute;
  margin-top: 2px;
  background-color: #e6fff9;
  height: 100%;
  width: 100%;
  -webkit-clip-path: polygon(53% 85%, 74% 79%, 100% 73%, 100% 100%, 0 100%, 0 91%, 11% 89%);
  clip-path: polygon(53% 85%, 74% 79%, 100% 73%, 100% 100%, 0 100%, 0 91%, 11% 89%);
  border-radius: 6%; }

.outline-none {
  outline: none; }

.resize-none {
  resize: none; }

.border-tab {
  border: solid 1px #e4e0f5; }

.border-primary-bis {
  border: solid 1px #c7c8ea; }

.border-tab-b:not(:last-child) {
  border-bottom: solid 1px #e4e0f5; }

.border-r-primary-bis:not(:last-child) {
  border-right: solid 1px #c7c8ea; }

.border-button {
  border: 1px solid #c7c8ea; }

.border-tag {
  border: solid 2px #706dba; }

.border-top {
  border-top: 1px solid #f5f5f5; }

.button-push {
  border: 1px solid #c7c8ea;
  background-color: #dadbf1;
  color: #2d307b;
  height: 80%;
  width: 100px;
  margin-left: 6px;
  padding: 5px;
  outline: none;
  transition: all .3s ease-in-out; }
  .button-push:hover {
    background-color: #817fc3;
    border: 1px solid #706dba;
    color: white;
    transform: scale(1.05); }

.danger {
  border: 1px solid #f7dede !important;
  background-color: #fcf3f3 !important;
  color: #cc3333 !important; }
  .danger::-webkit-input-placeholder {
    color: #cc3333; }
  .danger::-ms-input-placeholder {
    color: #cc3333; }
  .danger::placeholder {
    color: #cc3333; }

.danger-time {
  background-color: #fcf3f3 !important;
  color: #cc3333 !important; }
  .danger-time::-webkit-input-placeholder {
    color: #cc3333; }
  .danger-time::-ms-input-placeholder {
    color: #cc3333; }
  .danger-time::placeholder {
    color: #cc3333; }

.disabled {
  pointer-events: none; }

.dateInput {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  box-sizing: border-box;
  border: 1px solid #c7c8ea;
  background-color: #dadbf1;
  color: #2d307b;
  font-size: .7rem;
  outline: none;
  padding: 6px;
  width: 20%; }
  .dateInput ::-webkit-datetime-edit-text {
    padding: 0 .1rem; }
  .dateInput ::-webkit-inner-spin-button {
    display: none; }
  .dateInput ::-webkit-calendar-picker-indicator {
    background: transparent; }

.DateRangePickerInput__withBorder {
  overflow: hidden; }

.border-programmatic {
  border: 2px solid #eb6666; }
  .border-programmatic:not(:last-child) {
    border-bottom: 0px; }
  .border-programmatic + .border-b-primary-lighter {
    border-top: 2px solid #eb6666; }

.border-programmatic-unique {
  border-left: 7px solid #eb6666; }

.testMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 95%; }
  .testMain__form {
    width: 100%; }
    .testMain__form-label {
      margin-left: 15%;
      font-weight: 200; }
    .testMain__form-input {
      display: flex;
      flex-direction: row;
      justify-content: center; }
      .testMain__form-input--color {
        width: 60%;
        height: 35px;
        border: 1px solid #EEE9FE;
        background-color: #f7f5ff;
        border-radius: 4px;
        outline: none;
        color: #C3BBDA;
        font-weight: 200;
        font-size: 14px; }
        .testMain__form-input--color:focus {
          border: 2px solid #C3BBDA;
          font-weight: 600; }
      .testMain__form-input--buttonLoader {
        width: 11% !important; }
  .testMain__res {
    width: 70%;
    height: 60%;
    border: 1px solid #EEE9FE;
    background-color: #f7f5ff;
    border-radius: 4px;
    -o-box-shadow: 2px 2px 15px 0px #EEE9FE;
    box-shadow: 2px 2px 15px 0px #EEE9FE;
    overflow: auto; }
    .testMain__res--modifier {
      text-align: center;
      white-space: pre;
      text-align: left; }

.arrow:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-left-color: #EEE9FE;
  border-right: 0;
  margin-top: -10px;
  margin-right: -15px; }

.arrow-left:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-right-color: #EEE9FE;
  border-left: 0;
  margin-top: -10px;
  margin-left: -15px; }

.hideScroll {
  overflow: -moz-scrollbars-none !important;
  -ms-overflow-style: none !important; }
  .hideScroll ::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    background: transparent !important; }

* {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  scroll-behavior: smooth; }

body {
  background: #2d2f7b; }

#root {
  text-align: left;
  max-width: 100vw;
  height: 100vh;
  background: #2d2f7b; }

#root.panelV2-iframe {
  background-color: #f2f4fd; }

.zTop {
  z-index: 1000 !important; }

#feedback {
  z-index: 1000 !important;
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
  text-align: -webkit-right; }
  #feedback > .loader {
    width: 20px;
    height: 20px;
    background-image: url(/static/media/ball.4e9032f5.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px; }

.outline-glow {
  outline-style: dotted;
  outline-color: lightblue; }

.frame {
  height: 10% !important;
  text-align: center; }

